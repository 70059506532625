import React, { useEffect, useState } from 'react'
import { ReactComponent as IconComment } from "assets/images/icons/project-icons/comment.svg";
import { ReactComponent as IconClock } from "assets/images/icons/project-icons/clock.svg";
import { ReactComponent as IconUpload } from "assets/images/icons/project-icons/upload-2.svg";
import { DateTimeFormat } from "utils/dateFormat";
import Maybe from '../common/Maybe';
import { ButtonLoader, Input, SelectInput } from '../common/form';
import { FileUploader } from 'react-drag-drop-files';
import { toast } from 'react-toastify';
import getFileSize from 'utils/getFileSize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { getSingleCustomer, manualUpdateUserDetails, manualUpdateCustomersDetails } from 'store/entities/employees/action';
import axios from 'axios';



export const ManualFullNameForm = ({control, onCancel, user, fetchCustomers}) => {
    const dispatch = useDispatch();
    const { manualUpdateUserDetailsData, manualUpdateUserDetailsLoading: loading } = useSelector((state) => state.entities.employees);
    
    const hyphenRegex = /^[a-zA-Z0-9-]*$/;
    
    const [form, setForm] = useState({
        firstName: "",
        lastName: ""
    });
    
    
    const disableSubmit = !form?.firstName?.length || !form?.lastName?.length
    
    const submitForm = (e) => {
        e.preventDefault();
        
        if(!hyphenRegex.test(form.firstName) || !hyphenRegex.test(form.lastName)) {
            toast.error("Name fields cannot contain special characters");
        } else {
            const payload = {
                ...form,
                userId: user?._id
            }
            
            dispatch(manualUpdateUserDetails({
                id: user?._id,
                payload
            })).then(() => {
                if(control === true) {   
                    onCancel();
                    fetchCustomers();
                    dispatch(getSingleCustomer(user));
                }
            })
        }
    }
    
    useEffect(() => {
        if(user?.firstName?.length > 0 || user?.lastName?.length > 0) {
            setForm({
                firstName: user?.firstName,
                lastName: user?.lastName
            });
        }
        // eslint-disable-next-line
    }, [user])
    
    useEffect(() => {
        if(manualUpdateUserDetailsData?.message?.includes("Name successfully updated")) {
            setForm({
                firstName: "",
                lastName: "",
            });
            if(control === true) {   
                onCancel();
                fetchCustomers();
                dispatch(getSingleCustomer(user));
            }
        }
        // eslint-disable-next-line
    }, [manualUpdateUserDetailsData])
    
    return (
        <Maybe condition={control}>
            <div className="w-full">
                <form onSubmit={submitForm} className="mt-1">
                    <div className="flex items-cnter justify-between">
                        
                        <div className="w-48 mr-3 mt-1">
                            <Input type="text" label="First Name" placeholder="Enter First Name" value={form.firstName || ""} onChange={(e) => setForm({...form, firstName: e.target.value})} />
                        </div>
                        
                        <div className="w-48 mr-3 mt-1">
                            <Input type="text" label="Last Name" placeholder="Enter Last Name" value={form.lastName || ""} onChange={(e) => setForm({...form, lastName: e.target.value})} />
                        </div>
                    </div>

                    <div className="flex items-center justify-between mt-4">
                        <button onClick={onCancel} className="btn btn-md btn-transparent-primary w-2/4 mr-5">
                            Cancel
                        </button>
                        
                        <ButtonLoader loading={loading} disabled={disableSubmit || loading} type="submit" className="btn btn-md btn-ep-blue w-2/4">
                            Save
                        </ButtonLoader>
                    </div>
                </form>
            </div>
        </Maybe>
    )
}

export const ManualAddressValidationForm = ({control, onCancel, user}) => {
    const dispatch = useDispatch();
    const { manualUpdateUserDetailsData, manualUpdateUserDetailsLoading: loading } = useSelector((state) => state.entities.employees);
    const adminUser = useSelector((state) => state.auth.userPersist);
    
    const [form, setForm] = useState({
        kycProofOfAddressType: "",
        address: "",
        kycAddressVerificationFile: "",
    });
    
    const [fileUploading, setFileUploading] = useState(false);
    const [addressFile, setAddressFile] = useState(null);
    
    const baseURL = process.env.REACT_APP_API_BASE_URL;
    
    const submitForm = async (e) => {
		e.preventDefault();
        const payload = {
            ...form,
            userId: user?._id
        }
        dispatch(manualUpdateCustomersDetails(payload));
	}
    
    const uploadFile = async (file) => { 
        setAddressFile(file);
        setFileUploading(true)
        
        toast("Uploading file")
        
        const url = `${baseURL}/admin/user/upload-user-address-proof`

		const config = {
			headers: {
                "content-type": "multipart/form-data",
                "Authorization": adminUser?.data?.token,
            },
        }

		const formData = new FormData();
		formData.append('kycProofOfAddressType', form?.kycProofOfAddressType)
		formData.append('file', file)
		formData.append('userId', user?._id)

		try {
				await axios.post(url, formData,
				config).then((response) => {
                    toast.success(response?.data?.message)
                    if(response?.data?.message?.includes("Image uploaded successfully")) {
                        setForm({
                            ...form,
                            kycAddressVerificationFile: response?.data?.url
                        })
                        setFileUploading(false)
                    }
				})
			} catch (error) {
			setFileUploading(false)
            toast.error(error.response?.data?.message || "File upload failed. Please retry with a smaller-sized document")
		}
    }
    
    const disableSubmit = !form.kycProofOfAddressType?.length || addressFile === null || !form.address?.length || !form?.kycAddressVerificationFile?.length;
    
        
    useEffect(() => {
        if(manualUpdateUserDetailsData?.message?.includes("Kyc record uploaded sucessfully")) {
            setForm({
                kycProofOfAddressType: "",
                address: "",
                kycAddressVerificationFile: "",
            });
            setAddressFile(null);
            if(control === true) {   
                onCancel();
                dispatch(getSingleCustomer(user));
            }
        }
        // eslint-disable-next-line
    }, [manualUpdateUserDetailsData])
    
    return (
        <Maybe condition={control}>
            <div className="w-full">
                <form onSubmit={submitForm} className="mt-6">
                    <div className="flex items-cnter justify-between">
                        <div className="w-48 mr-5">
                            <SelectInput label="Document Type" value={form.kycProofOfAddressType} onChange={(e) => setForm({...form, kycProofOfAddressType: e.target.value})}>
                                <option value="" disabled>- Select Type -</option>
                                <option value="utility-bill">Utility Bill</option>
                                <option value="driver's-license">Driver's License</option>
                                <option value="international-passport">International Passport</option>
                                <option value="national-id">National ID Card</option>
                                <option value="voter's-card">Voter's Card</option>
                                <option value="bank-statement">Bank Statement</option>
                                <option value="tenancy-receipt">Tenancy Receipt</option>
                                <option value="tenancy-agreement">Tenancy Agreement</option>
                                <option value="others">Others</option>
                            </SelectInput>
                        </div>
                        <div className="w-48 mr-3 mt-1">
                            <Input type="text" label="Address" placeholder="Enter address" value={form.address} onChange={(e) => setForm({...form, address: e.target.value})} />
                        </div>
                    </div>
                    
                    <Maybe condition={form?.kycProofOfAddressType?.length > 0}>
                        <div className="w-full mt-5">
                            <Maybe condition={addressFile === null}>
                                <FileUploader handleChange={(e) => uploadFile(e)} multiple={false} name="addressFile" types={["pdf", "jpg", "jpeg", "png"]} onTypeError={(err) => toast.error(err)} onSizeError={(err) => toast.error(err)}>
                                    <div className="form-group">
                                        <label className='pb-2 font-semibold'>
                                            Document Upload
                                        </label>
                                        
                                        <div className="h-[104px] cursor-pointer border border-gray-300 rounded-md w-full flex items-center justify-center flex-col py-4">
                                            <IconUpload />
                                            <div className="mt-4 font-medium text-ep-primary-600">
                                                Click to upload <span className="lt-gray font-normal">or drag and drop</span>
                                            </div>
                                        </div>
                                    </div>
                                </FileUploader>
                            </Maybe>
                        
                            <Maybe condition={addressFile !== null}>
                                <div className="w-full h-full p-6  border border-gray-300 flex items-center justify-center">
                                    <div>
                                        <div className="h-20 flex items-center justify-center">
                                            <IconUpload />
                                        </div>
                                        <div className="mt-4 flex space-x-4 justify-between items-center">
                                            <div>
                                                <div className="flex space-x-4">
                                                    <div className="max-w-sm ellipsis">{addressFile?.name?.substring(0, 25)}...</div>
                                                    <div>•</div>
                                                    <div>{getFileSize(addressFile?.size)}</div>
                                                </div>
                                                <div className="w-full h-1 mt-1 bg-[#10B759] rounded-full"></div>
                                            </div>
                                            <div>
                                                <FontAwesomeIcon icon="times-circle" className="cursor-pointer hover:text-red-500" onClick={() => setAddressFile(null)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Maybe>
                        </div>
                    </Maybe>
                    
                    
                    <div className="flex items-center justify-between mt-8 pb-7">
                        <button onClick={onCancel} className="btn btn-md btn-transparent-primary w-2/4 mr-5">
                            Cancel
                        </button>
                        
                        <ButtonLoader loading={loading || fileUploading} disabled={disableSubmit} type="submit" className="btn btn-md btn-ep-blue w-2/4">
                            {fileUploading ? "Uploading" : "Submit"}
                        </ButtonLoader>
                    </div>
                </form>
            </div>
        </Maybe>
    )
}


export const ManualFaceIDForm = ({control, onCancel, user}) => {
    const dispatch = useDispatch();
    const { manualUpdateUserDetailsData, manualUpdateUserDetailsLoading: loading } = useSelector((state) => state.entities.employees);
    
    const symbolRegex = /[!@#$%^&*()_+\-=\]{};':"\\|,.<>?]+/;
    const hyphenRegex = /^[a-zA-Z0-9-]*$/;
    
    const [form, setForm] = useState({
        kycDocumentType: "",
        kycJobId: "",
        kycDocumentCheck: "",
        kycVerifyDocument: "",
        kycLivenessCheck: "",
        kycResultText: "",
    });
    
    
    const disableSubmit = !form?.kycDocumentType?.length || !form?.kycJobId?.length ||
                            !form?.kycDocumentCheck?.length || !form?.kycVerifyDocument?.length ||
                            !form?.kycLivenessCheck?.length || !form?.kycResultText?.length
    
    
    const submitForm = (e) => {
        e.preventDefault();
        
        if(!hyphenRegex.test(form.kycJobId)) {
            toast.error("KYC Job ID contains incorrect characters");
        } else if(symbolRegex.test(form.kycResultText)) {
            toast.error("KYC Result Text cannot contain special characters");
        } else {
            const payload = {
                ...form,
                userId: user?._id
            }
            dispatch(manualUpdateCustomersDetails(payload));
        }
    }
    
    useEffect(() => {
        if(manualUpdateUserDetailsData?.message?.includes("Kyc record uploaded sucessfully")) {
            setForm({
                kycDocumentType: "",
                kycJobId: "",
                kycDocumentCheck: "",
                kycVerifyDocument: "",
                kycLivenessCheck: "",
                kycResultText: "",
            });
            if(control === true) {   
                onCancel();
                dispatch(getSingleCustomer(user));
            }
        }
        // eslint-disable-next-line
    }, [manualUpdateUserDetailsData])
    
    return (
        <Maybe condition={control}>
            <div className="w-full">
                <form onSubmit={submitForm} className="mt-6">
                    <div className="flex items-cnter justify-between">
                        <div className="w-48 mr-5">
                            <SelectInput label="KYC Document Type" value={form.kycDocumentType || ""} onChange={(e) => setForm({...form, kycDocumentType: e.target.value})}>
                                <option value="" disabled>- Select Type -</option>
                                <option value="PASSPORT">Passport</option>
                                <option value="IDENTITY_CARD">Identity Card</option>
                                <option value="VOTER_ID">Voter's Card</option>
                                <option value="DRIVERS_LICENSE">Driver's License</option>
                            </SelectInput>
                        </div>
                        <div className="w-48 mr-3 mt-1">
                            <Input type="text" label="KYC Job ID" placeholder="Enter Job ID" value={form.kycJobId || ""} onChange={(e) => setForm({...form, kycJobId: e.target.value})} />
                        </div>
                    </div>
                    
                    
                    <div className="flex items-cnter justify-between mt-6">
                        <div className="w-48 mr-5">
                            <SelectInput label="KYC Document Check" value={form.kycDocumentCheck || ""} onChange={(e) => setForm({...form, kycDocumentCheck: e.target.value})}>
                                <option value="" disabled>- Select Status -</option>
                                <option value="Passed">Passed</option>
                                <option value="Failed">Failed</option>
                            </SelectInput>
                        </div>
                        <div className="w-48 mr-3">
                            <SelectInput label="KYC Verify Document" value={form.kycVerifyDocument || ""} onChange={(e) => setForm({...form, kycVerifyDocument: e.target.value})}>
                                <option value="" disabled>- Select Status -</option>
                                <option value="Passed">Passed</option>
                                <option value="Failed">Failed</option>
                            </SelectInput>
                        </div>
                    </div>
                    
                    
                    <div className="flex items-cnter justify-between mt-6">
                        <div className="w-48 mr-5">
                            <SelectInput label="KYC Liveliness Check" value={form.kycLivenessCheck || ""} onChange={(e) => setForm({...form, kycLivenessCheck: e.target.value})}>
                                <option value="" disabled>- Select Status -</option>
                                <option value="Passed">Passed</option>
                                <option value="Failed">Failed</option>
                            </SelectInput>
                        </div>
                        <div className="w-48 mr-3 mt-1">
                            <Input type="text" label="KYC Result Text" placeholder="Enter text" value={form.kycResultText || ""} onChange={(e) => setForm({...form, kycResultText: e.target.value})} />
                        </div>
                    </div>

                    <div className="flex items-center justify-between mt-4">
                        <button onClick={onCancel} className="btn btn-md btn-transparent-primary w-2/4 mr-5">
                            Cancel
                        </button>
                        
                        <ButtonLoader loading={loading} disabled={disableSubmit || loading} type="submit" className="btn btn-md btn-ep-blue w-2/4">
                            Submit
                        </ButtonLoader>
                    </div>
                </form>
            </div>
        </Maybe>
    )
}


export const StatusTimeline = ({email, timestamp, text, isActive, toggleCommentView}) => {
	
	return (
		<div className="w-full mb-7 pl-5 relative border-l-2 border-[#E5E7EB]">
			<div className="absolute top-0 -left-3 text-xs">
				<IconClock className="text-xs" width={20} />
			</div>
			
			<div className="flex items-center">
				<span className="text-ep-primary-500">{email}</span>
			</div>
			<div className="text-sm my-0.5">
				{DateTimeFormat(timestamp)}
			</div>
			
			<Maybe condition={text?.length > 0}>
				<div onClick={toggleCommentView}   className="w-max text-xs mt-1 text-ep-primary-500 cursor-pointer flex items-center">
					<span className="mr-1.5 text-md">
						<IconComment />
					</span>
					{isActive ? "Hide" : "See"} Comment
				</div>
				
				<Maybe condition={isActive}>
					<div className="w-full bg-gray-50 rounded-sm text-[#374151] text-justify text-xs p-2 mt-2">
						{text}
					</div>
				</Maybe>
			</Maybe>
		</div>
	)
}