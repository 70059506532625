import { toast } from 'react-toastify';

export const errorReducer = (actionPayload) => {
    if (actionPayload.message === "Validation Errors") {

        let payloadErrors = {};
        if (actionPayload.errors){
            payloadErrors = {...actionPayload.errors};
        }
        else if (actionPayload.data?.errors){
            payloadErrors = {...actionPayload.data?.errors};
        }
        else if (actionPayload.data?.responses){
            payloadErrors = {...actionPayload.data?.responses};
        }

        Object.keys(payloadErrors).forEach(function (key) {
            for (let i = 0; i < payloadErrors[key].length; i++) {
                toast.error(payloadErrors[key][i]);
            }
        });
        
    }
    else if (actionPayload.validationErrors !== undefined) {

        const errors = actionPayload.validationErrors;

        if (typeof errors === "string"){
            toast.error(errors);
        }
        else if (Array.isArray(errors)){
            for (let i = 0; i < errors.length; i++) {
                toast.error(errors[i]);
            }
        }
        else{
            toast.error("An error occurred", {
                toastId: "unknown error",
            });
        }
        
    }
    else {
        if (actionPayload.message){
            toast.error(actionPayload.message, {
                toastId: "expired",
            });
        } else if (typeof actionPayload === "string"){
            toast.error(actionPayload);
        }
        else{
            toast.error("An error occurred", {
                toastId: "unknown error",
            });
        }
    }

};


export default errorReducer;