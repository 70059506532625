import React, { useEffect, useState } from 'react';
import { TabSettingsWide, TabSettingsMobile } from 'components/common/settingsTab';
import CurrencyFormat from 'utils/currencyFormat';
import CenterModal from 'components/misc/CenterModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import { selectPlatformFee } from 'store/entities/settings/action';
import blueTick from "assets/images/icons/project-icons/blue-tick.svg"
import { toast } from 'react-toastify';

const PlatformFees = () => {
    const dispatch = useDispatch();
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [dummyFees, setDummyFees] = useState([
        {
            id: 1,
            range: "0 - 25",
            services: [
                {
                    title: "On-Demand Pay",
                    amount: "1000",
                    isActive: true
                },
                {
                    title: "Wallet - Bulk Bill Payments",
                    amount: "0",
                    isActive: false
                },
                {
                    title: "Employee Information Management",
                    amount: "1000",
                    isActive: true
                },
                {
                    title: "Employee Loans",
                    amount: "4000",
                    isActive: true
                },
                {
                    title: "Dedicated Customer Support",
                    amount: "0",
                    isActive: false
                },
                {
                    title: "Approval Workflow Builder",
                    amount: "0",
                    isActive: false
                },
                {
                    title: "Fully Customizable Payroll with Tax & Pension Remittance",
                    amount: "0",
                    isActive: false
                },
            ],
            totalAmount: 0
        },
        {
            id: 2,
            range: "26 - 50",
            services: [
                {
                    title: "Employee Information Management",
                    amount: "1500",
                    isActive: true
                },
                {
                    title: "Wallet - Bulk Bill Payments",
                    amount: "0",
                    isActive: false
                },
                {
                    title: "Dedicated Customer Support",
                    amount: "0",
                    isActive: false
                },
                {
                    title: "Approval Workflow Builder",
                    amount: "0",
                    isActive: false
                },
                {
                    title: "Employee Loans",
                    amount: "45000",
                    isActive: true
                },
                {
                    title: "Fully Customizable Payroll with Tax & Pension Remittance",
                    amount: "0",
                    isActive: false
                },
                {
                    title: "On-Demand Pay",
                    amount: "0",
                    isActive: false
                },
            ],
            totalAmount: 0
        },
        {
            id: 3,
            range: "> 50",
            services: [
                {
                    title: "Employee Loans",
                    amount: "2000",
                    isActive: true
                },
                {
                    title: "On-Demand Pay",
                    amount: "0",
                    isActive: false
                },
                {
                    title: "Wallet - Bulk Bill Payments",
                    amount: "0",
                    isActive: false
                },
                {
                    title: "Employee Information Management",
                    amount: "0",
                    isActive: false
                },
                {
                    title: "Dedicated Customer Support",
                    amount: "0",
                    isActive: false
                },
                {
                    title: "Approval Workflow Builder",
                    amount: "0",
                    isActive: false
                },
                {
                    title: "Fully Customizable Payroll with Tax & Pension Remittance",
                    amount: "0",
                    isActive: false
                },
            ],
            totalAmount: 0
        },
    ]);
    
        return ( 
            <div>
                
                <EditFeeModal 
                    isOpenModalAlert={editModalOpen}
                    setIsOpenModalAlert={setEditModalOpen}
                />
                
                <CreateFeeModal 
                    isOpenModalAlert={createModalOpen}
                    setIsOpenModalAlert={setCreateModalOpen}
                    dummyFees={dummyFees}
                    setDummyFees={setDummyFees}
                />
                
                <TabSettingsMobile />
                
                <div className="box">
                    <div className="flex">
                        <TabSettingsWide />

                        <div className="w-full lg:w-3/4 xl:w-4/5 lg:pl-6 flex-grow">
                        
                            <div className="grid sm:grid-cols-2">
                                <div className="page-title">
                                    Platform Fees Settings
                                </div>
                                
                                <div className="flex sm:justify-end pt-3 md:pt-0">
                                    <button onClick={() => setCreateModalOpen(true)} className="btn btn-md btn-ep-blue">
                                        <FontAwesomeIcon icon="plus" className="text-md" />
                                        <span>
                                            Create Fee
                                        </span>
                                    </button>
                                </div>
                            </div>
                            
                            <div className="w-full my-7">
                                <div className="mt-3">
                                        <div className="table-container">
                                            <table className="table table-auto table-rounded table-border table-align-top">
                                                <thead>
                                                    <tr>
                                                        <th>Employee Range</th>
                                                        <th>Services</th>
                                                        <th>Total Amount</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {dummyFees.map((fee) => {
                                                        return (
                                                            <tr key={fee?.id}>
                                                                <td>
                                                                    {fee?.range}
                                                                </td>
                                                                <td>
                                                                    <div className="flex items-center whitespace-nowrap">
                                                                    {
                                                                        fee?.services?.slice(0, 1)?.map(({title}, index) => {
                                                                            const activeFees = fee?.services?.filter((item) => Number(item?.amount) > 0)
                                                                            
                                                                            return (
                                                                                <div key={index} className="mr-2 text-sm">
                                                                                    {title} {`${activeFees?.length > 1 ? `, +${activeFees?.length - 1}` : ""}`}
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    {CurrencyFormat(fee?.services.reduce((sum , { amount }) => sum + Number(amount) , 0))}
                                                                </td>
                                                                <td>
                                                                    <button onClick={() => {
                                                                        dispatch(selectPlatformFee(fee));
                                                                        setEditModalOpen(true);
                                                                    }} className="text-ep-blue-deeper font-semibold">
                                                                        Edit
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
}



const CreateFeeModal = ({isOpenModalAlert, setIsOpenModalAlert, dummyFees, setDummyFees}) => {
    const onClose = () => setIsOpenModalAlert(false);
    const [form, setForm] = useState({
        id: 234,
        range: "",
        services: [
            {
                title: "On-Demand Pay",
                amount: "0",
                isActive: false
            },
            {
                title: "Wallet - Bulk Bill Payments",
                amount: "0",
                isActive: false
            },
            {
                title: "Employee Information Management",
                amount: "0",
                isActive: false
            },
            {
                title: "Employee Loans",
                amount: "0",
                isActive: false
            },
            {
                title: "Dedicated Customer Support",
                amount: "0",
                isActive: false
            },
            {
                title: "Approval Workflow Builder",
                amount: "0",
                isActive: false
            },
            {
                title: "Fully Customizable Payroll with Tax & Pension Remittance",
                amount: "0",
                isActive: false
            },
        ],
        totalAmount: 0
    });
    const [totalAmount, setTotalAmount] = useState("");
    
    const toggleSelection = (title) => {
        const editedServices = form?.services?.map((service) => service?.title === title ? {...service, isActive: !service.isActive, amount: "0"} : service)
        
        setForm({
            ...form,
            services: editedServices
        })
    }
    
    const updateAmount = (e, title) => { 
        const value = e.target.value;
        const editedServices = form?.services?.map((service) => service?.title === title ? {...service, amount: value} : service)
        
        setForm({
            ...form,
            services: editedServices
        })
    }
    
    const addNewFee = () => {
        if(!form?.range?.length) {
            toast.error("Please select an employee range")
        } else {
            const invalidService = form?.services?.find((service) => service?.isActive === true && (!service?.amount?.length || service.amount === "0"))
            
            if(invalidService) {
                toast.error("Please enter an amount for all selected services")
            } else {
                setDummyFees([...dummyFees, form])
                onClose();
            }
        }
    }
    
    const updateTotalAmount = () => {
        const total = form?.services?.reduce((sum , { amount }) => sum + Number(amount) , 0)
        setTotalAmount(total);
    }
    
    useEffect(() => {
        updateTotalAmount();
        // eslint-disable-next-line
    }, [form?.services])

    return (
        <CenterModal isCustom className="lg:w-2/4 bg-white rounded-md overflow-y-scroll" control={isOpenModalAlert}>
            <div className="platform-fee-modal-title">
                <div className="w-full flex items-center justify-between border-b pb-3">
                    <div className='font-semibold'>New Platform Fee</div>
                    
                    <div onClick={onClose}>
                        <FontAwesomeIcon icon="times" className="customizeSVG m-auto cursor-pointer" />
                    </div>
                </div>
            </div>
            
            <div className="platform-fee-modal-content">
                <div className="mb-9 w-full md:w-max">
                    <div className="form-group">
                        <label>Employee Range</label>
                        <select value={form?.range || ""} onChange={(e) => setForm({...form, range: e.target.value})} className="w-full py-1.5 border border-gray-300 rounded">
                            <option value="">Select range</option>
                            <option value="0 - 25">0 - 25 employees</option>
                            <option value="26 - 50">26 - 50 employees</option>
                            <option value="> 50">&gt; 50 employees</option>
                        </select>
                    </div>
                </div>
                
                <div className="w-full">
                    <div className="mb-5 flex items-center justify-between">
                        <p className="font-medium">Services</p>
                    
                        <div className="w-2/4 pl-1 hidden md:flex justify-start">
                            <p className="mb-0">Amount</p>
                        </div>
                    </div>
                    
                    {form?.services?.map((service) => {
                        return (
                            <div key={service?.title} className="mb-8 w-full flex items-center justify-between flex-wrap md:flex-nowrap">
                                <div className="w-full md:w-2/4 mb-2 md:mb-0 pr-2">
                                    <div onClick={() => toggleSelection(service?.title)}  className="w-max flex items-center cursor-pointer">
                                        <div className="w-4 h-4 rounded-full border mr-3">
                                            {service?.isActive && <img src={blueTick} alt="tick" />}
                                        </div>
                                        
                                        <div className={`text-sm cursor-pointer ${service?.title?.length > 40 && "w-4/5"}`}>
                                            {service?.title}
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="w-full md:w-2/4 pl-2">
                                    <div className="relative">
                                        <input 
                                            disabled={!service?.isActive}
                                            onChange={(e) => updateAmount(e, service?.title)}
                                            type="number" 
                                            placeholder="Enter amount" 
                                            className={`form-input input-minAmount-create ${!service?.isActive && "bg-gray-300 cursor-not-allowed"}`} 
                                            value={service?.amount || ""}
                                            id="platform-fee-input"
                                        />
                                        <span className="platform-fee-currency">₦&nbsp;</span>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
                
                <div className="mt-8 text-ep-blue">
                    Total: {CurrencyFormat(totalAmount)}
                </div>
                
                <div className="platform-fee-modal-actions">
                    <button onClick={addNewFee} className="btn btn-md btn-ep-blue mr-5">Save</button>
                    <button onClick={onClose} className="btn btn-md btn-transparent-black">Cancel</button>
                </div>
            </div>
        </CenterModal>
    )
}


const EditFeeModal = ({isOpenModalAlert, setIsOpenModalAlert}) => {
    const onClose = () => setIsOpenModalAlert(false);
    
    const {selectedPlatformFee} = useSelector((state) => state.entities.settings);
    const [fee, setFee] = useState(selectedPlatformFee);
    const [totalAmount, setTotalAmount] = useState("");
    
    const toggleSelection = (title) => {
        const editedServices = fee?.services?.map((service) => service?.title === title ? {...service, isActive: !service.isActive, amount: "0"} : service)
        
        setFee({
            ...fee,
            services: editedServices
        })
    }
    
    const updateAmount = (e, title) => { 
        const value = e.target.value;
        const editedServices = fee?.services?.map((service) => service?.title === title ? {...service, amount: value} : service)
        
        setFee({
            ...fee,
            services: editedServices
        })
    }
    
    const updateFeeEdit = () => { 
        const invalidService = fee?.services?.find((service) => service?.isActive === true && (!service?.amount?.length || service.amount === "0"))
        
        if(invalidService) {
            toast.error("Please enter an amount for all selected services")
        } else {
            onClose();
        }
    }
    
    const updateTotalAmount = () => {
        const total = fee?.services?.reduce((sum , { amount }) => sum + Number(amount) , 0)
        setTotalAmount(total);
    }
    
    useEffect(() => {
        if(selectedPlatformFee?.range?.length > 0) {
            setFee(selectedPlatformFee);
            updateTotalAmount();
        }
        // eslint-disable-next-line
    }, [selectedPlatformFee])
    
    useEffect(() => {
        updateTotalAmount();
        // eslint-disable-next-line
    }, [fee?.services])

    return (
        <CenterModal isCustom className="lg:w-2/4 bg-white rounded-md overflow-y-scroll" control={isOpenModalAlert}>
            <div className="platform-fee-modal-title">
                <div className="w-full flex items-center justify-between border-b pb-3">
                    <div className='font-semibold'>Edit Platform Fee</div>
                    
                    <div onClick={onClose}>
                        <FontAwesomeIcon icon="times" className="customizeSVG m-auto cursor-pointer" />
                    </div>
                </div>
            </div>
            
            <div className="platform-fee-modal-content">
                <div className="mb-9 w-full md:w-max">
                    <div className="form-group">
                        <label>Employee Range</label>
                        <select disabled className="w-full py-1.5 border border-gray-300 rounded bg-gray-300">
                            <option>{fee?.range} employees</option>
                        </select>
                    </div>
                </div>
                
                <div className="w-full">
                    <div className="mb-5 flex items-center justify-between">
                        <p className="font-medium">Services</p>
                    
                        <div className="w-2/4 pl-1 hidden md:flex justify-start">
                            <p className="mb-0">Amount</p>
                        </div>
                    </div>
                    
                    {fee?.services?.map((service) => {
                        return (
                            <div key={service?.title} className="mb-8 w-full flex items-center justify-between flex-wrap md:flex-nowrap">
                                <div className="w-full md:w-2/4 mb-2 md:mb-0 pr-2">
                                    <div onClick={() => toggleSelection(service?.title)}  className="w-max flex items-center cursor-pointer">
                                        <div className="w-4 h-4 rounded-full border mr-3">
                                            {service?.isActive && <img src={blueTick} alt="tick" />}
                                        </div>
                                        
                                        <div className={`text-sm cursor-pointer ${service?.title?.length > 40 && "w-4/5"}`}>
                                            {service?.title}
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="w-full md:w-2/4 pl-2">
                                    <div className="relative">
                                        <input 
                                            disabled={!service?.isActive}
                                            onChange={(e) => updateAmount(e, service?.title)}
                                            type="number" 
                                            placeholder="Enter amount" 
                                            className={`form-input input-minAmount-create ${!service?.isActive && "bg-gray-300 cursor-not-allowed"}`} 
                                            value={service?.amount || ""}
                                            id="platform-fee-input"
                                        />
                                        <span className="platform-fee-currency">₦&nbsp;</span>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
                
                <div className="mt-8 text-ep-blue">
                    Total: {CurrencyFormat(totalAmount)}
                </div>
                
                <div className="platform-fee-modal-actions">
                    <button onClick={updateFeeEdit} className="btn btn-md btn-ep-blue mr-5">Update</button>
                    <button onClick={onClose} className="btn btn-md btn-transparent-black">Cancel</button>
                </div>
            </div>
        </CenterModal>
    )
}

export default PlatformFees;