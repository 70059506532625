import FormDrawer from "components/__new/common/form-drawer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateMiniFormat } from "utils/dateFormat";
import moment from "moment";
import NumberFormat from "utils/numberFormat";
import Maybe from "../common/Maybe";
import useLoginInfo from "hooks/useLoginInfo";


const RewardDetails = (props) => {
    const { isOpen, setIsOpen, selectedReward: reward } = props;
    const {userInfo} = useLoginInfo();
    
    
    return (
        <FormDrawer isOpen={isOpen} setIsOpen={setIsOpen}>
            <div>
                <div className="p-6">
                    <div className="flex justify-between">
                        <div className="font-recoleta font-semibold text-2xl">
                            {reward.title ?? "-"}
                        </div>
                        <div onClick={() => setIsOpen(false)} className="modal-close group">
                            <FontAwesomeIcon icon="times" className="customizeSVG group-hover:text-white m-auto" />
                        </div>
                    </div>
                    <div className="text-sm">
                        <span className="text-fade">Reward Validity</span>&nbsp;
                        <span className="text-ep-blue">{DateMiniFormat(moment(reward.expiryDate ?? "", "YYYY-MM-DD")) ?? "-"}</span>
                    </div>
                </div>

                <div className="w-full h-px my-2 bg-gray-200"></div>

                <div className="p-6 w-full flex flex-row flex-wrap">

                    <div className="w-full mb-6 font-semibold text-sm">
                        Reward Details
                    </div>

                    <div className="w-full mb-6 grid grid-cols-2 gap-6">
                        <ModalDetailsContent label="Rewards Title" value={reward.title ?? "-"} />
                        <ModalDetailsContent label="Rewarder" value={reward?.partner ?? "-"} />
                        <ModalDetailsContent label="Status" value={<span className={reward?.isApproved === true ? "text-green-500" : "text-red-500"}>
                            {reward?.isApproved === true ? "Enabled" : "Disabled"}
                        </span>} />
                        <ModalDetailsContent label="Point Cost" value={<span className="text-green-500">{NumberFormat(reward?.pointCost) ?? "-"}</span>} />
                    </div>

                    <div className="w-full mb-6">
                        <ModalDetailsContent label="Reward Validity" value={DateMiniFormat(moment(reward?.expiryDate, "YYYY-MM-DD")) ?? "-"} />
                    </div>
                    <div className="w-full mb-6">
                        <ModalDetailsContent label="Description" value={reward?.description ?? "-"} />
                    </div>
                    
                    <div className="w-full h-px mt-4 mb-8 bg-gray-200"></div>

                    <div className="w-full mb-6 font-semibold text-sm">
                        Images
                    </div>

                    <div className="w-full mb-6">
                        <div className="font-medium text-xs">
                            Rewarder’s Logo
                        </div>
                        <div className="mt-1">
                            <a href={reward?.partnerLogo} target="_blank" rel="noreferrer" className="inline-block h-20 bg-gray-200">
                                <img src={reward?.partnerLogo} alt="logo" className="object-contain" />
                            </a>
                        </div>
                    </div>
                    
                    <div className="w-full mb-6">
                        <div className="font-medium text-xs">
                            Thumbnail
                        </div>
                        <div className="mt-1">
                            <a href={reward?.thumbnail} target="_blank" rel="noreferrer" className="inline-block h-40 bg-gray-200">
                                <img src={reward?.thumbnail} alt="thumbnail" className="object-contain" />
                            </a>
                        </div>
                    </div>
                    
                    <div className="w-full h-px mt-4 mb-8 bg-gray-200"></div>

                    <div className="w-full mb-6">
                        <div className="font-medium">
                            Promo Codes ({reward?.promoCode?.length ?? 0})
                        </div>
                        <Maybe condition={userInfo?.userType === "super-admin"}>
                            <div className="mt-1">
                                <Maybe condition={reward?.promoCode?.length > 0}>
                                    {reward?.promoCode?.map((code, index) => (
                                        <div key={index} className="w-full text-sm p-4 border grid grid-cols-2 gap-6">
                                            <div>
                                                {code?.code ?? "-"}
                                            </div>
                                            <div>
                                                {code?.available === true ? <span className="label label-green label-sm">Available</span> : <span className="label label-red label-sm">Unavailable</span>}
                                            </div>
                                        </div>
                                    ))}
                                </Maybe>
                                <Maybe condition={reward?.promoCode?.length === 0}>
                                    <div className="h-28 flex items-center justify-center italic bg-gray-100">
                                        No promo codes uploaded
                                    </div>
                                </Maybe>
                            </div>
                        </Maybe>
                    </div>

                    <div className="w-full h-px mt-4 mb-8 bg-gray-200"></div>

                    <div className="w-full mb-6 font-semibold text-sm">
                        How to Use
                    </div>

                    <div className="space-y-3">
                        <Instruction
                            index="1"
                            content={reward?.instructionOne}
                        />
                        <Instruction
                            index="2"
                            content={reward?.instructionTwo}
                        />
                        <Instruction
                            index="3"
                            content={reward?.instructionThree}
                        />
                    </div>
                    
                </div>
            </div>
        </FormDrawer>
    )
}


const ModalDetailsContent = (props) => (
    <div>
        <div className="font-medium text-sm">
            {props.label}
        </div>
        <div className="mt-1 font-medium text-sm">
            {props.value}
        </div>
    </div>
)


const Instruction = (props) => {
    const {index, content} = props;

    return (
        <div className="flex items-center">
            <div className="w-10 h-10 mr-3 font-bold flex flex-shrink-0 items-center justify-center text-[#00535C] bg-[#F0F6F6] rounded-full">
                {index}
            </div>
            <div>
                <Maybe condition={content === "" || content === null || content === undefined}>
                    -
                </Maybe>
                <Maybe condition={!(content === "" || content === null || content === undefined)}>
                    {content}
                </Maybe>
            </div>
        </div>
    )
}


export default RewardDetails;