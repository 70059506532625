import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';


const options = {
    closeOnEscape: true,
    closeOnClickOutside: true,
    keyCodeForClose: [8, 32],
    willUnmount: () => {},
    afterClose: () => {},
    onClickOutside: () => {},
    onKeypress: () => {},
    onKeypressEscape: () => {},
    overlayClassName: "overlay-custom-class-name"
};


/**
how to use

const result = await DialogBox({
    theme: "red",
});
if (result) {
    alert('a');
}
else{
    alert('b');
}
*/


const DialogBox = async (props) => {
    let title = "Confirm Action";
    let content = "Please confirm that you want to carry out this action";
    let buttonYes = "Proceed";
    let buttonNo = "Cancel";
    let buttonYesClass = "btn-ep-primary";
    let buttonNoClass = "btn-transparent-black";
    let size = "w-96";


    // console.log("props", props);
    if (props){
        if (props.title){
            ({title} = props);
        }
        if (props.content){
            ({content} = props);
        }
        if (props.buttonYes){
            ({buttonYes} = props);
        }
        if (props.buttonNo){
            ({buttonNo} = props);
        }
        if (props.buttonYesClass){
            ({buttonYesClass} = props);
        }
        if (props.buttonNoClass){
            ({buttonNoClass} = props);
        }
        if (props.size){
            ({size} = props);
        }
    }


    let myPromise = new Promise(function(resolve, reject) {
        confirmAlert({
            ...options,
            customUI: ({ onClose }) => {
                return (
                    <div className={size + " dialog-box p-8 bg-white rounded-lg shadow-lg overflow-hidden"}>
                        <div className="font-bold text-[#0D0C2B] text-lg sm:text-xl">
                            {title}
                        </div>
                        <div className="mt-6 text-[#343A40]">
                            {content}
                        </div>
                        <div className="mt-8 flex space-x-4 justify-start">
                            <button type="button" className={"btn " + buttonYesClass} onClick={() => {resolve(true); onClose();}} autoFocus={true}>
                                {buttonYes}
                            </button>
                            <button type="button" className={"btn " + buttonNoClass} onClick={() => {resolve(false); onClose();}}>
                                {buttonNo}
                            </button>
                        </div>
                    </div>
                );
            }
        })
    });


    return myPromise;
};


export default DialogBox;