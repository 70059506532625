
export const GET_ALL_SUB_ACCOUNTS_START = "GET_ALL_SUB_ACCOUNTS_START";
export const GET_ALL_SUB_ACCOUNTS_DONE = "GET_ALL_SUB_ACCOUNTS_DONE";
export const GET_ALL_SUB_ACCOUNTS_FAILED = "GET_ALL_SUB_ACCOUNTS_FAILED";

export const GET_SINGLE_SUB_ACCOUNT_START = "GET_SINGLE_SUB_ACCOUNT_START";
export const GET_SINGLE_SUB_ACCOUNT_DONE = "GET_SINGLE_SUB_ACCOUNT_DONE";
export const GET_SINGLE_SUB_ACCOUNT_FAILED = "GET_SINGLE_SUB_ACCOUNT_FAILED";

export const GET_SUB_ACCOUNT_METRICS_START = "GET_SUB_ACCOUNT_METRICS_START";
export const GET_SUB_ACCOUNT_METRICS_DONE = "GET_SUB_ACCOUNT_METRICS_DONE";
export const GET_SUB_ACCOUNT_METRICS_FAILED = "GET_SUB_ACCOUNT_METRICS_FAILED";

export const DOWNLOAD_SUB_ACCOUNTS_START = "DOWNLOAD_SUB_ACCOUNTS_START";
export const DOWNLOAD_SUB_ACCOUNTS_DONE = "DOWNLOAD_SUB_ACCOUNTS_DONE";
export const DOWNLOAD_SUB_ACCOUNTS_FAILED = "DOWNLOAD_SUB_ACCOUNTS_FAILED";

export const SELECT_SUB_ACCOUNT = "SELECT_SUB_ACCOUNT";