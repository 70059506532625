import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const EarniButton = ({
	text,
	onClick,
	disabled,
	loading,
	secondary,
	whiteBgBordered,
	className,
	plainBordered
}) => {
	return (
		<button
			disabled={disabled || loading}
			onClick={onClick}
			className={`px-4 py-1.5 earni-button flex items-center justify-center rounded-sm active:scale-90 text-white text-sm font-medium ${
				secondary ? "bg-white text-ep-blue-deeper border border-ep-blue" : "bg-ep-blue-deeper"
			} ${className}`}>
			{loading && (
				<FontAwesomeIcon
					icon="spinner"
					spin
					className={"text-xl" + (loading ? "inline" : "hidden")}
				/>
			)}{" "}
			<span className={loading ? "ml-2" : ""}>{text}</span>
		</button>
	);
};

export default EarniButton;
