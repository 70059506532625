
export const GET_BUSINESSES_METRICS = "GET_BUSINESSES_METRICS";
export const GET_BUSINESSES_METRICS_START = "GET_BUSINESSES_METRICS_START";
export const GET_BUSINESSES_METRICS_FAILED = "GET_BUSINESSES_METRICS_FAILED";

export const GET_BUSINESSES = "GET_BUSINESSES";
export const GET_BUSINESSES_START = "GET_BUSINESSES_START";
export const GET_BUSINESSES_FAILED = "GET_BUSINESSES_FAILED";

export const DOWNLOAD_GET_BUSINESSES = "DOWNLOAD_GET_BUSINESSES";
export const DOWNLOAD_GET_BUSINESSES_START = "DOWNLOAD_GET_BUSINESSES_START";
export const DOWNLOAD_GET_BUSINESSES_FAILED = "DOWNLOAD_GET_BUSINESSES_FAILED";

export const GET_BUSINESS = "GET_BUSINESS";
export const GET_BUSINESS_START = "GET_BUSINESS_START";
export const GET_BUSINESS_FAILED = "GET_BUSINESS_FAILED";

export const APPROVE_COMPLIANCE = "APPROVE_COMPLIANCE";
export const APPROVE_COMPLIANCE_START = "APPROVE_COMPLIANCE_START";
export const APPROVE_COMPLIANCE_FAILED = "APPROVE_COMPLIANCE_FAILED";
export const APPROVE_COMPLIANCE_RESET = "APPROVE_COMPLIANCE_RESET";

export const REJECT_COMPLIANCE = "REJECT_COMPLIANCE";
export const REJECT_COMPLIANCE_START = "REJECT_COMPLIANCE_START";
export const REJECT_COMPLIANCE_FAILED = "REJECT_COMPLIANCE_FAILED";
export const REJECT_COMPLIANCE_RESET = "REJECT_COMPLIANCE_RESET";
