import { useState } from "react";
import FormDrawer from "../common/form-drawer--new";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { resetAddressRejectionStatus } from "store/entities/employees/action";

const RejectAddressVerificationModal = ({isOpen, toggleAddressReject, onProceed, loading, user, onClose, fetchCustomers}) => {
	const { addressRejectionFailed, addressRejectionSuccess } = useSelector((state) => state.entities.employees);
	const [reason, setReason] = useState("");

	const handleRejectAddress = () => {
		onProceed(reason);
	}

	const handleCloseModal = () => {
		setReason("");
		toggleAddressReject();
	}

	useEffect(() => {
		if (addressRejectionSuccess) {
			resetAddressRejectionStatus();
			onClose();
			fetchCustomers();
			handleCloseModal();
		}

		if (addressRejectionFailed) {
			resetAddressRejectionStatus();
		}
	}, [addressRejectionFailed, addressRejectionSuccess])
	
	return (
		<FormDrawer display="center" size="md" isOpen={isOpen} setIsOpen={() => {}}>
			<div className="p-5 max-w-md">
				<h1 className="lg:text-lg font-semibold">Reject Address Verification</h1>
				
				<div className="form-group mt-6 mb-0">
					<label>
						Are you sure you want to reject address verification for <span className="font-semibold capitalize"> {user?.firstName} {user?.lastName} ?</span>
					</label>
				</div>
				<div className="form-group mt-6 mb-10">
					<label>Rejection Reason</label>
					<textarea 
						className="form-input"
						placeholder="Enter Reason"
						style={{ height: "120px", resize: "none" }}
						value={reason} onChange={(e) => setReason(e.target.value)}
					/>
				</div>
				
				<div className="flex items-center justify-center lg:flex-row lg:space-x-2">
					
					<button
						type="button"
						onClick={handleCloseModal}
						className="btn btn-md border border-ep-primary-500 text-ep-primary-500 w-full lg:w-2/4 focus:ring-0 mr-4"
						disabled={loading}>
						Cancel
					</button>
					
					<button
						disabled={loading || reason.length < 3}
						type="button" 
						onClick={handleRejectAddress}
						className="btn btn-md border btn-red text-white w-full lg:w-2/4 focus:ring-0">
						{loading ? "Submitting..." : "Yes"}
					</button>
				</div>
			</div>
		</FormDrawer>
	)
}

export default RejectAddressVerificationModal