import React, { Component } from 'react';
import DashboardBoxes from 'components/common/dashboard-boxes';
import CurrencyFormat from "utils/currencyFormat";
import queryString from 'query-string';
import Pagination from 'components/common/pagination';
import { DateTimeFormat, DateMiniFormat, TimeFormat } from "utils/dateFormat";
import Dropdown from "utils/dropdown";
// import { NavLink } from "react-router-dom";
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { connect } from "react-redux";
import { Dialog } from '@headlessui/react';
import { customizeSVG } from 'utils/customizeSVG.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactComponent as IconWithdraw } from "assets/images/icons/project-icons/Withdraw.svg";
import { ReactComponent as IconFees } from "assets/images/icons/project-icons/Fees.svg";
import { ReactComponent as IconWallet } from "assets/images/icons/project-icons/Wallet.svg";
import { ReactComponent as IconNav } from "assets/images/icons/project-icons/Navicon-v.svg";
import { ReactComponent as IconUser2 } from "assets/images/icons/project-icons/User-2.svg";
import { ReactComponent as IconUpload } from "assets/images/icons/project-icons/Upload.svg";
import { ReactComponent as DownloadIcon } from "assets/images/icons/project-icons/Download-Icon.svg";
import { ReactComponent as IconTicketStar } from "assets/images/icons/project-icons/Ticket-Star.svg";
import iconLogo from "assets/images/icons/logo-icon.svg";
import DialogBox from "utils/dialogBox";
import * as employees from "store/entities/employees/action";
import * as withdrawals from "store/entities/withdrawals/action";

const dropdownTableIcon = <IconNav className="w-8 h-8 p-1.5 relative top-1 cursor-pointer group-hover:bg-gray-200 rounded" />;

class List extends Component {
    constructor(props) {
        super(props);
        this.completeButtonRef = React.createRef(null);
    }

    state = {
        employeeId: "",
        getAllWithdrawalsParams: {
            page: 1,
            limit: 20,
            status: "",
            year: "",
            month: "",
            employeeId: "",
        },
        dashboardProps1: {
            iconBg: "bg-blue-200",
            icon: <IconWithdraw className="customizeSVG text-blue-600 m-auto w-5 h-5" />,
            titleText: "Total Withdrawals Amount",
            mainText: "-"
        },
        dashboardProps2: {
            iconBg: "bg-green-200",
            icon: <IconFees className="customizeSVG text-green-600 m-auto w-5 h-5" />,
            titleText: "Total Transaction Fees",
            mainText: "-"
        },
        toggleModalDetails: false,
        withdrawalsDetails: "",
    }


    goBack = () => {
        this.props.history.goBack();
    }


    getPageContent = () => {
        if ("id" in this.props.match.params){
            const {id} = this.props.match.params;
            this.props.getEmployeeDetails(id);
            this.getWithdrawalsByEmployeeId(id);
            this.setState({employeeId: id});
        }
    }


    getWithdrawalsByEmployeeId = (employeeId) => {
        const qs = queryString.parse(this.props.location.search);
        let getAllWithdrawalsParams = {...this.state.getAllWithdrawalsParams, employeeId};
        
        if ((qs.page !== undefined) && (!isNaN(qs.page)) && (qs.page > 0)){
            getAllWithdrawalsParams = {...getAllWithdrawalsParams, page: qs.page};
        }
        else{
            getAllWithdrawalsParams = {...getAllWithdrawalsParams, page: 1};
        }
        
        if ((qs.limit !== undefined) && (!isNaN(qs.limit)) && (qs.limit > 0)){
            getAllWithdrawalsParams = {...getAllWithdrawalsParams, limit: qs.limit};
        }
        else{
            getAllWithdrawalsParams = {...getAllWithdrawalsParams, limit: 20};
        }
        
        if ((qs.status !== undefined) && (qs.status !== null)){
            getAllWithdrawalsParams = {...getAllWithdrawalsParams, status: qs.status};
        }
        else{
            getAllWithdrawalsParams = {...getAllWithdrawalsParams, status: ""};
        }
        
        this.setState({getAllWithdrawalsParams});
        this.props.getWithdrawalsByEmployeeId(getAllWithdrawalsParams);
        // this.props.getWalletByEmployeeId(employeeId, getAllWithdrawalsParams);
    }


    viewDetails = (withdrawal = {}) => {
        // console.log(withdrawal);
        const toggleModalDetails = this.state.toggleModalDetails;
        this.setState({toggleModalDetails: !toggleModalDetails, withdrawalsDetails: withdrawal});
    }


    activateEmployee = (employee_id) => {
        this.props.activateEmployee(employee_id);
    }


    deactivateEmployee = (employee_id) => {
        this.props.deactivateEmployee(employee_id);
    }
    

    resendInvite = async (email) => {
        const result = await DialogBox();
		if (result) {
            const resendInviteParams = {
                email
            }
            this.props.resendInvite(resendInviteParams);
            toast("Resending invite to " + email);
        }
    }


    closeModal = (action) => {
        this.setState({toggleModalDetails: action});
    }


    retryWithdrawal = (withdrawal_id) => {
        this.props.retryWithdrawal(withdrawal_id);
        this.setState({toggleModalDetails: false});
    }


    componentDidMount() {
        this.getPageContent();
    }
    
    
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.getPageContent();
        }
        if (this.props.reloadPage === true) {
            setTimeout(()=>{
                this.props.resetReloadPage();
                this.props.history.replace(this.props.location.pathname + this.props.location.search);
            },1000);
        }
    }

    
    componentWillUnmount(){
        const { storeUnsubscribe1 } = this.state;
        if ((storeUnsubscribe1 !== "") && (storeUnsubscribe1 !== undefined) && (storeUnsubscribe1 !== null)){
            storeUnsubscribe1();
        }
    }


    render() { 
        customizeSVG();
        const { employeesDetailsLoading, employeesDetails, employeeWithdrawalsLoading, employeeWithdrawals, employeeWithdrawalsResponse, employeeWallet } = this.props;
        const { toggleModalDetails, withdrawalsDetails, getAllWithdrawalsParams } = this.state;
        
        return ( 
            <div>

                {(toggleModalDetails === true) &&
                    <Dialog open={toggleModalDetails} initialFocus={this.completeButtonRef} onClose={() => this.closeModal(false)} className="fixed z-10 inset-0 overflow-y-auto">
                        <div className="flex items-center justify-center min-h-screen">
                            <Dialog.Overlay className="fixed inset-0 bg-white bg-blur bg-opacity-50" />

                            <div className="w-full max-w-lg mx-auto relative bg-white rounded-2xl overflow-hidden shadow-xl">
                                <div className="h-14 px-4 bg-ep-yellow border-b border-white border-opacity-25 flex justify-between overflow-hidden">
                                    <div className="text-center font-medium flex-grow my-auto text-white">
                                        Payment Details
                                    </div>
                                    <div onClick={() => this.closeModal(false)} ref={this.completeButtonRef} className="w-7 h-7 flex flex-shrink-0 my-auto border border-transparent group hover:bg-red-400 hover:border-red-400 rounded-lg cursor-pointer">
                                        <FontAwesomeIcon icon="times" className="text-white m-auto" />
                                    </div>
                                </div>


                                {(withdrawalsDetails !== "") && 
                                    <div>

                                        <div className="h-24 bg-ep-yellow overflow-hidden"></div>

                                        <div className="w-full flex">
                                            <div className="w-16 h-16 mx-auto -mt-8 bg-white border flex rounded-full">
                                                <img src={iconLogo} alt="icon_logo" className="w-8 h-8 m-auto" />
                                            </div>
                                        </div>

                                        <div className="mt-3 text-center">
                                            <div className="font-semibold text-ep-blue text-base">
                                                {CurrencyFormat(withdrawalsDetails.amountRequested)} to&nbsp;
                                                {withdrawalsDetails.employeeId.fullName}
                                            </div>
                                            <div className="mt-1 text-fade font-medium text-sm">
                                                {DateTimeFormat(withdrawalsDetails.date)}
                                            </div>
                                            <div className="mt-3">

                                                {withdrawalsDetails.status === "pending" &&
                                                    <div className="label label-yellow uppercase">
                                                        Pending
                                                    </div>
                                                }
                                                {withdrawalsDetails.status === "paid" &&
                                                    <div className="label label-green uppercase">
                                                        Paid
                                                    </div>
                                                }
                                                {withdrawalsDetails.status === "approved" &&
                                                    <div className="label label-blue uppercase">
                                                        Approved
                                                    </div>
                                                }
                                                {withdrawalsDetails.status === "rejected" &&
                                                    <div className="label label-red uppercase">
                                                        Rejected
                                                    </div>
                                                }
                                                {withdrawalsDetails.status === "cancelled" &&
                                                    <div className="label label-gray uppercase">
                                                        Cancelled
                                                    </div>
                                                }
                                                {withdrawalsDetails.status === "failed-credit" &&
                                                    <div className="label label-red uppercase">
                                                        Failed Credit
                                                    </div>
                                                }
                                                {withdrawalsDetails.status === "cut-off" &&
                                                    <div className="label label-red uppercase">
                                                        Cut-Off
                                                    </div>
                                                }

                                            </div>
                                        </div>

                                        <div className="p-6">

                                            <div>
                                                <div className="py-4 text-sm flex border-b border-gray-200">
                                                    <div className="w-8 flex-shrink-0">
                                                        <IconUser2 className="customizeSVG text-black" />
                                                    </div>
                                                    <div className="flex-grow flex justify-between">
                                                        <div>
                                                            <div className="font-semibold">
                                                                Sent To
                                                            </div>
                                                            <div className="mt-1">
                                                                {withdrawalsDetails.employeeId.jobTitle}
                                                            </div>
                                                            <div>
                                                                {withdrawalsDetails.employeeId.fullName} |&nbsp;
                                                                <span className="text-ep-blue font-semibold">
                                                                    {employeesDetails ? employeesDetails.companyInfo.companyName : ""}
                                                                </span>
                                                            </div>
                                                            <div>
                                                                {withdrawalsDetails.employeeId.phoneNumber}
                                                            </div>
                                                            <div>
                                                                {withdrawalsDetails.employeeId.email}
                                                            </div>
                                                        </div>
                                                        <div className="text-right">
                                                            <div className="text-xs font-medium">
                                                                Amount Requested
                                                            </div>
                                                            <div className="font-medium">
                                                                {CurrencyFormat(withdrawalsDetails.amountRequested)}
                                                            </div>
                                                            <div className="mt-2.5 text-xs font-medium">
                                                                Amount Paid
                                                            </div>
                                                            <div className="font-medium">
                                                                {CurrencyFormat(withdrawalsDetails.actualPaid)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="py-4 text-sm flex border-b border-gray-200">
                                                    <div className="w-8 flex-shrink-0">
                                                        <IconTicketStar className="customizeSVG text-black" />
                                                    </div>
                                                    <div className="flex-grow flex justify-between">
                                                        <div>
                                                            <div className="font-semibold">
                                                                Bank Details
                                                            </div>
                                                            <div className="mt-1">
                                                                {withdrawalsDetails.employeeId.bankName}
                                                            </div>
                                                            <div>
                                                                {withdrawalsDetails.employeeId.bankAccountNumber}
                                                            </div>
                                                            <div>
                                                                <span className="text-ep-yellow">
                                                                    Payment Reference:&nbsp;
                                                                </span>
                                                                <span className="text-ep-yellow">
                                                                    {(withdrawalsDetails.transferResponse)? withdrawalsDetails.transferResponse.reference : ""}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="text-right">
                                                            <div className="text-xs font-medium">
                                                                Transaction Fee
                                                            </div>
                                                            <div className="font-medium">
                                                                {CurrencyFormat(withdrawalsDetails.fee)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="mt-6 flex space-x-2 justify-between">
                                                <div className="flex space-x-2">

                                                    {withdrawalsDetails.status === "failed-credit" &&
                                                        <button type="button" className="btn bg-green-400 font-normal text-white text-sm">
                                                            <IconWallet className="customizeSVG mr-2" />
                                                            Retry Payment
                                                        </button>
                                                    }
                                                    
                                                    <button type="button" className="hidden btn hover:bg-gray-200 font-normal text-sm">
                                                        Download PDF
                                                        <DownloadIcon className="customizeSVG ml-2" />
                                                    </button>

                                                </div>

                                                <div>
                                                    <button type="button" className="hidden btn bg-gray-200 font-normal text-sm">
                                                        <IconUpload className="customizeSVG mr-2" />
                                                        Send Invoice
                                                    </button>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                }

                            </div>

                        </div>
                    </Dialog>
                }

                <div className="lg:flex">
                    <div className="w-full lg:w-1/2">
                        <div className="page-title">
                            Employees Dashboard
                        </div>
                    </div>
                    <div className="w-full lg:w-1/2 flex space-x-4 mt-2 lg:mt-0 lg:justify-end">
                        {(employeesDetailsLoading === false) && (employeesDetails) && (Object.keys(employeesDetails).length > 0) && 
                            <>
                                <div onClick={() => this.resendInvite(employeesDetails.email)} className="btn btn-md btn-transparent-black">
                                    Resend Invite
                                </div>
                                {(employeesDetails.disabled === true) &&
                                    <button type="button" onClick={() => this.activateEmployee(employeesDetails._id)} className="btn btn-md btn-ep-blue">
                                        Activate Employee
                                    </button>
                                }
                                {(employeesDetails.disabled === false) &&
                                    <button type="button" onClick={() => this.deactivateEmployee(employeesDetails._id)} className="btn btn-md bg-red-500 btn-red">
                                        Deactivate Employee
                                    </button>
                                }
                            </>
                        }
                    </div>
                </div>


                <div className="xl:flex pt-6 xl:space-x-10 space-y-8 xl:space-y-0">

                    <div className="w-full xl:w-2/3">

                        {/* <div className="overflow-auto">
                            <div className="flex text-center space-x-0">
                                <NavLink to={"/users/employees/details/" + employeeId} className="tab flex-grow">
                                    Earnipay
                                </NavLink>
                                <NavLink to={"/employees/wallet/" + employeeId} className="tab flex-grow">
                                    Wallet
                                </NavLink>
                            </div>
                        </div> */}

                        <div className="mt-6">
                            <div className="grid sm:grid-cols-2 gap-6">
                                <DashboardBoxes data={this.state.dashboardProps1} loading={employeeWithdrawalsLoading} result={CurrencyFormat(employeeWithdrawalsResponse.totalWithdrawalAmount)} />
                                <DashboardBoxes data={this.state.dashboardProps2} loading={employeeWithdrawalsLoading} result={CurrencyFormat(employeeWithdrawalsResponse.totalWithdrawalFees)} />
                            </div>
                        </div>


                        <div className="mt-2">
                            <div className="">

                                <div className="mt-8 page-title-mini">
                                    Withdrawals history
                                </div>

                                <div className="mt-1 table-container">
                                    <table className="table table-auto table-rounded table-border">
                                        <thead>
                                            <tr>
                                                <th>Date</th>
                                                <th>Payment ID</th>
                                                <th>Amount</th>
                                                <th>Fee</th>
                                                <th>Status</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {(employeeWithdrawalsLoading === true) && 
                                                <tr>
                                                    <td colSpan="6">
                                                        <div className="table-info">
                                                            <FontAwesomeIcon icon="spinner" spin />
                                                            <div className="font-bold uppercase">
                                                                Loading
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            }

                                            {((employeeWithdrawalsLoading === false) && (employeeWithdrawals) && (!employeeWithdrawals.docs)) && 
                                                <tr>
                                                    <td colSpan="6">
                                                        <div className="table-info">
                                                            <FontAwesomeIcon icon="unlink" />
                                                            <div className="font-bold uppercase">
                                                                An error occurred
                                                                <br />
                                                                Please try again later.
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            }

                                            {((employeeWithdrawalsLoading === false) && (employeeWithdrawals) && (employeeWithdrawals.docs) && (employeeWithdrawals.docs.length <= 0)) && 
                                                <tr>
                                                    <td colSpan="6">
                                                            
                                                        {(employeeWithdrawals.page <= employeeWithdrawals.totalPages) &&
                                                            <div className="table-info">
                                                                <FontAwesomeIcon icon="list" />
                                                                <div className="font-bold uppercase">
                                                                    No results found
                                                                </div>
                                                            </div>
                                                        }
                                                        
                                                        {(employeeWithdrawals.page > employeeWithdrawals.totalPages) &&
                                                            <div className="table-info">
                                                                <FontAwesomeIcon icon="exclamation-triangle" />
                                                                <div className="font-bold uppercase">
                                                                    Invalid Page Number
                                                                </div>
                                                                <div className="mt-2">
                                                                    <Link to="?page=1" className="btn btn-ep-blue btn-sm mx-auto">Goto Page 1</Link>
                                                                </div>
                                                            </div>
                                                        }
                                                        
                                                    </td>
                                                </tr>
                                            }

                                            {((employeeWithdrawalsLoading === false) && (employeeWithdrawals) && (employeeWithdrawals.docs) && (employeeWithdrawals.docs.length > 0)) && 
                                                employeeWithdrawals.docs.map((withdrawal, index) =>
                                                    <tr key={index}>
                                                        <td>
                                                            <div>
                                                                {DateMiniFormat(withdrawal.date)}
                                                                <br />
                                                                at {TimeFormat(withdrawal.date)}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            {(withdrawal.transferResponse) ? withdrawal.transferResponse.reference : ""}
                                                        </td>
                                                        <td>
                                                            {CurrencyFormat(withdrawal.amountRequested)}
                                                        </td>
                                                        <td>
                                                        <div>
                                                            {CurrencyFormat(withdrawal.fee)}
                                                            </div>
                                                            <div className="text-xs">
                                                                {withdrawal.feePayer === "shared" &&
                                                                    <div>
                                                                        Shared
                                                                    </div>
                                                                }
                                                                {withdrawal.feePayer === "employee" &&
                                                                    <div>
                                                                        By Employee
                                                                    </div>
                                                                }
                                                                {withdrawal.feePayer === "employer" &&
                                                                    <div>
                                                                        By Employer
                                                                    </div>
                                                                }
                                                            </div>
                                                        </td>
                                                        <td>
                                                            {withdrawal.status === "pending" &&
                                                                <div className="label label-yellow uppercase">
                                                                    Pending
                                                                </div>
                                                            }
                                                            {withdrawal.status === "paid" &&
                                                                <div className="label label-green uppercase">
                                                                    Paid
                                                                </div>
                                                            }
                                                            {withdrawal.status === "approved" &&
                                                                <div className="label label-blue uppercase">
                                                                    Approved
                                                                </div>
                                                            }
                                                            {withdrawal.status === "rejected" &&
                                                                <div className="label label-red uppercase">
                                                                    Rejected
                                                                </div>
                                                            }
                                                            {withdrawal.status === "cancelled" &&
                                                                <div className="label label-gray uppercase">
                                                                    Cancelled
                                                                </div>
                                                            }
                                                            {withdrawal.status === "failed-credit" &&
                                                                <div className="label label-red uppercase">
                                                                    Failed Credit
                                                                </div>
                                                            }
                                                            {withdrawal.status === "cut-off" &&
                                                                <div className="label label-red uppercase">
                                                                    Cut-Off
                                                                </div>
                                                            }
                                                        </td>
                                                        <td>
                                                            <div className="table-dropdown-align-top">
                                                                <Dropdown icon={dropdownTableIcon} menu={[
                                                                    {type: "div", text: "View Details", click: () => this.viewDetails(withdrawal)},
                                                                    (withdrawal.status === "failed-credit") ?
                                                                        {type: "div", text: "Retry Payment", click: () => this.retryWithdrawal(withdrawal._id)} : "",
                                                                ]} />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                            
                                        </tbody>
                                    </table>
                                </div>

                                {((employeeWithdrawalsLoading === false) && (employeeWithdrawals) && (employeeWithdrawals.docs) && (employeeWithdrawals.docs.length > 0)) && 
                                    <div>
                                        <Pagination data={employeeWithdrawals} url={this.props.location.search} limit={getAllWithdrawalsParams.limit} />
                                    </div>
                                }

                            </div>
                        </div>
                    </div>

                    <div className="w-full xl:w-1/3">
                        <div className="box">

                            {(employeesDetailsLoading === true) && 
                                <div className="table-info">
                                    <FontAwesomeIcon icon="spinner" spin />
                                    <div className="font-bold uppercase">
                                        Loading
                                    </div>
                                </div>
                            }
                            
                            {(employeesDetailsLoading === false) && (employeesDetails) && (Object.keys(employeesDetails).length > 0) && 
                                <div>

                                    <div>
                                        <div className="flex justify-between">
                                            <div className="font-recoleta font-semibold text-2xl">
                                                {employeesDetails.fullName}
                                            </div>
                                        </div>
                                        <div className="text-fade text-sm">
                                            {employeesDetails.email}
                                        </div>
                                        <div className="mt-2 flex space-x-4">
                                            <div className="flex h-6">
                                                {employeesDetails.status === "active" &&
                                                    <>
                                                        <div className="w-2 h-2 my-auto mr-2 rounded-full" style={{"backgroundColor":"#5FDCB3"}}></div>
                                                        <div className="my-auto text-sm">Enrolled</div>
                                                    </>
                                                }
                                                {employeesDetails.status === "pending" &&
                                                    <>
                                                        <div className="w-2 h-2 my-auto mr-2 rounded-full" style={{"backgroundColor":"#D3B418"}}></div>
                                                        <div className="my-auto text-sm">Pending</div>
                                                    </>
                                                }
                                            </div>
                                            <Link to={"/employees?employerId=" + employeesDetails.employerId} className="hidden btn btn-sm bg-gray-200 btn-gray-hover">
                                                View Employees
                                            </Link>
                                        </div>
                                    </div>

                                    <div className="w-full h-px mt-6 mb-3 bg-gray-200"></div>
                                    <div className="text-lg font-recoleta text-ep-blue font-semibold">
                                        Employee’s Details
                                    </div>
                                    <div className="w-full h-px mt-3 mb-6 bg-gray-200"></div>

                                    <div className="space-y-5">

                                        <div className="my-auto">
                                            <div className="font-semibold text-sm">
                                                Company Name
                                            </div>
                                            <div className="mt-1 font-medium text-sm">
                                                {employeesDetails.companyInfo.companyName}
                                            </div>
                                        </div>

                                        <div className="my-auto">
                                            <div className="font-semibold text-sm">
                                                Job Title
                                            </div>
                                            <div className="mt-1 font-medium text-sm">
                                                {employeesDetails.jobTitle}
                                            </div>
                                        </div>

                                        <div className="my-auto">
                                            <div className="font-semibold text-sm">
                                                Employee ID
                                            </div>
                                            <div className="mt-1 font-medium text-sm">
                                                {(employeesDetails.staffId) ? employeesDetails.staffId : <span className="italic">N/A</span> }
                                            </div>
                                        </div>
                                    
                                        <div className="my-auto">
                                            <div className="font-semibold text-sm">
                                                Phone Number
                                            </div>
                                            <div className="mt-1 font-medium text-sm">
                                                {employeesDetails.phoneNumber}
                                            </div>
                                        </div>

                                        <div className="my-auto">
                                            <div className="font-semibold text-sm">
                                                Net Salary
                                            </div>
                                            <div className="mt-1 font-medium text-sm">
                                                {CurrencyFormat(employeesDetails.netMonthlySalary)}
                                            </div>
                                        </div>

                                        <div className="my-auto">
                                            <div className="font-semibold text-sm">
                                                Bank Name
                                            </div>
                                            <div className="mt-1 font-medium text-sm">
                                                {employeesDetails.bankName}
                                            </div>
                                        </div>

                                        <div className="my-auto">
                                            <div className="font-semibold text-sm">
                                                Bank Account Name
                                            </div>
                                            <div className="mt-1 font-medium text-sm">
                                                {employeesDetails.bankAccountName}
                                            </div>
                                        </div>

                                        <div className="my-auto">
                                            <div className="font-semibold text-sm">
                                                Bank Account No
                                            </div>
                                            <div className="mt-1 font-medium text-sm">
                                                {employeesDetails.bankAccountNumber}
                                            </div>
                                        </div>

                                        <div className="my-auto">
                                            <div className="font-semibold text-sm">
                                                Date Added
                                            </div>
                                            <div className="mt-1 font-medium text-sm">
                                                {DateTimeFormat(employeesDetails.createdAt)}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="w-full h-px mt-6 mb-3 bg-gray-200"></div>
                                    <div className="text-lg font-recoleta text-ep-blue font-semibold">
                                        Wallet Details
                                    </div>
                                    <div className="w-full h-px mt-3 mb-6 bg-gray-200"></div>

                                    <div className="space-y-5">

                                        <div className="my-auto">
                                            <div className="font-semibold text-sm">
                                                WalletID
                                            </div>
                                            <div className="mt-1 font-medium text-sm">
                                                {employeeWallet.wallet ? employeeWallet.wallet.walletId : "N/A"}
                                            </div>
                                        </div>

                                        <div className="my-auto">
                                            <div className="font-semibold text-sm">
                                                Wallet Bank Name
                                            </div>
                                            <div className="mt-1 font-medium text-sm">
                                                {employeeWallet.wallet ? employeeWallet.wallet.bankName : "N/A"}
                                            </div>
                                        </div>

                                        <div className="my-auto">
                                            <div className="font-semibold text-sm">
                                                Wallet Account Name
                                            </div>
                                            <div className="mt-1 font-medium text-sm">
                                                {employeeWallet.wallet ? employeeWallet.wallet.accountName : "N/A"}
                                            </div>
                                        </div>

                                        <div className="my-auto">
                                            <div className="font-semibold text-sm">
                                                Wallet Bank Account
                                            </div>
                                            <div className="mt-1 font-medium text-sm">
                                                {employeeWallet.wallet ? employeeWallet.wallet.accountNumber : "N/A"}
                                            </div>
                                        </div>
                                    
                                        <div className="my-auto">
                                            <div className="font-semibold text-sm">
                                                Date Added
                                            </div>
                                            <div className="mt-1 font-medium text-sm">
                                                {employeeWallet.wallet ? employeeWallet.wallet.createdAt : "N/A"}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            }

                        </div>
                    </div>

                </div>
                
            </div>
        );
    }
}


const mapStateToProps = (state) => ({
    employeesDetailsLoading: state.entities.employees.employeesDetailsLoading,
    employeesDetails: state.entities.employees.employeesDetails,
    reloadPage: state.entities.employees.reloadPage,

    employeeWithdrawalsLoading: state.entities.withdrawals.employeeWithdrawalsLoading,
    employeeWithdrawalsResponse: state.entities.withdrawals.employeeWithdrawals,
    employeeWithdrawals: state.entities.withdrawals.employeeWithdrawals.withdrawals,

    employeeWalletLoading: state.entities.withdrawals.employeeWalletLoading,
    employeeWallet: state.entities.withdrawals.employeeWallet,
});

const mapDispatchToProps = (dispatch) => ({
    getEmployeeDetails: (id) => dispatch(employees.getEmployeeDetails(id)),
    activateEmployee: (employee_id) => dispatch(employees.activateEmployee(employee_id)),
    deactivateEmployee: (employee_id) => dispatch(employees.deactivateEmployee(employee_id)),
    resendInvite: (params) => dispatch(employees.resendInvite(params)),
    resetReloadPage: () => dispatch(employees.resetReloadPage()),

    getWithdrawalsByEmployeeId: (params) => dispatch(withdrawals.getWithdrawalsByEmployeeId(params)),
    getWalletByEmployeeId: (id, params) => dispatch(withdrawals.getWalletByEmployeeId(id, params)),
    retryWithdrawal: (withdrawal_id) => dispatch(withdrawals.retryWithdrawal(withdrawal_id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(List);