import SideModal from "components/misc/SideModal";
import useToolkit from "utils/useToolkit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SingleSplitModal = ({ control, onClose, selectedSplit }) => {
	const {
		Xclose,
		CurrencyFormat,
		DateTimeFormat,
		copyText,
	} = useToolkit();

	const split = selectedSplit;
	const isSuccessful = split?.status === "completed";
	const isPending = split?.status === "pending";
	const isDebit = split?.response?.type === 'debit';
	const isCredit = split?.response?.type === 'credit';

	const TITLE = "font-semibold mb-6 tracking-wider text-sm lg:text-md uppercase";
	const WRAPPER_CLASS = "flex items-center justify-between text-sm mb-7";
	const WRAPPER_KEY = "font-medium tracking-wide";

const amount = CurrencyFormat(split?.amount / 100)
const prevBal = CurrencyFormat(split?.response?.previousBalance / 100) || 'N/A'
const newBal = CurrencyFormat(split?.response?.newBalance / 100) || 'N/A'
const splitType = split?.response?.type ? split?.response?.type : 'N/A'


	return (
		<SideModal onClose={onClose} control={control}>
				<div className="w-full px-3 py-5">
					<div className="flex items-center justify-between pb-5">
						<p className={TITLE}>Split Instruction Details</p>
						<Xclose className="cursor-pointer mb-6" onClick={onClose} />
					</div>

					<div>
						<div className={WRAPPER_CLASS}>
							<h5 className={WRAPPER_KEY}>Amount</h5>
							<h5>{amount}</h5>
						</div>

						<div className={WRAPPER_CLASS}>
							<h5 className={WRAPPER_KEY}>Previous Balance</h5>
							<h5>{prevBal}</h5>
						</div>

						<div className={WRAPPER_CLASS}>
							<h5 className={WRAPPER_KEY}>New Balance</h5>
							<h5>{newBal}</h5>
						</div>

						<div className={WRAPPER_CLASS}>
							<h5 className={WRAPPER_KEY}>Date</h5>
							<h5>{DateTimeFormat(split?.createdAt)}</h5>
						</div>

						<div className={WRAPPER_CLASS}>
							<h5 className={WRAPPER_KEY}>Description</h5>
							<h5 className="capitalize">{split?.description}</h5>
						</div>

						<div className={WRAPPER_CLASS}>
							<h5 className={WRAPPER_KEY}>Reference</h5>
							<h5 className="capitalize">{split?.reference}</h5>
						</div>

						<div className={WRAPPER_CLASS}>
							<h5 className={WRAPPER_KEY}>User ID</h5>
							<h5 className="capitalize">{split?.userId}</h5>
						</div>

						<div className={WRAPPER_CLASS}>
							<h5 className={WRAPPER_KEY}>Wallet ID</h5>
							<h5 className="capitalize">{split?.walletId}</h5>
						</div>

						<div className={WRAPPER_CLASS}>
							<h5 className={WRAPPER_KEY}>Webhook URL</h5>
							<h5 className="flex items-center">
								{split?.webhookUrl?.substring(0, 30)}...
								<span className="ml-1 text-ep-blue-deeper">
									<FontAwesomeIcon
										onClick={() =>
											copyText(split?.webhookUrl, "Webhook URL")
										}
										icon="copy"
										className="text-lg cursor-pointer"
									/>
								</span>
							</h5>
						</div>

						<div className={WRAPPER_CLASS}>
							<h5 className={WRAPPER_KEY}>Type</h5>
							<h5
								className={`px-2.5 py-1 rounded-md capitalize ${
									isDebit ? "text-white bg-red-500" : isCredit ? "text-white bg-green-500" : 'text-black'
								}`}>
								{splitType}
							</h5>
						</div>

						<div className={WRAPPER_CLASS}>
							<h5 className={WRAPPER_KEY}>Status</h5>
							<h5
								className={`px-2.5 py-1 rounded-md capitalize text-white ${
									isSuccessful ? "bg-green-500" : isPending ? 'bg-gray-400' : "bg-red-500"
								}`}>
								{split?.status}
							</h5>
						</div>
					</div>
				</div>
		</SideModal>
	);
};

export default SingleSplitModal;
