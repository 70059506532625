import Maybe from "components/__new/common/Maybe";

export function FormDrawer(props) {
  const { children, size, display, isOpen, setIsOpen, closeDialog, backdropClickable } = props;
  let sizeRender = size || "lg";  
  let isBackdropClickable = backdropClickable ?? true;
  

  let displayClassBackdrop = "";
  let displayClassModal = "";
  let displayClassModalContent = "";
  if (display && display === "center"){
    displayClassBackdrop = "fixed overflow-hidden z-30 bg-gray-900 bg-opacity-25 inset-0 transform " + (isOpen ? "opacity-100 translate-y-0": "opacity-0 translate-y-full");
    displayClassModal = "w-screen max-w-"+(sizeRender)+" absolute top-1/2 right-1/2 bg-white h-max rounded-xl shadow-xl transform translate-x-1/2  " + (isOpen ? "-translate-y-1/2 " : "translate-y-full");
    // displayClassModalContent = "relative w-screen max-w-[800px] flex flex-col space-y-6 overflow-y-scroll h-full";
    displayClassModalContent = "relative w-screen max-w-"+(sizeRender)+" flex flex-col space-y-6 overflow-y-scroll h-full";
  }
  else{
    displayClassBackdrop = "fixed overflow-hidden z-30 bg-gray-900 bg-opacity-25 inset-0 transform " + (isOpen ? "transition-opacity opacity-100 translate-x-0": "opacity-0 translate-x-full");
    displayClassModal = "w-screen max-w-"+(sizeRender)+" right-0 absolute bg-white h-full shadow-xl transform  " + (isOpen ? "translate-x-0 " : "translate-x-full");
    displayClassModalContent = "relative w-screen max-w-"+(sizeRender)+" flex flex-col space-y-6 overflow-y-scroll h-full";
  }
  
  
  return (
    <div className={displayClassBackdrop}>
      <div className={displayClassModal}>
        <div className={displayClassModalContent}>
          {children}
        </div>
      </div>

      <Maybe condition={isBackdropClickable === true}>
        <Maybe condition={closeDialog !== undefined}>
          <div
            className=" w-screen h-full "
            onClick={() => {
              closeDialog();
            }}
          ></div>
        </Maybe>

        <Maybe condition={closeDialog === undefined}>
          <div
            className=" w-screen h-full "
            onClick={() => {
              setIsOpen(false);
            }}
          ></div>
        </Maybe>
      </Maybe>

    </div>
  );
}

export default FormDrawer;