import CenterModal from 'components/misc/CenterModal'
import { ReactComponent as IconCheck } from "assets/images/icons/project-icons/circle-check.svg";
import React from 'react'

const ApproveRequestModal = ({control, onClose, onProceed}) => {
    return (
        <CenterModal control={control} onClose={onClose}>
            <div className='flex items-center justify-center flex-col py-4'>
                <IconCheck />
                <div className="text-center pt-5">
                    <p className="mb-2 font-semibold">Approve this Request</p>
                    <p className="text-gray-500">Are you sure you want to approve this request?</p>
                </div>
                
                <div className="flex items-center justify-center pt-6 w-full">
                    <button type="button" className="btn btn-md btn-transparent-black lg:px-14" onClick={onClose}>
                        Cancel
                    </button>
                    
                    <button type="button" className="btn btn-md bg-blue-500 text-white ml-3 lg:px-14" onClick={onProceed}>
                        Yes
                    </button>
                </div>
            </div>
        </CenterModal>
    )
}

export default ApproveRequestModal