import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import ContentLoading from "../common/ContentLoading";


const RewardsTab = () => {
    const { rewardPointsLoading, rewardPoints, rewardsLoading, rewards, redeemedRewardsLoading, redeemedRewards, getPointsHistoryLoading, getPointsHistoryData } = useSelector((s) => s.entities.rewards);

    return (
        <div className="mt-6 w-full overflow-auto">
            <div className="h-9 flex">
                <NavLink to="/rewards/points" className="cursor-pointer page-nav-blue">
                    <div className="whitespace-nowrap">
                        Point Activity
                        (<ContentLoading
                            loading={rewardPointsLoading}
                            data={rewardPoints?.data?.totalDocs ?? "-"}
                        />)
                    </div>
                </NavLink>
                <NavLink to="/rewards/gift-points-history" className="cursor-pointer page-nav-blue">
                    <div className="whitespace-nowrap">
                        Gift Points History
                        (<ContentLoading
                            loading={getPointsHistoryLoading}
                            data={getPointsHistoryData?.data?.totalDocs ?? "-"}
                        />)
                    </div>
                </NavLink>
                <NavLink to="/rewards/rewards" className="cursor-pointer page-nav-blue">
                    <div className="whitespace-nowrap">
                        Rewards
                        (<ContentLoading
                            loading={rewardsLoading}
                            data={rewards?.data?.totalDocs ?? "-"}
                        />)
                    </div>
                </NavLink>
                <NavLink to="/rewards/redemption-history" className="cursor-pointer page-nav-blue">
                    <div className="whitespace-nowrap">
                        Redemption History
                        (<ContentLoading
                            loading={redeemedRewardsLoading}
                            data={redeemedRewards?.data?.totalDocs ?? "-"}
                        />)
                    </div>
                </NavLink>
            </div>
        </div>
    )
}


export default RewardsTab;