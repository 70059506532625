import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TabSettingsWide, TabSettingsMobile } from "components/common/settingsTab";
import { ButtonLoader, Input, SelectInput, TabSelector } from "components/__new/common/form";
import { PageContent } from "components/__new/common/PageContent";
import { toast } from "react-toastify";
import Maybe from "components/__new/common/Maybe";
import useLoginInfo from "hooks/useLoginInfo";
import * as settingsAction from "store/entities/settings/action";


const List = () => {
	const dispatch = useDispatch();
    const {userInfo} = useLoginInfo();
    const { billPaymentSettingsLoading, billPaymentSettings, billPaymentSettingLoading, billPaymentSetting, billPaymentSettingDeleteLoading, billPaymentSettingDelete } = useSelector((s) => s.entities.settings);    

	const [transferType, setTransferType] = useState([
        { label: "Airtime", value: "airtime", selected: true },
        { label: "Data/Internet", value: "data", selected: false },
        { label: "Electricity/Power", value: "electricity", selected: false },
        { label: "TV services", value: "tv", selected: false },
    ])

	const [form, setForm] = useState({
		minAmount: "",
        maxAmount: "",
		type: "fixed",
        value: "",
        hasSavedData: false,
    })

	const updateTransferType = (type) => {
        const newTransferType = transferType.map((qa) => {
            if (qa.value === type){
                return {...qa, selected: true}
            }
            else{
                return {...qa, selected: false}
            }
        });
        setTransferType(newTransferType);
    }

	const getActiveTransferType = () => {
        const newTransferType = transferType.filter((type) => type.selected === true);
        return(newTransferType[0]?.value || "");
    }

	const getBillPaymentSettings = () => {
        dispatch(settingsAction.getBillPaymentSettings({limit: 10}));
    }

	const valueToNairaAndKobo = (value, type, action) => {
		if (type === "fixed"){
			if (action === "to-kobo"){
				return (parseInt(value) * 100);
			}
			else if (action === "to-naira"){
				return (parseInt(value) / 100);
			}
		}
		else{
			return (value);
		}
	}

	const populateForm = () => {
		const category = getActiveTransferType();
		const allSettings = billPaymentSettings?.data?.data?.docs || [];
		const data = allSettings.filter(setting => setting.category === category);

		if (data.length > 0){
			setForm({
				id: data[0]._id || "",
				minAmount: data[0] ? (parseInt(data[0]?.minAmount) / 100) : "",
				maxAmount: data[0] ? (parseInt(data[0]?.maxAmount) / 100) : "",
				type: data[0]?.type || "",
				value: valueToNairaAndKobo(data[0]?.value, data[0]?.type, "to-naira") || "",
				hasSavedData: true,
			})
		}
		else{
			setForm({
				id: "",
				minAmount: "",
        		maxAmount: "",
				type: "",
				value: "",
				hasSavedData: false,
			})
		}
	}

	const deleteDiscount = () => {
        dispatch(settingsAction.deleteBillPaymentSettings(form.id));
    }

	const submitForm = async (e) => {
        e.preventDefault();
		let errors = 0;

		if (form.type === ""){
			toast.error("Please select a discount type");
			errors++;
		}
		if (form.value === ""){
			toast.error("Please enter a discount value");
			errors++;
		}
		if (hasPermission() === false){
			toast.error("Access denied");
			errors++;
		}

		if (errors === 0){
			const params = {
				category: getActiveTransferType(),
				minAmount: parseInt(form.minAmount) * 100,
        		maxAmount: parseInt(form.maxAmount) * 100,
				type: form.type,
				value: valueToNairaAndKobo(form.value, form.type, "to-kobo"),
			};
			// console.log("form", form);
			// console.log("params", params);
			if (form.hasSavedData === true){
				dispatch(settingsAction.updateBillPaymentSettings(form.id, params));
			}
			else{
				dispatch(settingsAction.createBillPaymentSettings(params));
			}
		}
    }

	const hasPermission = () => {
		if (userInfo.userType === "super-admin" || userInfo.userType === "financial-admin"){
			return true;
		}
		else{
			return false;
		}
	}

	useEffect(() => {
        getBillPaymentSettings();
        // eslint-disable-next-line
    }, [])

	useEffect(() => {
        populateForm();
        // eslint-disable-next-line
    }, [transferType, billPaymentSettings])

	useEffect(() => {
		if (billPaymentSetting?.status === "sucess" || billPaymentSettingDelete?.status === "success"){
			getBillPaymentSettings();
			dispatch(settingsAction.resetBillPaymentSettings());
		}
        // eslint-disable-next-line
    }, [billPaymentSetting, billPaymentSettingDelete])


	return (
		<div>
			<TabSettingsMobile />

			<div className="box">
				<div className="flex">
					<TabSettingsWide />

					<div className="lg:w-3/4 xl:w-4/5 lg:pl-6 flex-grow">
						<div className="grid sm:grid-cols-2">
							<div className="font-bold text-2xl">Bill Payment Settings</div>
							<div></div>
						</div>

						<div className="mt-6">
								
							<Maybe condition={billPaymentSettingsLoading === true}>
								<PageContent loading={billPaymentSettingsLoading} loadingText="Loading settings" />
							</Maybe>


							<Maybe condition={billPaymentSettingsLoading === false && billPaymentSettings?.status === "sucess"}>
								<TabSelector selectOptions={transferType} onValueChange={(e) => updateTransferType(e)} />

								<form onSubmit={submitForm} className="mt-6">
									<div className="font-medium mb-4">
										Eligibility Range
									</div>

									<div className="sm:flex sm:flex-wrap">
										<div className="w-48 mr-4">
											<Input type="text" label="Min Amount (₦)" placeholder="" value={form.minAmount} onChange={(e) => setForm({...form, minAmount: e.target.value})} disabled={!hasPermission()} />
										</div>
										<div className="w-48 mr-4">
											<Input type="text" label="Max Amount (₦)" placeholder="" value={form.maxAmount} onChange={(e) => setForm({...form, maxAmount: e.target.value})} disabled={!hasPermission()} />
										</div>
									</div>

									<div className="block sm:hidden pt-6 mt-2 border-t"></div>

									<div className="sm:flex sm:flex-wrap">
										<div className="w-48 mr-4">
											<div>
												<SelectInput label="Cashback" required={true} value={form.type} onChange={(e) => setForm({...form, type: e.target.value})} disabled={!hasPermission()}>
													<option value="" disabled>- Select -</option>
													<option value="fixed">Fixed</option>
													<option value="percentage">Percentage</option>
												</SelectInput>
											</div>
										</div>
										<div className="w-48 mr-4">
											<Input type="text" label={<span className="hidden sm:block">&nbsp;</span>} placeholder="" value={form.value} onChange={(e) => setForm({...form, value: e.target.value})} disabled={!hasPermission()} />
										</div>
									</div>

									<div className="block sm:hidden pt-6 mt-2 border-t"></div>

									<Maybe condition={hasPermission() === true}>
										<div className="sm:mt-2 flex space-x-4">
											<ButtonLoader type="submit" loading={billPaymentSettingLoading} className="btn btn-ep-blue" style={{paddingLeft: "2rem", paddingRight: "2rem"}}>
												<span className="">Save</span>
											</ButtonLoader>

											<Maybe condition={form.hasSavedData === true}>
												<ButtonLoader type="button" onClick={deleteDiscount} loading={billPaymentSettingDeleteLoading} className="btn text-white bg-red-500" style={{paddingLeft: "2rem", paddingRight: "2rem"}}>
													<span className="">Delete</span>
												</ButtonLoader>
											</Maybe>
										</div>
									</Maybe>

								</form>
							</Maybe>
							

						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default List;
