import ObjectToQueryString from "utils/ObjectToQueryString";
import * as actions from "./actionTypes";
import * as config from "config";

export function getAllGiftPointsHistory(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetAllPointsHistory + ObjectToQueryString(params),
            method: "get",
            data: {},
            onStart: actions.GET_ALL_POINTS_HISTORY_START,
            onSuccess: actions.GET_ALL_POINTS_HISTORY_DONE,
            onError: actions.GET_ALL_POINTS_HISTORY_FAILED,
        },
    }
}

export function approveGiftPoint(id) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiApproveGiftPoint + id + "/approve",
            method: "patch",
            data: {},
            onStart: actions.APPROVE_GIFT_POINT_START,
            onSuccess: actions.APPROVE_GIFT_POINT_DONE,
            onError: actions.APPROVE_GIFT_POINT_FAILED,
        },
    }
}

export function rejectGiftPoint(payload) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiApproveGiftPoint + payload.id + "/reject",
            method: "patch",
            data: {reason: payload.reason},
            onStart: actions.REJECT_GIFT_POINT_START,
            onSuccess: actions.REJECT_GIFT_POINT_DONE,
            onError: actions.REJECT_GIFT_POINT_FAILED,
        },
    }
}

export function downloadPointsHistory(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetAllPointsHistory + ObjectToQueryString(params),
            method: "get",
            data: {},
            onStart: actions.DOWNLOAD_POINTS_HISTORY_START,
            onSuccess: actions.DOWNLOAD_POINTS_HISTORY_DONE,
            onError: actions.DOWNLOAD_POINTS_HISTORY_FAILED,
        },
    }
}

export function getRewardPoints(params) {
    let endpointUrl = config.apiRewardPoints;
    if (Object.keys(params).length > 2){
        endpointUrl = config.apiRewardPointsSearchAndFilter;
    }
    return {
        type: config.apiRequestStart,
        payload: {
            // url: config.apiRewardPoints + ObjectToQueryString(params),
            // url: config.apiRewardPointsSearchAndFilter + ObjectToQueryString(params),
            url: endpointUrl + ObjectToQueryString(params),
            method: "get",
            data: {},
            onStart: actions.GET_REWARD_POINTS_START,
            onSuccess: actions.GET_REWARD_POINTS,
            onError: actions.GET_REWARD_POINTS_FAILED,
        },
    }
}

export function downloadRewardPoints(params) {
    let endpointUrl = config.apiRewardPoints;
    if (Object.keys(params).length > 2){
        endpointUrl = config.apiRewardPointsSearchAndFilter;
    }
    return {
        type: config.apiRequestStart,
        payload: {
            // url: config.apiRewardPoints + ObjectToQueryString(params),
            // url: config.apiRewardPointsSearchAndFilter + ObjectToQueryString(params),
            url: endpointUrl + ObjectToQueryString(params),
            method: "get",
            data: {},
            onStart: actions.DOWNLOAD_REWARD_POINTS_START,
            onSuccess: actions.DOWNLOAD_REWARD_POINTS,
            onError: actions.DOWNLOAD_REWARD_POINTS_FAILED,
        },
    }
}

export function createRewardPoints(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiRewardPoints,
            method: "post",
            data: params,
            onStart: actions.CREATE_REWARD_POINTS_START,
            onSuccess: actions.CREATE_REWARD_POINTS,
            onError: actions.CREATE_REWARD_POINTS_FAILED,
        },
    }
}

export function resetCreateRewardPoints() {
    return {
        type: actions.RESET_CREATE_REWARD_POINTS,
        payload: {},
    }
}

export function updateRewardPoints(id, params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiRewardPoints + "/" + id,
            method: "patch",
            data: params,
            onStart: actions.UPDATE_REWARD_POINTS_START,
            onSuccess: actions.UPDATE_REWARD_POINTS,
            onError: actions.UPDATE_REWARD_POINTS_FAILED,
        },
    }
}

export function resetUpdateRewardPoints() {
    return {
        type: actions.RESET_UPDATE_REWARD_POINTS,
        payload: {},
    }
}

export function enableRewardPoints(id) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiRewardPoints + "/" + id + "/enable",
            method: "patch",
            data: {},
            onStart: actions.ENABLE_REWARD_POINTS_START,
            onSuccess: actions.ENABLE_REWARD_POINTS,
            onError: actions.ENABLE_REWARD_POINTS_FAILED,
        },
    }
}

export function disableRewardPoints(id) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiRewardPoints + "/" + id + "/disable",
            method: "patch",
            data: {},
            onStart: actions.DISABLE_REWARD_POINTS_START,
            onSuccess: actions.DISABLE_REWARD_POINTS,
            onError: actions.DISABLE_REWARD_POINTS_FAILED,
        },
    }
}

export function approvePointActivity(id) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiApprovePointActivity + id + "/approve",
            method: "patch",
            data: {},
            onStart: actions.APPROVE_POINT_ACTIVITY_START,
            onSuccess: actions.APPROVE_POINT_ACTIVITY_DONE,
            onError: actions.APPROVE_POINT_ACTIVITY_FAILED,
        },
    }
}

export function deleteRewardPoints(id) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiRewardPoints + "/" + id,
            method: "delete",
            data: {},
            onStart: actions.DELETE_REWARD_POINTS_START,
            onSuccess: actions.DELETE_REWARD_POINTS,
            onError: actions.DELETE_REWARD_POINTS_FAILED,
        },
    }
}

export function assignRewardPoints(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiRewardPoints + "/gift-user",
            method: "post",
            data: params,
            onStart: actions.ASSIGN_REWARD_POINTS_START,
            onSuccess: actions.ASSIGN_REWARD_POINTS,
            onError: actions.ASSIGN_REWARD_POINTS_FAILED,
        },
    }
}

export function resetAssignRewardPoints() {
    return {
        type: actions.ASSIGN_REWARD_POINTS_RESET,
        payload: {},
    }
}

export function getRewards(params) {
    let endpointUrl = config.apiRewards + "/all-rewards";
    if (Object.keys(params).length > 2){
        endpointUrl = config.apiRewardsSearchAndFilter;
    }
    return {
        type: config.apiRequestStart,
        payload: {
            // url: config.apiRewards + "/all-rewards" + ObjectToQueryString(params),
            // url: config.apiRewardsSearchAndFilter + ObjectToQueryString(params),
            url: endpointUrl + ObjectToQueryString(params),
            method: "get",
            data: {},
            onStart: actions.GET_REWARDS_START,
            onSuccess: actions.GET_REWARDS,
            onError: actions.GET_REWARDS_FAILED,
        },
    }
}

export function downloadRewards(params) {
    let endpointUrl = config.apiRewards + "/all-rewards";
    if (Object.keys(params).length > 2){
        endpointUrl = config.apiRewardsSearchAndFilter;
    }
    return {
        type: config.apiRequestStart,
        payload: {
            // url: config.apiRewards + "/all-rewards" + ObjectToQueryString(params),
            // url: config.apiRewardsSearchAndFilter + ObjectToQueryString(params),
            url: endpointUrl + ObjectToQueryString(params),
            method: "get",
            data: {},
            onStart: actions.DOWNLOAD_REWARDS_START,
            onSuccess: actions.DOWNLOAD_REWARDS,
            onError: actions.DOWNLOAD_REWARDS_FAILED,
        },
    }
}

export function createReward(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiRewards,
            method: "post",
            data: params,
            onStart: actions.CREATE_REWARD_START,
            onSuccess: actions.CREATE_REWARD,
            onError: actions.CREATE_REWARD_FAILED,
        },
    }
}

export function resetCreateReward() {
    return {
        type: actions.CREATE_REWARD_RESET,
        payload: {},
    }
}

export function uploadPromoCode(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiRewards + "/upload-reward-codes",
            method: "post",
            data: params,
            onStart: actions.UPLOAD_PROMO_CODE_START,
            onSuccess: actions.UPLOAD_PROMO_CODE,
            onError: actions.UPLOAD_PROMO_CODE_FAILED,
        },
    }
}

export function resetUploadPromoCode() {
    return {
        type: actions.UPLOAD_PROMO_CODE_RESET,
        payload: {},
    }
}

export function updateReward(id, params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiRewards + "/" + id,
            method: "patch",
            data: params,
            onStart: actions.UPDATE_REWARD_START,
            onSuccess: actions.UPDATE_REWARD,
            onError: actions.UPDATE_REWARD_FAILED,
        },
    }
}

export function resetUpdateReward() {
    return {
        type: actions.UPDATE_REWARD_RESET,
        payload: {},
    }
}

export function enableReward(id) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiRewards + "/" + id + "/enable",
            method: "patch",
            data: {},
            onStart: actions.ENABLE_REWARD_START,
            onSuccess: actions.ENABLE_REWARD,
            onError: actions.ENABLE_REWARD_FAILED,
        },
    }
}

export function disableReward(id) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiRewards + "/" + id + "/disable",
            method: "patch",
            data: {},
            onStart: actions.DISABLE_REWARD_START,
            onSuccess: actions.DISABLE_REWARD,
            onError: actions.DISABLE_REWARD_FAILED,
        },
    }
}

export function approveReward(id) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiApproveReward + id + "/approval",
            method: "patch",
            data: {},
            onStart: actions.APPROVE_REWARD_START,
            onSuccess: actions.APPROVE_REWARD_DONE,
            onError: actions.APPROVE_REWARD_FAILED,
        },
    }
}

export function getRedeemedRewards(params) {
    let endpointUrl = config.apiRewards + "/redeemed-rewards";
    if (Object.keys(params).length > 2){
        endpointUrl = config.apiRedeemedRewardsSearchAndFilter;
    }
    return {
        type: config.apiRequestStart,
        payload: {
            // url: config.apiRewards + "/redeemed-rewards" + ObjectToQueryString(params),
            // url: config.apiRedeemedRewardsSearchAndFilter + ObjectToQueryString(params),
            url: endpointUrl + ObjectToQueryString(params),
            method: "get",
            data: {},
            onStart: actions.GET_REDEEMED_REWARDS_START,
            onSuccess: actions.GET_REDEEMED_REWARDS,
            onError: actions.GET_REDEEMED_REWARDS_FAILED,
        },
    }
}

export function downloadRedeemedRewards(params) {
    let endpointUrl = config.apiRewards + "/redeemed-rewards";
    if (Object.keys(params).length > 2){
        endpointUrl = config.apiRedeemedRewardsSearchAndFilter;
    }
    return {
        type: config.apiRequestStart,
        payload: {
            // url: config.apiRewards + "/redeemed-rewards" + ObjectToQueryString(params),
            // url: config.apiRedeemedRewardsSearchAndFilter + ObjectToQueryString(params),
            url: endpointUrl + ObjectToQueryString(params),
            method: "get",
            data: {},
            onStart: actions.DOWNLOAD_REDEEMED_REWARDS_START,
            onSuccess: actions.DOWNLOAD_REDEEMED_REWARDS,
            onError: actions.DOWNLOAD_REDEEMED_REWARDS_FAILED,
        },
    }
}

export function getRewardMetrics() {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiRewardMetrics,
            method: "get",
            data: {},
            onStart: actions.GET_REWARD_METRICS_START,
            onSuccess: actions.GET_REWARD_METRICS,
            onError: actions.GET_REWARD_METRICS_FAILED,
        },
    }
}
