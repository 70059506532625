import { toast } from "react-toastify";
import * as actions from "./actionTypes";
import * as config from "config";

export function loginUser(email, password) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiLoginUser,
            method: "post",
            data: {
                email: email,
                password: password
            },
            onStart: actions.LOGIN_USER_START,
            onSuccess: actions.LOGIN_USER,
            onError: actions.LOGIN_USER_FAILED
        },
    }
}

export function forgotPassword(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiForgotPassword,
            method: "post",
            data: params,
            onStart: actions.FORGOT_PASSWORD_START,
            onSuccess: actions.FORGOT_PASSWORD,
            onError: actions.FORGOT_PASSWORD_FAILED
        }
    }
}

export function resetForgotPassword() {
    return {
        type: actions.FORGOT_PASSWORD_RESET,
        payload: {},
    }
}

export function resetPassword(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiResetPassword,
            method: "post",
            data: {
                email: params.email,
                resetPin: params.pin,
                password: params.password,
                password_confirmation: params.confirmPassword,
            },
            onStart: actions.RESET_PASSWORD_START,
            onSuccess: actions.RESET_PASSWORD,
            onError: actions.RESET_PASSWORD_FAILED
        }
    }
}

export function resetResetPassword() {
    return {
        type: actions.RESET_PASSWORD_RESET,
        payload: {},
    }
}

export function logoutUser() {
    toast("Logging out, please wait");
    
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiLogoutUser,
            method: "post",
            data: {},
            onStart: actions.LOGOUT_USER_START,
            onSuccess: actions.LOGOUT_USER,
            onError: actions.LOGOUT_USER
        }
    }

    // return {
    //     type: actions.LOGOUT_USER,
    //     payload: {}
    // }
}
