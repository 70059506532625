import * as actions from "./actionTypes";
import * as config from "config";
import ObjectToQueryString from 'utils/ObjectToQueryString';


export function generateVirtualAccount(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGenerateEVA,
            method: "post",
            data: params,
            onStart: actions.GENERATE_ERA_START,
            onSuccess: actions.GENERATE_ERA,
            onError: actions.GENERATE_ERA_FAILED,
        },
    }
}

export function resetCustomerFaceIdStatus(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiResetCustomerFaceId,
            method: "post",
            data: params,
            onStart: actions.TOGGLE_CUSTOMER_KYC_START,
            onSuccess: actions.TOGGLE_CUSTOMER_KYC_DONE,
            onError: actions.TOGGLE_CUSTOMER_KYC_FAILED,
        },
    }
}

export function manualUpdateUserDetails(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.apiUpdateCustomerFullname}/${params.id}`,
            method: "patch",
            data: params?.payload,
            onStart: actions.USER_DETAILS_MANUAL_UPDATE_START,
            onSuccess: actions.USER_DETAILS_MANUAL_UPDATE_DONE,
            onError: actions.USER_DETAILS_MANUAL_UPDATE_FAILED,
        },
    }
}

export function manualUpdateCustomersDetails(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiUserDetailsManualUpload,
            method: "post",
            data: params,
            onStart: actions.USER_DETAILS_MANUAL_UPDATE_START,
            onSuccess: actions.USER_DETAILS_MANUAL_UPDATE_DONE,
            onError: actions.USER_DETAILS_MANUAL_UPDATE_FAILED,
        },
    }
}

export function rejectCustomerAddressDoc(payload) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiRejectCustomerAddressDoc,
            method: "post",
            data: payload,
            onStart: actions.TOGGLE_CUSTOMER_KYC_START,
            onSuccess: actions.TOGGLE_CUSTOMER_KYC_DONE,
            onError: actions.TOGGLE_CUSTOMER_KYC_FAILED,
        },
    }
}

export function resetAddressRejectionStatus() {
    return {
        type: actions.RESET_ADDRESS_REJECTION_STATUS,
        payload: {},
    }
}

export function searchAndFilterCustomerTransactions(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiWalletBaseUrl,
            url: `${config.apiSearchAndFilterCustomerTransactions}/${params?.id}` + ObjectToQueryString(params?.payload),
            method: "get",
            data: params,
            onStart: actions.GET_CUSTOMER_TRANSACTIONS_START,
            onSuccess: actions.GET_CUSTOMER_TRANSACTIONS_DONE,
            onError: actions.GET_CUSTOMER_TRANSACTIONS_FAILED,
        },
    }
}


export function rejectCustomerFaceId(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiRejectCustomerFaceId,
            method: "post",
            data: params,
            onStart: actions.TOGGLE_CUSTOMER_KYC_START,
            onSuccess: actions.TOGGLE_CUSTOMER_KYC_DONE,
            onError: actions.TOGGLE_CUSTOMER_KYC_FAILED,
        },
    }
}

export function getCustomerTransactions(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiWalletBaseUrl,
            url: `${config.apiGetCustomerTransactions}/${params?.id}` + ObjectToQueryString(params?.payload),
            method: "get",
            data: params,
            onStart: actions.GET_CUSTOMER_TRANSACTIONS_START,
            onSuccess: actions.GET_CUSTOMER_TRANSACTIONS_DONE,
            onError: actions.GET_CUSTOMER_TRANSACTIONS_FAILED,
        }
    }
}

export function getCustomerTransactionsMetrics(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiWalletBaseUrl,
            url: `${config.apiGetCustomerTransactionsMetrics}/${params}`,
            method: "get",
            data: params,
            onStart: actions.GET_CUSTOMER_TRANSACTIONS_METRICS_START,
            onSuccess: actions.GET_CUSTOMER_TRANSACTIONS_METRICS_DONE,
            onError: actions.GET_CUSTOMER_TRANSACTIONS_METRICS_FAILED,
        },
    }
}

export function downloadCustomerTransactions(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiWalletBaseUrl,
            url: `${config.apiGetCustomerTransactions}/${params?.id}` + ObjectToQueryString(params?.payload),
            method: "get",
            data: params,
            onStart: actions.DOWNLOAD_CUSTOMER_TRANSACTIONS_START,
            onSuccess: actions.DOWNLOAD_CUSTOMER_TRANSACTIONS_DONE,
            onError: actions.DOWNLOAD_CUSTOMER_TRANSACTIONS_FAILED,
        },
    }
}


export function resetCustomerBvn(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiResetCustomerBvn,
            method: "post",
            data: params,
            onStart: actions.TOGGLE_CUSTOMER_KYC_START,
            onSuccess: actions.TOGGLE_CUSTOMER_KYC_DONE,
            onError: actions.TOGGLE_CUSTOMER_KYC_FAILED,
        },
    }
}

export function downloadAllCustomers(params) {
    let endpointUrl = config.apiGetAllCustomers;
    if (Object.keys(params).length > 2){
        endpointUrl = config.apiSearchAndFilterCustomers;
    }

    return {
        type: config.apiRequestStart,
        payload: {
            url: endpointUrl + ObjectToQueryString({...params, download: true}),
            method: "get",
            data: params,
            onStart: actions.DOWNLOAD_ALL_CUSTOMERS_START,
            onSuccess: actions.DOWNLOAD_ALL_CUSTOMERS_DONE,
            onError: actions.DOWNLOAD_ALL_CUSTOMERS_FAILED,
        },
    }
}

export function selectCustomer(payload) {
    return {
        type: actions.SELECT_CUSTOMER,
        payload,
    }
}

export function searchAndFilterCustomers(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiSearchAndFilterCustomers + ObjectToQueryString(params),
            method: "get",
            data: params,
            onStart: actions.GET_ALL_CUSTOMERS_START,
            onSuccess: actions.GET_ALL_CUSTOMERS_DONE,
            onError: actions.GET_ALL_CUSTOMERS_FAILED,
        },
    }
}

export function getAllCustomers(params) {

    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetAllCustomers + ObjectToQueryString(params),
            method: "get",
            data: params,
            onStart: actions.GET_ALL_CUSTOMERS_START,
            onSuccess: actions.GET_ALL_CUSTOMERS_DONE,
            onError: actions.GET_ALL_CUSTOMERS_FAILED,
        },
    }
}

export function getSingleCustomer(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetSingleCustomer + params?._id,
            method: "get",
            data: params,
            onStart: actions.GET_SINGLE_CUSTOMER_START,
            onSuccess: actions.GET_SINGLE_CUSTOMER_DONE,
            onError: actions.GET_SINGLE_CUSTOMER_FAILED,
        },
    }
}

export function approveCustomer(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.apiToggleCustomerStatus}${params?.id}?action=approved`,
            method: "patch",
            data: {},
            onStart: actions.TOGGLE_CUSTOMER_STATUS_START,
            onSuccess: actions.TOGGLE_CUSTOMER_STATUS_DONE,
            onError: actions.TOGGLE_CUSTOMER_STATUS_FAILED,
        },
    }
}

export function toggleCustomerStatus(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: `${config.apiToggleCustomerStatus}${params?.id}?action=${params?.action}`,
            method: "patch",
            data: {comment: params?.reason},
            onStart: actions.TOGGLE_CUSTOMER_STATUS_START,
            onSuccess: actions.TOGGLE_CUSTOMER_STATUS_DONE,
            onError: actions.TOGGLE_CUSTOMER_STATUS_FAILED,
        },
    }
}

export function getAllEmployees(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetEmployees,
            method: "post",
            data: params,
            onStart: actions.GET_ALL_EMPLOYEES_START,
            onSuccess: actions.GET_ALL_EMPLOYEES,
            onError: actions.GET_ALL_EMPLOYEES_FAILED,
            // useLoginJwt: false,
        },
    }
}

export function downloadGetAllEmployees(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetEmployees,
            method: "post",
            data: params,
            onStart: actions.DOWNLOAD_GET_ALL_EMPLOYEES_START,
            onSuccess: actions.DOWNLOAD_GET_ALL_EMPLOYEES,
            onError: actions.DOWNLOAD_GET_ALL_EMPLOYEES_FAILED,
        },
    }
}

export function getAllEmployeesCount() {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetEmployeesCount,
            method: "get",
            data: {},
            onStart: actions.GET_ALL_EMPLOYEES_COUNT_START,
            onSuccess: actions.GET_ALL_EMPLOYEES_COUNT,
            onError: actions.GET_ALL_EMPLOYEES_COUNT_FAILED,
            // useLoginJwt: false,
        },
    }
}

export function getEnrolledEmployeesCount(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetEmployees,
            method: "post",
            data: params,
            onStart: actions.GET_ENROLLED_EMPLOYEES_COUNT_START,
            onSuccess: actions.GET_ENROLLED_EMPLOYEES_COUNT,
            onError: actions.GET_ENROLLED_EMPLOYEES_COUNT_FAILED,
            // useLoginJwt: false,
        },
    }
}

export function getUnenrolledEmployeesCount(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetEmployees,
            method: "post",
            data: params,
            onStart: actions.GET_UNENROLLED_EMPLOYEES_COUNT_START,
            onSuccess: actions.GET_UNENROLLED_EMPLOYEES_COUNT,
            onError: actions.GET_UNENROLLED_EMPLOYEES_COUNT_FAILED,
            // useLoginJwt: false,
        },
    }
}

export function getAllEmployeesWithdrawOnceOrMore(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiEmployeeWithdrawalsOnceOrMore + ObjectToQueryString(params),
            method: "get",
            data: {},
            onStart: actions.GET_EMPLOYEES_WITHDRAW_ONCE_OR_MORE_START,
            onSuccess: actions.GET_EMPLOYEES_WITHDRAW_ONCE_OR_MORE,
            onError: actions.GET_EMPLOYEES_WITHDRAW_ONCE_OR_MORE_FAILED,
        },
    }
}

export function downloadGetAllEmployeesWithdrawOnceOrMore(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiEmployeeWithdrawalsOnceOrMore + ObjectToQueryString(params),
            method: "get",
            data: {},
            onStart: actions.DOWNLOAD_GET_EMPLOYEES_WITHDRAW_ONCE_OR_MORE_START,
            onSuccess: actions.DOWNLOAD_GET_EMPLOYEES_WITHDRAW_ONCE_OR_MORE,
            onError: actions.DOWNLOAD_GET_EMPLOYEES_WITHDRAW_ONCE_OR_MORE_FAILED,
        },
    }
}

export function getEmployeeDetails(id) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetEmployeeDetails + id,
            method: "get",
            data: {},
            onStart: actions.EMPLOYEE_DETAILS_START,
            onSuccess: actions.EMPLOYEE_DETAILS,
            onError: actions.EMPLOYEE_DETAILS_FAILED,
        },
    }
}

export function activateEmployee(id) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiEnableEmployee + id,
            method: "post",
            data: {},
            onStart: actions.ENABLE_EMPLOYEE_START,
            onSuccess: actions.ENABLE_EMPLOYEE,
            onError: actions.ENABLE_EMPLOYEE_FAILED,
        },
    }
}

export function deactivateEmployee(id) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiDisableEmployee + id,
            method: "post",
            data: {},
            onStart: actions.DISABLE_EMPLOYEE_START,
            onSuccess: actions.DISABLE_EMPLOYEE,
            onError: actions.DISABLE_EMPLOYEE_FAILED,
        },
    }
}

export function archiveEmployee(id) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiArchiveEmployee + id + "/archive",
            method: "post",
            data: {},
            onStart: actions.ARCHIVE_EMPLOYEE_START,
            onSuccess: actions.ARCHIVE_EMPLOYEE,
            onError: actions.ARCHIVE_EMPLOYEE_FAILED,
        },
    }
}

export function resendInvite(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiResendEmployeeInvite,
            method: "post",
            data: params,
            onStart: actions.RESEND_INVITE_START,
            onSuccess: actions.RESEND_INVITE,
            onError: actions.RESEND_INVITE_FAILED,
        },
    }
}

export function generateERA(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGenerateEVA,
            method: "post",
            data: params,
            onStart: actions.GENERATE_ERA_START,
            onSuccess: actions.GENERATE_ERA,
            onError: actions.GENERATE_ERA_FAILED,
        },
    }
}

export function withdrawalCharge(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiWithdrawalCharge,
            method: "post",
            data: params,
            onStart: actions.WITHDRAWAL_CHARGE_START,
            onSuccess: actions.WITHDRAWAL_CHARGE,
            onError: actions.WITHDRAWAL_CHARGE_FAILED,
        },
    }
}

export function resetReloadPage() {
    return {
        type: actions.RELOAD_PAGE_RESET,
        payload: {},
    }
}
