import * as actions from "./actionTypes";
import { toast } from 'react-toastify';

const initialState = {
    loading: false,
    allBanks: [],
};


export default function reducer(state = initialState, action) {
    switch (action.type) {

        case actions.GET_BANKS:
            state = {
                ...state,
                loading: false,
                allBanks: [...action.payload.data]
            }
            return state;


        case actions.GET_BANKS_START:
            state = {
                ...state,
                loading: true,
            }
            return state;


        case actions.GET_BANKS_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }

            state = {
                ...state,
                loading: false,
                allBanks: [],
            }
            return state;


        default:
            return state;
    }
}