import * as actions from "./actionTypes";
import * as config from "config";
import ObjectToQueryString from 'utils/ObjectToQueryString';

export function updateGoalSavingsSettings(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiWalletBaseUrl,
            url: config.apiGetGoalSavingsSettings,
            method: "patch",
            data: params,
            onStart: actions.UPDATE_GOAL_SAVINGS_SETTINGS_START,
            onSuccess: actions.UPDATE_GOAL_SAVINGS_SETTINGS_DONE,
            onError: actions.UPDATE_GOAL_SAVINGS_SETTINGS_FAILED,
        },
    }
}


export function getFlexSavingsSettings(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiWalletBaseUrl,
            url:`${config.apiGetGoalSavingsSettings}?${new URLSearchParams(params).toString()}`,
            method: "get",
            data: {},
            onStart: actions.GET_FLEX_SAVINGS_SETTINGS_START,
            onSuccess: actions.GET_FLEX_SAVINGS_SETTINGS_DONE,
            onError: actions.GET_FLEX_SAVINGS_SETTINGS_FAILED,
        },
    }
}

export function getGoalSavingsSettings(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiWalletBaseUrl,
            url:`${config.apiGetGoalSavingsSettings}?${new URLSearchParams(params).toString()}`,
            method: "get",
            data: {},
            onStart: actions.GET_GOAL_SAVINGS_SETTINGS_START,
            onSuccess: actions.GET_GOAL_SAVINGS_SETTINGS_DONE,
            onError: actions.GET_GOAL_SAVINGS_SETTINGS_FAILED,
        },
    }
}

export function getMobileSettings() {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiSettings,
            method: "get",
            data: {},
            onStart: actions.GET_MOBILE_SETTINGS_START,
            onSuccess: actions.GET_MOBILE_SETTINGS,
            onError: actions.GET_MOBILE_SETTINGS_FAILED,
            // useLoginJwt: false,
        },
    }
}

export function updateMobileSettings(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiSettings,
            method: "post",
            data: params,
            onStart: actions.UPDATE_MOBILE_SETTINGS_START,
            onSuccess: actions.UPDATE_MOBILE_SETTINGS,
            onError: actions.UPDATE_MOBILE_SETTINGS_FAILED,
        },
    }
}

export function getFeesSettings(employerId) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiFeesServiceBaseUrl,
            url: config.apiGetAllFeeSettings + ObjectToQueryString(employerId),
            method: "get",
            data: {},
            onStart: actions.GET_FEES_SETTINGS_START,
            onSuccess: actions.GET_FEES_SETTINGS,
            onError: actions.GET_FEES_SETTINGS_FAILED,
            customToken: config.apiFeesServiceToken,
        },
    }
}

export function getGroupedFeesSettings() {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiFeesServiceBaseUrl,
            url: config.apiGetAllGroupedFeeSettings,
            method: "get",
            data: {},
            onStart: actions.GET_GROUPED_FEES_SETTINGS_START,
            onSuccess: actions.GET_GROUPED_FEES_SETTINGS,
            onError: actions.GET_GROUPED_FEES_SETTINGS_FAILED,
            customToken: config.apiFeesServiceToken,
        },
    }
}

export function getFeeSettings(id) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiFeesServiceBaseUrl,
            url: config.apiGetAllFeeSettings + id,
            method: "get",
            data: {},
            onStart: actions.GET_FEE_SETTINGS_START,
            onSuccess: actions.GET_FEE_SETTINGS,
            onError: actions.GET_FEE_SETTINGS_FAILED,
            customToken: config.apiFeesServiceToken,
        },
    }
}

export function createFeesSettings(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiFeesServiceBaseUrl,
            url: config.apiCreateFeeSettings,
            method: "post",
            data: params,
            onStart: actions.CREATE_FEES_SETTINGS_START,
            onSuccess: actions.CREATE_FEES_SETTINGS,
            onError: actions.CREATE_FEES_SETTINGS_FAILED,
            customToken: config.apiFeesServiceToken,
        },
    }
}

export function updateFeesSettings(id, params) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiFeesServiceBaseUrl,
            url: config.apiEditFeeSettings + id,
            method: "put",
            data: params,
            onStart: actions.UPDATE_FEES_SETTINGS_START,
            onSuccess: actions.UPDATE_FEES_SETTINGS,
            onError: actions.UPDATE_FEES_SETTINGS_FAILED,
            customToken: config.apiFeesServiceToken,
        },
    }
}

export function deleteFeesSettings(feeId, userId) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiFeesServiceBaseUrl,
            url: `${config.apiDeleteFeeSettings}${feeId}/${userId}`,
            method: "delete",
            data: {},
            onStart: actions.DELETE_FEES_SETTINGS_START,
            onSuccess: actions.DELETE_FEES_SETTINGS,
            onError: actions.DELETE_FEES_SETTINGS_FAILED,
            customToken: config.apiFeesServiceToken,
        },
    }
}

export function getBillPaymentSettings(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetBillPaymentSettings + ObjectToQueryString(params),
            method: "get",
            data: {},
            onStart: actions.GET_BILL_PAYMENT_SETTINGS_START,
            onSuccess: actions.GET_BILL_PAYMENT_SETTINGS,
            onError: actions.GET_BILL_PAYMENT_SETTINGS_FAILED,
        },
    }
}

export function createBillPaymentSettings(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiCreateBillPaymentSettings,
            method: "post",
            data: params,
            onStart: actions.CREATE_BILL_PAYMENT_SETTINGS_START,
            onSuccess: actions.CREATE_BILL_PAYMENT_SETTINGS,
            onError: actions.CREATE_BILL_PAYMENT_SETTINGS_FAILED,
        },
    }
}

export function updateBillPaymentSettings(id, params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiUpdateBillPaymentSettings + id,
            method: "put",
            data: params,
            onStart: actions.UPDATE_BILL_PAYMENT_SETTINGS_START,
            onSuccess: actions.UPDATE_BILL_PAYMENT_SETTINGS,
            onError: actions.UPDATE_BILL_PAYMENT_SETTINGS_FAILED,
        },
    }
}

export function deleteBillPaymentSettings(id) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiDeleteBillPaymentSettings + id,
            method: "delete",
            data: {},
            onStart: actions.DELETE_BILL_PAYMENT_SETTINGS_START,
            onSuccess: actions.DELETE_BILL_PAYMENT_SETTINGS,
            onError: actions.DELETE_BILL_PAYMENT_SETTINGS_FAILED,
        },
    }
}

export function resetBillPaymentSettings() {
    return {
        type: actions.BILL_PAYMENT_SETTINGS_RESET,
        payload: {},
    }
}

export function selectPlatformFee(payload) {
    return {
        type: actions.SELECT_PLATFORM_FEE,
        payload,
    }
}

export function resetReloadPage() {
    return {
        type: actions.RELOAD_PAGE_RESET,
        payload: {},
    }
}

export function getYieldSettings() {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiWalletBaseUrl,
            url: config.apiGetYieldSettings,
            method: "get",
            data: {},
            onStart: actions.GET_YIELD_SETTINGS_START,
            onSuccess: actions.GET_YIELD_SETTINGS,
            onError: actions.GET_YIELD_SETTINGS_FAILED,
        },
    }
}

export function updateYieldSettings(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            baseUrl: config.apiWalletBaseUrl,
            url: config.apiUpdateYieldSettings,
            method: "patch",
            data: params,
            onStart: actions.UPDATE_YIELD_SETTINGS_START,
            onSuccess: actions.UPDATE_YIELD_SETTINGS,
            onError: actions.UPDATE_YIELD_SETTINGS_FAILED,
        },
    }
}

export function resetUpdateYieldSettings() {
    return {
        type: actions.UPDATE_YIELD_SETTINGS_RESET,
        payload: {},
    }
}
