import * as actions from "./actionTypes";
import { toast } from 'react-toastify';

const initialState = {
    loading: false,
    // loggedIn: false,     // moved to persist
    // data: {},            // moved to persist
    // signedUp: false,     // moved to persist
    signupForm: {},
    // signupUser: {},      // moved to persist
    forgotPassword: false,
    forgotPasswordForm: {},
    resetPasswordLoading: false,
    resetPassword: false,
    verifyAccountLoading: false,
    verifyAccount: false,
    resendMailLoading: false,
    resendMail: false,
};


export default function reducer(state = initialState, action) {
    switch (action.type) {

        case actions.LOGIN_USER:
            // console.log("login success");
            state = {
                ...state,
                loading: false,
                // loggedIn: true,
                // data: {
                //     token: action.payload.token,
                //     _id: action.payload.employer._id,
                //     firstName: action.payload.employer.firstName,
                //     lastName: action.payload.employer.lastName,
                //     name: action.payload.employer.firstName + " " + action.payload.employer.lastName,
                //     email: action.payload.employer.email,
                //     phoneNumber: action.payload.employer.phoneNumber,
                //     userType: action.payload.employer.userType,
                //     jobTitle: action.payload.employer.jobTitle,
                // },
                signupForm: {},
                // signedUp: false,
            }
            return state;


        case actions.UPDATE_LOGIN_USER:
            state = {
                ...state,
                // data: {
                //     ...state.data,
                //     firstName: action.payload.data.firstName,
                //     lastName: action.payload.data.lastName,
                //     name: action.payload.data.firstName + " " + action.payload.data.lastName,
                //     email: action.payload.data.email,
                //     phoneNumber: action.payload.data.phoneNumber,
                // },
            }
            return state;


        case actions.LOGIN_USER_START:
            state = {
                ...state,
                loading: true,
                // loggedIn: false,
                // data: {}
            }
            return state;


        case actions.LOGIN_USER_FAILED:
            if (action.payload.message === "Password does not match") {
                toast.error("Invalid Email Address or Password");
            }
            else if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }

            state = {
                ...state,
                loading: false,
                // loggedIn: false,
                // data: {}
            }
            return state;


        case actions.LOGOUT_USER:
            state = {
                ...state,
                // loggedIn: false,
                // data: {}
            }
            // window.location.href = "/login";
            return state;


        case actions.FORGOT_PASSWORD:
            toast.success("Password reset pin has been sent to your email address");
            state = {
                ...state,
                loading: false,
                forgotPassword: true,
                forgotPasswordForm: {
                    email: action.payload.email
                }
            }
            return state;


        case actions.FORGOT_PASSWORD_START:
            state = {
                ...state,
                loading: true,
                forgotPassword: false,
            }
            return state;


        case actions.FORGOT_PASSWORD_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }

            state = {
                ...state,
                loading: false,
                forgotPassword: false,
            }
            return state;


        case actions.FORGOT_PASSWORD_RESET:
            state = {
                ...state,
                forgotPassword: false,
            }
            return state;


        case actions.RESET_PASSWORD:
            // toast.success("Password reset successful");
            state = {
                ...state,
                resetPasswordLoading: false,
                resetPassword: true,
            }
            return state;


        case actions.RESET_PASSWORD_START:
            state = {
                ...state,
                resetPasswordLoading: true,
                resetPassword: false,
            }
            return state;


        case actions.RESET_PASSWORD_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }

            state = {
                ...state,
                resetPasswordLoading: false,
                resetPassword: false,
            }
            return state;


        case actions.RESET_PASSWORD_RESET:
            state = {
                ...state,
                resetPassword: false,
            }
            return state;


        default:
            return state;
    }
}