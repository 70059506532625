// import http, {useLoginJwt, dontUseLoginJwt} from "services/httpService"
import axios from "axios";
import { toast } from "react-toastify";
import * as config from "config";
import * as userActions from "store/auth/user/actionTypes";



const api = store => next => async action => {



    const getBaseUrl = (baseUrl) => {
        let apiBaseUrl;
        if (typeof baseUrl !== "undefined") {
            // console.log("use custom", baseUrl);
            apiBaseUrl = baseUrl;
        }
        else {
            // console.log("use default", config.apiBaseUrl);
            apiBaseUrl = config.apiBaseUrl;
        }
        return apiBaseUrl;
    }



    const getToken = (customToken) => {
        let apiToken;
        if (typeof customToken !== "undefined") {
            // console.log("use custom", customToken);
            apiToken = customToken;
        }
        else {
            const loginJwt = store.getState().auth.userPersist.data.token;
            // console.log("use loginJwt", loginJwt);
            apiToken = loginJwt;
        }
        return apiToken;
    }



    const isUserTokenActive = () => {
        const tokenExpiryValue = store.getState().auth.userPersist.data.tokenExpiry;
        if (tokenExpiryValue !== undefined && tokenExpiryValue !== null){
            const tokenExpiryTime = (parseInt(tokenExpiryValue) * 1000);
            const currentTime = Date.now();
            
            if (currentTime > tokenExpiryTime){
                // console.log("jwt has expired");
                toast.error("Expired Session, Please Login Again", {
                    toastId: "expired",
                });
                setTimeout(() => {
                    store.dispatch({ type: userActions.LOGOUT_USER });
                    return false;
                }, 1000)
            }
            else{
                // console.log("jwt hasn't expired");
                return true;
            }
        }
        // console.log("user isn't loggedin yet");
        return true;
    }



    if (action.type !== config.apiRequestStart) {
        next(action);
    }
    else {
        if (isUserTokenActive() === true){


        const { baseUrl, url, method, data, onStart, onSuccess, onError, useLoginJwt, customToken } = action.payload;

        if (onStart) {
            store.dispatch({ type: onStart });
        }

        const apiBaseUrl = getBaseUrl(baseUrl);

        let http;
        if ((typeof useLoginJwt !== "undefined") && (useLoginJwt === false)) {
            http = axios.create();
        }
        else {
            const token = getToken(customToken);
            http = axios.create({
                headers: {
                    Authorization: token,
                }
            });
        }


        next(action);                                                                           // for debugger logging purpose (in chrome Redux dev tools)


        await http.request({
            baseURL: apiBaseUrl,
            url,
            method,
            data
        }).then((response) => {
            store.dispatch({ type: config.apiRequestSuccess, payload: response });                // for debugger logging purpose
            if (onSuccess) {
                store.dispatch({ type: onSuccess, payload: response.data });
            }
        }).catch((error) => {
            // console.log(error); //check with dummy base64 endpoint online
            store.dispatch({ type: config.apiRequestFailed, payload: error });                    // for debugger logging purpose
            if (onError) {
                if (error.response) {
                    store.dispatch({ type: onError, payload: error.response.data });
                    if (error.response.data.message === "Expired Session, Please Login Again") {
                        // window.setTimeout(function () {
                            store.dispatch({ type: userActions.LOGOUT_USER });
                        // }, 1000);
                    }
                }
                else if (error.request) {
                    if (navigator.onLine) {
                        store.dispatch({
                            type: onError,
                            payload: {
                                message: "Error: Invalid request. Please try again later."
                            }
                        });
                    }
                    else {
                        store.dispatch({
                            type: onError,
                            payload: {
                                message: "No Internet Connection"
                            }
                        });
                    }
                }
                else {
                    store.dispatch({
                        type: onError,
                        payload: {
                            message: "An error occurred. Please try again later."
                        }
                    });
                }
            }
        });

        
        }
    }

}

export default api;