import React from 'react';

const isReactFragment = (variableToInspect) => {
    if (variableToInspect.type) {
        return variableToInspect.type === React.Fragment;
    }
    return variableToInspect === React.Fragment;
}


export default isReactFragment;