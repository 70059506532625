import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { customizeSVG } from 'utils/customizeSVG.jsx';
import { ReactComponent as IconUsers } from "assets/images/icons/project-icons/Users.svg";
import { ReactComponent as IconChart } from "assets/images/icons/project-icons/ChartPie.svg";


class QuerySearchCategory extends Component {
    state = { 
        dashboardProps1: {
            iconBg: "bg-purple-200",
            icon: <IconChart className="customizeSVG text-purple-600 m-auto w-7 h-7" />,
            titleText: "Total Payroll",
            mainText: "-"
        },
        dashboardProps2: {
            iconBg: "bg-gray-200",
            icon: <IconChart className="customizeSVG text-gray-600 m-auto w-7 h-7" />,
            titleText: "Total Withdrawals",
            mainText: "-"
        },
        dashboardProps3: {
            iconBg: "bg-yellow-200",
            icon: <IconChart className="customizeSVG text-yellow-600 m-auto w-7 h-7" />,
            titleText: "Repayment Balance",
            mainText: "-"
        },
    }


    componentDidMount() {

    }


    componentWillUnmount() {
        
    }


    render() { 
        customizeSVG();
        // const {loading, dashboard: data} = this.props;
        // const {dashboardProps1, dashboardProps2, dashboardProps3, dashboardProps4} = this.state;
        // console.log(data);

        return ( 
            <div>


                <div>

                    <div className="lg:flex">
                        <div className="w-full lg:w-1/2">
                            <div className="page-title capitalize">
                                Search
                            </div>
                        </div>
                        <div className="w-full lg:w-1/2 flex mt-2 lg:mt-0 lg:justify-end"></div>
                    </div>

                    <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">

                        <Link to="/search/employers" className="box flex hover:bg-gray-100">
                            <div className="w-10 h-10 xl:w-12 xl:h-12 mr-4 flex-shrink-0 rounded-lg flex bg-yellow-200">
                                <IconUsers className="customizeSVG text-yellow-600 m-auto w-7 h-7" />
                            </div>
                            <div className="my-auto">
                                <div className="h-7 text-xl font-bold overflow-hidden">
                                    Employers
                                </div>
                                <div className="text-gray-400 text-sm">
                                    All, active, pending, disabled, & other categories of employers.
                                </div>
                            </div>
                        </Link>

                        <Link to="/search/employees" className="box flex hover:bg-gray-100">
                            <div className="w-10 h-10 xl:w-12 xl:h-12 mr-4 flex-shrink-0 rounded-lg flex bg-blue-200">
                                <IconUsers className="customizeSVG text-blue-600 m-auto w-7 h-7" />
                            </div>
                            <div className="my-auto">
                                <div className="h-7 text-xl font-bold overflow-hidden">
                                    Employees
                                </div>
                                <div className="text-gray-400 text-sm">
                                    All, enrolled, pending, disabled, & other categories of employees.
                                </div>
                            </div>
                        </Link>

                        <Link to="/search/withdrawals" className="box flex hover:bg-gray-100">
                            <div className="w-10 h-10 xl:w-12 xl:h-12 mr-4 flex-shrink-0 rounded-lg flex bg-green-200">
                                <IconChart className="customizeSVG text-green-600 m-auto w-7 h-7" />
                            </div>
                            <div className="my-auto">
                                <div className="h-7 text-xl font-bold overflow-hidden">
                                    Withdrawals
                                </div>
                                <div className="text-gray-400 text-sm">
                                    All, pending, approved, cut-off, failed-credit & other categories.
                                </div>
                            </div>
                        </Link>

                        <Link to="#" className="box flex opacity-40 cursor-default">
                            <div className="w-10 h-10 xl:w-12 xl:h-12 mr-4 flex-shrink-0 rounded-lg flex bg-gray-200">
                                <IconUsers className="customizeSVG text-gray-600 m-auto w-7 h-7" />
                            </div>
                            <div className="my-auto">
                                <div className="h-7 text-xl font-bold overflow-hidden opacity-40">
                                    Payment Note
                                </div>
                                <div className="text-gray-400 text-sm">
                                    Payment note sent to the employer at the end of each month.
                                </div>
                            </div>
                        </Link>

                        <Link to="#" className="box flex opacity-40 cursor-default">
                            <div className="w-10 h-10 xl:w-12 xl:h-12 mr-4 flex-shrink-0 rounded-lg flex bg-gray-200">
                                <IconUsers className="customizeSVG text-gray-600 m-auto w-7 h-7" />
                            </div>
                            <div className="my-auto">
                                <div className="h-7 text-xl font-bold overflow-hidden opacity-40">
                                    Payroll
                                </div>
                                <div className="text-gray-400 text-sm">
                                    Payroll information of of all employees on the platform.
                                </div>
                            </div>
                        </Link>

                        <Link to="#" className="box flex opacity-40 cursor-default">
                            <div className="w-10 h-10 xl:w-12 xl:h-12 mr-4 flex-shrink-0 rounded-lg flex bg-gray-200">
                                <IconUsers className="customizeSVG text-gray-600 m-auto w-7 h-7" />
                            </div>
                            <div className="my-auto">
                                <div className="h-7 text-xl font-bold overflow-hidden opacity-40">
                                    Payment History
                                </div>
                                <div className="text-gray-400 text-sm">
                                    History of payment notes settled by the employer.
                                </div>
                            </div>
                        </Link>

                        <Link to="#" className="box flex opacity-40 cursor-default">
                            <div className="w-10 h-10 xl:w-12 xl:h-12 mr-4 flex-shrink-0 rounded-lg flex bg-gray-200">
                                <IconUsers className="customizeSVG text-gray-600 m-auto w-7 h-7" />
                            </div>
                            <div className="my-auto">
                                <div className="h-7 text-xl font-bold overflow-hidden opacity-40">
                                    Referrals
                                </div>
                                <div className="text-gray-400 text-sm">
                                    Companies that have been referred by people.
                                </div>
                            </div>
                        </Link>

                    </div>

                </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => ({
    
});

const mapDispatchToProps = (dispatch) => ({
    
});

export default connect(mapStateToProps, mapDispatchToProps)(QuerySearchCategory);