import React, { Component } from 'react';
import Joi from "joi-browser";
import Select from 'react-select';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import { toast } from 'react-toastify';
import { connect } from "react-redux";
import { customizeSVG } from 'utils/customizeSVG.jsx';
import { TabSettingsWide, TabSettingsMobile } from 'components/common/settingsTab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as employers from "store/entities/employers/action";
import * as settings from "store/entities/settings/action";

class Form extends Component {

    state = {
        feeId: "",
        feeForm: {
            userId: this.props.user._id,
            fee: "",
            minAmount: "",
            maxAmount: "",
        },
        showCreateForm: false,
        errors: {},
        employerId: "",
        employerFilterOptions: [],
        employerFilter: "",
        maxAmountFeeType: "",
        newFeeType: "percentage",
    }


    goBack = () => {
        this.props.history.goBack();
    }


    formChange = (e) => {
        const formValue = {...this.state.feeForm};
        formValue[e.currentTarget.name] = e.currentTarget.value;
        this.setState({feeForm: formValue});
    }


    schema = {
        fee: Joi.number().required().label("Fee").error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    case "any.empty":
                        err.message = "Please enter the fee";
                        break;
                    case "number.base":
                        err.message = `Please enter the fee`;
                        break;
                    default:
                        break;
                }
            });
            return errors;
        }),
        minAmount: Joi.number().required().label("Min Amount").error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    case "any.empty":
                        err.message = "Please enter the minimum amount";
                        break;
                    case "number.base":
                        err.message = `Please enter the minimum amount`;
                        break;
                    default:
                        break;
                }
            });
            return errors;
        }),
        maxAmount: Joi.number().required().label("Max Amount").error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    case "any.empty":
                        err.message = "Please enter the maximum amount";
                        break;
                    case "number.base":
                        err.message = `Please enter the maximum amount`;
                        break;
                    default:
                        break;
                }
            });
            return errors;
        }),
    }


    validate = () => {
        const options = { abortEarly: false, allowUnknown: true };
        const result = Joi.validate(this.state.feeForm, this.schema, options);
        // console.log(result);
        const error = result.error;
        if (!error) return null;

        const errors = {};
        for (let item of result.error.details){
            errors[item.path[0]] = item.message;
        }
        return errors;
    };


    submitForm = (action) => {
        const errors = this.validate();
        this.setState({errors: errors || {}});
        if (errors){
            // console.log(errors);
            for (var x in errors) {
                toast.error(errors[x]);
            };
            return;
        }
        else{
            // alert("all good");
            const { feeId, feeForm } = this.state;
            if (action === "create"){
                this.props.createFeesSettings(feeForm).then(() => {
                    if (this.props.feesSettingsCreateSuccess === true){
                        document.querySelector(".input-minAmount-create").value = "";
                        document.querySelector(".input-maxAmount-create").value = "";
                        document.querySelector(".input-fee-create").value = "";
                        this.getFeesSettings();
                        this.setState({showCreateForm: false});
                    }
                })
            }
            else if (action === "update"){
                this.props.updateFeesSettings(feeId, feeForm).then(() => {
                    if (this.props.feesSettingsUpdateSuccess === true){
                        this.getFeesSettings();
                    }
                })
            }

        }
    }


    showCreateForm = () => {
        this.setState({showCreateForm: true});
    }


    CreateFee = async (e) => {
        e.preventDefault();

        let feeForm = {
            ...this.state.feeForm,
            minAmount: document.querySelector(".input-minAmount-create").value,
            maxAmount: document.querySelector(".input-maxAmount-create").value,
            fee: document.querySelector(".input-fee-create").value,
            type: this.state.newFeeType || "percentage",
        };

        const {employerFilter} = this.state;
        if (employerFilter.value === "default"){
            delete feeForm["employerId"];
        }
        else{
            feeForm = {...feeForm, employerId: employerFilter.value}
        }

        if (feeForm?.type === 'fixed' && feeForm?.fee < 1) {
            toast.error('Please enter a valid fee amount')
        } else if ((feeForm?.type === 'percentage' && feeForm?.fee < 1) || (feeForm?.type === 'percentage' && feeForm?.fee > 100)) {
            toast.error('Please enter a valid percentage')
        } else if (feeForm?.type === 'fixed' && feeForm?.fee < 1) {
            toast.error('Please enter a valid fee amount')
        } else {
            this.setState({feeForm}, () => {
                this.submitForm("create");
            });
        }
    }


    UpdateFee = (feeId, index) => {
        let feeForm = {
            ...this.state.feeForm,
            minAmount: document.querySelector(".input-minAmount-" + index).value,
            maxAmount: document.querySelector(".input-maxAmount-" + index).value,
            fee: document.querySelector(".input-fee-" + index).value,
            type: this.state.maxAmountFeeType || "percentage"
        };

        const {employerFilter} = this.state;
        if (employerFilter.value === "default"){
            delete feeForm["employerId"];
        }
        else{
            feeForm = {...feeForm, employerId: employerFilter.value}
        }
        this.setState({feeId, feeForm}, () => {
            this.submitForm("update");
        });
    }


    DeleteFee = (feeId) => {
        this.setState({feeId});
        const {_id: userId} = this.props.user;
        this.props.deleteFeesSettings(feeId, userId).then(() => {
            if (this.props.feesSettingsDeleteSuccess === true){
                this.getFeesSettings();
            }
        })
    }


    getAllEmployers = () => {
        const getAllEmployersParams = {
            page: 1,
            limit: 1000,
            status: "approved",
        }
        this.props.getAllEmployers(getAllEmployersParams).then(() => {
            const allEmployers = this.props.allEmployers?.data?.employers;
            
            // let employerFilterOptions = [];
            let employerFilterOptions = [
                {value: "default", label: "Earnipay Default"},
            ];
            if ((allEmployers !== undefined) && (allEmployers !== null)){
                Object.keys(allEmployers).forEach(function(key) {
                    employerFilterOptions.push(
                        { value: allEmployers[key]?._id, label: allEmployers[key]?.company?.companyName }
                    );
                });
            }
            this.setState({employerFilterOptions});
            this.selectEmployerFilter();
        });
    }


    employerFilterValue = (employerId) => {
        // console.log(employerId);
        this.setState({employerFilter: employerId});
        this.gotoUrlQuery({"employerId": employerId.value});
    }


    gotoUrlQuery = (params) => {
        const qs = queryString.parse(this.props.location.search);

        Object.keys(params).forEach((paramName) => {
            // console.log("qs", qs[paramName]);
            delete qs[paramName];
        });

        let qsToUrl = new URLSearchParams(qs).toString();
        qsToUrl = this.props.location.pathname + "?" + ((qsToUrl !== "")? qsToUrl + "&" : "");

        Object.keys(params).forEach((paramName) => {
            if (params[paramName] !== ""){
                qsToUrl += paramName + "=" + params[paramName] + "&";
            }
        });

        qsToUrl = qsToUrl.substring(0, qsToUrl.length - 1);
        this.props.history.push(qsToUrl);
    }


    selectEmployerFilter = () => {
        const qs = queryString.parse(this.props.location.search);
        const { employerFilterOptions } = this.state;
        let employerName = "";

        if ((typeof qs.employerId !== undefined) && (qs.employerId !== undefined) && (qs.employerId !== null)){
            let employerFilter = {value: qs.employerId, label: ""};
            // console.log(employerFilterOptions);

            if (employerFilterOptions.length > 0){
                employerFilterOptions.forEach((employer) => {
                    if (employer.value === qs.employerId){
                        employerName = employer.label;
                    }
                });

                if (employerName !== ""){
                    employerFilter = {...employerFilter, label: employerName};
                }
                else{
                    toast.error("Invalid employee firm selected");
                }
            }
            this.setState({employerFilter});
        }

    }


    getFeesSettings = () => {
        const qs = queryString.parse(this.props.location.search);
        let employerId = "";
        if ((typeof qs.employerId !== undefined) && (qs.employerId !== undefined) && (qs.employerId !== null)){
            if (qs.employerId !== "default"){
                employerId = qs.employerId;
            }
            this.setState({employerId: qs.employerId});
        }
        this.props.getFeesSettings({employerId});
    }


    componentDidMount() {
        this.getAllEmployers();
        this.selectEmployerFilter();
        this.getFeesSettings();
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.getFeesSettings();
        }
    }


    render() {
        customizeSVG();
        const { feesSettingsLoading, feesSettings, allEmployersLoading } = this.props;
        const { feeId, showCreateForm, employerFilter, employerFilterOptions, employerId, newFeeType, } = this.state;

        let feesSettingsArray = [];
        if (employerFilter.value === "default"){
            feesSettingsArray = feesSettings.filter(fee => !fee.employerId);
        }
        else{
            feesSettingsArray = feesSettings;
        }

        return (
            <div>

                <TabSettingsMobile />

                <div className="box" style={{minHeight: "500px"}}>
                    <div className="flex">
                        <TabSettingsWide />

                        <div className="lg:w-3/4 xl:w-4/5 lg:pl-6 flex-grow">

                            <div className="grid sm:grid-cols-2">
                                <div className="page-title">
                                    Fees Settings
                                </div>
                                <div className="flex justify-end">

                                </div>
                            </div>

                            <div className="mt-6 grid sm:grid-cols-2">
                                <div>
                                    <div>
                                        {(allEmployersLoading === true) &&
                                            <div className="w-full px-3 py-2 border border-gray-200 rounded">
                                                <FontAwesomeIcon icon="spinner" spin className="mr-1.5" />
                                                Loading
                                            </div>
                                        }
                                        {(allEmployersLoading === false) &&
                                            <div className="w-full">
                                                <Select options={employerFilterOptions} placeholder="Select Employer" value={employerFilter} onChange={this.employerFilterValue} />
                                            </div>
                                        }
                                    </div>
                                    <div className="page-title-mini hidden">
                                        Earnipay Default
                                    </div>
                                </div>
                                <div className="flex space-x-2 justify-end">
                                    {(!showCreateForm) && (employerId !== "") &&
                                        <button type="button" className="btn btn-md btn-ep-blue" onClick={this.showCreateForm}>
                                            <FontAwesomeIcon icon="plus" className="text-md" />
                                            <span>
                                                Create Fee
                                            </span>
                                        </button>
                                    }
                                    <Link to="/settings/fees" className="btn btn-transparent-black btn-md">
                                        Back
                                    </Link>
                                </div>
                            </div>

                            <div className="pt-6 mt-6 border-t">

                                {(showCreateForm) &&
                                    <div className="mb-10">
                                        <div className="font-bold text-gray-600">
                                            Create New Fee
                                        </div>
                                        <form onSubmit={this.CreateFee} className="p-6 pb-2 mt-2 border sm:grid sm:grid-cols-2 lg:grid-cols-4 sm:gap-x-6">

                                            <div className="form-group">
                                                <label>
                                                    Min Amount (₦)
                                                    <span className="form-input-required">*</span>
                                                </label>
                                                <input type="number" placeholder="Enter fee amount" className="form-input input-minAmount-create" defaultValue="" />
                                            </div>

                                            <div className="form-group">
                                                <label>
                                                    Max Amount (₦)
                                                    <span className="form-input-required">*</span>
                                                </label>
                                                <input type="number" placeholder="Enter fee amount" className="form-input input-maxAmount-create" defaultValue="" />
                                            </div>

                                            <div className="form-group">
                                                <label>
                                                    Fee {`(${newFeeType === 'percentage' ? "%" : "₦"})`}
                                                    <span className="form-input-required">*</span>
                                                </label>
                                                <input type="number" placeholder={newFeeType === 'percentage' ? "Enter percentage" : "Enter fee amount"} className="form-input input-fee-create" defaultValue="" />
                                            </div>

                                            <div className="form-group">
                                                <label>
                                                    Fee Type
                                                    <span className="form-input-required">*</span>
                                                </label>
                                                <select onChange={(e) => this.setState({ newFeeType: e.target.value })} className="w-full py-1.5" defaultValue={newFeeType}>
                                                    <option value="">Select Type</option>
                                                    <option value="percentage">Percentage</option>
                                                    <option value="fixed">Fixed</option>
                                                </select>
                                            </div>

                                            <div className="form-group">
                                                <label>&nbsp;</label>
                                                <div className="flex space-x-2">
                                                    <button type="submit" className="btn btn-ep-blue btn-block" disabled={this.props.feesSettingsCreateLoading}>
                                                        <FontAwesomeIcon icon="spinner" spin className={"text-xl mr-2 " + (this.props.feesSettingsCreateLoading?"inline":"hidden")} />
                                                        Create
                                                    </button>
                                                </div>
                                            </div>


                                        </form>
                                    </div>
                                }

                                <div>
                                    <div className="font-bold text-gray-600">
                                        {employerId === "" ? "" : "Edit Existing Fees"}
                                    </div>
                                    <div className="pt-2">

                                        {((allEmployersLoading === true) || (feesSettingsLoading === true)) &&
                                            <div className="table-info">
                                                <FontAwesomeIcon icon="spinner" spin />
                                                <div className="font-bold uppercase">
                                                    Loading
                                                </div>
                                            </div>
                                        }

                                        {(allEmployersLoading === false) &&
                                            <>

                                                {(employerId === "") &&
                                                    <div className="table-info">
                                                        <FontAwesomeIcon icon="building" />
                                                        <div className="font-bold uppercase">
                                                            Select Employer
                                                        </div>
                                                    </div>
                                                }

                                                {(employerId !== "") && (!employerFilter || employerFilter.label === "") &&
                                                    <div className="table-info">
                                                        <FontAwesomeIcon icon="unlink" />
                                                        <div className="font-bold uppercase">
                                                            Company not found
                                                        </div>
                                                    </div>
                                                }

                                                {employerFilter && employerFilter.label !== "" &&
                                                    <>
                                                        {((feesSettingsLoading === false) && (!feesSettings)) &&
                                                            <div className="table-info">
                                                                <FontAwesomeIcon icon="unlink" />
                                                                <div className="font-bold uppercase">
                                                                    An error occurred
                                                                    <br />
                                                                    Please try again later.
                                                                </div>
                                                            </div>
                                                        }

                                                        {(feesSettingsLoading === false) && (feesSettings) && (feesSettings.length <= 0) &&
                                                            <div className="table-info">
                                                                <FontAwesomeIcon icon="unlink" />
                                                                <div className="font-bold uppercase">
                                                                    No fees configured yet
                                                                </div>
                                                                {(!showCreateForm) &&
                                                                    <div>
                                                                        <button type="button" className="mt-2 mx-auto btn btn-md btn-ep-blue" onClick={this.showCreateForm}>
                                                                            <FontAwesomeIcon icon="plus" className="text-md" />
                                                                            <span>
                                                                                Create Fee
                                                                            </span>
                                                                        </button>
                                                                    </div>
                                                                }
                                                            </div>
                                                        }

                                                        {(feesSettingsLoading === false) && (feesSettings) && (feesSettings.length > 0) &&
                                                            <div className="border-b">
                                                                {feesSettingsArray.map((feesSetting, index) => {
                                                                    return (
                                                                            <div key={index} className="p-6 pb-2 border border-b-0 sm:grid sm:grid-cols-2 md:grid-cols-4 lg:grid-cols-2 xl:grid-cols-4 sm:gap-x-6">
                                                                            <div className="form-group">
                                                                                <label>
                                                                                    Min Amount (₦)
                                                                                    <span className="form-input-required">*</span>
                                                                                </label>
                                                                                <input type="number" placeholder="Enter fee amount" className={"form-input input-minAmount-" + index} defaultValue={feesSetting.minAmount} />
                                                                            </div>

                                                                            <div className="form-group">
                                                                                <label>
                                                                                    Max Amount (₦)
                                                                                    <span className="form-input-required">*</span>
                                                                                </label>
                                                                                <input type="number" placeholder="Enter fee amount" className={"form-input input-maxAmount-" + index} defaultValue={feesSetting.maxAmount} />
                                                                            </div>

                                                                            <div className="form-group">
                                                                                <label>
                                                                                    Fee ({feesSetting?.type === "percentage" ? "%" : "₦"})
                                                                                    <span className="form-input-required">*</span>
                                                                                </label>
                                                                                <input type="number" placeholder="Enter fee amount" className={"form-input input-fee-" + index} defaultValue={feesSetting.fee} />
                                                                            </div>

                                                                                <div className="form-group">
                                                                                    <label>
                                                                                        Fee Type
                                                                                        <span className="form-input-required">*</span>
                                                                                    </label>
                                                                                    <select onChange={(e) => this.setState({ maxAmountFeeType: e.target.value })} className="w-full py-1.5" defaultValue={feesSetting?.type}>
                                                                                        <option value="">Select Type</option>
                                                                                        <option value="percentage">Percentage</option>
                                                                                        <option value="fixed">Fixed</option>
                                                                                    </select>
                                                                                </div>

                                                                            <div className="form-group">
                                                                                <label>&nbsp;</label>
                                                                                <div className="flex space-x-2">
                                                                                    <button type="button" className="btn btn-ep-blue btn-block" onClick={() => this.UpdateFee(feesSetting._id, index)} disabled={(this.props.feesSettingsUpdateLoading) && (feeId === feesSetting._id)}>
                                                                                        <FontAwesomeIcon icon="spinner" spin className={"text-xl mr-2 " + (((this.props.feesSettingsUpdateLoading) && (feeId === feesSetting._id)) ? "inline" : "hidden")} />
                                                                                        Update
                                                                                    </button>
                                                                                    <button type="button" className="btn btn-red btn-block" onClick={() => this.DeleteFee(feesSetting._id)} disabled={(this.props.feesSettingsDeleteLoading) && (feeId === feesSetting._id)}>
                                                                                        <FontAwesomeIcon icon="spinner" spin className={"text-xl mr-2 " + (((this.props.feesSettingsDeleteLoading) && (feeId === feesSetting._id))?"inline":"hidden")} />
                                                                                        Delete
                                                                                    </button>
                                                                                </div>
                                                                            </div>

                                                                            </div>
                                                                            )
                                                                }
                                                                )}

                                                            </div>
                                                        }

                                                    </>
                                                }

                                            </>
                                        }

                                    </div>
                                </div>

                            </div>
                        </div>


                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => ({
    user: state.auth.userPersist.data,
    feesSettingsLoading: state.entities.settings.feesSettingsLoading,
    feesSettings: state.entities.settings.feesSettings,
    feesSettingsCreateLoading: state.entities.settings.feesSettingsCreateLoading,
    feesSettingsCreateSuccess: state.entities.settings.feesSettingsCreateSuccess,
    feesSettingsUpdateLoading: state.entities.settings.feesSettingsUpdateLoading,
    feesSettingsUpdateSuccess: state.entities.settings.feesSettingsUpdateSuccess,
    feesSettingsDeleteLoading: state.entities.settings.feesSettingsDeleteLoading,
    feesSettingsDeleteSuccess: state.entities.settings.feesSettingsDeleteSuccess,
    reloadPage: state.entities.settings.reloadPage,

    allEmployersLoading: state.entities.employers.loading,
    allEmployers: state.entities.employers.allEmployers,
});

const mapDispatchToProps = (dispatch) => ({
    getFeesSettings: (employerId) => dispatch(settings.getFeesSettings(employerId)),
    createFeesSettings: (params) => dispatch(settings.createFeesSettings(params)),
    updateFeesSettings: (id, params) => dispatch(settings.updateFeesSettings(id, params)),
    deleteFeesSettings: (id, userId) => dispatch(settings.deleteFeesSettings(id, userId)),
    resetReloadPage: () => dispatch(settings.resetReloadPage()),

    getAllEmployers: (params) => dispatch(employers.searchFilterEmployers(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Form);
