import * as actions from "./actionTypes";
import * as config from "config";

export function downloadGoalSavings(params) {
	const {status, ...rest} = params
	const newParams = !status ? {...rest} : {...params}
	return {
		type: config.apiRequestStart,
		payload: {
			baseUrl: config.apiWalletBaseUrl,
			url:`${config.apiGetAllGoalSavings}?${new URLSearchParams(newParams).toString()}`,
			method: "get",
			data: params,
			onStart: actions.DOWNLOAD_GOALS_SAVINGS_START,
			onSuccess: actions.DOWNLOAD_GOALS_SAVINGS_DONE,
			onError: actions.DOWNLOAD_GOALS_SAVINGS_FAILED,
		},
	};
}

export function downloadSavingsCsv(params) {
	let endpointUrl = config.apiGetAllGoalSavings;
    if (Object.keys(params).length > 4){
        endpointUrl = config.apiSearchAndFilterGoalSavings;
    }

	return {
		type: config.apiRequestStart,
		payload: {
			baseUrl: config.apiWalletBaseUrl,
			url:`${endpointUrl}?${new URLSearchParams(params).toString()}`,
			method: "get",
			data: {},
			onStart: actions.DOWNLOAD_GOALS_SAVINGS_START,
			onSuccess: actions.DOWNLOAD_GOALS_SAVINGS_DONE,
			onError: actions.DOWNLOAD_GOALS_SAVINGS_FAILED,
		},
	};
}

export function searchFilterGoalSavings(params) {
	const {status, ...rest} = params
	const newParams = !status ? {...rest} : {...params}
	return {
		type: config.apiRequestStart,
		payload: {
			baseUrl: config.apiWalletBaseUrl,
			url:`${config.apiSearchAndFilterGoalSavings}?${new URLSearchParams(newParams).toString()}`,
			method: "get",
			data: params,
			onStart: actions.GET_ALL_GOALS_SAVINGS_START,
			onSuccess: actions.GET_ALL_GOALS_SAVINGS_DONE,
			onError: actions.GET_ALL_GOALS_SAVINGS_FAILED,
		},
	};
}

export function getAllGoalSavings(params) {
	const {status, ...rest} = params
	const newParams = !status ? {...rest} : {...params}
	return {
		type: config.apiRequestStart,
		payload: {
			baseUrl: config.apiWalletBaseUrl,
			url:`${config.apiGetAllGoalSavings}?${new URLSearchParams(newParams).toString()}`,
			method: "get",
			data: params,
			onStart: actions.GET_ALL_GOALS_SAVINGS_START,
			onSuccess: actions.GET_ALL_GOALS_SAVINGS_DONE,
			onError: actions.GET_ALL_GOALS_SAVINGS_FAILED,
		},
	};
}

export function getSavingsMetrics() {

	return {
		type: config.apiRequestStart,
		payload: {
			baseUrl: config.apiWalletBaseUrl,
			url: config.apiGetSavingsMetrics,
			method: "get",
			data: {},
			onStart: actions.GET_SAVINGS_METRICS_START,
			onSuccess: actions.GET_SAVINGS_METRICS_DONE,
			onError: actions.GET_SAVINGS_METRICS_FAILED,
		},
	};
}

export function getSavingsTransactions(params) {
	return {
		type: config.apiRequestStart,
		payload: {
			baseUrl: config.apiWalletBaseUrl,
			url: `${config.apiGetSingleGoalSavings}/savings?hash=${params.hash}&email=${params?.email}&page=1&limit=99999`,
			method: "get",
			data: params,
			onStart: actions.GET_SAVINGS_TRX_START,
			onSuccess: actions.GET_SAVINGS_TRX_DONE,
			onError: actions.GET_SAVINGS_TRX_FAILED,
		},
	};
}

export function getSavingsDetails(params) {
	return {
		type: config.apiRequestStart,
		payload: {
			baseUrl: config.apiWalletBaseUrl,
			url: `${config.apiGetSingleGoalSavings}?hash=${params.hash}&email=${params?.email}`,
			method: "get",
			data: params,
			onStart: actions.GET_SAVINGS_DETAILS_START,
			onSuccess: actions.GET_SAVINGS_DETAILS_DONE,
			onError: actions.GET_SAVINGS_DETAILS_FAILED,
		},
	};
}

export function selectSavingsItem(payload) {
    return {
        type: actions.SELECT_SAVINGS_ITEM,
		payload,
    }
}

export function getAllFlexSavings(params) {
	const {status, ...rest} = params
	const newParams = !status ? {...rest} : {...params}
	return {
		type: config.apiRequestStart,
		payload: {
			baseUrl: config.apiWalletBaseUrl,
			url:`${config.apiGetAllGoalSavings}?${new URLSearchParams(newParams).toString()}`,
			method: "get",
			data: params,
			onStart: actions.GET_ALL_FLEX_SAVINGS_START,
			onSuccess: actions.GET_ALL_FLEX_SAVINGS_DONE,
			onError: actions.GET_ALL_FLEX_SAVINGS_FAILED,
		},
	};
}
