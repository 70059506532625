import React from 'react';
import Header from 'components/common/header.jsx';
import IdleChecker from "hooks/useIdleChecker";
import Environment from "components/__new/common/Environment";

const NullLayout = (props) => {
    return ( 
        <div>
            <Environment local={false} develop={true} staging={true} production={true}>
                <IdleChecker />
            </Environment>

            <Header />
            <div>
                {props.children}
            </div>
        </div>
     );
}
 
export default NullLayout;