import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from "react-redux";
import SearchFilter from 'components/common/filter';
import Download from 'components/common/download';
import Pagination from 'components/common/pagination';
import NumberFormat from 'utils/numberFormat';
import DashboardBoxes from 'components/common/dashboard-boxes';
import CurrencyFormat from 'utils/currencyFormat';
import { ReactComponent as IconValue } from "assets/images/icons/project-icons/business-transactions-value.svg";
import { ReactComponent as IconVolume } from "assets/images/icons/project-icons/business-transactions-volume.svg";
import { ReactComponent as IconCredit } from "assets/images/icons/project-icons/business-transactions-credit.svg";
import { ReactComponent as IconDebit } from "assets/images/icons/project-icons/business-transactions-debit.svg";
import { useHistory, Link } from 'react-router-dom';
import { downloadEmployerTransactions, getEmployerTransactions, getEmployerTransactionsMetrics, searchFilterEmployerTransactions } from 'store/entities/employers/action';
import queryString from "query-string";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DateFormat from 'utils/dateFormat';
import { useGetBanks } from 'hooks/useGetBanks';


const BusinessTransactionsList = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { banks, isLoading } = useGetBanks();
    
    // eslint-disable-next-line
    const [transactionParams, setTransactionParams] = useState({
        page: 1,
        limit: 10,
    });
    
    const transactionProps = {
        totalValue: {
			iconBg: "bg-yellow-100",
			icon: (
				<IconValue className="customizeSVG m-auto w-5 h-5" />
			),
			titleText: "Total Transaction Value",
			mainText: "-",
		},
		totalVolume: {
			iconBg: "bg-gray-200",
			icon: (
				<IconVolume className="customizeSVG m-auto w-5 h-5" />
			),
			titleText: "Total Transaction Volume",
			mainText: "-",
		},
		totalCredit: {
			iconBg: "bg-green-300",
			icon: (
				<IconCredit className="customizeSVG m-auto w-5 h-5" />
			),
			titleText: "Total Credit",
			mainText: "-",
		},
		totalDebit: {
			iconBg: "bg-red-300",
			icon: (
				<IconDebit className="customizeSVG m-auto w-5 h-5" />
			),
			titleText: "Total Debit",
			mainText: "-",
		},
    }
    
    const { selectedEmployer, employerTransactionsMetricsLoading, employerTransactionsMetricsData: transMetrics, employerTransactionsLoading, employerTransactionsData, downloadEmployerTransactionsLoading, downloadEmployerTransactionsData } = useSelector(s => s.entities.employers);

    const refinedData = {
        ...employerTransactionsData?.data,
        docs: employerTransactionsData?.data?.data,
    }
    
    const columns = [
        { label: "Date", key: "date" },
        { label: "Transaction ID", key: "transactionId" },
        { label: "Amount", key: "amount" },
        { label: "Current Balance", key: "currentBalance" },
        { label: "Transaction Type", key: "type" },
        { label: "Description", key: "description" },
        { label: "Status", key: "status" },
    ];
    const downloadRef = useRef();

    const fetchTransactions = () => {
        const qs = queryString.parse(props.location.search);
        let params = transactionParams;
        
        if ((qs.page !== undefined) && (!isNaN(qs.page)) && (qs.page > 0)){
            params = {...params, page: qs.page};
        }
        else{
            params = {...params, page: 1};
        }
        
        if ((qs.limit !== undefined) && (!isNaN(qs.limit)) && (qs.limit > 0)){
            params = {...params, limit: qs.limit};
        }
        else{
            params = {...params, limit: 10};
        }
        
		if (qs.keyword !== undefined && qs.keyword !== null) {
			params = { ...params, keyword: qs.keyword };
		} else {
			params = { ...params, keyword: "" };
		}
        
		if (qs.startDate !== undefined && qs.startDate !== null) {
			params = { ...params, startDate: qs.startDate };
		} else {
			params = { ...params, startDate: "" };
		}
        
		if (qs.endDate !== undefined && qs.endDate !== null) {
			params = { ...params, endDate: qs.endDate };
		} else {
			params = { ...params, endDate: "" };
		}
        
		if (qs.status !== undefined && qs.status !== null) {
			params = { ...params, status: qs.status };
		} else {
			params = { ...params, status: "" };
		}
        
		if (qs.type !== undefined && qs.type !== null) {
			params = { ...params, type: qs.type };
		} else {
			params = { ...params, type: "" };
		}
        
        setTransactionParams(params);
        dispatch(getEmployerTransactions({
            id: selectedEmployer?._id,
            payload: params
        }));
    }

    const transformDataDownload = (data) => {
        const newData = data?.transactions?.map((transaction) => {
            
			return {
				...transaction,
                date: DateFormat(transaction.createdAt),
				transactionId: transaction._id,
                amount: CurrencyFormat(transaction.amount / 100, "no-kobo"),
                currentBalance: CurrencyFormat(transaction.newBalance / 100, "no-kobo"),
                type: transaction?.type,
                description: transaction?.description,
                status: transaction?.status
			}
		})
		return newData;
	}

    
    const getBusinessName = (employer) => {
        if(employer?.companyDetails?.length > 0) {
            return employer?.companyDetails[0]?.companyName;
        } else if(employer?.company?.companyName?.length) {
            return employer?.company?.companyName;
        } else if (employer?.accountName) {
            return employer?.accountName;
        }
        return "-"
    }

    const getBankName = (bankCode) => {
        const bank = banks.find((bankItem) => (bankItem?.institutionCode || bankItem?.v1InstitutionCode) === bankCode);
        return bank;
    }
    
    useEffect(() => {
        if(selectedEmployer?._id?.length) {
            dispatch(getEmployerTransactionsMetrics(selectedEmployer?._id))
            fetchTransactions()
        } else {
            history.push("/businesses");
        }
        // eslint-disable-next-line
    }, [props.location])
    
    return (
        <div className='relative'>
            <div>
                <div className="lg:flex">
                    
                    <div className="w-full lg:w-1/2">
                        <div className="page-title capitalize">
                            {getBusinessName(selectedEmployer)}
                        </div>
                    </div>
                    <div className="w-full lg:w-1/2 flex space-x-4 mt-2 lg:mt-0 lg:justify-end">
                        <div>
                            <Download
                                tooltip="Download transactions"
                                format="csv"
                                filename="All Transactions.csv"
                                columns={columns}
                                ref={downloadRef}
                                click={() => {
                                    dispatch(downloadEmployerTransactions({
                                        id: selectedEmployer?._id,
                                        payload: {page: 1, limit: 2000, download: true}
                                    })).then(() => {
                                        downloadRef.current.processDownload();
                                    });
                                }}
                                loading={downloadEmployerTransactionsLoading}
                                response={transformDataDownload(downloadEmployerTransactionsData?.data)}
                            />
                        </div>
                        <div>
                            <SearchFilter
                                search={{
                                    name: "keyword",
                                    placeholder: "Keyword search",
                                }}
                                filters={[
                                    {
                                        title: "Transaction Type",
                                        name: "type",
                                        dataType: "checkbox",
                                        options: [
                                            {
                                                display: "Credit",
                                                payload: "credit",
                                            },
                                            {
                                                display: "Debit",
                                                payload: "debit",
                                            },
                                        ],
                                    },
                                    {
                                        title: "Status",
                                        name: "status",
                                        dataType: "checkbox",
                                        options: [
                                            {
                                                display: "Successful",
                                                payload: "successful",
                                            },
                                            {
                                                display: "Failed",
                                                payload: "failed",
                                            },
                                            {
                                                display: "Pending",
                                                payload: "pending",
                                            },
                                        ],
                                    },
                                    {
                                        title: "Date",
                                        dataType: "date-range",
                                        options: {
                                            from: {
                                                name: "startDate",
                                                placeholder: "Start Date",
                                            },
                                            to: {
                                                name: "endDate",

                                                placeholder: "End Date",
                                            },
                                        },
                                    },
                                ]}
                            />
                        </div>
                    </div>
                </div>
                
                <div className="my-10">
						<div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-6">
							<DashboardBoxes
								data={transactionProps?.totalValue}
								loading={employerTransactionsMetricsLoading}
								result={
                                    transMetrics?.data?.metrics?.totalTransactionValue ? CurrencyFormat(transMetrics?.data?.metrics?.totalTransactionValue / 100) : "0"
								}
							/>
							<DashboardBoxes
								data={transactionProps?.totalVolume}
								loading={employerTransactionsMetricsLoading}
								result={
									transMetrics?.data?.metrics?.totalTransactionVolume ? NumberFormat(transMetrics?.data?.metrics?.totalTransactionVolume) : "0"
								}
							/>
							<DashboardBoxes
								data={transactionProps?.totalCredit}
								loading={employerTransactionsMetricsLoading}
								result={
                                    transMetrics?.data?.metrics?.totalCredit ? CurrencyFormat(transMetrics?.data?.metrics?.totalCredit / 100) : "0"
								}
							/>
							<DashboardBoxes
								data={transactionProps?.totalDebit}
								loading={employerTransactionsMetricsLoading}
								result={
                                    transMetrics?.data?.metrics?.totalDebit ? CurrencyFormat(transMetrics?.data?.metrics?.totalDebit / 100) : "0"
								}
							/>
						</div>
					</div>

                <div className="mt-2">
                    <div className="">
                    
                    <div className="my-3 font-medium md:text-lg">
                        Transaction Details
                    </div>
                    
                        <div className="table-container">
                            <table className="table table-auto table-rounded table-border">
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Transaction ID</th>
                                        <th className='whitespace-nowrap'>Transaction Type</th>
                                        <th className='whitespace-nowrap'>Sender Bank Name</th>
                                        <th className='whitespace-nowrap'>Destination Bank Name</th>
                                        <th className='whitespace-nowrap'>Destination Bank Account Name</th>
                                        <th className='whitespace-nowrap'>Destination Bank Account Number</th>
                                        <th className='whitespace-nowrap'>Previous Balance</th>
                                        <th>Amount</th>
                                        <th className='whitespace-nowrap'>Current Balance</th>
                                        <th>Description</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    
                                    {(employerTransactionsLoading === true || isLoading) && 
                                        <tr>
                                            <td colSpan="12">
                                                <div className="table-info">
                                                    <FontAwesomeIcon icon="spinner" spin />
                                                    <div className="font-bold uppercase">
                                                        Loading
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    }

                                    {((employerTransactionsLoading === false) && (!isLoading) && (!refinedData.docs)) && 
                                        <tr>
                                            <td colSpan="12">
                                                <div className="table-info">
                                                    <FontAwesomeIcon icon="unlink" />
                                                    <div className="font-bold uppercase">
                                                        An error occurred
                                                        <br />
                                                        Please try again later.
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    }
                                    
                                    {((employerTransactionsLoading === false) && (!isLoading) && (refinedData.docs) && (refinedData.docs.length <= 0)) && 
                                        <tr>
                                            <td colSpan="12">

                                                {(refinedData.page <= refinedData.totalPages || refinedData?.docs?.length < 1) &&
                                                    <div className="table-info">
                                                        <FontAwesomeIcon icon="list" />
                                                        <div className="font-bold uppercase">
                                                            No results found
                                                        </div>
                                                    </div>
                                                }
                                                
                                                {(refinedData.page > refinedData.totalPages && refinedData?.docs?.length > 0) &&
                                                    <div className="table-info">
                                                        <FontAwesomeIcon icon="exclamation-triangle" />
                                                        <div className="font-bold uppercase">
                                                            Invalid Page Number
                                                        </div>
                                                        <div className="mt-2">
                                                            <Link to="?page=1" className="btn btn-ep-blue btn-sm mx-auto">Goto Page 1</Link>
                                                        </div>
                                                    </div>
                                                }

                                            </td>
                                        </tr>
                                    }
                                    
                                    {((employerTransactionsLoading === false) && (!isLoading) && (refinedData?.docs?.length > 0)) && 
                                        refinedData?.docs?.map((transaction, index) => {
                                            let senderBankName = "N/A";
                                            let destinationBankName = "N/A";
                                            let destinationBankAccountNumber = "N/A";
                                            let destinationBankAccountName = "N/A";
                                            let senderAccountNumber = "";
                                            let senderAccountName = "";
                                            const DEFAULT_EARNIPAY = "Earnipay/Stanbic";                                          
                                            const isCredit = transaction.type === 'credit'
                                            const getSenderBankName = () => {
                                                if (isCredit) {
                                                    destinationBankName = DEFAULT_EARNIPAY;

                                                    if (transaction?.product === "general" && transaction?.sourceType === "ewa") {
                                                        senderBankName = "Earnipay ODP";
                                                        destinationBankName = DEFAULT_EARNIPAY;
                                                        return;
                                                    }

                                                    if (transaction?.sourceType === "card") {
                                                        senderBankName = "N/A"
                                                        return;
                                                    }

                                                    if (transaction?.sourceType === "intra-transfer") {
                                                        senderBankName = DEFAULT_EARNIPAY;
                                                        destinationBankName = DEFAULT_EARNIPAY;
                                                    }

                                                    if (transaction?.product === "general" && transaction?.sourceType !== "ewa" && transaction?.sourceType !== "intra-transfer") {
                                                        destinationBankName = DEFAULT_EARNIPAY;
                                                        const bank = getBankName(transaction?.senderBankCode);
                                                        senderBankName = bank?.institutionName || "N/A";
                                                        senderAccountNumber = transaction?.senderAccountNumber;
                                                        senderAccountName = transaction?.senderAccountName;
                                                        return;
                                                    }
                                                    return;
                                                };

                                                if (transaction?.sourceType === "bill-payment" || transaction?.product === "charge") {
                                                    return;
                                                }

                                                if (transaction?.product === "general" && transaction?.sourceType === "ewa") {
                                                    senderBankName = "Earnipay ODP";
                                                    destinationBankName = DEFAULT_EARNIPAY;
                                                    return;
                                                }

                                                if (transaction?.product === "general" && transaction?.sourceType !== "ewa") {
                                                    senderBankName = DEFAULT_EARNIPAY;
                                                    destinationBankName = transaction?.recipient?.bankName || transaction?.destinationBankName;
                                                    destinationBankAccountName = transaction?.recipient?.accountName || transaction?.destinationAccountName;
                                                    destinationBankAccountNumber = transaction?.recipient?.accountNumber || transaction?.destinationAccountNumber;
                                                    return;
                                                }

                                                if (transaction?.product === "wallet" || transaction?.sourceType === "intra") {
                                                    senderBankName = DEFAULT_EARNIPAY;
                                                    destinationBankName = DEFAULT_EARNIPAY;
                                                    return;
                                                }
                                            }
                                            getSenderBankName();
                                            
                                            return (
                                                <tr key={index}>
                                                <td>
                                                    {DateFormat(transaction.createdAt)}
                                                </td>
                                                <td>{transaction._id}</td>
                                                <td>
                                                    <div className={`capitalize flex justify-center px-3 py-1 rounded-full whitespace-nowrap text-xs w-[72px] ${isCredit ? "bg-[#E5FDFF]" : "bg-[#FEE2E2]"}`}>
                                                        {transaction?.type}
                                                    </div>
                                                </td>
                                                <td>{senderAccountName ? (
                                                    <div>
                                                        <p style={{ fontWeight: "bold" }}>{senderAccountName}</p>
                                                        <p>{senderBankName}</p>
                                                        <p>{senderAccountNumber}</p>
                                                    </div>
                                                ) : `${senderBankName}`}</td>
                                                <td>{destinationBankName}</td>
                                                <td>{destinationBankAccountName}</td>
                                                <td>{destinationBankAccountNumber}</td>
                                                <td>
                                                    <div className="whitespace-nowrap">             {CurrencyFormat(transaction.previousBalance / 100)}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="whitespace-nowrap" style={{ color: isCredit ? "#017928" : "#C61D1E" }}>
                                                        {isCredit ? "+" : "-"}{CurrencyFormat(transaction.amount / 100)}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="whitespace-nowrap">             {CurrencyFormat(transaction.newBalance / 100)}
                                                    </div>
                                                </td>
                                                <td>{transaction.description}</td>
                                                <td><StatusBadge status={transaction.status} /></td>
                                            </tr>
                                            )
                                            }
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>

                        {((employerTransactionsLoading === false) && (!isLoading) && (refinedData.docs) && (refinedData.docs.length > 0)) && 
                            <div>
                                <Pagination data={refinedData} url={props.location.search} limit={transactionParams.limit} />
                            </div>
                        }

                    </div>
                </div>
            </div>
        </div>
    )
}

const StatusBadge = ({status}) => {
    const isProcessing = status === "pending";
    const isSuccessful = status === "successful";
    
    return (
        <div className={`capitalize flex justify-center px-3 py-1 rounded-full whitespace-nowrap text-xs w-[72px] ${isSuccessful ? "bg-[#E5FDFF]" : isProcessing ? "bg-[#FFFCDA]" : "bg-[#FEE2E2]"}`}>
            {status}
        </div>
    )
}

export default BusinessTransactionsList;