
export const slug = (text) => {
    
    if ((typeof text !== undefined) && (text !== undefined) && (text !== null)){

        return text
            .toLowerCase()
            .replace(/ /g,'-')
            .replace(/[^a-z0-9-]+/g,'')
            .replace(/-{2,}/g,'-')
            ;

    }

};
