import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
	TabSettingsWide,
	TabSettingsMobile,
} from "components/common/settingsTab";
import { customizeSVG } from "utils/customizeSVG.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class List extends Component {
	render() {
		customizeSVG();

		return (
			<div>
				<TabSettingsMobile />

				<div className="box">
					<div className="flex">
						<TabSettingsWide />

						<div className="lg:w-3/4 xl:w-4/5 lg:pl-6 flex-grow">
							<div className="grid sm:grid-cols-2">
								<div className="page-title">Account Settings</div>
								<div></div>
							</div>

							<div className="mt-6">
								<div className="flex flex-col space-y-6">
									<div className="w-full flex justify-between">
										<div>
											<div className="font-bold">Edit Profile</div>
											<div className="text-fade">
												Change Account Information
											</div>
										</div>
										<div>
											<Link
												to="/account/profile"
												className="btn btn-ep-blue btn-md">
												<FontAwesomeIcon icon="edit" className="mr-2" />
												Edit
											</Link>
										</div>
									</div>

									<div className="w-full flex justify-between">
										<div>
											<div className="font-bold">Change Password</div>
											<div className="text-fade">
												Change your admin login password
											</div>
										</div>
										<div>
											<Link
												to="/account/profile"
												className="btn btn-ep-blue btn-md">
												<FontAwesomeIcon icon="edit" className="mr-2" />
												Change
											</Link>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(List);
