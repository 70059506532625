import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Table from "components/__new/common/Table";
import processParams from "utils/processParams";
import useUrlQueryParams from "hooks/useUrlQueryParams";
import { TableContent } from "components/__new/common/TableContent";
import SearchFilter from "components/common/filter";
import Download from "components/common/download";
import Pagination from "components/common/pagination";
import DialogBox from 'utils/dialogBox';
import { renderStatusColor } from "utils/statusColor";
import { ReactComponent as IconCircleQuestion } from "assets/images/icons/project-icons/circle-question.svg";
import { ReactComponent as IconCircleTimes } from "assets/images/icons/project-icons/circle-times.svg";
import { ReactComponent as IconCircleThrash } from "assets/images/icons/project-icons/circle-thrash.svg";
import Maybe from "components/__new/common/Maybe";
import DropdownNew from "utils/dropdown-new";
import PointActivityForm from "components/__new/rewards/PointActivityForm";
import RewardsDashboard from "components/__new/rewards/RewardsDashboard";
import RewardsTab from "components/__new/rewards/RewardsTab";
import NumberFormat from "utils/numberFormat";
import AssignActivityForm from "components/__new/rewards/AssignActivityForm";
import * as rewardsActions from "store/entities/rewards/action";
import DateFormat from "utils/dateFormat";


const List = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const {urlQueryParams} = useUrlQueryParams();
    const { rewardPointsLoading, rewardPoints, rewardPointsDownloadLoading, rewardPointsDownload } = useSelector((s) => s.entities.rewards);
    const { userType } = useSelector((state) => state.auth.userPersist.data);
    

    const downloadRef = useRef();
    const [isOpenPointActivity, setIsOpenPointActivity] = useState(false);
    const [isOpenAssignPoint, setIsOpenAssignPoint] = useState(false);
    const [selectedRewardPoint, setSelectedRewardPoint] = useState({});
    const [listParams, setListParams] = useState({
        page: 1,
        limit: 20,
    });


    const getRewardPoints = () => {
        let params = {...listParams};

        params = processParams(urlQueryParams, params, "page", "number", 1, "page");
        params = processParams(urlQueryParams, params, "limit", "number", 20, "limit");
        params = processParams(urlQueryParams, params, "search", "string", "", "keyword");
        params = processParams(urlQueryParams, params, "status", "string", "", "status");
        params = processParams(urlQueryParams, params, "dateFrom", "string", "", "dateFrom");
        params = processParams(urlQueryParams, params, "dateTo", "string", "", "dateTo");
        
        setListParams(params);
        dispatch(rewardsActions.getRewardPoints(params));
    }


    const transformDownloadResponse = (rewards) => {
        const data = rewards?.map((reward) => {
            return {
                ...reward,
                isActive: reward.isActive ? "enabled":"disabled",
            }
        });
        return data;
    }


    const createPointActivity = () => {
        setIsOpenPointActivity(true);
        setSelectedRewardPoint({});
    }


    const assignPoints = () => {
        setIsOpenAssignPoint(true);
    }


    const editRewardPoint = (rewardPoint) => {
        setIsOpenPointActivity(true);
        setSelectedRewardPoint(rewardPoint);
    }


    const enableRewardPoint = async (rewardPoint) => {
        const result = await DialogBox({
            icon: <IconCircleQuestion className="w-16 h-16" />,
			title: "Enable This Point Activity",
			content: "Are you sure you want to enable this point activity?",
			buttonYes: "Yes",
		});
        if (result) {
            dispatch(rewardsActions.enableRewardPoints(rewardPoint._id)).then(() => {
                getRewardPoints();
            })
        }
    }


    const disableRewardPoint = async (rewardPoint) => {
        const result = await DialogBox({
            icon: <IconCircleTimes className="w-16 h-16" />,
			title: "Disable This Point Activity",
			content: "Are you sure you want to disable this point activity?",
			buttonYes: "Yes",
		});
        if (result) {
            dispatch(rewardsActions.disableRewardPoints(rewardPoint._id)).then(() => {
                getRewardPoints();
            })
        }
    }


    const approvePoint = async (rewardPoint) => {
        const result = await DialogBox({
			title: "Approve This Point Activity",
			content: "Are you sure you want to approve this point activity?",
			buttonYes: "Yes",
		});
        if (result) {
            dispatch(rewardsActions.approvePointActivity(rewardPoint._id)).then(() => {
                getRewardPoints();
            })
        }
    }


    const deleteRewardPoint = async (rewardPoint) => {
        const result = await DialogBox({
			icon: <IconCircleThrash className="w-16 h-16" />,
            title: "Delete This Point Activity",
			content: "Are you sure you want to delete this point activity? This action cannot be undone",
			buttonYes: "Yes",
		});
        if (result) {
            dispatch(rewardsActions.deleteRewardPoints(rewardPoint._id)).then(() => {
                getRewardPoints();
            })
        }
    }


    useEffect(() => {
        getRewardPoints();
        // eslint-disable-next-line
    }, [location]);


    return (
        <div>

            
            <PointActivityForm
                isOpen={isOpenPointActivity}
                setIsOpen={setIsOpenPointActivity}
                selectedRewardPoint={selectedRewardPoint}
                getRewardPoints={getRewardPoints}
            />


            <AssignActivityForm
                isOpen={isOpenAssignPoint}
                setIsOpen={setIsOpenAssignPoint}
            />


            <div className="lg:flex">
                <div className="w-full lg:w-1/2">
                    <div className="page-title capitalize">
                        Rewards
                    </div>
                </div>
                <div className="w-full lg:w-1/2 flex space-x-4 mt-2 lg:mt-0 lg:justify-end">
                    <div>
                        <Download
                            tooltip="Download point activity"
                            filename="Reward points.csv"
                            format="csv"
                            columns={[
                                { label: "Point Activity", key: "activityName" },
                                { label: "Description", key: "activityDescription" },
                                { label: "Category", key: "activityCategory" },
                                { label: "Points to Earn", key: "numOfPoints" },
                                { label: "Activity Status", key: "isActive" },
                                { label: "Approval Status (Point Activity)", key: "status" },
                                { label: "Date of Point Activity", key: "createdAt" },
                            ]}
                            ref={downloadRef}
                            click={() => {
                                dispatch(rewardsActions.downloadRewardPoints({...listParams, page: 1, limit: 2000, download: true})).then(() => {
                                    downloadRef.current.processDownload();
                                });
                            }}
                            loading={rewardPointsDownloadLoading}
                            response={transformDownloadResponse(rewardPointsDownload?.data?.pointActivities)}
                        />
                    </div>
                    <div> 
                        <SearchFilter
                            search={{
                                name: "search",
                                placeholder: "Keyword search",
                            }}
                            filters={[
                                {
                                    title: "Status",
                                    name: "status",
                                    dataType: "select",
                                    options: [
                                        {
                                            display: "All",
                                            payload: "",
                                        },
                                        {
                                            display: "Pending",
                                            payload: "pending",
                                        },
                                        {
                                            display: "Approved",
                                            payload: "approved",
                                        },
                                        {
                                            display: "Rejected",
                                            payload: "rejected",
                                        },
                                    ],
                                },
                                {
                                    title: "Date Range",
                                    dataType: "date-range",
                                    options: {
                                        from: {
                                            name: "dateFrom",
                                            placeholder: "Start Date",
                                        },
                                        to: {
                                            name: "dateTo",
                                            placeholder: "End Date",
                                        },
                                    },
                                },
                            ]}
                        />
                    </div>
                </div>
            </div>


            <RewardsDashboard />


            <RewardsTab />


            <div className="mt-6 lg:flex lg:items-center">
                <div className="w-full lg:w-1/2">
                    <div className="page-title-mini">
                        Point Activity Data
                    </div>
                </div>
                <div className="w-full lg:w-1/2 flex space-x-4 mt-2 lg:mt-0 lg:justify-end">
                    <button type="button" onClick={assignPoints} className="btn btn-md btn-white">
                        Assign Points
                    </button>
                    <button type="button" onClick={createPointActivity} className="btn btn-md btn-ep-blue">
                        <FontAwesomeIcon icon="plus" className="mr-2" />
                        Create Point Activity
                    </button>
                </div>
            </div>


            <div className="mt-6">
                <Table>
                    <Table.Head>
                        <th>Date</th>
                        <th>Point Activity</th>
                        <th>Description</th>
                        <th>Category</th>
                        <th>Points to Earn</th>
                        <th>Activity Status</th>
                        <th>Approval Status</th>
                        <th>Actions</th>
                    </Table.Head>
                    <Table.Body>

                        <TableContent
                            loading={rewardPointsLoading}
                            loadingText="Loading reward points"
                            data={rewardPoints?.data?.pointActivities}
                            total={rewardPoints?.data?.totalDocs}
                            colspan={8}
                        />
                        
                        <Maybe condition={rewardPointsLoading === false && rewardPoints?.data?.pointActivities?.length > 0}>
                            {rewardPoints?.data?.pointActivities?.map((rewardPoint, index) =>
                                <tr key={index}>
                                    <td>
                                        {DateFormat(rewardPoint.createdAt)}
                                    </td>
                                    <td>
                                        {rewardPoint?.activityName ?? "-"}
                                    </td>
                                    <td>
                                        {rewardPoint?.activityDescription ?? "-"}
                                    </td>
                                    <td>
                                        {rewardPoint?.activityCategory ?? "-"}
                                    </td>
                                    <td>
                                        {NumberFormat(rewardPoint?.numOfPoints) ?? "-"}
                                    </td>
                                    <td>
                                        <div className={"label uppercase " + (rewardPoint?.isActive ? "label-green":"label-red")}>
                                            {rewardPoint?.isActive ? "Enabled": "Disabled"}
                                        </div>
                                    </td>
                                    <td>
                                        <div className={"label uppercase " + renderStatusColor(rewardPoint?.status)}>
                                            {rewardPoint?.status}
                                        </div>
                                    </td>
                                    <td>
                                        <DropdownNew>
                                            <></>
                                            <div>
                                                <div onClick={() => editRewardPoint(rewardPoint)} className="dropdown-item">Edit</div>
                                                <Maybe condition={rewardPoint?.status !== "pending"}>
                                                    <Maybe condition={rewardPoint?.isActive === false}>
                                                        <div onClick={() => enableRewardPoint(rewardPoint)} className="dropdown-item">Enable</div>
                                                    </Maybe>
                                                    <Maybe condition={rewardPoint?.isActive === true}>
                                                        <div onClick={() => disableRewardPoint(rewardPoint)} className="dropdown-item">Disable</div>
                                                    </Maybe>
                                                    <div onClick={() => deleteRewardPoint(rewardPoint)} className="dropdown-item text-red-500">Delete</div>
                                                </Maybe>
                                                <Maybe condition={rewardPoint?.isActive === false && rewardPoint?.status === "pending" && userType === "super-admin"}>
                                                    <div onClick={() => approvePoint(rewardPoint)} className="dropdown-item">Approve</div>
                                                </Maybe>
                                            </div>
                                        </DropdownNew>
                                    </td>
                                </tr>
                            )}
                        </Maybe>

                    </Table.Body>
                </Table>

                <Maybe condition={rewardPointsLoading === false && rewardPoints?.data?.pointActivities?.length > 0}>
                    <Pagination data={rewardPoints?.data} limit={listParams.limit} />
                </Maybe>
            </div>


        </div>
    )
}


export default List;