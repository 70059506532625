import React from 'react'
import { NavLink } from 'react-router-dom'

const TransactionsPageRouter = () => {
    return (
        <div className="bg-white overflow-auto">
            <div className="flex text-center space-x-0">
                <NavLink
                    to="/transactions/withdrawals/"
                    className="tab px-10 md:px-20 flex-shrink-0">
                    Withdrawals
                </NavLink>
                <NavLink
                    to="/transactions/wallet/"
                    className="tab px-10 md:px-20 flex-shrink-0">
                    Wallet
                </NavLink>
                <NavLink
                    to="/transactions/credit/"
                    className="tab px-10 md:px-20 flex-shrink-0">
                    Credit
                </NavLink>
            </div>
        </div>
    )
}

export default TransactionsPageRouter