export const GET_ALL_WITHDRAWALS = "GET_ALL_WITHDRAWALS";
export const GET_ALL_WITHDRAWALS_START = "GET_ALL_WITHDRAWALS_START";
export const GET_ALL_WITHDRAWALS_FAILED = "GET_ALL_WITHDRAWALS_FAILED";

export const DOWNLOAD_GET_ALL_WITHDRAWALS = "DOWNLOAD_GET_ALL_WITHDRAWALS";
export const DOWNLOAD_GET_ALL_WITHDRAWALS_START = "DOWNLOAD_GET_ALL_WITHDRAWALS_START";
export const DOWNLOAD_GET_ALL_WITHDRAWALS_FAILED = "DOWNLOAD_GET_ALL_WITHDRAWALS_FAILED";

export const GET_ALL_WITHDRAWALS_COUNT = "GET_ALL_WITHDRAWALS_COUNT";
export const GET_ALL_WITHDRAWALS_COUNT_START = "GET_ALL_WITHDRAWALS_COUNT_START";
export const GET_ALL_WITHDRAWALS_COUNT_FAILED = "GET_ALL_WITHDRAWALS_COUNT_FAILED";

export const EMPLOYEE_WITHDRAWALS = "EMPLOYEE_WITHDRAWALS";
export const EMPLOYEE_WITHDRAWALS_START = "EMPLOYEE_WITHDRAWALS_START";
export const EMPLOYEE_WITHDRAWALS_FAILED = "EMPLOYEE_WITHDRAWALS_FAILED";

export const RETRY_WITHDRAWAL = "RETRY_WITHDRAWAL";
export const RETRY_WITHDRAWAL_START = "RETRY_WITHDRAWAL_START";
export const RETRY_WITHDRAWAL_FAILED = "RETRY_WITHDRAWAL_FAILED";

export const EMPLOYEE_WALLET = "EMPLOYEE_WALLET";
export const EMPLOYEE_WALLET_START = "EMPLOYEE_WALLET_START";
export const EMPLOYEE_WALLET_FAILED = "EMPLOYEE_WALLET_FAILED";

export const GET_WALLET_SUMMARY = "GET_WALLET_SUMMARY";
export const GET_WALLET_SUMMARY_START = "GET_WALLET_SUMMARY_START";
export const GET_WALLET_SUMMARY_FAILED = "GET_WALLET_SUMMARY_FAILED";

export const GET_WALLET_TRANSACTIONS = "GET_WALLET_TRANSACTIONS";
export const GET_WALLET_TRANSACTIONS_START = "GET_WALLET_TRANSACTIONS_START";
export const GET_WALLET_TRANSACTIONS_FAILED = "GET_WALLET_TRANSACTIONS_FAILED";

export const DOWNLOAD_WALLET_TRANSACTIONS = "DOWNLOAD_WALLET_TRANSACTIONS";
export const DOWNLOAD_WALLET_TRANSACTIONS_START = "DOWNLOAD_WALLET_TRANSACTIONS_START";
export const DOWNLOAD_WALLET_TRANSACTIONS_FAILED = "DOWNLOAD_WALLET_TRANSACTIONS_FAILED";

export const RELOAD_PAGE_RESET = "RELOAD_PAGE_RESET";