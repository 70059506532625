import React from 'react'
import Maybe from '../common/Maybe'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactComponent as Xclose } from "assets/images/icons/project-icons/xclose.svg";
import DateFormat from 'utils/dateFormat';
import ContentLoading from "../common/ContentLoading";


const UserDetailsView = ({copyItem, user, detailKeyClass, detailValueClass, toggleManualNameUpdate, Upload, isNameUpdateManual, onClose, loading, viewUserTransactions}) => {
    
    const isActiveUser = user?.kycStatus === "approved"
	const isPendingUser = user?.kycStatus === "pending"
	const isRejectedUser = user?.kycStatus === "rejected"
    
    return (
        <div className='w-full'>
            <div>
                <div className="flex justify-between">
                    <div className="font-recoleta font-semibold text-2xl capitalize">
                        <ContentLoading loading={loading} data={(user?.firstName ?? "-") + " " + (user?.lastName ?? "")} />
                    </div>
                    <div
                        onClick={onClose}
                        className="w-8 h-8 flex group hover:bg-red-400 rounded-lg cursor-pointer">
                        <Xclose className="customizeSVG group-hover:text-white m-auto" />
                    </div>
                </div>
                <div className="text-fade text-sm">
                    <ContentLoading loading={loading} data={user?.email ?? "-"} />
                </div>
                
                <div className="mt-3 flex items-center">
                    <div
                        className="w-2 h-2 my-auto mr-2 rounded-full"
                        style={{ backgroundColor: isActiveUser ? "#398D94" : isPendingUser ? "#F49D1B" : isRejectedUser ? "#DC2626" : "" }}></div>
                    <div className="my-auto text-xs">{isActiveUser ? "Active" : isPendingUser ? "Pending" : isRejectedUser ? "Rejected" : "-"}</div>
                </div>
            </div>
            
            <Maybe condition={loading === false}>
                <div className="mt-5">
                    <button onClick={viewUserTransactions} className="btn btn-md btn-transparent-primary rounded-lg">View transactions</button>
                </div>
            </Maybe>

            <div className="w-full h-px mt-8 bg-gray-200"></div>
            
            <div className="font-semibold text-sm mt-5">User's Details</div>
            
            <div className="mt-5 grid grid-cols-2 gap-x-4">
                <div className="space-y-5">
                    <div className="my-auto">
                        <div className={detailKeyClass}>Account Name</div>
                        <div className={detailValueClass}>
                            <ContentLoading loading={loading} data={(user?.firstName ?? "-") + " " + (user?.lastName ?? "")} />
                        </div>
                    </div>
                    
                    <div className="my-auto">
                        <div className={detailKeyClass}>Phone Number</div>
                        <div className={detailValueClass}>
                            <ContentLoading loading={loading} data={user?.phoneNumber ?? "-"} />
                        </div>
                    </div>
                    
                    <div className="my-auto">
                        <div className={detailKeyClass}>
                            Date Joined
                        </div>
                        <div className={detailValueClass}>
                            <ContentLoading loading={loading} data={DateFormat(user?.createdAt) ?? "-"} />
                        </div>
                    </div>
                    
                    <div className="my-auto">
                        <div className={detailKeyClass}>
                            User ID
                        </div>
                        <div className="mt-1 font-medium capitalize text-sm flex items-center justify-start">
                            <ContentLoading loading={loading} data={user?._id ?? "-"} />
                            <Maybe condition={user?._id?.length > 0}>
                                <span onClick={() => copyItem(user?._id, "User ID")} className="ml-2 cursor-pointer z-10 text-ep-blue-deeper">
                                    <FontAwesomeIcon icon="copy" className="text-lg" />
                                </span>
                            </Maybe>
                        </div>
                    </div>
                </div>
                <div className="space-y-5">
                    <div className="my-auto">
                        <div className={detailKeyClass}>Account Number</div>
                        <div className={detailValueClass}>
                            <ContentLoading loading={loading} data={user?.accountNumber ?? "-"} />
                        </div>
                    </div>
                    
                    <div className="w-full my-auto">
                        <div className={detailKeyClass}>Points</div>
                        <div className="mt-1 font-medium text-md text-ep-primary-500">
                            <ContentLoading loading={loading} data={user?.points ?? "-"} />
                        </div>
                    </div>
                    
                    <div className="w-full my-auto">
                        <div className={detailKeyClass}>Wallet ID</div>
                        <div className="mt-1 font-medium text-md">
                            <ContentLoading loading={loading} data={user?.walletId ?? "-"} />
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="mt-7 grid grid-cols-2 gap-x-4">
                <Maybe condition={isNameUpdateManual === false}>
                    <button onClick={toggleManualNameUpdate} className="btn btn-md btn-transparent-primary rounded-[4px] flex items-center whitespace-nowrap">
                        Manual Update<span className="ml-2"><Upload width={15} /></span>
                    </button>
                </Maybe>
            </div>
        </div>
    )
}

export default UserDetailsView