import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactComponent as Xclose } from "assets/images/icons/project-icons/xclose.svg";
import { ReactComponent as IconCopy } from "assets/images/icons/project-icons/copy.svg";
import { toast } from "react-toastify";


const CreditTransactionDetails = ({control, onClose, loading}) => {
	const user = {
		_id: 918389283
	}
	const detailKeyClass = "text-xs"
	const detailValueClass = "mt-1 font-medium capitalize text-sm"

	const displayDetails = (details) => { 
		if (loading === true) {
			return <FontAwesomeIcon icon="spinner" spin className="text-md" />;
		}
		return details || "-";
	}
	
	const copyItem = (item, tag) => {
		navigator.clipboard.writeText(item);
		toast(`${tag} Copied`)
	}

	
	return (
		<div
			className={
				"max-w-md w-screen h-screen bg-white fixed z-50 top-0 bottom-0 shadow-xl transition-all duration-300 ease-in-out " +
				(control ? "right-0" : "-right-full")
			}>
			<div className="w-full h-full p-5 pb-10 pt-2 relative overflow-auto overscroll-contain">
				<div className="my-8">
					<div className='w-full'>
						<div>
							<div className="flex justify-between">
								<div className="font-recoleta font-semibold text-2xl capitalize">
									{/* {displayDetails(`${user?.firstName} ${user?.lastName}`)} */}
									View User Details
								</div>
								<div
									onClick={onClose}
									className="w-8 h-8 flex group hover:bg-red-400 rounded-lg cursor-pointer">
									<Xclose className="customizeSVG group-hover:text-white m-auto" />
								</div>
							</div>
						</div>
						
						<div className="w-full h-px mt-6 mb-6 bg-gray-200"></div>
						
						<div className="font-semibold text-sm">User Details</div>
						
						<div className="mt-5 grid grid-cols-2 gap-x-4">
							<div className="space-y-5">
								<div className="my-auto">
									<div className={detailKeyClass}>Name of User</div>
									<div className={detailValueClass}>
										Gift Ikpefan
										{/* {displayDetails(`${user?.firstName} ${user?.lastName}`)} */}
									</div>
								</div>
								
								<div className="my-auto">
									<div className={detailKeyClass}>Phone Number</div>
									<div className={detailValueClass}>
										{/* {displayDetails(user?.phoneNumber)} */}
										08033838383
									</div>
								</div>
							</div>
							<div className="space-y-5">
								<div className="my-auto">
									<div className={detailKeyClass}>Email Address</div>
									<div className="mt-1 font-medium text-sm">
										{/* {displayDetails(user?.accountNumber)} */}
										gift@gmail.com
									</div>
								</div>
								
								<div className="my-auto">
									<div className={detailKeyClass}>
										User ID
									</div>
									<div className="mt-1 font-medium capitalize text-sm flex items-center justify-start">
										{displayDetails(123454)}
										{/* <Maybe condition={user?._id?.length > 0}> */}
											<span onClick={() => copyItem(user?._id, "User ID")} className="ml-2 cursor-pointer z-10 text-ep-blue-deeper">
												<IconCopy />
											</span>
										{/* </Maybe> */}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}


export default CreditTransactionDetails;
