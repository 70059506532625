import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from 'react-router-dom';
import SearchFilter from 'components/common/filter';
import Download from 'components/common/download';
import { approveCustomer, downloadAllCustomers, getAllCustomers, searchAndFilterCustomers, toggleCustomerStatus } from 'store/entities/employees/action';
import CustomerDetailsModal from 'components/__new/customers/CustomerDetailsModal';
import ApproveRejectModal from 'components/__new/customers/ApproveRejectModal';
import DialogBox from 'utils/dialogBox';
import nameToSentenceCase from "utils/nameToSentenceCase";
import useUrlQueryParams from 'hooks/useUrlQueryParams';
import processParams from 'utils/processParams';
import TableFilterTabs from 'components/__new/customers/TableFilterTabs';
import CustomersTable from './CustomersTable';
import ActivateCustomerModal from 'components/__new/customers/ActivateCustomerModal';
import DeactivateCustomerModal from 'components/__new/customers/DeactivateCustomerModal';


const UsersList = (props) => {
    const dispatch = useDispatch();
    const { urlQueryParams } = useUrlQueryParams();
    const [isDetailsOpen, setIsDetailsOpen] = useState(false);
    
    const [isApproving, setIsApproving] = useState(false);
    const [isActionModalOpen, setIsActionModalOpen] = useState(false);
    const [reason, setReason] = useState("");
    
    const [usersParams, setUsersParams] = useState({
        page: 1,
        limit: 10,
    });
    const [activateModalOpen, setActivateModalOpen] = useState(false);
    const [deactivateModalOpen, setDeactivateModalOpen] = useState(false);
    
    const approveMode = isApproving === true;
    const toggleDetails = () => setIsDetailsOpen(!isDetailsOpen)
    const toggleActionModal = () => setIsActionModalOpen(!isActionModalOpen)
    const { singleCustomerLoading, singleCustomerData, allCustomersLoading, allCustomersData, selectedCustomer, toggleCustomerStatusLoading, downloadAllCustomersLoading, downloadAllCustomersData } = useSelector(s => s.entities.employees);
    
    const toggleActivateModal = () => setActivateModalOpen(!activateModalOpen);
    const toggleDeactivateModal = () => setDeactivateModalOpen(!deactivateModalOpen);

    const refinedData = {
        ...allCustomersData?.data,
        docs: allCustomersData?.data?.users?.map((user) => {
            
            const params = [
                {
                    label: 1,
                    value: user?.bvnValidated
                },
                {
                    label: 2,
                    value: user?.isFinalResult
                },
                {
                    label: 3,
                    value: user?.kycAddressVerificationFile?.length > 0 ? true : false
                },
            ]
            
            const kycLevel = params?.filter((item) => item.value === true)?.length
            
            return {
                ...user,
                kycLevel,
            }
        })
    }
    
    const columns = [
        { label: "Date Joined", key: "createdAt" },
        { label: "Date Updated", key: "updatedAt" },
        { label: "Full Name", key: "fullName" },
        { label: "Email Address", key: "email" },
        { label: "Phone Number", key: "phone" },
        {label: "BVN Validated", key: "bvnValidated"},
        { label: "Points", key: "points" },
        { label: "IsCustomerEmployee", key: "isEmployee" },
        { label: "User Status", key: "userStatus" },
        { label: "Account Tier", key: "accountTier" },
        { label: "KYC Status", key: "kycStatus" },
        { label: "User Type", key: "userType" },
    ];
    const downloadRef = useRef();

    const fetchCustomers = () => {
        let params = { ...usersParams };
        
        params = processParams(urlQueryParams, params, "page", "number", 1, "page");
        params = processParams(urlQueryParams, params, "limit", "number", 10, "limit");
		params = processParams(urlQueryParams, params, "status", "string", "", "status");
		params = processParams(urlQueryParams, params, "keyword", "string", "", "keyword");
		params = processParams(urlQueryParams, params, "dateFrom", "string", "", "dateFrom");
		params = processParams(urlQueryParams, params, "dateTo", "string", "", "dateTo");
		params = processParams(urlQueryParams, params, "kycStatus", "string", "", "kycStatus");
		params = processParams(urlQueryParams, params, "accountNumber", "string", "", "accountNumber");
		params = processParams(urlQueryParams, params, "pointFrom", "string", "", "pointFrom");
		params = processParams(urlQueryParams, params, "pointTo", "string", "", "pointTo");
		params = processParams(urlQueryParams, params, "userTier", "string", "", "userTier");
		params = processParams(urlQueryParams, params, "updatedFrom", "string", "", "updatedFrom");
		params = processParams(urlQueryParams, params, "updatedTo", "string", "", "updatedTo");
        
        setUsersParams(params);
        
        if(params?.keyword?.length || params?.dateFrom?.length || 
            params?.dateTo?.length || params?.kycStatus?.length || 
            params?.status?.length || params?.accountNumber?.length || 
            (params?.pointFrom?.length && params?.pointTo?.length) ||
            params?.userTier?.length || (params?.updatedFrom?.length && params?.updatedTo?.length)) {
            dispatch(searchAndFilterCustomers(params));
        } else {
            dispatch(getAllCustomers(params));
        }
        
    }
    
    const clearState = () => {
        if(isDetailsOpen) {
            setIsDetailsOpen(false);
        }
        if(isActionModalOpen) {
            toggleActionModal();
        }
        
        setReason("");
        fetchCustomers();
    }

    const transformDataDownload = (data) => {
        const newData = data?.docs?.map((row) => {
            const isActive = row?.isActive === true && row?.disabled === false;
            const isInactive = row?.isActive === false && row?.disabled === false;
            const isDeactivated = row?.isActive === false && row?.disabled === true;
            
            const userStatus = isActive ? "Active" : isInactive ? "Inactive" : isDeactivated ? "Deactivated" : ""
            const bvnValidated = row?.bvnValidated
            
			const newRow = {
				...row,
				fullName: nameToSentenceCase(row.fullName) ?? "",
                isEmployee: row.employeeId && row.employeeId !== "" ? "true" : "false",
                userStatus,
                accountTier: `Tier ${row?.accountTier}`,
                bvnValidated
			}
			return newRow;
		})
		return newData;
	}
    
	const proceedToApprove = async () => {
        dispatch(approveCustomer({id: selectedCustomer?._id})).then(() => clearState())
	};
    
	const proceedToReject = async () => {
        dispatch(toggleCustomerStatus({
            id: selectedCustomer?._id,
            action: "rejected",
            reason
        })).then(() => clearState())
	};
    
    const onApprove = async () => {
    const result = await DialogBox({
        theme: "blue",
        content: "Are you sure you want to approve this customer?"
    });
    if (result) {
        proceedToApprove();
    }
	};
    
    useEffect(() => {
        fetchCustomers();
        // eslint-disable-next-line
    }, [props.location])

    return (
        <div>
            
            <ActivateCustomerModal 
                user={selectedCustomer}
                isOpen={activateModalOpen}
                fetchCustomers={fetchCustomers}
                setIsOpen={toggleActivateModal}
                setUsersParams={setUsersParams}
            />
            
            <DeactivateCustomerModal 
                user={selectedCustomer}
                isOpen={deactivateModalOpen}
                fetchCustomers={fetchCustomers}
                setIsOpen={toggleDeactivateModal}
                setUsersParams={setUsersParams}
            />
            
            <CustomerDetailsModal
                control={isDetailsOpen}
                onClose={toggleDetails}
                loading={singleCustomerLoading || toggleCustomerStatusLoading}
                customer={singleCustomerData}
                setIsDetailsOpen={setIsDetailsOpen}
                onApprove={onApprove}
                onReject={() => {
                    toggleActionModal();
                    setIsApproving(false);
                }}
                approveCustomer={proceedToApprove}
                deactivateCustomer={proceedToReject}
                selectedCustomer={selectedCustomer}
                toggleDetails={toggleDetails}
                fetchCustomers={fetchCustomers}
            />
            
            <ApproveRejectModal
                onProceed={() => {
                    if(approveMode) {
                        proceedToApprove();
                    } else if(isApproving === false) {
                        proceedToReject();
                    }
                }}
                reason={reason}
                setReason={setReason}
                approveMode={approveMode}
                isApproving={isApproving}
                control={isActionModalOpen}
                onClose={toggleActionModal}
                loading={toggleCustomerStatusLoading}
            />
            
            <div>
                <div className="bg-white overflow-auto mb-7">
                    <div className="flex text-center space-x-0">
                        <NavLink
                            to="/users/employees"
                            className="tab px-10 md:px-20 flex-shrink-0">
                            Employees
                        </NavLink>
                        <NavLink
                            to="/users/customers"
                            className="tab px-10 md:px-20 flex-shrink-0">
                            Customers
                        </NavLink>
                    </div>
                </div>
                <div className="lg:flex">
                    <div className="w-full lg:w-1/2">
                        <div className="page-title capitalize">
                            Customers
                        </div>
                    </div>
                    <div className="w-full lg:w-1/2 flex space-x-4 mt-2 lg:mt-0 lg:justify-end">
                        <div>
                            <Download
                                tooltip="Download customers"
                                format="csv"
                                filename="All Customers.csv"
                                columns={columns}
                                ref={downloadRef}
                                click={() => {
                                    dispatch(downloadAllCustomers({...usersParams, limit: 2000})).then(() => {
                                        downloadRef.current.processDownload();
                                    });
                                }}
                                loading={downloadAllCustomersLoading}
                                response={transformDataDownload(downloadAllCustomersData?.data)}
                            />
                        </div>
                        <div>
                            <SearchFilter
                                search={{
                                    name: "keyword",
                                    placeholder: "Keyword search",
                                }}
                                filters={[
                                    {
                                        title: "Date Joined",
                                        dataType: "date-range",
                                        options: {
                                            from: {
                                                name: "dateFrom",
                                                placeholder: "Start Date",
                                            },
                                            to: {
                                                name: "dateTo",

                                                placeholder: "End Date",
                                            },
                                        },
                                    },
                                    {
                                        title: "Updated at",
                                        dataType: "date-range",
                                        options: {
                                            from: {
                                                name: "updatedFrom",
                                                placeholder: "Start Date",
                                            },
                                            to: {
                                                name: "updatedTo",

                                                placeholder: "End Date",
                                            },
                                        },
                                    },
                                    // {
                                    //     title: "KYC Status",
                                    //     name: "kycStatus",
                                    //     dataType: "radio",
                                    //     options: [
                                    //         {
                                    //             display: "All",
                                    //             payload: "",
                                    //         },
                                    //         {
                                    //             display: "Pending",
                                    //             payload: "pending",
                                    //         },
                                    //         {
                                    //             display: "Approved",
                                    //             payload: "approved",
                                    //         },
                                    //         {
                                    //             display: "Rejected",
                                    //             payload: "deactivated",
                                    //         },
                                    //     ],
                                    // },
                                    {
                                        title: "User Status",
                                        name: "status",
                                        dataType: "checkbox",
                                        options: [
                                            {
                                                display: "Active",
                                                payload: true,
                                            },
                                            {
                                                display: "Inactive",
                                                payload: false,
                                            },
                                            {
                                                display: "Deactivated",
                                                payload: "deactivated",
                                            },
                                        ],
                                    },
                                    {
                                        title: "Points range",
                                        dataType: "number-range",
                                        options: {
                                            from: {
                                                name: "pointFrom",
                                                placeholder: "points",
                                            },
                                            to: {
                                                name: "pointTo",
                                                placeholder: "points",
                                            },
                                        },
                                    },
                                    // {
                                    //     title: "Account Number",
                                    //     dataType: "plain-text",
                                    //     option: {
                                    //         name: "accountNumber",
                                    //         type: "number",
                                    //         placeholder: "Enter the account number",
                                    //     },
                                    // },
                                ]}
                            />
                        </div>
                    </div>
                </div>
                
                <TableFilterTabs 
                    usersParams={usersParams}
                    refinedData={refinedData}
                    allCustomersLoading={allCustomersLoading}
                />
                
                
                <CustomersTable 
                    usersParams={usersParams}
                    refinedData={refinedData}
                    toggleDetails={toggleDetails}
                    fetchCustomers={fetchCustomers}
                    allCustomersLoading={allCustomersLoading}
                    toggleCustomerStatusLoading={toggleCustomerStatusLoading}
                    toggleActivateModal={toggleActivateModal}
                    toggleDeactivateModal={toggleDeactivateModal}
                />
                
            </div>
        </div>
    )
    
    
}

export default UsersList;