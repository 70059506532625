import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import thankYouIcon from "assets/images/icons/thankYouIcon@svg.svg";

class ResetPasswordSuccess extends Component {
    state = {  }

    render() { 
        return (
            <div className="p-8 pb-14 sm:p-14 md:pt-20">
                <div className="lg:pl-4 lg:pr-4">

                    <div className="mt-8 text-center max-w-md">
                        <div className="h-48 sm:h-56">
                            <img src={thankYouIcon} alt="EarniPay" className="h-full object-contain mx-auto" />
                        </div>
                        <div className="mt-8 font-boing font-semibold text-2xl sm:text-3xl">
                            Password Reset Successful
                        </div>
                        <div className="mt-4 text-gray-500">
                            You can now gain access to your account using your email address and your new password.
                        </div>
                        <Link to="/login" className="btn btn-lg btn-block btn-ep-blue mt-8">Login</Link>

                    </div>

                </div>
            </div>
        )
    }
}
 
export default ResetPasswordSuccess;