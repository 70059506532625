import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useUrlNavigation from "hooks/useUrlNavigation";
import { Link } from "react-router-dom";
import Maybe from "./Maybe";


export const TableContent = (props) => {
    const {urlQuery} = useUrlNavigation();
    const {loading, loadingText, data, total, colspan, emptyText, errorText} = props;
    
    
    let dataTotal = 0;
    let dataContent;

    if (data?.docs !== undefined && data?.totalDocs !== undefined){
        dataTotal = data.totalDocs;
        dataContent = data?.docs;
    }
    else if (data?.data !== undefined && data?.pageInfo !== undefined){
        dataTotal = data?.pageInfo?.total;
        dataContent = data.data;
    }
    else{
        if (total){
            dataTotal = total;
        }
        if (data){
            dataContent = data;
        }
    }
    

    return (
        <>


            <Maybe condition={loading === true}>
                <tr className="table-no-data">
                    <td colSpan={colspan}>
                        <div className="table-info">
                            <FontAwesomeIcon icon="spinner" spin />
                            <div className="font-semibold uppercase">
                                {loadingText || "Loading"}
                            </div>
                        </div>
                    </td>
                </tr>
            </Maybe>

            
            <Maybe condition={loading === false && !dataContent}>
                <tr className="table-no-data">
                    <td colSpan={colspan}>
                        <div className="table-info">
                            <FontAwesomeIcon icon="unlink" />
                            <div className="font-semibold uppercase">
                                {errorText || <>An error occurred<br />Please try again later.</>}
                            </div>
                        </div>
                    </td>
                </tr>
            </Maybe>


            <Maybe condition={loading === false && dataContent && dataContent?.length <= 0}>
                <tr className="table-no-data">
                    <td colSpan={colspan}>

                        <Maybe condition={dataTotal === 0}>
                            <div className="table-info">
                                <FontAwesomeIcon icon="list" />
                                <div className="font-semibold uppercase">
                                    {emptyText || <>No results found</>}
                                </div>
                            </div>
                        </Maybe>
                        
                        <Maybe condition={dataTotal !== 0}>
                            <div className="table-info">
                                <FontAwesomeIcon icon="exclamation-triangle" />
                                <div className="font-semibold uppercase">
                                    Invalid Page Number
                                </div>
                                <div className="mt-2">
                                    <Link to={urlQuery("page=1")} className="btn btn-ep-primary btn-sm mx-auto">Goto Page 1</Link>
                                </div>
                            </div>
                        </Maybe>

                    </td>
                </tr>
            </Maybe>


        </>
    )
}



export const TableContentCustom = (props) => {
    const {loading, icon, text, colspan} = props;
    return (
        <tr className="table-no-data">
            <td colSpan={colspan}>
                <div className="table-info">
                    <Maybe condition={loading === true}>
                        <FontAwesomeIcon icon="circle-notch" spin />
                    </Maybe>
                    <Maybe condition={loading !== true}>
                        <FontAwesomeIcon icon={icon} />
                    </Maybe>
                    <div className="font-semibold uppercase">
                        {text}
                    </div>
                </div>
            </td>
        </tr>
    )
}