import Maybe from "components/__new/common/Maybe";
import { DateTimeMiniFormat } from "utils/dateFormat";
import useToolkit from "utils/useToolkit";
import { ReactComponent as IconChevronLeft } from "assets/images/icons/project-icons/chevron-left.svg";
import { ReactComponent as IconChevronRight } from "assets/images/icons/project-icons/chevron-right.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const SavingsTransactions = ({ control, onClose }) => {
    const itemsPerPage = 5;
	const { useState, useSelector, Spinner, Xclose, CurrencyFormat, EmptyUI, avail } = useToolkit();
	const { savingsDetailsLoading: loading, savingsTrxData: transactions, savingsTrxLoading: trxLoading, selectedSavingsItem } = useSelector((state) => state.entities.savings);
    const [currentPage, setCurrentPage] = useState(1);
    
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentData = transactions?.data?.slice(startIndex, endIndex);
    
	const details = selectedSavingsItem
	
    const detailsNotFound = loading === false && details === null
	
    const data = details?.goal
    const isGoalSavings = data?.type === 'target'
    const email = details?.wallet?.email
    const firstName = details?.wallet?.firstName
    const lastName = details?.wallet?.lastName
    
    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };
	
	return (
		<div className={
			"max-w-md w-screen h-screen bg-white fixed z-50 top-0 bottom-0 shadow-xl transition-all duration-300 ease-in-out " +
			(control ? "right-0" : "-right-full")
			}>
			<div className="w-full h-full p-5 pb-10 relative overflow-auto overscroll-contain">
				<Maybe condition={loading || trxLoading}>
					<Spinner text={loading ? "Fetching details" : trxLoading ? "Fetching transactions" : ""} />
				</Maybe>
				
				<Maybe condition={trxLoading === false && detailsNotFound === true}>
					<EmptyUI text='User Details Not Found' />
				</Maybe>
				
				<Maybe condition={trxLoading === false && detailsNotFound === false}>
					<div>
						<div className="flex justify-between">
							<div className="font-recoleta font-semibold text-2xl capitalize">
								{firstName} {lastName || ''}
							</div>
							<div
								onClick={onClose}
								className="w-8 h-8 flex group hover:bg-red-400 rounded-lg cursor-pointer">
								<Xclose className="customizeSVG group-hover:text-white m-auto" />
							</div>
						</div>
						<div className="text-fade text-xs">
							{avail(email)}
						</div>
						<div className="text-fade text-xs">
							<span>Savings type:&nbsp;</span>
							<strong className="text-ep-blue-deeper">{isGoalSavings ?'Goal' : 'Flex'} Savings</strong>
						</div>
					</div>
				</Maybe>
				
				<Maybe condition={trxLoading === false && transactions?.data?.length === 0}>
					<div className="table-info">
						<FontAwesomeIcon icon="list" />
						<div className="font-bold uppercase">
							No transactions yet
						</div>
					</div>
				</Maybe>
				
				<Maybe condition={trxLoading === false && transactions?.data?.length > 0}>
					<div className="my-6">
						<div className="w-full h-px mt-8 bg-gray-200"></div>
						<div className="mt-6 font-semibold text-sm">
							<h5 className="tracking-wide mb-4">
								User Savings Transactions
							</h5>
							<div className="w-full border border-[#E5E7EB] p-4 pt-0 rounded-md">
								{currentData?.map((trx, index) => {

									const formattedMeta = JSON.parse(trx?.meta)
									const dateCreated =	DateTimeMiniFormat(trx?.createdAt)
									const nairaAmount = CurrencyFormat(trx?.amount / 100)
									const interestAmount = CurrencyFormat(trx?.interest)
									const isCredit = trx?.type === 'savings'
									const isFailed = trx?.status === 'failed'
									const isSuccess = trx?.status === 'success'
									const isCutOff = formattedMeta?.data?.message?.includes('Withdrawal is stopped, contact your employer')

									const failedColor = '#EF4444'
									const successColor = '#007928'
									const successDark = '#006770'
									const cutOffColor = 'rgba(244,157,27,1)'

									return (
										<div key={trx?.createdAt} className={`w-full flex items-start 
										justify-between text-sm py-2.5 ${index !== currentData?.length -1 && " border-b border-[#E5E7EB]"}`}>
											<div className="">
												<h5 className="mb-1 text-[#1f2937] capitalize">
													{trx?.type}
												</h5>
												<p className="mb-0 text-[#9CA3AF] font-normal">
												{dateCreated}
												</p>
											</div>
											<div className="">
												<h5 style={{
													color: isSuccess ? successColor : isFailed ? failedColor :
													isCutOff ? cutOffColor : '',
												}} className="mb-1 font-bold">
													{isCredit ? '+' : '-'}{nairaAmount}
												</h5>
												<p style={{
													color: isSuccess ? successDark : isFailed ? failedColor : ''
												}} className="mb-0 font-thin">
													int:&nbsp;{interestAmount}
												</p>
											</div>
										</div>
									)
								})}
							</div>
						</div>
					</div>
					
					<Pagination
						totalItems={transactions?.data}
						itemsPerPage={itemsPerPage}
						currentPage={currentPage}
						handlePageChange={handlePageChange}
					/>
				</Maybe>
			</div>
		</div>
	);
};

export default SavingsTransactions;


const Pagination = ({totalItems, itemsPerPage, currentPage, handlePageChange}) => {
    const totalPages = Math.ceil(totalItems?.length / itemsPerPage);
    
    const prevBtnDisabled = currentPage === 1;
    const nextBtnDisabled = currentPage === totalPages;
    
    const sharedClass = "py-1.5 px-3 rounded-sm"
    const sharedActiveBtnClass = "bg-ep-blue-deeper text-white"
    const sharedDisabledBtnClass = "bg-gray-100 cursor-not-allowed text-gray-300"

    return (
        <div className="flex items-center justify-between w-full">
            <span className="text-sm">{`Showing ${itemsPerPage > totalItems?.length ? totalItems?.length : itemsPerPage} item(s) out of ${totalItems?.length} result(s) found`}</span>
            <div className="flex items-center">
                <button className={`mr-2 ${sharedClass} ${prevBtnDisabled ? sharedDisabledBtnClass : sharedActiveBtnClass}`} onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                    <IconChevronRight className="customizeSVG text-lg" />
                </button>
                
                <button className={`${sharedClass} ${nextBtnDisabled ? sharedDisabledBtnClass : sharedActiveBtnClass}`} onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                    <IconChevronLeft className="customizeSVG text-lg" />
                </button>
            </div>
        </div>
    );
};