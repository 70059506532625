import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import ReactToolTip from 'react-tooltip';
import queryString from 'query-string';
import DropdownFilter from "utils/dropdown-filter";
import { MonthName } from "utils/dateFormat";
import searchIcon from "assets/images/icons/project-icons/Search-long.svg";
import filter from "assets/images/icons/project-icons/filter.svg";
import SwapRight from "assets/images/icons/project-icons/SwapRight.svg";


class SearchFilter extends Component {
    state = {
        isFilterActive: false,
        urlParams: {},
    }


    showFilterIndicator = () => {
        let isFilterActive = false;
        let filterNames = [];
        const {filters} = this.props;
        const qsObject = queryString.parse(this.props.location.search);

        if (filters){
            filters.forEach((filter) => {
                if (filter.name !== undefined){
                    filterNames.push(filter.name);
                }
                else{
                    if (filter.dataType === "number-range" || filter.dataType === "date-range"){
                        filterNames.push(filter.options.from.name);
                        filterNames.push(filter.options.to.name);
                    }
                    else if (filter.dataType === "month-year"){
                        filterNames.push("month");
                        filterNames.push("year");
                    }
                }
            });

            filterNames.forEach((name) => {
                if (qsObject[name] !== undefined){
                    isFilterActive = true;
                }
            });
        }

        this.setState({isFilterActive});
    }


    processUrlParams = () => {
        const params = new URLSearchParams(this.props.location.search);
        const urlParams = Object.fromEntries(params);
        this.setState({urlParams});
    }


    gotoUrlQuery = (params) => {
        const qs = queryString.parse(this.props.location.search);

        Object.keys(params).forEach((paramName) => {
            delete qs[paramName];
        });

        let qsToUrl = new URLSearchParams(qs).toString();
        qsToUrl = this.props.location.pathname + "?" + ((qsToUrl !== "")? qsToUrl + "&" : "");

        Object.keys(params).forEach((paramName) => {
            if (params[paramName] !== ""){
                qsToUrl += paramName + "=" + params[paramName] + "&";
            }
        });

        qsToUrl = qsToUrl.substring(0, qsToUrl.length - 1);
        this.props.history.push(qsToUrl);
    }
    

    submitFormSearch = (e) => {
        e.preventDefault();
        const searchInput = document.querySelector("#search");
        this.gotoUrlQuery({[searchInput.name]: searchInput.value, page: 1});
    }

    handleAmountFormat = (value) => {
        if (value === "0" || !value) return;
        const cleanedValue = value.replace(/[^\d.]/g, "");
        const [integerPart, decimalPart] = cleanedValue.split(".");
        const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        let formattedValue = formattedInteger;
        if (decimalPart !== undefined) {
            formattedValue += "." + decimalPart.slice(0, 2);
        }
        return formattedValue;
    };

    handleAmountFieldChange = (evt) => {
        const { value, name } = evt.target;
        const formattedValue = this.handleAmountFormat(value);
        this.setState({
            ...this.state,
            urlParams: {
                ...this.state.urlParams,
                [name]: `₦ ${formattedValue}`,
            },
        })
    }


    submitFormFilter = (e) => {
        e.preventDefault();
        let params = {page: 1};

        const inputSelect = document.querySelectorAll(".submitFormFilter select option:checked");
        const inputRadio = document.querySelectorAll(".submitFormFilter input[type='radio']:checked");
        const inputCheckbox = document.querySelectorAll(".submitFormFilter input[type='checkbox']:checked");
        const inputDate = document.querySelectorAll(".submitFormFilter input[type='date']");
        const inputNumber = document.querySelectorAll(".submitFormFilter input[type='number']");
        const inputMonthYear = document.querySelectorAll(".submitFormFilter input[type='month']");
        const inputText = document.querySelectorAll(".submitFormFilter input[type='text']");

        inputSelect.forEach(function (ele, index) {
            params[ele.id] = ele.value;
        })
        inputCheckbox.forEach(function (ele, index) {
            if(params[ele.name]?.length > 0) {
                params[ele.name] = [params[ele.name], ele.value].join(",");
            } else {
                params[ele.name] = ele.value;
            }
        })
        inputText.forEach(function (ele, index) {
            // console.log('ele :>> ', ele);
            params[ele.name] = ele.value;
        })
        inputRadio.forEach(function (ele, index) {
            params[ele.name] = ele.value;
        })
        inputDate.forEach(function (ele, index) {
            params[ele.name] = ele.value;
        })
        inputNumber.forEach(function (ele, index) {
            params[ele.name] = ele.value;
        })
        inputMonthYear.forEach(function (ele, index) {
            const name = ele.name.split("-");
            if (ele.value !== ""){
                let d = new Date(ele.value);
                params[name[0]] = MonthName('' + (d.getMonth() + 1));
                if (name[1]){
                    params[name[1]] = '' + d.getFullYear();
                }
            }
            else{
                params[name[0]] = "";
                params[name[1]] = "";
            }
        })

        this.gotoUrlQuery(params);
    }

    
    clearFilter = () => {
        const qsObject = queryString.parse(this.props.location.search);
        // const preserveObjects = ["page", "limit", "employerId"];
        const preserveObjects = ["limit", "employerId"];

        Object.keys(qsObject).forEach((qsObjectName) => {
            if (!preserveObjects.includes(qsObjectName)){
                delete qsObject[qsObjectName];
            }
        });

        if (document.querySelector("#search")){
            document.querySelector("#search").value = "";
        }

        const inputSelect = document.querySelectorAll(".submitFormFilter select option:checked");
        const inputRadio = document.querySelectorAll(".submitFormFilter input[type='radio']:checked");
        const inputCheckbox = document.querySelectorAll(".submitFormFilter input[type='checkbox']:checked");
        const inputDate = document.querySelectorAll(".submitFormFilter input[type='date']");
        const inputNumber = document.querySelectorAll(".submitFormFilter input[type='number']");
        const inputMonthYear = document.querySelectorAll(".submitFormFilter input[type='month']");
        const inputText = document.querySelectorAll(".submitFormFilter input[type='text']");
        
        
        inputSelect.forEach(function (ele, index) {
            ele.value = "";
        })
        inputCheckbox.forEach(function (ele, index) {
            ele.checked = !ele.checked;
        })
        inputText.forEach(function (ele, index) {
            ele.value = "";
        })
        inputRadio.forEach(function (ele, index) {
            ele.checked = !ele.checked;
        })
        inputDate.forEach(function (ele, index) {
            ele.value = "";
        })
        inputNumber.forEach(function (ele, index) {
            ele.value = "";
        })
        inputMonthYear.forEach(function (ele, index) {
            ele.value = "";
        })
            
        let qsToUrl = new URLSearchParams(qsObject).toString();
        qsToUrl = this.props.location.pathname + "?" + ((qsToUrl !== "")? qsToUrl + "&" : "");
        qsToUrl = qsToUrl.substring(0, qsToUrl.length - 1);
        this.props.history.push(qsToUrl);
    }


    componentDidMount() {
        this.processUrlParams();
        this.showFilterIndicator();
    }


    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.processUrlParams();
            this.showFilterIndicator();
        }
    }


    render() { 
        const { isFilterActive, urlParams } = this.state;
        const { search, filters } = this.props;

        return ( 
            <>
                <div className="h-9 flex box-border-only">
                    
                    {search &&
                        <form onSubmit={this.submitFormSearch}>
                            <div className="form-group">
                                <div className="form-group-icon h-8 rounded-md border-none">
                                    <label htmlFor="search">
                                        <img src={searchIcon} alt="Search" className="form-group-icon-attach h-4" />
                                    </label>
                                    <input type="search" name={search.name} id="search" placeholder={search.placeholder} className="h-full text-sm form-input-icon" defaultValue={urlParams[search.name]} style={{width: "150px", padding: 0}} />
                                </div>
                            </div>
                        </form>
                    }


                    {(search && filters) &&
                        <div className="w-0 ml-0.5 border-l border-gray-200"></div>
                    }


                    {filters &&
                        <form onSubmit={this.submitFormFilter} className="submitFormFilter">
                            <DropdownFilter dropdownClass="max-w-none right-0 md:right-2">
                                <div className="h-9 px-4 flex space-x-3 relative hover:bg-gray-200" data-tip data-for={isFilterActive ? "filter" : ""}>
                                    <div className="my-auto text-sm">Filter</div>
                                    <div className="my-auto">
                                        <img src={filter} alt="Filter" className="w-6 h-3" />
                                    </div>

                                    {isFilterActive &&
                                        <>
                                            <div className="w-3 h-3 absolute -top-1 -right-1 rounded-full bg-red-500" ></div>
                                            <ReactToolTip id="filter" className="tooptip-size">
                                                Filter is active
                                            </ReactToolTip>
                                        </>
                                    }
                                </div>

                                <div className="w-full max-w-lg p-3 text-xs">

                                    <div>
                                        {filters.map((filter, index) =>

                                            <div key={index} className="py-3 sm:flex sm:items-center border-b border-gray-200">
                                                <div className="sm:w-24 sm:flex-shrink-0 flex mr-2 mb-2 sm:mb-0">
                                                    <div className="my-auto">
                                                        {filter.title}
                                                    </div>
                                                </div>
                                                <div className="sm:flex-grow mr-2">

                                                    {filter.dataType === "select" &&
                                                        <div className="border-gray-300">
                                                            <div className="form-group mb-0">
                                                                <select name={filter.name} defaultValue={urlParams[filter.name]} className="form-input-sm">
                                                                    <option value="" disabled>Select</option>
                                                                    {filter.options.map((option, index2) =>
                                                                        <option key={index2} id={filter.name} value={option.payload}>{option.display}</option>
                                                                    )}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    }
                                                    
                                                    {filter.dataType === "radio" &&
                                                        <div className="grid grid-cols-2 gap-x-4 gap-y-2">
                                                            {filter.options.map((option, index2) => 
                                                                <label key={index2} className="flex space-x-1.5 cursor-pointer">
                                                                    <input type="radio"
                                                                        name={filter.name}
                                                                        value={option.payload}
                                                                        defaultChecked={(urlParams[filter.name] === option.payload.toString()) || (!urlParams[filter.name] && option.payload === "")}
                                                                        className="my-auto"
                                                                    /> 
                                                                    <span className="my-auto">
                                                                        {option.display}
                                                                    </span>
                                                                </label>
                                                            )}
                                                        </div>
                                                    }

                                                    {filter.dataType === "checkbox" &&
                                                        <div className="grid grid-cols-2 gap-x-4 gap-y-2">
                                                            {filter.options.map((option, index) => 
                                                                <label key={index} className="flex space-x-1.5 cursor-pointer">
                                                                    <input type="checkbox"
                                                                        name={filter.name}
                                                                        value={option.payload}
                                                                        defaultChecked={(urlParams[filter.name]?.includes(option.payload.toString())) || (!urlParams[filter.name] && option.payload === "")}
                                                                        className="my-auto"
                                                                    /> 
                                                                    <span className="my-auto">
                                                                        {option.display}
                                                                    </span>
                                                                </label>
                                                            )}
                                                        </div>
                                                    }

                                                    {filter.dataType === "month-year" &&
                                                        <div className="box-border-only border-gray-300">
                                                            <div className="form-group mb-0">
                                                                <input type="month"
                                                                    name={filter.option.name}
                                                                    placeholder={filter.option.placeholder}
                                                                    defaultValue={urlParams[filter.option.name]}
                                                                    className="form-input-sm"
                                                                    style={{border: "none"}}
                                                                />
                                                            </div>
                                                        </div>
                                                    }

                                                    {filter.dataType === "date-range" &&
                                                        <div className="flex box-border-only border-gray-300">
                                                            <div className="form-group mb-0 flex-grow">
                                                                <input type="date"
                                                                    name={filter.options.from.name}
                                                                    placeholder={filter.options.from.placeholder}
                                                                    defaultValue={urlParams[filter.options.from.name]}
                                                                    className="form-input-sm"
                                                                    style={{border: "none"}}
                                                                />
                                                            </div>
                                                            <div className="w-7 flex-shrink-0 flex">
                                                                <img src={SwapRight} alt="arrow" className="m-auto h-5" />
                                                            </div>
                                                            <div className="form-group mb-0 flex-grow">
                                                                <input type="date"
                                                                    name={filter.options.to.name}
                                                                    placeholder={filter.options.to.placeholder}
                                                                    defaultValue={urlParams[filter.options.to.name]}
                                                                    className="form-input-sm"
                                                                    style={{border: "none"}}
                                                                />
                                                            </div>
                                                        </div>
                                                    }

                                                    {filter.dataType === "number-range" &&
                                                        //  style={{width: "230px"}}
                                                        <div className="flex box-border-only border-gray-300">
                                                            <div className="form-group mb-0 flex-grow">
                                                                <input type="number"
                                                                    name={filter.options.from.name}
                                                                    placeholder={filter.options.from.placeholder}
                                                                    defaultValue={urlParams[filter.options.from.name]}
                                                                    className="form-input-sm"
                                                                    // style={{width: "100px", border: "none"}}
                                                                    style={{border: "none"}}
                                                                />
                                                            </div>
                                                            <div className="w-7 flex-shrink-0 flex">
                                                                <img src={SwapRight} alt="arrow" className="m-auto h-5" />
                                                            </div>
                                                            <div className="form-group mb-0 flex-grow">
                                                                <input type="number"
                                                                    name={filter.options.to.name}
                                                                    placeholder={filter.options.to.placeholder}
                                                                    defaultValue={urlParams[filter.options.to.name]}
                                                                    className="form-input-sm"
                                                                    // style={{width: "100px", border: "none"}}
                                                                    style={{border: "none"}}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                    
                                                    
                                                    {filter.dataType === "plain-text" &&
                                                        <div className="form-group mb-0">
                                                            <input type={filter?.type ?? "text"}
                                                                name={filter.name}
                                                                placeholder={filter.placeholder}
                                                                defaultValue={urlParams[filter.name]}
                                                                className="form-input-sm"
                                                                id={filter?.type === "number" ? "number-input" : ""}
                                                            />
                                                        </div>
                                                    }

                                                    {filter.dataType === "amount-range" &&
                                                        <div className="input-range-wrapper">
                                                            <div className="input-range-div">
                                                                <input 
                                                                    type="text"
                                                                    name={filter?.range1?.name}
                                                                    placeholder={filter?.range1?.placeholder}
                                                                    value={urlParams[filter?.range1?.name] || "₦"}
                                                                    onChange={this.handleAmountFieldChange}
                                                                    className="form-input-sm"
                                                                    id={filter?.range1?.type === "number" ? "number-input" : ""}
                                                                />
                                                            </div>
                                                            <div className="input-range-div">
                                                                <input 
                                                                    type="text"
                                                                    name={filter?.range2?.name}
                                                                    placeholder={filter?.range2?.placeholder}
                                                                    value={urlParams[filter?.range2?.name] || "₦"}
                                                                    onChange={this.handleAmountFieldChange}
                                                                    className="form-input-sm"
                                                                    id={filter?.range2?.type === "number" ? "number-input" : ""}
                                                                />
                                                            </div>
                                                        </div>
                                                    }

                                                </div>
                                            </div>

                                        )}
                                    </div>

                                    <div className="py-3 flex space-x-1 justify-end text-lg">
                                        <button type="button" onClick={this.clearFilter} className="btn-sm text-fade ">
                                            Reset filter
                                        </button>
                                        <button type="submit" className="btn btn-sm text-ep-blue">
                                            Apply
                                        </button>
                                    </div>

                                </div>
                            </DropdownFilter>
                        </form>
                    }

                </div>
            </>
        );
    }
}
 

export default withRouter(SearchFilter);