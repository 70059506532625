import React, { Component } from "react";
import { NavLink } from "react-router-dom";

const links = [
	{
		display: "App Settings",
		url: "/settings/app",
	},
	{
		display: "Fees Settings",
		url: "/settings/fees",
	},
	{
		display: "Payroll Fees Settings",
		url: "/settings/payroll-fees",
	},
	{
		display: "Platform Fees Settings",
		url: "/settings/platform-fees",
	},
	{
		display: "Account Settings",
		url: "/settings/account",
	},
	{
		display: "Bill Payment Settings",
		url: "/settings/bill-payment",
	},
	{
		display: "Savings Settings",
		url: "/settings/savings",
	},
	{
		display: "Bulk Notifications",
		url: "/settings/bulk-notifications",
	},
	{
		display: "Credit Settings",
		url: "/settings/credit",
	},
	{
		display: "Yield Settings",
		url: "/settings/yield-settings",
	},
]

export class TabSettingsWide extends Component {
	render() {
		return (
			<div className="hidden lg:block lg:w-1/4 xl:w-1/5 border-r flex-shrink-0 space-y-6 py-6 settings-tab">
				{links.map((link, index) =>
					<div key={index}>
						<NavLink to={link.url}>{link.display}</NavLink>
					</div>
				)}
			</div>
		);
	}
}

export class TabSettingsMobile extends Component {
	render() {
		return (
			<div className="mb-2 overflow-auto overscroll-x-contain">
				<div className="flex flex-nowrap lg:hidden space-x-6 settings-tab settings-tab-mobile">
					{links.map((link, index) =>
						<div key={index} className="py-3">
							<NavLink to={link.url}>{link.display}</NavLink>
						</div>
					)}
				</div>
			</div>
		);
	}
}
