import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactToolTip from 'react-tooltip';

const DashboardBoxes = (props) => {
    // console.log("props",props);
    const {data, result, loading} = props;
    
    
    let dataDisplay = "";
    if (("loading" in props) && (loading === true)){
        dataDisplay = <FontAwesomeIcon icon="spinner" spin />;
    }
    else{
        if ("result" in props){
            dataDisplay = result;
        }
        else{
            dataDisplay = data.mainText;
        }
    }


    return ( 
        <div className={ data.classes + " box flex h-full"}>
            <div className={"w-10 h-10 xl:w-12 xl:h-12 mr-4 flex-shrink-0 rounded-lg flex " + (data.iconBg ? data.iconBg : '') }>
                {data.icon ? data.icon : '' }
            </div>
            <div>
                <div className="text-gray-400 text-sm">
                    {data.titleText ? data.titleText : '' }

                    {props.tooltip &&
                        <>
                            <FontAwesomeIcon data-tip data-for={props.tooltipIndex} icon="info-circle" className="ml-2 text-ep-yellow" />
                            <ReactToolTip id={props.tooltipIndex}>
                                {props.tooltip}
                            </ReactToolTip>
                        </>
                    }
                </div>
                <div className="h-7 text-2xl font-semibold font-work-sans overflow-hidden">
                    {dataDisplay}
                    {/* {("result" in props) ? result : data.mainText } */}
                </div>
                {data.subText1 || data.subText2 ? 
                    <div className="text-xs mt-1 text-gray-400 font-bold">
                        {data.subText1 ? data.subText1 : '' }
                        {data.subText2 ? data.subText2 : '' }
                    </div>
                :''}
            </div>
        </div>
     );
}
 
export default DashboardBoxes;