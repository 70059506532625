import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Table from "components/__new/common/Table";
import processParams from "utils/processParams";
import useUrlQueryParams from "hooks/useUrlQueryParams";
import { TableContent } from "components/__new/common/TableContent";
import SearchFilter from "components/common/filter";
import Pagination from "components/common/pagination";
import Maybe from "components/__new/common/Maybe";
import RewardsDashboard from "components/__new/rewards/RewardsDashboard";
import RewardsTab from "components/__new/rewards/RewardsTab";
import NumberFormat from "utils/numberFormat";
import DateFormat from "utils/dateFormat";
import Download from "components/common/download";
import * as rewardsActions from "store/entities/rewards/action";


const List = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const {urlQueryParams} = useUrlQueryParams();
    const { redeemedRewardsLoading, redeemedRewards, redeemedRewardsDownloadLoading, redeemedRewardsDownload } = useSelector((s) => s.entities.rewards);
    

    const downloadRef = useRef();
    const [listParams, setListParams] = useState({
        page: 1,
        limit: 20,
    });


    const getRedeemedRewards = () => {
        let params = {...listParams};

        params = processParams(urlQueryParams, params, "page", "number", 1, "page");
        params = processParams(urlQueryParams, params, "limit", "number", 20, "limit");
        params = processParams(urlQueryParams, params, "search", "string", "", "keyword");
        // params = processParams(urlQueryParams, params, "status", "string", "", "status");
        params = processParams(urlQueryParams, params, "dateFrom", "string", "", "dateFrom");
        params = processParams(urlQueryParams, params, "dateTo", "string", "", "dateTo");
        params = processParams(urlQueryParams, params, "username", "string", "", "username");
        params = processParams(urlQueryParams, params, "rewardType", "string", "", "rewardType");
        params = processParams(urlQueryParams, params, "partner", "string", "", "partner");
        
        setListParams(params);
        dispatch(rewardsActions.getRedeemedRewards(params));
    }


    const transformDownloadResponse = (redeemedReward) => {
        const data = redeemedReward?.map((reward) => {
            return {
                ...reward,
                name: reward?.user?.firstName + " " + reward?.user?.lastName,
            }
        });
        return data;
    }
    

    useEffect(() => {
        getRedeemedRewards();
        // eslint-disable-next-line
    }, [location]);


    return (
        <div>


            <div className="lg:flex">
                <div className="w-full lg:w-1/2">
                    <div className="page-title capitalize">
                        Rewards
                    </div>
                </div>
                <div className="w-full lg:w-1/2 flex space-x-4 mt-2 lg:mt-0 lg:justify-end">
                    <div>
                        <Download
                            tooltip="Download redeemed rewards"
                            filename="Redeemed rewards.csv"
                            format="csv"
                            columns={[
                                { label: "Date of Redemption", key: "createdAt" },
                                { label: "User’s Full Name", key: "name" },
                                { label: "User’s email", key: "email" },
                                { label: "Reward Type", key: "reward.title" },
                                { label: "Partner", key: "reward.partner" },
                                { label: "Points Spent", key: "pointSpent" },
                            ]}
                            ref={downloadRef}
                            click={() => {
                                dispatch(rewardsActions.downloadRedeemedRewards({...listParams, page: 1, limit: 2000, download: true})).then(() => {
                                    downloadRef.current.processDownload();
                                });
                            }}
                            loading={redeemedRewardsDownloadLoading}
                            response={transformDownloadResponse(redeemedRewardsDownload?.data?.redeemedRewards)}
                        />
                    </div>
                    <div> 
                        <SearchFilter
                            search={{
                                name: "search",
                                placeholder: "Keyword search",
                            }}
                            filters={[
                                {
                                    title: "Date Range",
                                    dataType: "date-range",
                                    options: {
                                        from: {
                                            name: "dateFrom",
                                            placeholder: "Start Date",
                                        },
                                        to: {
                                            name: "dateTo",
                                            placeholder: "End Date",
                                        },
                                    },
                                },
                                {
                                    title: "Username",
                                    name: "username",
                                    dataType: "plain-text",
                                    placeholder: "Enter the username",
                                },
                                {
                                    title: "Reward Type ",
                                    name: "rewardType",
                                    dataType: "plain-text",
                                    placeholder: "Enter the reward type",
                                },
                                {
                                    title: "Partner",
                                    name: "partner",
                                    dataType: "plain-text",
                                    placeholder: "Enter the name of partner",
                                },
                            ]}
                        />
                    </div>
                </div>
            </div>


            <RewardsDashboard />


            <RewardsTab />


            <div className="mt-6 lg:flex lg:items-center">
                <div className="w-full lg:w-1/2">
                    <div className="page-title-mini">
                        Redemption History Data
                    </div>
                </div>
                <div className="w-full lg:w-1/2 flex space-x-4 mt-2 lg:mt-0 lg:justify-end"></div>
            </div>


            <div className="mt-6">
                <Table>
                    <Table.Head>
                        <th>Date of Redemption</th>
                        <th>User’s Details</th>
                        <th>Reward Type</th>
                        <th>Partner</th>
                        <th>Points Spent</th>
                    </Table.Head>
                    <Table.Body>

                        <TableContent
                            loading={redeemedRewardsLoading}
                            loadingText="Loading redemption history"
                            data={redeemedRewards?.data?.redeemedRewards}
                            total={redeemedRewards?.data?.totalDocs}
                            colspan={5}
                        />
                        
                        <Maybe condition={redeemedRewardsLoading === false && redeemedRewards?.data?.redeemedRewards?.length > 0}>
                            {redeemedRewards?.data?.redeemedRewards?.map((reward, index) =>
                                <tr key={index}>
                                    <td>
                                        {DateFormat(reward?.createdAt) ?? "-"}
                                    </td>
                                    <td>
                                        <div className="capitalize">
                                            {reward?.user?.firstName ?? "-"} {reward?.user?.lastName}
                                        </div>
                                        <div className="text-fade">
                                            {reward?.user?.email}
                                        </div>
                                    </td>
                                    <td>
                                        {reward?.reward?.title ?? "-"}
                                    </td>
                                    <td>
                                        {reward?.reward?.partner ?? "-"}
                                    </td>
                                    <td>
                                        {NumberFormat(reward?.pointSpent) ?? "-"}
                                    </td>
                                </tr>
                            )}
                        </Maybe>

                    </Table.Body>
                </Table>

                <Maybe condition={redeemedRewardsLoading === false && redeemedRewards?.data?.redeemedRewards?.length > 0}>
                    <Pagination data={redeemedRewards?.data} limit={listParams.limit} />
                </Maybe>
            </div>


        </div>
    )
}


export default List;