import NumberFormat from "utils/numberFormat";


export const getFileSize = (size) => {

  let sizeFormatted = size;
  let unit = "";
  
  if (size >= 1000000){
    sizeFormatted = (size / 1000000).toFixed(2);
    unit = "mb";
  }
  else{
    sizeFormatted = (size / 1000).toFixed(2);
    unit = "kb";
  }

  return (NumberFormat(sizeFormatted) + unit);

};


export default getFileSize;