import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ButtonLoader } from "../common/form";
import FormDrawer from "../common/form-drawer--new";
import { ReactComponent as IconCircleQuestion } from "assets/images/icons/project-icons/circle-question.svg";
import {
	makeLoanPayment,
	resetMakeLoanPayment,
} from "store/entities/credit/action";
import { toast } from "react-toastify";

export const MakeLoanPaymentModal = ({
	isMakeLoanPaymentModalOpen,
	handleToggleMakeLoanPaymentModal,
	fetchAllLoans,
	loanAccountId,
}) => {
	const dispatch = useDispatch();
	const { makeLoanPaymentLoading: loading, makeLoanPaymentPayload } =
		useSelector((state) => state.entities.credit);
	const [amount, setAmount] = useState("");

	const handleCloseModal = () => {
		setAmount("");
		handleToggleMakeLoanPaymentModal();
	};

	const onProceed = () => {
		const payload = {
			data: {
				amount: +amount.split(",").join(""),
			},
			id: loanAccountId,
		};
		dispatch(makeLoanPayment(payload));
	};

	useEffect(() => {
		if (makeLoanPaymentPayload?.success) {
			toast.success(`Loan payment of ₦${amount} made successfully`);
			fetchAllLoans();
			dispatch(resetMakeLoanPayment());
			handleCloseModal();
		}
	}, [makeLoanPaymentPayload]);

	return (
		<FormDrawer
			display="center"
			size="sm"
			isOpen={isMakeLoanPaymentModalOpen}
			setIsOpen={() => {}}
		>
			<div className="p-5 max-w-sm">
				<div className="flex items-center justify-center flex-col pb-8">
					<div className="text-center">
						<IconCircleQuestion />
					</div>

					<h1 className="mt-5 mb-2 font-semibold w-full">
						Make Loan Payment
					</h1>

					<div className="form-group w-full">
						<label>
							Enter Amount (Naira)
							<span className="form-input-required">*</span>
						</label>
						<input
							type="text"
							name="amount"
							className="form-input"
							placeholder="0.00"
							value={amount}
							onChange={(e) => {
								const { value } = e.target;
								if (value === "0") return;
								const cleanedValue = value.replace(
									/[^\d.]/g,
									""
								);
								const [integerPart, decimalPart] =
									cleanedValue.split(".");
								const formattedInteger = integerPart.replace(
									/\B(?=(\d{3})+(?!\d))/g,
									","
								);
								let formattedValue = formattedInteger;
								if (decimalPart !== undefined) {
									formattedValue +=
										"." + decimalPart.slice(0, 2);
								}
								setAmount(formattedValue);
							}}
						/>
					</div>
				</div>

				<div className="flex items-center justify-center lg:flex-row pb-2">
					<button
						onClick={handleCloseModal}
						className="btn btn-md border border-ep-primary-500 text-ep-primary-500 focus:ring-0 w-full lg:w-2/4 mr-4"
						disabled={loading}
					>
						Cancel
					</button>

					<ButtonLoader
						loading={loading}
						onClick={onProceed}
						className="z-5 btn btn-md border bg-ep-primary text-white focus:ring-0 w-full lg:w-2/4"
					>
						{loading ? "Submitting..." : "Proceed"}
					</ButtonLoader>
				</div>
			</div>
		</FormDrawer>
	);
};
