import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Pagination from "components/common/pagination";
import useUrlQueryParams from "hooks/useUrlQueryParams";
import { useLocation } from "react-router-dom";
import {
	getSinglePaymentHandler,
} from "store/entities/payments/action";
import { DateTimeFormat } from "utils/dateFormat";
import Dropdown from "utils/dropdown";
import processParams from "utils/processParams";
import useToolkit from "utils/useToolkit";
import SingleHandlerModal from "./SingleHandlerModal";
import * as paymentActions from "store/entities/payments/action";


const PaymentHandlersTable = () => {
	const {
		useState,
		useEffect,
		dispatch,
		useSelector,
		Spinner,
		isFinancialAdmin,
		isSuperAdmin,
		CurrencyFormat,
		dropdownTableIcon,
		toastInfo,
	} = useToolkit();

	const { paymentHandlersLoading: isLoading, paymentHandlersData } =
		useSelector((state) => state.entities.payments);

	// eslint-disable-next-line
	const [handlerParams, setHandlerParams] = useState({
		page: 1,
		status: "",
		limit: 20,
		year: "",
		month: "",
	});

	const [handlerModalOpen, setHandlerModalOpen] = useState(false);
	const {urlQueryParams} = useUrlQueryParams();
	const handlers = paymentHandlersData?.data?.results;

	const emptyTableData = isLoading === false && !handlers;

	const toggleHandlerModal = () => setHandlerModalOpen(!handlerModalOpen);

					const location = useLocation()

				const [listParams, setListParams] = useState({
        page: 1,
        limit: 999,
        status: "",
    });

				    const getHandlers = () => {
        let params = {...listParams};

        params = processParams(urlQueryParams, params, "page", "number", 1, "page");
        params = processParams(urlQueryParams, params, "limit", "number", 999, "limit");
        params = processParams(urlQueryParams, params, "status", "string", "", "status");
        
        setListParams(params);
								dispatch(paymentActions.getPaymentHandlers(params));
    }

	useEffect(() => {
		getHandlers()
				// eslint-disable-next-line
	}, [location]);

	return isLoading ? (
		<Spinner />
	) : (
		<div className="w-full">
			<div className="mt-10">
				<div className="">
					<div className="table-container">
						<table className="table table-auto table-rounded table-border table-align-tope">
							<thead>
								<tr>
									<th>Date</th>
									<th>Amount</th>
									<th>Destination Acc. No.</th>
									<th>Destination Bank</th>
									<th>Channel</th>
									<th>Narration</th>
									<th>Status</th>
									<th>Actions</th>
								</tr>
							</thead>
							<tbody>
								{emptyTableData ? (
									<tr>
										<td colSpan={isSuperAdmin || isFinancialAdmin ? "10" : "9"}>
											<div className="table-info">
												<FontAwesomeIcon icon="unlink" />
												<div className="font-bold uppercase">
													An error occurred
													<br />
													Please try again later.
												</div>
											</div>
										</td>
									</tr>
								) : (
									handlers?.map((handler, index) => (
										<tr key={index}>
											<td>{DateTimeFormat(handler?.createdAt)}</td>
											<td>{CurrencyFormat(handler?.amount / 100)}</td>
											<td>{handler?.destAccount}</td>
											<td>{handler?.destBank}</td>
											<td className="capitalize">{handler?.channel}</td>
											<td className="capitalize">
												{handler?.narration?.length > 15
													? handler?.narration?.substring(0, 15) + "..."
													: handler?.narration}
											</td>
											<td className="capitalize">
												<div
													className={`label label-green ${
														handler?.status === "success"
															? "label-green"
															: handler?.status === "pending"
															? "label-gray"
															: "label-red"
													}`}>
													{handler?.status === "success"
														? "Successful"
														: handler?.status}
												</div>
											</td>
											<td>
												<div className="table-dropdown-align-top">
													<Dropdown
														icon={dropdownTableIcon}
														menu={[
															{
																type: "div",
																text: "View Details",
																click: () => {
																	if (handler?.status === "pending") {
																		toastInfo(
																			"Transaction details are pending"
																		);
																	} else {
																		toggleHandlerModal();
																		dispatch(
																			getSinglePaymentHandler({
																				path: `payments/${handler?.reference}`,
																			})
																		);
																	}
																},
															},
														]}
													/>
												</div>
											</td>
										</tr>
									))
								)}
							</tbody>
						</table>
					</div>
					{isLoading === false && handlers?.length  && (
						<div>
							<Pagination
								data={paymentHandlersData?.data}
								limit={handlerParams?.limit}
							/>
						</div>
					)}
				</div>
			</div>
			{handlerModalOpen && (
				<SingleHandlerModal
					control={handlerModalOpen}
					onClose={toggleHandlerModal}
				/>
			)}
		</div>
	);
};

export default PaymentHandlersTable;
