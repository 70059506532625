function CenterModal({
	children,
	title,
	isCustom,
	onClose,
	control,
	isRounded,
	className
}) {
	return control ? (
		<section
			onClick={onClose}
			style={{ zIndex: "9000" }}
			className="fixed top-0 left-0 right-0 bottom-0 p-4 lg:p-0 bg-black bg-opacity-40 flex items-center justify-center backdrop-blur-[2px] min-w-full z-50 overflow-y-auto">
			{isCustom ? (
				<div
					className={`z-50 w-full px-2 md:px-0 mx-auto ${className}`}
					onClick={(e) => e.stopPropagation()}>
					{children}
				</div>
			) : (
				<div
					style={{
						minHeight: "200px",
					}}
					onClick={(e) => e.stopPropagation()}
					className={`bg-white md:w-3/5 lg:w-4/12 mx-auto rounded-md shadow-md z-50 ${
						isRounded ? "rounded-3xl" : ""
					}`}>
					<div className="p-5 h-full">
						{title && (
							<p className="font-semibold mb-5 tracking-wider">{title}</p>
						)}
						{children}
					</div>
				</div>
			)}
		</section>
	) : null;

	// <Dialog
	// 	open={control}
	// 	initialFocus={completeButtonRef}
	// 	onClose={() => {
	// 		return onClose();
	// 	}}
	// 	className="fixed z-10 inset-0 overflow-y-auto">
	// 	<div className="flex items-center justify-center min-h-screen">
	// 		<Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

	// 		<div className="w-full max-w-lg mx-auto relative bg-white rounded overflow-hidden">
	// 			<div
	// 				ref={completeButtonRef}
	// 				className="h-12 px-4 bg-ep-blue flex justify-between overflow-hidden">
	// 				{children}
	// 				{/* <div
	// 					onClick={onClose}
	// 					ref={this.completeButtonRef}
	// 					className="w-7 h-7 flex my-auto border group hover:bg-red-400 hover:border-red-400 border-white rounded-lg cursor-pointer">
	// 					<FontAwesomeIcon icon="times" className="text-white m-auto" />
	// 				</div> */}
	// 			</div>
	// 		</div>
	// 	</div>
	// </Dialog>
}

export default CenterModal;
