import { useEffect, useState } from "react";
import { ButtonLoader, Input, SelectInput } from "components/__new/common/form";
import FormDrawer from "components/__new/common/form-drawer--new";
import { toast } from "react-toastify";


const NewLoanTypeModal = ({user, refetch, isOpen, setIsOpen, loading, termLoanFees, setTermLoanFees}) => {

	const [form, setForm] = useState({
		creditType: "",
		loanTypeName: "",
		fees: {
			maxTenor: "",
			penaltyCharge: "",
			interestRate: "",
		}
	});

	const btnDisabled = !form.creditType || !form.loanTypeName || !form.fees.maxTenor || !form.fees.penaltyCharge || !form.fees.interestRate;

	const updateFeeValue = (e) => { 
		setForm({
			...form,
			fees: {
				...form.fees,
				[e.target.name]: e.target.value
			}
		})
	}
	
    const submitForm = (e) => {
        e.preventDefault();

        
        if(form?.fees?.penaltyCharge > 100 || form?.fees?.penaltyCharge < 1) {
			toast.error("Please enter a valid penalty charge");
        } else if(form?.fees?.interestRate > 100 || form?.fees?.interestRate < 1) {
			toast.error("Please enter a valid interest rate");
        } else {
			// save new category to backend
        }
    }

	useEffect(() => {
		setForm({
			creditType: "",
			loanTypeName: "",
			fees: {
				maxTenor: "",
				penaltyCharge: "",
				interestRate: "",
			}
		})
	}, [])

	
	return (
		<FormDrawer display="center" size="md" isOpen={isOpen} setIsOpen={() => {}}>
			<div className="p-5 max-w-md">
				<h1 className="lg:text-lg font-semibold">Add New Loan Type</h1>

			<form onSubmit={submitForm}>
				<div className="mt-8 space-y-7">

					<div className="form-group">
						<SelectInput label="Credit Type" value={form.creditType || ""} onChange={(e) => setForm({...form, creditType: e.target.value})}>
							<option value="" disabled>Choose a type</option>
							<option value="personal">Personal</option>
							<option value="business" disabled>Business</option>
						</SelectInput>
					</div>

					<div className="form-group">
						<Input
							type="text"
							name="loanTypeName"
							label="Loan Type Name"
							value={form.loanTypeName || ""}
							placeholder="Enter a loan type name"
							onChange={(e) => setForm({...form, loanTypeName: e.target.value})}
						/>
					</div>

					<div className="border-t pt-3 border-gray-300 font-semibold">
						Fee Settings
					</div>

					<div className="form-group">
						<label>Max Tenor (months)</label>
						<input
							id="number-input"
							type="number"
							name="maxTenor"
							label="Max Tenor (months)"
							className="form-input"
							value={form?.fees?.maxTenor || ""}
							placeholder="Enter a max tenor"
							onChange={(e) => updateFeeValue(e)}
						/>
					</div>

					<div className="form-group w-full">
						<label>Penalty Charge (%)</label>
						<input
							id="number-input"
							type="number"
							name="penaltyCharge"
							value={form?.fees?.penaltyCharge || ""}
							placeholder="Enter a value"
							className="form-input"
							onChange={(e) => updateFeeValue(e)}
						/>
					</div>

					<div className="form-group w-full">
						<label>Interest Rate (%)</label>
						<input
							id="number-input"
							type="number"
							name="interestRate"
							value={form?.fees?.interestRate || ""}
							placeholder="Enter a value"
							className="form-input"
							onChange={(e) => updateFeeValue(e)}
						/>
					</div>

				</div>
				
				<div className="flex items-center justify-center lg:flex-row pb-2 pt-7">
					
					<button
						onClick={() => setIsOpen(false)}
						className="btn btn-md border border-ep-primary-500 text-ep-primary-500 focus:ring-0 w-full lg:w-2/4 mr-4">
						Cancel
					</button>
					
					<ButtonLoader
						type="submit"
						loading={loading}
						disabled={btnDisabled}
						className="z-5 btn btn-md border bg-ep-primary text-white focus:ring-0 w-full lg:w-2/4">
						{loading ? "Creating..." : "Continue"}
					</ButtonLoader>
				</div>
			</form>

			</div>
		</FormDrawer>
	)
}

export default NewLoanTypeModal