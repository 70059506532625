export const DOWNLOAD_POINTS_HISTORY_START = "DOWNLOAD_POINTS_HISTORY_START";
export const DOWNLOAD_POINTS_HISTORY_DONE = "DOWNLOAD_POINTS_HISTORY_DONE";
export const DOWNLOAD_POINTS_HISTORY_FAILED = "DOWNLOAD_POINTS_HISTORY_FAILED";

export const REJECT_GIFT_POINT_START = "REJECT_GIFT_POINT_START";
export const REJECT_GIFT_POINT_DONE = "REJECT_GIFT_POINT_DONE";
export const REJECT_GIFT_POINT_FAILED = "REJECT_GIFT_POINT_FAILED";

export const APPROVE_GIFT_POINT_START = "APPROVE_GIFT_POINT_START";
export const APPROVE_GIFT_POINT_DONE = "APPROVE_GIFT_POINT_DONE";
export const APPROVE_GIFT_POINT_FAILED = "APPROVE_GIFT_POINT_FAILED";

export const GET_ALL_POINTS_HISTORY_START = "GET_ALL_POINTS_HISTORY_START";
export const GET_ALL_POINTS_HISTORY_DONE = "GET_ALL_POINTS_HISTORY_DONE";
export const GET_ALL_POINTS_HISTORY_FAILED = "GET_ALL_POINTS_HISTORY_FAILED";

export const GET_REWARD_POINTS = "GET_REWARD_POINTS";
export const GET_REWARD_POINTS_START = "GET_REWARD_POINTS_START";
export const GET_REWARD_POINTS_FAILED = "GET_REWARD_POINTS_FAILED";

export const DOWNLOAD_REWARD_POINTS = "DOWNLOAD_REWARD_POINTS";
export const DOWNLOAD_REWARD_POINTS_START = "DOWNLOAD_REWARD_POINTS_START";
export const DOWNLOAD_REWARD_POINTS_FAILED = "DOWNLOAD_REWARD_POINTS_FAILED";

export const CREATE_REWARD_POINTS = "CREATE_REWARD_POINTS";
export const CREATE_REWARD_POINTS_START = "CREATE_REWARD_POINTS_START";
export const CREATE_REWARD_POINTS_FAILED = "CREATE_REWARD_POINTS_FAILED";
export const RESET_CREATE_REWARD_POINTS = "RESET_CREATE_REWARD_POINTS";

export const UPDATE_REWARD_POINTS = "UPDATE_REWARD_POINTS";
export const UPDATE_REWARD_POINTS_START = "UPDATE_REWARD_POINTS_START";
export const UPDATE_REWARD_POINTS_FAILED = "UPDATE_REWARD_POINTS_FAILED";
export const RESET_UPDATE_REWARD_POINTS = "RESET_UPDATE_REWARD_POINTS";

export const ENABLE_REWARD_POINTS = "ENABLE_REWARD_POINTS";
export const ENABLE_REWARD_POINTS_START = "ENABLE_REWARD_POINTS_START";
export const ENABLE_REWARD_POINTS_FAILED = "ENABLE_REWARD_POINTS_FAILED";

export const DISABLE_REWARD_POINTS = "DISABLE_REWARD_POINTS";
export const DISABLE_REWARD_POINTS_START = "DISABLE_REWARD_POINTS_START";
export const DISABLE_REWARD_POINTS_FAILED = "DISABLE_REWARD_POINTS_FAILED";

export const APPROVE_POINT_ACTIVITY_START = "APPROVE_POINT_ACTIVITY_START";
export const APPROVE_POINT_ACTIVITY_DONE = "APPROVE_POINT_ACTIVITY_DONE";
export const APPROVE_POINT_ACTIVITY_FAILED = "APPROVE_POINT_ACTIVITY_FAILED";

export const DELETE_REWARD_POINTS = "DELETE_REWARD_POINTS";
export const DELETE_REWARD_POINTS_START = "DELETE_REWARD_POINTS_START";
export const DELETE_REWARD_POINTS_FAILED = "DELETE_REWARD_POINTS_FAILED";

export const ASSIGN_REWARD_POINTS = "ASSIGN_REWARD_POINTS";
export const ASSIGN_REWARD_POINTS_START = "ASSIGN_REWARD_POINTS_START";
export const ASSIGN_REWARD_POINTS_FAILED = "ASSIGN_REWARD_POINTS_FAILED";
export const ASSIGN_REWARD_POINTS_RESET = "ASSIGN_REWARD_POINTS_RESET";

export const GET_REWARDS = "GET_REWARDS";
export const GET_REWARDS_START = "GET_REWARDS_START";
export const GET_REWARDS_FAILED = "GET_REWARDS_FAILED";

export const DOWNLOAD_REWARDS = "DOWNLOAD_REWARDS";
export const DOWNLOAD_REWARDS_START = "DOWNLOAD_REWARDS_START";
export const DOWNLOAD_REWARDS_FAILED = "DOWNLOAD_REWARDS_FAILED";

export const CREATE_REWARD = "CREATE_REWARD";
export const CREATE_REWARD_START = "CREATE_REWARD_START";
export const CREATE_REWARD_FAILED = "CREATE_REWARD_FAILED";
export const CREATE_REWARD_RESET = "CREATE_REWARD_RESET";

export const UPLOAD_PROMO_CODE = "UPLOAD_PROMO_CODE";
export const UPLOAD_PROMO_CODE_START = "UPLOAD_PROMO_CODE_START";
export const UPLOAD_PROMO_CODE_FAILED = "UPLOAD_PROMO_CODE_FAILED";
export const UPLOAD_PROMO_CODE_RESET = "UPLOAD_PROMO_CODE_RESET";

export const UPDATE_REWARD = "UPDATE_REWARD";
export const UPDATE_REWARD_START = "UPDATE_REWARD_START";
export const UPDATE_REWARD_FAILED = "UPDATE_REWARD_FAILED";
export const UPDATE_REWARD_RESET = "UPDATE_REWARD_RESET";

export const ENABLE_REWARD = "ENABLE_REWARD";
export const ENABLE_REWARD_START = "ENABLE_REWARD_START";
export const ENABLE_REWARD_FAILED = "ENABLE_REWARD_FAILED";

export const DISABLE_REWARD = "DISABLE_REWARD";
export const DISABLE_REWARD_START = "DISABLE_REWARD_START";
export const DISABLE_REWARD_FAILED = "DISABLE_REWARD_FAILED";

export const APPROVE_REWARD_START = "APPROVE_REWARD_START";
export const APPROVE_REWARD_DONE = "APPROVE_REWARD_DONE";
export const APPROVE_REWARD_FAILED = "APPROVE_REWARD_FAILED";

export const GET_REDEEMED_REWARDS = "GET_REDEEMED_REWARDS";
export const GET_REDEEMED_REWARDS_START = "GET_REDEEMED_REWARDS_START";
export const GET_REDEEMED_REWARDS_FAILED = "GET_REDEEMED_REWARDS_FAILED";

export const DOWNLOAD_REDEEMED_REWARDS = "DOWNLOAD_REDEEMED_REWARDS";
export const DOWNLOAD_REDEEMED_REWARDS_START = "DOWNLOAD_REDEEMED_REWARDS_START";
export const DOWNLOAD_REDEEMED_REWARDS_FAILED = "DOWNLOAD_REDEEMED_REWARDS_FAILED";

export const GET_REWARD_METRICS = "GET_REWARD_METRICS";
export const GET_REWARD_METRICS_START = "GET_REWARD_METRICS_START";
export const GET_REWARD_METRICS_FAILED = "GET_REWARD_METRICS_FAILED";
