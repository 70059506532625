import { useState } from "react";
import { Link } from "react-router-dom";
import { FileUploader } from "react-drag-drop-files";
import Maybe from "components/__new/common/Maybe";
import getFileSize from "utils/getFileSize";
import validate from "utils/validate";
import { ReactComponent as IconFileUpload } from "assets/images/icons/project-icons/upload-3.svg";
import { ReactComponent as IconDelete } from "assets/images/icons/project-icons/delete.svg";
// import { ReactComponent as IconFile } from "assets/images/icons/project-icons/file.svg";
import { ReactComponent as IconTimes } from "assets/images/icons/project-icons/times.svg";
import { Input } from "components/__new/common/form";


export const FileUploadForm = (props) => {
    const {data, onchange, filetype, maxFileSize, filename, disabled, validationName, validationGenerics, checkFormValidation, designStyle, label, placeholder} = props;
    const types = filetype ?? ["jpg","jpeg","png","gif","pdf","doc","docx","xls","xlsx","csv"];
    const maxSize = maxFileSize ?? 3;
    const design = designStyle ?? "default";
    const [errors, setErrors] = useState({});
    

    const doValidation = (e) => {
        if (validationName !== undefined){
            const data = {[validationName]: e};
            const vErrors = validate(data);
            if (vErrors){
                setErrors(vErrors);
            }
            else{
                setErrors({});
            }
            checkFormValidation(undefined, validationName, e);
        }
    }
    
    
    const displayError = (errorMessage) => {
        const errorObject = {
            "File type is not supported": "The file format is not supported. Acceptable formats: " + filetype.join(", "),
            "File size is too big": "The file size should not be greater than " + maxSize + "mb",
        }
        setErrors({ [validationName]: errorObject[errorMessage] ?? errorMessage });
    }
    
    
    return (
        <div className="mb-5">
            
            
            <Maybe condition={data === null || data === ""}>
                <FileUploader handleChange={(e) => {onchange(e); doValidation(e);}} disabled={disabled} multiple={false} types={types} maxSize={maxSize} onTypeError={(err) => displayError(err)} onSizeError={(err) => displayError(err)}>
                    <Maybe condition={design === "default"}>
                        <div className={`h-20 box text-sm flex flex-col items-center justify-center ${disabled === true ? "bg-gray-100 opacity-60 cursor-not-allowed" : "cursor-pointer"} border border-gray-200`}>
                            <div className="mt-2 flex space-x-1">
                                <IconFileUpload className="w-5 h-5" />
                                <Maybe condition={disabled === true}>
                                    <span className="text-fade underline">Browse</span>
                                </Maybe>
                                <Maybe condition={disabled !== true}>
                                    <span className="text-ep-blue underline">Browse</span>
                                </Maybe>
                                <span className="text-fade">
                                    or drag and drop CSV file
                                </span>
                            </div>
                            {/* 
                            <div className="text-fade text-xs">
                                (Formats: {filetype.join(", ")}. Maximum size: {maxSize}mb)
                            </div>
                            */}
                        </div>
                    </Maybe>
                    <Maybe condition={design === "input"}>
                        <Input
                            type="text"
                            label={label}
                            value={placeholder}
                            readonly
                            inputClass={`!bg-white cursor-pointer`}
                            containerClass={`mb-0`}
                            rightIcon={<IconFileUpload />}
                            rightIconClass="px-3 cursor-pointer"
                        />
                    </Maybe>
                </FileUploader>
            </Maybe>
            
            
            <Maybe condition={data !== null && data !== ""}>
                <Maybe condition={typeof data === "object" && data?.lastModified !== undefined}>
                    <Maybe condition={design === "default"}>
                        <div className="p-4 flex items-center justify-between space-x-8 box text-sm bg-[#F9FAFB] border border-ep-primary overflow-hidden">
                            <div className="flex items-center space-x-4 ellipsis-2-lines">
                                {/* 
                                <div className="w-10 h-10 flex bg-ep-primary-100 rounded-full">
                                    <IconFile />
                                </div>
                                */}
                                <div className="text-ep-primary">
                                    {data?.name}
                                </div>
                            </div>
                            <div className="flex items-center justify-end space-x-2">
                                <div className="text-[#667085]">
                                    {getFileSize(data?.size)}
                                </div>
                                <div onClick={() => {onchange(null); doValidation(null)}} className="w-6 h-6 rounded flex hover:bg-red-500 cursor-pointer">
                                    <IconTimes className="w-3 h-3 m-auto" />
                                </div>
                            </div>
                        </div>
                    </Maybe>
                    <Maybe condition={design === "input"}>
                        <Input
                            type="text"
                            label={label}
                            value={data?.name}
                            readonly
                            inputClass={`!bg-white cursor-pointer`}
                            containerClass={`mb-0`}
                            rightIcon={<IconDelete />}
                            rightIconClicked={() => {onchange(null); doValidation(null)}}
                            rightIconClass="px-3 svg-fill-red hover:bg-red-500 hover:svg-fill-white"
                        />
                    </Maybe>
                </Maybe>
                
                
                <Maybe condition={typeof data === "string" && data?.lastModified === undefined}>
                    <Maybe condition={design === "default"}>
                        <div className="p-4 flex items-center justify-between space-x-8 box bg-[#F9FAFB] border border-ep-primary overflow-hidden">
                            <div className="flex items-center space-x-4 ellipsis-2-lines">
                                {/* 
                                <div className="w-10 h-10 flex bg-ep-primary-100 rounded-full">
                                    <IconFile />
                                </div>
                                */}
                                <Link to={{pathname: data}} target="_blank" className="text-ep-primary ellipsis hover:underline">
                                    {filename ?? data}
                                </Link>
                            </div>
                            <div className="flex-shrink-0 flex items-center justify-end space-x-2">
                                <Link to={{pathname: data}} target="_blank" className="text-ep-primary hover:underline">
                                    View file
                                </Link>
                                <div onClick={() => {onchange(null); doValidation(null)}} className="w-6 h-6 rounded flex hover:bg-red-500 group cursor-pointer">
                                    <IconTimes className="w-3 h-3 m-auto group-hover:svg-fill-white" />
                                </div>
                            </div>
                        </div>
                    </Maybe>
                    <Maybe condition={design === "input"}>
                        <Input
                            type="text"
                            label={label}
                            value={filename ?? data}
                            readonly
                            inputClass={`!bg-white cursor-pointer`}
                            containerClass={`mb-0`}
                            rightIcon={<IconDelete />}
                            rightIconClicked={() => {onchange(null); doValidation(null)}}
                            rightIconClass="px-3 svg-fill-red hover:bg-red-500 hover:svg-fill-white"
                        />
                    </Maybe>
                </Maybe>
            </Maybe>
            
            
            {errors[validationName] !== undefined &&
                <div className="form-input-message">
                    {validationGenerics?.includes(validationName) === true &&
                        <>{filename?.toLowerCase() ?? ""}&nbsp;</>
                    }
                    {errors[validationName]}
                </div>
            }
            
            
        </div>
    )
}

export default FileUploadForm
