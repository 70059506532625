import { jwtDecode } from "jwt-decode";
import * as actions from "./actionTypes";

const initialState = {
    loggedIn: false,
    data: {},
    signedUp: false,
    signupUser: {},
};


export default function reducer(state = initialState, action) {
    switch (action.type) {

        case actions.LOGIN_USER:
            state = {
                ...state,
                loggedIn: true,
                data: {
                    token: action.payload.token,
                    tokenExpiry: jwtDecode(action.payload.token)?.exp,
                    _id: action.payload.userData._id,
                    userName: action.payload.userData.username,
                    email: action.payload.userData.email,
                    userType: action.payload.userData.userType,
                },
                signedUp: false,
            }
            return state;


        case actions.LOGIN_USER_START:
            state = {
                ...state,
                loggedIn: false,
                data: {}
            }
            return state;


        case actions.LOGIN_USER_FAILED:
            state = {
                ...state,
                loggedIn: false,
                data: {}
            }
            return state;


        case actions.UPDATE_LOGIN_USER:
            state = {
                ...state,
                data: {
                    ...state.data,
                    userName: action.payload.data.userName,
                    email: action.payload.data.email,
                },
            }
            return state;


        case actions.LOGOUT_USER:
            state = {
                ...state,
                loggedIn: false,
                data: {}
            }
            window.location.href = "/login";
            return state;


        default:
            return state;
    }
}