import CenterModal from "components/misc/CenterModal";
import React from "react";
import useToolkit from "utils/useToolkit";
import SwapRight from "assets/images/icons/project-icons/SwapRight.svg";
import EarniButton from "components/misc/EarniButton";
import { setEmployeeEarnings } from "store/entities/employers/action";
import Select from "react-select";
import { getAllEmployees } from "store/entities/employees/action";

const SetEarningsModal = ({
	control,
	onClose,
	hasMultiple,
	companyName,
	employerId,
	refreshFunction,
}) => {
	const { useSelector, useState, useEffect, Spinner, dispatch } = useToolkit();

	const [dateOptions, setDateOptions] = useState({});
	const [selectedPeople, setSelectedPeople] = useState(null);

	const { isSetEarningsLoading: loading } = useSelector(
		(state) => state.entities.employers
	);
	const { allEmployees, loading: empLoading } = useSelector(
		(state) => state.entities.employees
	);

	const employeesData = allEmployees?.docs;
	const onChange = (e) => {
		setDateOptions({ ...dateOptions, [e.target.name]: e.target.value });
	};

	const setEarnings = async () => {
		const { startDate, endDate } = dateOptions;
		const employeeIds = selectedPeople?.map((person) => person.value);

		const multiplePayload = {
			employeeIds,
			employerId,
			startDate,
			endDate,
		};
		const allPayload = {
			employerId,
			startDate,
			endDate,
		};

		const payload = hasMultiple ? multiplePayload : allPayload;
		dispatch(setEmployeeEarnings(payload)).then(() => {
			resetParams();
			refreshFunction();
		});
	};

	const resetParams = () => {
		setDateOptions({});
		setSelectedPeople(null);
		onClose();
	};

	useEffect(() => {
		if (hasMultiple && employerId) {
			dispatch(
				getAllEmployees({
					employerId,
				})
			);
		}
	}, [employerId, dispatch, hasMultiple]);

	const handleChange = (option) => setSelectedPeople(option);
	const isLoading = loading || empLoading;

	const employeeOptions = employeesData?.map((person) => ({
		label: `${person.firstName} ${person?.lastName}`,
		value: person._id,
	}));

	const buttonChecks = hasMultiple
		? !dateOptions?.startDate?.length ||
		  !dateOptions?.endDate?.length ||
		  !selectedPeople?.length
		: !dateOptions?.startDate?.length || !dateOptions?.endDate?.length;

	return (
		<CenterModal control={control} onClose={resetParams}>
			<div className="w-full min-h-full">
				<p className="mb-8 text-sm lg:text-md">
					{hasMultiple
						? "Set multiple employee earnings"
						: "Set all employee earnings"}{" "}
					for {companyName}
				</p>
				{isLoading ? (
					<Spinner text={empLoading ? "Fetching employees" : "Processing"} />
				) : (
					<>
						<div className="flex box-border-only border-gray-300">
							<div className="form-group mb-0 flex-grow">
								<input
									onChange={onChange}
									type="date"
									name="startDate"
									className="form-input-sm"
									style={{ border: "none" }}
								/>
							</div>
							<div className="w-7 flex-shrink-0 flex">
								<img src={SwapRight} alt="arrow" className="m-auto h-5" />
							</div>
							<div className="form-group mb-0 flex-grow">
								<input
									onChange={onChange}
									type="date"
									name="endDate"
									className="form-input-sm"
									style={{ border: "none" }}
								/>
							</div>
						</div>

						{hasMultiple && (
							<div className="mt-7">
								<Select
									isMulti
									onChange={handleChange}
									closeMenuOnSelect={false}
									options={employeeOptions}
									placeholder="Select employees"
								/>
							</div>
						)}

						<div className="flex justify-center pt-8">
							<EarniButton
								onClick={setEarnings}
								text="Proceed"
								disabled={buttonChecks}
							/>
						</div>
					</>
				)}
			</div>
		</CenterModal>
	);
};

export default SetEarningsModal;
