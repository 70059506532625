
const Table = (props) => {
    return (
        <div className="table-container">
            <table className="table table-auto table-rounded table-border">
                {props.children}
            </table>
        </div>
    )
}

const TableHead = (props) => {
    return (
        <thead>
            <tr>
                {props.children}
            </tr>
        </thead>
    )
}

const TableBody = (props) => {
    return (
        <tbody>
            {props.children}
        </tbody>
    )
}

Table.Head = TableHead;
Table.Body = TableBody;
export default Table
