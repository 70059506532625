import * as actions from "./actionTypes";
import { toast } from 'react-toastify';

const initialState = {
    loading: false,
    allAdmins: {},
    reloadPage: false,
    
    admin: {},
    adminLoading: false,

    adminCreateLoading: false,
    adminCreateResponse: {},

    adminUpdateLoading: false,
    adminUpdateResponse: {},
    adminUpdateReset: false,

    adminUpdateStatusLoading: false,
    adminUpdateStatusSuccess: false,

    adminOnboardLoading: false,
    adminOnboard: false,
};


export default function reducer(state = initialState, action) {
    switch (action.type) {

        case actions.GET_ALL_ADMINS:
            state = {
                ...state,
                loading: false,
                allAdmins: { ...action.payload.admins }
            }
            return state;


        case actions.GET_ALL_ADMINS_START:
            state = {
                ...state,
                loading: true,
            }
            return state;


        case actions.GET_ALL_ADMINS_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }

            state = {
                ...state,
                loading: false,
                allAdmins: {},
            }
            return state;


        case actions.GET_ADMIN:
            state = {
                ...state,
                adminLoading: false,
                admin: { ...action.payload.admin }
            }
            return state;


        case actions.GET_ADMIN_START:
            state = {
                ...state,
                adminLoading: true,
            }
            return state;


        case actions.GET_ADMIN_FAILED:
            toast.error(action.payload.message);
            state = {
                ...state,
                adminLoading: false,
                admin: {}
            }
            return state;
            

        case actions.CREATE_ADMIN:
            toast.success(action.payload.message);
            state = {
                ...state,
                adminCreateLoading: false,
                adminCreateResponse: action.payload,
            }
            return state;


        case actions.CREATE_ADMIN_START:
            state = {
                ...state,
                adminCreateLoading: true,
                adminCreateResponse: {},
            }
            return state;


        case actions.CREATE_ADMIN_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }

            state = {
                ...state,
                adminCreateLoading: false,
                adminCreateResponse: action.payload,
            }
            return state;


        case actions.CREATE_ADMIN_RESET:
            state = {
                ...state,
                adminCreateResponse: {}
            }
            return state;


        case actions.UPDATE_ADMIN:
            toast.success(action.payload.message);
            state = {
                ...state,
                adminUpdateLoading: false,
                adminUpdateResponse: action.payload,
            }
            return state;


        case actions.UPDATE_ADMIN_START:
            state = {
                ...state,
                adminUpdateLoading: true,
                adminUpdateResponse: {},
            }
            return state;


        case actions.UPDATE_ADMIN_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }

            state = {
                ...state,
                adminUpdateLoading: false,
                adminUpdateResponse: action.payload,
            }
            return state;


        case actions.UPDATE_ADMIN_RESET:
            state = {
                ...state,
                adminUpdateResponse: {},
            }
            return state;


        case actions.UPDATE_ADMIN_STATUS:
            toast.success(action.payload.message);
            state = {
                ...state,
                adminUpdateStatusLoading: false,
                reloadPage: true,
            }
            return state;


        case actions.UPDATE_ADMIN_STATUS_START:
            toast("Updating Admin Status");
            state = {
                ...state,
                adminUpdateStatusLoading: true,
            }
            return state;


        case actions.UPDATE_ADMIN_STATUS_FAILED:
            toast.error(action.payload.message);
            state = {
                ...state,
                adminUpdateStatusLoading: false,
            }
            return state;


        case actions.DELETE_ADMIN:
            toast.success(action.payload.message);
            state = {
                ...state,
                adminDeleteLoading: false,
                reloadPage: true
            }
            return state;


        case actions.DELETE_ADMIN_START:
            toast("Deleting Admin");
            state = {
                ...state,
                adminDeleteLoading: true,
            }
            return state;


        case actions.DELETE_ADMIN_FAILED:
            toast.error(action.payload.message);
            state = {
                ...state,
                adminDeleteLoading: false,
            }
            return state;


        case actions.RESEND_INVITE:
            toast.success("Invite Sent");
            state = {
                ...state,
            }
            return state;


        /*
        case actions.RESEND_INVITE_START:
            toast("Resending invite");
            state = {
                ...state,
            }
            return state;
        */

        case actions.RESEND_INVITE_FAILED:
            toast.error(action.payload.message);
            state = {
                ...state,
            }
            return state;


        case actions.RELOAD_PAGE_RESET:
            state = {
                ...state,
                reloadPage: false
            }
            return state;


        case actions.ADMIN_ONBOARD:
            // toast.success("Password reset successful");
            state = {
                ...state,
                adminOnboardLoading: false,
                adminOnboard: true,
            }
            return state;


        case actions.ADMIN_ONBOARD_START:
            state = {
                ...state,
                adminOnboardLoading: true,
                adminOnboard: false,
            }
            return state;


        case actions.ADMIN_ONBOARD_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }

            state = {
                ...state,
                adminOnboardLoading: false,
                adminOnboard: false,
            }
            return state;


        case actions.ADMIN_ONBOARD_RESET:
            state = {
                ...state,
                adminOnboard: false,
            }
            return state;


        default:
            return state;
    }
}