import React, { Component } from 'react';
import store from "store/store";
import queryString from 'query-string';
import CurrencyFormat from "utils/currencyFormat";
import DashboardBoxes from 'components/common/dashboard-boxes';
import Pagination2 from 'components/common/pagination2';
import { DateMiniFormat, TimeFormat, DateTimeFormat } from "utils/dateFormat";
import { connect } from "react-redux";
import { CSVLink } from "react-csv";
import { toast } from 'react-toastify';
import { customizeSVG } from 'utils/customizeSVG.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactComponent as Calendar } from "assets/images/icons/project-icons/calendar4-week.svg";
import { ReactComponent as Download } from "assets/images/icons/project-icons/Download.svg";
import { ReactComponent as IconChart } from "assets/images/icons/project-icons/ChartPie.svg";
import * as reports from "store/entities/reports/action";


const headers = [
    { label: "Created At", key: "createdAt" },
    { label: "Date From", key: "from" },
    { label: "Date To", key: "to" },
    { label: "Employee Name", key: "employee.fullName" },
    { label: "Email Address", key: "employee.email" },
    { label: "Phone Number", key: "employee.phoneNumber" },
    { label: "Company Name", key: "employer.companyName" },
    { label: "Amount Requested", key: "amountRequested" },
    { label: "Actual Paid", key: "actualPaid" },
    { label: "Fee", key: "fee" },
];

class QuerySearchCategory extends Component {
    constructor(props) {
        super(props);
        this.csvLinkEl = React.createRef();
    }
    
    state = { 
        dashboardProps1: {
            iconBg: "bg-blue-200",
            icon: <IconChart className="customizeSVG text-blue-600 m-auto w-7 h-7" />,
            titleText: "Search Results",
            mainText: "-"
        },
        pageUi: {
            page: 1,
            limit: 20,   // @endpoint, limit: 0 === all
        },
        storeUnsubscribe1: "",
        storeUnsubscribe2: "",

        showSearchForm: true,
        showSearchQuerySelector: true,
        showSearchResults: true,
        allFilters: {},
        excludedFilters: ["employerId", "employeeId", "netSalary", "date", "page", "limit", "sortBy", "order"],
        selectedFilters: [],
        selectedFiltersKeys: [],
        searchQueryForm: "",
        searchQueryPayload: "",
        dataDownload: [],
    }


    fieldNameChange = (e) => {
        // console.log(e);
        const selectedFieldName = e.target.value;
        const { allFilters } = this.state;
        let newFilter = {};
        let filterType = "";

        for (var filter in allFilters){
            if (allFilters[filter].name === selectedFieldName){
                filterType = allFilters[filter].type;
                break;
            }
        }

        const selectedFilters = [...this.state.selectedFilters];
        newFilter = {
            name: selectedFieldName,
            type: filterType,
        }
        selectedFilters.push(newFilter);
        // console.log("selectedFilters",selectedFilters);
        
        const selectedFiltersKeys = [...this.state.selectedFiltersKeys];
        selectedFiltersKeys.push(selectedFieldName);
        // console.log("selectedFiltersKeys",selectedFiltersKeys);

        this.setState({selectedFilters, selectedFiltersKeys, showSearchQuerySelector: false});
    }


    formChange = () => {
        const selectedFiltersKeys = [...this.state.selectedFiltersKeys];
        let searchQueryForm = {};

        selectedFiltersKeys.forEach((filter) => {
            const fieldValue = document.querySelector("." + filter + "Field").value;
            const fieldQuery = document.querySelector("." + filter + "Query").value;
            const fieldSearch = document.querySelector("." + filter + "Search").value;

            searchQueryForm[fieldValue] = {
                "query" : fieldQuery,
                "search" : fieldSearch,
            }
        });

        // console.log("searchQueryForm", searchQueryForm);
        this.setState({searchQueryForm});
    }


    showSearchQuerySelector = () => {
        this.setState({showSearchQuerySelector: true});
    }


    hideSearchQuerySelector = () => {
        this.setState({showSearchQuerySelector: false});
    }


    deleteRow = (fieldName) => {
        // console.log("deleteRow",fieldName);
        const selectedFiltersKeys = [...this.state.selectedFiltersKeys];
        const removeArrayIndex = selectedFiltersKeys.indexOf(fieldName);
        if (removeArrayIndex > -1) {
            selectedFiltersKeys.splice(removeArrayIndex, 1);
        }

        const selectedFilters = [...this.state.selectedFilters];
        let removeJsonIndex = -1;
        for (var filter in selectedFilters){
            if (selectedFilters[filter].name === fieldName){
                removeJsonIndex = filter;
            }
        }
        if (removeJsonIndex > -1) {
            selectedFilters.splice(removeJsonIndex, 1);
        }

        // console.log("selectedFiltersKeys", selectedFiltersKeys);
        // console.log("selectedFilters", selectedFilters);
        this.setState({selectedFilters, selectedFiltersKeys});
    }


    resetForm = () => {
        const selectedFiltersKeys = [];
        const selectedFilters = [];
        this.setState({selectedFilters, selectedFiltersKeys, showSearchQuerySelector: true});
    }


    submitForm = (e) => {
        e.preventDefault();
        let error = 0;
        const selectedFiltersKeys = [...this.state.selectedFiltersKeys];
        let searchQueryPayload = [];
        let searchQueryPayloadElement = [];
        let searchQueryPayloadSubmit = "";

        selectedFiltersKeys.forEach((filter) => {
            searchQueryPayloadElement = [];
            const fieldValue = document.querySelector("." + filter + "Field").value;
            const fieldQuery = document.querySelector("." + filter + "Query").value;
            const fieldSearch = document.querySelector("." + filter + "Search").value;
            // console.log("fieldValue", fieldValue);
            // console.log("fieldQuery", fieldQuery);
            // console.log("fieldSearch", fieldSearch);
            
            if ((fieldValue !== "") && (fieldQuery !== "") && (fieldSearch !== "")){
                searchQueryPayloadElement = [fieldValue, fieldQuery, fieldSearch];
                // console.log("searchQueryPayloadElement", searchQueryPayloadElement);
                searchQueryPayload.push(searchQueryPayloadElement);
                searchQueryPayloadSubmit += "&" + fieldValue + fieldQuery + fieldSearch;
            }
            else{
                error++;
                if (fieldValue === ""){
                    toast.error("Please select a valid field name for " + filter);
                }
                if (fieldQuery === ""){
                    toast.error("Please select a valid query criteria for " + filter);
                }
                if (fieldSearch === ""){
                    toast.error("Please select a valid search param for " + filter);
                }
            }
        });

        if (error === 0){
            // alert('all good');
            // console.log("searchQueryPayloadElement", searchQueryPayloadElement);
            // console.log("searchQueryPayloadSubmit", searchQueryPayloadSubmit);
            this.setState({searchQueryPayload: searchQueryPayloadSubmit});
            this.getSearchResults(searchQueryPayloadSubmit);
        }
    }


    getReportsWithdrawalParams = (payload) => {
        const qs = queryString.parse(this.props.location.search);
        let { page, limit } = this.state.pageUi;

        if ((qs.page !== undefined) && (!isNaN(qs.page)) && (qs.page > 0)){
            page = qs.page;
        }
        else{
            page = 1;
        }

        if ((qs.limit !== undefined) && (!isNaN(qs.limit)) && (qs.limit > 0)){
            limit = qs.limit;
        }
        else{
            limit = 20;
        }

        const pageUi = {
            page: page,
            limit: limit,
        }
        this.setState({pageUi});

        const payloadSubmit = "?output=json" + payload + "&page=" + page + "&limit=" + limit;
        return payloadSubmit;
    }
    
    
    getSearchResults = (payload = null) => {
        if (payload === null){
            payload = this.state.searchQueryPayload;
        }
        const payloadSubmit = this.getReportsWithdrawalParams(payload);
        this.props.getReportsWithdrawal(payloadSubmit);
    }
    
    
    downloadResult = () => {
        /*
        const { searchQueryPayload } = this.state;
        const payloadSubmit = "?output=csv" + searchQueryPayload + "&limit=0";
        console.log(payloadSubmit);

        this.props.downloadReportsWithdrawal(payloadSubmit).then(() => {
            const {downloadReportsWithdrawals} = this.props;
            console.log("downloadReportsWithdrawals", downloadReportsWithdrawals);
            // if (downloadReportsWithdrawals){
                // const downloadLink = downloadReportsWithdrawals.data;
                // window.location = downloadLink;
                // toast.success("Data downloaded");
            // }
        });
        */

        const pageUi = {
            page: 1,
            limit: 1000,
        }
        const {searchQueryPayload: payload} = this.state;
        const payloadSubmit = "?output=json" + payload + "&page=" + pageUi.page + "&limit=" + pageUi.limit;
        this.props.downloadReportsWithdrawalFE(payloadSubmit).then(() => {

            const {downloadReportsWithdrawals} = this.props;
            // console.log("downloadReportsWithdrawals", downloadReportsWithdrawals);

            if (downloadReportsWithdrawals){
                const dataJSON = downloadReportsWithdrawals.data;
                const data = Object.values(dataJSON);
                this.setState({ dataDownload: data }, () => {
                    setTimeout(() => {
                        this.csvLinkEl.current.link.click();
                        toast.success("Data Downloaded");
                    });
                });
            }
            
        }).catch((error) => {
            toast.success("Unable to download file");
        })
    }


    getFilterName = (filter) => {
        const filterNames = {
            "netSalary" : "Net Salary",
            "amountRequested" : "Amount Requested",
            "fee" : "Fee",
            "actualPaid" : "Actual Paid",
            "feePayer" : "Fee Payer",
            "payBack" : "Pay Back",
            "percentage" : "Percentage",
            "transferResponse" : "Transfer Response",
            "status" : "Status",
            "groupBy" : "Group By",
            "filter" : "Filter",
            "field" : "Field",
            "from" : "Date From",
            "to" : "Date To",
        };

        if (filter in filterNames) {
            return filterNames[filter];
        }
        else{
            return filter;
        }
    }


    componentDidMount() {
        this.props.getReportFiltersWithdrawal();

        this.unsubscribe1 = store.subscribe(() => {
            if (this.props.allReportFiltersWithdrawalsLoading === false){
                this.unsubscribe1();
                const allFilters = this.props.allReportFiltersWithdrawals.data;
                
                const newFilters = [
                    {
                        "name": "from",
                        "type": "BooleanDate",
                    },
                    {
                        "name": "to",
                        "type": "BooleanDate",
                    },
                ];

                allFilters.push(...newFilters);
                // console.log("allFilters", allFilters);
                this.setState({allFilters});
            }
        });
        this.setState({storeUnsubscribe1: this.unsubscribe1});
    }


    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.getSearchResults();
        }
        if (this.props.reloadPage === true) {
            setTimeout(()=>{
                this.props.resetReloadPage();
                this.props.history.replace(this.props.location.pathname + this.props.location.search);
            },1000);
        }
    }


    componentWillUnmount() {
        const { storeUnsubscribe1, storeUnsubscribe2 } = this.state;
        if ((storeUnsubscribe1 !== "") && (storeUnsubscribe1 !== undefined) && (storeUnsubscribe1 !== null)){
            storeUnsubscribe1();
        }
        if ((storeUnsubscribe2 !== "") && (storeUnsubscribe2 !== undefined) && (storeUnsubscribe2 !== null)){
            storeUnsubscribe2();
        }
    }


    render() { 
        customizeSVG();
        const { allReportFiltersWithdrawals: data, allReportsWithdrawals, downloadReportsWithdrawalsLoading } = this.props;
        const { allFilters, showSearchForm, showSearchQuerySelector, showSearchResults, excludedFilters, selectedFilters, selectedFiltersKeys, searchQueryForm, dataDownload, dashboardProps1, pageUi } = this.state;
        
        
        return ( 
            <div>



                {(this.props.allReportFiltersWithdrawalsLoading === true) && 
                    <div>
                        <div className="table-info">
                            <FontAwesomeIcon icon="spinner" spin />
                            <div className="font-bold uppercase">
                                Loading
                            </div>
                        </div>
                    </div>
                }

                {(this.props.allReportFiltersWithdrawalsLoading === false) && (!data.data) && 
                    <div>
                        <div className="table-info">
                            <FontAwesomeIcon icon="unlink" />
                            <div className="font-bold uppercase">
                                An error occurred
                                <br />
                                Please try again later.
                            </div>
                        </div>
                    </div>
                }

                {((this.props.allReportFiltersWithdrawalsLoading === false) && (data.data) && (data.data.length <= 0)) && 
                    <div>
                        <div className="table-info">
                            <FontAwesomeIcon icon="list" />
                            <div className="font-bold uppercase">
                                No filters found
                            </div>
                        </div>
                    </div>
                }

                {((this.props.allReportFiltersWithdrawalsLoading === false) && (data.data) && (data.data.length > 0)) && 
                    <>

                        <div>
                            <div className="lg:flex">
                                <div className="w-full lg:w-1/2">
                                    <div className="page-title capitalize">
                                        Search Withdrawals
                                    </div>
                                </div>
                                <div className="w-full lg:w-1/2 flex mt-2 lg:mt-0 lg:justify-end"></div>
                            </div>
                        </div>

                        {(showSearchForm === true) &&
                            <div className="overflow-x-auto overflow-y-hidden">
                                <div style={{"minWidth":"440px"}}>
                                    <form onSubmit={this.submitForm} className="mt-6 box" style={{"minWidth":"400px"}}>

                                        <div className="mb-3 text-sm flex space-x-6">
                                            <div className="w-1/4">
                                                Field Name
                                                <span className="form-input-required">*</span>
                                            </div>
                                            <div className="w-1/4">
                                                Query Criteria
                                                <span className="form-input-required">*</span>
                                            </div>
                                            <div className="w-2/4">
                                                Search Param
                                                <span className="form-input-required">*</span>
                                            </div>
                                        </div>


                                        {/* {data.data.filter((filter) => {return selectedFilters.includes(filter.name)}).map((filter, index) => */}
                                        {selectedFilters.map((filter, index) =>
                                            <div key={index} className="flex space-x-6">
                                                <div className="w-1/4 form-group">
                                                    <input type="text" className="form-input" value={this.getFilterName(filter.name)} disabled />
                                                    <input type="hidden" className={"form-input hidden " + filter.name + "Field"} value={filter.name} onChange={this.fieldNameChange} disabled />
                                                </div>

                                                <div className="w-1/4 form-group">
                                                    {(filter.name === "groupBy") &&
                                                        <select className={"form-input " + filter.name + "Query"} value={(searchQueryForm[filter["name"]]) ? searchQueryForm[filter["name"]].query : ""} onChange={this.formChange}>
                                                            <option value="=">using</option>
                                                        </select>
                                                    }
                                                    {(filter.name !== "groupBy") &&
                                                        <>
                                                            {(filter.type === "String") &&
                                                                <select className={"form-input " + filter.name + "Query"} value={(searchQueryForm[filter["name"]]) ? searchQueryForm[filter["name"]].query : ""} onChange={this.formChange}>
                                                                    <option value="=%23">Contains</option>
                                                                    {/* <option value="">Select</option> */}
                                                                    {/* <option value="!#">Does not contain</option> */}
                                                                </select>
                                                            }
                                                            {((filter.type === "Number") || (filter.type === "Date")) &&
                                                                <select className={"form-input " + filter.name + "Query"} value={(searchQueryForm[filter["name"]]) ? searchQueryForm[filter["name"]].query : ""} onChange={this.formChange}>
                                                                    <option value="">Select</option>
                                                                    <option value="=>">Greater than</option>
                                                                    <option value="=>=">Greater than & Equals to</option>
                                                                    <option value="==">Equals to</option>
                                                                    <option value="=<=">Less than & Equals to</option>
                                                                    <option value="=<">Less than</option>
                                                                </select>
                                                            }
                                                            {((filter.type === "Boolean") || (filter.type === "BooleanDate")) &&
                                                                <select className={"form-input " + filter.name + "Query"} value={(searchQueryForm[filter["name"]]) ? searchQueryForm[filter["name"]].query : ""} onChange={this.formChange}>
                                                                    <option value="=">is</option>
                                                                    {/* 
                                                                    <option value="">Select</option>
                                                                    <option value="=">is</option>
                                                                    <option value="!=">is not</option>
                                                                    */}
                                                                </select>
                                                            }
                                                        </>
                                                    }
                                                </div>

                                                <div className="w-2/4 flex space-x-6">
                                                    <div className="flex-grow form-group">
                                                        {(filter.name === "groupBy") &&
                                                            <select className={"form-input " + filter.name + "Search"}>
                                                                <option value="employee">Employee</option>
                                                                <option value="employer">Employer</option>
                                                            </select>
                                                        }
                                                        {(filter.name !== "groupBy") &&
                                                            <>
                                                                {(filter.type === "String") &&
                                                                    <input type="text" placeholder="Enter your search query" className={"form-input " + filter.name + "Search"} value={(searchQueryForm[filter["name"]]) ? searchQueryForm[filter["name"]].search : ""} onChange={this.formChange} />
                                                                }
                                                                {(filter.type === "Number") &&
                                                                    <input type="number" placeholder="Enter your search query" className={"form-input " + filter.name + "Search"} value={(searchQueryForm[filter["name"]]) ? searchQueryForm[filter["name"]].search : ""} onChange={this.formChange} />
                                                                }
                                                                {((filter.type === "Date") || (filter.type === "BooleanDate")) &&
                                                                    <input type="date" className={"form-input " + filter.name + "Search"} value={(searchQueryForm[filter["name"]]) ? searchQueryForm[filter["name"]].search : ""} onChange={this.formChange} />
                                                                }
                                                                {(filter.type === "Boolean") &&
                                                                    <select className={"form-input " + filter.name + "Search"} value={(searchQueryForm[filter["name"]]) ? searchQueryForm[filter["name"]].search : ""} onChange={this.formChange}>
                                                                        <option value="">Select</option>
                                                                        <option value="true">Yes</option>
                                                                        <option value="false">No</option>
                                                                    </select>
                                                                }
                                                            </>
                                                        }
                                                    </div>

                                                    <div className="flex-shrink-0 form-group">
                                                        <button type="button" onClick={() => this.deleteRow(filter.name)} className="h-10 flex rounded px-3 py-2 border border-gray-300">
                                                            <FontAwesomeIcon icon="times" className="my-auto" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        

                                        {(showSearchQuerySelector === true) &&
                                            <div className="flex space-x-6">
                                                <div className="w-1/4 form-group">
                                                    <select id="searchField1" className="form-input" value="" onChange={this.fieldNameChange}>
                                                        <option value="">Select field</option>
                                                        {Object.values(allFilters).filter((filter) => {return (!excludedFilters.includes(filter.name) && !selectedFiltersKeys.includes(filter.name))}).map((filter, index) =>
                                                            <option key={index} value={filter.name}>{this.getFilterName(filter.name)}</option>
                                                        )}
                                                    </select>
                                                </div>
                                                <div className="w-1/4 form-group">
                                                    <input type="text" className="form-input" disabled />
                                                </div>
                                                <div className="w-2/4 flex space-x-6">
                                                    <div className="flex-grow form-group">
                                                        <input type="text" className="form-input" disabled />
                                                    </div>
                                                    <div className="flex-shrink-0 form-group">
                                                        <button type="button" onClick={this.hideSearchQuerySelector} className="h-10 flex rounded px-3 py-2 border border-gray-300">
                                                            <FontAwesomeIcon icon="times" className="my-auto" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        

                                        <div className="flex justify-between">
                                            <div>
                                                {(showSearchQuerySelector === false) &&
                                                    <button type="button" onClick={this.showSearchQuerySelector} className="btn btn-transparent-black btn-md">
                                                        <FontAwesomeIcon icon="plus" className="mr-2" />
                                                        Add filter
                                                    </button>
                                                }
                                            </div>
                                            <div className="flex justify-end space-x-4">
                                                <button type="button" onClick={this.resetForm} className="btn btn-red btn-md">
                                                    <FontAwesomeIcon icon="times" className="mr-2" />
                                                    Reset
                                                </button>
                                                <button type="submit" className="btn btn-ep-blue btn-md">
                                                    <FontAwesomeIcon icon="search" className="mr-2" />
                                                    Search
                                                </button>
                                            </div>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        }

                        {(showSearchResults === true) &&
                            <div>
                                <div className="mt-6">
                                    <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6 items-end">
                                        <DashboardBoxes data={dashboardProps1} result={(allReportsWithdrawals.data !== undefined) ? allReportsWithdrawals.total : "-"} />
                                        <div className="hidden lg:block"></div>

                                        {((this.props.allReportsWithdrawalsLoading === false) && (allReportsWithdrawals.data) && (allReportsWithdrawals.data.length > 0)) && 
                                            <div>
                                                <CSVLink filename="All Withdrawals.csv" data={dataDownload} headers={headers} ref={this.csvLinkEl} />
                                                <button type="button" onClick={this.downloadResult} className="btn btn-md btn-gray-hover btn-transparent-black md:float-right" disabled={downloadReportsWithdrawalsLoading}>
                                                    <FontAwesomeIcon icon="spinner" spin className={"text-lg mr-2 " + (downloadReportsWithdrawalsLoading?"inline":"hidden")} />
                                                    Download
                                                    <Download className="customizeSVG ml-2 -mt-px" />
                                                </button>
                                            </div>
                                        }

                                        {/* 
                                        {((this.props.allReportsWithdrawalsLoading === false) && (allReportsWithdrawals.data) && (allReportsWithdrawals.data.length > 0)) && 
                                            <div>
                                                <button type="button" onClick={this.downloadResult} className="btn btn-md btn-gray-hover btn-transparent-black md:float-right" disabled={downloadReportsWithdrawalsLoading}>
                                                    <FontAwesomeIcon icon="spinner" spin className={"text-lg mr-2 " + (downloadReportsWithdrawalsLoading?"inline":"hidden")} />
                                                    Download
                                                    <Download className="customizeSVG ml-2 -mt-px" />
                                                </button>
                                            </div>
                                        }
                                        */}
                                    </div>
                                </div>
                                
                                <div className="mt-6">

                                    <div className="table-container">
                                        <table className="table table-auto table-rounded table-border table-align-top">
                                            <thead>
                                                <tr>
                                                    <th>Date Withdrawn</th>
                                                    <th>Employee Info</th>
                                                    <th>Company Name</th>
                                                    <th>Amount Requested</th>
                                                    <th>Actual Paid</th>
                                                    <th>Fee</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {(this.props.allReportsWithdrawalsLoading === true) && 
                                                    <tr>
                                                        <td colSpan="7">
                                                            <div className="table-info">
                                                                <FontAwesomeIcon icon="spinner" spin />
                                                                <div className="font-bold uppercase">
                                                                    Loading
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                }

                                                {((this.props.allReportsWithdrawalsLoading === false) && (!allReportsWithdrawals.data)) && 
                                                    <tr>
                                                        <td colSpan="7">
                                                            <div className="table-info">
                                                                <FontAwesomeIcon icon="search" />
                                                                <div className="font-bold uppercase">
                                                                    Enter your
                                                                    <br />
                                                                    search query
                                                                </div>
                                                            </div>
                                                            {/* 
                                                            <div className="table-info">
                                                                <FontAwesomeIcon icon="unlink" />
                                                                <div className="font-bold uppercase">
                                                                    An error occurred
                                                                    <br />
                                                                    Please try again later.
                                                                </div>
                                                            </div>
                                                             */}
                                                        </td>
                                                    </tr>
                                                }
                                        
                                                {((this.props.allReportsWithdrawalsLoading === false) && (allReportsWithdrawals.data) && (allReportsWithdrawals.data.length <= 0)) && 
                                                    <tr>
                                                        <td colSpan="7">
                                                            <div className="table-info">
                                                                <FontAwesomeIcon icon="list" />
                                                                <div className="font-bold uppercase">
                                                                    No results found
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                }

                                                {((this.props.allReportsWithdrawalsLoading === false) && (allReportsWithdrawals.data) && (allReportsWithdrawals.data.length > 0)) && 
                                                    allReportsWithdrawals.data.map((withdrawal, index) =>
                                                        <tr key={index}>
                                                            <td>
                                                                <div className="flex">
                                                                    <Calendar className="customizeSVG mt-0.5 mr-2" />
                                                                    <div>
                                                                        {(withdrawal.createdAt) &&
                                                                            <>
                                                                                {DateMiniFormat(withdrawal.createdAt)}
                                                                                <br />
                                                                                {TimeFormat(withdrawal.createdAt)}
                                                                            </>
                                                                        }
                                                                        {(!withdrawal.createdAt) &&
                                                                            <>
                                                                                <span className="text-gray-400">From: </span>
                                                                                {DateTimeFormat(withdrawal.from)}
                                                                                <br />
                                                                                <span className="text-gray-400">To: </span>
                                                                                {DateTimeFormat(withdrawal.to)}
                                                                            </>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                {(withdrawal.employee) ? withdrawal.employee.fullName : ""}
                                                                <br />
                                                                {(withdrawal.employee) ? withdrawal.employee.email : ""}
                                                                <br />
                                                                {(withdrawal.employee) ? withdrawal.employee.phoneNumber : ""}
                                                            </td>
                                                            <td>
                                                                {(withdrawal.employer) ? withdrawal.employer.companyName : ""}
                                                            </td>
                                                            <td>
                                                                {CurrencyFormat(withdrawal.amountRequested)}
                                                            </td>
                                                            <td>
                                                                {CurrencyFormat(withdrawal.actualPaid)}
                                                            </td>
                                                            <td>
                                                                {CurrencyFormat(withdrawal.fee)}
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                                
                                            </tbody>
                                        </table>
                                    </div>

                                    {((this.props.allReportsWithdrawalsLoading === false) && (allReportsWithdrawals.data) && (allReportsWithdrawals.data.length > 0)) && 
                                        <div>
                                            <Pagination2 data={allReportsWithdrawals} url={this.props.location.search} limit={pageUi.limit} />
                                        </div>
                                    }

                                </div>
                            </div>
                        }
                        
                    </>
                }

            </div>
        );
    }
}


const mapStateToProps = (state) => ({
    allReportFiltersWithdrawalsLoading: state.entities.reports.allReportFiltersWithdrawalsLoading,
    allReportFiltersWithdrawals: state.entities.reports.allReportFiltersWithdrawals,
    allReportsWithdrawalsLoading: state.entities.reports.allReportsWithdrawalsLoading,
    allReportsWithdrawals: state.entities.reports.allReportsWithdrawals,
    downloadReportsWithdrawalsLoading: state.entities.reports.downloadReportsWithdrawalsLoading,
    downloadReportsWithdrawals: state.entities.reports.downloadReportsWithdrawals,
});

const mapDispatchToProps = (dispatch) => ({
    getReportFiltersWithdrawal: () => dispatch(reports.getReportFiltersWithdrawal()),
    getReportsWithdrawal: (params) => dispatch(reports.getReportsWithdrawal(params)),   
    downloadReportsWithdrawal: (params) => dispatch(reports.downloadReportsWithdrawal(params)),   
    downloadReportsWithdrawalFE: (params) => dispatch(reports.downloadReportsWithdrawal(params)),   
});

export default connect(mapStateToProps, mapDispatchToProps)(QuerySearchCategory);