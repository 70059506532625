import React, { Component } from 'react';
import store from "store/store";
import Joi from "joi-browser";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as auth from "store/auth/user/action";


class Login extends Component {
    state = { 
        loginForm: {
            email: "",
            password: "",
            showPassword: false,
        },
        errors: {}
    }

    formChange = (e) => {
        const formValue = {...this.state.loginForm};
        formValue[e.currentTarget.name] = e.currentTarget.value;
        this.setState({loginForm: formValue});
    }
    
    toggleShowPassword = () => {
        const showPassword = this.state.showPassword;
        this.setState({showPassword: !showPassword});
    }
    
    schema = {
        email: Joi.string().email().required().label("Email Address").error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    case "any.empty":
                        err.message = "Please enter your email address";
                        break;
                    case "string.email":
                        err.message = "Please enter your valid email address";
                        break;
                    default:
                        break;
                }
            });
            return errors;
        }),
        password: Joi.string().required().label("Password").error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    case "any.empty":
                        err.message = "Please enter your password";
                        break;
                    default:
                        break;
                }
            });
            return errors;
        }),
        showPassword: Joi.allow(null).allow(''),
    }

    validate = () => {
        const options = { abortEarly: false };
        // const options = { abortEarly: false, allowUnknown: true };
        const result = Joi.validate(this.state.loginForm, this.schema, options);
        // console.log(result);
        const error = result.error;
        if (!error) return null;
    
        const errors = {};
        for (let item of result.error.details){
            errors[item.path[0]] = item.message;
        }
        return errors;
    };

    submitForm = async e => {
        e.preventDefault();
        const errors = this.validate();
        this.setState({errors: errors || {}});
        if (errors){
            // console.log(errors);
            for (var x in errors) {
                toast.error(errors[x]);
            };
            return;
        }
        else{
            const {email, password} = this.state.loginForm;
            this.props.login(email, password);
        }
    }

    componentDidMount() {
        this.unsubscribe = store.subscribe(() => {
            // console.log("store updated", store.getState().auth.userPersist.loggedIn);
            if (store.getState().auth.userPersist.loggedIn === true){
                this.props.history.push("/dashboard");
            }
        });
    }

    componentWillUnmount() {
        this.unsubscribe();
    }

    render() {
        const {loginForm} = this.state;

        return (
    
            <>
                <div className="mt-10 text-center">
                    <div className="font-semibold text-2xl sm:text-3xl 2xl:text-4xl font-recoleta">
                        Administrator Login
                    </div>
                    <div className="mt-1">
                        Enter your Login details 
                    </div>
                </div>

                <div className="max-w-md mx-auto p-8 mt-10 bg-white rounded">
                    <form onSubmit={this.submitForm}>

                        <div>
                            
                            <div className="form-group">
                                <label>
                                    Email Address
                                    <span className="form-input-required">*</span>
                                </label>
                                <input type="email" name="email" placeholder="example@email.com" className="form-input" value={loginForm.email} onChange={this.formChange} />
                            </div>

                            <div className="form-group">
                                <label>
                                    Password
                                    <span className="form-input-required">*</span>
                                </label>
                                <input type="password" name="password" placeholder="XXXXXXXXXX" className="form-input" value={loginForm.password} onChange={this.formChange} />
                            </div>

                            {/* 
                            <div className="form-group relative">
                                <label htmlFor="password" className="absolute top-4 left-4 text-xs text-fade">
                                    Password
                                    <span className="form-input-required">*</span>
                                </label>
                                <div className="form-group-icon h-20 pt-8">
                                    <input type={showPassword ? "text" : "password"} name="password" id="password" placeholder="Enter Password" className="form-input-icon" value={loginForm.password} onChange={this.formChange} style={{"paddingLeft":"16px"}} />
                                    <label onClick={this.toggleShowPassword} className="cursor-pointer">
                                        <img src={showPassword ? eyeSlash : eye} alt="Visibility" className="form-group-icon-attach h-5 relative -top-1" />
                                    </label>
                                </div>
                            </div>
                            */}

                            <div className="hidden -mt-3 text-sm font-semibold text-gray-500">
                                <Link to="/forgot-password" className="text-ep-blue hover:underline">Forgot Password</Link>
                            </div>

                            <div className="hidden mt-3 text-sm text-gray-500">
                                Need Access? &nbsp;
                                <Link to="/" className="text-ep-blue hover:underline">Request Access</Link>
                            </div>

                            <button type="submit" className="btn btn-lg btn-block btn-ep-blue mt-8" disabled={this.props.loading}>
                                <FontAwesomeIcon icon="spinner" spin className={"text-xl mr-2 " + (this.props.loading?"inline":"hidden")} />
                                Sign In
                            </button>

                        </div>
                        
                    </form>
                </div>
            </>

        )
    }
}


const mapStateToProps = (state) => ({
    userLoggedIn: state.auth.userPersist.loggedIn,
    loading: state.auth.user.loading,
});

const mapDispatchToProps = (dispatch) => ({
    login: (email, password) => dispatch(auth.loginUser(email, password)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);