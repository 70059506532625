import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { TabSettingsWide, TabSettingsMobile } from 'components/common/settingsTab';
import { customizeSVG } from 'utils/customizeSVG.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CurrencyFormat from "utils/currencyFormat";
import * as settings from "store/entities/settings/action";

class List extends Component {

    componentDidMount() {
        this.props.getGroupedFeesSettings();
    }
    

    render() { 
        customizeSVG();
        const userRole = this.props.user.userType;
        const { groupedFeesSettingsLoading, groupedFeesSettings } = this.props;

        return ( 
            <div>

                <TabSettingsMobile />

                <div className="box">
                    <div className="flex">
                        <TabSettingsWide />

                        <div className="lg:w-3/4 xl:w-4/5 lg:pl-6 flex-grow">
                        
                            <div className="grid sm:grid-cols-2">
                                <div className="page-title">
                                    Fees Settings
                                </div>
                                <div className="flex sm:justify-end">
                                    {(this.props.user.userType === "super-admin" || this.props.user.userType === "financial-admin") &&
                                        <Link to="/settings/fees/edit" className="btn btn-md btn-ep-blue">
                                            <FontAwesomeIcon icon="plus" className="text-md" />
                                            <span>
                                                Setup New Company Fees
                                            </span>
                                        </Link>
                                    }
                                </div>
                            </div>

                            <div className="mt-6">
                                {(groupedFeesSettingsLoading === true) && 
                                    <div className="table-info py-2">
                                        <FontAwesomeIcon icon="spinner" spin />
                                        <div className="font-bold uppercase">
                                            Loading
                                        </div>
                                    </div>
                                }

                                {((groupedFeesSettingsLoading === false) && (!groupedFeesSettings)) && 
                                    <div className="table-info py-2">
                                        <FontAwesomeIcon icon="unlink" />
                                        <div className="font-bold uppercase">
                                            An error occurred
                                            <br />
                                            Please try again later.
                                        </div>
                                    </div>
                                }
                                
                                {(groupedFeesSettingsLoading === false) && (groupedFeesSettings) && (groupedFeesSettings.length > 0) && 
                                    <div>
                                        <div className="flex-grow flex-col">

                                            {groupedFeesSettings.map((feesSetting, index) =>
                                                <Link key={index} to={(userRole === "super-admin" || userRole === "financial-admin") ? "/settings/fees/edit?employerId=" + (feesSetting.employerId ? feesSetting.employerId : "default") : "#"} className={"py-4 border-b block " + (userRole === "super-admin" || userRole === "financial-admin" ? "hover:bg-gray-200" : "cursor-default")}>
                                                    <div className="flex justify-between">
                                                        <div>
                                                            {feesSetting.companyName !== "default" ? feesSetting.companyName : "General Fee Settings"}
                                                        </div>
                                                        <div className="hidden sm:block text-fade text-sm">
                                                            Transaction Fee
                                                        </div>
                                                    </div>
                                                    {feesSetting.fees.map((fee, indexFee) => 
                                                        <div key={indexFee} className="mt-2 flex justify-between space-x-10">
                                                            <div>
                                                                {CurrencyFormat(fee.minAmount)} - {CurrencyFormat(fee.maxAmount)}
                                                            </div>
                                                            <div>
                                                                {fee?.type === 'percentage' ? fee.fee + '%' : CurrencyFormat(fee.fee)}
                                                            </div>
                                                        </div>
                                                    )}
                                                    {feesSetting.companyName === "default" &&
                                                        <div className="h-2 bg-ep-blue relative top-5"></div>
                                                    }
                                                </Link>
                                            )}

                                        </div>
                                    </div>
                                }
                            </div>

                        </div>
                    </div>
                </div>
                
            </div>
        );
    }
}


const mapStateToProps = (state) => ({
    user: state.auth.userPersist.data,
    groupedFeesSettingsLoading: state.entities.settings.groupedFeesSettingsLoading,
    groupedFeesSettings: state.entities.settings.groupedFeesSettings,
    reloadPage: state.entities.settings.reloadPage,
});

const mapDispatchToProps = (dispatch) => ({
    getGroupedFeesSettings: () => dispatch(settings.getGroupedFeesSettings()),
});

export default connect(mapStateToProps, mapDispatchToProps)(List);
