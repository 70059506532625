import { ReactComponent as IconCopy } from "assets/images/icons/project-icons/copy.svg";
import React, { useEffect } from 'react'
import DateFormat from 'utils/dateFormat';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import DropdownNew from 'utils/dropdown-new';
import Pagination from 'components/common/pagination';
import { useLocation } from 'react-router-dom';
import CurrencyFormat from 'utils/currencyFormat';
import {
    selectCreditItem,
    toggleLoanApprovalConfirmation,
    toggleLoanApprovalRequest,
    toggleLoanLimitUpdate,
    toggleLoanLimitUpdateApproval,
    toggleLoanLimitUpdateRejection,
    toggleLoanRejectionConfirmation,
    toggleLoanResetConfirmation,
    toggleLoanRejectionRequest,
    toggleUpdateBankDetails,
    setCreditLimitIds,
    getCreditRequests,
    resetBulkUpdateCreditLimit,
    setCreditLimitUpdateText
} from 'store/entities/credit/action';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactComponent as IconTimes } from "assets/images/icons/project-icons/times.svg";
import Maybe from "components/__new/common/Maybe";
import useMultiSelect from "hooks/useMultiSelect";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import NumberFormat from "utils/numberFormat";
import { renderStatusColor } from "utils/statusColor";


const CreditItemsTable = ({toggleDetails, fetchCreditItems, loading, data}) => {

    // eslint-disable-next-line no-unused-vars
    const { selections, toggleMultiSelectHeader, getMultiSelectBody, clearSelections } = useMultiSelect();
    const { bulkUpdateCreditLimitPayload, creditLimitUpdateText } = useSelector(s => s.entities.credit);

    const dispatch = useDispatch();
    const location = useLocation();
        
    const copyItem = (item, tag) => {
		navigator.clipboard.writeText(item);
		toast(`${tag} Copied`)
	}
    
    const sendLimitForReview = (item) => { 
        dispatch(selectCreditItem(item));
        dispatch(toggleLoanApprovalRequest());
    }
    
    // const requestLoanApproval = (user) => { 
    //     dispatch(selectCreditItem(user));
    //     dispatch(toggleLoanApprovalRequest());
    // }
    
    // const requestLoanRejection = (user) => { 
    //     dispatch(selectCreditItem(user));
    //     dispatch(toggleLoanRejectionRequest());
    // }
    
    // const requestUpdate = (user) => { 
    //     dispatch(selectCreditItem(user));
    //     dispatch(toggleLoanLimitUpdate());
    // }
    
    // const approveUpdate = (user) => { 
    //     dispatch(selectCreditItem(user));
    //     dispatch(toggleLoanLimitUpdateApproval());
    // }
    
    // const rejectUpdate = (user) => { 
    //     dispatch(selectCreditItem(user));
    //     dispatch(toggleLoanLimitUpdateRejection());
    // }
    
    const approveLoan = (user) => { 
        dispatch(selectCreditItem(user));
        dispatch(toggleLoanApprovalConfirmation());
    }
    
    const rejectLoan = (user) => { 
        dispatch(selectCreditItem(user));
        dispatch(toggleLoanRejectionConfirmation());
    }

    const resetLoanLimit = (user) => { 
        dispatch(selectCreditItem(user));
        dispatch(toggleLoanResetConfirmation());
    }


    const updateUserBankDetails = (user) => { 
        dispatch(selectCreditItem(user));
        dispatch(toggleUpdateBankDetails());
    }

    const dummy = [
        {   
            _id: 1,
            createdAt: "2024-02-06T14:06:01.061Z",
            firstName: "Joyce",
            lastName: "Meyer",
            eligibility: true,
            limit: "200000",
            status: "pending",
        },
        {   
            _id: 2,
            createdAt: "2024-02-06T14:06:01.061Z",
            firstName: "Joyce",
            lastName: "Meyer",
            eligibility: false,
            limit: "200000",
            status: "rejected",
        },
        {   
            _id: 3,
            createdAt: "2024-02-06T14:06:01.061Z",
            firstName: "Joyce",
            lastName: "Meyer",
            eligibility: true,
            limit: "200000",
            status: "approved",
        }
    ]

    const handleSendForBulkApproval = () => {
        dispatch(setCreditLimitIds(selections));
        dispatch(toggleLoanApprovalConfirmation());
    }

    const handleSendForBulkRejection = () => {
        dispatch(setCreditLimitIds(selections));
        dispatch(toggleLoanRejectionConfirmation());
    }

    useEffect(() => {
        if (bulkUpdateCreditLimitPayload?.data?.unresolvedLimits?.length === 0) {
            toast.success(`${creditLimitUpdateText} successful`);
            clearSelections();
            dispatch(resetBulkUpdateCreditLimit());
            dispatch(setCreditLimitUpdateText(""));
            fetchCreditItems()
            dispatch(getCreditRequests())
        } else if (bulkUpdateCreditLimitPayload?.data?.unresolvedLimits?.length > 0 && bulkUpdateCreditLimitPayload?.data?.count === 0) {
			toast.error(`${creditLimitUpdateText} ${bulkUpdateCreditLimitPayload?.message === "No pending limits chosen" ? "has already been done!" : "failed"}`);
            dispatch(resetBulkUpdateCreditLimit(true));
            dispatch(setCreditLimitUpdateText(""));
		}
    }, [bulkUpdateCreditLimitPayload, creditLimitUpdateText]);

    return (
        <div className="mt-2">
            
            <Maybe condition={selections.length > 0}>
                <div className="bg-white mb-7 w-full flex items-center rounded-md p-3 pl-6 shadow-sm">
                    <div
                        onClick={handleSendForBulkApproval}
                        className="flex items-center text-[#1A202C] font-normal lg:text-[16px] cursor-pointer">
                        <FontAwesomeIcon icon="check" className="text-[#007928] text-lg" />
                        <span className="ml-2">Send for Bulk Approval</span>
                    </div>  
                    
                    <div className="h-5 w-0.5 bg-[#E5E7EB] mx-7" />

                    <div
                        onClick={handleSendForBulkRejection}
                        className="flex items-center text-[#1A202C] font-normal lg:text-[16px] cursor-pointer">
                        <IconTimes className="svg-fill-red" />
                        <span className="ml-2">Send for Bulk Rejection</span>
                    </div>            
                </div>
            </Maybe>

            <div className="table-container">
                <table className="table table-auto table-rounded table-border">
                    <thead>
                        <tr>
                            <th style={{ paddingRight: 0, paddingLeft: "1.5rem", width: 20, minWidth: 20 }}>
                                <div className="flex w-full">
                                    <input type="checkbox" className="form-checkbox w-5 h-5 multi-select-header" onChange={toggleMultiSelectHeader} />
                                </div>
                            </th>
                            <th>
                                <div className="flex items-center">
                                    Date
                                </div>
                            </th>
                            <th>Name of User</th>
                            <th>User ID</th>
                            <th>Eligibility Status</th>
                            <th>Loan Limit</th>
                            <th>Limit Status</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        
                        {loading === true && 
                            <tr>
                                <td colSpan="8">
                                    <div className="table-info">
                                        <FontAwesomeIcon icon="spinner" spin />
                                        <div className="font-bold uppercase">
                                            Loading
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        }

                        {((loading === false) && (!data)) && 
                            <tr>
                                <td colSpan="8">
                                    <div className="table-info">
                                        <FontAwesomeIcon icon="unlink" />
                                        <div className="font-bold uppercase">
                                            An error occurred
                                            <br />
                                            Please try again later.
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        }
                        
                        {((loading === false) && (data?.data) && (data?.data.length <= 0)) && 
                            <tr>
                                <td colSpan="8">

                                    {(data?.page <= data?.totalPages || data?.data?.length < 1) &&
                                        <div className="table-info">
                                            <FontAwesomeIcon icon="list" />
                                            <div className="font-bold uppercase">
                                                No results found
                                            </div>
                                        </div>
                                    }
                                    
                                    {(data?.page > data?.totalPages && data?.data?.length > 0) &&
                                        <div className="table-info">
                                            <FontAwesomeIcon icon="exclamation-triangle" />
                                            <div className="font-bold uppercase">
                                                Invalid Page Number
                                            </div>
                                            <div className="mt-2">
                                                <Link to="?page=1" className="btn btn-ep-blue btn-sm mx-auto">Goto Page 1</Link>
                                            </div>
                                        </div>
                                    }

                                </td>
                            </tr>
                        }
                        
                        {(loading === false && (data) && (data?.data?.length > 0)) && data?.data?.map((item, index) => {
                            const allApprovalsFalse = item?.firstLevelApproval === false && item?.secondLevelApproval === false && item?.thirdLevelApproval === false;
                            const userIsEligible = item?.eligibilityStatus === 'eligible';

                            const loanIsApproved = item?.limitStatus === 'approved';
                            const loanIsRejected = item?.limitStatus === 'rejected';
                            const loanIsPending = item?.limitStatus === 'pending';

                                return (
                                    <tr key={index}>
                                        <td style={{ paddingRight: "15px", paddingLeft: "1.5rem", width: "20px", minWidth: 20 }}>
                                            <input
                                                type="checkbox"
                                                className="form-checkbox w-5 h-5 multi-select"
                                                onChange={getMultiSelectBody}
                                                checked={selections?.filter((selection) => selection === item.id).length === 1}
                                                value={item.id}
                                                disabled={item?.limitStatus === "approved" || item?.limitStatus === "rejected"}
                                            />
                                        </td>
                                        <td>
                                            <div>
                                                {DateFormat(item?.createdAt?.split("T")[0])}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='whitespace-nowrap'>
                                                {item?.name || "N/A"}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='flex items-center'>
                                                {item?.userId?.substring(0, 8) || "-"}
                                                <span onClick={() => copyItem(item?.userId, "User ID")} className="ml-1 cursor-pointer">
                                                    <IconCopy />
                                                </span>
                                            </div>
                                        </td>
                                        <td>
                                            <EligibilityBadge item={item} />
                                        </td>
                                        <td>{CurrencyFormat(item?.amount / 100 || "0")}</td>
                                        <td>
                                            <div className={"label uppercase " + renderStatusColor(item?.limitStatus === "rejected" ? "failed" : item?.limitStatus)}>
                                                {item?.limitStatus}
                                            </div>
                                        </td>
                                        <td>
                                            <DropdownNew>
                                                <></>
                                                <div>
                                                    <div onClick={() => toggleDetails(item?.userId)} className="dropdown-item dropdown-item-icon">
                                                        View User Details
                                                    </div>

                                                    <Maybe condition={allApprovalsFalse}>
                                                        <div onClick={() => sendLimitForReview(item)} className="dropdown-item dropdown-item-icon">
                                                            Send Loan Limit for Review
                                                        </div>
                                                    </Maybe>

                                                    <Maybe condition={!(loanIsApproved || loanIsRejected)}>
                                                        <div onClick={() => approveLoan(item)} className="dropdown-item dropdown-item-icon">
                                                            Approve Loan Limit
                                                        </div>
                                                    </Maybe>

                                                    <Maybe condition={!(loanIsApproved || loanIsRejected)}>
                                                        <div onClick={() => rejectLoan(item)} className="dropdown-item dropdown-item-icon text-ep-danger">
                                                            Reject Loan Limit
                                                        </div>
                                                    </Maybe>

                                                    <Maybe condition={true}>
                                                        <div onClick={() => updateUserBankDetails(item)} className="dropdown-item dropdown-item-icon">
                                                            Update Bank Details
                                                        </div>
                                                    </Maybe>

                                                    <Maybe condition={true}>
                                                        <div onClick={() => resetLoanLimit(item)} className="dropdown-item dropdown-item-icon text-ep-danger">
                                                            Reset Loan Limit
                                                        </div>
                                                    </Maybe>


                                                    {/* <div onClick={() => requestLoanRejection(item)} className="dropdown-item dropdown-item-icon text-ep-danger">
                                                        Send Loan Limit for Rejection
                                                    </div>
                                                    <div onClick={() => requestUpdate(item)} className="dropdown-item dropdown-item-icon">
                                                        Request Loan Limit Update
                                                    </div> */}
                                                    <hr />
                                                    {/* <div onClick={() => {toggleDetails();}} className="dropdown-item dropdown-item-icon">
                                                        View User Details
                                                    </div> */}



                                                    {/* <div onClick={() => approveUpdate(item)} className="dropdown-item dropdown-item-icon">
                                                        Approve Loan Limit Update
                                                    </div>
                                                    <div onClick={() => rejectUpdate(item)} className="dropdown-item dropdown-item-icon text-ep-danger">
                                                        Reject Loan Limit Update
                                                    </div> */}
                                                </div>
                                            </DropdownNew>
                                        </td>
                                    </tr>
                                )
                                }
                            )
                        }
                    </tbody>
                </table>
            </div>

            {((loading === false) && (data.data) && (data.data.length > 0)) && 
                <div>
                    <Pagination data={data} url={location.search} limit={data?.limit} limitQueryParam="per_page" />
                </div>
            }
    </div>
    )
}

const EligibilityBadge = ({item}) => {

    const isNotEligible = item?.eligibilityStatus === "noteligible";
    
    return (
        <div className={`capitalize flex justify-center px-3.5 py-1 rounded-full whitespace-nowrap text-xs w-[72px] ${isNotEligible ? "bg-[#FEE2E2] text-[#991B1B]" : "bg-ep-primary-light text-ep-primary-600" }`}>
            {isNotEligible ? "Not Eligible" : "Eligible"}
        </div>
    )
}

const LimitStatusBadge = ({status}) => {
    const isSuccessful = status === "approved";
    const isPending = status === "pending";
    
    return (
        <div className={`capitalize flex justify-center px-3 py-1 rounded-full whitespace-nowrap text-xs w-[72px] ${isSuccessful ? "bg-[#E5FDFF] text-ep-primary-60" : isPending ? "bg-[#FFFCDA] text-[#1A202C]" : "bg-[#FEE2E2] text-[#991B1B]"}`}>
            {status}
        </div>
    )
}


export default CreditItemsTable