import errorReducer from "utils/errorReducer";
import * as actions from "./actionTypes";
import { toast } from 'react-toastify';

const initialState = {
    allApprovalsLoading: false,
    allApprovalsData: {},
    
    singleApprovalLoading: false,
    singleApprovalData: {},
    selectedApproval: {},
    
    toggleApprovalStatusLoading: false,
    dummy: "text"
};


export default function reducer(state = initialState, action) {
    switch (action.type) {

        case actions.GET_ALL_APPROVALS_START:
            state = {
                ...state,
                allApprovalsLoading: true,
                allApprovalsData: {},
            }
            return state;

        case actions.GET_ALL_APPROVALS_DONE:
            state = {
                ...state,
                allApprovalsLoading: false,
                allApprovalsData: action.payload,
            }
            return state;

        case actions.GET_ALL_APPROVALS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                allApprovalsLoading: false,
                allApprovalsData: {},
            }
            return state;

        case actions.GET_SINGLE_APPROVAL_START:
            state = {
                ...state,
                singleApprovalLoading: true,
                singleApprovalData: {},
            }
            return state;

        case actions.GET_SINGLE_APPROVAL_DONE:
            state = {
                ...state,
                singleApprovalLoading: false,
                singleApprovalData: action.payload,
            }
            return state;

        case actions.GET_SINGLE_APPROVAL_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                singleApprovalLoading: false,
                singleApprovalData: {},
            }
            return state;

        case actions.TOGGLE_APPROVAL_STATUS_START:
            toast("Submitting")
            state = {
                ...state,
                toggleApprovalStatusLoading: true,
            }
            return state;

        case actions.TOGGLE_APPROVAL_STATUS_DONE:
            state = {
                ...state,
                toggleApprovalStatusLoading: false,
            }
            return state;

        case actions.TOGGLE_APPROVAL_STATUS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                toggleApprovalStatusLoading: false,
            }
            return state;

        case actions.SELECT_APPROVAL_ITEM:
            state = {
                ...state,
                selectedApproval: action.payload,
            }
            return state;

        default:
            return state;
    }
}