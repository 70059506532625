import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormDrawer from "components/__new/common/form-drawer";
import { ButtonLoader, Input, Textarea } from "components/__new/common/form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import validate from "utils/validate";
import * as rewardsActions from "store/entities/rewards/action";
import { toast } from "react-toastify";
import FileUploadForm from "../common/form/FileUploadForm";


const AssignActivityForm = (props) => {
    const { isOpen, setIsOpen } = props;
    const dispatch = useDispatch();
    const { rewardPointsAssignedLoading, rewardPointsAssigned } = useSelector((s) => s.entities.rewards);
    

    const [isFormValidated, setIsFormValidated] = useState(false);
    const formDefault = {
        email: "",
        pointsToAward: "",
        reason: "",
    }
    const [form, setForm] = useState(formDefault)
    const [formUpload, setFormUpload] = useState("");


    const checkFormValidation = (data = form, appendKey, appendValue, file = formUpload) => {
        let response = false;
        let params = {...data};
        if (appendKey){ params = {...params, [appendKey]: appendValue} }
        const errors = validate(params);
        if (!errors || (file && file !== "")){ response = true }
        setIsFormValidated(response);
    }


    const submitForm = async (e) => {
        e?.preventDefault();
        const params = {
            reason: form.reason,
            email: form.email,
            pointsGifted: parseInt(form.pointsToAward),
        }
        dispatch(rewardsActions.assignRewardPoints(params));
    }


    useEffect(() => {
        if (rewardPointsAssigned?.status === "success"){
            toast.success(`Points assigned successfully`);
            dispatch(rewardsActions.resetAssignRewardPoints());
            setForm(formDefault);
            setFormUpload("");
            setIsOpen(false);
        }
        // eslint-disable-next-line
    }, [rewardPointsAssigned])


    return (
        <FormDrawer display="center" size="sm" isOpen={isOpen} setIsOpen={setIsOpen}>
            <form onSubmit={submitForm} className="p-6 max-w-sm">
                <div className="flex items-center justify-between">
                    <div className="page-title-mini capitalize">
                        Assign Points
                    </div>
                    <div onClick={() => setIsOpen(false)} className="modal-close group">
                        <FontAwesomeIcon icon="times" className="group-hover:text-white m-auto" />
                    </div>
                </div>
                
                <div className="mt-10">
                    <Input type="email" label="Enter User’s Email Address" placeholder="Email Address" required={true} value={form.email} onChange={(e) => setForm({...form, email: e.target.value})} validationName="email" checkFormValidation={checkFormValidation} />
                    <Input type="number" label="Number of Points to Award" placeholder="0" required={true} value={form.pointsToAward} onChange={(e) => setForm({...form, pointsToAward: e.target.value})} validationName="pointsToAward" checkFormValidation={checkFormValidation} />
                    <Textarea label="Reason for Points Award" placeholder="Please enter a reason for awarding points..." required={true} styles={{height: "100px", resize: "none"}} value={form.reason} onChange={(e) => setForm({...form, reason: e.target.value})} validationName="reason" checkFormValidation={checkFormValidation} />
                    
                    <div className="h-5 border-b flex justify-center">
                        <div className="h-10 bg-white px-4 py-2 text-sm">OR</div>
                    </div>

                    <div className="mt-10">
                        <div className="text-sm mb-1">
                            Assign Points to Bulk Users
                        </div>
                        <FileUploadForm
                            data={formUpload}
                            onchange={(e) => {setFormUpload(e); checkFormValidation(undefined, undefined, undefined, e)}}
                            filetype={["csv"]}
                            filename="Points to Bulk Users"
                            disabled={true}
                        />
                    </div>
                </div>
                
                <div className="mt-10 flex space-x-4">
                    <button type="button" onClick={() => setIsOpen(false)} className="btn btn-md btn-block btn-white">
                        Cancel
                    </button>
                    <ButtonLoader type="submit" loading={rewardPointsAssignedLoading} className="btn btn-md btn-block btn-ep-blue" disabled={!isFormValidated}>
                        Assign
                    </ButtonLoader>
                </div>
            </form>
        </FormDrawer>
    )
}


export default AssignActivityForm;