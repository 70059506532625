import * as actions from "./actionTypes";
import * as config from "config";

export function getPaystackBalance() {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetPaystackBalance,
            method: "get",
            data: {},
            onStart: actions.GET_PAYSTACK_BALANCE_START,
            onSuccess: actions.GET_PAYSTACK_BALANCE,
            onError: actions.GET_PAYSTACK_BALANCE_FAILED,
            // useLoginJwt: false,
        },
    }
}
