import EarniButton from "components/misc/EarniButton";
import { useRef } from "react";
import { getFlexSavingsSettings, updateGoalSavingsSettings } from "store/entities/settings/action";
import useToolkit from "utils/useToolkit";

const FlexSavingsTab = () => {
	const {
		useSelector,
		Spinner,
		useState,
		useEffect,
		dispatch
	} = useToolkit();

	const [isEditing, setIsEditing] = useState(false)
	const formPayload = useRef({})
	const { flexSettingsLoading: loading, flexSettingsData: data,
	updateSavingsSettingsLoading: updLoading } = useSelector(state => state.entities.settings)


const viewFormItems = [
	{
			label: 'Interest rate (P/A)',
			value: data?.interest,
	},
	{
			label: 'Breaking fee (% of withdrawal amount)',
			value: data?.breaking_fee,
	},
	{
			label: 'Minimum number of days of withdrawal',
			value: data?.minimum_withdrawal_days,
	},
	{
			label: 'Minimum number of days to save a goal',
			value: data?.minimum_saving_days,
	},
]

	const WRAP_CLASS = 'mb-3 settings-form-wrap'

	const onChange = (e) => {
	const name = e.target.name
	const value = e.target.value
	formPayload.current = {
		...formPayload.current,
		[name]: value
	}
	}


	const onSubmit = async (e) => {
		e.preventDefault()
		const new_interest = Number(formPayload.current?.interest)
		const new_breaking_fee = Number(formPayload.current?.breaking_fee)
		const new_minimum_withdrawal_days = Number(formPayload.current?.minimum_withdrawal_days)
		const new_minimum_saving_days = Number(formPayload.current?.minimum_saving_days)

		const payload = {
			plan: 'flex',
			interest: new_interest || data?.interest,
			breaking_fee: new_breaking_fee || data?.breaking_fee,
			minimum_withdrawal_days: new_minimum_withdrawal_days || data?.minimum_withdrawal_days,
			minimum_saving_days: new_minimum_saving_days || data?.minimum_saving_days
		}

	await dispatch(updateGoalSavingsSettings(payload)).then(() => {
		setTimeout(() => {
			setIsEditing(false)
				formPayload.current = {}
		}, 1500);
	})
	}

	const btnCheck = formPayload?.current === {}

	useEffect(() => {
	dispatch(getFlexSavingsSettings({plan: 'flex'}))
	// eslint-disable-next-line
	}, [isEditing])

	const FormItem = ({ label, placeholder, value, className, onChange, name, disabled }) => (
		<div className="form-group">
			<label>
				{label}
			</label>
			<input
				name={name}
				disabled={disabled}
				placeholder={placeholder}
				className={`form-input ${className}`}
				value={value}
				onChange={onChange}
			/>
		</div>
	);

	const EditingForm = () => (
	<form onSubmit={onSubmit}>
			<div className="settings-form-container">
			<div className={WRAP_CLASS}>
				<FormItem label="Interest rate (P/A)" 
				placeholder={data?.interest}
				name="interest"
				onChange={onChange}
				/>
			</div>

			
			<div className={WRAP_CLASS}>
				<FormItem label="Breaking fee (% of withdrawal amount)" 
					placeholder={data?.breaking_fee}
					name="breaking_fee"
					onChange={onChange}
					/>
			</div>

			
			<div className={WRAP_CLASS}>
				<FormItem label="Minimum number of days of withdrawal" 
				placeholder={data?.minimum_withdrawal_days}
				name='minimum_withdrawal_days'
				onChange={onChange} />
			</div>

			
			<div className={WRAP_CLASS}>
				<FormItem label="Minimum number of days to save a goal'" 
				placeholder={data?.minimum_saving_days}
				name='minimum_saving_days'
				onChange={onChange} />
			</div>
		</div>

		<div className="flex space-x-5">
			<EarniButton className='rounded-md px-10'
			 text='Save' type='submit' 
				disabled={btnCheck} 
				/>
			<EarniButton secondary 
				className='rounded-md px-10' text='Cancel' 
				onClick={() => {
				setIsEditing(false)
				formPayload.current = {}
			}} />
		</div>
	</form>
	)

	const ViewingForm = () => (
			<div>
					<div className="settings-form-container">
						{viewFormItems.map(({label, value}) => (
							<div key={label} className={WRAP_CLASS}>
							<FormItem disabled value={value} 
								label={label} className='cursor-not-allowed' />
							</div>
				))}
		</div>

		<div className="flex space-x-5">
			<EarniButton className='rounded-md px-10' text='Edit' 
			onClick={() => {
				setIsEditing(!isEditing)
				formPayload.current = {}
				}} />
		</div>
	</div>)

	return (
		<div className="w-full pb-10">
					{loading || updLoading ? <Spinner /> 
					: isEditing ? <EditingForm /> 
					: <ViewingForm />}
		</div>
	);
};

export default FlexSavingsTab;
