import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormDrawer from "components/__new/common/form-drawer";
import { ButtonLoader, Input, Textarea } from "components/__new/common/form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SearchableCategory from "./SearchableCategory";
import validate from "utils/validate";
import { toast } from "react-toastify";
import * as rewardsActions from "store/entities/rewards/action";


const PointActivityForm = (props) => {
    const { isOpen, setIsOpen, selectedRewardPoint, getRewardPoints } = props;
    const formAction = Object.keys(selectedRewardPoint).length === 0 ? "create" : "edit";


    const dispatch = useDispatch();
    const { rewardPointsCreatedLoading, rewardPointsCreated, rewardPointsUpdatedLoading, rewardPointsUpdated } = useSelector((s) => s.entities.rewards);
    

    const searchableCategoryRef = useRef();
    const [isFormValidated, setIsFormValidated] = useState(false);
    const formDefault = {
        activity: "",
        description: "",
        category: "",
        pointsToEarn: "",
    }
    const [form, setForm] = useState(formDefault)


    const checkFormValidation = (data = form, appendKey, appendValue) => {
        let params = {...data};
        if (appendKey){
            params = {...params, [appendKey]: appendValue};
        }
        const errors = validate(params);
        if (errors){
            setIsFormValidated(false);
            // console.log("checkFormValidation", errors);
        }
        else{
            setIsFormValidated(true);
        }
    }


    const submitForm = async (e) => {
        e?.preventDefault();
        const params = {
            name: form.activity,
            description: form.description,
            pointNumber: parseInt(form.pointsToEarn),
            category: form.category,
        }
        if (formAction === "create"){
            dispatch(rewardsActions.createRewardPoints(params));
        }
        else{
            dispatch(rewardsActions.updateRewardPoints(selectedRewardPoint._id, params));
        }
    }


    const clearForm = () => {
        setForm(formDefault);
        searchableCategoryRef.current?.clearSearchableCategory();
    }


    useEffect(() => {
        if (isOpen === true){
            let data = {...formDefault};
            if (formAction === "edit"){
                data = {
                    ...formDefault,
                    activity: selectedRewardPoint?.activityName ?? "",
                    description: selectedRewardPoint?.activityDescription ?? "",
                    category: selectedRewardPoint?.activityCategory ?? "",
                    pointsToEarn: selectedRewardPoint?.numOfPoints ?? "",
                };
                setForm(data);
            }
            else{
                clearForm();
            }
            checkFormValidation(data);
        }
        // eslint-disable-next-line
    }, [isOpen])


    useEffect(() => {
        if (rewardPointsCreated?.status === "success" || rewardPointsUpdated?.status === "success"){
            toast.success(`Reward point ${formAction === "create" ? "created" : "edited"} successfully`);
            dispatch(rewardsActions.resetCreateRewardPoints());
            dispatch(rewardsActions.resetUpdateRewardPoints());
            clearForm();
            setIsOpen(false);
            getRewardPoints();
        }
        // eslint-disable-next-line
    }, [rewardPointsCreated, rewardPointsUpdated])


    return (
        <FormDrawer display="center" size="sm" isOpen={isOpen} setIsOpen={setIsOpen}>
            <form onSubmit={submitForm} className="p-6 max-w-sm">
                <div className="flex items-center justify-between">
                    <div className="page-title-mini capitalize">
                        {formAction} Point Activity
                    </div>
                    <div onClick={() => setIsOpen(false)} className="modal-close group">
                        <FontAwesomeIcon icon="times" className="group-hover:text-white m-auto" />
                    </div>
                </div>
                
                <div className="mt-10">
                    <Input type="text" label="Point Activity" placeholder="Enter a point activity" required={true} value={form.activity} onChange={(e) => setForm({...form, activity: e.target.value})} validationName="activity" checkFormValidation={checkFormValidation} />
                    <Textarea label="Description" placeholder="Enter a description of the activity..." required={true} styles={{height: "100px", resize: "none"}} value={form.description} onChange={(e) => setForm({...form, description: e.target.value})} validationName="description" checkFormValidation={checkFormValidation} />
                    <SearchableCategory
                        ref={searchableCategoryRef}
                        label="Search Category"
                        required={true}
                        value={form.category}
                        onValueChange={(e) => setForm({...form, category: e.value})}
                        validationName="category"
                        checkFormValidation={checkFormValidation}
                    />
                    <Input type="number" label="Number of Points to Earn" placeholder="0" required={true} value={form.pointsToEarn} onChange={(e) => setForm({...form, pointsToEarn: e.target.value})} validationName="pointsToEarn" checkFormValidation={checkFormValidation} />
                </div>
                
                <div className="mt-10 flex space-x-4">
                    <button type="button" onClick={() => setIsOpen(false)} className="btn btn-md btn-block btn-white">
                        Cancel
                    </button>
                    <ButtonLoader type="submit" loading={rewardPointsCreatedLoading || rewardPointsUpdatedLoading} className="btn btn-md btn-block btn-ep-blue" disabled={!isFormValidated}>
                        Save {formAction === "edit" && "Changes"}
                    </ButtonLoader>
                </div>
            </form>
        </FormDrawer>
    )
}


export default PointActivityForm;