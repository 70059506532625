import React from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import logo from "assets/images/icons/logo-icon.svg";
import authIntro1 from "assets/images/bgs/auth-new-intro-1.svg";
import authIntro2 from "assets/images/bgs/auth-new-intro-2.svg";
import authIntro3 from "assets/images/bgs/auth-new-intro-3.svg";

const slideshowProperties = {
    duration: 3000,
    transitionDuration: 300,
    pauseOnHover: true,
    indicators: true,
    autoplay: true,
    infinite: true,
    canSwipe: true,
    prevArrow: <div></div>,
    nextArrow: <div></div>,
}

const AuthLayout = (props) => {

    return ( 
        <div className="md:flex bg-gray">
            
            <div className="w-full md:w-2/4">
                <div className="h-full p-8 py-14 md:pb-0 flex">
                    <div className="w-full m-auto">
                        
                        <div className="text-center">
                            <div className="w-12 h-12 flex rounded mx-auto">
                                <img src={logo} alt="earnipay logo" className="object-contain w-full h-full" />
                            </div>
                        </div>

                        {props.children}

                    </div>
                </div>
            </div>

            <div className="w-full p-6 md:w-2/4 flex bg-white md:min-h-screen overflow-hidden">
                
                    <div className="w-full max-w-md m-auto pb-14 md:pb-0">
                        <Slide {...slideshowProperties} easing="ease">
                            
                            
                            <div className="slideshow-each">
                                <div>
                                    <div>
                                        <img src={authIntro1} alt="Improve Employee Productivity" className="object-contain" />
                                    </div>
                                    <div className="mt-1">
                                        <div className="font-recoleta font-semibold text-xl sm:text-2xl 2xl:text-3xl">
                                            Improve Employee Productivity
                                        </div>
                                        <div className="mt-2 text-sm">
                                            Reduce distractions caused due to financial short falls and lack of disposable income
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            
                            
                            <div className="slideshow-each">
                                <div>
                                    <div>
                                        <img src={authIntro2} alt="Attract and Retain Best Talent" className="object-contain mx-auto lg:mx-0" />
                                    </div>
                                    <div className="mt-1">
                                        <div className="font-recoleta font-semibold text-xl sm:text-2xl 2xl:text-3xl">
                                            Attract and Retain Best Talent
                                        </div>
                                        <div className="mt-2 text-sm">
                                            Incentivise current and future employees with the #1 employee benefit they need
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            
                            
                            <div className="slideshow-each">
                                <div>
                                    <div>
                                        <img src={authIntro3} alt="Build Financial Resilience" className="object-contain mx-auto lg:mx-0" />
                                    </div>
                                    <div className="mt-1">
                                        <div className="font-recoleta font-semibold text-xl sm:text-2xl 2xl:text-3xl">
                                            Build Financial Resilience
                                        </div>
                                        <div className="mt-2 text-sm">
                                            Empower employees to withstand unexpected expenses and avoid unnecessary borrowing
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            

                        </Slide>
                    </div>
                    
            </div>

        </div>
     );
}
 
export default AuthLayout;


// <div className="w-full md:w-2/4 flex">
//     <div className="w-full mx-auto mt-8 mb-14 md:mt-20 py-7 px-4 md:px-10">
//         {props.children}
//     </div>
// </div>