import { Menu } from "@headlessui/react";

const Dropdown = (props) => {
    return (
        <Menu>
            <div className={"group inline-block " + props.classes}>
                <Menu.Button>
                    {(props.children) ? props.children[0] : ""}
                </Menu.Button>

                <Menu.Items className={"dropdown " + props.dropdownClass}>
                    {(props.children) ? props.children[1] : ""}
                </Menu.Items>
            </div>
        </Menu>
    );
};

export default Dropdown;