import * as actions from "./actionTypes";
import { toast } from 'react-toastify';

const initialState = {
    loadingPaystackBalance: false,
    paystackBalance: {},
};


export default function reducer(state = initialState, action) {
    switch (action.type) {

        case actions.GET_PAYSTACK_BALANCE:
            // console.log(action.payload);
            state = {
                ...state,
                loadingPaystackBalance: false,
                paystackBalance: action.payload.data,
            }
            return state;


        case actions.GET_PAYSTACK_BALANCE_START:
            state = {
                ...state,
                loadingPaystackBalance: true,
            }
            return state;


        case actions.GET_PAYSTACK_BALANCE_FAILED:
            toast.error(action.payload.message);
            state = {
                ...state,
                loadingPaystackBalance: false,
                paystackBalance: {},
            }
            return state;


        default:
            return state;
    }
}