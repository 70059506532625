import React, { useEffect, useImperativeHandle, useState } from "react";
import { default as ReactSelect, components } from "react-select";
import Maybe from "components/__new/common/Maybe";
import validate from "utils/validate";


export const SearchableCategory = React.forwardRef((props, ref) => {
    const { label, placeholder, value, onValueChange, required, validationName, checkFormValidation } = props;
    const [errors, setErrors] = useState({});


    const options = [
        { label: "User Onboarding", value: "User Onboarding", },
        { label: "Savings", value: "Savings", },
        { label: "Transactions", value: "Transactions", },
        { label: "Bill payment", value: "Bill payment", },
    ];
    const [selectedOption, setSelectedOption] = useState({});


    const doValidation = (e) => {
        if (validationName !== undefined){
            const data = {[validationName]: e.value};
            const vErrors = validate(data);
            if (vErrors){
                setErrors(vErrors);
            }
            else{
                setErrors({});
            }
            checkFormValidation(undefined, validationName, e.value);
        }
    }


    const updateSelection = (e) => {
        setSelectedOption(e);
        onValueChange(e);
    }


    const clearSearchableCategory = () => {
        setSelectedOption({});
    }


    useImperativeHandle(ref, () => ({
        clearSearchableCategory
    }));


    useEffect(() => {
        setSelectedOption({label: value, value});
        // eslint-disable-next-line
    }, [value])


    return (
        <div className="form-group">
            <label>
                {label || "Category"}
                <Maybe condition={required === true}>
                    <span className="form-input-required">*</span>
                </Maybe>
            </label>

            {/* 
            <Maybe condition={employeesLoading === true}>
                <input type="text" value="Loading categories..." readOnly={true} className="form-input" />
            </Maybe>

            <Maybe condition={employeesLoading === false}>
            */}
                <ReactSelect
                    options={options}
                    placeholder={placeholder ?? "Choose a category"}
                    noOptionsMessage={() => "No category yet"}
                    styles={ReactSelectStyles}
                    isMulti={false}
                    closeMenuOnSelect={true}
                    hideSelectedOptions={false}
                    components={{Option}}
                    onChange={(e) => {updateSelection(e); doValidation(e)}}
                    value={selectedOption}
                />
            {/* </Maybe> */}

            <Maybe condition={errors[validationName] !== undefined}>
                <div className="form-input-message">
                    {errors[validationName]}
                </div>
            </Maybe>
        </div>
    )
})



const Option = (props) => {
    return (
        <div className="p-0">
            <components.Option {...props}>
                <div className="-mx-2 -my-1.5 p-4 text-sm flex justify-between border rounded-sm">
                    <div className="w-10 flex-shrink-0">
                      <input
                        type="radio"
                        checked={props.isSelected}
                        onChange={() => null}
                        className="w-5 h-5 form-radio rounded-full"
                      />
                    </div>
                    <div className="flex-grow text-left font-semibold">
                        {props.label}
                        {/* <br /> */}
                        {/* <span className="text-fade">{props.value}</span> */}
                    </div>
                </div>
            </components.Option>
        </div>
    );
};


  
const ReactSelectStyles = {
    option: (provided, state) => ({
        ...provided,
        color: "#333",
        background: "#fff",
        cursor: "pointer",
        "&:hover": {
            background: "#eee",
        }
    }),
};

  

export default SearchableCategory;