import * as actions from "./actionTypes";
import errorReducer from "utils/errorReducer";

const initialState = {
    loading: false,
    uploadedFile: {},

    uploadLoading: false,
    upload: {},
};


export default function reducer(state = initialState, action) {
    switch (action.type) {

        case actions.FILE_UPLOAD:
            state = {
                ...state,
                loading: false,
                uploadedFile: {...action.payload},

                uploadLoading: false,
                upload: action.payload,
            }
            return state;


        case actions.FILE_UPLOAD_START:
            state = {
                ...state,
                loading: true,

                uploadLoading: true,
            }
            return state;


        case actions.FILE_UPLOAD_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                loading: false,
                uploadedFile: {},

                uploadLoading: false,
                upload: {},
            }
            return state;


        default:
            return state;
    }
}