import CenterModal from 'components/misc/CenterModal'
import { ReactComponent as IconCancel } from "assets/images/icons/project-icons/circle-times.svg";
import React from 'react'

const DeclineRequestModal = ({control, onClose, onProceed, loading, rejectReason, setRejectReason}) => {
    return (
        <CenterModal control={control} onClose={onClose}>
            <div className='flex items-center justify-center flex-col py-4'>
                <IconCancel />
                <div className="text-center pt-5">
                    <p className="mb-2 font-semibold">Reject this Request</p>
                    <p className="text-gray-500">Are you sure you want to reject this request?</p>
                </div>
                
                <div className="form-group w-full mt-7">
                    <label>
                        Comments
                    </label>
                    <textarea
                        placeholder='Enter a comment...'
                        value={rejectReason}
                        style={{ height: "120px", resize: "none" }}
                        onChange={(e) => setRejectReason(e.target.value)}
                        className="p-2 w-full border border-[#D1D5DB] rounded-md focus:ring-0 outline-none"
                    />
                </div>
                
                <div className="flex items-center justify-center pt-6">
                    <button type="button" className="btn btn-md btn-transparent-black lg:px-14" onClick={onClose}>
                        Cancel
                    </button>
                    
                    <button disabled={loading || rejectReason?.length < 10} type="button" className="btn btn-md bg-blue-500 text-white ml-3 lg:px-14" onClick={onProceed}>
                        Yes
                    </button>
                </div>
            </div>
        </CenterModal>
    )
}

export default DeclineRequestModal