import * as actions from "./actionTypes";
import { toast } from 'react-toastify';

const initialState = {
    loading: false,
    allPayroll: {},
    downloadLoading: false,
    downloadAllPayroll: {},
};


export default function reducer(state = initialState, action) {
    switch (action.type) {

        case actions.GET_ALL_PAYROLL:
            state = {
                ...state,
                loading: false,
                allPayroll: { ...action.payload }
            }
            return state;


        case actions.GET_ALL_PAYROLL_START:
            state = {
                ...state,
                loading: true,
                allPayroll: {},
            }
            return state;


        case actions.GET_ALL_PAYROLL_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }

            state = {
                ...state,
                loading: false,
                allPayroll: {},
            }
            return state;


        case actions.DOWNLOAD_GET_ALL_PAYROLL:
            state = {
                ...state,
                downloadLoading: false,
                downloadAllPayroll: { ...action.payload.payrolls }
            }
            return state;


        case actions.DOWNLOAD_GET_ALL_PAYROLL_START:
            toast("Downloading Data...");
            state = {
                ...state,
                downloadLoading: true,
                downloadAllPayroll: {},
            }
            return state;


        case actions.DOWNLOAD_GET_ALL_PAYROLL_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }

            state = {
                ...state,
                downloadLoading: false,
                downloadAllPayroll: {},
            }
            return state;


        default:
            return state;
    }
}