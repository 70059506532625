import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from "react-redux";
import queryString from 'query-string';
import DashboardBoxes from 'components/common/dashboard-boxes';
import { DateTimeFormat } from "utils/dateFormat";
import { Link } from 'react-router-dom';
import Download from 'components/common/download';
import { customizeSVG } from 'utils/customizeSVG.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactComponent as IconPlus } from "assets/images/icons/project-icons/Plus-bordered.svg";
import * as employersActions from "store/entities/employers/action";


const List = (props) => {
    
    const dispatch = useDispatch();

    const { allEmployerAdminLoading, allEmployerAdmin, downloadAllEmployerAdminLoading, downloadAllEmployerAdmin } = useSelector(s => s.entities.employers);

    const [adminParams, setAdminParams] = useState({
        page: 1,
        limit: 20,
        name: "",
        download: false,
    });
    const [employerId, setEmployerId] = useState("");
    const [dashboardBox] = useState({
        dashboardProps1: {
            iconBg: "bg-blue-200",
            icon: <IconPlus className="customizeSVG text-blue-600 m-auto w-7 h-7" />,
            titleText: "All Employer Admins",
            mainText: "-"
        },
    })

    const columns = [
        { label: "Date Added", key: "createdAt" },
        { label: "First Name", key: "firstName" },
        { label: "Last Name", key: "lastName" },
        { label: "Job Title", key: "jobTitle" },
        { label: "Email Address", key: "email" },
        { label: "Phone Number", key: "phoneNumber" },
        { label: "Active", key: "isActive" },
        { label: "Disabled", key: "disabled" },
    ];
    const downloadRef = useRef();


    const getAllQueryFilters = (params) => {
        const qs = queryString.parse(props.location.search);
        
        if ((qs.search !== undefined) && (qs.search !== null)){
            // searchQuery = qs.search;
            params = {...params, name: qs.search};
        }
        else{
            params = {...params, name: ""};
        }
        
        // ? run delete empty params
        
        return params;
    }


    const getAllAdmins = () => {
        if ("id" in props.match.params){
            const {id: employerId} = props.match.params;
            setEmployerId(employerId);
                
            const qs = queryString.parse(props.location.search);
            let params = adminParams;
            
            if ((qs.page !== undefined) && (!isNaN(qs.page)) && (qs.page > 0)){
                params = {...params, page: qs.page};
            }
            else{
                params = {...params, page: 1};
            }
            
            if ((qs.limit !== undefined) && (!isNaN(qs.limit)) && (qs.limit > 0)){
                params = {...params, limit: qs.limit};
            }
            else{
                params = {...params, limit: 20};
            }
            
            if ((qs.status !== undefined) && (qs.status !== null)){
                params = {...params, status: qs.status};
            }
            else{
                params = {...params, status: ""};
            }

            params = getAllQueryFilters(params);
            setAdminParams(params);
            dispatch(employersActions.getAllEmployerAdmins(employerId, params));
        }
    }


    useEffect(() => {
        getAllAdmins();
        // eslint-disable-next-line
    }, [props])

    
    customizeSVG();

    return (
        <div>


            <div>
                <div className="lg:flex">
                    <div className="w-full lg:w-1/2">
                        <div className="page-title capitalize">
                            Employer Admins
                        </div>
                    </div>
                    <div className="w-full lg:w-1/2 flex space-x-4 mt-2 lg:mt-0 lg:justify-end">
                        <div>
                            <Download
                                tooltip="Download admin list"
                                format="csv"
                                filename="All Employer Admins.csv"
                                columns={columns}
                                ref={downloadRef}
                                click={() => {
                                    dispatch(employersActions.downloadAllEmployerAdmins(employerId, {...adminParams, page: 1, limit: 10000})).then(() => {
                                        downloadRef.current.processDownload();
                                    });
                                }}
                                loading={downloadAllEmployerAdminLoading}
                                response={downloadAllEmployerAdmin}
                            />
                        </div>
                        {/* 
                        <div>
                            <SearchFilter
                                search={{
                                    name: "search",
                                    placeholder: "Keyword search",
                                }}
                            />
                        </div>
                         */}
                    </div>
                </div>


                <div className="mt-6">
                    <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-6">
                        <DashboardBoxes data={dashboardBox.dashboardProps1} loading={allEmployerAdminLoading} result={Object.keys(allEmployerAdmin).length} />
                    </div>
                </div>


                <div className="mt-2">
                    <div className="">

                        <div className="table-container">
                            <table className="table table-auto table-rounded table-border">
                                <thead>
                                    <tr>
                                        <th>Date Added</th>
                                        <th>Name</th>
                                        <th>Job Title</th>
                                        <th>Email</th>
                                        <th>Phone Number</th>
                                        <th>Active</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    
                                    {(allEmployerAdminLoading === true) && 
                                        <tr>
                                            <td colSpan="6">
                                                <div className="table-info">
                                                    <FontAwesomeIcon icon="spinner" spin />
                                                    <div className="font-bold uppercase">
                                                        Loading
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    }

                                    {((allEmployerAdminLoading === false) && (Object.keys(allEmployerAdmin).length <= 0)) && 
                                        <tr>
                                            <td colSpan="6">
                                                <div className="table-info">
                                                    <FontAwesomeIcon icon="list" />
                                                    <div className="font-bold uppercase">
                                                        No results found
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    }
                                    
                                    {((allEmployerAdminLoading === false) && (Object.keys(allEmployerAdmin).length > 0)) && 
                                        Object.keys(allEmployerAdmin).map((admin, index) =>
                                            <tr key={index}>
                                                <td>
                                                    <div>
                                                        {DateTimeFormat(allEmployerAdmin[index].createdAt)}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        {allEmployerAdmin[index].firstName} {allEmployerAdmin[index].lastName}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        {allEmployerAdmin[index].jobTitle}
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <Link to={{pathname:"mailto:" + allEmployerAdmin[index].email}} target="_blank" onClick={(e) => {window.open(e.target.getAttribute("href"), '_blank'); e.preventDefault(); }} className="hover:underline">{allEmployerAdmin[index].email}</Link>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <Link to={{pathname:"tel:" + allEmployerAdmin[index].phoneNumber}} target="_blank" onClick={(e) => {window.open(e.target.getAttribute("href"), '_blank'); e.preventDefault(); }} className="hover:underline">{allEmployerAdmin[index].phoneNumber}</Link>
                                                    </div>
                                                </td>
                                                <td>
                                                    {allEmployerAdmin[index].disabled === true &&
                                                        <div className="label label-red uppercase">
                                                            Disabled
                                                        </div>
                                                    }
                                                    {allEmployerAdmin[index].disabled === false &&
                                                        <>
                                                            {allEmployerAdmin[index].isActive === true &&
                                                                <div className="label label-green uppercase">
                                                                    Active
                                                                </div>
                                                            }
                                                            {allEmployerAdmin[index].isActive === false &&
                                                                <div className="label label-red uppercase">
                                                                    Inactive
                                                                </div>
                                                            }
                                                        </>
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    }
                                    
                                </tbody>
                            </table>
                        </div>



                    </div>
                </div>


            </div>
        </div>
    )
    
    
}

export default List;