import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import {TopNav, BottomNav} from 'components/common/sidebarLinks.jsx';
import { ReactComponent as IconLogout } from "assets/images/icons/project-icons/Logout.svg";
import logo from "assets/images/icons/project-icons/logo-white.svg";
import * as auth from "store/auth/user/action";

class Sidebar extends Component {
    
    
    render() { 

        return ( 
            <div className="h-screen flex-none fixed bg-ep-primary-600 hidden lg:block shadow-lg" style={{width:"250px"}}>
                <div className="w-full h-full bg-cover bg-no-repeat" style={{backgroundPosition:"0 20px"}}>
                    
                    <div className="px-6 py-8">
                        <Link to="/dashboard" className="h-7 inline-block animate hover:scale-110">
                            <img src={logo} alt="EarniPay logo" className="h-full object-contain" />
                        </Link>
                    </div>

                    <div className="sidebar-body h-screen overflow-auto">
                        <TopNav />

                        <div className="sidebar-footer-secondary pb-28">
                            <div className="sidebar-links-divider">
                                Others
                            </div>

                            <BottomNav />
                            
                            <div onClick={this.props.logout} className="sidebar-links">
                                <div className="sidebar-links-highlight"></div>
                                <IconLogout className="customizeSVG sidebar-links-icon" />
                                Logout
                            </div>
                        </div>
                    </div>

                    <div className="w-full sidebar-footer">
                        <BottomNav />

                        <div onClick={this.props.logout} className="sidebar-links">
                            <div className="sidebar-links-highlight"></div>
                            <IconLogout className="customizeSVG sidebar-links-icon" />
                            Logout
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => ({
    
});

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(auth.logoutUser()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);