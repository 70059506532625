import React, { Component } from "react";
import store from "store/store";
import queryString from "query-string";
import DashboardBoxes from "components/common/dashboard-boxes";
import CurrencyFormat from "utils/currencyFormat";
import Pagination from "components/common/pagination";
import Dropdown from "utils/dropdown";
import {
	DateMiniFormat,
	MonthName,
	MonthNameToIndex,
} from "utils/dateFormat";
import DialogBox from "utils/dialogBox";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Download from "components/common/download";
import SearchFilter from "components/common/filter";
import { customizeSVG } from "utils/customizeSVG.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactComponent as IconNav } from "assets/images/icons/project-icons/Navicon-v.svg";
import { ReactComponent as IconChartWave } from "assets/images/icons/project-icons/ChartWave.svg";
import { ReactComponent as Xclose } from "assets/images/icons/project-icons/xclose.svg";
import * as payments from "store/entities/payments/action";
import PaymentHandlersTable from "components/payments/PaymentHandlersTable";
import SplitInstructionsTable from "components/payments/SplitInstructionsTable";

const dropdownTableIcon = (
	<IconNav className="w-8 h-8 p-1.5 relative top-1 cursor-pointer group-hover:bg-gray-200 rounded" />
);

const columns = [
	{ label: "Payment ID", key: "invoiceId" },
	{ label: "Company Name", key: "company.companyName" },
	{ label: "Company Email", key: "company.invoiceEmail" },
	{ label: "Month", key: "month" },
	{ label: "Year", key: "year" },
	{ label: "Amount", key: "amount" },
	{ label: "Amount Paid", key: "amountPaid" },
	{ label: "Due Date", key: "dueDate" },
	{ label: "Token", key: "token" },
	{ label: "Status", key: "status" },
	{ label: "Created At", key: "createdAt" },
	{ label: "Updated At", key: "updatedAt" },
];

class List extends Component {
	constructor(props) {
		super(props);
		this.csvLinkEl = React.createRef();
	}

	state = {
		getAllInvoiceParams: {
			page: 1,
			status: "",
			limit: 20,
			download: false,
			year: "",
			month: "",
		},
		filter: {
			monthSelect: "",
			showMonthSelect: false,
		},
		dashboardProps1: {
			iconBg: "bg-blue-200",
			icon: (
				<IconChartWave className="customizeSVG text-blue-600 m-auto w-7 h-7" />
			),
			titleText: "Number of Withdrawal Payments",
			mainText: "-",
		},
		dashboardProps2: {
			iconBg: "bg-gray-200",
			icon: (
				<IconChartWave className="customizeSVG text-gray-600 m-auto w-7 h-7" />
			),
			titleText: "Amount of Payment Due",
			mainText: "-",
		},
		dataDownload: [],
		storeUnsubscribe: "",
		toggleModalDetails: false,
		paymentDetails: "",
		currentTab: "allPayments",
	};

	formChange = (e) => {
		const formValue = { ...this.state.filter };
		formValue[e.currentTarget.name] = e.currentTarget.value;
		this.setState({ filter: formValue });
	};

	getAllInvoice = () => {
		const qs = queryString.parse(this.props.location.search);
		let getAllInvoiceParams = { ...this.state.getAllInvoiceParams };
		let dashboardProps1 = { ...this.state.dashboardProps1 };
		let dashboardProps2 = { ...this.state.dashboardProps2 };

		const date = new Date();
		const currentMonthIndex = date.getMonth() + 1;
		let month = MonthName(currentMonthIndex);
		// let month = currentMonthIndex;
		let year = date.getFullYear();

		if (
			typeof qs.month !== undefined &&
			qs.month !== undefined &&
			qs.month !== null
		) {
			month = qs.month;
			getAllInvoiceParams = { ...getAllInvoiceParams, month: qs.month };
		} else {
			getAllInvoiceParams = { ...getAllInvoiceParams, month: month };
		}

		if (
			typeof qs.year !== undefined &&
			qs.year !== undefined &&
			qs.year !== null
		) {
			year = qs.year;
			getAllInvoiceParams = { ...getAllInvoiceParams, year: qs.year };
		} else {
			getAllInvoiceParams = { ...getAllInvoiceParams, year: year };
		}

		if (qs.status !== undefined && qs.status !== null) {
			getAllInvoiceParams = { ...getAllInvoiceParams, status: qs.status };
		} else {
			getAllInvoiceParams = { ...getAllInvoiceParams, status: "" };
		}

		if (qs.page !== undefined && !isNaN(qs.page) && qs.page > 0) {
			getAllInvoiceParams = { ...getAllInvoiceParams, page: qs.page };
		} else {
			getAllInvoiceParams = { ...getAllInvoiceParams, page: 1 };
		}

		if (qs.limit !== undefined && !isNaN(qs.limit) && qs.limit > 0) {
			getAllInvoiceParams = { ...getAllInvoiceParams, limit: qs.limit };
		} else {
			getAllInvoiceParams = { ...getAllInvoiceParams, limit: 20 };
		}

		dashboardProps1 = {
			...dashboardProps1,
			subText2: (
				<span className="text-ep-blue capitalize">
					{month}, {year}
				</span>
			),
		};
		dashboardProps2 = {
			...dashboardProps2,
			subText2: (
				<span className="text-ep-blue capitalize">
					{month}, {year}
				</span>
			),
		};

		this.setState({ getAllInvoiceParams, dashboardProps1, dashboardProps2 });

		getAllInvoiceParams = {
			...getAllInvoiceParams,
			month: MonthNameToIndex(month),
			year: year.toString(),
		}; // now requests month index
		this.props.getAllInvoice(getAllInvoiceParams);
	};

	showMonthSelect = () => {
		const filter = {
			...this.state.filter,
			showMonthSelect: !this.state.filter.showMonthSelect,
		};
		this.setState({ filter });
	};

	toggleMonthSelect = (e) => {
		e.preventDefault();
		const { monthSelect } = this.state.filter;
		if (monthSelect !== "") {
			let d = new Date(monthSelect),
				month = "" + (d.getMonth() + 1),
				year = "" + d.getFullYear();
			month = MonthName(month);

			const filter = { ...this.state.filter, showMonthSelect: false };
			this.setState({ filter });

			this.gotoUrlQuery({ month: month, year: year });
		}
	};

	gotoUrlQuery = (params) => {
		const qs = queryString.parse(this.props.location.search);

		Object.keys(params).forEach((paramName) => {
			delete qs[paramName];
		});

		let qsToUrl = new URLSearchParams(qs).toString();
		qsToUrl =
			this.props.location.pathname +
			"?" +
			(qsToUrl !== "" ? qsToUrl + "&" : "");

		Object.keys(params).forEach((paramName) => {
			if (params[paramName] !== "") {
				qsToUrl += paramName + "=" + params[paramName] + "&";
			}
		});

		qsToUrl = qsToUrl.substring(0, qsToUrl.length - 1);
		this.props.history.push(qsToUrl);
	};

	viewDetails = (invoice = {}) => {
		const toggleModalDetails = this.state.toggleModalDetails;
		this.setState({
			toggleModalDetails: !toggleModalDetails,
			paymentDetails: invoice,
		});
	};

	closeModal = (action) => {
		this.setState({ toggleModalDetails: action });
	};

	approveInvoice = async (invoiceId, token) => {
		const result = await DialogBox();
		if (result) {
			this.props.approveInvoice(invoiceId, token);
			this.setState({ toggleModalDetails: false });
		}
	};

	revokeApprovalInvoice = async (invoiceId) => {
		const result = await DialogBox({
			theme: "red",
		});
		if (result) {
			this.props.revokeApprovalInvoice(invoiceId);
			this.setState({ toggleModalDetails: false });
		}
	};

	downloadInvoicePDF = (invoice) => {
		const { invoiceId: paymentNoteId } = invoice;

		const downloadInvoicePDFParams = {
			paymentId: paymentNoteId,
		};
		this.props.downloadInvoicePDF(downloadInvoicePDFParams);
		toast("Downloading PDF");

		this.unsubscribe = store.subscribe(() => {
			if (this.props.downloadInvoicePDFLoading === false) {
				// if ((this.props.invoicePDF) && (Object.keys(this.props.invoicePDF).length > 0)){
				if (this.props.invoicePDF) {
					const invoicePDFContent = this.props.invoicePDF;

					const linkSource = `data:application/pdf;base64,${invoicePDFContent}`;
					const downloadLink = document.createElement("a");
					// const fileName = "Payment Note.pdf";
					const fileName = `Payment Note - ${
						invoice.company.companyName
					} - ${MonthName(invoice.month)} ${invoice.year}.pdf`;
					downloadLink.href = linkSource;
					downloadLink.download = fileName;
					downloadLink.click();

					toast.success("PDF downloaded");
				} else {
					toast.error("An error occurred while downloading payment note.");
				}
				this.unsubscribe();
			}
		});
		this.setState({ storeUnsubscribe: this.unsubscribe });
	};

	componentDidMount() {
		this.getAllInvoice();
	}

	componentDidUpdate(prevProps) {
		if (this.props.location !== prevProps.location) {
			this.getAllInvoice();
		}
		if (this.props.reloadPage === true) {
			setTimeout(() => {
				this.props.resetReloadPage();
				this.props.history.replace(
					this.props.location.pathname + this.props.location.search
				);
			}, 1000);
		}
	}

	render() {
		const paymentTabs = [
			{
				title: "All Payments",
				value: "allPayments",
			},
			{
				title: "Payment Handlers",
				value: "paymentHandlers",
			},
			{
				title: "Split Instructions",
				value: "splitInstructions",
			},
		];

		customizeSVG();
		const { allInvoice: data, invoiceResponse } = this.props;
		const userRole = this.props.user.userType;
		const {
			getAllInvoiceParams,
			toggleModalDetails,
			paymentDetails,
			currentTab,
		} = this.state;

		const TabsSwitch = () => (
			<div className="mt-6 w-full lg:w-max inlines-block box-border-only overflow-auto">
				<div className="h-10 flex">
					{paymentTabs.map(({ title, value }) => (
						<div
							key={title}
							className={`cursor-pointer page-nav ${
								currentTab === value && "active"
							}`}>
							<div
								onClick={() => this.setState({ currentTab: value })}
								className="whitespace-nowrap">
								{title}
							</div>
						</div>
					))}
				</div>
			</div>
		);

		const ExistingPaymentsTable = () => (
			<>
				<div className="mt-6 w-full lg:w-auto inline-block box-border-only overflow-auto">
					<div className="h-10 flex">
						<div
							onClick={() => this.gotoUrlQuery({ status: "" })}
							className={
								"cursor-pointer page-nav " +
								(getAllInvoiceParams.status === "" ? "active" : "")
							}>
							<div className="whitespace-nowrap">All</div>
						</div>
						<div
							onClick={() => this.gotoUrlQuery({ status: "paid" })}
							className={
								"cursor-pointer page-nav " +
								(getAllInvoiceParams.status === "paid" ? "active" : "")
							}>
							<div className="whitespace-nowrap">Paid</div>
						</div>
						<div
							onClick={() => this.gotoUrlQuery({ status: "unpaid" })}
							className={
								"cursor-pointer page-nav " +
								(getAllInvoiceParams.status === "unpaid" ? "active" : "")
							}>
							<div className="whitespace-nowrap">Unpaid</div>
						</div>
						<Link to="/payments/history" className="page-nav border-l">
							<div className="whitespace-nowrap">History</div>
						</Link>
					</div>
				</div>

				<div className="mt-2">
					<div className="">
						<div className="table-container">
							<table className="table table-auto table-rounded table-border table-align-tope">
								<thead>
									<tr>
										<th>Payment ID</th>
										<th>Company Info</th>
										<th>Month</th>
										<th>Amount</th>
										<th>Amount Paid</th>
										<th>Due Date</th>
										<th>Status</th>
										<th>Payment Due?</th>
										{(userRole === "super-admin" ||
											userRole === "financial-admin") && <th>Approval</th>}
										<th>Actions</th>
									</tr>
								</thead>
								<tbody>
									{this.props.loading === true && (
										<tr>
											<td
												colSpan={
													userRole === "super-admin" ||
													userRole === "financial-admin"
														? "10"
														: "9"
												}>
												<div className="table-info">
													<FontAwesomeIcon icon="spinner" spin />
													<div className="font-bold uppercase">Loading</div>
												</div>
											</td>
										</tr>
									)}

									{this.props.loading === false && !data && (
										<tr>
											<td
												colSpan={
													userRole === "super-admin" ||
													userRole === "financial-admin"
														? "10"
														: "9"
												}>
												<div className="table-info">
													<FontAwesomeIcon icon="unlink" />
													<div className="font-bold uppercase">
														An error occurred
														<br />
														Please try again later.
													</div>
												</div>
											</td>
										</tr>
									)}

									{this.props.loading === false &&
										data &&
										data.docs &&
										data.docs.length <= 0 && (
											<tr>
												<td
													colSpan={
														userRole === "super-admin" ||
														userRole === "financial-admin"
															? "10"
															: "9"
													}>
													{data.page <= data.totalPages && (
														<div className="table-info">
															<FontAwesomeIcon icon="list" />
															<div className="font-bold uppercase">
																No results found
															</div>
														</div>
													)}

													{data.page > data.totalPages && (
														<div className="table-info">
															<FontAwesomeIcon icon="exclamation-triangle" />
															<div className="font-bold uppercase">
																Invalid Page Number
															</div>
															<div className="mt-2">
																<Link
																	to="?page=1"
																	className="btn btn-ep-blue btn-sm mx-auto">
																	Goto Page 1
																</Link>
															</div>
														</div>
													)}
												</td>
											</tr>
										)}

									{this.props.loading === false &&
										data &&
										data.docs &&
										data.docs.length > 0 &&
										data.docs.map((invoice, index) => (
											<tr key={index}>
												<td>{invoice.invoiceId}</td>
												<td>
													{invoice.company && (
														<div>
															<div>{invoice.company.companyName}</div>
															<div className="text-fade">
																{invoice.company.invoiceEmail}
															</div>
														</div>
													)}
												</td>
												<td>
													{MonthName(invoice.month)}, {invoice.year}
												</td>
												<td>{CurrencyFormat(invoice.amount)}</td>
												<td>{CurrencyFormat(invoice.amountPaid)}</td>
												<td>
													<div>
														{DateMiniFormat(invoice.dueDate?.split("T")[0])}
													</div>
												</td>
												<td>
													{invoice.status === "unpaid" && (
														<div className="label label-red uppercase">
															Unpaid
														</div>
													)}
													{invoice.status === "paid" && (
														<div className="label label-green uppercase">
															Paid
														</div>
													)}
												</td>
												<td>
													{(() => {
														if (
															invoice.status === "unpaid" &&
															Date.parse(invoice.dueDate) -
																Date.parse(new Date()) <
																0
														) {
															return (
																<div className="label label-yellow uppercase">
																	Payment Due
																</div>
															);
														} else {
															return (
																<div className="label label-gray uppercase">
																	Not Due
																</div>
															);
														}
													})()}
												</td>
												{(userRole === "super-admin" ||
													userRole === "financial-admin") && (
													<td>
														{invoice.status === "unpaid" && (
															<>
																<button
																	type="button"
																	onClick={() =>
																		this.approveInvoice(
																			invoice.invoiceId,
																			invoice.token
																		)
																	}
																	className="btn btn-sm text-white bg-ep-blue">
																	{/* <FontAwesomeIcon icon="check" className="w-4 h-4 text-sm p-0.5 border border-white" style={{"borderRadius":"4px"}} /> */}
																	<span className="ml-2">Approve</span>
																</button>
															</>
														)}

														{invoice.status === "paid" && (
															<>
																<button
																	type="button"
																	onClick={() =>
																		this.revokeApprovalInvoice(
																			invoice.invoiceId
																		)
																	}
																	className="btn btn-sm text-white bg-red-500">
																	{/* <FontAwesomeIcon icon="times" className="w-4 h-4 text-sm p-0.5 border border-white" style={{"borderRadius":"4px"}} /> */}
																	<span className="ml-2">Revoke Approval</span>
																</button>
															</>
														)}
													</td>
												)}
												<td>
													<div className="table-dropdown-align-top">
														<Dropdown
															icon={dropdownTableIcon}
															menu={[
																// {type: "link", text:"View Payment Note", link:`/payment-note/details/${invoice.invoiceId}`},
																{
																	type: "div",
																	text: "View Payment Note",
																	click: () => this.viewDetails(invoice),
																},
																{
																	type: "div",
																	text: "Download Payment Note",
																	click: () => this.downloadInvoicePDF(invoice),
																},
																{
																	type: "link",
																	text: "View Withdrawals",
																	link: `/transactions/withdrawals?month=${MonthName(
																		invoice.month
																	)}&year=${invoice.year}&employerId=${
																		invoice.employerId
																	}`,
																},
															]}
														/>
													</div>
												</td>
											</tr>
										))}
								</tbody>
							</table>
						</div>

						{this.props.loading === false &&
							data &&
							data.docs &&
							data.docs.length > 0 && (
								<div>
									<Pagination
										data={data}
										url={this.props.location.search}
										limit={getAllInvoiceParams.limit}
									/>
								</div>
							)}
					</div>
				</div>
			</>
		);

		return (
			<div>
				<div
					className={
						"max-w-md w-screen h-screen bg-white fixed z-50 top-0 bottom-0 shadow-xl transition-all duration-300 ease-in-out " +
						(toggleModalDetails ? "right-0" : "-right-full")
					}>
					<div className="w-full h-full p-5 pb-0 relative overflow-auto overscroll-contain">
						{Object.keys(paymentDetails).length > 0 && (
							<div>
								<div>
									<div className="flex justify-between">
										<div className="font-recoleta font-semibold text-2xl">
											Payment Details
										</div>
										<div
											onClick={() => this.closeModal(false)}
											className="w-8 h-8 flex group hover:bg-red-400 rounded-lg cursor-pointer">
											<Xclose className="customizeSVG group-hover:text-white m-auto" />
										</div>
									</div>
									<div className="text-fade text-sm">
										{paymentDetails.company.companyName}
									</div>
									<div className="mt-2 flex space-x-4">
										{paymentDetails.status === "unpaid" && (
											<div className="label label-red uppercase">Unpaid</div>
										)}
										{paymentDetails.status === "paid" && (
											<div className="label label-green uppercase">Paid</div>
										)}
									</div>
								</div>

								<div className="w-full h-px mt-8 bg-gray-200"></div>

								<div className="my-8">
									<div className="font-semibold text-sm">Payment</div>

									<div className="mt-5 grid grid-cols-2 gap-x-4">
										<div className="space-y-5">
											<div className="my-auto">
												<div className="font-semibold text-xs">Payment ID</div>
												<div className="mt-1 font-medium text-xs">
													{paymentDetails.invoiceId}
												</div>
											</div>

											<div className="w-full my-auto">
												<div className="font-semibold text-xs">Amount</div>
												<div className="mt-1 font-medium text-xs break-words">
													{CurrencyFormat(paymentDetails.amount)}
												</div>
											</div>

											<div className="w-full my-auto">
												<div className="font-semibold text-xs">Amount Paid</div>
												<div className="mt-1 font-medium text-xs break-words">
													{CurrencyFormat(paymentDetails.amountPaid)}
												</div>
											</div>
										</div>
										<div className="space-y-5">
											<div className="w-full my-auto">
												<div className="font-semibold text-xs">
													Payment Month
												</div>
												<div className="mt-1 font-medium text-xs break-words">
													{MonthName(paymentDetails.month)},{" "}
													{paymentDetails.year}
												</div>
											</div>

											<div className="w-full my-auto">
												<div className="font-semibold text-xs">Due Date</div>
												<div className="mt-1 font-medium text-xs break-words">
													{DateMiniFormat(paymentDetails.dueDate?.split("T")[0])}
												</div>
											</div>

											<div className="my-auto">
												<div className="font-semibold text-xs">Email</div>
												<div className="mt-1 font-medium text-xs">
													{paymentDetails.company.invoiceEmail}
												</div>
											</div>
										</div>
									</div>

									<div className="w-full h-px mt-8 bg-gray-200"></div>

									<div className="my-8 flex sm:justify-end space-x-2">
										<button
											type="button"
											onClick={() => this.downloadInvoicePDF(paymentDetails)}
											className="btn btn-md btn-transparent-black">
											Download Payment Note
										</button>
										{(userRole === "super-admin" ||
											userRole === "financial-admin") && (
											<>
												{paymentDetails.status === "unpaid" && (
													<button
														type="button"
														onClick={() =>
															this.approveInvoice(
																paymentDetails.invoiceId,
																paymentDetails.token
															)
														}
														className="btn btn-md btn-ep-blue text-white">
														Approve
													</button>
												)}
												{paymentDetails.status === "paid" && (
													<button
														type="button"
														onClick={() =>
															this.revokeApprovalInvoice(
																paymentDetails.invoiceId
															)
														}
														className="btn btn-md bg-red-500 text-white">
														Revoke
													</button>
												)}
											</>
										)}
									</div>
								</div>
							</div>
						)}
					</div>
				</div>

				<div>
					<div className="lg:flex">
						<div className="w-full lg:w-1/2">
							<div className="page-title capitalize">Payments</div>
						</div>
						<div className="w-full lg:w-1/2 flex space-x-4 mt-2 lg:mt-0 lg:justify-end">
							<div>
								<Download
									tooltip="Download payment notes list"
									format="csv"
									filename="All Payment Notes.csv"
									columns={columns}
									ref={(instance) => {
										this.Download = instance;
									}}
									click={() => {
										this.props
											.downloadGetAllInvoice({
												...getAllInvoiceParams,
												month: MonthNameToIndex(getAllInvoiceParams.month),
												year: getAllInvoiceParams.year.toString(),
												page: 1,
												limit: 10000,
											})
											.then(() => {
												this.Download.processDownload();
											});
									}}
									loading={this.props.downloadLoading}
									response={this.props.downloadAllInvoice}
								/>
							</div>
							<div>
								<SearchFilter
									search={{
										name: "search",
										placeholder: "Keyword search",
									}}
									filters={[
										{
											title: "Status",
											name: "status",
											dataType: "radio",
											options: [
												{
													display: "All",
													payload: "",
												},
												{
													display: "Paid",
													payload: "paid",
												},
												{
													display: "Unpaid",
													payload: "unpaid",
												},
											],
										},
										{
											title: "Month & Year",
											dataType: "month-year",
											option: {
												name: "month-year",
												placeholder: "Month & Year",
											},
										},
										{
											title: "Due Date",
											dataType: "date-range",
											options: {
												from: {
													name: "startDate",
													placeholder: "Start Date",
												},
												to: {
													name: "endDate",
													placeholder: "End Date",
												},
											},
										},
									]}
								/>
							</div>
						</div>
					</div>

					<div className="mt-6">
						<div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-6">
							<DashboardBoxes
								data={this.state.dashboardProps1}
								loading={this.props.loading}
								result={invoiceResponse.totalWithdrawals}
							/>
							<DashboardBoxes
								data={this.state.dashboardProps2}
								loading={this.props.loading}
								result={CurrencyFormat(invoiceResponse.amountDue)}
							/>
						</div>
					</div>

					<TabsSwitch />
					{currentTab === "allPayments" ? (
						<ExistingPaymentsTable />
					) : currentTab === "splitInstructions" ? 
							<SplitInstructionsTable />
					: (
						<PaymentHandlersTable />
					)}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	user: state.auth.userPersist.data,
	loading: state.entities.payments.loading,
	allInvoice: state.entities.payments.allInvoice.invoices,
	downloadLoading: state.entities.payments.downloadLoading,
	downloadAllInvoice: state.entities.payments.downloadAllInvoice,
	invoiceResponse: state.entities.payments.allInvoice,
	invoiceApproveLoading: state.entities.payments.invoiceApproveLoading,
	invoiceRevokeLoading: state.entities.payments.invoiceRevokeLoading,
	invoicePDF: state.entities.payments.invoicePDF,
	downloadInvoicePDFLoading: state.entities.payments.downloadInvoicePDFLoading,
	reloadPage: state.entities.payments.reloadPage,
});

const mapDispatchToProps = (dispatch) => ({
	getAllInvoice: (params) => dispatch(payments.getAllInvoice(params)),
	downloadGetAllInvoice: (params) =>
		dispatch(payments.downloadGetAllInvoice(params)),
	approveInvoice: (invoiceId, token) =>
		dispatch(payments.approveInvoice(invoiceId, token)),
	revokeApprovalInvoice: (invoiceId) =>
		dispatch(payments.revokeApprovalInvoice(invoiceId)),
	downloadInvoicePDF: (params) => dispatch(payments.downloadInvoicePDF(params)),
	resetReloadPage: () => dispatch(payments.resetReloadPage()),
});

export default connect(mapStateToProps, mapDispatchToProps)(List);
