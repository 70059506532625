import Maybe from '../common/Maybe';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import ContentLoading from '../common/ContentLoading';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactComponent as Xclose } from "assets/images/icons/project-icons/xclose.svg";


const SubAccountDetails = ({isOpen, setIsOpen}) => {
    const { getEmployerLoading, getEmployer: employer } = useSelector((state) => state.entities.employers)

    const detailKeyClass = "text-xs"
	const detailValueClass = "mt-1 font-medium capitalize text-sm"

    const businessIsPending = employer?.employer?.status === "pending"
    const businessIsInReview = employer?.employer?.status === "review"
    const businessIsActive = employer?.employer?.status === "approved"
    const businessIsRejected = employer?.employer?.status === "rejected"
    
    return (
        <div className={"max-w-md w-screen h-screen bg-white fixed z-50 top-0 bottom-0 shadow-xl transition-all duration-300 ease-in-out " + (isOpen ? "right-0" : "-right-full")}>
            <div className="w-full h-full p-5 pb-0 pl-0 relative overflow-auto overscroll-contain">
                    <div>
                        <div className="pl-5">
                            <div className="flex justify-between">
                                <div className="font-recoleta font-semibold text-2xl capitalize">
                                    <ContentLoading 
                                        loading={getEmployerLoading}
                                        data={employer?.employer?.company?.companyName}
                                    />
                                </div>
                                <div onClick={() => setIsOpen(false)}
                                    className="w-8 h-8 flex group hover:bg-red-400 rounded-lg cursor-pointer">
                                    <Xclose className="customizeSVG group-hover:text-white m-auto" />
                                </div>
                            </div>
                            <div className="text-fade text-sm">
                                <ContentLoading 
                                    loading={getEmployerLoading}
                                    data={employer?.employer?.email}
                                />
                            </div>
                            
                            <div className="mt-2 flex space-x-4">
                                <div className="flex h-6">
                                    {employer?.employer?.status?.length && (
                                        <>
                                            <div
                                                className="w-2 h-2 my-auto mr-2 rounded-full"
                                                style={{ backgroundColor: businessIsActive ? "#398D94" : (businessIsPending || businessIsInReview) ? "#F49D1B" : businessIsRejected ? "#DC2626" : "" }}></div>
                                            <div className="my-auto text-xs">{businessIsActive ? "Active" : businessIsPending ? "Pending" : businessIsInReview ? "In Review" : businessIsRejected ? "Rejected" : "-"}</div>
                                        </>
                                    )}
                                </div>
                                
                                <Maybe condition={getEmployerLoading === false}>
                                    <div className="flex items-center">
                                        <Link
                                            to={"/users/employees?employerId=" + employer?.employer?._id}
                                            className="btn btn-sm bg-gray-200 btn-gray-hover mr-3">
                                            View Employees
                                        </Link>
                                    </div>
                                </Maybe>
                            </div>
                        </div>

                        <div className="w-full h-px my-7 bg-gray-200"></div>
                        <div className="my-7 pb-5 pl-5">
                            <div className="font-semibold text-xs mb-5">
                                Business Details
                            </div>
                            
                            <Maybe condition={getEmployerLoading === true}>
                                <div className="h-20 flex flex-col items-center justify-center">
                                    <FontAwesomeIcon icon="spinner" spin className="text-md" />
                                    <div className={detailValueClass}>
                                        Fetching Business Details...
                                    </div>
                                </div>
                            </Maybe>
                            
                            <div className="grid grid-cols-2 gap-x-2">
                                <div className="space-y-7">
                                    <div className="my-auto">
                                        <div className={detailKeyClass}>
                                            Business Name
                                        </div>
                                        <div className={detailValueClass}>
                                            {employer?.employer?.company?.companyName || "-"}
                                        </div>
                                    </div>

                                    <div className="my-auto">
                                        <div className={detailKeyClass}>
                                            Industry Type
                                        </div>
                                        <div className={detailValueClass}>
                                            {employer?.employer?.company?.industry || "-"}
                                        </div>
                                    </div>
                                    
                                    <div className="my-auto">
                                        <div className={detailKeyClass}>
                                            Business Address
                                        </div>
                                        <div className={detailValueClass}>
                                            {employer?.employer?.company?.address || "-"}
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="space-y-7">
                                    <div className="my-auto">
                                        <div className={detailKeyClass}>
                                            Business Size
                                        </div>
                                        <div className={detailValueClass}>
                                            {employer?.employer?.company?.estimatedEmployees ?? "-"}
                                        </div>
                                    </div>

                                    <div className="my-auto">
                                        <div className={detailKeyClass}>
                                            Business Phone Number
                                        </div>
                                        <div className={detailValueClass}>
                                            {employer?.employer?.phoneNumber || "-"}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="w-full h-px my-5 bg-gray-200"></div>
                            <div className="font-semibold text-xs mb-5">
                                Personal Information
                            </div>
                            <div className="grid grid-cols-2 gap-x-2">
                                <div className="space-y-7">
                                    <div className="my-auto">
                                        <div className={detailKeyClass}>
                                            Full Name
                                        </div>
                                        <div className={detailValueClass}>
                                            <ContentLoading 
                                                loading={getEmployerLoading}
                                                data={`${employer?.employer?.firstName + " " + employer?.employer?.lastName}`}
                                            />
                                        </div>
                                    </div>

                                    <div className="my-auto">
                                        <div className={detailKeyClass}>
                                            Country
                                        </div>
                                        <div className={detailValueClass}>
                                            <ContentLoading
                                                loading={getEmployerLoading}
                                                data={employer?.employer?.company?.country ?? "-"}
                                            />
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="space-y-7">
                                    <div className="my-auto">
                                        <div className={detailKeyClass}>
                                            Date of Birth
                                        </div>
                                        <div className={detailValueClass}>
                                            <ContentLoading
                                                loading={getEmployerLoading}
                                                data={employer?.employer?.company?.dob ?? "-"}
                                            />
                                        </div>
                                    </div>

                                    <div className="my-auto">
                                        <div className={detailKeyClass}>
                                            BVN
                                        </div>
                                        <div className={detailValueClass}>
                                            {employer?.employer?.company?.bvn || "-"}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </div>
    )
}

export default SubAccountDetails