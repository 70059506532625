import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactComponent as Xclose } from "assets/images/icons/project-icons/xclose.svg";
import { ReactComponent as IconCopy } from "assets/images/icons/project-icons/copy.svg";
import { toast } from "react-toastify";
import { StatusTimeline } from "./MiscUtils";
import CurrencyFormat from "utils/currencyFormat";
import ContentLoading from "../common/ContentLoading";

const CreditDetailsModal = ({
  control,
  user,
  onClose,
  loading,
  setIsDetailsOpen,
}) => {
  // const user = {
  // 	_id: 918389283
  // }
  const detailKeyClass = "text-xs";
  const detailValueClass = "mt-1 font-medium capitalize text-sm";

  const displayDetails = (details) => {
    if (loading === true) {
      return <FontAwesomeIcon icon="spinner" spin className="text-md" />;
    }
    return details || "-";
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);

    const options = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  };

  const copyItem = (item) => {
    navigator.clipboard.writeText(item);
    toast(`Copied: ${item}`);
  };

  const maskCharacters = (str) => {
    if (str?.length <= 4) {
      return str;
    }

    return `${str?.substring(0, 3)}${"*".repeat(
      str?.length - 6
    )}${str?.substring(str?.length - 3)}`;
  };

  return (
    <div
      className={
        "max-w-md w-screen h-screen bg-white fixed z-50 top-0 bottom-0 shadow-xl transition-all duration-300 ease-in-out " +
        (control ? "right-0" : "-right-full")
      }
    >
      <div className="w-full h-full p-5 pb-10 pt-2 relative overflow-auto overscroll-contain">
        <div className="my-8">
          <div className="w-full">
            <div>
              <div className="flex justify-between">
                <div className="font-recoleta font-semibold text-2xl capitalize">
                  {/* {displayDetails(`${user?.firstName} ${user?.lastName}`)} */}
                  View User Details
                </div>
                <div
                  onClick={() => setIsDetailsOpen(false)}
                  className="w-8 h-8 flex group hover:bg-red-400 rounded-lg cursor-pointer"
                >
                  <Xclose className="customizeSVG group-hover:text-white m-auto" />
                </div>
              </div>
            </div>

            <div className="w-full h-px mt-6 mb-6 bg-gray-200"></div>

            <div className="font-semibold text-sm">User Details</div>

            <div className="mt-5 grid grid-cols-2 gap-x-4">
              <div className="space-y-5">
                <div className="my-auto">
                  <div className={detailKeyClass}>Name of User</div>
                  <div className={detailValueClass}>
                    <ContentLoading loading={loading} data={user?.name} />
                  </div>
                </div>

                <div className="my-auto">
                  <div className={detailKeyClass}>Phone Number</div>
                  <div className={detailValueClass}>
                    <ContentLoading
                      loading={loading}
                      data={user?.phoneNumber}
                    />
                  </div>
                </div>

                <div className="my-auto">
                  <div className={detailKeyClass}>Date of Birth</div>
                  <div className={detailValueClass}>
                    <ContentLoading loading={loading} data={user?.dob} />
                  </div>
                </div>

                <div className="my-auto">
                  <div className={detailKeyClass}>Application Date</div>
                  <div className="mt-1 font-medium capitalize text-sm flex items-center justify-start">
                    {formatDate(user?.createdAt)}
                  </div>
                </div>

                <div className="my-auto">
                  <div className={detailKeyClass}>Limit Approval Date</div>
                  <div className="mt-1 font-medium capitalize text-sm flex items-center justify-start">
                    {formatDate(user?.reviewedDate)}
                  </div>
                </div>
              </div>
              <div className="space-y-5">
                <div className="my-auto">
                  <div className={detailKeyClass}>Email Address</div>
                  <div className="mt-1 font-medium text-sm overflow-x-auto">
                    <ContentLoading loading={loading} data={user?.email} />
                  </div>
                </div>

                <div className="w-full my-auto">
                  <div className={detailKeyClass}>Bank Verification Number</div>
                  <div className="mt-1 font-medium text-m">
                    <ContentLoading
                      loading={loading}
                      data={maskCharacters(user?.bvn)}
                    />
                  </div>
                </div>

                <div className="my-auto">
                  <div className={detailKeyClass}>User ID</div>
                  <div className="mt-1 font-medium capitalize text-sm flex items-center justify-start">
                    {user?.userId?.substring(0, 8) || "-"}
                    {/* <Maybe condition={user?._id?.length > 0}> */}
                    <span
                      onClick={() => copyItem(user?.userId)}
                      className="ml-2 cursor-pointer z-10 text-ep-blue-deeper"
                    >
                      <IconCopy />
                    </span>
                    {/* </Maybe> */}
                  </div>
                </div>

                <div className="w-full my-auto">
                  <div className={detailKeyClass}>Loan Limit</div>
                  <div className="mt-1 font-medium text-m">
                    <ContentLoading
                      loading={loading}
                      data={CurrencyFormat(user?.amount / 100)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="font-semibold text-xs md:text-[13px] mt-7 mb-5">
            Loan Limit
          </div>

          <div className="border border-gray-200 rounded-lg w-full">
            <div className="mb-5 pl-5 pt-4">
              <div className="font-medium text-sm mb-1.5">
                New Loan Limit: {CurrencyFormat(user?.amount / 100)}
              </div>
              <div className="font-medium text-sm mb-5">
                <span className="text-gray-400">Old Limit: &nbsp;</span>
                {CurrencyFormat(user?.old_amount / 100, "no-kobo")}
              </div>
            </div>

            {/* <div className="p-3 pl-8">
							<StatusTimeline
								isRequested
								email={"Emem O."}
								timestamp={"2024-02-06T14:06:01.214Z"}
							/>
						
							<StatusTimeline
								isApproved
								email={"Laolu Oyedele"}
								timestamp={"2024-02-06T14:06:01.214Z"}
							/>
						
							<StatusTimeline
								isRejected
								hideConnector
								email={"Laolu Oyedele"}
								timestamp={"2024-02-06T14:06:01.214Z"}
							/>
						</div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreditDetailsModal;
