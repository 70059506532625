import React, { useState } from 'react';
import { TabSettingsWide, TabSettingsMobile } from 'components/common/settingsTab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useLoginInfo from 'hooks/useLoginInfo';
import Maybe from 'components/__new/common/Maybe';

const PayrollFees = () => {
    const [isViewing, setIsViewing] = useState(true);
    const [form, setForm] = useState([
        {
            serviceType: "Salaries",
            charges: [
                {id: 1, employeeRange: "0-25", amount: "4000"},
                // {id: 27, employeeRange: "26-50", amount: "7900"},
                // {id: 18, employeeRange: ">50", amount: "48000"},
            ]
        },
        {
            serviceType: "PAYE",
            charges: [
                {id: 11, employeeRange: "0-25", amount: "8000"},
                // {id: 15, employeeRange: "26-50", amount: "10200"},
                // {id: 12, employeeRange: ">50", amount: "4500"},
            ]
        },
        {
            serviceType: "Pension",
            charges: [
                {id: 8, employeeRange: "0-25", amount: "1256"},
                // {id: 33, employeeRange: "26-50", amount: "1200"},
                // {id: 84, employeeRange: ">50", amount: "32000"},
            ]
        }
    ]);
    
    // const defaultRanges = [
    //     {
    //         title: "0-25 employees",
    //         value: "0-25"
    //     },
    //     {
    //         title: "26-50 employees",
    //         value: "26-50"
    //     },
    //     {
    //         title: ">50 employees",
    //         value: ">50"
    //     }
    // ]
    
    const { userInfo } = useLoginInfo();
    
    // const updateRange = (serviceType, e, id) => {
    //     const service = serviceType.toLowerCase();
    //     const value = e.target.value;
    //     const updatedForm = form.map((item) => item.serviceType.toLowerCase() === service ? {...item, charges: [...item.charges.map((item) => item.id === id ? {...item, employeeRange: value} : item)]} : item)
        
    //     setForm(updatedForm)
    // }
    
    const updateAmount = (serviceType, e, id) => {
        const service = serviceType.toLowerCase();
        const value = e.target.value;
        const updatedForm = form.map((item) => item.serviceType.toLowerCase() === service ? {...item, charges: [...item.charges.map((item) => item.id === id ? {...item, amount: value} : item)]} : item)
        
        setForm(updatedForm)
    }

        return ( 
            <div>
                
                <TabSettingsMobile />
                
                <div className="box">
                    <div className="flex">
                        <TabSettingsWide />

                        <div className="lg:w-3/4 xl:w-4/5 lg:pl-6 flex-grow">
                        
                            <div className="grid sm:grid-cols-2">
                                <div className="page-title">
                                    Payroll Fees Settings
                                </div>
                                
                                <Maybe condition={isViewing}>
                                    <div className="flex sm:justify-end pt-3 md:pt-0">
                                        <Maybe condition={userInfo.userType === "super-admin" || userInfo.userType === "financial-admin"}>
                                            <button onClick={() => setIsViewing(false)} className="btn btn-md btn-ep-blue">
                                                <FontAwesomeIcon icon="plus" className="text-md" />
                                                <span>
                                                    Edit Fees
                                                </span>
                                            </button>
                                        </Maybe>
                                    </div>
                                </Maybe>
                            </div>
                            
                            <div className="w-full my-7">
                                <p className="text-gray-500">
                                    Service Type
                                </p>
                                
                                {form.map(({serviceType, charges}) => {
                                    return (
                                        <div key={serviceType} className="payroll-setting-wrapper">
                                            <div className="payroll-setting-service">
                                                {serviceType}
                                            </div>
                                            <div className="payroll-setting-form-wrapper">
                                            {charges.map(({employeeRange, amount, id}) => {
                                                return (
                                                        <div key={id} className="payroll-setting-form">
                                                            {/* <div className="payroll-setting-form-item lg:mr-5">
                                                                <div className="form-group">
                                                                        <label>Employee Range</label>
                                                                        <select disabled={isViewing} defaultValue={employeeRange || ""} onChange={(e) => updateRange(serviceType, e, id)} className={`w-full py-1.5 border border-gray-300 rounded ${isViewing && "bg-gray-300"}`}>
                                                                            <option value="">Select Type</option>
                                                                            {defaultRanges.map(({title, value}) => (
                                                                                <option key={value} value={value}>{title}</option>
                                                                            ))}
                                                                        </select>
                                                                    </div>
                                                            </div> */}
                                                            
                                                            <div className="payroll-setting-form-item">
                                                            <div className="form-group relative">
                                                                    <label>Amount</label>
                                                                    <input
                                                                        disabled={isViewing}
                                                                        onChange={(e) => updateAmount(serviceType, e, id)}
                                                                        type="number" 
                                                                        placeholder="Enter amount" 
                                                                        className={`form-input input-minAmount-create ${isViewing && "bg-gray-300 cursor-not-allowed"}`} 
                                                                        value={amount || ""}
                                                                        id="payroll-fee-input"
                                                                        />
                                                                        <span className="absolute top-7 left-3">₦&nbsp;</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                )
                                            })}
                                            </div>
                                    </div>
                                    )
                                })}
                                
                                <Maybe condition={!isViewing}>
                                    <div className="w-full flex items-center justify-start lg:justify-end mt-5">
                                        <button onClick={() => {}} className="btn btn-md btn-ep-blue mr-4">
                                            Update
                                        </button>
                                            
                                        <button type="button" onClick={() => setIsViewing(!isViewing)} className="btn btn-transparent-black text-sm">
                                            Cancel
                                        </button>
                                    </div>
                                </Maybe>
                            </div>
                            
                        </div>
                    </div>
                </div>
                
            </div>
        );
}

export default PayrollFees;