import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { TabSettingsWide, TabSettingsMobile } from 'components/common/settingsTab';
import { customizeSVG } from 'utils/customizeSVG.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as settings from "store/entities/settings/action";

class List extends Component {

    componentDidMount() {
        this.props.getMobileSettings();
    }
    

    render() { 
        customizeSVG();
        const { mobileSettingsLoading, mobileSettings } = this.props;

        return ( 
            <div>

                <TabSettingsMobile />

                <div className="box">
                    <div className="flex">
                        <TabSettingsWide />

                        <div className="lg:w-3/4 xl:w-4/5 lg:pl-6 flex-grow">
                        
                            <div className="grid sm:grid-cols-2">
                                <div className="page-title">
                                    App Settings
                                </div>
                                <div className="flex justify-end">
                                    {this.props.user.userType === "super-admin" &&
                                        <Link to="/settings/app/edit" className="btn btn-ep-blue btn-md">
                                            <FontAwesomeIcon icon="edit" className="mr-2" />
                                            Edit
                                        </Link>
                                    }
                                </div>
                            </div>

                            <div className="mt-6">
                                {(mobileSettingsLoading === true) && 
                                    <div className="table-info py-2">
                                        <FontAwesomeIcon icon="spinner" spin />
                                        <div className="font-bold uppercase">
                                            Loading
                                        </div>
                                    </div>
                                }

                                {((mobileSettingsLoading === false) && (!mobileSettings)) && 
                                    <div className="table-info py-2">
                                        <FontAwesomeIcon icon="unlink" />
                                        <div className="font-bold uppercase">
                                            An error occurred
                                            <br />
                                            Please try again later.
                                        </div>
                                    </div>
                                }
                                
                                {((mobileSettingsLoading === false) && (mobileSettings) && (Object.keys(mobileSettings).length) > 0) && 
                                    <div className="flex flex-col space-y-4">

                                        <div>
                                            <div className='font-bold'>
                                                Android App
                                            </div>
                                            <div className='text-fade'>
                                                Version {mobileSettings.android.version}
                                                &nbsp; , &nbsp;
                                                Force Update {(mobileSettings.android.forceUpdate ? "Allowed" : "Disallowed")}
                                            </div>
                                        </div>

                                        <div>
                                            <div className='font-bold'>
                                                iOS App
                                            </div>
                                            <div className='text-fade'>
                                                Version {mobileSettings.ios.version}
                                                &nbsp; , &nbsp;
                                                Force Update {(mobileSettings.ios.forceUpdate ? "Allowed" : "Disallowed")}
                                            </div>
                                        </div>

                                    </div>
                                }
                            </div>

                        </div>
                    </div>
                </div>
                
            </div>
        );
    }
}


const mapStateToProps = (state) => ({
    user: state.auth.userPersist.data,
    mobileSettingsLoading: state.entities.settings.mobileSettingsLoading,
    mobileSettings: state.entities.settings.mobileSettings,
});

const mapDispatchToProps = (dispatch) => ({
    getMobileSettings: () => dispatch(settings.getMobileSettings()),
});

export default connect(mapStateToProps, mapDispatchToProps)(List);
