export const GET_ALL_ADMINS = "GET_ALL_ADMINS";
export const GET_ALL_ADMINS_START = "GET_ALL_ADMINS_START";
export const GET_ALL_ADMINS_FAILED = "GET_ALL_ADMINS_FAILED";

export const GET_ADMIN = "GET_ADMIN";
export const GET_ADMIN_START = "GET_ADMIN_START";
export const GET_ADMIN_FAILED = "GET_ADMIN_FAILED";

export const CREATE_ADMIN = "CREATE_ADMIN";
export const CREATE_ADMIN_START = "CREATE_ADMIN_START";
export const CREATE_ADMIN_FAILED = "CREATE_ADMIN_FAILED";
export const CREATE_ADMIN_RESET = "CREATE_ADMIN_RESET";

export const UPDATE_ADMIN = "UPDATE_ADMIN";
export const UPDATE_ADMIN_START = "UPDATE_ADMIN_START";
export const UPDATE_ADMIN_FAILED = "UPDATE_ADMIN_FAILED";
export const UPDATE_ADMIN_RESET = "UPDATE_ADMIN_RESET";

export const UPDATE_ADMIN_STATUS = "UPDATE_ADMIN_STATUS";
export const UPDATE_ADMIN_STATUS_START = "UPDATE_ADMIN_STATUS_START";
export const UPDATE_ADMIN_STATUS_FAILED = "UPDATE_ADMIN_STATUS_FAILED";

export const DELETE_ADMIN = "DELETE_ADMIN";
export const DELETE_ADMIN_START = "DELETE_ADMIN_START";
export const DELETE_ADMIN_FAILED = "DELETE_ADMIN_FAILED";

export const RESEND_INVITE = "ADMIN_RESEND_INVITE";
export const RESEND_INVITE_START = "ADMIN_RESEND_INVITE_START";
export const RESEND_INVITE_FAILED = "ADMIN_RESEND_INVITE_FAILED";

export const ADMIN_ONBOARD = "ADMIN_ONBOARD";
export const ADMIN_ONBOARD_START = "ADMIN_ONBOARD_START";
export const ADMIN_ONBOARD_FAILED = "ADMIN_ONBOARD_FAILED";
export const ADMIN_ONBOARD_RESET = "ADMIN_ONBOARD_RESET";

export const RELOAD_PAGE_RESET = "RELOAD_PAGE_RESET";