import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { ReactComponent as IconDashboard } from "assets/images/icons/project-icons/dashboard.svg";
import { ReactComponent as IconEmployers } from "assets/images/icons/project-icons/employers.svg";
import { ReactComponent as IconEmployees } from "assets/images/icons/project-icons/employees.svg";
import { ReactComponent as IconWithdrawals } from "assets/images/icons/project-icons/withdrawals.svg";
import { ReactComponent as IconSavings } from "assets/images/icons/project-icons/savings.svg";
import { ReactComponent as IconCredit } from "assets/images/icons/project-icons/credit.svg";
import { ReactComponent as IconRewards } from "assets/images/icons/project-icons/rewards.svg";
import { ReactComponent as IconReports } from "assets/images/icons/project-icons/reports.svg";
// import { ReactComponent as IconApprovals } from "assets/images/icons/project-icons/approvals.svg";
import { ReactComponent as IconSearch } from "assets/images/icons/project-icons/Search.svg";
import { ReactComponent as IconPayments } from "assets/images/icons/project-icons/payments.svg";
import { ReactComponent as IconReferrals } from "assets/images/icons/project-icons/referrals.svg";
import { ReactComponent as IconAmbassadors } from "assets/images/icons/project-icons/share-alt.svg";
import { ReactComponent as IconSettings } from "assets/images/icons/project-icons/settings.svg";
import { ReactComponent as IconAuditTrail } from "assets/images/icons/project-icons/audit-trail.svg";

export const TopNav = (props) => {
	const { data: user } = useSelector((state) => state.auth.userPersist);

	return (
		<React.Fragment>
			<NavLink to="/dashboard" className="sidebar-links">
				<div className="sidebar-links-highlight"></div>
				<IconDashboard className="sidebar-links-icon" />
				Dashboard
			</NavLink>

			<NavLink to="/businesses" className="sidebar-links">
				<div className="sidebar-links-highlight"></div>
				<IconEmployers className="sidebar-links-icon" />
				Businesses
			</NavLink>

			<NavLink to="/businesses-balance" className="sidebar-links">
				<div className="sidebar-links-highlight"></div>
				<IconEmployers className="sidebar-links-icon" />
				Businesses Balance
			</NavLink>

			<NavLink to="/customers-balance" className="sidebar-links">
				<div className="sidebar-links-highlight"></div>
				<IconEmployers className="sidebar-links-icon" />
				Customers Balance
			</NavLink>

			<NavLink to="/sub-accounts" className="sidebar-links">
				<div className="sidebar-links-highlight"></div>
				<IconEmployers className="sidebar-links-icon" />
				Sub Accounts
			</NavLink>
            
			{/* <NavLink to="/compliance" className="sidebar-links">
				<div className="sidebar-links-highlight"></div>
				<IconEmployers className="sidebar-links-icon" />
				Compliance
			</NavLink> */}
            
            <NavLink to="/users" className="sidebar-links">
                <div className="sidebar-links-highlight"></div>
                <IconEmployees className="customizeSVG sidebar-links-icon" />
                Users
            </NavLink>
            
            <NavLink to="/transactions" className="sidebar-links">
                <div className="sidebar-links-highlight"></div>
                <IconWithdrawals className="sidebar-links-icon" />
                Transactions
            </NavLink>

			<NavLink to="/savings/goals" className="sidebar-links">
				<div className="sidebar-links-highlight"></div>
				<IconSavings className="sidebar-links-icon" />
				Savings
			</NavLink>

			<NavLink to="/credit" className="sidebar-links">
				<div className="sidebar-links-highlight"></div>
				<IconCredit className="sidebar-links-icon" />
				Credit
			</NavLink>
            
            <NavLink to="/rewards" className="sidebar-links">
                <div className="sidebar-links-highlight"></div>
                <IconRewards className="sidebar-links-icon" />
                Rewards
			</NavLink>
            
            {/* <NavLink to="/approvals" className="sidebar-links">
                <div className="sidebar-links-highlight"></div>
                <IconApprovals className="customizeSVG sidebar-links-icon" />
                Approvals
            </NavLink> */}
    
            <NavLink to="/reports" className="sidebar-links">
                <div className="sidebar-links-highlight"></div>
                <IconReports className="sidebar-links-icon" />
                Reports
            </NavLink>
            
            <NavLink to="/search" className="sidebar-links">
                <div className="sidebar-links-highlight"></div>
                <IconSearch className="sidebar-links-icon w-5" />
                <span className="-ml-1.5">
                    Query Search
                </span>
            </NavLink>
            
            <NavLink to="/payments" className="sidebar-links">
                <div className="sidebar-links-highlight"></div>
                <IconPayments className="sidebar-links-icon" />
                Payments
            </NavLink>
            
            {/* <NavLink to="/referrals" className="sidebar-links">
                <div className="sidebar-links-highlight"></div>
                <IconReferrals className="sidebar-links-icon" />
                Referrals
            </NavLink> */}
            

			{/* <NavLink to="/ambassador-program" className="sidebar-links">
				<div className="sidebar-links-highlight"></div>
				<IconAmbassadors className="sidebar-links-icon" />
				Ambassador Program
			</NavLink> */}

			{user.userType === "super-admin" && (
				<NavLink to="/admin" className="sidebar-links">
					<div className="sidebar-links-highlight"></div>
					<IconEmployees className="sidebar-links-icon" />
					Admin Management
				</NavLink>
			)}

			<NavLink to="/settings" className="sidebar-links">
				<div className="sidebar-links-highlight"></div>
				<IconSettings className="sidebar-links-icon" />
				Settings
			</NavLink>

			<NavLink to="/audit-trail" className="sidebar-links">
				<div className="sidebar-links-highlight"></div>
				<IconAuditTrail className="sidebar-links-icon" />
				Audit Trail
			</NavLink>
		</React.Fragment>
	);
};

export const BottomNav = () => {
	// const user = store.getState().auth.userPersist;
	// const user_role = user.data.userType;

	return (
		<React.Fragment>
			{/* 
            <div onClick={toggleChatWidget} className="sidebar-links relative">
                <div className="sidebar-links-highlight"></div>
                <IconSupport className="sidebar-links-icon" />
                Support
            </div>
            */}
		</React.Fragment>
	);
};
