import React, { useState, useRef } from 'react';
import { useSelector } from "react-redux";
import SearchFilter from 'components/common/filter';
import Download from 'components/common/download';
import CurrencyFormat from 'utils/currencyFormat';
import DateFormat from 'utils/dateFormat';
import LoanHistoryRepaymentSchedule from 'components/__new/credit/credit-transactions/LoanHistoryRepaymentSchedule';


const CustomersLoanHistory = (props) => {
    // const history = useHistory();
    // const dispatch = useDispatch();

        const [scheduleOpen, setScheduleOpen] = useState(true);

        const toggleSchedule = () => setScheduleOpen(!scheduleOpen);
        
    
    // eslint-disable-next-line
    const [transactionParams, setTransactionParams] = useState({
        page: 1,
        limit: 10,
    });
    
    const { downloadCustomerTransactionsLoading, downloadCustomerTransactionsData } = useSelector(s => s.entities.employees);

    const columns = [
        { label: "Date", key: "date" },
        { label: "Transaction ID", key: "transactionId" },
        { label: "Amount", key: "amount" },
        { label: "Current Balance", key: "currentBalance" },
        { label: "Transaction Type", key: "type" },
        { label: "Description", key: "description" },
        { label: "Status", key: "status" },
    ];
    const downloadRef = useRef();

    // const fetchTransactions = () => {
    //     const qs = queryString.parse(props.location.search);
    //     let params = transactionParams;
        
    //     if ((qs.page !== undefined) && (!isNaN(qs.page)) && (qs.page > 0)){
    //         params = {...params, page: qs.page};
    //     }
    //     else{
    //         params = {...params, page: 1};
    //     }
        
    //     if ((qs.limit !== undefined) && (!isNaN(qs.limit)) && (qs.limit > 0)){
    //         params = {...params, limit: qs.limit};
    //     }
    //     else{
    //         params = {...params, limit: 10};
    //     }
        
	// 	if (qs.keyword !== undefined && qs.keyword !== null) {
	// 		params = { ...params, keyword: qs.keyword };
	// 	} else {
	// 		params = { ...params, keyword: "" };
	// 	}
        
	// 	if (qs.dateFrom !== undefined && qs.dateFrom !== null) {
	// 		params = { ...params, dateFrom: qs.dateFrom };
	// 	} else {
	// 		params = { ...params, dateFrom: "" };
	// 	}
        
	// 	if (qs.dateTo !== undefined && qs.dateTo !== null) {
	// 		params = { ...params, dateTo: qs.dateTo };
	// 	} else {
	// 		params = { ...params, dateTo: "" };
	// 	}
        
	// 	if (qs.status !== undefined && qs.status !== null) {
	// 		params = { ...params, status: qs.status };
	// 	} else {
	// 		params = { ...params, status: "" };
	// 	}
        
	// 	if (qs.type !== undefined && qs.type !== null) {
	// 		params = { ...params, type: qs.type };
	// 	} else {
	// 		params = { ...params, type: "" };
	// 	}
        
    //     setTransactionParams(params);
        
    //     if(params?.type?.length || params?.status?.length ||
    //         params?.dateFrom?.length || params?.dateTo?.length || params?.keyword?.length) {
    //         dispatch(searchAndFilterCustomerTransactions({
    //             id: selectedCustomer?._id,
    //             payload: params
    //         }));
    //     } else {
    //         dispatch(getCustomerTransactions({
    //             id: selectedCustomer?._id,
    //             payload: params
    //         }));            
    //     }
        
    // }

    const transformDataDownload = (data) => {
        const newData = data?.transactions?.map((transaction) => {
            
			return {
				...transaction,
                date: DateFormat(transaction.createdAt),
				transactionId: transaction._id,
                amount: CurrencyFormat(transaction.amount / 100),
                currentBalance: CurrencyFormat(transaction.newBalance / 100),
                type: transaction?.type,
                description: transaction?.description,
                status: transaction?.status
			}
		})
		return newData;
	}
    
    
    return (
        <>
            <LoanHistoryRepaymentSchedule 
                control={scheduleOpen}
                onClose={toggleSchedule}
            />

            <div className='relative'>
                <div>
                    <div className="lg:flex">
                        
                        <div className="w-full lg:w-1/2">
                            <div className="page-title capitalize">
                                Gift Ikpefan
                                {/* {selectedCustomer?.firstName} {selectedCustomer?.lastName} */}
                            </div>
                        </div>
                        <div className="w-full lg:w-1/2 flex space-x-4 mt-2 lg:mt-0 lg:justify-end">
                            <div>
                                <Download
                                    tooltip="Download transactions"
                                    format="csv"
                                    filename="All Transactions.csv"
                                    columns={columns}
                                    ref={downloadRef}
                                    click={() => {
                                        // dispatch(downloadCustomerTransactions({
                                        //     id: selectedCustomer?._id,
                                        //     payload: {page: 1, limit: 2000}
                                        // })).then(() => {
                                        //     downloadRef.current.processDownload();
                                        // });
                                    }}
                                    loading={downloadCustomerTransactionsLoading}
                                    response={transformDataDownload(downloadCustomerTransactionsData?.data)}
                                />
                            </div>
                            <div>
                                <SearchFilter
                                    search={{
                                        name: "keyword",
                                        placeholder: "Keyword search",
                                    }}
                                    filters={[
                                        {
                                            title: "Transaction Type",
                                            name: "type",
                                            dataType: "checkbox",
                                            options: [
                                                {
                                                    display: "Credit",
                                                    payload: "credit",
                                                },
                                                {
                                                    display: "Debit",
                                                    payload: "debit",
                                                },
                                            ],
                                        },
                                        {
                                            title: "Status",
                                            name: "status",
                                            dataType: "checkbox",
                                            options: [
                                                {
                                                    display: "Successful",
                                                    payload: "successful",
                                                },
                                                {
                                                    display: "Failed",
                                                    payload: "failed",
                                                },
                                                {
                                                    display: "Pending",
                                                    payload: "pending",
                                                },
                                            ],
                                        },
                                        {
                                            title: "Date",
                                            dataType: "date-range",
                                            options: {
                                                from: {
                                                    name: "dateFrom",
                                                    placeholder: "Start Date",
                                                },
                                                to: {
                                                    name: "dateTo",

                                                    placeholder: "End Date",
                                                },
                                            },
                                        },
                                    ]}
                                />
                            </div>
                        </div>
                    </div>
                    
                    {/* <div className="my-10">
                            <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-6">
                                <DashboardBoxes
                                    data={transactionProps.totalValue}
                                    loading={customerTransactionsMetricsLoading}
                                    result={transMetrics?.data?.metrics?.totalTransactionValue ?
                                        CurrencyFormat(transMetrics?.data?.metrics?.totalTransactionValue / 100) : "0"
                                    }
                                />
                                <DashboardBoxes
                                    data={transactionProps.totalVolume}
                                    loading={customerTransactionsMetricsLoading}
                                    result={transMetrics?.data?.metrics?.totalTransactionVolume ?
                                        NumberFormat(transMetrics?.data?.metrics?.totalTransactionVolume) : "0"
                                    }
                                />
                                <DashboardBoxes
                                    data={transactionProps.totalCredit}
                                    loading={customerTransactionsMetricsLoading}
                                    result={transMetrics?.data?.metrics?.totalCredit ?
                                        CurrencyFormat(transMetrics?.data?.metrics?.totalCredit / 100) : "0"
                                    }
                                />
                                <DashboardBoxes
                                    data={transactionProps.totalDebit}
                                    loading={customerTransactionsMetricsLoading}
                                    result={transMetrics?.data?.metrics?.totalDebit ?
                                        CurrencyFormat(transMetrics?.data?.metrics?.totalDebit / 100) : "0"
                                    }
                                />
                            </div>
                    </div> */}

                    <div className="mt-12">
                        
                    <div className="mt-12 mb-6 font-medium md:text-lg">
                        Loan History
                    </div>
                    
                    <div className="table-container">
                        <table className="table table-auto table-rounded table-border">
                            <thead>
                                <tr>
                                    <th>Request Date</th>
                                    <th>Loan Amount</th>
                                    <th>Tenor</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                
                                {/* {(customerTransactionsLoading === true) && 
                                    <tr>
                                        <td colSpan="10">
                                            <div className="table-info">
                                                <FontAwesomeIcon icon="spinner" spin />
                                                <div className="font-bold uppercase">
                                                    Loading
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                }

                                {((customerTransactionsLoading === false) && (!refinedData.docs)) && 
                                    <tr>
                                        <td colSpan="10">
                                            <div className="table-info">
                                                <FontAwesomeIcon icon="list" />
                                                <div className="font-bold uppercase">
                                                    No transactions found
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                }
                                
                                {((customerTransactionsLoading === false) && (refinedData.docs) && (refinedData.docs.length <= 0)) && 
                                    <tr>
                                        <td colSpan="10">

                                            {(refinedData.page <= refinedData.totalPages || refinedData?.docs?.length < 1) &&
                                                <div className="table-info">
                                                    <FontAwesomeIcon icon="list" />
                                                    <div className="font-bold uppercase">
                                                        No results found
                                                    </div>
                                                </div>
                                            }
                                            
                                            {(refinedData.page > refinedData.totalPages && refinedData?.docs?.length > 0) &&
                                                <div className="table-info">
                                                    <FontAwesomeIcon icon="exclamation-triangle" />
                                                    <div className="font-bold uppercase">
                                                        Invalid Page Number
                                                    </div>
                                                    <div className="mt-2">
                                                        <Link to="?page=1" className="btn btn-ep-blue btn-sm mx-auto">Goto Page 1</Link>
                                                    </div>
                                                </div>
                                            }

                                        </td>
                                    </tr>
                                } */}
                                
                                {/* {((customerTransactionsLoading === false) && (refinedData?.docs?.length > 0)) && 
                                    refinedData?.docs?.map((transaction, index) => {
                                        
                                        const isCredit = transaction.type === 'credit'
                                        
                                        return (
                                            <tr key={index}>
                                            <td>
                                                {DateFormat(transaction.createdAt)}
                                            </td>
                                            <td>{transaction._id}</td>
                                            <td>
                                                <div className="whitespace-nowrap">
                                                    {isCredit ? "+" : "-"}{CurrencyFormat(transaction.amount / 100)}
                                                </div>
                                            </td>
                                            <td>
                                                <div className={`capitalize flex justify-center px-3 py-1 rounded-full whitespace-nowrap text-xs w-[72px] ${isCredit ? "bg-[#E5FDFF]" : "bg-[#FEE2E2]"}`}>
                                                    {transaction?.type}
                                                </div>
                                            </td>
                                            <td>
                                                <div className="whitespace-nowrap">
                                                    {CurrencyFormat(transaction.newBalance / 100)}
                                                </div>
                                            </td>
                                            <td>{transaction.description}</td>
                                            <td><StatusBadge status={transaction.status} /></td>
                                        </tr>
                                        )
                                        }
                                    )
                                } */}

                                {
                                    [...Array(4)]?.map((item, index) => {
                                        return (
                                            <tr>
                                                <td>Mar 30, 2022</td>
                                                <td>{CurrencyFormat("40000")}</td>
                                                <td>6 Months</td>
                                                <td><StatusBadge status="successful" /></td>
                                                <td>
                                                    <div onClick={toggleSchedule} className="text-ep-primary-600 font-semibold border-b w-max border-ep-primary-600 cursor-pointer">
                                                        View repayment schedule
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>

                    {/* {((customerTransactionsLoading === false) && (refinedData.docs) && (refinedData.docs.length > 0)) && 
                        <div>
                            <Pagination data={refinedData} url={props.location.search} limit={transactionParams.limit} />
                        </div>
                    } */}
                    </div>
                </div>
            </div>
        </>
    )
}

const StatusBadge = ({status}) => {
    const isProcessing = status === "pending";
    const isSuccessful = status === "successful";
    
    return (
        <div className={`capitalize flex justify-center px-3 py-1 rounded-full whitespace-nowrap text-xs w-[72px] ${isSuccessful ? "bg-[#E5FDFF]" : isProcessing ? "bg-[#FFFCDA]" : "bg-[#FEE2E2]"}`}>
            {status}
        </div>
    )
}

export default CustomersLoanHistory;