import React, { Component } from 'react';
import { connect } from "react-redux";
import { Menu } from "@headlessui/react";
import { nameInitials } from 'utils/nameInitials.jsx';
import { Link, NavLink, withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {TopNav, BottomNav} from 'components/common/sidebarLinks.jsx';
import { ReactComponent as IconLogout } from "assets/images/icons/project-icons/Logout.svg";
import logo from "assets/images/icons/logo@svg.svg";
import notificationDot from "assets/images/icons/project-icons/Notification-dot.svg";
import * as auth from "store/auth/user/action";
import Maybe from 'components/__new/common/Maybe';

class Header extends Component {

    state = {
        showMobileNav: false,
    }


    goBack = () => {
        if (this.props.history){
            this.props.history.goBack();
        }
    }
    

    toggleHeaderMobileNav = (e, action='') => {
        let toggle = action;
        if (action === ''){
            toggle = !this.state.showMobileNav;
        }
        this.setState({showMobileNav: toggle})
    }


    render() {
        const {showMobileNav} = this.state;
        
        const isBusinessTransactionsPath = window?.location?.pathname?.includes("/businesses/transactions");
        const isCustomersTransactionsPath = window?.location?.pathname?.includes("/users/customers/transactions");
        const isEmployeeDetailsPath = window?.location?.pathname?.includes("/users/employees/details");
        
        const visitPreviousPage = () => {
            this.props.history.goBack();
        }
        
        return ( 
            <div className="border-b border-gray-200">


                <div className="h-16 px-6 bg-white hidden lg:flex">
                    <div className="w-full px-0 sm:px-4 flex items-center justify-between">
                        
                            <div className="flex-grow">
                                <Maybe condition={isBusinessTransactionsPath || isCustomersTransactionsPath || isEmployeeDetailsPath}>
                                        <button onClick={visitPreviousPage} className="btn btn-md bg-gray-200 text-gray-500">
                                            <FontAwesomeIcon icon="angle-left" className="mr-2" />
                                            Back
                                        </button>
                                </Maybe>
                            </div>

                        <div className="flex-shrink-0 flex space-x-5 items-center justify-end">
                            <div className="hidden w-10 h-10 my-auto cursor-pointer rounded hover:bg-gray-200">
                                <img src={notificationDot} alt="Notifications" className="w-6 h-6 m-auto object-contain" />
                            </div>
                            <div className="hidden w-px h-10 my-auto bg-gray-200">&nbsp;</div>
                            <Menu as="div" className="relative">
                                <Menu.Button as="div" className="w-10 h-10 relative flex bg-blue-fade border-2 border-transparent cursor-pointer hover:border-ep-blue rounded overflow-hidden">
                                    <span className="m-auto text-ep-blue text-lg font-bold">
                                        {nameInitials(this.props.user.userName)}
                                    </span>
                                </Menu.Button>
                                <Menu.Items as="div" className="dropdown dropdown-right">
                                    <Menu.Item as="div">
                                        <div className="">
                                            <NavLink to="/account/profile" className="dropdown-item whitespace-nowrap">Manage Account</NavLink>
                                            <div onClick={this.props.logout} className="dropdown-item">Logout</div>
                                        </div>
                                    </Menu.Item>
                                </Menu.Items>
                            </Menu>
                        </div>
                        
                    </div>
                </div>


                <div className="w-full bg-ep-blue sidebar-bg-ep-pattern lg:hidden">
                    <div className="h-14 px-6 flex items-center justify-between">
                        <Link to="/dashboard" className="h-7 animate flex">
                            <img src={logo} alt="EarniPay logo" className="h-full my-auto object-contain" />
                        </Link>

                        <div className="flex space-x-2">
                            <div onClick={this.toggleHeaderMobileNav} className="w-10 h-10 text-white group hover:bg-white flex rounded cursor-pointer">
                                <FontAwesomeIcon icon="bars" className="text-xl m-auto group-hover:text-black" />
                            </div>
                        </div>
                    </div>
                    {showMobileNav &&
                        <div onClick={this.toggleHeaderMobileNav} className="flex flex-col pt-4 pb-4">
                            <TopNav />
                            <div className="w-full h-px my-4 bg-white opacity-25"></div>
                            <NavLink to="/account/profile" className="sidebar-links">
                                <div className="sidebar-links-highlight"></div>
                                <FontAwesomeIcon icon="user" className="sidebar-links-icon" />
                                Manage Account
                            </NavLink>
                            <BottomNav  />
                            <div onClick={this.props.logout} className="sidebar-links">
                                <div className="sidebar-links-highlight"></div>
                                <IconLogout className="customizeSVG sidebar-links-icon" />
                                Logout
                            </div>
                        </div>
                    }
                </div>


            </div>            
        );
    }
}
 

const mapStateToProps = (state) => ({
    loggedIn: state.auth.userPersist.loggedIn,
    user: state.auth.userPersist.data
});

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(auth.logoutUser())
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));