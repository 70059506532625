import { ButtonLoader } from "../common/form";
import FormDrawer from "../common/form-drawer--new";

const ResetBvnModal = ({isOpen, setIsOpen, onProceed, loading, user}) => {
	
	return (
		<FormDrawer display="center" size="sm" isOpen={isOpen} setIsOpen={() => {}}>
			<div className="p-5 max-w-sm">
				<h1 className="lg:text-lg font-semibold">Reset BVN</h1>
				
				<div className="form-group mt-6 mb-10">
					<label>
						Are you sure you want to reset BVN for <span className="font-semibold capitalize"> {user?.firstName} {user?.lastName}?</span>
					</label>
				</div>
				
				<div className="flex items-center justify-center lg:flex-row">
					
					<button
						onClick={() => setIsOpen(false)}
						className="btn btn-md border border-ep-primary-500 text-ep-primary-500 focus:ring-0 w-full lg:w-2/4 mr-4"
						disabled={loading}>
						Cancel
					</button>
					
					<ButtonLoader
						loading={loading}
						onClick={onProceed}
						className="z-5 btn btn-md border bg-ep-primary text-white focus:ring-0 w-full lg:w-2/4">
						{loading ? "Resetting..." : "Yes"}
					</ButtonLoader>
				</div>
			</div>
		</FormDrawer>
	)
}

export default ResetBvnModal