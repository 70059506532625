
const Maybe = (props) => {
    return (
        <>
            {(props.condition) && (props.condition === true) &&
                props.children
            }
        </>
    )
}

export default Maybe
