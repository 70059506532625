import { useEffect, useState } from "react";
import { ButtonLoader, Input, SelectInput } from "components/__new/common/form";
import FormDrawer from "components/__new/common/form-drawer--new";
import { toast } from "react-toastify";


const NewTermLoanFee = ({user, refetch, isOpen, setIsOpen, loading, termLoanFees, setTermLoanFees}) => {

	const [form, setForm] = useState({
		feeName: "",
		feeType: "",
		value: ""
	});

	const btnDisabled = !form.feeName || !form.feeType || !form.value;
	
    const submitForm = (e) => {
        e.preventDefault();
        
        if(form.feeType === "percentage" && form.value > 100) {
            toast.error("Percentage values cannot exceed 100");
        } else if(form.feeType === "fixed" && form.value < 1) {
            toast.error("Fixed amount cannot be less than 1");
        } else {
			//  replace this with API call to save new fee to backend
			setTermLoanFees([
				...termLoanFees,
				{
					...form, 
					isCustom: true,
					id: termLoanFees?.length + 1, 
				}
			])
			setIsOpen(false);
        }
    }

	useEffect(() => {
		setForm({
			feeName: "",
			feeType: "",
			value: ""
		})
	}, [])

	
	return (
		<FormDrawer display="center" size="md" isOpen={isOpen} setIsOpen={() => {}}>
			<div className="p-6 max-w-md">
				<h1 className="lg:text-lg font-semibold">Add New Fee</h1>

			<form onSubmit={submitForm}>
				<div className="mt-8 space-y-7">
					<div className="form-group">
						<Input
							type="text"
							name="feeName"
							label="Fee Name"
							value={form.feeName || ""}
							placeholder="Enter a fee name"
							onChange={(e) => setForm({...form, feeName: e.target.value})}
						/>
					</div>

					<div className="form-group">
						<SelectInput label="Fee Type" value={form.feeType} onChange={(e) => setForm({...form, feeType: e.target.value})}>
							<option value="" disabled>Choose a fee type</option>
							<option value="percentage">Percentage</option>
							<option value="fixed">Fixed Amount</option>
						</SelectInput>
					</div>

					<div className="form-group w-full">
						<label>Value</label>
						<input
							id="number-input"
							type="number"
							name="value"
							label="Value"
							value={form.value || ""}
							placeholder="Enter a value"
							className="form-input"
							onChange={(e) => setForm({...form, value: e.target.value})}
						/>
					</div>
				</div>
				
				<div className="flex items-center justify-center lg:flex-row pb-2 pt-7">
					
					<button
						type="button"
						onClick={() => setIsOpen(false)}
						className="btn btn-md border border-ep-primary-500 text-ep-primary-500 focus:ring-0 w-full lg:w-2/4 mr-4">
						Cancel
					</button>
					
					<ButtonLoader
						type="submit"
						loading={loading}
						disabled={btnDisabled}
						className="z-5 btn btn-md border bg-ep-primary text-white focus:ring-0 w-full lg:w-2/4">
						{loading ? "Creating..." : "Add"}
					</ButtonLoader>
				</div>
			</form>

			</div>
		</FormDrawer>
	)
}

export default NewTermLoanFee