export const GET_ALL_REFERRALS = "GET_ALL_REFERRALS";
export const GET_ALL_REFERRALS_START = "GET_ALL_REFERRALS_START";
export const GET_ALL_REFERRALS_FAILED = "GET_ALL_REFERRALS_FAILED";

export const DOWNLOAD_GET_ALL_REFERRALS = "DOWNLOAD_GET_ALL_REFERRALS";
export const DOWNLOAD_GET_ALL_REFERRALS_START = "DOWNLOAD_GET_ALL_REFERRALS_START";
export const DOWNLOAD_GET_ALL_REFERRALS_FAILED = "DOWNLOAD_GET_ALL_REFERRALS_FAILED";

export const GET_UNFILTERED_REFERRALS = "GET_UNFILTERED_REFERRALS";
export const GET_UNFILTERED_REFERRALS_START = "GET_UNFILTERED_REFERRALS_START";
export const GET_UNFILTERED_REFERRALS_FAILED = "GET_UNFILTERED_REFERRALS_FAILED";

export const GET_COMPLETED_REFERRALS = "GET_COMPLETED_REFERRALS";
export const GET_COMPLETED_REFERRALS_START = "GET_COMPLETED_REFERRALS_START";
export const GET_COMPLETED_REFERRALS_FAILED = "GET_COMPLETED_REFERRALS_FAILED";

export const GET_PENDING_REFERRALS = "GET_PENDING_REFERRALS";
export const GET_PENDING_REFERRALS_START = "GET_PENDING_REFERRALS_START";
export const GET_PENDING_REFERRALS_FAILED = "GET_PENDING_REFERRALS_FAILED";

export const REFERRALS_RESEND_INVITE = "REFERRALS_RESEND_INVITE";
export const REFERRALS_RESEND_INVITE_START = "REFERRALS_RESEND_INVITE_START";
export const REFERRALS_RESEND_INVITE_FAILED = "REFERRALS_RESEND_INVITE_FAILED";
