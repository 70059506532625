import React, { useEffect, useState } from 'react'
import DateFormat from 'utils/dateFormat';
import DropdownNew from 'utils/dropdown-new';
import Pagination from 'components/common/pagination';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import CurrencyFormat from 'utils/currencyFormat';
import useUrlQueryParams from 'hooks/useUrlQueryParams';

const CreditTransactionsTable = ({toggleDetails, refinedData, fetchCreditTransactions, creditParams, toggleSchedule}) => {

    const loading = false;
    const location = useLocation();

    // const [creditParams, setCreditParams] = useState({
    //     // page: 1,
    //     // limit: 10
    // }); 
    
    return (
        <div className="mt-2">
            <div className="table-container">
                <table className="table table-auto table-rounded table-border">
                    <thead>
                        <tr>
                            <th>Disbursement Date</th>
                            <th>User's Name</th>
                            <th>Loan Amount</th>
                            <th>Loan Tenor</th>
                            <th>Status</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        
                        {/* {(loading === true || toggleCustomerStatusLoading === true) && 
                            <tr>
                                <td colSpan="11">
                                    <div className="table-info">
                                        <FontAwesomeIcon icon="spinner" spin />
                                        <div className="font-bold uppercase">
                                            Loading
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        } */}

                        {/* {((loading === false) && (!refinedData.docs)) && 
                            <tr>
                                <td colSpan="11">
                                    <div className="table-info">
                                        <FontAwesomeIcon icon="unlink" />
                                        <div className="font-bold uppercase">
                                            An error occurred
                                            <br />
                                            Please try again later.
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        } */}
                        
                        {/* {((loading === false) && (refinedData.docs) && (refinedData.docs.length <= 0)) && 
                            <tr>
                                <td colSpan="11">

                                    {(refinedData.page <= refinedData.totalPages || refinedData?.docs?.length < 1) &&
                                        <div className="table-info">
                                            <FontAwesomeIcon icon="list" />
                                            <div className="font-bold uppercase">
                                                No results found
                                            </div>
                                        </div>
                                    }
                                    
                                    {(refinedData.page > refinedData.totalPages && refinedData?.docs?.length > 0) &&
                                        <div className="table-info">
                                            <FontAwesomeIcon icon="exclamation-triangle" />
                                            <div className="font-bold uppercase">
                                                Invalid Page Number
                                            </div>
                                            <div className="mt-2">
                                                <Link to="?page=1" className="btn btn-ep-blue btn-sm mx-auto">Goto Page 1</Link>
                                            </div>
                                        </div>
                                    }

                                </td>
                            </tr>
                        } */}
                        
                        {/* {((loading === false && toggleCustomerStatusLoading === false) && (refinedData.docs) && (refinedData.docs.length > 0)) && 
                            refinedData.docs.map((user, index) => {

                                return (
                                    <tr key={index}>
                                        <td>
                                            <div>
                                                {DateFormat(user?.createdAt?.split("T")[0])}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='whitespace-nowrap'>
                                                {DateFormat(user?.updatedAt?.split("T")[0])}
                                            </div>
                                        </td>
                                        <td>
                                            <div className="flex items-center">
                                                <div className="capitalize">
                                                    {user?.firstName} {user?.lastName}
                                                </div>
                                                <Maybe condition={user?.employeeId && user?.employeeId !== ""}>
                                                    <div className="ml-2 label label-gray label-sm">
                                                        Employee
                                                    </div>
                                                </Maybe>
                                            </div>
                                        </td>
                                        <td>{user?.email || "-"}</td>
                                        <td>
                                            <div className='flex items-center'>
                                                {user?._id?.substring(0, 8) || "-"}
                                                <span onClick={() => copyItem(user?._id, "User ID")} className="ml-1 cursor-pointer">
                                                    <IconCopy />
                                                </span>
                                            </div>
                                        </td>
                                        <td>{user?.phoneNumber || "-"}</td>
                                        <td>{NumberFormat(user?.points || "0")}</td>
                                        <td>
                                            <EligibilityBadge user={user} />
                                        </td>
                                        <td>
                                            <UserTierBadge 
                                                status={user?.kycStatus} 
                                                accountTier={user?.accountTier} 
                                            />
                                        </td>
                                        <td>
                                            <div className="flex items-center justify-center">
                                                {user?.kycLevel}/3
                                            </div>
                                        </td>
                                        <td>
                                            <DropdownNew>
                                                <></>
                                                <div>
                                                    <div onClick={() => {dispatch(selectCustomer(user)); dispatch(getSingleCustomer(user)); toggleDetails();}} className="dropdown-item dropdown-item-icon">
                                                        View Details
                                                    </div>
                                                    <div onClick={() => viewUserTransactions(user)} className="dropdown-item dropdown-item-icon">
                                                        View Transactions
                                                    </div>
                                                    <div onClick={() => generateEva(user)} className="dropdown-item dropdown-item-icon">
                                                        Generate EVA
                                                    </div>
                                                    <Maybe condition={user?.disabled === true && user?.isActive === false}>
                                                        <div onClick={() => {dispatch(selectCustomer(user)); toggleActivateModal();}} className="dropdown-item dropdown-item-icon">
                                                            Activate Customer
                                                        </div>
                                                    </Maybe>
                                                    <Maybe condition={user?.isActive === true && user?.disabled === false}>
                                                        <div onClick={() => {dispatch(selectCustomer(user)); toggleDeactivateModal();}} className="dropdown-item dropdown-item-icon text-ep-danger">
                                                            Deactivate Customer
                                                        </div>
                                                    </Maybe>
                                                </div>
                                            </DropdownNew>
                                        </td>
                                    </tr>
                                )
                                }
                            )
                        } */}
                        
                            {refinedData?.data?.map((user, index) => {
                                return (
                                    <tr key={index}>
                                        <td>
                                            <div>
                                                {DateFormat(user?.createdAt?.split("T")[0])}
                                            </div>
                                        </td>
                                        <td>
                                            <div className="flex items-center capitalize">
                                                {user?.accountNumber}
                                            </div>
                                        </td>
                                        <td>
                                            {CurrencyFormat(user?.amount)}
                                        </td>
                                        <td>
                                            {user?.tenor}
                                        </td>
                                        <td>
                                            <LoanStatusBadge status={user?.status} />
                                        </td>
                                        <td>
                                            <DropdownNew>
                                                <></>
                                                <div>

                                                    <div onClick={() => toggleDetails()} className="dropdown-item dropdown-item-icon">
                                                        View User Details
                                                    </div>

                                                    <div onClick={() => toggleSchedule(user)} className="dropdown-item dropdown-item-icon">
                                                        View Repayment Schedule
                                                    </div>
                                                    
                                                </div>
                                            </DropdownNew>
                                        </td>
                                    </tr>
                                )
                                }
                            )
                        }
                    </tbody>
                </table>
            </div>

            {((loading === false) && (refinedData?.data) && (refinedData?.data.length > 0)) && 
                <div>
                    <Pagination data={refinedData} url={location.search} limit={creditParams.limit} />
                </div>
            }
    </div>
    )
}


const LoanStatusBadge = ({status}) => {
    const isCompleted = status === "completed";
    const isRunning = status === "running";
    
    return (
        <div className={`capitalize flex justify-center px-3 py-1 rounded-full whitespace-nowrap text-xs w-[72px] ${isCompleted ? "bg-[#E5FDFF] text-ep-primary-60" : isRunning ? "bg-[#FFFCDA] text-[#1A202C]" : "bg-[#FEE2E2] text-[#991B1B]"}`}>
            {status}
        </div>
    )
}


export default CreditTransactionsTable