import * as actions from "./actionTypes";
import * as config from "config";
import ObjectToQueryString from 'utils/ObjectToQueryString';

export function getAuditTrail(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetAuditTrail + ObjectToQueryString(params),
            method: "get",
            data: {},
            onStart: actions.GET_AUDIT_TRAIL_START,
            onSuccess: actions.GET_AUDIT_TRAIL,
            onError: actions.GET_AUDIT_TRAIL_FAILED,
            // useLoginJwt: false,
        },
    }
}

export function downloadGetAuditTrail(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetAuditTrail + ObjectToQueryString(params),
            method: "get",
            data: {},
            onStart: actions.DOWNLOAD_GET_AUDIT_TRAIL_START,
            onSuccess: actions.DOWNLOAD_GET_AUDIT_TRAIL,
            onError: actions.DOWNLOAD_GET_AUDIT_TRAIL_FAILED,
        },
    }
}

export function getAuditActions() {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetAuditTrailActions,
            method: "get",
            data: {},
            onStart: actions.GET_AUDIT_TRAIL_ACTIONS_START,
            onSuccess: actions.GET_AUDIT_TRAIL_ACTIONS,
            onError: actions.GET_AUDIT_TRAIL_ACTIONS_FAILED,
            // useLoginJwt: false,
        },
    }
}
