import * as actions from "./actionTypes";
import * as config from "config";
import ObjectToQueryString from 'utils/ObjectToQueryString';

export function getAllAdmins(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetAdmins,
            method: "post",
            data: params,
            onStart: actions.GET_ALL_ADMINS_START,
            onSuccess: actions.GET_ALL_ADMINS,
            onError: actions.GET_ALL_ADMINS_FAILED,
            // useLoginJwt: false,
        },
    }
}

export function getAdmin(id) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetAdmin + id,
            method: "get",
            data: {},
            onStart: actions.GET_ADMIN_START,
            onSuccess: actions.GET_ADMIN,
            onError: actions.GET_ADMIN_FAILED,
        },
    }
}

export function createAdmin(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiCreateAdmin,
            method: "post",
            data: params,
            onStart: actions.CREATE_ADMIN_START,
            onSuccess: actions.CREATE_ADMIN,
            onError: actions.CREATE_ADMIN_FAILED,
        },
    }
}

export function resetAdminCreateResponse() {
    return {
        type: actions.CREATE_ADMIN_RESET,
        payload: {},
    }
}

export function resetReloadPage() {
    return {
        type: actions.RELOAD_PAGE_RESET,
        payload: {},
    }
}

export function editAdmin(id, params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiUpdateAdmin,
            method: "post",
            data: params,
            onStart: actions.UPDATE_ADMIN_START,
            onSuccess: actions.UPDATE_ADMIN,
            onError: actions.UPDATE_ADMIN_FAILED,
        },
    }
}

export function resetAdminEditSuccess() {
    return {
        type: actions.UPDATE_ADMIN_RESET,
        payload: {},
    }
}

export function updateAdminStatus(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiUpdateAdminStatus + ObjectToQueryString(params),
            method: "post",
            data: {},
            onStart: actions.UPDATE_ADMIN_STATUS_START,
            onSuccess: actions.UPDATE_ADMIN_STATUS,
            onError: actions.UPDATE_ADMIN_STATUS_FAILED,
        },
    }
}

export function deleteAdmin(id) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiDeleteAdmin + id,
            method: "delete",
            data: {},
            onStart: actions.DELETE_ADMIN_START,
            onSuccess: actions.DELETE_ADMIN,
            onError: actions.DELETE_ADMIN_FAILED,
        },
    }
}

export function resendInvite(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiAdminResendInvite + ObjectToQueryString(params),
            method: "post",
            data: {},
            onStart: actions.RESEND_INVITE_START,
            onSuccess: actions.RESEND_INVITE,
            onError: actions.RESEND_INVITE_FAILED,
        },
    }
}

export function adminOnboard(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiAdminOnboard,
            method: "post",
            data: params,
            onStart: actions.ADMIN_ONBOARD_START,
            onSuccess: actions.ADMIN_ONBOARD,
            onError: actions.ADMIN_ONBOARD_FAILED
        }
    }
}

export function resetAdminOnboard() {
    return {
        type: actions.ADMIN_ONBOARD_RESET,
        payload: {},
    }
}
