export const USER_DETAILS_MANUAL_UPDATE_START = "USER_DETAILS_MANUAL_UPDATE_START";
export const USER_DETAILS_MANUAL_UPDATE_DONE = "USER_DETAILS_MANUAL_UPDATE_DONE";
export const USER_DETAILS_MANUAL_UPDATE_FAILED = "USER_DETAILS_MANUAL_UPDATE_FAILED";

export const DOWNLOAD_CUSTOMER_TRANSACTIONS_START = "DOWNLOAD_CUSTOMER_TRANSACTIONS_START";
export const DOWNLOAD_CUSTOMER_TRANSACTIONS_DONE = "DOWNLOAD_CUSTOMER_TRANSACTIONS_DONE";
export const DOWNLOAD_CUSTOMER_TRANSACTIONS_FAILED = "DOWNLOAD_CUSTOMER_TRANSACTIONS_FAILED";

export const GET_CUSTOMER_TRANSACTIONS_METRICS_START = "GET_CUSTOMER_TRANSACTIONS_METRICS_START";
export const GET_CUSTOMER_TRANSACTIONS_METRICS_DONE = "GET_CUSTOMER_TRANSACTIONS_METRICS_DONE";
export const GET_CUSTOMER_TRANSACTIONS_METRICS_FAILED = "GET_CUSTOMER_TRANSACTIONS_METRICS_FAILED";

export const GET_CUSTOMER_TRANSACTIONS_START = "GET_CUSTOMER_TRANSACTIONS_START";
export const GET_CUSTOMER_TRANSACTIONS_DONE = "GET_CUSTOMER_TRANSACTIONS_DONE";
export const GET_CUSTOMER_TRANSACTIONS_FAILED = "GET_CUSTOMER_TRANSACTIONS_FAILED";

export const TOGGLE_CUSTOMER_KYC_START = "TOGGLE_CUSTOMER_KYC_START";
export const TOGGLE_CUSTOMER_KYC_DONE = "TOGGLE_CUSTOMER_KYC_DONE";
export const TOGGLE_CUSTOMER_KYC_FAILED = "TOGGLE_CUSTOMER_KYC_FAILED";

export const RESET_ADDRESS_REJECTION_STATUS = "RESET_ADDRESS_REJECTION_STATUS";

export const DOWNLOAD_ALL_CUSTOMERS_START = "DOWNLOAD_ALL_CUSTOMERS_START";
export const DOWNLOAD_ALL_CUSTOMERS_DONE = "DOWNLOAD_ALL_CUSTOMERS_DONE";
export const DOWNLOAD_ALL_CUSTOMERS_FAILED = "DOWNLOAD_ALL_CUSTOMERS_FAILED";

export const GET_ALL_CUSTOMERS_START = "GET_ALL_CUSTOMERS_START";
export const GET_ALL_CUSTOMERS_DONE = "GET_ALL_CUSTOMERS_DONE";
export const GET_ALL_CUSTOMERS_FAILED = "GET_ALL_CUSTOMERS_FAILED";

export const SELECT_CUSTOMER = "SELECT_CUSTOMER";

export const GET_SINGLE_CUSTOMER_START = "GET_SINGLE_CUSTOMER_START";
export const GET_SINGLE_CUSTOMER_DONE = "GET_SINGLE_CUSTOMER_DONE";
export const GET_SINGLE_CUSTOMER_FAILED = "GET_SINGLE_CUSTOMER_FAILED";

export const TOGGLE_CUSTOMER_STATUS_START = "TOGGLE_CUSTOMER_STATUS_START";
export const TOGGLE_CUSTOMER_STATUS_DONE = "TOGGLE_CUSTOMER_STATUS_DONE";
export const TOGGLE_CUSTOMER_STATUS_FAILED = "TOGGLE_CUSTOMER_STATUS_FAILED";

export const GET_ALL_EMPLOYEES = "GET_ALL_EMPLOYEES";
export const GET_ALL_EMPLOYEES_START = "GET_ALL_EMPLOYEES_START";
export const GET_ALL_EMPLOYEES_FAILED = "GET_ALL_EMPLOYEES_FAILED";

export const GET_ALL_ACCEPTED_EMPLOYEES = "GET_ALL_ACCEPTED_EMPLOYEES";
export const GET_ALL_ACCEPTED_EMPLOYEES_START = "GET_ALL_ACCEPTED_EMPLOYEES_START";
export const GET_ALL_ACCEPTED_EMPLOYEES_FAILED = "GET_ALL_ACCEPTED_EMPLOYEES_FAILED";

export const EMPLOYEE_DETAILS = "EMPLOYEE_DETAILS";
export const EMPLOYEE_DETAILS_START = "EMPLOYEE_DETAILS_START";
export const EMPLOYEE_DETAILS_FAILED = "EMPLOYEE_DETAILS_FAILED";

export const DOWNLOAD_GET_ALL_EMPLOYEES = "DOWNLOAD_GET_ALL_EMPLOYEES";
export const DOWNLOAD_GET_ALL_EMPLOYEES_START = "DOWNLOAD_GET_ALL_EMPLOYEES_START";
export const DOWNLOAD_GET_ALL_EMPLOYEES_FAILED = "DOWNLOAD_GET_ALL_EMPLOYEES_FAILED";

export const GET_ALL_EMPLOYEES_COUNT = "GET_ALL_EMPLOYEES_COUNT";
export const GET_ALL_EMPLOYEES_COUNT_START = "GET_ALL_EMPLOYEES_COUNT_START";
export const GET_ALL_EMPLOYEES_COUNT_FAILED = "GET_ALL_EMPLOYEES_COUNT_FAILED";

export const GET_ENROLLED_EMPLOYEES_COUNT = "GET_ENROLLED_EMPLOYEES_COUNT";
export const GET_ENROLLED_EMPLOYEES_COUNT_START = "GET_ENROLLED_EMPLOYEES_COUNT_START";
export const GET_ENROLLED_EMPLOYEES_COUNT_FAILED = "GET_ENROLLED_EMPLOYEES_COUNT_FAILED";

export const GET_UNENROLLED_EMPLOYEES_COUNT = "GET_UNENROLLED_EMPLOYEES_COUNT";
export const GET_UNENROLLED_EMPLOYEES_COUNT_START = "GET_UNENROLLED_EMPLOYEES_COUNT_START";
export const GET_UNENROLLED_EMPLOYEES_COUNT_FAILED = "GET_UNENROLLED_EMPLOYEES_COUNT_FAILED";

export const GET_EMPLOYEES_WITHDRAW_ONCE_OR_MORE = "GET_EMPLOYEES_WITHDRAW_ONCE_OR_MORE";
export const GET_EMPLOYEES_WITHDRAW_ONCE_OR_MORE_START = "GET_EMPLOYEES_WITHDRAW_ONCE_OR_MORE_START";
export const GET_EMPLOYEES_WITHDRAW_ONCE_OR_MORE_FAILED = "GET_EMPLOYEES_WITHDRAW_ONCE_OR_MORE_FAILED";

export const DOWNLOAD_GET_EMPLOYEES_WITHDRAW_ONCE_OR_MORE = "DOWNLOAD_GET_EMPLOYEES_WITHDRAW_ONCE_OR_MORE";
export const DOWNLOAD_GET_EMPLOYEES_WITHDRAW_ONCE_OR_MORE_START = "DOWNLOAD_GET_EMPLOYEES_WITHDRAW_ONCE_OR_MORE_START";
export const DOWNLOAD_GET_EMPLOYEES_WITHDRAW_ONCE_OR_MORE_FAILED = "DOWNLOAD_GET_EMPLOYEES_WITHDRAW_ONCE_OR_MORE_FAILED";

export const ENABLE_EMPLOYEE = "ENABLE_EMPLOYEE";
export const ENABLE_EMPLOYEE_START = "ENABLE_EMPLOYEE_START";
export const ENABLE_EMPLOYEE_FAILED = "ENABLE_EMPLOYEE_FAILED";

export const DISABLE_EMPLOYEE = "DISABLE_EMPLOYEE";
export const DISABLE_EMPLOYEE_START = "DISABLE_EMPLOYEE_START";
export const DISABLE_EMPLOYEE_FAILED = "DISABLE_EMPLOYEE_FAILED";

export const ARCHIVE_EMPLOYEE = "ARCHIVE_EMPLOYEE";
export const ARCHIVE_EMPLOYEE_START = "ARCHIVE_EMPLOYEE_START";
export const ARCHIVE_EMPLOYEE_FAILED = "ARCHIVE_EMPLOYEE_FAILED";

export const RESEND_INVITE = "RESEND_INVITE";
export const RESEND_INVITE_START = "RESEND_INVITE_START";
export const RESEND_INVITE_FAILED = "RESEND_INVITE_FAILED";

export const GENERATE_ERA = "GENERATE_ERA";
export const GENERATE_ERA_START = "GENERATE_ERA_START";
export const GENERATE_ERA_FAILED = "GENERATE_ERA_FAILED";

export const WITHDRAWAL_CHARGE = "WITHDRAWAL_CHARGE";
export const WITHDRAWAL_CHARGE_START = "WITHDRAWAL_CHARGE_START";
export const WITHDRAWAL_CHARGE_FAILED = "WITHDRAWAL_CHARGE_FAILED";

export const RELOAD_PAGE_RESET = "RELOAD_PAGE_RESET";