import { useDispatch, useSelector } from "react-redux";
import { ButtonLoader } from "../common/form";
import FormDrawer from "../common/form-drawer--new";
import { ReactComponent as IconCircleQuestion } from "assets/images/icons/project-icons/circle-question.svg";
import { manualUpdateUserDetails } from "store/entities/employees/action";


const ActivateCustomerModal = ({isOpen, setIsOpen, user, fetchCustomers, setUsersParams}) => {
	
	const dispatch = useDispatch();
	const { manualUpdateUserDetailsLoading: loading } = useSelector((state) => state.entities.employees);

	
	const activateUser = () => { 
		const payload = {
			disabled: false,
			isActive: true,
		}
		
		dispatch(manualUpdateUserDetails({
			id: user?._id,
			payload
		})).then(() => {
			setIsOpen(false);
			setUsersParams({
				page: 1,
				limit: 10,
			});
			fetchCustomers();
		});;
	}
	
	return (
		<FormDrawer display="center" size="md" isOpen={isOpen} setIsOpen={() => {}}>
			<div className="p-5 max-w-md">
				<div className="flex items-center justify-center flex-col pb-8">
					<div className="text-center">
						<IconCircleQuestion />
					</div>
					
					<h1 className="mt-5 mb-2 font-semibold">
						Activate Customer
					</h1>
					
					<p className="mb-0 text-center">Are you sure you want to activate this customer?</p>
				</div>
				
				<div className="flex items-center justify-center lg:flex-row pb-2">
					
					<button
						onClick={() => setIsOpen(false)}
						className="btn btn-md border border-ep-primary-500 text-ep-primary-500 focus:ring-0 w-full lg:w-2/4 mr-4"
						disabled={loading}>
						Cancel
					</button>
					
					<ButtonLoader
						loading={loading}
						onClick={activateUser}
						className="z-5 btn btn-md border bg-ep-primary text-white focus:ring-0 w-full lg:w-2/4">
						{loading ? "Submitting..." : "Yes"}
					</ButtonLoader>
				</div>
			</div>
		</FormDrawer>
	)
}

export default ActivateCustomerModal