import React from 'react'
import { Switch } from '@headlessui/react';


const CustomSwitch = ({active, onChange, activeText, inactiveText, disabled}) => {
    return (
        <div style={{opacity: disabled ? "40%" : "100%"}} className="flex items-center">
            <span className="mr-3 text-sm">
                {active ? activeText || 'Active' : inactiveText || 'Inactive'}
            </span>
            <Switch
                as="button"
                checked={active}
                disabled={disabled} 
                onChange={onChange}
                className={`relative inline-flex h-5 w-8 items-center rounded-full transition-all duration-300 ${ disabled && "cursor-not-allowed"} ${active ? 'bg-ep-blue' : 'bg-gray-300'}`}>
                <span className={`inline-block h-4 w-4 transform rounded-full bg-white ${active ? 'absolute right-0.5' : 'ml-0.5'}`} />
            </Switch>
        </div>
    )
}


export default CustomSwitch