import { useLocation } from "react-router-dom";
import queryString from 'query-string';


const useUrlQueryParams = () => {
    const {search: urlQueryString} = useLocation();
    const qs = queryString.parse(urlQueryString);
    const urlQueryParams = qs;

    return {urlQueryParams, urlQueryString};
}


export default useUrlQueryParams;