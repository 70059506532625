import SideModal from "components/misc/SideModal";
import useToolkit from "utils/useToolkit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SingleHandlerModal = ({ control, onClose }) => {
	const {
		useSelector,
		Spinner,
		Xclose,
		CurrencyFormat,
		DateTimeFormat,
		copyText,
		ErrorUI,
	} = useToolkit();
	const { singleHandlerLoading: loading, singleHandlerInfo } = useSelector(
		(state) => state.entities.payments
	);

	const handler = singleHandlerInfo?.data;
	const recipient = handler?.processorResponse?.recipient;

	const isSuccessful = handler?.status === "success";

	const CONTAINER_CLASS = "my-8 border-b border-dashed border-gray-500";
	const TITLE =
		"font-semibold mb-6 tracking-wider text-sm lg:text-md uppercase";
	const WRAPPER_CLASS = "flex items-center justify-between text-sm mb-4";
	const WRAPPER_KEY = "font-medium tracking-wide";

	const errorOccurred = loading === false && singleHandlerInfo === null;

	return (
		<SideModal onClose={onClose} control={control}>
			{loading ? (
				<Spinner />
			) : errorOccurred ? (
				<ErrorUI />
			) : (
				<div className="w-full px-3 py-5">
					<div className="flex items-center justify-between">
						<h5 className="font-semibold uppercase">
							{handler?.processorResponse?.reference}
						</h5>
						<Xclose className="cursor-pointer" onClick={onClose} />
					</div>

					<div className={CONTAINER_CLASS}>
						<p className={TITLE}>Payment Details</p>

						<div className={WRAPPER_CLASS}>
							<h5 className={WRAPPER_KEY}>Amount</h5>
							<h5>{CurrencyFormat(handler?.processorResponse?.amount / 100)}</h5>
						</div>

						<div className={WRAPPER_CLASS}>
							<h5 className={WRAPPER_KEY}>Date</h5>
							<h5>{DateTimeFormat(handler?.processorResponse?.createdAt)}</h5>
						</div>

						<div className={WRAPPER_CLASS}>
							<h5 className={WRAPPER_KEY}>Channel</h5>
							<h5 className="capitalize">{handler?.request?.channel}</h5>
						</div>

						<div className={WRAPPER_CLASS}>
							<h5 className={WRAPPER_KEY}>Description</h5>
							<h5 className="capitalize">{recipient?.description}</h5>
						</div>

						<div className={WRAPPER_CLASS}>
							<h5 className={WRAPPER_KEY}>Fee Charged</h5>
							<h5>{handler?.processorResponse?.fee_charged}</h5>
						</div>

						<div className={WRAPPER_CLASS}>
							<h5 className={WRAPPER_KEY}>Reference</h5>
							<h5 className="flex items-center">
								{handler?.processorResponse?.reference?.substring(0, 20)}...
								<span className="ml-1 text-ep-blue-deeper">
									<FontAwesomeIcon
										onClick={() =>
											copyText(
												handler?.processorResponse?.reference,
												"Reference"
											)
										}
										icon="copy"
										className="text-lg cursor-pointer"
									/>
								</span>
							</h5>
						</div>

						<div className={WRAPPER_CLASS}>
							<h5 className={WRAPPER_KEY}>Webhook URL</h5>
							<h5 className="flex items-center">
								{handler?.request?.webhook?.substring(0, 30)}...
								<span className="ml-1 text-ep-blue-deeper">
									<FontAwesomeIcon
										onClick={() =>
											copyText(handler?.request?.webhook, "Webhook URL")
										}
										icon="copy"
										className="text-lg cursor-pointer"
									/>
								</span>
							</h5>
						</div>

						<div className={WRAPPER_CLASS}>
							<h5 className={WRAPPER_KEY}>Status</h5>
							<h5
								className={`px-2.5 py-1 rounded-md capitalize text-white ${
									isSuccessful ? "bg-green-500" : "bg-red-500"
								}`}>
								{handler?.status}
							</h5>
						</div>
					</div>

					<div className={CONTAINER_CLASS}>
						<p className={TITLE}>Recipient Details</p>

						<div className={WRAPPER_CLASS}>
							<h5 className={WRAPPER_KEY}>Account Name</h5>
							<h5>{recipient?.details?.account_name}</h5>
						</div>

						<div className={WRAPPER_CLASS}>
							<h5 className={WRAPPER_KEY}>Account Number</h5>
							<h5>{recipient?.details?.account_number}</h5>
						</div>

						<div className={WRAPPER_CLASS}>
							<h5 className={WRAPPER_KEY}>Bank Name</h5>
							<h5>{recipient?.details?.bank_name}</h5>
						</div>
					</div>
				</div>
			)}
		</SideModal>
	);
};

export default SingleHandlerModal;
