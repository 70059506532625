function SideModal({ children, title, onClose, control }) {
	return control ? (
		<section
			onClick={onClose}
			className={`fixed p-4 lg:p-0 top-0 left-0 right-0 backdrop-blur-lg bottom-0 bg-black z-20 bg-opacity-30 flex items-center justify-center lg:justify-end`}>
			<div
				onClick={(e) => e.stopPropagation()}
				className="bg-white overflow-y-auto h-full w-full md:w-3/5 lg:w-2/6 p-0 rounded-sm lg:rounded-0 z-10">
				{title && (
					<div
						className={`flex items-center justify-between sticky top-0 left-0 z-10 py-4 bg-white shadow-md w-full px-6`}>
						<div>
							<p className="mb-0 font-semibold tracking-wide">
								{title || "title"}
							</p>
						</div>
						<div>
							<p
								onClick={onClose}
								className="font-semibold cursor-pointer tracking-wider mb-0 text-[15px] transition-colors">
								Close
							</p>
						</div>
					</div>
				)}
				<div className="px-6 py-5">{children}</div>
			</div>
		</section>
	) : null;
}

export default SideModal;
