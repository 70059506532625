import * as actions from "./actionTypes";
import { toast } from 'react-toastify';
import errorReducer from "utils/errorReducer";

const initialState = {
    referralsLoading: false,
    referrals: {},
    referralsDownloadLoading: false,
    referralsDownload: {},

    referralsUnfilteredLoading: false,
    referralsUnfiltered: {},
    referralsCompletedLoading: false,
    referralsCompleted: {},
    referralsPendingLoading: false,
    referralsPending: {},
};


export default function reducer(state = initialState, action) {
    switch (action.type) {

        case actions.GET_ALL_REFERRALS:
            state = {
                ...state,
                referralsLoading: false,
                referrals: action.payload,
            }
            return state;


        case actions.GET_ALL_REFERRALS_START:
            state = {
                ...state,
                referralsLoading: true,
                referrals: {},
            }
            return state;


        case actions.GET_ALL_REFERRALS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                referralsLoading: false,
                referrals: action.payload,
            }
            return state;


        case actions.DOWNLOAD_GET_ALL_REFERRALS:
            state = {
                ...state,
                referralsDownloadLoading: false,
                referralsDownload: action.payload,
            }
            return state;


        case actions.DOWNLOAD_GET_ALL_REFERRALS_START:
            state = {
                ...state,
                referralsDownloadLoading: true,
                referralsDownload: {},
            }
            return state;


        case actions.DOWNLOAD_GET_ALL_REFERRALS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                referralsDownloadLoading: false,
                referralsDownload: action.payload,
            }
            return state;


        case actions.GET_UNFILTERED_REFERRALS:
            state = {
                ...state,
                referralsUnfilteredLoading: false,
                referralsUnfiltered: action.payload,
            }
            return state;


        case actions.GET_UNFILTERED_REFERRALS_START:
            state = {
                ...state,
                referralsUnfilteredLoading: true,
                referralsUnfiltered: {},
            }
            return state;


        case actions.GET_UNFILTERED_REFERRALS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                referralsUnfilteredLoading: false,
                referralsUnfiltered: {},
            }
            return state;


        case actions.GET_COMPLETED_REFERRALS:
            state = {
                ...state,
                referralsCompletedLoading: false,
                referralsCompleted: action.payload,
            }
            return state;


        case actions.GET_COMPLETED_REFERRALS_START:
            state = {
                ...state,
                referralsCompletedLoading: true,
                referralsCompleted: {},
            }
            return state;


        case actions.GET_COMPLETED_REFERRALS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                referralsCompletedLoading: false,
                referralsCompleted: {},
            }
            return state;


        case actions.GET_PENDING_REFERRALS:
            state = {
                ...state,
                referralsPendingLoading: false,
                referralsPending: action.payload,
            }
            return state;


        case actions.GET_PENDING_REFERRALS_START:
            state = {
                ...state,
                referralsPendingLoading: true,
                referralsPending: {},
            }
            return state;


        case actions.GET_PENDING_REFERRALS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                referralsPendingLoading: false,
                referralsPending: {},
            }
            return state;


        case actions.REFERRALS_RESEND_INVITE:
            toast.success("Invite Sent");
            state = {
                ...state,
            }
            return state;


        case actions.REFERRALS_RESEND_INVITE_START:
            // toast("Resending invite");
            state = {
                ...state,
            }
            return state;

            
        case actions.REFERRALS_RESEND_INVITE_FAILED:
            toast.error(action.payload.message);
            state = {
                ...state,
            }
            return state;


        default:
            return state;
    }
}