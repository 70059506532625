import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from "react-redux";
import SearchFilter from 'components/common/filter';
import Download from 'components/common/download';
import nameToSentenceCase from "utils/nameToSentenceCase";
import { ReactComponent as IconTotalApprovedLimit } from "assets/images/icons/project-icons/color-stack.svg";
import { ReactComponent as IconApprovedLimitCount } from "assets/images/icons/project-icons/dark-green-card.svg";
import { ReactComponent as IconCurrentExposure } from "assets/images/icons/project-icons/kiosk.svg";
import { ReactComponent as IconRejectedLoanLimit } from "assets/images/icons/project-icons/red-card.svg";
import useUrlQueryParams from 'hooks/useUrlQueryParams';
import processParams from 'utils/processParams';
import CreditRequestsTable from '../../components/__new/credit/CreditRequestsTable';
import DashboardBoxes from 'components/common/dashboard-boxes';
import CurrencyFormat from 'utils/currencyFormat';
import TableFilterTabsWithQuery from 'components/__new/common/TableFilterTabsWithQuery';
import CreditDetailsModal from 'components/__new/credit/CreditDetailsModal';
import LoanApprovalRequest from 'components/__new/credit/LoanApprovalRequest';
import LoanApprovalConfirmation from 'components/__new/credit/LoanApprovalConfirmation';
import LoanRejectionRequest from 'components/__new/credit/LoanRejectionRequest';
import LoanResetConfirmation from 'components/__new/credit/LoanResetConfirmation';
import LoanRejectionConfirmation from 'components/__new/credit/LoanRejectionConfirmation';
import LoanLimitUpdateRequest from 'components/__new/credit/LoanLimitUpdateRequest';
import LoanLimitUpdateApproval from 'components/__new/credit/LoanLimitUpdateApproval';
import LoanLimitUpdateRejection from 'components/__new/credit/LoanLimitUpdateRejection';
import UpdateBankConfirmation from 'components/__new/credit/UpdateBankModal';
import NumberFormat from 'utils/numberFormat';
import { useLocation } from 'react-router-dom';
import { getCreditMetrics, getCreditRequestDetails, getCreditRequests, getAllLoans } from 'store/entities/credit/action';
import { AllLoansTable } from 'components/__new/credit/AllLoansTable';
import { MakeLoanPaymentModal } from 'components/__new/credit/MakeLoanPaymentModal';
// import LoanLimitUpdateRejection from 'components/__new/credit/LoanLimitUpdateRejection';


const CreditRequestsHomepage = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { urlQueryParams } = useUrlQueryParams();
    const [isDetailsOpen, setIsDetailsOpen] = useState(false);

    const [creditParams, setCreditParams] = useState({
        page: 1,
        per_page: 10
    });
    const [allLoansParams, setAllLoansParams] = useState({
        page: 1,
        limit: 10,
    });

    const toggleDetails = (userId) => {
        dispatch(getCreditRequestDetails(userId))
        setIsDetailsOpen(!isDetailsOpen)
    }


    const { allCustomersData, downloadAllCustomersLoading, downloadAllCustomersData } = useSelector(s => s.entities.employees);
    const {
        creditRequestsLoading,
        creditRequestsData,
        creditMetricsLoading,
        creditMetricsData,
        creditDetailsLoading,
        creditDetailsData,
        allLoansPayload, allLoansLoading
    } = useSelector(s => s.entities.credit);

    const rejectedStats = creditMetricsData?.filter((item) => item.limitStatus === 'rejected')[0];
    const approveStats = creditMetricsData?.filter((item) => item.limitStatus === 'approved')[0];
    const creditStats = {
        rejectCount: rejectedStats?._count?.limitStatus,
        rejectSum: rejectedStats?._sum?.amount,
        approvedCount: approveStats?._count?.limitStatus,
        approvedSum: approveStats?._sum?.amount,
    }
    const refinedData = {
        ...allCustomersData?.data,
        docs: allCustomersData?.data?.users?.map((user) => {
            
            const params = [
                {
                    label: 1,
                    value: user?.bvnValidated
                },
                {
                    label: 2,
                    value: user?.isFinalResult
                },
                {
                    label: 3,
                    value: user?.kycAddressVerificationFile?.length > 0 ? true : false
                },
            ]
            
            const kycLevel = params?.filter((item) => item.value === true)?.length
            
            return {
                ...user,
                kycLevel,
            }
        })
    }

    const creditMetrics = {
        totalApprovedLimit: {
			iconBg: "bg-ep-primary-100",
			icon: (
				<IconTotalApprovedLimit className="customizeSVG m-auto w-5 h-5" />
			),
			titleText: "Total Approved Limit",
			mainText: "-",
		},
		numberOfApprovedLimit: {
			iconBg: "bg-[#DEFFEE]",
			icon: (
				<IconApprovedLimitCount className="customizeSVG m-auto w-5 h-5" />
			),
			titleText: "No. of Approved Loan Limit",
			mainText: "-",
		},
		totalCurrentExposure: {
			iconBg: "bg-yellow-100",
			icon: (
				<IconCurrentExposure className="customizeSVG m-auto w-5 h-5" />
			),
			titleText: "Total Current Exposure",
			mainText: "-",
		},
		rejectedLoanLimit: {
			iconBg: "bg-[#FFE2E2]",
			icon: (
				<IconRejectedLoanLimit className="customizeSVG m-auto w-5 h-5" />
			),
			titleText: "No. of Rejected Loan Limits",
			mainText: "-",
		},
		rejectedLoanAmount: {
			iconBg: "bg-[#FFE2E2]",
			icon: (
				<IconRejectedLoanLimit className="customizeSVG m-auto w-5 h-5" />
			),
			titleText: "Total Rejected Loan Amount",
			mainText: "-",
		},
    }

    const [isAllLoansActive, setIsAllLoansActive] = useState(false);
    const handleAllLoansToggle = () => setIsAllLoansActive(!isAllLoansActive);
    const handleCloseAllLoansTab = () => {
        setIsAllLoansActive(false);
    }
    const [loanAccountId, setLoanAccountId] = useState("");
    const [isMakeLoanPaymentModalOpen, setIsMakeLoanPaymentModalOpen] = useState(false);

    const handleToggleMakeLoanPaymentModal = () => setIsMakeLoanPaymentModalOpen(!isMakeLoanPaymentModalOpen);

    const fetchAllLoans = () => {
        let params = { ...allLoansParams };
        params = processParams(urlQueryParams, params, "page", "number", 1, "page");
        params = processParams(urlQueryParams, params, "limit", "number", 10, "limit");

        setAllLoansParams(params);
        dispatch(getAllLoans(params));
    }

    const creditTabsDataset = [
        {
            title: "All",
            loading: creditRequestsLoading,
            onClickPayload: {"eligibilityStatus": "", page: "1", limit: creditParams.per_page },
            data: creditRequestsData?.totalDocs,
            isActive: (!creditParams?.eligibilityStatus || creditParams?.eligibilityStatus === "") && !isAllLoansActive,
            onClick: handleCloseAllLoansTab,
        },
        {
            title: "Eligible",
            loading: creditRequestsLoading,
            onClickPayload: {"eligibilityStatus": "eligible", page: "1", limit: creditParams.per_page },
            data: creditRequestsData?.totalDocs,
            isActive: creditParams?.eligibilityStatus === "eligible" && !isAllLoansActive,
            onClick: handleCloseAllLoansTab,
        },
        {
            title: "Not Eligible",
            loading: creditRequestsLoading,
            onClickPayload: {"eligibilityStatus": "noteligible", page: "1", limit: creditParams.per_page },
            data: creditRequestsData?.totalDocs,
            isActive: creditParams?.eligibilityStatus === "noteligible" && !isAllLoansActive,
            onClick: handleCloseAllLoansTab,
        },
        {
            title: "All Loans",
            loading: creditRequestsLoading,
            onClickPayload: { "eligibilityStatus": "allloans", page: "1", limit: allLoansParams.limit },
            data: allLoansPayload?.totalDocs || "-",
            isActive: isAllLoansActive,
            onClick: handleAllLoansToggle,
        },
    ]
    
    const columns = [
        { label: "Date Joined", key: "createdAt" },
        { label: "Date Updated", key: "updatedAt" },
        { label: "Full Name", key: "fullName" },
        { label: "Email Address", key: "email" },
        { label: "Phone Number", key: "phone" },
        { label: "Points", key: "points" },
        { label: "IsCustomerEmployee", key: "isEmployee" },
        { label: "User Status", key: "userStatus" },
        { label: "Account Tier", key: "accountTier" },
        { label: "KYC Status", key: "kycStatus" },
        { label: "User Type", key: "userType" },
    ];
    const downloadRef = useRef();

    const fetchCreditItems = () => {
        let params = { ...creditParams };
        
        params = processParams(urlQueryParams, params, "page", "number", 1, "page");
        params = processParams(urlQueryParams, params, "per_page", "number", 10, "per_page");
		// params = processParams(urlQueryParams, params, "status", "string", "", "status");
		params = processParams(urlQueryParams, params, "search", "string", "", "search");
		params = processParams(urlQueryParams, params, "applicationStartDate", "string", "", "applicationStartDate");
		params = processParams(urlQueryParams, params, "applicationEndDate", "string", "", "applicationEndDate");
		params = processParams(urlQueryParams, params, "approvalStartDate", "string", "", "approvalStartDate");
		params = processParams(urlQueryParams, params, "approvalEndDate", "string", "", "approvalEndDate");
		params = processParams(urlQueryParams, params, "eligibilityStatus", "string", "", "eligibilityStatus");
		params = processParams(urlQueryParams, params, "limitStatus", "string", "", "limitStatus");
        
        setCreditParams(params);

        dispatch(getCreditRequests(params));
        
        if(params?.keyword?.length || params?.dateFrom?.length || 
            params?.dateTo?.length || params?.kycStatus?.length || 
            params?.status?.length || params?.accountNumber?.length || 
            (params?.pointFrom?.length && params?.pointTo?.length) ||
            params?.userTier?.length || (params?.updatedFrom?.length && params?.updatedTo?.length)) {
            // dispatch(searchAndFilterCustomers(params));
        } else {
            // dispatch(getAllCustomers(params));
        }
        
    }
    
    const transformDataDownload = (data) => {
        const newData = data?.docs?.map((row) => {
            const isActive = row?.isActive === true && row?.disabled === false;
            const isInactive = row?.isActive === false && row?.disabled === false;
            const isDeactivated = row?.isActive === false && row?.disabled === true;
            
            const userStatus = isActive ? "Active" : isInactive ? "Inactive" : isDeactivated ? "Deactivated" : ""
            
			const newRow = {
				...row,
				fullName: nameToSentenceCase(row.fullName) ?? "",
                isEmployee: row.employeeId && row.employeeId !== "" ? "true" : "false",
                userStatus,
                accountTier: `Tier ${row?.accountTier}`
			}
			return newRow;
		})
		return newData;
	}
    
    useEffect(() => {
        if (isAllLoansActive || urlQueryParams?.eligibilityStatus === "allloans") {
            setIsAllLoansActive(true);
            fetchAllLoans();
        } else {
            fetchCreditItems();
        }
        dispatch(getCreditMetrics());
        // eslint-disable-next-line
    }, [location, isAllLoansActive]);


    return (
        <div>

            <LoanApprovalRequest
                user={creditDetailsData}
                refetch={() => {}}
            />

            <LoanApprovalConfirmation />

            <UpdateBankConfirmation
                user={creditDetailsData}
                refetch={fetchCreditItems}
            />

            <LoanResetConfirmation
                user={creditDetailsData}
                refetch={() => {}}
            />

            <LoanRejectionRequest
                user={creditDetailsData}
                refetch={() => {}}
            />

            <LoanRejectionConfirmation />

            <LoanLimitUpdateRequest
                user={creditDetailsData}
                refetch={() => {}}
            />

            <LoanLimitUpdateApproval
                user={creditDetailsData}
                refetch={() => {}}
            />

            <LoanLimitUpdateRejection
                user={creditDetailsData}
                refetch={getCreditRequests}
            />
            
            <CreditDetailsModal
                loading={creditDetailsLoading}
                user={creditDetailsData?.limit}
                control={isDetailsOpen}
                onClose={toggleDetails}
                setIsDetailsOpen={setIsDetailsOpen}
            />

            <MakeLoanPaymentModal 
                isMakeLoanPaymentModalOpen={isMakeLoanPaymentModalOpen}
                handleToggleMakeLoanPaymentModal={handleToggleMakeLoanPaymentModal}
                fetchAllLoans={fetchAllLoans}
                loanAccountId={loanAccountId}
            />
            
            <div>
                <div className="lg:flex">
                    <div className="w-full lg:w-1/2">
                        <div className="page-title capitalize">
                            Credit
                        </div>
                    </div>
                    <div className="w-full lg:w-1/2 flex space-x-4 mt-2 lg:mt-0 lg:justify-end">
                        <div>
                            <Download
                                tooltip="Download credit requests"
                                format="csv"
                                filename="All Credit requests.csv"
                                columns={columns}
                                ref={downloadRef}
                                click={() => {
                                    alert("Cannot download")
                                    // dispatch(downloadAllCustomers({page: 1, limit: 2000})).then(() => {
                                    //     downloadRef.current.processDownload();
                                    // });
                                }}
                                loading={downloadAllCustomersLoading}
                                response={transformDataDownload(downloadAllCustomersData?.data)}
                            />
                        </div>
                        <div>
                            <SearchFilter
                                search={{
                                    name: "search",
                                    placeholder: "Keyword search",
                                }}
                                filters={[
                                    {
                                        title: "Application Date",
                                        dataType: "date-range",
                                        options: {
                                            from: {
                                                name: "applicationStartDate",
                                                placeholder: "Start Date",
                                            },
                                            to: {
                                                name: "applicationEndDate",

                                                placeholder: "End Date",
                                            },
                                        },
                                    },
                                    {
                                        title: "Approval Date",
                                        dataType: "date-range",
                                        options: {
                                            from: {
                                                name: "approvalStartDate",
                                                placeholder: "Start Date",
                                            },
                                            to: {
                                                name: "approvalEndDate",

                                                placeholder: "End Date",
                                            },
                                        },
                                    },
                                    {
                                        title: "Loan Limit Status",
                                        name: "limitStatus",
                                        dataType: "checkbox",
                                        options: [
                                            {
                                                display: "Approved",
                                                payload: "approved",
                                            },
                                            {
                                                display: "Pending",
                                                payload: "pending",
                                            },
                                            {
                                                display: "Rejected",
                                                payload: "rejected",
                                            },
                                        ],
                                    },
                                    {
                                        title: "Eligibility Status",
                                        name: "eligibilityStatus",
                                        dataType: "checkbox",
                                        options: [
                                            {
                                                display: "Eligible",
                                                payload: "eligible",
                                            },
                                            {
                                                display: "Not Eligible",
                                                payload: "noteligible",
                                            },
                                        ],
                                    },
                                    {
                                        title: "Loan Limit Anount",
                                        name: "loanLimitAmount",
                                        dataType: "checkbox",
                                        options: [
                                            {
                                                display: "1m and below",
                                                payload: true,
                                            },
                                            {
                                                display: "Above 1m",
                                                payload: false,
                                            },
                                        ],
                                    },
                                    // {
                                    //     title: "Loan Limit Amount",
                                    //     dataType: "number-range",
                                    //     options: {
                                    //         from: {
                                    //             name: "loanLimitFrom",
                                    //             placeholder: "Enter an amount",
                                    //         },
                                    //         to: {
                                    //             name: "loanLimitTo",
                                    //             placeholder: "Enter an amount",
                                    //         },
                                    //     },
                                    // },
                                ]}
                            />
                        </div>
                    </div>
                </div>

                <div className="my-10">
                    <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-6">
                        <DashboardBoxes
                            data={creditMetrics.totalApprovedLimit}
                            loading={creditMetricsLoading}
                            result={
                                CurrencyFormat(creditStats.approvedSum / 100) || 0
                            }
                        />
                        <DashboardBoxes
                            data={creditMetrics.numberOfApprovedLimit}
                            loading={creditMetricsLoading}
                            result={
                                NumberFormat(creditStats.approvedCount) || 0
                            }
                        />
                        <DashboardBoxes
                            data={creditMetrics.totalCurrentExposure}
                            loading={creditMetricsLoading}
                            result={
                                CurrencyFormat("000000")
                            }
                        />
                        <DashboardBoxes
                            data={creditMetrics.rejectedLoanLimit}
                            loading={creditMetricsLoading}
                            result={
                                NumberFormat(creditStats.rejectCount) || 0
                            }
                        />
                        <DashboardBoxes
                            data={creditMetrics.rejectedLoanAmount}
                            loading={creditMetricsLoading}
                            result={
                                CurrencyFormat(creditStats.rejectSum / 100) || 0
                            }
                        />
                    </div>
				</div>
                

                <div className="w-full mt-6 lg:flex justify-between">
                    <div className="w-full lg:w-1/2 flex">
                        <div className="page-title-mini my-auto">
                            {isAllLoansActive ? "All Loans" : "Loan Data"}
                        </div>
                    </div>
                    <div>
                        <TableFilterTabsWithQuery 
                            params={{...creditParams}}
                            queryDataset={creditTabsDataset}
                        />
                    </div>
                </div>
                
                {
                    isAllLoansActive ? (
                        <AllLoansTable 
                            allLoansPayload={allLoansPayload} 
                            loading={allLoansLoading}
                            setLoanAccountId={setLoanAccountId}
                            handleToggleMakeLoanPaymentModal={handleToggleMakeLoanPaymentModal}
                        />
                    ) : (
                        <CreditRequestsTable 
                            refinedData={refinedData}
                            creditParams={creditParams}
                            toggleDetails={toggleDetails}
                            fetchCreditItems={fetchCreditItems}
                            loading={creditRequestsLoading}
                            data={creditRequestsData}
                        />
                    )
                }
                
            </div>
        </div>
    )
    
    
}

export default CreditRequestsHomepage;