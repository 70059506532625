import React from 'react';
import Header from 'components/common/header.jsx';
import Sidebar from 'components/common/sidebar.jsx';
import IdleChecker from "hooks/useIdleChecker";
import Environment from "components/__new/common/Environment";


const AdminLayout = (props) => {
    return (
        <>

            <Environment local={false} develop={true} staging={true} production={true}>
                <IdleChecker />
            </Environment>

            <div className="flex min-h-screen" style={{backgroundColor: "#F7FAFC"}}>
                <Sidebar />
                <div className="h-screen flex-none hidden lg:block" style={{width:"250px"}}></div>
                <div className="flex-grow overflow-hidden">
                    <Header />
                    <div>
                        <div id="main-content" className="p-6 pb-24 sm:p-10">
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
 
export default AdminLayout;