import React, { Component } from 'react';
import Joi from "joi-browser";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as admin from "store/entities/admin/action";


class ResetPassword extends Component {
    state = { 
        adminOnboardForm: {
            email: "",
            pin: "",
            password: "",
            confirmPassword: "",
        },
        errors: {},
        pageAccess: "",
        storeUnsubscribe: "",
    }

    formChange = (e) => {
        const formValue = {...this.state.adminOnboardForm};
        formValue[e.currentTarget.name] = e.currentTarget.value;
        this.setState({adminOnboardForm: formValue});
    }
    
    schema = {
        password: Joi.string().required().label("Password").error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    case "any.empty":
                        err.message = "Please enter your new password";
                        break;
                    default:
                        break;
                }
            });
            return errors;
        }),
        confirmPassword: Joi.any().valid(Joi.ref('password')).label("Confirm Password").error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    case "any.allowOnly":
                        err.message = "Your password does not match";
                        break;
                    default:
                        break;
                }
            });
            return errors;
        }),
    }

    validate = () => {
        const options = { abortEarly: false, allowUnknown: true };
        const result = Joi.validate(this.state.adminOnboardForm, this.schema, options);
        // console.log(result);
        const error = result.error;
        if (!error) return null;
    
        const errors = {};
        for (let item of result.error.details){
            errors[item.path[0]] = item.message;
        }
        return errors;
    };

    submitForm = (e) => {
        e.preventDefault();
        const errors = this.validate();
        this.setState({errors: errors || {}});
        if (errors){
            // console.log(errors);
            for (var x in errors) {
                toast.error(errors[x]);
            };
            return;
        }
        else{
            // alert('all good');
            // console.log(this.state.adminOnboardForm);
            this.props.adminOnboard(this.state.adminOnboardForm).then(() => {
                if (this.props.adminOnboardSuccess === true){
                    this.props.resetAdminOnboard();
                    this.props.history.push("/admin/verify/success");
                }
            })
        }
    }
    
    componentDidMount() {
        let pageAccess = false;
        if (("email" in this.props.match.params) && ("pin" in this.props.match.params)){
            pageAccess = true;
            const {email, pin} = this.props.match.params;
            const adminOnboardForm = {...this.state.adminOnboardForm, email, pin};
            this.setState({adminOnboardForm});
        }
        this.setState({pageAccess});
    }
    

    render() { 
        const {adminOnboardForm} = this.state;

        return (
            <div className="p-8 pb-14 sm:p-14 md:pt-20">
                <div className="lg:pl-4 lg:pr-4">
                    
                    {(this.state.pageAccess === false) &&
                        <div className="mt-8 mx-auto text-center max-w-xs">
                            <div className="w-24 h-24 md:w-32 md:h-32 rounded-full bg-red-500 flex mx-auto">
                                <FontAwesomeIcon icon="exclamation-circle" className="text-7xl text-white m-auto" />
                            </div>
                            <div className="mt-8 font-boing font-semibold text-2xl sm:text-3xl">
                                Link Broken
                            </div>
                            <div className="mt-2">
                                Sorry, this link is broken.&nbsp;
                                <br className="hidden md:block" />
                                Please recheck the url and try again later, or contact our support team.
                            </div>
                            <Link to="/" className="btn btn-lg btn-block btn-ep-blue mt-8">
                                Dismiss
                            </Link>
                        </div>
                    }

                    {(this.state.pageAccess === true) &&
                        <>
                                
                            <div className="font-boing font-semibold text-2xl sm:text-3xl text-center md:text-left">
                                {/* Get Started */}
                                Welcome, Admin
                            </div>
                            <div className="mt-2">
                                Please set your password to gain access to your admin account.
                            </div>

                            <div>
                                <div>

                                    <div className="mt-10 w-full max-w-md mx-auto md:mx-0 lg:w-96">

                                        <form onSubmit={this.submitForm}>

                                            <div className="form-group">
                                                <label>
                                                    New Password
                                                    <span className="form-input-required">*</span>
                                                </label>
                                                <input type="password" name="password" placeholder="XXXXXXXXXX" autoComplete="off" className="form-input" value={adminOnboardForm.password} onChange={this.formChange} />
                                            </div>

                                            <div className="form-group">
                                                <label>
                                                    Confirm Password
                                                    <span className="form-input-required">*</span>
                                                </label>
                                                <input type="password" name="confirmPassword" placeholder="XXXXXXXXXX" autoComplete="off" className="form-input" value={adminOnboardForm.confirmPassword} onChange={this.formChange} />
                                            </div>

                                            <button type="submit" className="btn btn-lg btn-block btn-ep-blue mt-8" disabled={this.props.loading}>
                                                <FontAwesomeIcon icon="spinner" spin className={"text-xl mr-2 " + (this.props.loading?"inline":"hidden")} />
                                                Set Password
                                            </button>
                                        </form>


                                        <div className="mt-3 text-sm font-semibold text-gray-500">
                                            Already setup your account? &nbsp;
                                            <Link to="/login" className="text-ep-blue hover:underline">Login</Link>
                                        </div>

                                    </div>

                                </div>
                            </div>

                        </>
                    }

                </div>
            </div>
        )
    }
}
 


const mapStateToProps = (state) => ({
    loading: state.entities.admin.adminOnboardLoading,
    adminOnboardSuccess: state.entities.admin.adminOnboard,
});

const mapDispatchToProps = (dispatch) => ({
    adminOnboard: (params) => dispatch(admin.adminOnboard(params)),
    resetAdminOnboard: () => dispatch(admin.resetAdminOnboard()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);