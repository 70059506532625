import CurrencyFormat from "utils/currencyFormat";
import * as actions from "./actionTypes";
import errorReducer from "utils/errorReducer";

const initialState = {
	goalsSavingsLoading: false,
	goalsSavingsData: [],
	goalSavingsCSV: [],

	flexSavingsLoading: false,
	flexSavingsData: [],

	savingsDetailsLoading: false,
	savingsDetailsData: null,
	selectedSavingsItem: null,
	savingsMetricsLoading: false,
	savingsMetricsData: null,

	savingsTrxLoading: false,
	savingsTrxData: null,

	downloadGoalSavingsLoading: false,
	downloadGoalSavingsData: null,
	
	pauseGoalSavingsLoading: false,
	cancelGoalSavingsLoading: false,
};

export default function reducer(state = initialState, action) {
	switch (action.type) {

		case actions.DOWNLOAD_GOALS_SAVINGS_START:
			state = {
				...state,
				downloadGoalSavingsLoading: true,
				downloadGoalSavingsData: null,
			};
			return state;

		case actions.DOWNLOAD_GOALS_SAVINGS_DONE:
			state = {
				...state,
				downloadGoalSavingsLoading: false,
				downloadGoalSavingsData: action.payload,
			};
			return state;

		case actions.DOWNLOAD_GOALS_SAVINGS_FAILED:
			errorReducer(action.payload);
			state = {
				...state,
				downloadGoalSavingsLoading: false,
				downloadGoalSavingsData: null,
			};
			return state;

		case actions.GET_SAVINGS_TRX_START:
			state = {
				...state,
				savingsTrxLoading: true,
			};
			return state;

		case actions.GET_SAVINGS_TRX_DONE:
			state = {
				...state,
				savingsTrxLoading: false,
				savingsTrxData: action.payload,
			};
			return state;

		case actions.GET_SAVINGS_TRX_FAILED:
			errorReducer(action.payload);
			state = {
				...state,
				savingsTrxLoading: false,
				savingsTrxData: null,
			};
			return state;


		case actions.GET_SAVINGS_DETAILS_START:
			state = {
				...state,
				savingsDetailsLoading: true,
			};
			return state;

		case actions.GET_SAVINGS_DETAILS_DONE:
			state = {
				...state,
				savingsDetailsLoading: false,
				savingsDetailsData: action.payload,
			};
			return state;

		case actions.GET_SAVINGS_DETAILS_FAILED:
			errorReducer(action.payload);
			state = {
				...state,
				savingsDetailsLoading: false,
				savingsDetailsData: null,
			};
			return state;

		case actions.SELECT_SAVINGS_ITEM:
			state = {
				...state,
				selectedSavingsItem: action.payload,
			};
			return state;

		case actions.GET_ALL_GOALS_SAVINGS_START:
			state = {
				...state,
				goalsSavingsLoading: true,
				goalsSavingsData: [],
				goalSavingsCSV: [],
			};
			return state;

		case actions.GET_ALL_GOALS_SAVINGS_DONE:
			const rawData = action.payload?.data?.docs
			const formattedData = rawData?.map(item => {
				const dailyDepositInNaira = CurrencyFormat(item?.auto_save?.amount / 100)
				const targetAmountInNaira = CurrencyFormat(item?.target / 100)
				return {
					...item,
					dailyDepositInNaira,
					targetAmountInNaira
				}
			})
			state = {
				...state,
				goalsSavingsLoading: false,
				goalsSavingsData: action.payload?.data,
				goalSavingsCSV: formattedData
			};
			return state;

		case actions.GET_ALL_GOALS_SAVINGS_FAILED:
			errorReducer(action.payload);
			state = {
				...state,
				goalsSavingsLoading: false,
				goalsSavingsData: [],
				goalSavingsCSV: []
			};
			return state;

		case actions.GET_SAVINGS_METRICS_START:
			state = {
				...state,
				savingsMetricsLoading: true,
				savingsMetricsData: null
			};
			return state;

		case actions.GET_SAVINGS_METRICS_DONE:
			state = {
				...state,
				savingsMetricsLoading: false,
				savingsMetricsData: action.payload
			};
			return state;

		case actions.GET_SAVINGS_METRICS_FAILED:
			errorReducer(action.payload);
			state = {
				...state,
				savingsMetricsLoading: false,
				savingsMetricsData: null
			};
			return state;

		case actions.PAUSE_GOALS_SAVINGS_START:
			state = {
				...state,
				pauseGoalSavingsLoading: true,
			};
			return state;

		case actions.PAUSE_GOALS_SAVINGS_DONE:
			state = {
				...state,
				pauseGoalSavingsLoading: false,
			};
			return state;

		case actions.PAUSE_GOALS_SAVINGS_FAILED:
			errorReducer(action.payload);
			state = {
				...state,
				pauseGoalSavingsLoading: false,
			};
			return state;

		case actions.CANCEL_GOALS_SAVINGS_START:
			state = {
				...state,
				cancelGoalSavingsLoading: true,
			};
			return state;

		case actions.CANCEL_GOALS_SAVINGS_DONE:
			state = {
				...state,
				cancelGoalSavingsLoading: false,
			};
			return state;

		case actions.CANCEL_GOALS_SAVINGS_FAILED:
			errorReducer(action.payload);
			state = {
				...state,
				cancelGoalSavingsLoading: false,
			};
			return state;

		case actions.GET_ALL_FLEX_SAVINGS_START:
			state = {
				...state,
				flexSavingsLoading: true,
				flexSavingsData: [],
			};
			return state;

		case actions.GET_ALL_FLEX_SAVINGS_DONE:
			state = {
				...state,
				flexSavingsLoading: false,
				flexSavingsData: action.payload,
			};
			return state;

		case actions.GET_ALL_FLEX_SAVINGS_FAILED:
			errorReducer(action.payload);
			state = {
				...state,
				flexSavingsLoading: false,
				flexSavingsData: [],
			};
			return state;

		default:
			return state;
	}
}
