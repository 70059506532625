export const GET_ALL_INVOICE = "GET_ALL_INVOICE";
export const GET_ALL_INVOICE_START = "GET_ALL_INVOICE_START";
export const GET_ALL_INVOICE_FAILED = "GET_ALL_INVOICE_FAILED";

export const DOWNLOAD_GET_ALL_INVOICE = "DOWNLOAD_GET_ALL_INVOICE";
export const DOWNLOAD_GET_ALL_INVOICE_START = "DOWNLOAD_GET_ALL_INVOICE_START";
export const DOWNLOAD_GET_ALL_INVOICE_FAILED =
	"DOWNLOAD_GET_ALL_INVOICE_FAILED";

export const GET_INVOICE = "GET_INVOICE";
export const GET_INVOICE_START = "GET_INVOICE_START";
export const GET_INVOICE_FAILED = "GET_INVOICE_FAILED";

export const DOWNLOAD_INVOICE_PDF = "DOWNLOAD_INVOICE_PDF";
export const DOWNLOAD_INVOICE_PDF_START = "DOWNLOAD_INVOICE_PDF_START";
export const DOWNLOAD_INVOICE_PDF_FAILED = "DOWNLOAD_INVOICE_PDF_FAILED";

export const APPROVE_INVOICE = "APPROVE_INVOICE";
export const APPROVE_INVOICE_START = "APPROVE_INVOICE_START";
export const APPROVE_INVOICE_FAILED = "APPROVE_INVOICE_FAILED";

export const REVOKE_APPROVAL_INVOICE = "REVOKE_APPROVAL_INVOICE";
export const REVOKE_APPROVAL_INVOICE_START = "REVOKE_APPROVAL_INVOICE_START";
export const REVOKE_APPROVAL_INVOICE_FAILED = "REVOKE_APPROVAL_INVOICE_FAILED";

export const RELOAD_PAGE_RESET = "RELOAD_PAGE_RESET";

export const GET_ALL_PAYMENT_HISTORY = "GET_ALL_PAYMENT_HISTORY";
export const GET_ALL_PAYMENT_HISTORY_START = "GET_ALL_PAYMENT_HISTORY_START";
export const GET_ALL_PAYMENT_HISTORY_FAILED = "GET_ALL_PAYMENT_HISTORY_FAILED";

export const GET_PAYMENT_HANDLERS_START = "GET_PAYMENT_HANDLERS_START";
export const GET_PAYMENT_HANDLERS_DONE = "GET_PAYMENT_HANDLERS_DONE";
export const GET_PAYMENT_HANDLERS_FAILED = "GET_PAYMENT_HANDLERS_FAILED";

export const GET_SINGLE_HANDLER_START = "GET_SINGLE_HANDLER_START";
export const GET_SINGLE_HANDLER_DONE = "GET_SINGLE_HANDLER_DONE";
export const GET_SINGLE_HANDLER_FAILED = "GET_SINGLE_HANDLER_FAILED";

export const GET_SPLIT_INSTRUCTIONS_START = "GET_SPLIT_INSTRUCTIONS_START";
export const GET_SPLIT_INSTRUCTIONS_DONE = "GET_SPLIT_INSTRUCTIONS_DONE";
export const GET_SPLIT_INSTRUCTIONS_FAILED = "GET_SPLIT_INSTRUCTIONS_FAILED";

export const DOWNLOAD_GET_ALL_PAYMENT_HISTORY =
	"DOWNLOAD_GET_ALL_PAYMENT_HISTORY";
export const DOWNLOAD_GET_ALL_PAYMENT_HISTORY_START =
	"DOWNLOAD_GET_ALL_PAYMENT_HISTORY_START";
export const DOWNLOAD_GET_ALL_PAYMENT_HISTORY_FAILED =
	"DOWNLOAD_GET_ALL_PAYMENT_HISTORY_FAILED";
