import React from 'react'
import Maybe from '../common/Maybe'

const FaceVerificationView = ({isFaceValidationManual, toggleManualFaceValidation, toggleFaceIdReject, Upload, displayDetails, user, detailKeyClass, detailValueClass, toggleFaceIdReset}) => {
    return (
        <div className='w-full'>
            <div className="w-full h-px mt-8 bg-gray-200"></div>
            <div className="font-semibold text-sm mt-10">Face and ID Verification</div>
            <Maybe condition={isFaceValidationManual === false}>
                <div className="mt-5 grid grid-cols-2 gap-x-4">
                    <div className="space-y-5">
                        <div className="my-auto">
                            <div className={detailKeyClass}>KYC Document Type</div>
                            <div className={detailValueClass}>
                                {displayDetails(user?.kycDocumentType)}
                            </div>
                        </div>
                        <div className="my-auto">
                            <div className={detailKeyClass}>KYC Document Check</div>
                            <div className={detailValueClass}>
                                {displayDetails(user?.kycDocumentCheck)}
                            </div>
                        </div>
                        <div className="my-auto">
                            <div className={detailKeyClass}>KYC Liveliness Check</div>
                            <div className={detailValueClass}>
                                {displayDetails(user?.kycLivenessCheck)}
                            </div>
                        </div>
                        <div className="my-auto">
                            <div className={detailKeyClass}>KYC Job Status</div>
                            <div className={detailValueClass}>
                                {displayDetails(user?.kycJobStatus)}
                            </div>
                        </div>
                    </div>
                    <div className="space-y-5">
                        <div className="my-auto">
                            <div className={detailKeyClass}>KYC Job ID</div>
                            <div className={detailValueClass}>
                                {displayDetails(user?.kycJobId)}
                            </div>
                        </div>
                        <div className="my-auto">
                            <div className={detailKeyClass}>KYC Verify Document</div>
                            <div className={detailValueClass}>
                                {displayDetails(user?.kycVerifyDocument)}
                            </div>
                        </div>
                        <div className="my-auto">
                            <div className={detailKeyClass}>KYC Result Text</div>
                            <div className={detailValueClass}>
                                {displayDetails(user?.kycResultText)}
                            </div>
                        </div>
                    </div>
                </div>
            </Maybe>
            
            
            <Maybe condition={isFaceValidationManual === false && user?.accountTier !== 3}>
                <div className="mt-7 flex items-center justify-between">
                    <Maybe condition={user?.kycJobId?.length > 0}>
                        <button onClick={toggleFaceIdReject} className="btn btn-md border border-ep-danger text-ep-danger rounded-[4px] flex items-center w-max px-7">
                            Reject
                        </button>
                    </Maybe>
                    
                    <button onClick={toggleManualFaceValidation} className="btn btn-md btn-transparent-primary rounded-[4px] flex items-center whitespace-nowrap">
                        Manual Update
                        <span className="ml-2"><Upload width={15} /></span>
                    </button>
                    
                    <Maybe condition={user?.kycJobStatus === "in-review" || user?.kycJobStatus === "attempted"}>
                        <button onClick={toggleFaceIdReset} className="btn btn-md border border-ep-danger text-ep-danger rounded-[4px] flex items-center w-max px-7">
                            Reset
                        </button>
                    </Maybe>
                </div>
            </Maybe>
        </div>
    )
}

export default FaceVerificationView