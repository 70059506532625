import React, { Component } from 'react';
import store from "store/store";
import Joi from "joi-browser";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as auth from "store/auth/user/action";


class ResetPassword extends Component {
    state = { 
        resetPasswordForm: {
            email: "",
            pin: "",
            password: "",
            confirmPassword: "",
        },
        errors: {}
    }

    formChange = (e) => {
        const formValue = {...this.state.resetPasswordForm};
        formValue[e.currentTarget.name] = e.currentTarget.value;
        this.setState({resetPasswordForm: formValue});
    }
    
    schema = {
        email: Joi.string().email().required().label("Email Address").error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    case "any.empty":
                        err.message = "Unable to process your email address";
                        break;
                    case "string.email":
                        err.message = "Unable to process email address";
                        break;
                    default:
                        break;
                }
            });
            return errors;
        }),
        pin: Joi.number().integer().required().label("Reset Pin").error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    case "number.base":
                        err.message = `Please enter your password reset pin, sent to your email address`;
                        break;
                    case "number.integer":
                        err.message = `Please enter a valid password reset pin, sent to your email address`;
                        break;
                    default:
                        break;
                }
            });
            return errors;
        }),
        password: Joi.string().required().label("Password").error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    case "any.empty":
                        err.message = "Please enter your password";
                        break;
                    default:
                        break;
                }
            });
            return errors;
        }),
        confirmPassword: Joi.any().valid(Joi.ref('password')).label("Confirm Password").error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    case "any.allowOnly":
                        err.message = "Your password does not match";
                        break;
                    default:
                        break;
                }
            });
            return errors;
        }),
    }

    validate = () => {
        const options = { abortEarly: false };
        const result = Joi.validate(this.state.resetPasswordForm, this.schema, options);
        // console.log(result);
        const error = result.error;
        if (!error) return null;
    
        const errors = {};
        for (let item of result.error.details){
            errors[item.path[0]] = item.message;
        }
        return errors;
    };

    submitForm = (e) => {
        e.preventDefault();
        const errors = this.validate();
        this.setState({errors: errors || {}});
        if (errors){
            // console.log(errors);
            for (var x in errors) {
                toast.error(errors[x]);
            };
            return;
        }
        else{
            // alert('all good');
            // console.log(this.state.resetPasswordForm);
            this.props.resetPassword(this.state.resetPasswordForm);
        }
    }
    
    resendMail = (e) => {
        e.preventDefault();
        const forgotPasswordForm = {email: this.state.resetPasswordForm.email};
        this.props.forgotPassword(forgotPasswordForm);
    }

    componentDidMount() {
        const currentForm = {...this.state.resetPasswordForm, ...this.props.forgotPasswordForm};
        this.setState({resetPasswordForm: currentForm});

        this.unsubscribe = store.subscribe(() => {
            if (store.getState().auth.user.resetPassword === true){
                this.props.history.push("/reset-password/success");
                this.props.resetResetPassword();
            }
        });
    }

    componentWillUnmount() {
        this.unsubscribe();
    }

    render() { 
        const {resetPasswordForm} = this.state;

        return (
            <div className="p-8 pb-14 sm:p-14 md:pt-20">
                <div className="lg:pl-4 lg:pr-4">
                    
                    <div className="font-boing font-semibold text-2xl sm:text-3xl text-center md:text-left">
                        Reset Password
                    </div>

                    <div>
                        <div>

                            <div className="mt-10 w-full max-w-md mx-auto md:mx-0 lg:w-96">

                                <div className="note mb-4 text-center hidden">
                                    A password reset pin has been sent to your email address
                                </div>
                                
                                <form onSubmit={this.submitForm}>

                                    <div className="form-group">
                                        <label>
                                            Email Address
                                            <span className="form-input-required">*</span>
                                        </label>
                                        <input type="email" className="form-input" value={resetPasswordForm.email} disabled />
                                    </div>

                                    <div className="form-group">
                                        <label>
                                            Reset PIN
                                            <span className="form-input-required">*</span>
                                        </label>
                                        <input type="number" name="pin" className="form-input" value={resetPasswordForm.pin} onChange={this.formChange} />
                                    </div>

                                    <div className="form-group">
                                        <label>
                                            Password
                                            <span className="form-input-required">*</span>
                                        </label>
                                        <input type="password" name="password" placeholder="XXXXXXXXXX" autoComplete="off" className="form-input" value={resetPasswordForm.password} onChange={this.formChange} />
                                    </div>

                                    <div className="form-group">
                                        <label>
                                            Confirm Password
                                            <span className="form-input-required">*</span>
                                        </label>
                                        <input type="password" name="confirmPassword" placeholder="XXXXXXXXXX" autoComplete="off" className="form-input" value={resetPasswordForm.confirmPassword} onChange={this.formChange} />
                                    </div>

                                    <button type="submit" className="btn btn-lg btn-block btn-ep-blue mt-8" disabled={this.props.resetPasswordLoading}>
                                        <FontAwesomeIcon icon="spinner" spin className={"text-xl mr-2 " + (this.props.resetPasswordLoading?"inline":"hidden")} />
                                        Reset Password
                                    </button>

                                </form>


                                <form onSubmit={this.resendMail}>
                                    <button type="submit" className="btn btn-block border-2 border-gray-300 mt-3 hover:bg-gray-300" disabled={this.props.loading}>
                                        <FontAwesomeIcon icon="spinner" spin className={"text-xl mr-2 " + (this.props.loading?"inline":"hidden")} />
                                        Resend Mail
                                    </button>
                                </form>

                                <div className="mt-3 text-sm font-semibold text-gray-500">
                                    Already reset your password? &nbsp;
                                    <Link to="/login" className="text-ep-blue hover:underline">Login</Link>
                                </div>

                            </div>

                        </div>
                    </div>

                </div>
            </div>
        )
    }
}
 


const mapStateToProps = (state) => ({
    loading: state.auth.user.loading,
    forgotPassword: state.auth.user.forgotPassword,
    forgotPasswordForm: state.auth.user.forgotPasswordForm,
    resetPasswordLoading: state.auth.user.resetPasswordLoading,
    resetPassword: state.auth.user.resetPassword,
});

const mapDispatchToProps = (dispatch) => ({
    forgotPassword: (params) => dispatch(auth.forgotPassword(params)),
    resetPassword: (params) => dispatch(auth.resetPassword(params)),
    resetResetPassword: () => dispatch(auth.resetResetPassword()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);