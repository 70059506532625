import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Maybe from 'components/__new/common/Maybe';
import { ReactComponent as IconCopy } from "assets/images/icons/project-icons/copy.svg";
import React from 'react'
import DateFormat from 'utils/dateFormat';
import NumberFormat from 'utils/numberFormat';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { selectCustomer, getSingleCustomer, generateVirtualAccount } from 'store/entities/employees/action';
import DropdownNew from 'utils/dropdown-new';
import Pagination from 'components/common/pagination';
import { Link, useLocation, useHistory } from 'react-router-dom';

const CustomersTable = ({toggleDetails, usersParams, allCustomersLoading, refinedData, toggleCustomerStatusLoading, toggleActivateModal, toggleDeactivateModal, fetchCustomers}) => {
    
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
        
    const viewUserTransactions = (user) => {
            dispatch(selectCustomer(user)); 
            history.push(`/users/customers/transactions/${user?.firstName}-${user?.lastName}`);
    }
        
    const viewLoanHistory = (user) => {
            dispatch(selectCustomer(user)); 
            history.push(`/users/customers/loan-history/${user?.firstName}-${user?.lastName}`);
    }
    
    const copyItem = (item, tag) => {
		navigator.clipboard.writeText(item);
		toast(`${tag} Copied`)
	}
    
    const generateEva = (user) => { 
        toast("Generating...");
        dispatch(generateVirtualAccount({userId: user?._id, type: "individual"})).then(() => fetchCustomers());
    }
    
    return (
        <div className="mt-2">
            <div className="table-container">
                <table className="table table-auto table-rounded table-border">
                    <thead>
                        <tr>
                            <th>Date Joined</th>
                            <th>Date Updated</th>
                            <th>Name</th>
                            <th>Email address</th>
                            <th>User ID</th>
                            <th>Phone No.</th>
                            <th>Points</th>
                            <th>User Status</th>
                            <th>Tier</th>
                            <th>KYC Level</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        
                        {(allCustomersLoading === true || toggleCustomerStatusLoading === true) && 
                            <tr>
                                <td colSpan="11">
                                    <div className="table-info">
                                        <FontAwesomeIcon icon="spinner" spin />
                                        <div className="font-bold uppercase">
                                            Loading
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        }

                        {((allCustomersLoading === false) && (!refinedData.docs)) && 
                            <tr>
                                <td colSpan="11">
                                    <div className="table-info">
                                        <FontAwesomeIcon icon="unlink" />
                                        <div className="font-bold uppercase">
                                            An error occurred
                                            <br />
                                            Please try again later.
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        }
                        
                        {((allCustomersLoading === false) && (refinedData.docs) && (refinedData.docs.length <= 0)) && 
                            <tr>
                                <td colSpan="11">

                                    {(refinedData.page <= refinedData.totalPages || refinedData?.docs?.length < 1) &&
                                        <div className="table-info">
                                            <FontAwesomeIcon icon="list" />
                                            <div className="font-bold uppercase">
                                                No results found
                                            </div>
                                        </div>
                                    }
                                    
                                    {(refinedData.page > refinedData.totalPages && refinedData?.docs?.length > 0) &&
                                        <div className="table-info">
                                            <FontAwesomeIcon icon="exclamation-triangle" />
                                            <div className="font-bold uppercase">
                                                Invalid Page Number
                                            </div>
                                            <div className="mt-2">
                                                <Link to="?page=1" className="btn btn-ep-blue btn-sm mx-auto">Goto Page 1</Link>
                                            </div>
                                        </div>
                                    }

                                </td>
                            </tr>
                        }
                        
                        {((allCustomersLoading === false && toggleCustomerStatusLoading === false) && (refinedData.docs) && (refinedData.docs.length > 0)) && 
                            refinedData.docs.map((user, index) => {

                                return (
                                    <tr key={index}>
                                        <td>
                                            <div>
                                                {DateFormat(user?.createdAt?.split("T")[0])}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='whitespace-nowrap'>
                                                {DateFormat(user?.updatedAt?.split("T")[0])}
                                            </div>
                                        </td>
                                        <td>
                                            <div className="flex items-center">
                                                <div className="capitalize">
                                                    {user?.firstName ?? "-"} {user?.lastName}
                                                </div>
                                                <Maybe condition={user?.employeeId && user?.employeeId !== ""}>
                                                    <div className="ml-2 label label-gray label-sm">
                                                        Employee
                                                    </div>
                                                </Maybe>
                                            </div>
                                        </td>
                                        <td>{user?.email || "-"}</td>
                                        <td>
                                            <div className='flex items-center'>
                                                {user?._id?.substring(0, 8) || "-"}
                                                <span onClick={() => copyItem(user?._id, "User ID")} className="ml-1 cursor-pointer">
                                                    <IconCopy />
                                                </span>
                                            </div>
                                        </td>
                                        <td>{user?.phoneNumber || "-"}</td>
                                        <td>{NumberFormat(user?.points || "0")}</td>
                                        <td>
                                            <UserStatusBadge user={user} />
                                        </td>
                                        <td>
                                            <UserTierBadge 
                                                status={user?.kycStatus} 
                                                accountTier={user?.accountTier} 
                                            />
                                        </td>
                                        <td>
                                            <div className="flex items-center justify-center">
                                                {user?.kycLevel}/3
                                            </div>
                                        </td>
                                        <td>
                                            <DropdownNew>
                                                <></>
                                                <div>
                                                    <div onClick={() => {dispatch(selectCustomer(user)); dispatch(getSingleCustomer(user)); toggleDetails();}} className="dropdown-item dropdown-item-icon">
                                                        View Details
                                                    </div>
                                                    <div onClick={() => viewLoanHistory(user)} className="dropdown-item dropdown-item-icon">
                                                        View Loan History
                                                    </div>
                                                    <div onClick={() => viewUserTransactions(user)} className="dropdown-item dropdown-item-icon">
                                                        View Transactions
                                                    </div>
                                                    <div onClick={() => generateEva(user)} className="dropdown-item dropdown-item-icon">
                                                        Generate EVA
                                                    </div>
                                                    <Maybe condition={user?.disabled === true && user?.isActive === false}>
                                                        <div onClick={() => {dispatch(selectCustomer(user)); toggleActivateModal();}} className="dropdown-item dropdown-item-icon">
                                                            Activate Customer
                                                        </div>
                                                    </Maybe>
                                                    <Maybe condition={(user?.isActive === true && user?.disabled === false) || (user?.isActive === false && user?.disabled === false)}>
                                                        <div onClick={() => {dispatch(selectCustomer(user)); toggleDeactivateModal();}} className="dropdown-item dropdown-item-icon text-ep-danger">
                                                            Deactivate Customer
                                                        </div>
                                                    </Maybe>
                                                </div>
                                            </DropdownNew>
                                        </td>
                                    </tr>
                                )
                                }
                            )
                        }
                    </tbody>
                </table>
            </div>

            {((allCustomersLoading === false) && (refinedData.docs) && (refinedData.docs.length > 0)) && 
                <div>
                    <Pagination data={refinedData} url={location.search} limit={usersParams.limit} />
                </div>
            }
    </div>
    )
}

const UserStatusBadge = ({user}) => {
    const isActive = user?.isActive === true && user?.disabled === false;
    const isInactive = user?.isActive === false && user?.disabled === false;
    const isDeactivated = user?.isActive === false && user?.disabled === true;
    
    return (
        <div className={`label ${isActive ? "bg-ep-primary-light text-ep-primary-600" : isDeactivated ? "bg-[#FEE2E2] text-ep-danger" : isInactive ? "bg-[#F3F4F6] text-[#718096]" : ""}`}>
            {isActive ? "Active" : isDeactivated ? "Deactivated" : isInactive ? "Inactive" : ""}
        </div>
    )
}

const UserTierBadge = ({accountTier}) => {
    
    const tierZero = !accountTier || accountTier === 0
    const tierOne = accountTier === 1
    const tierThree = accountTier === 3
    
    return (
        <div className={`label capitalize text-ep-primary-600 ${tierZero ? "bg-[#F3F4F6] text-[#718096]" : tierOne ? "bg-[#FDDFB4]" : tierThree ? "bg-ep-primary-light" : ""}`}>
            Tier {accountTier ?? 0}
        </div>
    )
}

export default CustomersTable