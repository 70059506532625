import * as actions from "./actionTypes";
import { toast } from 'react-toastify';

const initialState = {
    dashboardWithdrawalsLoading: false,
    dashboardWithdrawals: {},
    loadingDashboardPerformanceRevenue: false,
    dashboardPerformanceRevenue: {},
    loadingDashboardPerformanceRepayment: false,
    dashboardPerformanceRepayment: {},
    dashboardChartsLoading: false,
    dashboardCharts: {},
    companyStatsLoading: false,
    companyStats: {},
    withdrawalMonthStatsLoading: false,
    withdrawalMonthStats: {},
};


export default function reducer(state = initialState, action) {
    switch (action.type) {


        case actions.GET_ALL_DASHBOARD:
            state = {
                ...state,
                dashboardWithdrawalsLoading: false,
                dashboardWithdrawals: {...action.payload}
            }
            return state;

           
        case actions.GET_ALL_DASHBOARD_START:
            state = {
                ...state,
                dashboardWithdrawalsLoading: true,
            }
            return state;


        case actions.GET_ALL_DASHBOARD_FAILED:
            toast.error(action.payload.message);
            state = {
                ...state,
                dashboardWithdrawalsLoading: false,
                dashboardWithdrawals: {},
            }
            return state;


        case actions.GET_DASHBOARD_PERFORMANCE_REVENUE:
            state = {
                ...state,
                loadingDashboardPerformanceRevenue: false,
                dashboardPerformanceRevenue: { ...action.payload }
            }
            return state;


        case actions.GET_DASHBOARD_PERFORMANCE_REVENUE_START:
            state = {
                ...state,
                loadingDashboardPerformanceRevenue: true,
            }
            return state;


        case actions.GET_DASHBOARD_PERFORMANCE_REVENUE_FAILED:
            toast.error(action.payload.message);
            state = {
                ...state,
                loadingDashboardPerformanceRevenue: false,
                dashboardPerformanceRevenue: {},
            }
            return state;


        case actions.GET_DASHBOARD_PERFORMANCE_REPAYMENT:
            state = {
                ...state,
                loadingDashboardPerformanceRepayment: false,
                dashboardPerformanceRepayment: { ...action.payload }
            }
            return state;


        case actions.GET_DASHBOARD_PERFORMANCE_REPAYMENT_START:
            state = {
                ...state,
                loadingDashboardPerformanceRepayment: true,
            }
            return state;


        case actions.GET_DASHBOARD_PERFORMANCE_REPAYMENT_FAILED:
            toast.error(action.payload.message);
            state = {
                ...state,
                loadingDashboardPerformanceRepayment: false,
                dashboardPerformanceRepayment: {},
            }
            return state;


        case actions.GET_DASHBOARD_CHARTS:
            state = {
                ...state,
                dashboardChartsLoading: false,
                dashboardCharts: { ...action.payload.data },
            }
            return state;


        case actions.GET_DASHBOARD_CHARTS_START:
            state = {
                ...state,
                dashboardChartsLoading: true,
            }
            return state;


        case actions.GET_DASHBOARD_CHARTS_FAILED:
            toast.error(action.payload.message);
            state = {
                ...state,
                dashboardChartsLoading: false,
                dashboardCharts: {},
            }
            return state;


        case actions.GET_COMPANY_STATS:
            state = {
                ...state,
                companyStatsLoading: false,
                companyStats: { ...action.payload.data },
            }
            return state;


        case actions.GET_COMPANY_STATS_START:
            state = {
                ...state,
                companyStatsLoading: true,
            }
            return state;


        case actions.GET_COMPANY_STATS_FAILED:
            toast.error(action.payload.message);
            state = {
                ...state,
                companyStatsLoading: false,
                companyStats: {},
            }
            return state;


        case actions.GET_WITHDRAWAL_MONTH_STATS:
            state = {
                ...state,
                withdrawalMonthStatsLoading: false,
                withdrawalMonthStats: { ...action.payload.data },
            }
            return state;


        case actions.GET_WITHDRAWAL_MONTH_STATS_START:
            state = {
                ...state,
                withdrawalMonthStatsLoading: true,
            }
            return state;


        case actions.GET_WITHDRAWAL_MONTH_STATS_FAILED:
            toast.error(action.payload.message);
            state = {
                ...state,
                withdrawalMonthStatsLoading: false,
                withdrawalMonthStats: {},
            }
            return state;


        default:
            return state;
    }
}