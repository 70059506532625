import errorReducer from "utils/errorReducer";
import * as actions from "./actionTypes";

const initialState = {
	businessMetricsLoading: false,
	businessMetrics: {},

	businessesLoading: false,
	businesses: {},	

	businessesDownloadLoading: false,
	businessesDownload: {},	

	businessLoading: false,
	business: {},

	complianceApprovalLoading: false,
	complianceApproval: {},
	complianceRejectionLoading: false,
	complianceRejection: {},
};

export default function reducer(state = initialState, action) {
	switch (action.type) {
		
		case actions.GET_BUSINESSES_METRICS:
			state = {
				...state,
				businessMetricsLoading: false,
				businessMetrics: action.payload,
			};
			return state;

		case actions.GET_BUSINESSES_METRICS_START:
			state = {
				...state,
				businessMetricsLoading: true,
				businessMetrics: {},
			};
			return state;

		case actions.GET_BUSINESSES_METRICS_FAILED:
			errorReducer(action.payload);
			state = {
				...state,
				businessMetricsLoading: false,
				businessMetrics: {},
			};
			return state;

		case actions.GET_BUSINESSES:
			state = {
				...state,
				businessesLoading: false,
				businesses: action.payload,
			};
			return state;

		case actions.GET_BUSINESSES_START:
			state = {
				...state,
				businessesLoading: true,
				businesses: {},
			};
			return state;

		case actions.GET_BUSINESSES_FAILED:
			errorReducer(action.payload);
			state = {
				...state,
				businessesLoading: false,
				businesses: {},
			};
			return state;

		case actions.DOWNLOAD_GET_BUSINESSES:
			state = {
				...state,
				businessesDownloadLoading: false,
				businessesDownload: action.payload,
			};
			return state;

		case actions.DOWNLOAD_GET_BUSINESSES_START:
			state = {
				...state,
				businessesDownloadLoading: true,
				businessesDownload: {},
			};
			return state;

		case actions.DOWNLOAD_GET_BUSINESSES_FAILED:
			errorReducer(action.payload);
			state = {
				...state,
				businessesDownloadLoading: false,
				businessesDownload: {},
			};
			return state;

		case actions.GET_BUSINESS:
			state = {
				...state,
				businessLoading: false,
				business: action.payload,
			};
			return state;

		case actions.GET_BUSINESS_START:
			state = {
				...state,
				businessLoading: true,
				business: {},
			};
			return state;

		case actions.GET_BUSINESS_FAILED:
			errorReducer(action.payload);
			state = {
				...state,
				businessLoading: false,
				business: {},
			};
			return state;

		case actions.APPROVE_COMPLIANCE:
			state = {
				...state,
				complianceApprovalLoading: false,
				complianceApproval: action.payload,
			};
			return state;

		case actions.APPROVE_COMPLIANCE_START:
			state = {
				...state,
				complianceApprovalLoading: true,
				complianceApproval: {},
			};
			return state;

		case actions.APPROVE_COMPLIANCE_FAILED:
			errorReducer(action.payload);
			state = {
				...state,
				complianceApprovalLoading: false,
				complianceApproval: {},
			};
			return state;

		case actions.APPROVE_COMPLIANCE_RESET:
			state = {
				...state,
				complianceApprovalLoading: false,
				complianceApproval: {},
			};
			return state;

		case actions.REJECT_COMPLIANCE:
			state = {
				...state,
				complianceRejectionLoading: false,
				complianceRejection: action.payload,
			};
			return state;

		case actions.REJECT_COMPLIANCE_START:
			state = {
				...state,
				complianceRejectionLoading: true,
				complianceRejection: {},
			};
			return state;

		case actions.REJECT_COMPLIANCE_FAILED:
			errorReducer(action.payload);
			state = {
				...state,
				complianceRejectionLoading: false,
				complianceRejection: {},
			};
			return state;

		case actions.REJECT_COMPLIANCE_RESET:
			state = {
				...state,
				complianceRejectionLoading: false,
				complianceRejection: {},
			};
			return state;

		default:
			return state;

	}
}
