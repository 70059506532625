import React, { useRef, useState } from 'react'
import Maybe from '../common/Maybe'
import { Link } from 'react-router-dom'
import { ReactComponent as Xclose } from "assets/images/icons/project-icons/xclose.svg";
import { ReactComponent as IconApproved } from "assets/images/icons/project-icons/onboarding-approved.svg";
import { ReactComponent as IconPending } from "assets/images/icons/project-icons/pending-circle.svg";
import { ReactComponent as IconAttach } from "assets/images/icons/project-icons/attachment.svg";
import { ReactComponent as IconSend } from "assets/images/icons/project-icons/send.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const approverColor = "#DA52CC"
const requesterColor = "#D9AD10"


const ApprovalDetails = ({control, onClose, onApprove, onReject}) => {
    const fileRef = useRef();
    const [comment, setComment] = useState("");
    
    const detailKeyClass = "text-xs";
    const detailValueClass = "mt-1 font-medium capitalize text-sm";
    const dummyLink = "https://media-files-staging.s3.eu-west-1.amazonaws.com/_1701170910064.pdf";
    
    // const getInitials = (name) => {
	// 	const nameArr = name.split(" ");
	// 	const newNameArr = nameArr.filter((item) => item.length > 0);
	// 	const firstInitial = newNameArr?.[0]?.[0]?.toUpperCase() || '';
	// 	const secondInitial = newNameArr?.[1]?.[0]?.toUpperCase() || '';
	// 	return firstInitial + secondInitial;
	// };
    
    // const displayDetails = (details) => { 
    //     // if (getEmployerLoading === true) {
    //     //     return <FontAwesomeIcon icon="spinner" spin className="text-md" />;
    //     // }
    //     return details;
    // }
    
    const attachFile = () => fileRef.current.click();
    
    const proceedWithComment = () => { 
        // call endpoint to submit comment
    }
    
    return (
        <div
        className={
            "max-w-md w-screen h-screen bg-white fixed z-50 top-0 bottom-0 shadow-xl transition-all duration-300 ease-in-out " +
            (control ? "right-0" : "-right-full")
        }>
        <div className="w-full h-full p-5 pb-0 pl-0 relative overflow-auto overscroll-contain">
            {/* {Object.keys(employerDetails).length > 0 && ( */}
                <div>
                    <div className="pl-5">
                        <div className="flex justify-between">
                            <div className="font-recoleta font-semibold text-2xl capitalize mb-2">
                                {/* {displayDetails(employer?.employer?.company?.companyName)} */}
                                Approve Bolt Ride Bundle
                            </div>
                            <div
                                onClick={onClose}
                                className="w-8 h-8 flex group hover:bg-red-400 rounded-lg cursor-pointer">
                                <Xclose className="customizeSVG group-hover:text-white m-auto" />
                            </div>
                        </div>
                        
                        <div className="text-sm text-fade mt-1">
                            Requester's Name:&nbsp;
                            <span className="text-sm text-black">
                                {/* {displayDetails(employer?.employer?.email)} */}
                                Chioma Nwadiko
                            </span>
                        </div>
                        
                        <div className="text-sm text-fade mt-3">
                            Date Requested:&nbsp;
                            <span className="text-sm text-black">
                                {/* {displayDetails(employer?.employer?.email)} */}
                                24/11/2023, 2:05pm
                            </span>
                        </div>
{/*                         
                        <div className="my-3 text-sm">
                            Business Type: &nbsp;
                            <span className="text-ep-primary-500 capitalize">
                                {
                                    employer?.employer?.company?.companyType?.includes("freelance") ? 
                                    "Un-registered business" : 
                                    employer?.employer?.company?.companyType || "-"
                                }
                            </span>
                        </div> */}
                        
                        {/* <div className="mt-2 flex space-x-4">
                            <div className="flex h-6">
                                {employer?.employer?.status?.length && (
                                    <>
                                        <div
                                            className="w-2 h-2 my-auto mr-2 rounded-full"
                                            style={{ backgroundColor: approvalIsActive ? "#398D94" : approvalIsPending ? "#F49D1B" : approvalIsRejected ? "#DC2626" : "" }}></div>
                                        <div className="my-auto text-xs">{approvalIsActive ? "Active" : approvalIsPending ? "Pending" : approvalIsRejected ? "Rejected" : "-"}</div>
                                    </>
                                )}
                            </div>
                            <Link
                                to={"/employees?employerId=" + employer?.employer?._id}
                                className="btn btn-sm bg-gray-200 btn-gray-hover">
                                View Employees
                            </Link>
                        </div> */}
                        
                        <div className="flex items-center space-x-3 mt-7">
                            <ActionButton isSuccess onClick={onApprove} />
                            <ActionButton onClick={onReject} />
                        </div>
                    </div>

                    <div className="w-full h-px my-7 bg-gray-200"></div>
                    <div className="my-7 pb-5 pl-5">
                            <div className="font-semibold text-xs mb-5">
                                Request Details
                            </div>
                            
                            <Maybe condition={false}>
                                <div className="h-20 flex flex-col items-center justify-center">
                                    <FontAwesomeIcon icon="spinner" spin className="text-md" />
                                    <div className={detailValueClass}>
                                        Fetching Business Details...
                                    </div>
                                </div>
                            </Maybe>
                            
                            <div className="grid grid-cols-2 gap-x-2">
                                <div className="space-y-7">
                                    <div className="my-auto">
                                        <div className={detailKeyClass}>
                                            Requester's Name
                                        </div>
                                        <div className={detailValueClass}>
                                            {/* {employer?.employer?.company?.companyName || "-"} */}
                                            Chioma Nwadiko
                                        </div>
                                    </div>

                                    <div className="my-auto">
                                        <div className={detailKeyClass}>
                                            Status
                                        </div>
                                        <div style={{color: "#F49D1B"}} className={detailValueClass}>
                                            {/* {employer?.employer?.company?.industry || "-"} */}
                                            Pending
                                        </div>
                                    </div>
                                    
                                    <div className="my-auto">
                                        <div className={detailKeyClass}>
                                            Reason for Request
                                        </div>
                                        <div className={detailValueClass}>
                                            {/* {employer?.employer?.company?.address || "-"} */}
                                            Approval Needed
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="space-y-7">
                                <div className="my-auto">
                                    <div className={detailKeyClass}>
                                        Request Creation Date
                                    </div>
                                    <div className={detailValueClass}>
                                        {/* {employeeGroups[(employer?.employer?.company?.companySize)] || "-"} */}23/11/2023, 2:05pm
                                    </div>
                                </div>
                                
                                <div className="my-auto">
                                    <div className={detailKeyClass}>
                                        Due Date
                                    </div>
                                    <div className={detailValueClass}>
                                        {/* {employeeGroups[(employer?.employer?.company?.companySize)] || "-"} */}23/11/2023
                                    </div>
                                </div>

                                    <div className="my-auto">
                                        <div className={detailKeyClass}>
                                            Attachments
                                        </div>
                                        <div style={{color: "#00535C"}} className={detailValueClass}>
                                            {/* {employer?.employer?.phoneNumber || "-"} */}
                                            <Link to={{ pathname: dummyLink }} target="_blank">
                                                Bolt.pdf
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>

                    <div className="w-full h-px my-7 bg-gray-200"></div>
                    <div className="my-7 pl-5">
                        <div className="font-semibold text-xs mb-5">
                            Workflow
                        </div>
                        
                        <div style={{height: "125px"}} className="w-full relative pl-5">
                            <div className="absolute top-1.5 -left-2 z-10">
                                <IconApproved />
                            </div>
                            
                            <div className="absolute bottom-3.5 -left-2.5 z-10">
                                <IconPending />
                            </div>
                            <div style={{height: "90px", backgroundColor: "#E5E5EF"}} className="absolute w-0.5 left-0 top-3" />
                            
                            <div className="w-3/5 flex items-center justify-between mb-10">
                                <div>
                                    <div className={detailKeyClass}>
                                        17th Mar
                                    </div>
                                    <div className="text-fade">
                                        3:07 PM
                                    </div>
                                </div>
                                <div className="flex items-center justify-start w-3/5">
                                    <NameTag initials="CN" />
                                    <div>
                                        <div className={detailKeyClass}>
                                            Chioma Nwadiko
                                        </div>
                                        <div className="text-fade">
                                            Requester
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="w-3/5 flex items-center justify-between mb-7">
                                    <div className="text-fade">
                                        Pending
                                    </div>
                                <div className="flex items-center justify-start w-3/5">
                                    <NameTag initials="LO" />
                                    <div>
                                        <div className={detailKeyClass}>
                                            Laolu Oyedele
                                        </div>
                                        <div className="text-fade">
                                            Approver
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="w-full h-px my-7 bg-gray-200"></div>
                    <div className="mt-7 pb-5 pl-5 w-full">
                        <div className="font-semibold text-xs mb-5">
                            Activity Feed
                        </div>
                        
                        <div className="flex items-center mb-6">
                            <NameTag isRequester initials="CN" /> Chioma Nwadiko&nbsp; <span className="text-fade text-xs"> made this request &#8226; 20min ago</span>
                        </div>
                        
                        <div className="w-full">
                            <div className="flex items-center">
                                <NameTag initials="LO" /> Laolu Oyedele&nbsp; <span className="text-fade text-xs"> left a comment &#8226; 3min ago</span>
                            </div>
                            <div className="text-xs mt-2">
                                Please recheck the document you attached. A correction is needed.
                            </div>
                        </div>
                    </div>
                    
                    
                    <div className="p-5 pb-0 pr-0 relative">
                        <div className="form-group">
                            <label>
                                Leave a comment
                            </label>
                            <textarea
                                placeholder='Enter your comment...'
                                value={comment}
                                style={{ height: "120px", resize: "none" }}
                                onChange={(e) => setComment(e.target.value)}
                                className="p-2 w-full border border-[#D1D5DB] rounded-md focus:ring-0 outline-none"
                            />
                        </div>
                        
                        <div className="absolute bottom-4 right-4 flex items-center">
                            <input type="file" style={{display: "none"}} ref={fileRef} />
                            <IconAttach className='cursor-pointer mr-3' onClick={attachFile} />
                            
                            <div className={`cursor-pointer border border-[#D7E8E9] p-1.5 ${comment?.length < 10 && "cursor-not-allowed"}`}>
                                <IconSend onClick={proceedWithComment} />
                            </div>
                        </div>
                    </div>
                    
                    {/* <div style={{margin: "20px"}} className="px-5 py-3 flex items-center justify-between w-full border border-ep-primary-500">
                        asdfasdfsdf.jpg
                        
                        <Xclose className="customizeSVG cursor-pointer" />
                    </div> */}
                </div>
        </div>
    </div>
    )
}

const NameTag = ({initials, isRequester}) => (
    <div style={{backgroundColor: isRequester ? requesterColor : approverColor, height: "30px", width: "30px"}} className="mr-2 text-xs rounded-full flex items-center justify-center text-white font-semibold">
        {initials}
    </div>
)

const ActionButton = ({onClick, isSuccess}) => (
    <button onClick={onClick} style={{color: isSuccess ? "#00B35C" : "#EF4444", border: `1px solid ${isSuccess ? "#CFEAED" : "#FEE2E2"}`}} 
        className="w-max py-2 px-3 rounded-md flex items-center justify-center border border-[#00B35C]">
        <FontAwesomeIcon icon={`${isSuccess ? "check" : "times"}`} /> <span className="ml-2 textsm">{isSuccess ? "Approve" : "Reject"}</span>
    </button>
)

export default ApprovalDetails