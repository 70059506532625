import React, { Component } from 'react';
import DashboardBoxes from 'components/common/dashboard-boxes';
import CurrencyFormat from "utils/currencyFormat";
import queryString from 'query-string';
import Pagination from 'components/common/pagination';
import { DateTimeFormat, DateMiniFormat, TimeFormat } from "utils/dateFormat";
import Dropdown from "utils/dropdown";
import { NavLink } from "react-router-dom";
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { connect } from "react-redux";
import { customizeSVG } from 'utils/customizeSVG.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactComponent as IconWallet } from "assets/images/icons/project-icons/Wallet-Solid.svg";
import { ReactComponent as IconMoneyBag } from "assets/images/icons/project-icons/Money.svg";
import { ReactComponent as IconNav } from "assets/images/icons/project-icons/Navicon-v.svg";
import { ReactComponent as Xclose } from "assets/images/icons/project-icons/xclose.svg";
import * as employees from "store/entities/employees/action";
import * as withdrawals from "store/entities/withdrawals/action";

const dropdownTableIcon = <IconNav className="w-8 h-8 p-1.5 relative top-1 cursor-pointer group-hover:bg-gray-200 rounded" />;

class Wallet extends Component {
    constructor(props) {
        super(props);
        this.completeButtonRef = React.createRef(null);
    }

    state = {
        employeeId: "",
        getAllWithdrawalsParams: {
            page: 1,
            limit: 20,
            status: "",
        },
        dashboardProps1: {
            iconBg: "bg-blue-200",
            icon: <IconWallet className="customizeSVG text-blue-600 m-auto w-5 h-5" />,
            titleText: "Wallet Balance",
            mainText: "-"
        },
        dashboardProps2: {
            iconBg: "bg-green-200",
            icon: <IconMoneyBag className="customizeSVG text-green-600 m-auto w-5 h-5" />,
            titleText: "Saving Balance",
            mainText: "-"
        },
        toggleModalDetails: false,
        walletDetails: "",
    }


    goBack = () => {
        this.props.history.goBack();
    }


    getPageContent = () => {
        if ("id" in this.props.match.params){
            const {id} = this.props.match.params;
            this.props.getEmployeeDetails(id);
            this.getWalletByEmployeeId(id);
            this.setState({employeeId: id});
        }
    }


    getWalletByEmployeeId = (employeeId) => {
        const qs = queryString.parse(this.props.location.search);
        let getAllWithdrawalsParams = {...this.state.getAllWithdrawalsParams};
        
        if ((qs.page !== undefined) && (!isNaN(qs.page)) && (qs.page > 0)){
            getAllWithdrawalsParams = {...getAllWithdrawalsParams, page: qs.page};
        }
        else{
            getAllWithdrawalsParams = {...getAllWithdrawalsParams, page: 1};
        }
        
        if ((qs.limit !== undefined) && (!isNaN(qs.limit)) && (qs.limit > 0)){
            getAllWithdrawalsParams = {...getAllWithdrawalsParams, limit: qs.limit};
        }
        else{
            getAllWithdrawalsParams = {...getAllWithdrawalsParams, limit: 20};
        }

        // getAllWithdrawalsParams = {...getAllWithdrawalsParams, startDate: "2022-01-01", endDate: "2022-08-02"};
        
        this.setState({getAllWithdrawalsParams});
        this.props.getWalletByEmployeeId(employeeId, getAllWithdrawalsParams);
    }


    viewDetails = (wallet = {}) => {
        // console.log(withdrawal);
        const toggleModalDetails = this.state.toggleModalDetails;
        this.setState({toggleModalDetails: !toggleModalDetails, walletDetails: wallet});
    }


    activateEmployee = (employee_id) => {
        this.props.activateEmployee(employee_id);
    }


    deactivateEmployee = (employee_id) => {
        this.props.deactivateEmployee(employee_id);
    }
    

    resendInvite = (name, phoneNumber) => {
        const resendInviteParams = {
            phoneNumber: phoneNumber,
        }
        this.props.resendInvite(resendInviteParams);
        toast("Resending invite to " + name);
    }


    closeModal = (action) => {
        this.setState({toggleModalDetails: action});
    }


    retryWithdrawal = (withdrawal_id) => {
        this.props.retryWithdrawal(withdrawal_id);
        this.setState({toggleModalDetails: false});
    }


    amountColor = (amount, type) => {
        if (type === "credit"){
            return <span className="text-green-500">{CurrencyFormat(amount)}</span>
        }
        else if (type === "debit"){
            return <span className="text-red-500">{CurrencyFormat(amount)}</span>
        }
        else{
            return <span>{CurrencyFormat(amount)}</span>
        }
    }


    displayStatus = (status, type="label") => {
        const allStatus = {
            "successful": {label: "label-green", text: "success"},
            "pending": {label: "label-yellow"},
            "failed": {label: "label-red"},
        }
        if (status in allStatus){
            if (type === "label"){
                return allStatus[status].label;
            }
            else if (type === "text"){
                return allStatus[status].text ? allStatus[status].text : status;
            }
        }
        return "";
    }


    componentDidMount() {
        this.getPageContent();
    }
    
    
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.getPageContent();
        }
        if (this.props.reloadPage === true) {
            setTimeout(()=>{
                this.props.resetReloadPage();
                this.props.history.replace(this.props.location.pathname + this.props.location.search);
            },1000);
        }
    }

    
    componentWillUnmount(){
        const { storeUnsubscribe1 } = this.state;
        if ((storeUnsubscribe1 !== "") && (storeUnsubscribe1 !== undefined) && (storeUnsubscribe1 !== null)){
            storeUnsubscribe1();
        }
    }


    render() { 
        customizeSVG();
        const { employeesDetailsLoading, employeesDetails, employeeWalletLoading, employeeWallet } = this.props;
        const { toggleModalDetails, walletDetails, getAllWithdrawalsParams, employeeId } = this.state;

        return ( 
            <div>

                
                <div className={"max-w-md w-screen h-screen bg-white fixed z-50 top-0 bottom-0 shadow-xl transition-all duration-300 ease-in-out " + (toggleModalDetails ? "right-0" : "-right-full")}>
                    <div className="w-full h-full p-5 pb-0 relative overflow-auto overscroll-contain">
                        {(Object.keys(walletDetails).length > 0) && 
                            <div>

                                <div>
                                    <div className="flex justify-between">
                                        <div className="font-recoleta font-semibold text-2xl">
                                            Payment Details
                                        </div>
                                        <div onClick={() => this.closeModal(false)} className="w-8 h-8 flex group hover:bg-red-400 rounded-lg cursor-pointer">
                                            <Xclose className="customizeSVG group-hover:text-white m-auto" />
                                        </div>
                                    </div>
                                    <div className="mt-1 text-sm flex space-x-2">
                                        {/* 
                                        {walletDetails.status === "success" &&
                                            <div className="bg-green-500 w-2 h-2 rounded-full"></div>
                                        }
                                        {walletDetails.status === "failed" &&
                                            <div className="bg-red-500 w-2 h-2 rounded-full"></div>
                                        }
                                         */}
                                        <div className="capitalize">
                                            {walletDetails.product} &nbsp;•&nbsp;&nbsp;
                                            <span className="text-fade">
                                                Target: {walletDetails.target}
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div className="w-full h-px mt-8 bg-gray-200"></div>

                                <div className="my-8">
                                    <div className="font-semibold text-sm">
                                        Details
                                    </div>

                                    <div className="mt-5 grid grid-cols-2 gap-x-4">
                                        <div className="space-y-5">

                                            <div className="my-auto">
                                                <div className="font-semibold text-xs">
                                                    Amount
                                                </div>
                                                <div className="mt-1 font-medium text-xs">
                                                    {this.amountColor((walletDetails.amount / 100), walletDetails.type)}
                                                </div>
                                            </div>

                                            <div className="w-full my-auto">
                                                <div className="font-semibold text-xs">
                                                    Previous Balance
                                                </div>
                                                <div className="mt-1 font-medium text-xs break-words">
                                                    {CurrencyFormat(walletDetails.previousBalance / 100)}
                                                </div>
                                            </div>

                                            <div className="w-full my-auto">
                                                <div className="font-semibold text-xs">
                                                    Current Balance
                                                </div>
                                                <div className="mt-1 font-medium text-xs break-words">
                                                    {CurrencyFormat(walletDetails.newBalance / 100)}
                                                </div>
                                            </div>

                                            <div className="w-full my-auto">
                                                <div className="font-semibold text-xs">
                                                    Date
                                                </div>
                                                <div className="mt-1 font-medium text-xs break-words">
                                                    {DateTimeFormat(walletDetails.createdAt)}
                                                </div>
                                            </div>

                                        </div>
                                        <div className="space-y-5">

                                            <div className="my-auto">
                                                <div className="font-semibold text-xs">
                                                    Description
                                                </div>
                                                <div className="mt-1 font-medium text-xs">
                                                    {walletDetails.description}
                                                </div>
                                            </div>
                                        
                                            <div className="my-auto">
                                                <div className="font-semibold text-xs">
                                                    Status
                                                </div>
                                                <div className="mt-1 font-medium text-xs">
                                                    <div className={"label uppercase " + this.displayStatus(walletDetails.status, "label")}>
                                                        {this.displayStatus(walletDetails.status, "text")}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="my-auto">
                                                <div className="font-semibold text-xs">
                                                    Transaction Reference
                                                </div>
                                                <div className="mt-1 font-medium text-xs">
                                                    {walletDetails.transactionRef}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    
                                    <div className="w-full h-px mt-8 bg-gray-200"></div>
                                    <div className="mt-6 font-semibold text-sm">
                                        Sent From
                                    </div>

                                    <div className="mt-5 grid grid-cols-2 gap-x-4">
                                        <div className="space-y-5">

                                            <div className="my-auto">
                                                <div className="font-semibold text-xs">
                                                    Wallet ID
                                                </div>
                                                <div className="mt-1 font-medium text-xs">
                                                    {walletDetails.walletId}
                                                </div>
                                            </div>

                                        </div>
                                        <div className="space-y-5">

                                            <div className="w-full my-auto">
                                                <div className="font-semibold text-xs">
                                                    Account Name
                                                </div>
                                                <div className="mt-1 font-medium text-xs break-words">
                                                    {employeeWallet.wallet ? employeeWallet.wallet.accountName : "N/A"}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        }
                    </div>
                </div>
                


                <div className="lg:flex">
                    <div className="w-full lg:w-1/2">
                        <div className="page-title">
                            Employees Dashboard
                        </div>
                    </div>
                    <div className="w-full lg:w-1/2 flex space-x-4 mt-2 lg:mt-0 lg:justify-end">
                        {(employeesDetailsLoading === false) && (employeesDetails) && (Object.keys(employeesDetails).length > 0) && 
                            <>
                                <div onClick={() => this.resendInvite(employeesDetails.fullName, employeesDetails.phoneNumber)} className="btn btn-md btn-transparent-black">
                                    Resend Invite
                                </div>
                                {(employeesDetails.disabled === true) &&
                                    <button type="button" onClick={() => this.activateEmployee(employeesDetails._id)} className="btn btn-md btn-ep-blue">
                                        Activate Employee
                                    </button>
                                }
                                {(employeesDetails.disabled === false) &&
                                    <button type="button" onClick={() => this.deactivateEmployee(employeesDetails._id)} className="btn btn-md bg-red-500 btn-red">
                                        Deactivate Employee
                                    </button>
                                }
                            </>
                        }
                    </div>
                </div>


                <div className="xl:flex pt-6 xl:space-x-10 space-y-8 xl:space-y-0">

                    <div className="w-full xl:w-2/3">

                        <div className="overflow-auto">
                            <div className="flex text-center space-x-0">
                                <NavLink to={"/users/employees/details/" + employeeId} className="tab flex-grow">
                                    Earnipay
                                </NavLink>
                                <NavLink to={"/employees/wallet/" + employeeId} className="tab flex-grow">
                                    Wallet
                                </NavLink>
                            </div>
                        </div>

                        <div className="mt-6">
                            <div className="grid sm:grid-cols-2 gap-6">
                                <DashboardBoxes data={this.state.dashboardProps1} loading={employeeWalletLoading} result={employeeWallet.wallet? CurrencyFormat(employeeWallet.wallet.balance / 100) : "-"} />
                                <DashboardBoxes data={this.state.dashboardProps2} loading={employeeWalletLoading} result={employeeWallet.wallet? CurrencyFormat(employeeWallet.wallet?.savings / 100) : "-"} />
                            </div>
                        </div>


                        <div className="mt-2">
                            <div className="">

                                <div className="mt-8 page-title-mini">
                                    Wallet transaction history
                                </div>

                                <div className="mt-1 table-container">
                                    <table className="table table-auto table-rounded table-border">
                                        <thead>
                                            <tr>
                                                <th>Date</th>
                                                <th>Amount</th>
                                                <th>Current Bal.</th>
                                                <th>Description</th>
                                                <th>Status</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            {(employeeWalletLoading === true) && 
                                                <tr>
                                                    <td colSpan="6">
                                                        <div className="table-info">
                                                            <FontAwesomeIcon icon="spinner" spin />
                                                            <div className="font-bold uppercase">
                                                                Loading
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            }

                                            {((employeeWalletLoading === false) && (!employeeWallet.transactions || !employeeWallet.transactions)) && 
                                                <tr>
                                                    <td colSpan="6">
                                                        <div className="table-info">
                                                            <FontAwesomeIcon icon="unlink" />
                                                            <div className="font-bold uppercase">
                                                                An error occurred
                                                                <br />
                                                                Please try again later.
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            }

                                            {((employeeWalletLoading === false) && (employeeWallet.transactions) && (employeeWallet.transactions) && (employeeWallet.transactions.length <= 0)) && 
                                                <tr>
                                                    <td colSpan="6">
                                                            
                                                        {(employeeWallet.transactions.page <= employeeWallet.transactions.totalPages) &&
                                                            <div className="table-info">
                                                                <FontAwesomeIcon icon="list" />
                                                                <div className="font-bold uppercase">
                                                                    No results found
                                                                </div>
                                                            </div>
                                                        }
                                                        
                                                        {(employeeWallet.transactions.page > employeeWallet.transactions.totalPages) &&
                                                            <div className="table-info">
                                                                <FontAwesomeIcon icon="exclamation-triangle" />
                                                                <div className="font-bold uppercase">
                                                                    Invalid Page Number
                                                                </div>
                                                                <div className="mt-2">
                                                                    <Link to="?page=1" className="btn btn-ep-blue btn-sm mx-auto">Goto Page 1</Link>
                                                                </div>
                                                            </div>
                                                        }
                                                        
                                                    </td>
                                                </tr>
                                            }

                                            {((employeeWalletLoading === false) && (employeeWallet.transactions) && (employeeWallet.transactions) && (employeeWallet.transactions.length > 0)) && 
                                                employeeWallet.transactions.map((wallet, index) =>
                                                    <tr key={index}>
                                                        <td>
                                                            <div>
                                                                {DateMiniFormat(wallet.createdAt)}
                                                                <br />
                                                                at {TimeFormat(wallet.createdAt)}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="font-bold">
                                                                {this.amountColor(wallet.amount / 100, wallet.type)}
                                                            </div>
                                                            <div className="text-xs">
                                                                Charge: {CurrencyFormat(wallet.charge / 100)}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            {CurrencyFormat(wallet.newBalance / 100)}
                                                        </td>
                                                        <td>
                                                            {wallet.description}
                                                        </td>
                                                        <td>
                                                            <div className={"label uppercase " + this.displayStatus(wallet.status, "label")}>
                                                                {this.displayStatus(wallet.status, "text")}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="table-dropdown-align-top">
                                                                <Dropdown icon={dropdownTableIcon} menu={[
                                                                    {type: "div", text: "View Details", click: () => this.viewDetails(wallet)},
                                                                ]} />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                            
                                        </tbody>
                                    </table>
                                </div>

                                {((employeeWalletLoading === false) && (employeeWallet.transactions) && (employeeWallet.transactions) && (employeeWallet.transactions.length > 0)) && 
                                    <div>
                                        <Pagination data={employeeWallet.transactions} url={this.props.location.search} limit={getAllWithdrawalsParams.limit} />
                                    </div>
                                }

                            </div>
                        </div>
                    </div>

                    <div className="w-full xl:w-1/3">
                        <div className="box">

                            {(employeesDetailsLoading === true) && 
                                <div className="table-info">
                                    <FontAwesomeIcon icon="spinner" spin />
                                    <div className="font-bold uppercase">
                                        Loading
                                    </div>
                                </div>
                            }
                            
                            {(employeesDetailsLoading === false) && (employeesDetails) && (Object.keys(employeesDetails).length > 0) && 
                                <div>

                                    <div>
                                        <div className="flex justify-between">
                                            <div className="font-recoleta font-semibold text-2xl">
                                                {employeesDetails.fullName}
                                            </div>
                                        </div>
                                        <div className="text-fade text-sm">
                                            {employeesDetails.email}
                                        </div>
                                        <div className="mt-2 flex space-x-4">
                                            <div className="flex h-6">
                                                {employeesDetails.status === "active" &&
                                                    <>
                                                        <div className="w-2 h-2 my-auto mr-2 rounded-full" style={{"backgroundColor":"#5FDCB3"}}></div>
                                                        <div className="my-auto text-sm">Enrolled</div>
                                                    </>
                                                }
                                                {employeesDetails.status === "pending" &&
                                                    <>
                                                        <div className="w-2 h-2 my-auto mr-2 rounded-full" style={{"backgroundColor":"#D3B418"}}></div>
                                                        <div className="my-auto text-sm">Pending</div>
                                                    </>
                                                }
                                            </div>
                                            <Link to={"/employees?employerId=" + employeesDetails.employerId} className="hidden btn btn-sm bg-gray-200 btn-gray-hover">
                                                View Employees
                                            </Link>
                                        </div>
                                    </div>

                                    <div className="w-full h-px mt-6 mb-3 bg-gray-200"></div>
                                    <div className="text-lg font-recoleta text-ep-blue font-semibold">
                                        Employee’s Details
                                    </div>
                                    <div className="w-full h-px mt-3 mb-6 bg-gray-200"></div>

                                    <div className="space-y-5">

                                        <div className="my-auto">
                                            <div className="font-semibold text-sm">
                                                Company Name
                                            </div>
                                            <div className="mt-1 font-medium text-sm">
                                                {employeesDetails.companyInfo.companyName}
                                            </div>
                                        </div>

                                        <div className="my-auto">
                                            <div className="font-semibold text-sm">
                                                Job Title
                                            </div>
                                            <div className="mt-1 font-medium text-sm">
                                                {employeesDetails.jobTitle}
                                            </div>
                                        </div>

                                        <div className="my-auto">
                                            <div className="font-semibold text-sm">
                                                Employee ID
                                            </div>
                                            <div className="mt-1 font-medium text-sm">
                                                {(employeesDetails.employeeId) ? employeesDetails.employeeId : <span className="italic">N/A</span> }
                                            </div>
                                        </div>
                                    
                                        <div className="my-auto">
                                            <div className="font-semibold text-sm">
                                                Phone Number
                                            </div>
                                            <div className="mt-1 font-medium text-sm">
                                                {employeesDetails.phoneNumber}
                                            </div>
                                        </div>

                                        <div className="my-auto">
                                            <div className="font-semibold text-sm">
                                                Net Salary
                                            </div>
                                            <div className="mt-1 font-medium text-sm">
                                                {CurrencyFormat(employeesDetails.netMonthlySalary)}
                                            </div>
                                        </div>

                                        <div className="my-auto">
                                            <div className="font-semibold text-sm">
                                                Bank Name
                                            </div>
                                            <div className="mt-1 font-medium text-sm">
                                                {employeesDetails.bankName}
                                            </div>
                                        </div>

                                        <div className="my-auto">
                                            <div className="font-semibold text-sm">
                                                Bank Account Name
                                            </div>
                                            <div className="mt-1 font-medium text-sm">
                                                {employeesDetails.bankAccountName}
                                            </div>
                                        </div>

                                        <div className="my-auto">
                                            <div className="font-semibold text-sm">
                                                Bank Account No
                                            </div>
                                            <div className="mt-1 font-medium text-sm">
                                                {employeesDetails.bankAccountNumber}
                                            </div>
                                        </div>

                                        <div className="my-auto">
                                            <div className="font-semibold text-sm">
                                                Date Added
                                            </div>
                                            <div className="mt-1 font-medium text-sm">
                                                {DateTimeFormat(employeesDetails.createdAt)}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="w-full h-px mt-6 mb-3 bg-gray-200"></div>
                                    <div className="text-lg font-recoleta text-ep-blue font-semibold">
                                        Wallet Details
                                    </div>
                                    <div className="w-full h-px mt-3 mb-6 bg-gray-200"></div>

                                    <div className="space-y-5">

                                        <div className="my-auto">
                                            <div className="font-semibold text-sm">
                                                WalletID
                                            </div>
                                            <div className="mt-1 font-medium text-sm">
                                                {employeeWallet.wallet ? employeeWallet.wallet.walletId : "N/A"}
                                            </div>
                                        </div>

                                        <div className="my-auto">
                                            <div className="font-semibold text-sm">
                                                Wallet Bank Name
                                            </div>
                                            <div className="mt-1 font-medium text-sm">
                                                {employeeWallet.wallet ? employeeWallet.wallet.bankName : "N/A"}
                                            </div>
                                        </div>

                                        <div className="my-auto">
                                            <div className="font-semibold text-sm">
                                                Wallet Account Name
                                            </div>
                                            <div className="mt-1 font-medium text-sm">
                                                {employeeWallet.wallet ? employeeWallet.wallet.accountName : "N/A"}
                                            </div>
                                        </div>

                                        <div className="my-auto">
                                            <div className="font-semibold text-sm">
                                                Wallet Bank Account
                                            </div>
                                            <div className="mt-1 font-medium text-sm">
                                                {employeeWallet.wallet ? employeeWallet.wallet.accountNumber : "N/A"}
                                            </div>
                                        </div>
                                    
                                        <div className="my-auto">
                                            <div className="font-semibold text-sm">
                                                Date Added
                                            </div>
                                            <div className="mt-1 font-medium text-sm">
                                                {employeeWallet.wallet ? DateTimeFormat(employeeWallet.wallet.createdAt) : "N/A"}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            }

                        </div>
                    </div>

                </div>
                
            </div>
        );
    }
}


const mapStateToProps = (state) => ({
    employeesDetailsLoading: state.entities.employees.employeesDetailsLoading,
    employeesDetails: state.entities.employees.employeesDetails,
    reloadPage: state.entities.employees.reloadPage,

    employeeWalletLoading: state.entities.withdrawals.employeeWalletLoading,
    employeeWallet: state.entities.withdrawals.employeeWallet,
});

const mapDispatchToProps = (dispatch) => ({
    getEmployeeDetails: (id) => dispatch(employees.getEmployeeDetails(id)),
    activateEmployee: (employee_id) => dispatch(employees.activateEmployee(employee_id)),
    deactivateEmployee: (employee_id) => dispatch(employees.deactivateEmployee(employee_id)),
    resendInvite: (params) => dispatch(employees.resendInvite(params)),
    resetReloadPage: () => dispatch(employees.resetReloadPage()),

    getWalletByEmployeeId: (id, params) => dispatch(withdrawals.getWalletByEmployeeId(id, params)),
    retryWithdrawal: (withdrawal_id) => dispatch(withdrawals.retryWithdrawal(withdrawal_id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Wallet);