import store from "store/store";
import { useEffect, useRef } from "react";
import {
	BrowserRouter,
	Route,
	Switch,
	Redirect,
	withRouter,
} from "react-router-dom";

import AuthLayout from "./components/layouts/authLayout";
import generalAdminLayout from "./components/layouts/generalAdminLayout";
import NullLayout from "./components/layouts/nullLayout";
import Error401 from "./components/misc/error401";
import Error404 from "./components/misc/error404";
import { UnderMaintenance } from "./components/misc/UnderMaintenance";

import Login from "./components/pages/auth/login";
import ForgotPassword from "./components/pages/auth/forgotPassword";
import ResetPassword from "./components/pages/auth/resetPassword";
import ResetPasswordSuccess from "./components/pages/auth/resetPasswordSuccess";

import settingsAdministratorsOnboarding from "./pages/settings/adminOnboard";
import settingsAdministratorsOnboardingSuccess from "./pages/settings/adminOnboardSuccess";

import accountProfile from "./pages/account/profile";
import dashboard from "./pages/dashboard/index";
import BusinessesList from "pages/employers/BusinessesList";
import BusinessesBalanceList from "pages/employers/BusinessesBalanceList";
import BusinessTransactionsList from "pages/employers/BusinessTransactionsList";
import employersDetails from "./pages/employers/details";
import employersAdmins from "./pages/employers/admins";
import employersPerformance from "./pages/employers/performance";

import SubAccountsList from "pages/subAccounts/SubAccountsList";

// import ComplianceList from "pages/compliance/list";

import employeesList from "./pages/employees/list";
import CustomersList from "pages/employees/CustomersList";
import AccountDeletionRequests from "pages/employees/AccountDeletionRequests";
import CustomersTransactionsList from "pages/employees/CustomersTransactionsList";
import CustomersLoanHistory from "pages/employees/CustomersLoanHistory";
import employeesListWithdrawOnceOrMore from "./pages/employees/listWithdrawOnceOrMore";
import employeesDetails from "./pages/employees/details";
import employeesWallet from "./pages/employees/wallet";
import withdrawalsTransactionList from "./pages/transactions/withdrawals";
import walletTransactionList from "./pages/transactions/wallet";
import CreditTransactionsList from "pages/transactions/CreditTransactionsList";
import payrollList from "./pages/reports/payroll";

import rewardsPointList from "./pages/rewards/points/list";
import RewardsPointsHistoryList from "./pages/rewards/points-history/RewardsPointsHistoryList";
import rewardsRewardsList from "./pages/rewards/rewards/list";
import rewardsRedemptionHistory from "./pages/rewards/redemption-history/list";

import ApprovalsList from "pages/approvals/ApprovalsList";
import querySearchCategory from "./pages/search/querySearchCategory";
import querySearchEmployers from "./pages/search/querySearchEmployers";
import querySearchEmployees from "./pages/search/querySearchEmployees";
import querySearchWithdrawals from "./pages/search/querySearchWithdrawals";
import paymentNote from "./pages/payment/list";
import paymentHistory from "./pages/payment/history";
import referralsList from "./pages/referrals/list";
import SavingsSettings from "components/pages/generalAdmin/settings/SavingsSettings";
import GoalSavings from "components/pages/generalAdmin/savings/revamp/GoalSavings";
import FlexSavings from "components/pages/generalAdmin/savings/revamp/FlexSavings";
import BulkNotifications from "components/pages/generalAdmin/settings/BulkNotifications";
import CreditSettings from "components/pages/generalAdmin/settings/CreditSettings";
// import ambassadorProgramList from "./pages/ambassador/program-list";
// import ambassadorsList from "./pages/ambassador/ambassador-list";
// import ambassadorsDetails from "./pages/ambassador/ambassador-details";
// import ambassadorsForm from "./pages/ambassador/ambassador-form";
import adminsList from "./pages/admin/list";
import adminsForm from "./pages/admin/form";
import settingsApp from "./pages/settings/app";
import settingsAppEdit from "./pages/settings/appEdit";
import settingsFees from "./pages/settings/fees";
import settingsFeesEdit from "./pages/settings/feesEdit";
import PayrollFees from "pages/settings/PayrollFees";
import PlatformFees from "pages/settings/PlatformFees";
import settingsAccount from "./pages/settings/account";
import settingsBillPayment from "./pages/settings/billPayment";
import settingsYieldSettings from "./pages/settings/yieldSettings";
import auditTrail from "./pages/auditTrail/list";

import CreditList from "pages/credit/CreditRequestsHomepage";

/*
import employersDetails from './components/pages/generalAdmin/employers/details';
import employeesList from './components/pages/generalAdmin/employees/list';
import employersEmployeesList from './components/pages/generalAdmin/employers/listEmployees';
import paymentDetails from './components/pages/generalAdmin/invoice/payment';
*/

import { library } from "@fortawesome/fontawesome-svg-core";
// import { fab } from '@fortawesome/free-brands-svg-icons';
// import { far } from '@fortawesome/free-regular-svg-icons';
import { fas } from "@fortawesome/free-solid-svg-icons";
import CustomersBalanceList from "pages/employers/CustomersBalanceList";
library.add(fas);

const ScrollToTopComponent = () => {
	const mounted = useRef(false);
	useEffect(() => {
		if (!mounted.current) {
			//componentDidMount
			mounted.current = true;
		} else {
			//componentDidUpdate
			window.scrollTo(0, 0);
		}
	});
	return null;
};
export const ScrollToTop = withRouter(ScrollToTopComponent);

const RouteLayout = ({component: Component, layout: Layout, authUser, authRole, ...rest}) => (
	<Route
		{...rest}
		render={(props) => {
			const user = store.getState().auth.userPersist;
			const user_role = user.data.userType;

			if (authUser) {
				if (authUser === "loggedIn") {
					if (user.loggedIn === true) {
						if (authRole) {
							if (authRole.includes(user_role)) {
								return (
									<Layout>
										<Component {...props} />
									</Layout>
								);
							} else {
								// alert("Access denied due to user role");
								return <Redirect to="/401" />;
							}
						} else {
							// no roles required, you only need to be logged in
							return (
								<Layout>
									<Component {...props} />
								</Layout>
							);
						}
					} else {
						// alert("need to be logged in);
						return <Redirect to="/login" />;
					}
				} else if (authUser === "loggedOut") {
					if (user.loggedIn === false) {
						return (
							<Layout>
								<Component {...props} />
							</Layout>
						);
					} else {
						// alert("already logged in; need to be logged out to access);
						return <Redirect to="/dashboard" />;
					}
				}
			} else {
				// public page, no auth-yes or auth-no required
				return (
					<Layout>
						<Component {...props} />
					</Layout>
				);
			}
		}}
	/>
);

function App() {
	return (
		<BrowserRouter>
			<ScrollToTop />
			<Switch>
				<RouteLayout exact authUser="loggedOut" path="/login" layout={AuthLayout} component={Login} />
				<RouteLayout exact authUser="loggedOut" path="/forgot-password" layout={AuthLayout} component={ForgotPassword} />
				<RouteLayout exact authUser="loggedOut" path="/reset-password" layout={AuthLayout} component={ResetPassword} />
				<RouteLayout exact authUser="loggedOut" path="/reset-password/success" layout={AuthLayout} component={ResetPasswordSuccess} />
				<RouteLayout exact authUser="loggedIn" path="/account/profile" layout={generalAdminLayout} component={accountProfile} />

				<RouteLayout exact authUser="loggedIn" authRole={["super-admin", "general-admin", "financial-admin", "customer-success-admin",]} path="/dashboard" layout={generalAdminLayout} component={UnderMaintenance} />

				<RouteLayout exact authUser="loggedIn" authRole={["super-admin", "general-admin", "financial-admin", "customer-success-admin",]} path="/businesses" layout={generalAdminLayout} component={BusinessesList} />
				<RouteLayout exact authUser="loggedIn" authRole={["super-admin", "general-admin", "financial-admin", "customer-success-admin",]} path="/businesses-balance" layout={generalAdminLayout} component={BusinessesBalanceList} />
				<RouteLayout exact authUser="loggedIn" authRole={["super-admin", "general-admin", "financial-admin", "customer-success-admin",]} path="/customers-balance" layout={generalAdminLayout} component={CustomersBalanceList} />

				<RouteLayout exact authUser="loggedIn" authRole={["super-admin", "general-admin", "financial-admin", "customer-success-admin",]} path="/businesses/:id/admins" layout={generalAdminLayout} component={employersAdmins} />
				<RouteLayout exact authUser="loggedIn" authRole={["super-admin", "general-admin", "financial-admin", "customer-success-admin",]} path="/businesses/transactions/:name" layout={generalAdminLayout} component={BusinessTransactionsList} />
				<RouteLayout exact authUser="loggedIn" authRole={["super-admin", "general-admin", "financial-admin", "customer-success-admin",]} path="/businesses/:id/:slug" layout={generalAdminLayout} component={employersDetails} />
				<RouteLayout exact authUser="loggedIn" authRole={["super-admin", "general-admin", "financial-admin", "customer-success-admin",]} path="/businesses/performance" layout={generalAdminLayout} component={employersPerformance} />

				<RouteLayout exact authUser="loggedIn" authRole={["super-admin", "general-admin", "financial-admin", "customer-success-admin",]} path="/sub-accounts" layout={generalAdminLayout} component={SubAccountsList} />

				{/* <RouteLayout exact authUser="loggedIn" authRole={["super-admin", "general-admin", "financial-admin", "customer-success-admin",]} path="/compliance" layout={generalAdminLayout} component={ComplianceList} /> */}

				<RouteLayout exact authUser="loggedIn" authRole={["super-admin", "general-admin", "financial-admin", "customer-success-admin",]} path="/users/employees" layout={generalAdminLayout} component={employeesList} />
				<RouteLayout exact authUser="loggedIn" authRole={["super-admin", "general-admin", "financial-admin", "customer-success-admin",]} path="/users/customers" layout={generalAdminLayout} component={CustomersList} />
				<RouteLayout exact authUser="loggedIn" authRole={["super-admin", "general-admin", "financial-admin", "customer-success-admin",]} path="/users/account-deletion/" layout={generalAdminLayout} component={AccountDeletionRequests} />
				<RouteLayout exact authUser="loggedIn" authRole={["super-admin", "general-admin", "financial-admin", "customer-success-admin",]} path="/users/customers/transactions/:name" layout={generalAdminLayout} component={CustomersTransactionsList} />
				<RouteLayout exact authUser="loggedIn" authRole={["super-admin", "general-admin", "financial-admin", "customer-success-admin",]} path="/users/customers/loan-history/:name" layout={generalAdminLayout} component={CustomersLoanHistory} />
				<RouteLayout exact authUser="loggedIn" authRole={["super-admin", "general-admin", "financial-admin", "customer-success-admin",]} path="/credit" layout={generalAdminLayout} component={CreditList} />

				<RouteLayout exact authUser="loggedIn" authRole={["super-admin", "general-admin", "financial-admin", "customer-success-admin",]} path="/users/employees/withdraw-once-or-more" layout={generalAdminLayout} component={employeesListWithdrawOnceOrMore} />
				<RouteLayout exact authUser="loggedIn" authRole={["super-admin", "general-admin", "financial-admin", "customer-success-admin",]} path="/users/employees/details/:id" layout={generalAdminLayout} component={employeesDetails} />
				<RouteLayout exact authUser="loggedIn" authRole={["super-admin", "general-admin", "financial-admin", "customer-success-admin",]} path="/employees/wallet/:id" layout={generalAdminLayout} component={employeesWallet} />
				<RouteLayout exact authUser="loggedIn" authRole={["super-admin", "general-admin", "financial-admin", "customer-success-admin",]} path="/search" layout={generalAdminLayout} component={/* querySearchCategory */UnderMaintenance} />
				<RouteLayout exact authUser="loggedIn" authRole={["super-admin", "general-admin", "financial-admin", "customer-success-admin",]} path="/search/businesses" layout={generalAdminLayout} component={/* querySearchEmployers */UnderMaintenance} />
				<RouteLayout exact authUser="loggedIn" authRole={["super-admin", "general-admin", "financial-admin", "customer-success-admin",]} path="/search/employees" layout={generalAdminLayout} component={/* querySearchEmployees */UnderMaintenance} />
				<RouteLayout exact authUser="loggedIn" authRole={["super-admin", "general-admin", "financial-admin", "customer-success-admin",]} path="/search/withdrawals" layout={generalAdminLayout} component={/* querySearchWithdrawals */UnderMaintenance} />
				<RouteLayout exact authUser="loggedIn" authRole={["super-admin", "general-admin", "financial-admin", "customer-success-admin",]} path="/transactions/withdrawals" layout={generalAdminLayout} component={withdrawalsTransactionList} />
				<RouteLayout exact authUser="loggedIn" authRole={["super-admin", "general-admin", "financial-admin", "customer-success-admin",]} path="/transactions/credit" layout={generalAdminLayout} component={CreditTransactionsList} />
				<RouteLayout exact authUser="loggedIn" authRole={["super-admin", "general-admin", "financial-admin", "customer-success-admin",]} path="/savings/goals" layout={generalAdminLayout} component={GoalSavings} />
				<RouteLayout exact authUser="loggedIn" authRole={["super-admin", "general-admin", "financial-admin", "customer-success-admin",]} path="/savings/flex" layout={generalAdminLayout} component={FlexSavings} />


				<RouteLayout exact authUser="loggedIn" authRole={["super-admin", "general-admin", "financial-admin", "customer-success-admin",]} path="/rewards/points" layout={generalAdminLayout} component={rewardsPointList} />
				<RouteLayout exact authUser="loggedIn" authRole={["super-admin", "general-admin", "financial-admin", "customer-success-admin",]} path="/rewards/gift-points-history" layout={generalAdminLayout} component={RewardsPointsHistoryList} />
				<RouteLayout exact authUser="loggedIn" authRole={["super-admin", "general-admin", "financial-admin", "customer-success-admin",]} path="/rewards/rewards" layout={generalAdminLayout} component={rewardsRewardsList} />
				<RouteLayout exact authUser="loggedIn" authRole={["super-admin", "general-admin", "financial-admin", "customer-success-admin",]} path="/rewards/redemption-history" layout={generalAdminLayout} component={rewardsRedemptionHistory} />

				<RouteLayout exact authUser="loggedIn" authRole={["super-admin", "general-admin", "financial-admin", "customer-success-admin",]} path="/transactions/wallet" layout={generalAdminLayout} component={walletTransactionList} />
				<RouteLayout exact authUser="loggedIn" authRole={["super-admin", "general-admin", "financial-admin", "customer-success-admin",]} path="/approvals" layout={generalAdminLayout} component={ApprovalsList} />
				<RouteLayout exact authUser="loggedIn" authRole={["super-admin", "general-admin", "financial-admin", "customer-success-admin",]} path="/reports" layout={generalAdminLayout} component={payrollList} />
				<RouteLayout exact authUser="loggedIn" authRole={["super-admin", "general-admin", "financial-admin", "customer-success-admin",]} path="/payments" layout={generalAdminLayout} component={paymentNote} />
				<RouteLayout exact authUser="loggedIn" authRole={["super-admin", "general-admin", "financial-admin", "customer-success-admin",]} path="/payments/history" layout={generalAdminLayout} component={paymentHistory} />
				{/* <RouteLayout exact authUser="loggedIn" authRole={["super-admin", "general-admin", "financial-admin", "customer-success-admin",]} path="/referrals" layout={generalAdminLayout} component={referralsList} /> */}
                {/* 
				<RouteLayout exact authUser="loggedIn" authRole={["super-admin", "general-admin", "financial-admin", "customer-success-admin",]} path="/ambassador-program" layout={generalAdminLayout} component={ambassadorProgramList} />
				<RouteLayout exact authUser="loggedIn" authRole={["super-admin", "general-admin", "financial-admin", "customer-success-admin",]} path="/ambassador-program/ambassadors" layout={generalAdminLayout} component={ambassadorsList} />
				<RouteLayout exact authUser="loggedIn" authRole={["super-admin", "general-admin", "financial-admin", "customer-success-admin",]} path="/ambassador-program/ambassadors/list" layout={generalAdminLayout} component={ambassadorsForm} />
				<RouteLayout exact authUser="loggedIn" authRole={["super-admin", "general-admin", "financial-admin", "customer-success-admin",]} path="/ambassador-program/ambassadors/list/:employerId" layout={generalAdminLayout} component={ambassadorsForm} />
				<RouteLayout exact authUser="loggedIn" authRole={["super-admin", "general-admin", "financial-admin", "customer-success-admin",]} path="/ambassador-program/ambassadors/:id/:view" layout={generalAdminLayout} component={ambassadorsDetails} />
                 */}
				<RouteLayout exact authUser="loggedIn" authRole={["super-admin"]} path="/admin" layout={generalAdminLayout} component={adminsList} />
				<RouteLayout exact authUser="loggedIn" authRole={["super-admin"]} path="/admin/add" layout={generalAdminLayout} component={adminsForm} />
				<RouteLayout exact authUser="loggedIn" authRole={["super-admin"]} path="/admin/edit/:id" layout={generalAdminLayout} component={adminsForm} />
				<RouteLayout exact authUser="loggedIn" authRole={["super-admin", "general-admin", "financial-admin", "customer-success-admin",]} path="/settings/app" layout={generalAdminLayout} component={settingsApp} />
				<RouteLayout exact authUser="loggedIn" authRole={["super-admin"]} path="/settings/app/edit" layout={generalAdminLayout} component={settingsAppEdit} />
				<RouteLayout exact authUser="loggedIn" authRole={["super-admin", "general-admin", "financial-admin", "customer-success-admin",]} path="/settings/fees" layout={generalAdminLayout} component={settingsFees} />
				<RouteLayout exact authUser="loggedIn" authRole={["super-admin", "general-admin", "financial-admin", "customer-success-admin",]} path="/settings/payroll-fees" layout={generalAdminLayout} component={PayrollFees} />
				<RouteLayout exact authUser="loggedIn" authRole={["super-admin", "general-admin", "financial-admin", "customer-success-admin",]} path="/settings/platform-fees" layout={generalAdminLayout} component={PlatformFees} />
				<RouteLayout exact authUser="loggedIn" authRole={["super-admin", "financial-admin"]} path="/settings/fees/edit" layout={generalAdminLayout} component={settingsFeesEdit} />
				<RouteLayout exact authUser="loggedIn" authRole={["super-admin", "general-admin", "financial-admin", "customer-success-admin",]} path="/settings/account" layout={generalAdminLayout} component={settingsAccount} />
				<RouteLayout exact authUser="loggedIn" authRole={["super-admin", "general-admin", "financial-admin", "customer-success-admin",]} path="/settings/savings" layout={generalAdminLayout} component={SavingsSettings} />
				<RouteLayout exact authUser="loggedIn" authRole={["super-admin", "general-admin", "financial-admin", "customer-success-admin",]} path="/settings/bulk-notifications" layout={generalAdminLayout} component={BulkNotifications} />
				<RouteLayout exact authUser="loggedIn" authRole={["super-admin", "general-admin", "financial-admin", "customer-success-admin",]} path="/settings/credit" layout={generalAdminLayout} component={CreditSettings} />
				<RouteLayout exact authUser="loggedIn" authRole={["super-admin", "general-admin", "financial-admin", "customer-success-admin",]} path="/settings/bill-payment" layout={generalAdminLayout} component={settingsBillPayment} />
				<RouteLayout exact authUser="loggedIn" authRole={["super-admin", "general-admin", "financial-admin", "customer-success-admin",]} path="/settings/yield-settings" layout={generalAdminLayout} component={settingsYieldSettings} />
				<RouteLayout exact authUser="loggedIn" authRole={["super-admin", "general-admin", "financial-admin", "customer-success-admin",]} path="/audit-trail" layout={generalAdminLayout} component={auditTrail} />

				<RouteLayout exact path="/admin/verify/:email/:pin" layout={AuthLayout} component={settingsAdministratorsOnboarding} />
				<RouteLayout exact path="/admin/verify/success" layout={AuthLayout} component={settingsAdministratorsOnboardingSuccess} />

				<RouteLayout exact path="/401" layout={NullLayout} component={Error401} />
				<RouteLayout exact path="/404" layout={NullLayout} component={Error404} />
				<RouteLayout exact authUser="loggedIn" path="/" layout={generalAdminLayout} component={dashboard} />

				<Redirect exact from="/rewards" to="/rewards/points" />
				<Redirect exact from="/transactions" to="/transactions/withdrawals" />
				<Redirect exact from="/withdrawals" to="/transactions/withdrawals" />
				<Redirect exact from="/users" to="/users/employees" />
				<Redirect exact from="/wallet" to="/transactions/wallet" />
				<Redirect exact from="/goals" to="/savings/goals" />
				<Redirect exact from="/flex" to="/savings/flex" />
                {/* <Redirect exact from="/ambassador-program/ambassadors/:id" to="/ambassador-program/ambassadors/:id/referred" /> */}
				<Redirect exact from="/settings" to="/settings/app" />

                <Redirect from="/ambassador-program" to="/" />
				<Redirect to="/404" />
			</Switch>
		</BrowserRouter>
	);
}

export default App;
