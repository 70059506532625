import React from "react";
import CurrencyFormat from "utils/currencyFormat";
import Dropdown from "utils/dropdown";
import SearchFilter from "components/common/filter";
import { ReactComponent as IconNav } from "assets/images/icons/project-icons/Navicon-v.svg";
import { useState } from "react";
import SavingsDetails from "../revamp/SavingsDetails";
import SavingsTransactions from "../revamp/SavingsTransactions";
import useToolkit from "utils/useToolkit";
import { useLocation, NavLink, Link } from 'react-router-dom'
import useUrlQueryParams from "hooks/useUrlQueryParams";
import * as savingsActions from "store/entities/savings/action";
import processParams from "utils/processParams";
import { ReactComponent as DownloadIcon } from "assets/images/icons/project-icons/Download.svg";
import { ReactComponent as IconTotalSavingsAmount } from "assets/images/icons/project-icons/total-savings-amount.svg";
import { ReactComponent as IconTotalWithdrawalsAmount } from "assets/images/icons/project-icons/total-withdrawals-amount.svg";
import { ReactComponent as IconTotalInterestEarned } from "assets/images/icons/project-icons/total-interest-earned.svg";
import { ReactComponent as IconTotalNumberOfSavers } from "assets/images/icons/project-icons/total-number-of-savers.svg";
import { ReactComponent as IconNumberOfActiveGoalSavings } from "assets/images/icons/project-icons/number-of-active-goal-savings.svg";
import { useRef } from "react";
import { CSVLink } from "react-csv";
import Pagination from "components/common/pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import DashboardBoxes from "components/common/dashboard-boxes";


const dropdownTableIcon = (
	<IconNav className="w-8 h-8 p-1.5 relative top-1 cursor-pointer group-hover:bg-gray-200 rounded" />
);

const GoalSavings = () => {
	const { useEffect, dispatch, useSelector, DateFormat } = useToolkit();
	const { urlQueryParams } = useUrlQueryParams();
	const csvRef = useRef();
	const [initiateDownload, setInitiateDownload] = useState(false);
	
	const { goalsSavingsLoading: loading, goalsSavingsData: savingsData, downloadGoalSavingsLoading, downloadGoalSavingsData, savingsMetricsLoading, savingsMetricsData } = useSelector((state) => state.entities.savings);
	const data = savingsData?.data?.docs;
	const [savingsDetailsOpen, setSavingsDetailsOpen] = useState(false);
	const [savingsTransactionsOpen, setSavingsTransactionsOpen] = useState(false)
	// eslint-disable-next-line
	const savingsMetrics = {
		totalSavingsAmount: {
			iconBg: "bg-ep-primary-100",
			icon: (
				<IconTotalSavingsAmount className="customizeSVG text-ep-primary-500 m-auto w-5 h-5" />
			),
			titleText: "Total Savings Amount",
			mainText: "-",
		},
		totalWithdrawalsAmount: {
			iconBg: "bg-yellow-100",
			icon: (
				<IconTotalWithdrawalsAmount className="customizeSVG text-ep-orange-400 m-auto w-5 h-5" />
			),
			titleText: "Total Withdrawals Amount",
			mainText: "-",
		},
		totalInterestEarned: {
			iconBg: "bg-ep-primary-200",
			icon: (
				<IconTotalInterestEarned className="customizeSVG m-auto w-5 h-5" />
			),
			titleText: "Total Interest Earned",
			mainText: "-",
		},
		totalNumberOfSavers: {
			iconBg: "bg-ep-purple-100",
			icon: (
				<IconTotalNumberOfSavers className="customizeSVG m-auto w-5 h-5" />
			),
			titleText: "Total Number of Savers",
			mainText: "-",
		},
		numberOfActiveSavingsGoals: {
			iconBg: "bg-pink-100",
			icon: (
				<IconNumberOfActiveGoalSavings className="customizeSVG m-auto w-5 h-5" />
			),
			titleText: "Number of Active Savings Goals Created",
			mainText: "-",
		},
	}

	const location = useLocation()

	const [listParams, setListParams] = useState({
		page: 1,
		limit: 20,
		type: "target",
	});

	const getGoalSavings = () => {
		let params = { ...listParams };

		params = processParams(urlQueryParams, params, "page", "number", 1, "page");
		params = processParams(urlQueryParams, params, "limit", "number", 20, "limit");
		params = processParams(urlQueryParams, params, "status", "string", "", "status");
		params = processParams(urlQueryParams, params, "savingsFrequency", "string", "", "savingsFrequency");
		params = processParams(urlQueryParams, params, "startDate", "string", "", "startDate");
		params = processParams(urlQueryParams, params, "endDate", "string", "", "endDate");
		params = processParams(urlQueryParams, params, "keyword", "string", "", "keyword");

		setListParams(params);
		
		if(params?.status?.length || params?.savingsFrequency?.length
			|| params?.startDate?.length || params?.endDate?.length || params?.keyword?.length) {
			dispatch(savingsActions.searchFilterGoalSavings(params));
		} else {
			dispatch(savingsActions.getAllGoalSavings(params));
		}
	}


	const getDownloads = () => {
		if (downloadGoalSavingsLoading === false){
			dispatch(savingsActions.downloadSavingsCsv({...listParams, limit: 10000, download: true }));
			setInitiateDownload(true);
		}
	}
	
	const viewDetails = (saving) => {
		const hash = saving?.hash
		const email = saving?.wallet?.email
		
		if(email?.length > 0) {
			setSavingsDetailsOpen(true)
			dispatch(savingsActions.getSavingsDetails({ hash, email }))
		} else {
			toast.error("User email not found; unable to get savings details")
		}
	}
	
	const viewTransactions = (saving) => {
		const hash = saving?.hash
		const email = saving?.wallet?.email
		
		if(email?.length > 0) {
			setSavingsTransactionsOpen(true)
			dispatch(savingsActions.selectSavingsItem(saving))
			dispatch(savingsActions.getSavingsTransactions({ hash, email }))
		} else {
			toast.error("User email not found; unable to get savings transactions")
		}
	}
	
	useEffect(() => {
		getGoalSavings();
		dispatch(savingsActions.getSavingsMetrics());
		// eslint-disable-next-line
	}, [location]);
	

	useEffect(() => {
		if (initiateDownload === true) {
			if (downloadGoalSavingsLoading === false && downloadGoalSavingsData !== null) {
					toast.success("The requested document has been forwarded to your email");
				// setTimeout(() => {
				// 	toast.success("Download complete");
				// 	csvRef.current.link.click();
				// 	setInitiateDownload(false);
				// });
			}
		}
		// eslint-disable-next-line
	}, [downloadGoalSavingsData]);
	
	return (
		<div>
			<div>
				<div className="mt-6 lg:flex">
					<div className="w-full lg:w-1/2">
						<div className="page-title">Savings</div>
					</div>
					<div className="w-full lg:w-1/2 flex space-x-4 mt-2 lg:mt-0 lg:justify-end">

						<CSVLink ref={csvRef} data={downloadGoalSavingsData?.data?.data?.docs || ""} filename="Savings"></CSVLink>
						<div onClick={getDownloads} className="w-9 h-9 flex items-center justify-center box-border-only cursor-pointer">
							{downloadGoalSavingsLoading === true &&
								<FontAwesomeIcon icon="spinner" spin />
							}
							{downloadGoalSavingsLoading === false &&
								<DownloadIcon className="customizeSVG m-auto" />
							}
						</div>
						
						

						<div>
							<SearchFilter
								search={{
									name: "keyword",
									placeholder: "Keyword search",
								}}
								filters={[
									{
										title: "Status",
										name: "status",
										dataType: "checkbox",
										options: [
											{
												display: "Active",
												payload: "active",
											},
											{
												display: "Completed",
												payload: "completed",
											},
											{
												display: "Matured",
												payload: "matured",
											},
											{
												display: "Cancelled",
												payload: "stopped",
											},
											{
												display: "Paused",
												payload: "paused",
											},
											{
												display: "Pending",
												payload: "pending",
											},
										],
									},
									{
										title: "Savings Frequency",
										name: "savingsFrequency",
										dataType: "checkbox",
										options: [
											{
												display: "Daily",
												payload: "daily",
											},
											{
												display: "Weekly",
												payload: "weekly",
											},
											{
												display: "Monthly",
												payload: "monthly",
											},
										],
									},
									{
										title: "Date created",
										dataType: "date-range",
										options: {
											from: {
												name: "startDate",
												placeholder: "Start Date",
											},
											to: {
												name: "endDate",
												placeholder: "End Date",
											},
										},
									},
								]}
							/>
						</div>
					</div>
				</div>
				
				<div className="mt-6">
					<div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-6">
						<DashboardBoxes
							data={savingsMetrics?.totalSavingsAmount}
							loading={savingsMetricsLoading}
							result={savingsMetricsData?.data ? CurrencyFormat(savingsMetricsData?.data?.totalSavingsAmount / 100) : "0"}
						/>
							
						<DashboardBoxes
							data={savingsMetrics?.totalWithdrawalsAmount}
							loading={savingsMetricsLoading}
							result={savingsMetricsData?.data ? CurrencyFormat(savingsMetricsData?.data?.totalWithdrawalsAmount / 100) : "0"}
						/>
						
						<DashboardBoxes
							data={savingsMetrics?.totalInterestEarned}
							loading={savingsMetricsLoading}
							result={savingsMetricsData?.data ? CurrencyFormat(savingsMetricsData?.data?.totalInterestEarned / 100) : "0"}
						/>
						
						<DashboardBoxes
							data={savingsMetrics?.totalNumberOfSavers}
							loading={savingsMetricsLoading}
							result={savingsMetricsData?.data ? savingsMetricsData?.data?.totalSavers : "0"}
						/>
						
						<DashboardBoxes
							data={savingsMetrics?.numberOfActiveSavingsGoals}
							loading={savingsMetricsLoading}
							result={savingsMetricsData?.data ? savingsMetricsData?.data?.totalActiveGoals : "0"}
						/>
					</div>
				</div>
				
				<div className="bg-white overflow-auto mt-8">
					<div className="flex text-center space-x-0">
						<NavLink
							to="/savings/goals/"
							className="tab px-10 md:px-20 flex-shrink-0">
							Goal Savings
						</NavLink>
						{/* <NavLink
							to="/savings/flex/"
							className="tab px-10 md:px-20 flex-shrink-0">
							Flex Savings
						</NavLink> */}
					</div>
				</div>

					<div className="mt-3">
						<div className="">
							<div className="table-container">
								<table className="table table-auto table-rounded table-border table-align-top">
									<thead>
										<tr>
											<th>Date Created</th>
											<th>Goal Name</th>
											<th>Goal Amount</th>
											<th>Start Date</th>
											<th>End Date</th>
											<th>Current Savings</th>
											<th>Interest</th>
											<th>Frequency</th>
											<th>Status</th>
											<th>Actions</th>
										</tr>
									</thead>
									<tbody>
										
										{loading === true && (
											<tr>
												<td colSpan="10">
													<div className="table-info">
														<FontAwesomeIcon icon="spinner" spin />
														<div className="font-bold uppercase">Loading</div>
													</div>
												</td>
											</tr>
										)}
										
										{loading === false && savingsData?.data?.docs &&
											savingsData?.data?.docs?.length <= 0 && (
												<tr>
													<td colSpan="10">
														{savingsData?.data.totalDocs === 0 && (
															<div className="table-info">
																<FontAwesomeIcon icon="list" />
																<div className="font-bold uppercase">
																	No results found
																</div>
															</div>
														)}

														{savingsData?.data?.page > savingsData?.data?.totalPages && savingsData?.data.totalDocs !== 0 && (
															<div className="table-info">
																<FontAwesomeIcon icon="exclamation-triangle" />
																<div className="font-bold uppercase">
																	Invalid Page Number
																</div>
																<div className="mt-2">
																	<Link
																		to="?page=1"
																		className="btn btn-ep-blue btn-sm mx-auto">
																		Goto Page 1
																	</Link>
																</div>
															</div>
														)}
													</td>
												</tr>
											)}
										
										{loading === false && data?.length > 0 && data?.map((saving, index) => {
											const isActive = saving?.status === 'active'
											const isComplete = saving?.status === 'completed'
											const isPaused = saving?.status === 'paused'
											const isMatured = saving?.status === 'matured'
											const isPending = saving?.status === 'pending'
											const isStopped = saving?.status === 'stopped'
											const targetAmountInNaira = CurrencyFormat(saving?.target / 100)

											return (
												<tr className="whitespace-nowrap" key={index}>
													<td>
														<div>{DateFormat(saving?.createdAt?.split("T")[0])}</div>
													</td>
													<td className="capitalize">{saving?.goal}</td>
													<td>{targetAmountInNaira}</td>
													<td>{DateFormat(saving?.startsAt)}</td>
													<td>{DateFormat(saving?.endsAt)}</td>
													<td>
														<div>{CurrencyFormat(saving?.amountSaved)}</div>
													</td>
													<td>{CurrencyFormat(saving?.interest / 100)}</td>
													<td className="capitalize">{saving?.auto_save?.plan}</td>
													<td>
														<div className={`capitalize label ${isActive || isComplete ? 'label-green' : isMatured ? 'label-blue' : isPaused ? 'label-yellow' : isPending ? 'label-gray' : isStopped ? 'label-red' : ''}`}>
															{saving?.status}
														</div>
													</td>
													<td>
														<div className="table-dropdown-align-top">
															<Dropdown
																icon={dropdownTableIcon}
																menu={[
																	{
																		type: "div",
																		text: "View details",
																		click: () => viewDetails(saving),
																	},
																	{
																		type: "div",
																		text: "View transactions",
																		click: () => viewTransactions(saving),
																	},
																	// {
																	// 	type: "div",
																	// 	text: "Pause savings",
																	// 	click: () => togglePauseSavings(saving.hash),
																	// },
																	// {
																	// 	type: "div",
																	// 	text: "Cancel savings",
																	// 	click: () => toggleCancelSavings(saving.hash),
																	// },
																]}
															/>
														</div>
													</td>
												</tr>
											);
										})}
									</tbody>
								</table>
							</div>
							{loading === false &&
								data?.length > 0 && (
									<div>
										<Pagination
											data={savingsData?.data}
											url={location?.search}
											limit={listParams.limit}
										/>
									</div>
								)}
						</div>
					</div>
			</div>

			<SavingsDetails
				onClose={() => setSavingsDetailsOpen(false)}
				control={savingsDetailsOpen}
			/>

			<SavingsTransactions
				onClose={() => setSavingsTransactionsOpen(false)}
				control={savingsTransactionsOpen}
			/>
		</div>
	)
}


export default GoalSavings;
