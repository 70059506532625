import React, { useState } from "react";
import DashboardBoxes from "components/common/dashboard-boxes";
import Dropdown from "utils/dropdown";
import Download from "components/common/download";
import SearchFilter from "components/common/filter";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactComponent as IconEmployees } from "assets/images/icons/project-icons/employers.svg";
import { ReactComponent as IconNav } from "assets/images/icons/project-icons/Navicon-v.svg";
// import CenterModal from "components/misc/CenterModal";
import { selectApprovalItem } from "store/entities/approvals/action";
import ApprovalDetails from "components/__new/approvals/ApprovalDetails";
import ApproveRequestModal from "components/__new/approvals/ApproveRequestModal";
import DeclineRequestModal from "components/__new/approvals/DeclineRequestModal";


const dropdownTableIcon = (
	<IconNav className="w-8 h-8 p-1.5 relative top-1 cursor-pointer group-hover:bg-gray-200 rounded" />
);

// const columns = [
// 	{ label: "Employer ID", key: "userId" },
// 	{ label: "Date Registered", key: "createdAt" },
// 	{ label: "Company Name", key: "companyName" },
// 	{ label: "First Name", key: "firstName" },
// 	{ label: "Last Name", key: "lastName" },
// 	{ label: "Email Address", key: "email" },
// 	{ label: "Phone Number", key: "phoneNumber" },
// 	{ label: "Job Title", key: "jobTitle" },
// 	{ label: "Company Size", key: "company.estimatedEmployees" },
// 	{ label: "Balance", key: "balance" },
// 	{ label: "Salary Withdrawal Limit", key: "salaryWithdrawalLimit" },
// 	{ label: "Bank Statement File", key: "company.bankStatementFile" },
// 	{ label: "Active", key: "isActive" },
// 	{ label: "Disabled", key: "disabled" },
// ];

// const truncate = (text) => text?.length > 9 ? text?.substring(0,9) + "..." : text;

const ApprovalsList = () => {
	const dispatch = useDispatch();
	
	const [isDetailsOpen, setIsDetailsOpen] = useState(false);
	 // eslint-disable-next-line
	const [isApproveModalOpen, setIsApproveModalOpen] = useState(false);
	const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
	const [rejectReason, setRejectReason] = useState("");
	
	// const { userType } = useSelector((state) => state.auth.userPersist.data);
	const { selectedApproval } = useSelector((state) => state.entities.approvals);
	
	const metrics = {
		dashboardProps1: {
			iconBg: "bg-blue-200",
			icon: (
				<IconEmployees className="customizeSVG text-blue-600 m-auto w-5 h-5" />
			),
			titleText: "Number of requests made",
			mainText: "-",
		},
		dashboardProps2: {
			iconBg: "bg-green-200",
			icon: (
				<FontAwesomeIcon
					icon="check-circle"
					className="customizeSVG text-green-600 m-auto w-5 h-5"
				/>
			),
			titleText: "Number of requests approved",
			mainText: "-",
		},
		dashboardProps3: {
			iconBg: "bg-red-200",
			icon: (
				<FontAwesomeIcon
					icon="times-circle"
					className="customizeSVG text-red-600 m-auto w-5 h-5"
				/>
			),
			titleText: "Number of awaiting approvals",
			mainText: "-",
		},
		dashboardProps4: {
			iconBg: "bg-red-200",
			icon: (
				<FontAwesomeIcon
					icon="times-circle"
					className="customizeSVG text-red-600 m-auto w-5 h-5"
				/>
			),
			titleText: "Number of requests rejected",
			mainText: "-",
		},
	};
	
	const proceedToApprove = () => {
		// call endpoint to approve request
	}
	
	const proceedToDecline = () => {
		// call endpoint to decline request
	}
	
	const toggleDetailsModal = () => setIsDetailsOpen(!isDetailsOpen);
	const toggleRejectionModal = () => setIsRejectModalOpen(!isRejectModalOpen);
	const toggleApprovalModal = () => setIsApproveModalOpen(!isApproveModalOpen);
	
		// const displayDetails = (details) => { 
		// 	if (getEmployerLoading === true) {
		// 		return <FontAwesomeIcon icon="spinner" spin className="text-md" />;
		// 	}
		// 	return details;
		// }
		
		// console.log('employer :>> ', employer?.employer);
		
		return (
			<div>
				
				<ApprovalDetails
					control={isDetailsOpen}
					onClose={() => toggleDetailsModal()}
					onApprove={toggleApprovalModal}
					onReject={toggleRejectionModal}
					// selectedEmployer={this.state.selectedEmployer}
					// rejectOnboarding={rejectEmployerOnboarding}
					// loading={rejectEmployerOnboardingLoading}
					// refetchData={refetchData}
					selectedApproval={selectedApproval}
				/>
				
				<ApproveRequestModal 
					control={isApproveModalOpen}
					onClose={toggleApprovalModal}
					onProceed={proceedToApprove}
				/>
				
				<DeclineRequestModal
					control={isRejectModalOpen}
					onClose={toggleRejectionModal}
					onProceed={proceedToDecline}
					rejectReason={rejectReason}
					setRejectReason={setRejectReason}
				/>
				
				<div>
					<div className="lg:flex">
						<div className="w-full lg:w-1/2">
							<div className="page-title">Approvals</div>
						</div>
						<div className="w-full lg:w-1/2 flex space-x-4 mt-2 lg:mt-0 lg:justify-end">
							<div>
								<Download
									tooltip="Download employee list"
									format="csv"
									filename="All Businesses.csv"
									// columns={columns}
									// ref={(instance) => {
									// 	this.Download = instance;
									// }}
									click={() => {
										// this.props
										// 	.downloadGetAllEmployers({
										// 		...getAllEmployersParams,
										// 		page: 1,
										// 		limit: 10000,
										// 	})
										// 	.then(() => {
										// 		this.Download.processDownload();
										// 	});
									}}
									// loading={this.props.downloadLoading}
									// response={this.props.downloadAllEmployers}
								/>
							</div>

							<div>
								<SearchFilter
									search={{
										name: "keyword",
										placeholder: "Keyword search",
									}}
									filters={[
										{
											title: "Date Requested",
											dataType: "date-range",
											options: {
												from: {
													name: "requestedFrom",
													placeholder: "Start Date",
												},
												to: {
													name: "requestedTo",
													placeholder: "End Date",
												},
											},
										},
										{
											title: "Date Approved",
											dataType: "date-range",
											options: {
												from: {
													name: "approvedFrom",
													placeholder: "Start Date",
												},
												to: {
													name: "approvedTo",
													placeholder: "End Date",
												},
											},
										},
										{
											title: "Status",
											name: "status",
											dataType: "checkbox",
											options: [
												{
													display: "Approved",
													payload: "approved",
												},
												{
													display: "Pending",
													payload: "pending",
												},
												{
													display: "Rejected",
													payload: "rejected",
												},
											],
										},
										{
											title: "Approver",
											dataType: "plain-text",
											option: {
												name: "approver",
												placeholder: "Enter approver's name",
											},
										},
									]}
								/>
							</div>
						</div>
					</div>

					<div className="mt-6">
						<div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-6">
							<DashboardBoxes
								data={metrics.dashboardProps1}
								loading={false}
								result={
									500
								}
							/>
							
							<DashboardBoxes
								data={metrics.dashboardProps2}
								loading={false}
								result={
									500
								}
							/>
							
							<DashboardBoxes
								data={metrics.dashboardProps3}
								loading={false}
								result={
									500
								}
							/>
							
							<DashboardBoxes
								data={metrics.dashboardProps4}
								loading={false}
								result={
									500
								}
							/>
						</div>
					</div>

					<div className="mt-10">
						<div className="lg:flex">
							<div className="w-full lg:w-1/2 flex">
								<div className="page-title-mini my-auto">
									Approval Table
								</div>
							</div>
						</div>

						<div className="mt-3">
							<div className="table-container">
								<table className="table table-auto table-rounded table-border">
									<thead>
										<tr>
											<th>Date Requested</th>
											<th className="whitespace-nowrap">Workflow Title</th>
											<th>Requester</th>
											<th>Approvers</th>
											<th>Status</th>
											<th>Actions</th>
										</tr>
									</thead>
									<tbody>
										{/* {loading === true && (
											<tr>
												<td colSpan="6">
													<div className="table-info">
														<FontAwesomeIcon icon="spinner" spin />
														<div className="font-bold uppercase">Loading</div>
													</div>
												</td>
											</tr>
										)}

										{loading === false && !data.docs && (
											<tr>
												<td colSpan="6">
													<div className="table-info">
														<FontAwesomeIcon icon="unlink" />
														<div className="font-bold uppercase">
															An error occurred
															<br />
															Please try again later.
														</div>
													</div>
												</td>
											</tr>
										)}

										{loading === false &&
											data.docs &&
											data.docs.length <= 0 && (
												<tr>
													<td colSpan="6">
														{data.page <= data.totalPages && (
															<div className="table-info">
																<FontAwesomeIcon icon="list" />
																<div className="font-bold uppercase">
																	No results found
																</div>
															</div>
														)}

														{data.page > data.totalPages && (
															<div className="table-info">
																<FontAwesomeIcon icon="exclamation-triangle" />
																<div className="font-bold uppercase">
																	Invalid Page Number
																</div>
																<div className="mt-2">
																	<Link
																		to="?page=1"
																		className="btn btn-ep-blue btn-sm mx-auto">
																		Goto Page 1
																	</Link>
																</div>
															</div>
														)}
													</td>
												</tr>
										)} */}

										{/* {loading === false &&
											data.docs &&
											data.docs.length > 0 &&
											data.docs.map((employer, index) => {
												const hasBulkPay = employer?.products?.includes("BULK_PAY")
												const hasOnDemandPay = employer?.products?.includes("ON_DEMAND_PAY")
												const hasPayroll = employer?.products?.includes("PAYROLL")
												
												return (
													<tr key={index}>
														<td>{DateFormat(employer.createdAt?.split("T")[0])}</td>
														<td className="w-4">
															<span onClick={() => {
																	this.setState({selectedEmployer: employer})
																	this.toggleViewDetails(true, employer)
																	}} className="hover:underline cursor-pointer capitalize">
																{employer?.company?.companyName || "-"}
															</span>
														</td>
														<td>
															{employer?.email}
														</td>
														<td>
															{employer?.phoneNumber}
														</td>
														<td>
															{employer.disabled && (
																<div className="label label-red uppercase">
																	Disabled
																</div>
															)}

															{!employer.disabled && (
																<>
																	{employer.isActive && (
																		<div className="label label-green uppercase">
																			Active
																		</div>
																	)}
																	{!employer.isActive && (
																		<div className="label label-yellow uppercase">
																			Pending
																		</div>
																	)}
																</>
															)}
														</td>
														<td>
															<div>
																<Dropdown
																	icon={dropdownTableIcon}
																	menu={[
																		{
																			type: "div",
																			text: "View Details",
																			click: () => {}
																		},
																		
																		// ((hasBulkPay || hasPayroll) && 
																		// 	{
																		// 		type: "link",
																		// 		text: "View Admins",
																		// 		link: `/businesses/${employer._id}/admins`,
																		// 	}
																		// ),
																		
																		
																	]}
																/>
															</div>
														</td>
													</tr>
												)
										})} */}
										
										
										<tr>
											<td>23/10/2023</td>
											{/* <td>{DateFormat("23-10-2023")}</td> */}
											<td className="whitespace-nowrap">
												Bolt Ride Bundle
											</td>
											<td>
												{/* {employer?.email} */} Chioma Nwadiko
											</td>
											<td>
												{/* {employer?.phoneNumber} */} Laolu, Nonso
											</td>
											<td>
												<div className="label label-red uppercase">
													Disabled
												</div>

												{/* {!employer.disabled && (
													<>
														{employer.isActive && (
															<div className="label label-green uppercase">
																Active
															</div>
														)}
														{!employer.isActive && (
															<div className="label label-yellow uppercase">
																Pending
															</div>
														)}
													</>
												)} */}
											</td>
											<td>
												<div>
													<Dropdown
														icon={dropdownTableIcon}
														menu={[
															{
																type: "div",
																text: "View Details",
																click: () => {
																	dispatch(selectApprovalItem({payload: "here"}))
																	toggleDetailsModal();
																}
															},
															{
																type: "div",
																text: "Approve",
																click: () => {
																	dispatch(selectApprovalItem({payload: "here"}))
																	toggleApprovalModal();
																}
															},
															{
																type: "div",
																text: "Reject",
																click: () => {
																	dispatch(selectApprovalItem({payload: "here"}))
																	toggleRejectionModal();
																}
															},
														]}
													/>
												</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	
}


// const OnboardingRejectionModal = ({control, onClose, selectedEmployer, rejectOnboarding, loading, refetchData}) => {
// 	const [reason, setReason] = useState("");
	
// 	const onReject = () => {
// 		rejectOnboarding({
// 			id: selectedEmployer?._id,
// 			payload: {rejectComment: reason}
// 		}).then(() => {
// 			setReason("");
// 			refetchData();
// 		})
// 	}
	
// 	return (
// 		<CenterModal control={control} onClose={onClose}>
// 		<h1 className="lg:text-lg font-semibold">Reason For Rejection</h1>
		
// 		<div className="form-group mt-8">
// 			<label>
// 				Rejection Reasons
// 			</label>
// 			<textarea style={{ height: "120px", resize: "none" }} className="p-2 w-full border border-[#D1D5DB] rounded-md focus:ring-0 outline-none" value={reason} onChange={(e) => setReason(e.target.value)} />
//         </div>
		
// 		<div className="flex items-center justify-center lg:flex-row lg:space-x-2">
			
// 			<button
// 				type="button"
// 				onClick={onClose}
// 				className="btn btn-md border border-ep-primary-500 text-ep-primary-500 mb-4 lg:mb-0 w-full lg:w-2/4 focus:ring-0"
// 				disabled={loading}>
// 				Cancel
// 			</button>
			
// 			<button
// 				disabled={reason?.length < 10 || loading}
// 				type="button" 
// 				onClick={onReject}
// 				className="btn btn-md border bg-red-500 text-white w-full lg:w-2/4 focus:ring-0">
// 				{loading ? "Submitting..." : "Reject"}
// 			</button>
// 		</div>
// 	</CenterModal>
// 	)
// }

export default ApprovalsList;
