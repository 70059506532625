import * as actions from "./actionTypes";
import { toast } from 'react-toastify';

const initialState = {
    mobileSettingsLoading: false,
    mobileSettings: {},
    mobileSettingsUpdateLoading: false,
    mobileSettingsUpdateSuccess: false,

    feesSettingsLoading: false,
    feesSettings: [],
    groupedFeesSettingsLoading: false,
    groupedFeesSettings: [],
    feeSettingsLoading: false,
    feeSettings: {},
    feesSettingsCreateLoading: false,
    feesSettingsCreateSuccess: false,
    feesSettingsUpdateLoading: false,
    feesSettingsUpdateSuccess: false,
    feesSettingsDeleteLoading: false,
    feesSettingsDeleteSuccess: false,
    
    selectedPlatformFee: {},

    goalSettingsLoading: false,
    goalSettingsData: null,

    flexSettingsLoading: false,
    flexSettingsData: null,

    updateSavingsSettingsLoading: false,
    updateflexSettingsLoading: false,

    billPaymentSettingsLoading: false,
    billPaymentSettings: {},
    billPaymentSettingLoading: false,
    billPaymentSetting: {},
    billPaymentSettingDeleteLoading: false,
    billPaymentSettingDelete: {},

    yieldSettingsLoading: false,
    yieldSettings: {},
    yieldSettingsUpdateLoading: false,
    yieldSettingsUpdate: {},

    reloadPage: false,
};


export default function reducer(state = initialState, action) {
    switch (action.type) {

         case actions.UPDATE_FLEX_SAVINGS_SETTINGS_START:
            state = {
                ...state,
             updateflexSettingsLoading: true,
            }
            return state;

         case actions.UPDATE_FLEX_SAVINGS_SETTINGS_DONE:
            toast.success('Settings Updated');
            state = {
                ...state,
             updateflexSettingsLoading: false,
            }
            return state;

         case actions.UPDATE_FLEX_SAVINGS_SETTINGS_FAILED:
            state = {
                ...state,
             updateflexSettingsLoading: false,
            }
            return state;

         case actions.UPDATE_GOAL_SAVINGS_SETTINGS_START:
            state = {
                ...state,
             updateSavingsSettingsLoading: true,
            }
            return state;

         case actions.UPDATE_GOAL_SAVINGS_SETTINGS_DONE:
            toast.success('Settings Updated');
            state = {
                ...state,
             updateSavingsSettingsLoading: false,
            }
            return state;

         case actions.UPDATE_GOAL_SAVINGS_SETTINGS_FAILED:
            state = {
                ...state,
             updateSavingsSettingsLoading: false,
            }
            return state;

         case actions.GET_FLEX_SAVINGS_SETTINGS_START:
            state = {
                ...state,
             flexSettingsLoading: true,
            }
            return state;

         case actions.GET_FLEX_SAVINGS_SETTINGS_DONE:
            state = {
                ...state,
             flexSettingsLoading: false,
             flexSettingsData: action.payload
            }
            return state;

         case actions.GET_FLEX_SAVINGS_SETTINGS_FAILED:
            state = {
                ...state,
             flexSettingsLoading: false,
             flexSettingsData: null
            }
            return state;

         case actions.GET_GOAL_SAVINGS_SETTINGS_START:
            state = {
                ...state,
             goalSettingsLoading: true,
            }
            return state;

         case actions.GET_GOAL_SAVINGS_SETTINGS_DONE:
            state = {
                ...state,
             goalSettingsLoading: false,
             goalSettingsData: action.payload
            }
            return state;

         case actions.GET_GOAL_SAVINGS_SETTINGS_FAILED:
            state = {
                ...state,
             goalSettingsLoading: false,
             goalSettingsData: null
            }
            return state;


        case actions.GET_MOBILE_SETTINGS:
            state = {
                ...state,
                mobileSettingsLoading: false,
                mobileSettings: { ...action.payload.data }
            }
            return state;


        case actions.GET_MOBILE_SETTINGS_START:
            state = {
                ...state,
                mobileSettingsLoading: true,
                mobileSettings: {},
            }
            return state;


        case actions.GET_MOBILE_SETTINGS_FAILED:
            toast.error(action.payload.message);
            state = {
                ...state,
                mobileSettingsLoading: false,
                mobileSettings: {},
            }
            return state;


        case actions.UPDATE_MOBILE_SETTINGS:
            toast.success(action.payload.message);
            state = {
                ...state,
                mobileSettingsUpdateLoading: false,
                mobileSettingsUpdateSuccess: true
            }
            return state;


        case actions.UPDATE_MOBILE_SETTINGS_START:
            state = {
                ...state,
                mobileSettingsUpdateLoading: true,
            }
            return state;


        case actions.UPDATE_MOBILE_SETTINGS_FAILED:
            toast.error(action.payload.message);
            state = {
                ...state,
                mobileSettingsUpdateLoading: false,
            }
            return state;



        case actions.SELECT_PLATFORM_FEE:
            state = {
                ...state,
                selectedPlatformFee: action.payload,
            }
            return state;


        case actions.GET_FEES_SETTINGS:
            state = {
                ...state,
                feesSettingsLoading: false,
                feesSettings: action.payload.fees,
            }
            return state;


        case actions.GET_FEES_SETTINGS_START:
            state = {
                ...state,
                feesSettingsLoading: true,
            }
            return state;


        case actions.GET_FEES_SETTINGS_FAILED:
            toast.error(action.payload.message);
            state = {
                ...state,
                feesSettingsLoading: false,
                feesSettings: [],
            }
            return state;




        case actions.GET_GROUPED_FEES_SETTINGS:
            state = {
                ...state,
                groupedFeesSettingsLoading: false,
                groupedFeesSettings: action.payload.fees,
            }
            return state;


        case actions.GET_GROUPED_FEES_SETTINGS_START:
            state = {
                ...state,
                groupedFeesSettingsLoading: true,
            }
            return state;


        case actions.GET_GROUPED_FEES_SETTINGS_FAILED:
            toast.error(action.payload.message);
            state = {
                ...state,
                groupedFeesSettingsLoading: false,
                groupedFeesSettings: [],
            }
            return state;


        case actions.GET_FEE_SETTINGS:
            state = {
                ...state,
                feeSettingsLoading: false,
                feeSettings: { ...action.payload.fees }
            }
            return state;


        case actions.GET_FEE_SETTINGS_START:
            state = {
                ...state,
                feeSettingsLoading: true,
            }
            return state;


        case actions.GET_FEE_SETTINGS_FAILED:
            toast.error(action.payload.message);
            state = {
                ...state,
                feeSettingsLoading: false,
                feeSettings: {}
            }
            return state;


        case actions.CREATE_FEES_SETTINGS:
            toast.success(action.payload.message);
            state = {
                ...state,
                feesSettingsCreateLoading: false,
                feesSettingsCreateSuccess: true
            }
            return state;


        case actions.CREATE_FEES_SETTINGS_START:
            state = {
                ...state,
                feesSettingsCreateLoading: true,
            }
            return state;


        case actions.CREATE_FEES_SETTINGS_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }

            state = {
                ...state,
                feesSettingsCreateLoading: false,
            }
            return state;


        case actions.UPDATE_FEES_SETTINGS:
            toast.success(action.payload.message);
            state = {
                ...state,
                feesSettingsUpdateLoading: false,
                feesSettingsUpdateSuccess: true,
            }
            return state;


        case actions.UPDATE_FEES_SETTINGS_START:
            state = {
                ...state,
                feesSettingsUpdateLoading: true,
                feesSettingsUpdateSuccess: false,
            }
            return state;


        case actions.UPDATE_FEES_SETTINGS_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }

            state = {
                ...state,
                feesSettingsUpdateLoading: false,
                feesSettingsUpdateSuccess: false,
            }
            return state;


        case actions.DELETE_FEES_SETTINGS:
            toast.success(action.payload.message);
            state = {
                ...state,
                feesSettingsDeleteLoading: false,
                feesSettingsDeleteSuccess: true,
                reloadPage: true
            }
            return state;


        case actions.DELETE_FEES_SETTINGS_START:
            toast("Deleting Fee");
            state = {
                ...state,
                feesSettingsDeleteLoading: true,
                feesSettingsDeleteSuccess: false,
            }
            return state;


        case actions.DELETE_FEES_SETTINGS_FAILED:
            toast.error(action.payload.message);
            state = {
                ...state,
                feesSettingsDeleteLoading: false,
                feesSettingsDeleteSuccess: false,
            }
            return state;


        case actions.GET_BILL_PAYMENT_SETTINGS:
            state = {
                ...state,
                billPaymentSettingsLoading: false,
                billPaymentSettings: action.payload,
            }
            return state;


        case actions.GET_BILL_PAYMENT_SETTINGS_START:
            state = {
                ...state,
                billPaymentSettingsLoading: true,
                billPaymentSettings: {},
            }
            return state;


        case actions.GET_BILL_PAYMENT_SETTINGS_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }
            state = {
                ...state,
                billPaymentSettingsLoading: false,
                billPaymentSettings: action.payload,
            }
            return state;


        case actions.CREATE_BILL_PAYMENT_SETTINGS:
            toast.success('Settings Created');
            state = {
                ...state,
                billPaymentSettingLoading: false,
                billPaymentSetting: action.payload,
            }
            return state;


        case actions.CREATE_BILL_PAYMENT_SETTINGS_START:
            state = {
                ...state,
                billPaymentSettingLoading: true,
                billPaymentSetting: {},
            }
            return state;


        case actions.CREATE_BILL_PAYMENT_SETTINGS_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }
            state = {
                ...state,
                billPaymentSettingLoading: false,
                billPaymentSetting: action.payload,
            }
            return state;


        case actions.UPDATE_BILL_PAYMENT_SETTINGS:
            toast.success('Settings Updated');
            state = {
                ...state,
                billPaymentSettingLoading: false,
                billPaymentSetting: action.payload,
            }
            return state;


        case actions.UPDATE_BILL_PAYMENT_SETTINGS_START:
            state = {
                ...state,
                billPaymentSettingLoading: true,
                billPaymentSetting: {},
            }
            return state;


        case actions.UPDATE_BILL_PAYMENT_SETTINGS_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }
            state = {
                ...state,
                billPaymentSettingLoading: false,
                billPaymentSetting: action.payload,
            }
            return state;


        case actions.DELETE_BILL_PAYMENT_SETTINGS:
            toast.success('Settings Deleted');
            state = {
                ...state,
                billPaymentSettingDeleteLoading: false,
                // billPaymentSettingDelete: action.payload,
                billPaymentSettingDelete: {status: "success"},
            }
            return state;


        case actions.DELETE_BILL_PAYMENT_SETTINGS_START:
            state = {
                ...state,
                billPaymentSettingDeleteLoading: true,
                billPaymentSettingDelete: {},
            }
            return state;


        case actions.DELETE_BILL_PAYMENT_SETTINGS_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }
            state = {
                ...state,
                billPaymentSettingDeleteLoading: false,
                billPaymentSettingDelete: action.payload,
            }
            return state;


        case actions.BILL_PAYMENT_SETTINGS_RESET:
            state = {
                ...state,
                billPaymentSettingLoading: false,
                billPaymentSetting: {},
                billPaymentSettingDeleteLoading: false,
                billPaymentSettingDelete: {},
            }
            return state;


        case actions.GET_YIELD_SETTINGS:
            state = {
                ...state,
                yieldSettingsLoading: false,
                yieldSettings: action.payload,
            }
            return state;


        case actions.GET_YIELD_SETTINGS_START:
            state = {
                ...state,
                yieldSettingsLoading: true,
                yieldSettings: {},
            }
            return state;


        case actions.GET_YIELD_SETTINGS_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }
            state = {
                ...state,
                yieldSettingsLoading: false,
                yieldSettings: action.payload,
            }
            return state;


        case actions.UPDATE_YIELD_SETTINGS:
            state = {
                ...state,
                yieldSettingsUpdateLoading: false,
                yieldSettingsUpdate: action.payload,
            }
            return state;


        case actions.UPDATE_YIELD_SETTINGS_START:
            state = {
                ...state,
                yieldSettingsUpdateLoading: true,
                yieldSettingsUpdate: {},
            }
            return state;


        case actions.UPDATE_YIELD_SETTINGS_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }
            state = {
                ...state,
                yieldSettingsUpdateLoading: false,
                yieldSettingsUpdate: action.payload,
            }
            return state;


        case actions.UPDATE_YIELD_SETTINGS_RESET:
            state = {
                ...state,
                yieldSettingsUpdateLoading: false,
                yieldSettingsUpdate: {},
            }
            return state;


        case actions.RELOAD_PAGE_RESET:
            state = {
                ...state,
                reloadPage: false
            }
            return state;


        default:
            return state;
    }
}