import * as actions from "./actionTypes";
import { toast } from 'react-toastify';

const initialState = {
    loading: false,
    allWithdrawals: {},
    downloadLoading: false,
    downloadAllWithdrawals: {},
    withdrawalsCountLoading: false,
    withdrawalsCount: {},
    employeeWithdrawalsLoading: false,
    employeeWithdrawals: {},
    retryWithdrawalLoading: false,
    employeeWallet: {},
    employeeWalletLoading: false,
    walletSummaryLoading: false,
    walletSummary: {},
    walletTransactionsLoading: false,
    walletTransactions: {},
    walletTransactionsDownloadLoading: false,
    walletTransactionsDownload: {},
    reloadPage: false,
};


export default function reducer(state = initialState, action) {
    switch (action.type) {

        case actions.GET_ALL_WITHDRAWALS:
            state = {
                ...state,
                loading: false,
                allWithdrawals: { ...action.payload }
            }
            return state;


        case actions.GET_ALL_WITHDRAWALS_START:
            state = {
                ...state,
                loading: true,
            }
            return state;


        case actions.GET_ALL_WITHDRAWALS_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }

            state = {
                ...state,
                loading: false,
                allWithdrawals: {},
            }
            return state;


        case actions.DOWNLOAD_GET_ALL_WITHDRAWALS:
            state = {
                ...state,
                downloadLoading: false,
                downloadAllWithdrawals: { ...action.payload.withdrawals }
            }
            return state;


        case actions.DOWNLOAD_GET_ALL_WITHDRAWALS_START:
            toast("Downloading Data...");
            state = {
                ...state,
                downloadLoading: true,
                downloadAllWithdrawals: {},
            }
            return state;


        case actions.DOWNLOAD_GET_ALL_WITHDRAWALS_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }

            state = {
                ...state,
                downloadLoading: false,
                downloadAllWithdrawals: {},
            }
            return state;


        case actions.EMPLOYEE_WITHDRAWALS:
            // console.log(action.payload);
            state = {
                ...state,
                employeeWithdrawalsLoading: false,
                employeeWithdrawals: { ...action.payload }
            }
            return state;


        case actions.EMPLOYEE_WITHDRAWALS_START:
            state = {
                ...state,
                employeeWithdrawalsLoading: true,
            }
            return state;


        case actions.EMPLOYEE_WITHDRAWALS_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }

            state = {
                ...state,
                employeeWithdrawalsLoading: false,
                employeeWithdrawals: {},
            }
            return state;


        case actions.RETRY_WITHDRAWAL:
            // toast.success("Withdrawal Completed");
            toast.success(action.payload.message);
            state = {
                ...state,
                retryWithdrawalLoading: false,
                reloadPage: true,
            }
            return state;


        case actions.RETRY_WITHDRAWAL_START:
            toast("Retrying Withdrawal");
            state = {
                ...state,
                retryWithdrawalLoading: true,
            }
            return state;


        case actions.RETRY_WITHDRAWAL_FAILED:
            toast.error(action.payload.message);
            state = {
                ...state,
                retryWithdrawalLoading: false,
            }
            return state;


        case actions.EMPLOYEE_WALLET:
            state = {
                ...state,
                employeeWalletLoading: false,
                employeeWallet: { ...action.payload.data },
            }
            return state;


        case actions.EMPLOYEE_WALLET_START:
            state = {
                ...state,
                employeeWalletLoading: true,
                employeeWallet: {},
            }
            return state;


        case actions.EMPLOYEE_WALLET_FAILED:
            toast.error(action.payload.message);
            state = {
                ...state,
                employeeWalletLoading: false,
            }
            return state;


        case actions.GET_WALLET_SUMMARY:
            state = {
                ...state,
                walletSummaryLoading: false,
                walletSummary: { ...action.payload.data },
            }
            return state;


        case actions.GET_WALLET_SUMMARY_START:
            state = {
                ...state,
                walletSummaryLoading: true,
                walletSummary: {},
            }
            return state;


        case actions.GET_WALLET_SUMMARY_FAILED:
            toast.error(action.payload.message);
            state = {
                ...state,
                walletSummaryLoading: false,
            }
            return state;


        case actions.GET_WALLET_TRANSACTIONS:
            state = {
                ...state,
                walletTransactionsLoading: false,
                walletTransactions: { ...action.payload.data },
            }
            return state;


        case actions.GET_WALLET_TRANSACTIONS_START:
            state = {
                ...state,
                walletTransactionsLoading: true,
                walletTransactions: {},
            }
            return state;


        case actions.GET_WALLET_TRANSACTIONS_FAILED:
            toast.error(action.payload.message);
            state = {
                ...state,
                walletTransactionsLoading: false,
            }
            return state;


        case actions.DOWNLOAD_WALLET_TRANSACTIONS:
            state = {
                ...state,
                walletTransactionsDownloadLoading: false,
                walletTransactionsDownload: { ...action.payload.data },
            }
            return state;


        case actions.DOWNLOAD_WALLET_TRANSACTIONS_START:
            state = {
                ...state,
                walletTransactionsDownloadLoading: true,
                walletTransactionsDownload: {},
            }
            return state;


        case actions.DOWNLOAD_WALLET_TRANSACTIONS_FAILED:
            toast.error(action.payload.message);
            state = {
                ...state,
                walletTransactionsDownloadLoading: false,
            }
            return state;


        case actions.GET_ALL_WITHDRAWALS_COUNT:
            state = {
                ...state,
                withdrawalsCountLoading: false,
                withdrawalsCount: { ...action.payload.withdrawalCount },
            }
            return state;


        case actions.GET_ALL_WITHDRAWALS_COUNT_START:
            state = {
                ...state,
                withdrawalsCountLoading: true,
                withdrawalsCount: {},
            }
            return state;


        case actions.GET_ALL_WITHDRAWALS_COUNT_FAILED:
            toast.error(action.payload.message);
            state = {
                ...state,
                withdrawalsCountLoading: false,
                withdrawalsCount: {},
            }
            return state;


        case actions.RELOAD_PAGE_RESET:
            state = {
                ...state,
                reloadPage: false
            }
            return state;


        default:
            return state;
    }
}