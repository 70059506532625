import React from 'react'
import Maybe from '../common/Maybe'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ReactComponent as Reset } from "assets/images/icons/project-icons/Reset.svg";


const BvnInformationView = ({copyItem, toggleBvnReset, displayDetails, user, detailKeyClass, detailValueClass}) => {
    return (
        <div className='w-full'>
            <div className="w-full h-px mt-8 bg-gray-200"></div>
            <div className="font-semibold text-sm mt-10">BVN Information</div>
            <div className="mt-5 grid grid-cols-2 gap-x-4">
                <div className="space-y-5">
                    <div className="my-auto">
                        <div className={detailKeyClass}>Bank Verification Number</div>
                        <div className={detailValueClass}>
                            {displayDetails(user?.bvn)}
                        </div>
                    </div>
                    <div className="my-auto">
                        <div className={detailKeyClass}>
                            BVN Reference
                        </div>
                        <div className="mt-1 font-medium capitalize text-sm flex items-center justify-start">
                            {displayDetails(user?.bvnReference)}
                            <Maybe condition={user?.bvnReference?.length > 0}>
                                <span onClick={() => copyItem(user?.bvnReference, "BVN Reference")} className="cursor-pointer z-10 text-ep-blue-deeper">
                                    <FontAwesomeIcon icon="copy" className="text-lg" />
                                </span>
                            </Maybe>
                        </div>
                    </div>
                </div>
                <div className="space-y-5">
                    <div className="my-auto">
                        <div className={detailKeyClass}>Date of Birth</div>
                        <div className={detailValueClass}>
                        {displayDetails(user?.dob)}
                        </div>
                    </div>
                </div>
                
                <Maybe condition={user?.bvn?.length > 0 || user?.bvnValidated === true}>
                    <div className="mt-5">
                        <button onClick={toggleBvnReset} className="btn btn-md btn-transparent-primary rounded-[4px] flex items-center">
                            Reset BVN <span className="ml-2"><Reset width={15} /></span>
                        </button>
                    </div>
                </Maybe>
            </div>
        </div>
    )
}

export default BvnInformationView