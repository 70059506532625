import * as actions from "./actionTypes";
import * as config from "config";


export function getSplitInstructions(params) {
	const {status, ...rest} = params
	const newParams = !status ? {...rest} : {...params}
	return {
		type: config.apiRequestStart,
		payload: {
			baseUrl: config.apiBaseUrl,
			url:`${config.apiGetSplitInstructions}?${new URLSearchParams(newParams).toString()}`,
			method: "get",
			data: params,
			onStart: actions.GET_SPLIT_INSTRUCTIONS_START,
			onSuccess: actions.GET_SPLIT_INSTRUCTIONS_DONE,
			onError: actions.GET_SPLIT_INSTRUCTIONS_FAILED,
		},
	};
}

export function getSinglePaymentHandler(params) {
	return {
		type: config.apiRequestStart,
		payload: {
			baseUrl: config.apiPaymentsBaseUrl,
			url: params.path,
			method: "get",
			data: params,
			onStart: actions.GET_SINGLE_HANDLER_START,
			onSuccess: actions.GET_SINGLE_HANDLER_DONE,
			onError: actions.GET_SINGLE_HANDLER_FAILED,
		},
	};
}

export function getPaymentHandlers(params) {
	const {status, ...rest} = params
	const newParams = !status ? {...rest} : {...params}
	return {
		type: config.apiRequestStart,
		payload: {
			baseUrl: config.apiPaymentsBaseUrl,
			url:`${config.apiGetPaymentHandlers}?${new URLSearchParams(newParams).toString()}`,
			method: "get",
			data: params,
			onStart: actions.GET_PAYMENT_HANDLERS_START,
			onSuccess: actions.GET_PAYMENT_HANDLERS_DONE,
			onError: actions.GET_PAYMENT_HANDLERS_FAILED,
		},
	};
}

export function getAllInvoice(params) {
	return {
		type: config.apiRequestStart,
		payload: {
			url: config.apiGetAllInvoice,
			method: "post",
			data: params,
			onStart: actions.GET_ALL_INVOICE_START,
			onSuccess: actions.GET_ALL_INVOICE,
			onError: actions.GET_ALL_INVOICE_FAILED,
			// useLoginJwt: false,
		},
	};
}

export function downloadGetAllInvoice(params) {
	return {
		type: config.apiRequestStart,
		payload: {
			url: config.apiGetAllInvoice,
			method: "post",
			data: params,
			onStart: actions.DOWNLOAD_GET_ALL_INVOICE_START,
			onSuccess: actions.DOWNLOAD_GET_ALL_INVOICE,
			onError: actions.DOWNLOAD_GET_ALL_INVOICE_FAILED,
		},
	};
}

export function getInvoice(id) {
	return {
		type: config.apiRequestStart,
		payload: {
			url: config.apiGetInvoice + id,
			method: "get",
			data: {},
			onStart: actions.GET_INVOICE_START,
			onSuccess: actions.GET_INVOICE,
			onError: actions.GET_INVOICE_FAILED,
		},
	};
}

export function downloadInvoicePDF(params) {
	return {
		type: config.apiRequestStart,
		payload: {
			url: config.apiDownloadInvoicePDF,
			method: "post",
			data: params,
			onStart: actions.DOWNLOAD_INVOICE_PDF_START,
			onSuccess: actions.DOWNLOAD_INVOICE_PDF,
			onError: actions.DOWNLOAD_INVOICE_PDF_FAILED,
		},
	};
}

export function approveInvoice(id, token) {
	return {
		type: config.apiRequestStart,
		payload: {
			url: config.apiApproveInvoice + id,
			method: "post",
			data: {
				reference: token,
				paymentType: "transfer",
			},
			onStart: actions.APPROVE_INVOICE_START,
			onSuccess: actions.APPROVE_INVOICE,
			onError: actions.APPROVE_INVOICE_FAILED,
		},
	};
}

export function revokeApprovalInvoice(id) {
	return {
		type: config.apiRequestStart,
		payload: {
			url: config.apiRevokeInvoice + id,
			method: "post",
			data: {},
			onStart: actions.REVOKE_APPROVAL_INVOICE_START,
			onSuccess: actions.REVOKE_APPROVAL_INVOICE,
			onError: actions.REVOKE_APPROVAL_INVOICE_FAILED,
		},
	};
}

export function getAllPaymentHistory(params) {
	return {
		type: config.apiRequestStart,
		payload: {
			url: config.apiGetAllPaymentHistory,
			method: "post",
			data: params,
			onStart: actions.GET_ALL_PAYMENT_HISTORY_START,
			onSuccess: actions.GET_ALL_PAYMENT_HISTORY,
			onError: actions.GET_ALL_PAYMENT_HISTORY_FAILED,
			// useLoginJwt: false,
		},
	};
}

export function downloadGetAllPaymentHistory(params) {
	return {
		type: config.apiRequestStart,
		payload: {
			url: config.apiGetAllPaymentHistory,
			method: "post",
			data: params,
			onStart: actions.DOWNLOAD_GET_ALL_PAYMENT_HISTORY_START,
			onSuccess: actions.DOWNLOAD_GET_ALL_PAYMENT_HISTORY,
			onError: actions.DOWNLOAD_GET_ALL_PAYMENT_HISTORY_FAILED,
		},
	};
}

export function resetReloadPage() {
	return {
		type: actions.RELOAD_PAGE_RESET,
		payload: {},
	};
}
