
export const nameToSentenceCase = (fullname) => {
    if (fullname){
        const fullnameSplit = fullname.split(" ");
        let newFullname = [];

        fullnameSplit.map((name) => {
            if (name && name !== ""){
                newFullname.push(name[0]?.toUpperCase() + name?.substring(1))
            }
            return null;
        });

        newFullname = newFullname.join(" ");
        return newFullname;
    }
    return fullname;
}

export default nameToSentenceCase;
