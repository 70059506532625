import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import ContentLoading from './ContentLoading';

const TableFilterTabsWithQuery = ({ queryDataset }) => {
    const history = useHistory();
    const location = useLocation();
    
    const gotoUrlQuery = (params) => {
        const qs = queryString.parse(location.search);

        Object.keys(params).forEach((paramName) => {
            delete qs[paramName];
        });

        let qsToUrl = new URLSearchParams(qs).toString();
        qsToUrl = location.pathname + "?" + ((qsToUrl !== "")? qsToUrl + "&" : "");

        Object.keys(params).forEach((paramName) => {
            if (params[paramName] !== ""){
                qsToUrl += paramName + "=" + params[paramName] + "&";
            }
        });

        qsToUrl = qsToUrl.substring(0, qsToUrl.length - 1);
        history.push(qsToUrl);
    }
    
    
    return (
        <div className="w-full md:flex md:justify-end">
            <div className="xl:flex xl:justify-end mt-1 xl:mt-0">
                <div className="xl:w-auto inline-block box-border-only overflow-auto">
                    <div className="h-10 flex">
                        {queryDataset?.map((tab) => (
                            <div key={tab?.title} onClick={() => {
                                gotoUrlQuery(tab?.onClickPayload);
                                tab?.onClick();
                            }} className={"cursor-pointer page-nav " + (tab?.isActive ? "active" : "")}>
                                <div className="whitespace-nowrap">
                                    {tab?.title}&nbsp;
                                    (<ContentLoading loading={tab?.loading} data={tab?.isActive ? tab?.data : "-"} />)
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TableFilterTabsWithQuery