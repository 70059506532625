import { useState } from "react";
import Maybe from "../Maybe";
import validate from "utils/validate";

export const Textarea = (props) => {
    const { label, placeholder, value, onChange, onBlur, required, readonly, disabled, defaultProps, containerClass, styles, validationName, checkFormValidation } = props;
    const [errors, setErrors] = useState({});

    const doValidation = (e) => {
        if (validationName !== undefined){
            const data = {[validationName]: e.target.value};
            const vErrors = validate(data);
            if (vErrors){
                setErrors(vErrors);
            }
            else{
                setErrors({});
            }
            checkFormValidation(undefined, validationName, e.target.value);
        }
    }

    const doOnBlur = (e) => {
        if (onBlur !== undefined){
            onBlur(e);
        }
    }

    return (
        <div className={`form-group ${containerClass || ""}`}>
            <label>
                {label || ""}
                <Maybe condition={required === true}>
                    <span className="form-input-required">*</span>
                </Maybe>
            </label>

            <textarea
                placeholder={placeholder || ""}
                style={styles}
                
                value={value}
                onChange={(e) => {onChange(e); doValidation(e)}}
                onBlur={(e) => {doOnBlur(e); doValidation(e)}}
                readOnly={readonly || disabled}
                
                {...defaultProps}
                className={"form-input form-input-textarea " + ((readonly || disabled) === true && "disabled")}
            />
            
            {errors[validationName] !== undefined &&
                <div className="form-input-message">
                    {errors[validationName]}
                </div>
            }
        </div>
    )
}

export default Textarea;
