import React, { Component } from 'react';
import Joi from "joi-browser";
import store from "store/store";
import { connect } from "react-redux";
import { nameInitials } from 'utils/nameInitials.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import * as account from "store/auth/account/action";

class Profile extends Component {
    state = {
        account: {
            userName: "",
            email: "",
        },
        password: {
            currentPassword: "",
            password: "",
            password_confirmation: "",
        },
        editProfile: false,
        editPassword: false,
        errors: {}
    }


    toggleEditProfile = () => {
        this.setState({editProfile: !this.state.editProfile});
    }


    toggleEditPassword = () => {
        this.setState({editPassword: !this.state.editPassword});
    }


    formChangeAccount = (e) => {
        const formValue = {...this.state.account};
        formValue[e.currentTarget.name] = e.currentTarget.value;
        this.setState({account: formValue});
    }


    formChangePassword = (e) => {
        const formValue = {...this.state.password};
        formValue[e.currentTarget.name] = e.currentTarget.value;
        this.setState({password: formValue});
    }

    
    schemaAccount = {
        userName: Joi.string().required().label("First Name"),
        email: Joi.string().email().required().label("Email Address"),
    }


    schemaPassword = {
        currentPassword: Joi.string().required().label("Current Password").error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    case "any.empty":
                        err.message = "Please enter your current password";
                        break;
                    default:
                        break;
                }
            });
            return errors;
        }),
        password: Joi.string().required().label("New Password").error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    case "any.empty":
                        err.message = "Please enter your new password";
                        break;
                    default:
                        break;
                }
            });
            return errors;
        }),
        password_confirmation: Joi.any().valid(Joi.ref('password')).label("Confirm Password").error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    case "any.allowOnly":
                        err.message = "Your password does not match";
                        break;
                    default:
                        break;
                }
            });
            return errors;
        }),
    }


    validateAccount = () => {
        const options = { abortEarly: false };
        const result = Joi.validate(this.state.account, this.schemaAccount, options);
        // console.log(result);
        const error = result.error;
        if (!error) return null;
    
        const errors = {};
        for (let item of result.error.details){
            errors[item.path[0]] = item.message;
        }
        return errors;
    };


    validatePassword = () => {
        const options = { abortEarly: false };
        const result = Joi.validate(this.state.password, this.schemaPassword, options);
        // console.log(result);
        const error = result.error;
        if (!error) return null;
    
        const errors = {};
        for (let item of result.error.details){
            errors[item.path[0]] = item.message;
        }
        return errors;
    };


    submitFormAccount = async e => {
        e.preventDefault();
        const errors = this.validateAccount();
        this.setState({errors: errors || {}});
        if (errors){
            // console.log(errors);
            for (var x in errors) {
                toast.error(errors[x]);
            };
            return;
        }
        else{
            // this.props.updateProfile(this.state.account);
        }
    }


    submitFormPassword = async e => {
        e.preventDefault();
        const errors = this.validatePassword();
        this.setState({errors: errors || {}});
        if (errors){
            // console.log(errors);
            for (var x in errors) {
                toast.error(errors[x]);
            };
            return;
        }
        else{
            // this.props.updatePassword(this.state.password);
        }
    }


    componentDidMount() {
        // const currentProfile = {...this.props.user};
        const currentProfile = {
            userName: this.props.user.userName,
            email: this.props.user.email,
        };
        this.setState({account: currentProfile});

        this.unsubscribe = store.subscribe(() => {
            if (this.props.profileUpdated === true){
                this.props.resetUpdateProfile();
                this.props.updateLoggedInPersistedStorage(this.state.account);
            }

            if (this.props.passwordUpdated === true){
                const emptyForm = {...this.state.password, currentPassword: "", password: "", password_confirmation: ""};
                this.setState({password: emptyForm});
                this.props.resetUpdatePassword();
            }
        });
    }

    
    componentWillUnmount() {
        this.unsubscribe();
    }


    render() { 
        const {account, password} = this.state;

        return ( 
            <div className="pt-6 sm:pt-10">
                
                
                <div className="lg:flex">
                    <div className="w-full lg:w-1/2">
                        <div className="page-title">
                            Profile
                        </div>
                    </div>
                </div>


                <div className="mt-6 space-y-6 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-x-6">


                    <div className="col-span-2">

                        <div className="box">
                            <div className="flex">
                                <div className="w-14 h-14 mr-4 my-auto rounded-full flex text-lg font-bold flex-shrink-0 bg-gray-500">
                                    <span className="m-auto text-white">
                                        {nameInitials(this.props.user.userName)}
                                    </span>
                                </div>
                                <div className="flex overflow-hidden">
                                    <div className="my-auto overflow-hidden">
                                        <div className="font-bold text-gray-600 ellipsis capitalize">
                                            {this.props.user.userName}
                                        </div>
                                        <div className="text-sm text-gray-400 ellipsis capitalize">
                                            {this.props.user.userType}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <form className="box mt-6" onSubmit={this.submitFormAccount}>

                            <div className="font-bold text-gray-600">
                                Account Information
                                <button type="button" className="hidden" onClick={this.toggleEditProfile}>
                                    Edit
                                </button>
                            </div>

                            <div className="mt-6 sm:grid sm:grid-cols-2 sm:gap-x-6">
                                
                                <div className="form-group">
                                    <label>
                                        User Name
                                        <span className="form-input-required">*</span>
                                    </label>
                                    <input type="text" name="userName" placeholder="Enter your User Name" className="form-input" value={account.userName} onChange={this.formChangeAccount} disabled={!this.state.editProfile} />
                                </div>

                                <div className="form-group">
                                    <label>
                                        Email Address
                                        <span className="form-input-required">*</span>
                                    </label>
                                    <input type="text" placeholder="Enter your Email Address" className="form-input" value={account.email} disabled />
                                </div>

                            </div>

                            <button type="submit" className={(this.state.editProfile?"btn btn-lg btn-ep-blue mt-2":"hidden")} disabled={this.props.profileUpdateLoading}>
                                <FontAwesomeIcon icon="spinner" spin className={"text-xl mr-2 " + (this.props.profileUpdateLoading?"inline":"hidden")} />
                                Save Changes
                            </button>

                        </form>
                        
                    </div>
                    
                    <form className="box" onSubmit={this.submitFormPassword}>

                        <div className="font-bold text-gray-600">
                            Password
                            <button type="button" className="hidden" onClick={this.toggleEditPassword}>
                                Edit
                            </button>
                        </div>

                        <div className="mt-6 sm:grid sm:grid-cols-1 sm:gap-x-6">
                        
                            <div className="form-group">
                                <label>
                                    Current Password
                                    <span className="form-input-required">*</span>
                                </label>
                                <input type="password" name="currentPassword" placeholder="Enter Current Password" className="form-input" value={password.currentPassword} onChange={this.formChangePassword} disabled={!this.state.editPassword} />
                            </div>

                            <div className="form-group">
                                <label>
                                    New Password
                                    <span className="form-input-required">*</span>
                                </label>
                                <input type="password" name="password" placeholder="Enter New Password" className="form-input" value={password.password} onChange={this.formChangePassword} disabled={!this.state.editPassword} />
                            </div>

                            <div className="form-group">
                                <label>
                                    Confirm Password
                                    <span className="form-input-required">*</span>
                                </label>
                                <input type="password" name="password_confirmation" placeholder="Enter Confirm Password" className="form-input" value={password.password_confirmation} onChange={this.formChangePassword} disabled={!this.state.editPassword} />
                            </div>
                        </div>

                        <button type="submit" className={(this.state.editPassword?"btn btn-lg btn-ep-blue mt-2":"hidden")} disabled={this.props.passwordUpdateLoading}>
                            <FontAwesomeIcon icon="spinner" spin className={"text-xl mr-2 " + (this.props.passwordUpdateLoading?"inline":"hidden")} />
                            Update Password
                        </button>

                    </form>

                </div>
                

            </div>
         );
    }
}


const mapStateToProps = (state) => ({
    user: state.auth.userPersist.data,
    profileUpdateLoading: state.auth.account.profileUpdateLoading,
    profileUpdated: state.auth.account.profileUpdated,
    passwordUpdateLoading: state.auth.account.passwordUpdateLoading,
    passwordUpdated: state.auth.account.passwordUpdated,
});

const mapDispatchToProps = (dispatch) => ({
    updateProfile: (params) => dispatch(account.updateProfile(params)),
    resetUpdateProfile: () => dispatch(account.resetUpdateProfile()),
    updateLoggedInPersistedStorage: (params) => dispatch(account.updateLoggedInPersistedStorage(params)),
    updatePassword: (params) => dispatch(account.updatePassword(params)),
    resetUpdatePassword: () => dispatch(account.resetUpdatePassword()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);