import React from "react";
// import ReactTooltip from 'react-tooltip';
import { Menu } from "@headlessui/react";
import { Link } from "react-router-dom";
import { customizeSVG } from 'utils/customizeSVG.jsx';
import { ReactComponent as IconChevronLeft } from "assets/images/icons/project-icons/chevron-left.svg";
import { ReactComponent as IconChevronRight } from "assets/images/icons/project-icons/chevron-right.svg";
import { ReactComponent as AngleDown } from "assets/images/icons/project-icons/angle-down.svg";
import useUrlNavigation from "hooks/useUrlNavigation";

const Pagination = (props) => {
    const {urlQuery} = useUrlNavigation();
    const {data, limit: dataPerPage, limitQueryParam} = props;
    customizeSVG();
    
    return (

        <>
            {/* <ReactTooltip /> */}

            <div className="py-4 text-sm sm:flex sm:justify-between">

                <div className="my-auto">
                    <div className="">
                        <div className="pagination">

                            {(data.hasPrevPage) && 
                                <Link to={urlQuery("page=" + data.prevPage)} className="pagination-items border">
                                    <IconChevronRight className="customizeSVG pagination-items-icon" />
                                    <div>
                                        Prev
                                    </div>
                                </Link>
                            }

                            {(!data.hasPrevPage) && 
                                <div className="pagination-items border disabled opacity-50">
                                    <IconChevronRight className="customizeSVG pagination-items-icon opacity-50" />
                                    <div>
                                        Prev
                                    </div>
                                </div>
                            }


                            {((data.page !== 1) && (data.page - 3) >= 1) &&
                                <Link to={urlQuery("page=1")} className="pagination-items">
                                    <div>
                                        1
                                    </div>
                                </Link>
                            }

                            {((data.page - 3) > 1) &&
                                <div className="pagination-items-null">
                                    <div>
                                        ...
                                    </div>
                                </div>
                            }
                            
                            {((data.page - 2) >= 1) &&
                                <Link to={urlQuery("page=" + (data.page - 2))} className="pagination-items">
                                    <div>
                                        {data.page - 2}
                                    </div>
                                </Link>
                            }
                            
                            {((data.page - 1) >= 1) &&
                                <Link to={urlQuery("page=" + (data.page - 1))} className="pagination-items">
                                    <div>
                                        {data.page - 1}
                                    </div>
                                </Link>
                            }
                            


                            <Link to={urlQuery("page=" + data.page)} className="pagination-items pagination-items-active">
                                <div>
                                    {data.page}
                                </div>
                            </Link>



                            {((data.page + 1) <= (data.totalPages)) &&
                                <Link to={urlQuery("page=" + (data.page + 1))} className="pagination-items">
                                    <div>
                                        {data.page + 1}
                                    </div>
                                </Link>
                            }

                            {((data.page + 2) <= (data.totalPages)) &&
                                <Link to={urlQuery("page=" + (data.page + 2))} className="pagination-items">
                                    <div>
                                        {data.page + 2}
                                    </div>
                                </Link>
                            }

                            {((data.page + 3) < (data.totalPages)) &&
                                <div className="pagination-items-null">
                                    <div>
                                        ...
                                    </div>
                                </div>
                            }
                            
                            {((data.page !== (data.totalPages)) && (data.page + 2) < (data.totalPages)) &&
                                <Link to={urlQuery("page=" + (data.totalPages))} className="pagination-items">
                                    <div>
                                        {data.totalPages}
                                    </div>
                                </Link>
                            }


                            {(data.hasNextPage) && 
                                <Link to={urlQuery("page=" + (data.nextPage))} className="pagination-items border">
                                    <div>
                                        Next
                                    </div>
                                    <IconChevronLeft className="customizeSVG pagination-items-icon" />
                                </Link>
                            }
                            
                            {(!data.hasNextPage) && 
                                <div className="pagination-items border disabled opacity-50">
                                    <div>
                                        Next
                                    </div>
                                    <IconChevronLeft className="customizeSVG pagination-items-icon opacity-50" />
                                </div>
                            }
                            
                        </div>
                    </div>
                </div>

                <div className="hidden sm:block my-auto">
                    <div className="float-right">
                        <Menu as="div">
                            <div className="group inline-block">
                                <Menu.Button as="div">
                                    <button type="button" className="btn btn-md border btn-gray-hover">
                                        {dataPerPage} per page
                                        <AngleDown className="customizeSVG ml-2" />
                                    </button>
                                </Menu.Button>
                                <Menu.Items as="div" className="dropdown">
                                    <Menu.Item as="div">
                                        <div>
                                            <Link to={urlQuery(`${limitQueryParam ? `page=1&${limitQueryParam}=20` : "page=1&limit=20"}`)} className="dropdown-item">
                                                20 per page
                                            </Link>
                                            <Link to={urlQuery(`${limitQueryParam ? `page=1&${limitQueryParam}=50` : "page=1&limit=50"}`)} className="dropdown-item">
                                                50 per page
                                            </Link>
                                            <Link to={urlQuery(`${limitQueryParam ? `page=1&${limitQueryParam}=100` : "page=1&limit=100"}`)} className="dropdown-item">
                                                100 per page
                                            </Link>
                                        </div>
                                    </Menu.Item>
                                </Menu.Items>
                            </div>
                        </Menu>
                    </div>
                </div>

            </div>
        </>
    );
};

export default Pagination;
