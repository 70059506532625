// Main
export const projectName = "Earnipay";
export const repoName = "earnipay-admin";

// API - middleware
export const apiRequestStart = "API/REQUEST_START";
export const apiRequestSuccess = "API/REQUEST_SUCCESS"; // for logging purpose only
export const apiRequestFailed = "API/REQUEST_FAILED"; // for logging purpose only

// API - base url
export const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
export const apiFeesServiceBaseUrl = process.env.REACT_APP_API_FEES_SERVICE_BASE_URL;
export const apiFeesServiceToken = process.env.REACT_APP_API_FEES_SERVICE_TOKEN;
export const apiPaymentsBaseUrl = process.env.REACT_APP_PAYMENTS_BASE_URL;
export const apiWalletBaseUrl = process.env.REACT_APP_WALLET_BASE_URL;
export const apiMediaBaseUrl = process.env.REACT_APP_API_MEDIA_SERVICE_BASE_URL;
export const apiCreditBaseUrl = process.env.REACT_APP_CREDIT_BASE_URL;
export const apiDummyBaseUrl = "https://jsonplaceholder.typicode.com/posts";

// API - user auth
export const apiLoginUser = "/admin/login";
export const apiForgotPassword = "/admin/recover-password";
export const apiResetPassword = "/admin/reset-password";
export const apiLogoutUser = "/admin/logout";

// API - user profile
export const apiUpdateProfile = "";
export const apiUpdateProfilePassword = "";

// API - dashboard
export const apiGetAllDashboard = "/admin/dashboard";
export const apiGetDashboardPerformanceRevenue = "/reports/revenue-performace";
export const apiGetDashboardPerformanceRepayment = "/reports/repayment-performance";
export const apiGetDashboardPerformanceEmployee = "/reports/employee-performance";
export const apiGetDashboardPerformanceEmployeeWithdrawal = "/reports/employee-withdrawal-performance";
export const apiGetDashboardCharts = "/reports/dashboard-chart";
export const apiGetCompanyStats = "/reports/company-stats";
export const apiGetWithdrawalMonthStats = "/reports/month-stats";

// API - sub accounts
export const apiGetAllSubAccounts = "/admin/get-sub-employers";
export const apiSearchFilterSubAccounts = "/admin/search-filter-sub-employers";
export const apiGetSubAccountMetrics = "/admin/metrics/sub-businesses";

// API - employers
export const apiApproveEmployerOdp = "/admin/approve-odp/";
export const apiRejectEmployerOdp = "/admin/reject-odp/";
export const apiGetEmployers = "/admin/get-employers";
export const apiGetEmployersBalance = "/wallet/admin/get-employer-balance";
export const apiGetCustomersBalance = "/wallet/admin/get-user-balance";
export const apiSearchAndFilterEmployers = "/admin/search-filter-employers";
export const apiGetEmployersCount = "/admin/metrics/businesses";
export const apiGetEmployer = "/admin/get-employer/";
export const apiGetEmployerToken = "/admin/employer/account/sign-in";
export const apiGetEmployerAdmins = "/admin/employers/";
export const apiGetEmployerTransactions = "/admin/business/";
export const apiSearchAndFilterEmployerTransactions = "/admin/business/";
export const apiGetEmployerTransactionsMetrics = "/wallet/admin/business";
export const apiSetEarnings = "/admin/credit-earnings";
export const apiApproveEmployer = "/admin/approve-employer/";
export const apiRejectEmployerOnboarding = "/admin/reject-employer/";
export const apiEnableEmployer = "/admin/employer/enable/";
export const apiDisableEmployer = "/admin/employer/disable/";
export const apiStopEmployeeWithdrawals = "/admin/stop-company-withdrawals";
export const apiResumeEmployeeWithdrawals = "/admin/resume-company-withdrawals";
export const apiResendEmployerVerificationMail = "/user-management/employer/resend-verification-mail";
export const apiResendEmployerCompanyInfoPrompt = "/admin/employer/resend-commpany-policy";
export const apiResendUnenrolledEmployeesInvite = "/miscellaneous/resend-bulk-employee-invite";
export const apiPerformanceEmployees = "/reports/employee-performance";
export const apiPerformanceEmployeesWithdrawals = "/reports/employee-withdrawal-performance";

// API - employees
export const apiResetCustomerFaceId = "/admin/user/reset-verification-document";
export const apiUpdateCustomerFullname = "/admin/user/update-user-profile";
export const apiUploadUserAddressProof = "/admin/user/upload-user-address-proof";
export const apiUserDetailsManualUpload = "/admin/user/update-user-details";
export const apiGetCustomerTransactionsMetrics = "wallet/admin/user-transaction-metrics";
export const apiSearchAndFilterCustomerTransactions = "wallet/admin/search-filter-user-transactions";
export const apiGetCustomerTransactions = "wallet/admin/user-transactions";
export const apiRejectCustomerAddressDoc = "/admin/user/reset-proof-of-address";
export const apiRejectCustomerFaceId = "/admin/user/reset-verification-document";
export const apiResetCustomerBvn = "/admin/user/reset-user-bvn";
export const apiGetAllCustomers = "/admin/users";
export const apiSearchAndFilterCustomers = "/admin/search-filter-users";
export const apiGetSingleCustomer = "/admin/users/";
export const apiToggleCustomerStatus = "/admin/users/";
export const apiGetEmployees = "/admin/get-employees";
export const apiGetEmployeesCount = "/reports/employee-count";
export const apiGetEmployersEmployees = "/admin/employer/get-employees/";
export const apiGetEmployeeDetails = "/employees/admin/";
export const apiEnableEmployee = "/admin/employee/enable/";
export const apiDisableEmployee = "/admin/employee/disable/";
export const apiPokeEmployer = "/admin/employers/poke/";
export const apiResendEmployeeInvite = "/miscellaneous/resend-employee-invite";
export const apiEmployeeWithdrawalsOnceOrMore = "/reports/employee/withdrawal-count";
export const apiArchiveEmployee = "/employees/admin/";
export const apiWithdrawalCharge = "/admin/charge-withdrawal";

// API - admins
export const apiGetAdmins = "/admin/get-all-admins";
export const apiGetAdmin = "/admin/";
export const apiCreateAdmin = "/admin/create";
export const apiUpdateAdmin = "admin/update-admin";
export const apiUpdateAdminStatus = "/admin/disable-enable";
export const apiDeleteAdmin = "";
export const apiAdminResendInvite = "/admin/resend-admin-invite";
export const apiAdminOnboard = "/admin/reset-password";

// API - withdrawals
export const apiGetAllWithdrawals = "/admin/withdrawals/all";
export const apiGetWithdrawalsCount = "/reports/withdrawal-count";
export const apiGetWithdrawalsByEmployeeId = "/admin/withdrawals/";
export const apiRetryWithdrawal = "/admin/employee/withdrawal/approve/";
export const apiGetWalletByEmployeeId = "/wallet/";
export const apiGetWalletTransactions = "/wallet/transactions";
export const apiGetWalletSummary = "/wallet/balance-summary";

// API - payroll
export const apiGetAllPayroll = "/admin/payrolls/all";

// API - invoice
export const apiGetAllInvoice = "/admin/invoice/all";
export const apiGetInvoice = "/admin/invoice/";
export const apiDownloadInvoicePDF = "/miscellaneous/employer/invoice/fetch";
export const apiApproveInvoice = "/admin/invoice/approve/";
export const apiRevokeInvoice = "/admin/invoice/approval/revoke/";

// API - credit
export const apiGetCreditRequests = "/credit-engine/limit/all";
export const apiGetCreditRequestDetails = "/credit-engine/limit/single";
export const apiGetCreditMetrics = "/credit-engine/limit/stats";
export const apiToggleLoanStatus = "/credit-engine/limit/credit-analyst/update-status/";
export const apiGetDirectDebitMetrics = "/credit-engine/application/stats";
export const apiGetDirectDebitRequest = "/credit-engine/application/all";
export const apiGetCreditViewerRequest = "/credit-engine/account/viewer";
export const apiResetLoanLimit = "/credit-engine/limit/credit-analyst/reset/";
export const apiUpdateUserBank = "/credit-engine/account/banks/update";
export const apiGetAllLoans = "/direct-debit";
export const apiMakeLoanPayment = "/direct-debit/manual-payment";
export const apiBulkLimitStatusUpdate = "/control/credit-engine/credit-limit/bulk-limit-status-update";
export const apiCreditLimitSettings = "/control/credit-engine/credit-limit/credit-setting";

// API - payment history
export const apiGetAllPaymentHistory = "/admin/payments/all";
export const apiGetPaymentHandlers = "/payments";

// API - savings/goals
export const apiSearchAndFilterGoalSavings = "/wallet/savings/v1/admin/search-filter-savings";
export const apiGetAllGoalSavings = "/wallet/savings/v1/admin/savings";
export const apiGetSingleGoalSavings = "/wallet/savings/v1/admin/goals";
export const apiGetGoalSavingsSettings = "/wallet/savings/v1/admin/settings";
export const apiSavingsCsvDownload = "/wallet/savings/v1/admin/savings/download";
export const apiGetSavingsMetrics = "/wallet/savings/v1/admin/metrics";

// API - rewards
export const apiGetAllPointsHistory = "/admin/points-history";
export const apiApproveGiftPoint = "/admin/points/gift/";
export const apiRejectPointHistory = "/admin/points/gift/";
export const apiRewardPoints = "/admin/points";
export const apiRewardPointsSearchAndFilter = "/admin/search-filter-points";
export const apiRewards = "/admin/rewards";
export const apiRewardsSearchAndFilter = "/admin/search-filter-rewards";
export const apiRedeemedRewardsSearchAndFilter = "/admin/rewards/search-filter-redeemed-history";
export const apiRewardMetrics = "/admin/metrics/points";
export const apiApproveReward = "/admin/rewards/";
export const apiApprovePointActivity = "/admin/points/";

// API - split instructions
export const apiGetSplitInstructions = "admin/split-instructions/all";

// API - approvals
export const apiGetAllApprovals = "/reports/filters/employer";
export const apiGetSingleApproval = "/reports/filters/employer";
export const apiToggleApprovalStatus = "/reports/filters/employer";

// API - reports
export const apiGetReportFiltersEmployer = "/reports/filters/employer";
export const apiGetReportFiltersEmployee = "/reports/filters/employee";
export const apiGetReportFiltersWithdrawal = "/reports/filters/withdrawal";
export const apiGetReportEmployer = "/reports/employer";
export const apiGetReportEmployee = "/reports/employee";
export const apiGetReportWithdrawal = "/reports/withdrawal";

// API - referrals
export const apiGetReferrals = "/admin/referrals/get-all";
export const apiResendReferralsInvite = "/admin/referrals/resend/";

// API - ambassadors
export const apiAmbassadors = "/ambassadors";
export const apiAmbassadorStats = "/ambassadors/stats";
export const apiAmbassadorReferrals = "/ambassadors/referrals";
export const apiRemoveAmbassador = "/ambassadors/remove";

// API - audit trail
export const apiGetAuditTrail = "/audits";
export const apiGetAuditTrailActions = "/audits/actions";

// API - settings - mobile
export const apiSettings = "/setting";

// API - settings - fees
export const apiGetAllFeeSettings = "/fees";
export const apiGetAllGroupedFeeSettings = "/fees/grouped";
export const apiCreateFeeSettings = "/create";
export const apiEditFeeSettings = "/fee/";
export const apiDeleteFeeSettings = "/fee/";
export const apiCalculateFeeSettings = "/fee/calculate-fee";

// API - settings - bill payment
export const apiGetBillPaymentSettings = "/admin/discounts/fetch";
export const apiCreateBillPaymentSettings = "/admin/discount";
export const apiUpdateBillPaymentSettings = "/admin/discount/";
export const apiDeleteBillPaymentSettings = "/admin/discount/";

// API - settings - yield settings
export const apiGetYieldSettings = "/wallet/savings/v1/admin/yield-settings";
export const apiUpdateYieldSettings = "/wallet/savings/v1/admin/yield-settings";

// API - misc
export const apiGenerateEVA = "/miscellaneous/create-eva";
export const apiGetPaystackBalance = "/integration-service/paystack-balance";

// API - banks
export const apiGetBanks = "/miscellaneous/get-bank-list";
export const apiFileUpload = "/files";