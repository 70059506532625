import React, { Component } from 'react';
import store from "store/store";
import queryString from 'query-string';
import DashboardBoxes from 'components/common/dashboard-boxes';
import Select from 'react-select'
import { connect } from "react-redux";
import { toast } from 'react-toastify';
import SearchFilter from 'components/common/filter';
import { DateMiniFormat } from "utils/dateFormat";
import { customizeSVG } from 'utils/customizeSVG.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactComponent as IconUsers } from "assets/images/icons/project-icons/Users.svg";
import { ReactComponent as IconChart } from "assets/images/icons/project-icons/Chart.svg";
import { ReactComponent as IconChartPie } from "assets/images/icons/project-icons/ChartPie.svg";
import { ReactComponent as IconWallet } from "assets/images/icons/project-icons/Wallet.svg";
import * as employers from "store/entities/employers/action";

class Performance extends Component {
    state = { 
        getDashboardParams: {
            startDate: "",
            endDate: "",
            employerId: "",
        },
        filter: {
            startDate: "",
            endDate: "",
            showDateSelect: false,
        },
        dashboardProps1: {
            iconBg: "bg-gray-200",
            icon: <IconChart className="customizeSVG text-gray-600 m-auto w-7 h-7" />,
            titleText: <><span className='font-bold text-black'>Enrollment rates</span> <br /> No of enrolled Employees / Total No of Employees</>,
            mainText: "-"
        },
        dashboardProps2: {
            iconBg: "bg-blue-200",
            icon: <IconChartPie className="customizeSVG text-blue-600 m-auto w-7 h-7" />,
            titleText: <><span className='font-bold text-black'>Percentage</span> <br /> No of Withdrawals / Total No of Withdrawals</>,
            mainText: "-"
        },
        dashboardProps3: {
            iconBg: "bg-green-200",
            icon: <IconWallet className="customizeSVG text-green-600 m-auto w-7 h-7" />,
            titleText: <><span className='font-bold text-black'>Value</span> <br /> Value of Withdrawals / Total Value of Withdrawals</>,
            mainText: "-"
        },
        dashboardProps4: {
            iconBg: "bg-green-200",
            icon: <IconUsers className="customizeSVG text-green-600 m-auto w-7 h-7" />,
            titleText: <><span className='font-bold text-black'>Active Employees</span> <br /> No of employees that withdrew in the month)</>,
            mainText: "-"
        },
        dashboardProps5: {
            iconBg: "bg-red-200",
            icon: <IconChartPie className="customizeSVG text-red-600 m-auto w-7 h-7" />,
            titleText: <><span className='font-bold text-black'>Activity Rate</span> <br /> No of enrolled employees that withdrew within the month/Number of enrolled employees</>,
            mainText: "-"
        },
        dashboardProps6: {
            iconBg: "bg-blue-200",
            icon: <IconChart className="customizeSVG text-blue-600 m-auto w-7 h-7" />,
            titleText: <><span className='font-bold text-black'>Average withdrawal times of active employees</span> <br /> Number of withdrawals in the month/Number of Active employees in the month</>,
            mainText: "-"
        },
        employerFilterOptions: [],
        employerFilter: "",
    }


    formChange = (e) => {
        const formValue = {...this.state.filter};
        formValue[e.currentTarget.name] = e.currentTarget.value;
        this.setState({filter: formValue});
    }


    goBack = () => {
        this.props.history.goBack();
    }


    nullToZero = (val) => {
        return ((val === null) ? 0 : val);
    }


    getAllEmployers = () => {
        const getAllEmployersParams = {
            page: 1,
            limit: 1000,
            status: "",
            name: "",
            download: false,
        }
        this.props.getAllEmployers(getAllEmployersParams);
    }


    employerFilterValue = (employer) => {
        // if (employer){
            this.setState({employerFilter: employer});
            this.gotoUrlQuery({"employerId": employer.value});
        // }
    }


    setEmployerFilterValue = (employerId) => {
        const { employerFilterOptions } = this.state;
        let selectedFilterOptions;
        // console.log(employerFilterOptions);
        
        Object.keys(employerFilterOptions).forEach(function(key) {
            if (employerId === employerFilterOptions[key].value){
                selectedFilterOptions = { value: employerFilterOptions[key].value, label: employerFilterOptions[key].label };
            }
        });

        this.employerFilterValue(selectedFilterOptions);
    }

    
    clearEmployerFilterOptions = () => {
        this.setState({employerFilter: ""});
        this.gotoUrlQuery({"employerId": ""});
    }


    getDashboard = () => {
        const qs = queryString.parse(this.props.location.search);
        let getDashboardParams = {...this.state.getDashboardParams};
        let filter = {...this.state.filter};

        let dashboardProps1 = {...this.state.dashboardProps1};
        let dashboardProps2 = {...this.state.dashboardProps2};
        let dashboardProps3 = {...this.state.dashboardProps3};
        let dashboardProps4 = {...this.state.dashboardProps4};
        let dashboardProps5 = {...this.state.dashboardProps5};
        let dashboardProps6 = {...this.state.dashboardProps6};


        let startDate = "";
        let endDate = "";
        

        if ((typeof qs.startDate !== undefined) && (qs.startDate !== undefined) && (qs.startDate !== null)){
            startDate = qs.startDate;
            getDashboardParams = {...getDashboardParams, startDate: qs.startDate};
        }
        else{
            getDashboardParams = {...getDashboardParams, startDate: startDate};
        }


        if ((typeof qs.endDate !== undefined) && (qs.endDate !== undefined) && (qs.endDate !== null)){
            endDate = qs.endDate;
            getDashboardParams = {...getDashboardParams, endDate: qs.endDate};
        }
        else{
            getDashboardParams = {...getDashboardParams, endDate: endDate};
        }
        
        
        if ((typeof qs.employerId !== undefined) && (qs.employerId !== undefined) && (qs.employerId !== null)){
            getDashboardParams = {...getDashboardParams, employerId: qs.employerId};
        }
        else{
            getDashboardParams = {...getDashboardParams, employerId: ""};
        }
        
        filter = {...filter, startDate: getDashboardParams.startDate, endDate: getDashboardParams.endDate};
        // const dashboardLabel = "From: " + ((startDate !== "") ? DateMiniFormat(startDate) : "Infinity") + ", To: " + ((endDate !== "") ? DateMiniFormat(endDate) : "Infinity");
        const dashboardLabel = "Date: " + ((startDate !== "") ? DateMiniFormat(startDate) : "Infinity") + " - " + ((endDate !== "") ? DateMiniFormat(endDate) : "Infinity");

        dashboardProps1 = {...dashboardProps1, subText2: <span className='text-ep-blue capitalize'>{dashboardLabel}</span>};
        dashboardProps2 = {...dashboardProps2, subText2: <span className='text-ep-blue capitalize'>{dashboardLabel}</span>};
        dashboardProps3 = {...dashboardProps3, subText2: <span className='text-ep-blue capitalize'>{dashboardLabel}</span>};
        dashboardProps4 = {...dashboardProps4, subText2: <span className='text-ep-blue capitalize'>{dashboardLabel}</span>};
        dashboardProps5 = {...dashboardProps5, subText2: <span className='text-ep-blue capitalize'>{dashboardLabel}</span>};
        dashboardProps6 = {...dashboardProps6, subText2: <span className='text-ep-blue capitalize'>{dashboardLabel}</span>};
        
        
        this.setState({getDashboardParams, filter, dashboardProps1, dashboardProps2, dashboardProps3, dashboardProps4, dashboardProps5, dashboardProps6});
        this.props.getPerformanceEmployees(getDashboardParams);
        this.props.getPerformanceEmployeesWithdrawals(getDashboardParams);
    }
    

    showDateSelect = () => {
        const filter = {...this.state.filter, showDateSelect: !this.state.filter.showDateSelect};
        this.setState({filter});
    }


    toggleDateSelect = (e) => {
        e.preventDefault();
        
        const { startDate, endDate } = this.state.filter;
        // console.log("startDate", startDate);
        // console.log("endDate", endDate);

        if ((startDate === "") || (endDate === "") || (startDate <= endDate)){
            this.gotoUrlQuery({"startDate": startDate, "endDate": endDate});
            const filter = {...this.state.filter, showDateSelect: false};
            this.setState({filter});
        }
        else{
            toast.error("Invalid date range selected");
        }
    }


    gotoUrlQuery = (params) => {
        const qs = queryString.parse(this.props.location.search);

        Object.keys(params).forEach((paramName) => {
            // console.log("qs", qs[paramName]);
            delete qs[paramName];
        });

        let qsToUrl = new URLSearchParams(qs).toString();
        qsToUrl = this.props.location.pathname + "?" + ((qsToUrl !== "")? qsToUrl + "&" : "");

        Object.keys(params).forEach((paramName) => {
            if (params[paramName] !== ""){
                qsToUrl += paramName + "=" + params[paramName] + "&";
            }
        });

        qsToUrl = qsToUrl.substring(0, qsToUrl.length - 1);
        this.props.history.push(qsToUrl);
    }


    componentDidMount() {
        this.getDashboard();
        this.getAllEmployers();

        this.unsubscribe = store.subscribe(() => {
            if (this.props.allEmployersLoading === false){
                this.unsubscribe();
                const allEmployers = this.props.allEmployers.docs;
                let employerFilterOptions = [];
                if ((allEmployers !== undefined) && (allEmployers !== null)){
                    Object.keys(allEmployers).forEach(function(key) {
                        employerFilterOptions.push(
                            { value: allEmployers[key]._id, label: allEmployers[key].companyName }
                        );
                    });
                }
                this.setState({employerFilterOptions}, () => {
                    const qs = queryString.parse(this.props.location.search);
                    if ((typeof qs.employerId !== undefined) && (qs.employerId !== undefined) && (qs.employerId !== null)){
                        this.setEmployerFilterValue(qs.employerId);
                    }
                });
            }
        });
        this.setState({storeUnsubscribe: this.unsubscribe});
    }

    
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.getDashboard();
        }
        if (this.props.reloadPage === true) {
            setTimeout(()=>{
                this.props.resetReloadPage();
                this.props.history.replace(this.props.location.pathname + this.props.location.search);
            },1000);
        }
    }


    componentWillUnmount() {
        const storeUnsubscribe = this.state.storeUnsubscribe;
        if ((storeUnsubscribe !== "") && (storeUnsubscribe !== undefined) && (storeUnsubscribe !== null)){
            storeUnsubscribe();
        }
    }


    render() { 
        customizeSVG();
        const { employerFilterOptions, employerFilter, dashboardProps1, dashboardProps2, dashboardProps3, dashboardProps4, dashboardProps5, dashboardProps6 } = this.state;
        const { loadingPerformanceEmployees, performanceEmployees, loadingPerformanceEmployeesWithdrawals, performanceEmployeesWithdrawals, allEmployersLoading } = this.props;
        // console.log("performanceEmployees", performanceEmployees);
        // console.log("performanceEmployeesWithdrawals", performanceEmployeesWithdrawals);
        
        return ( 
            <div>
                
                <div>


                    <div className="lg:flex">
                        <div className="w-full lg:w-1/2">
                            <div className="page-title capitalize">
                                Performance Metrics
                            </div>
                        </div>
                        <div className="w-full lg:w-1/2 flex mt-2 lg:mt-0 lg:justify-end space-x-4">
                            
                            <div>
                                <SearchFilter
                                    filters={[
                                        {
                                            title: "Date Range",
                                            dataType: "date-range",
                                            options: {
                                                from: {
                                                    name: "startDate",
                                                    placeholder: "Start Date",
                                                },
                                                to: {
                                                    name: "endDate",
                                                    placeholder: "End Date",
                                                },
                                            },
                                        },
                                    ]}
                                />
                            </div>

                            <div>
                                <button type="button" onClick={this.goBack} className="btn btn-md btn-ep-blue">
                                    <FontAwesomeIcon icon="angle-left" className="text-lg" />
                                    <span>
                                        Back
                                    </span>
                                </button>
                            </div>

                        </div>
                    </div>




                    <div className="w-full lg:flex mt-6 mb-4 sm:mb-0">
                        <div className="w-full lg:w-1/2 flex space-x-3">
                            <div>
                                {(allEmployersLoading === true) && 
                                    <div className="w-full px-3 py-2 border border-gray-200 rounded">
                                        <FontAwesomeIcon icon="spinner" spin className="mr-1.5" />
                                        Loading
                                    </div>
                                }
                                {(allEmployersLoading === false) && 
                                    <div className="w-full">
                                        <Select options={employerFilterOptions} placeholder="Search Employees Firm" value={employerFilter} onChange={this.employerFilterValue} />
                                    </div>
                                }
                            </div>
                            <div className="lg:col-span-2 flex">
                                {(allEmployersLoading === false) && (employerFilter !== "") &&
                                    <div className="mx-auto sm:mx-0 my-auto flex space-x-6 text-sm">
                                        <div>
                                            {/* {(data) ? data.totalDocs : ""} {pluralize("result", (data) ? data.totalDocs : "")} found */}
                                        </div>
                                        <div onClick={this.clearEmployerFilterOptions} className="cursor-pointer text-red-500 border-b-2 border-transparent hover:border-red-500">
                                            <FontAwesomeIcon icon="times" className="mr-1" />
                                            Clear Filter
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>



                    <div className="mt-6">
                        <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-6">
                            <DashboardBoxes data={dashboardProps1} loading={loadingPerformanceEmployees} result={(performanceEmployees)? ((performanceEmployees.employeePerformance.length > 0)? performanceEmployees.employeePerformance[0].percentageOfEmployeesEnrolled : "0") + "%" : "-"} />
                            <DashboardBoxes data={dashboardProps2} loading={loadingPerformanceEmployees} result={(performanceEmployees)? ((performanceEmployees.withdrawalPerformance.withdrawals.length > 0)? performanceEmployees.withdrawalPerformance.withdrawals[0].percentageWithdrawalCountByCompany : "0") + "%" : "-"} />
                            <DashboardBoxes data={dashboardProps3} loading={loadingPerformanceEmployees} result={(performanceEmployees)? ((performanceEmployees.withdrawalPerformance.withdrawals.length > 0)? performanceEmployees.withdrawalPerformance.withdrawals[0].percentageWithdrawalAmountByCompany : "0") + "%" : "-"} />
                            <DashboardBoxes data={dashboardProps4} loading={loadingPerformanceEmployees} result={(performanceEmployees)? ((performanceEmployees.employeePerformance.length > 0)? performanceEmployees.employeePerformance[0].totalEnrolledEmployees : "0") + "%" : "-"} />
                            <DashboardBoxes data={dashboardProps5} loading={loadingPerformanceEmployeesWithdrawals} result={(performanceEmployeesWithdrawals)? this.nullToZero(performanceEmployeesWithdrawals.result.activityRatePercent) + "%" : "-"} />
                            <DashboardBoxes data={dashboardProps6} loading={loadingPerformanceEmployeesWithdrawals} result={(performanceEmployeesWithdrawals)? this.nullToZero(performanceEmployeesWithdrawals.result.averageWithdrawalTimePercent) + "%" : "-"} />
                        </div>
                    </div>



                </div>

            </div>

        );
    }
}


const mapStateToProps = (state) => ({
    loadingPerformanceEmployees: state.entities.employers.loadingPerformanceEmployees,
    performanceEmployees: state.entities.employers.performanceEmployees,
    loadingPerformanceEmployeesWithdrawals: state.entities.employers.loadingPerformanceEmployeesWithdrawals,
    performanceEmployeesWithdrawals: state.entities.employers.performanceEmployeesWithdrawals,

    allEmployersLoading: state.entities.employers.loading,
    allEmployers: state.entities.employers.allEmployers,
});

const mapDispatchToProps = (dispatch) => ({
    getPerformanceEmployees: (params) => dispatch(employers.getPerformanceEmployees(params)),
    getPerformanceEmployeesWithdrawals: (params) => dispatch(employers.getPerformanceEmployeesWithdrawals(params)),
    getAllEmployers: (params) => dispatch(employers.getAllEmployers(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Performance);