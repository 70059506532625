import * as actions from "./actionTypes";
import errorReducer from "utils/errorReducer";
import { toast } from 'react-toastify';

const initialState = {
    allAmbassadorsLoading: false,
    allAmbassadors: {},
    downloadAllAmbassadorsLoading: false,
    downloadAllAmbassadors: {},
    ambassadorDetailsLoading: false,
    ambassadorDetails: {},

    ambassadorAdded: false,
    ambassadorAddedLoading: false,
    ambassadorAddedResponse: "",
    ambassadorDelete: false,
    ambassadorDeleteLoading: false,
    ambassadorDeleteResponse: "",

    allAmbassadorProgramsLoading: false,
    allAmbassadorPrograms: {},
    downloadAllAmbassadorProgramsLoading: false,
    downloadAllAmbassadorPrograms: {},

    ambassadorProgramsStatsLoading: false,
    ambassadorProgramsStats: {},
};


export default function reducer(state = initialState, action) {
    switch (action.type) {

        case actions.GET_AMBASSADORS:
            state = {
                ...state,
                allAmbassadorsLoading: false,
                allAmbassadors: { ...action.payload.data }
            }
            return state;


        case actions.GET_AMBASSADORS_START:
            state = {
                ...state,
                allAmbassadorsLoading: true,
                allAmbassadors: {},
            }
            return state;


        case actions.GET_AMBASSADORS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                allAmbassadorsLoading: false,
                allAmbassadors: {},
            }
            return state;


        case actions.DOWNLOAD_GET_AMBASSADORS:
            state = {
                ...state,
                downloadAllAmbassadorsLoading: false,
                downloadAllAmbassadors: { ...action.payload.data }
            }
            return state;


        case actions.DOWNLOAD_GET_AMBASSADORS_START:
            toast("Downloading Data...");
            state = {
                ...state,
                downloadAllAmbassadorsLoading: true,
                downloadAllAmbassadors: {},
            }
            return state;


        case actions.DOWNLOAD_GET_AMBASSADORS_FAILED:
            errorReducer(action.payload);

            state = {
                ...state,
                downloadAllAmbassadorsLoading: false,
                downloadAllAmbassadors: {},
            }
            return state;


        case actions.GET_AMBASSADORS_DETAILS:
            state = {
                ...state,
                ambassadorDetailsLoading: false,
                ambassadorDetails: { ...action.payload.data }
            }
            return state;


        case actions.GET_AMBASSADORS_DETAILS_START:
            state = {
                ...state,
                ambassadorDetailsLoading: true,
                ambassadorDetails: {},
            }
            return state;


        case actions.GET_AMBASSADORS_DETAILS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                ambassadorDetailsLoading: false,
                ambassadorDetails: {},
            }
            return state;


        case actions.GET_AMBASSADOR_PROGRAM:
            state = {
                ...state,
                allAmbassadorProgramsLoading: false,
                allAmbassadorPrograms: { ...action.payload.data }
            }
            return state;


        case actions.GET_AMBASSADOR_PROGRAM_START:
            state = {
                ...state,
                allAmbassadorProgramsLoading: true,
                allAmbassadorPrograms: {},
            }
            return state;


        case actions.GET_AMBASSADOR_PROGRAM_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                allAmbassadorProgramsLoading: false,
                allAmbassadorPrograms: {},
            }
            return state;


        case actions.DOWNLOAD_GET_AMBASSADOR_PROGRAM:
            state = {
                ...state,
                downloadAllAmbassadorProgramsLoading: false,
                downloadAllAmbassadorPrograms: { ...action.payload.data }
            }
            return state;


        case actions.DOWNLOAD_GET_AMBASSADOR_PROGRAM_START:
            toast("Downloading Data...");
            state = {
                ...state,
                downloadAllAmbassadorProgramsLoading: true,
                downloadAllAmbassadorPrograms: {},
            }
            return state;


        case actions.DOWNLOAD_GET_AMBASSADOR_PROGRAM_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                downloadAllAmbassadorProgramsLoading: false,
                downloadAllAmbassadorPrograms: {},
            }
            return state;


        case actions.GET_AMBASSADORS_PROGRAM_STATS:
            state = {
                ...state,
                ambassadorProgramsStatsLoading: false,
                ambassadorProgramsStats: { ...action.payload.data }
            }
            return state;


        case actions.GET_AMBASSADORS_PROGRAM_STATS_START:
            state = {
                ...state,
                ambassadorProgramsStatsLoading: true,
                ambassadorProgramsStats: {},
            }
            return state;


        case actions.GET_AMBASSADORS_PROGRAM_STATS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                ambassadorProgramsStatsLoading: false,
                ambassadorProgramsStats: {},
            }
            return state;


        case actions.ADD_AMBASSADORS:
            // toast.success("Ambassador added");
            state = {
                ...state,
                ambassadorAdded: true,
                ambassadorAddedLoading: false,
                ambassadorAddedResponse: action.payload.message,
            }
            return state;


        case actions.ADD_AMBASSADORS_START:
            state = {
                ...state,
                ambassadorAdded: false,
                ambassadorAddedLoading: true,
                ambassadorAddedResponse: "",
            }
            return state;


        case actions.ADD_AMBASSADORS_FAILED:
            toast.error(action.payload.message);
            state = {
                ...state,
                ambassadorAdded: false,
                ambassadorAddedLoading: false,
                ambassadorAddedResponse: action.payload.message,
            }
            return state;


        case actions.DELETE_AMBASSADORS:
            // toast.success("Ambassador deleted");
            state = {
                ...state,
                ambassadorDelete: true,
                ambassadorDeleteLoading: false,
                ambassadorDeleteResponse: action.payload.message,
            }
            return state;


        case actions.DELETE_AMBASSADORS_START:
            state = {
                ...state,
                ambassadorDelete: false,
                ambassadorDeleteLoading: true,
                ambassadorDeleteResponse: "",
            }
            return state;


        case actions.DELETE_AMBASSADORS_FAILED:
            toast.error(action.payload.message);
            state = {
                ...state,
                ambassadorDelete: false,
                ambassadorDeleteLoading: false,
                ambassadorDeleteResponse: action.payload.message,
            }
            return state;


        case actions.RESET_DELETE_AMBASSADORS:
            state = {
                ...state,
                ambassadorDelete: false,
            }
            return state;


        default:
            return state;
    }
}