import {
	TabSettingsWide,
	TabSettingsMobile,
} from "components/common/settingsTab";
import EarniButton from "components/misc/EarniButton";
import axios from "axios";
import useToolkit from "utils/useToolkit";

function BulkNotifications() {
	const {Spinner, toastError, toastSuccess, useState} = useToolkit()
	const [items, setItems] = useState({
		title: '',
		message: '',
		file: ''
	});
	const [isUploading, setIsUploading] = useState(false);


	const WRAP_CLASS = 'mb-3 settings-form-wrap'

		const baseURL = process.env.REACT_APP_API_BASE_URL

	const onChange = (e) => {
				const name = e.target.name
				const value = e.target.value
				if(name === 'file') {
			const file = e.target.files[0]
					setItems({
								...items,
								file
							})
				} else {
					setItems({
						...items,
						[name]: value
					})
				}
	}


	const onSubmit = async (e) => {
		e.preventDefault()
		const {title, message, file} = items
		const url = `${baseURL}/miscellaneous/notify-segment`

		const config = {
			headers: {"content-type": "multipart/form-data"},}

		const formData = new FormData();
		formData.append('title', title)
		formData.append('message', message)
		formData.append('file', file)

		setIsUploading(true)
		try {
				await axios.post(url, formData,
				config).then(resp => {
					toastSuccess(resp?.data?.message || 'Sending Notifications to employees...')
				})
				setIsUploading(false)
			} catch (error) {
			setIsUploading(false)
			const msg = error.response?.data?.message
			toastError(msg)
		}
	}

	return (
		<div>
			<TabSettingsMobile />

			<div className="box">
				<div className="flex">
					<TabSettingsWide />
					<div className="lg:w-3/4 xl:w-4/5 lg:pl-6 flex-grow">
						<div className="grid sm:grid-cols-2">
							<div className="page-title">Send Bulk Notifications</div>
						</div>
							{isUploading ? <Spinner /> : 
						<div className="mt-8 w-full">
									<form onSubmit={onSubmit}>
											<div className="settings-form-container">
											<div className={WRAP_CLASS}>
													<div className="form-group">
														<label>
																Notification Title
														</label>
														<input 
															required
															name='title'
															placeholder="Enter title"
															className="form-input"
															onChange={onChange}
														/>
													</div>
											</div>

											
											<div className={WRAP_CLASS}>
													<div className="form-group">
														<label>
																Notification Message
														</label>
														<input 
															required
															name='message'
															className="form-input"
															placeholder="Enter message"
															onChange={onChange}
														/>
													</div>
											</div>

											
											<div className={WRAP_CLASS}>
													<div className="form-group">
														<label>
																Attach Sendgrid CSV File
														</label>
														<input 
															required
															type='file'
															name='file'
															className="form-input"
															placeholder="Enter message"
															onChange={onChange}
														/>
													</div>
											</div>
										</div>

										<div className="mt-5">
											<EarniButton className='rounded-md px-10'
												text='Submit' type='submit' 
												/>
										</div>
									</form>

						</div>}
					</div>
				</div>
			</div>
		</div>
	);
}

export default BulkNotifications;
