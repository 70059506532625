import React, { Component, useState, Fragment } from "react";
import Joi from "joi-browser";
import queryString from "query-string";
import DashboardBoxes from "components/common/dashboard-boxes";
import Dropdown from "utils/dropdown";
import store from "store/store";
import Pagination from "components/common/pagination";
import DialogBox from "utils/dialogBox";
import Download from "components/common/download";
import SearchFilter from "components/common/filter";
import { Link } from "react-router-dom";
import DateFormat, { DateTimeFormat } from "utils/dateFormat";
import { customizeSVG } from "utils/customizeSVG.jsx";
import { encrypt } from "utils/encrypt_decrypt";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { renderStatusColor } from "utils/statusColor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactComponent as IconEmployees } from "assets/images/icons/project-icons/employers.svg";
import { ReactComponent as IconNav } from "assets/images/icons/project-icons/Navicon-v.svg";
import { ReactComponent as Xclose } from "assets/images/icons/project-icons/xclose.svg";
import { ReactComponent as IconFileUpload } from "assets/images/icons/project-icons/file-upload.svg";
import { ReactComponent as IconDownload } from "assets/images/icons/project-icons/download-green.svg";
import { ReactComponent as IconComment } from "assets/images/icons/project-icons/comment.svg";
import { ReactComponent as IconApproved } from "assets/images/icons/project-icons/onboarding-approved.svg";
import { ReactComponent as IconRejected } from "assets/images/icons/project-icons/onboarding-rejected.svg";
import { ReactComponent as IconCheck } from "assets/images/icons/project-icons/circle-check.svg";
import * as employers from "store/entities/employers/action";
import * as settings from "store/entities/settings/action";
import CenterModal from "components/misc/CenterModal";
import SetEarningsModal from "components/payments/SetEarningsModal";
import Maybe from "components/__new/common/Maybe";
import numberOrdinalSuffix from "utils/numberOrdinalSuffix";
import nameToSentenceCase from "utils/nameToSentenceCase";
import CurrencyFormat from "utils/currencyFormat";

const dropdownTableIcon = (
	<IconNav className="w-8 h-8 p-1.5 relative top-1 cursor-pointer group-hover:bg-gray-200 rounded" />
);

const columns = [
	{ label: "Employer ID", key: "_id" },
	{ label: "Business Name", key: "accountName" },
	{ label: "Account Number", key: "accountNumber" },
	{ label: "Account Balance", key: "balance" },
];

const truncate = (text) =>
	text?.length > 9 ? text?.substring(0, 9) + "..." : text;

class BusinessesBalanceList extends Component {
	state = {
		getAllEmployersBalanceParams: {
			page: 1,
			limit: 20,
			download: false,
			name: "",
			keyword: "",
			minBalance: "",
			maxBalance: "",
		},
		dashboardProps1: {
			iconBg: "bg-blue-200",
			icon: (
				<IconEmployees className="customizeSVG text-blue-600 m-auto w-5 h-5" />
			),
			titleText: "Total Balance",
			mainText: "-",
		},
		searchErrors: "",
		storeUnsubscribe: "",
		toggleModalDetails: false,
		toggleModalFee: false,
		modalDetailsAction: "",
		employerDetails: "",
		isRejectionModalOpen: false,
		selectedEmployer: null,
		selectedComment: null,
		selectedOnDemandRejectionComment: null,

		isOdpRejectOpen: false,
		isOdpApprovalOpen: false,

		feeId: "",
		feeForm: {
			fee: "",
			employerId: "",
			minAmount: "",
			maxAmount: "",
		},
		showCreateForm: false,
		showDialogBox: false,
		errors: {},
		isEarningsModalOpen: false,
		hasMultiEmployees: false,
		selectedEmployerName: "",
		selectedEmployerId: "",
	};

	gotoUrlQuery = (params) => {
		const qs = queryString.parse(this.props.location.search);

		Object.keys(params).forEach((paramName) => {
			delete qs[paramName];
		});

		let qsToUrl = new URLSearchParams(qs).toString();
		qsToUrl =
			this.props.location.pathname +
			"?" +
			(qsToUrl !== "" ? qsToUrl + "&" : "");

		Object.keys(params).forEach((paramName) => {
			if (params[paramName] !== "") {
				qsToUrl += paramName + "=" + params[paramName] + "&";
			}
		});

		qsToUrl = qsToUrl.substring(0, qsToUrl.length - 1);
		this.props.history.push(qsToUrl);
	};

	getAllQueryFilters = (params) => {
		const qs = queryString.parse(this.props.location.search);

		if (qs.keyword !== undefined && qs.keyword !== null) {
			params = { ...params, keyword: qs.keyword };
		} else {
			params = { ...params, keyword: "" };
		}
		return params;
	};

	getAllEmployersBalance = () => {
		const qs = queryString.parse(this.props.location.search);
		let getAllEmployersBalanceParams = {
			...this.state.getAllEmployersBalanceParams,
		};

		if (qs.page !== undefined && !isNaN(qs.page) && qs.page > 0) {
			getAllEmployersBalanceParams = {
				...getAllEmployersBalanceParams,
				page: qs.page,
			};
		} else {
			getAllEmployersBalanceParams = {
				...getAllEmployersBalanceParams,
				page: 1,
			};
		}

		if (qs.limit !== undefined && !isNaN(qs.limit) && qs.limit > 0) {
			getAllEmployersBalanceParams = {
				...getAllEmployersBalanceParams,
				limit: qs.limit,
			};
		} else {
			getAllEmployersBalanceParams = {
				...getAllEmployersBalanceParams,
				limit: 20,
			};
		}

		if (qs.minBalance !== undefined && qs.minBalance !== null) {
			getAllEmployersBalanceParams = {
				...getAllEmployersBalanceParams,
				minBalance: qs.minBalance.replace(/\D/g, ""),
			};
		} else {
			getAllEmployersBalanceParams = {
				...getAllEmployersBalanceParams,
				minBalance: "",
			};
		}

		if (qs.maxBalance !== undefined && qs.maxBalance !== null) {
			getAllEmployersBalanceParams = {
				...getAllEmployersBalanceParams,
				maxBalance: qs.maxBalance.replace(/\D/g, ""),
			};
		} else {
			getAllEmployersBalanceParams = {
				...getAllEmployersBalanceParams,
				maxBalance: "",
			};
		}

		getAllEmployersBalanceParams = this.getAllQueryFilters(
			getAllEmployersBalanceParams
		);
		this.setState({ getAllEmployersBalanceParams });
		this.props.getAllEmployersBalance(getAllEmployersBalanceParams);
	};

	downloadEmployersBalance = () => {
		const { getAllEmployersBalanceParams: params } = this.state;
		this.props
			.getAllEmployersBalance({
				...params,
				page: 1,
				limit: 10000,
				download: true,
			})
			.then(() => {
				this.Download.processDownload();
			});
	};

	transformDataDownload = (data) => {
		const newData = data?.map((row) => {
			const newRow = {
				...row,
				balance: CurrencyFormat((row.balance || 0) / 100) ?? 0,
			};
			return newRow;
		});
		return newData;
	};

	formChange = (e) => {
		const formValue = { ...this.state.search };
		formValue[e.currentTarget.name] = e.currentTarget.value;
		this.setState({ search: formValue });
	};

	validate = () => {
		const options = { abortEarly: false };
		const result = Joi.validate(this.state.search, this.schema, options);
		const error = result.error;
		if (!error) return null;

		const errors = {};
		for (let item of result.error.details) {
			errors[item.path[0]] = item.message;
		}
		return errors;
	};

	toggleViewDetails = (status, employer = {}, action = "view") => {
		this.setState({
			toggleModalDetails: status,
			employerDetails: employer,
			modalDetailsAction: action,
		});
		if (status === true) {
			this.props.getEmployer(employer.employerId);
		}
	};

	getEmployerDetails = () => {
		const { getEmployerLoading, employer } = this.props;

		if (getEmployerLoading === true) {
			return (
				<FontAwesomeIcon
					icon="spinner"
					spin
					className="text-md"
				/>
			);
		} else if (
			getEmployerLoading === false &&
			Object.keys(employer).length <= 0
		) {
			return <>-</>;
		} else if (
			getEmployerLoading === false &&
			Object.keys(employer).length > 0
		) {
			return true;
		}
	};

	toggleModalFee = (status, employer = {}) => {
		this.setState({ toggleModalFee: status, employerDetails: employer });
	};

	gotoEmployerDashboard = (employer_id) => {
		toast("Loading... Please wait");

		this.props.getEmployerToken({ employerId: employer_id }).then(() => {
			// const { employerToken } = this.props;
			const employerToken =
				store.getState().entities.employers.employerToken;

			if (employerToken.token && employerToken.token !== "") {
				const userData = {
					loggedIn: true,
					data: {
						token: employerToken.token,
						_id: employerToken.employer._id,
						firstName: employerToken.employer.firstName,
						lastName: employerToken.employer.lastName,
						name:
							employerToken.employer.firstName +
							" " +
							employerToken.employer.lastName,
						email: employerToken.employer.email,
						phoneNumber: employerToken.employer.phoneNumber,
						userType: employerToken.employer.userType,
						jobTitle: employerToken.employer.jobTitle,
						isActive: employerToken.employer.isActive,
						disabled: employerToken.employer.disabled,
						accountType: employerToken.employer.accountType,
					},
					signedUp: false,
				};

				const userDataString = JSON.stringify(userData);
				const userDataStringEncrypt = encrypt(userDataString);

				const url =
					process.env.REACT_APP_EMPLOYER_APP_BASE_URL +
					"/ep-admin/login/" +
					userDataStringEncrypt;
				window.open(url, "_blank").focus();
			} else {
				toast.error("Unable to load employer dashboard");
			}
		});
	};

	approveEmployer = async (employer_id) => {
		const result = await DialogBox();
		if (result) {
			this.props.approveEmployer(employer_id).then(() => {
				this.toggleViewDetails(false);
			});
		}
	};

	activateEmployer = async (employer_id) => {
		const result = await DialogBox();
		if (result) {
			this.props.activateEmployer(employer_id);
			this.toggleViewDetails(false);
		}
	};

	deactivateEmployer = async (employer_id) => {
		const result = await DialogBox({
			theme: "red",
		});
		if (result) {
			this.props.deactivateEmployer(employer_id);
			this.toggleViewDetails(false);
		}
	};

	stopEmployeeWithdrawals = async (employer_id) => {
		const result = await DialogBox({
			theme: "red",
		});
		if (result) {
			this.props.stopEmployeeWithdrawals(employer_id);
		}
	};

	resumeEmployeeWithdrawals = async (employer_id) => {
		const result = await DialogBox();
		if (result) {
			this.props.resumeEmployeeWithdrawals(employer_id);
		}
	};

	pokeEmployer = async (employer) => {
		const result = await DialogBox();
		if (result) {
			this.props.pokeEmployer(employer._id);
			toast("Poking employer");
		}
	};

	resendVerificationMail = async (email) => {
		const result = await DialogBox();
		if (result) {
			const resendVerificationMailParams = {
				email: email,
			};
			this.props.resendVerificationMail(resendVerificationMailParams);
			toast("Resending verification mail to " + email);
		}
	};

	resendCompanyInfoPrompt = async (email) => {
		const result = await DialogBox();
		if (result) {
			const resendCompanyInfoPromptParams = {
				email: email,
			};
			this.props.resendCompanyInfoPrompt(resendCompanyInfoPromptParams);
			toast("Resending company info prompt mail to " + email);
		}
	};

	resendUnenrolledEmployeesInvite = async (id) => {
		const result = await DialogBox({
			title: "Resend Invite",
			content: "Resend Invite to Unenrolled employees",
			buttonYes: "Resend",
		});
		if (result) {
			const params = {
				employerId: id,
			};
			this.props.resendUnenrolledEmployeesInvite(params);
			toast("Resending invites");
		}
	};

	generateERA = async (userId, type) => {
		const result = await DialogBox();
		if (result) {
			const params = {
				userId,
				type,
			};
			this.props.generateERA(params);
			toast("Generating Reconciliation Account");
		}
	};

	schemaFee = {
		fee: Joi.number()
			.required()
			.label("Fee")
			.error((errors) => {
				errors.forEach((err) => {
					switch (err.type) {
						case "any.empty":
							err.message = "Please enter the fee";
							break;
						case "number.base":
							err.message = `Please enter the fee`;
							break;
						default:
							break;
					}
				});
				return errors;
			}),
		minAmount: Joi.number()
			.required()
			.label("Min Amount")
			.error((errors) => {
				errors.forEach((err) => {
					switch (err.type) {
						case "any.empty":
							err.message = "Please enter the minimum amount";
							break;
						case "number.base":
							err.message = `Please enter the minimum amount`;
							break;
						default:
							break;
					}
				});
				return errors;
			}),
		maxAmount: Joi.number()
			.required()
			.label("Max Amount")
			.error((errors) => {
				errors.forEach((err) => {
					switch (err.type) {
						case "any.empty":
							err.message = "Please enter the maximum amount";
							break;
						case "number.base":
							err.message = `Please enter the maximum amount`;
							break;
						default:
							break;
					}
				});
				return errors;
			}),
	};

	validateFee = () => {
		const options = { abortEarly: false, allowUnknown: true };
		const result = Joi.validate(
			this.state.feeForm,
			this.schemaFee,
			options
		);
		const error = result.error;
		if (!error) return null;

		const errors = {};
		for (let item of result.error.details) {
			errors[item.path[0]] = item.message;
		}
		return errors;
	};

	submitFormFee = (action) => {
		const errors = this.validateFee();
		this.setState({ errors: errors || {} });
		if (errors) {
			for (var x in errors) {
				toast.error(errors[x]);
			}
			return;
		} else {
			const { feeId, feeForm } = this.state;

			if (action === "create") {
				this.props.createFeesSettings(feeForm).then(() => {
					if (this.props.feesSettingsCreateSuccess === true) {
						document.querySelector(
							".input-minAmount-create"
						).value = "";
						document.querySelector(
							".input-maxAmount-create"
						).value = "";
						document.querySelector(".input-fee-create").value = "";
						this.props.getFeesSettings();
						this.setState({ showCreateForm: false });
					}
				});
			} else if (action === "update") {
				this.props.updateFeesSettings(feeId, feeForm).then(() => {
					if (this.props.feesSettingsUpdateSuccess === true) {
						this.props.getFeesSettings();
					}
				});
			}
		}
	};

	showCreateForm = () => {
		this.setState({ showCreateForm: true });
	};

	toggleRejectionModal = () =>
		this.setState({
			isRejectionModalOpen: !this.state.isRejectionModalOpen,
		});
	toggleOdpApprovalModal = () =>
		this.setState({ isOdpApprovalOpen: !this.state.isOdpApprovalOpen });
	toggleOdpRejectionModal = () =>
		this.setState({ isOdpRejectOpen: !this.state.isOdpRejectOpen });

	CreateFee = async (e) => {
		e.preventDefault();
		const { employerDetails } = this.state;

		const feeForm = {
			minAmount: document.querySelector(".input-minAmount-create").value,
			maxAmount: document.querySelector(".input-maxAmount-create").value,
			fee: document.querySelector(".input-fee-create").value,
			employerId: employerDetails._id,
		};

		this.setState({ feeForm }, () => {
			this.submitFormFee("create");
		});
	};

	UpdateFee = (feeId, index) => {
		const { employerDetails } = this.state;

		const feeForm = {
			minAmount: document.querySelector(".input-minAmount-" + index)
				.value,
			maxAmount: document.querySelector(".input-maxAmount-" + index)
				.value,
			fee: document.querySelector(".input-fee-" + index).value,
			employerId: employerDetails._id,
		};

		this.setState({ feeId, feeForm }, () => {
			this.submitFormFee("update");
		});
	};

	DeleteFee = (feeId) => {
		this.setState({ feeId });
		this.props.deleteFeesSettings(feeId);
		this.unsubscribe3 = store.subscribe(() => {
			if (this.props.feesSettingsDeleteSuccess === true) {
				this.unsubscribe3();
				this.props.getFeesSettings();
			}
		});
		this.setState({ storeUnsubscribe3: this.unsubscribe3 });
	};

	componentDidMount() {
		this.getAllEmployersBalance();
	}

	componentDidUpdate(prevProps) {
		if (this.props.location !== prevProps.location) {
			this.getAllEmployersBalance();
		}
		if (this.props.reloadPage === true) {
			setTimeout(() => {
				this.props.resetReloadPage();
				this.props.history.replace(
					this.props.location.pathname + this.props.location.search
				);
			}, 1000);
		}
	}

	componentWillUnmount() {
		const storeUnsubscribe = this.state.storeUnsubscribe;
		if (
			storeUnsubscribe !== "" &&
			storeUnsubscribe !== undefined &&
			storeUnsubscribe !== null
		) {
			storeUnsubscribe();
		}
	}

	render() {
		customizeSVG();
		const {
			loading,
			allEmployersBalance: data,
			employer,
			getEmployerLoading,
		} = this.props;
		const {
			getAllEmployersBalanceParams,
			toggleModalDetails,
			employerDetails,
		} = this.state;

		const detailKeyClass = "text-xs";
		const detailValueClass = "mt-1 font-medium capitalize text-sm";
		const documentContainerClass =
			"flex items-center w-full justify-between mt-4 flex-wrap";

		const businessIsPending = employer?.employer?.status === "pending";
		const businessIsInReview = employer?.employer?.status === "review";
		const businessIsActive = employer?.employer?.status === "approved";
		const businessIsRejected = employer?.employer?.status === "rejected";
		const businessIsRegistered =
			employer?.employer?.company &&
			employer?.employer?.company?.companyType === "registered business";

		const employeeGroups = [
			"0",
			"1-10",
			"11-50",
			"51-200",
			"201-500",
			"500+",
		];

		const displayDetails = (details) => {
			if (getEmployerLoading === true) {
				return (
					<FontAwesomeIcon
						icon="spinner"
						spin
						className="text-md"
					/>
				);
			}
			return details;
		};

		return (
			<div>
				<div
					className={
						"max-w-md w-screen h-screen bg-white fixed z-50 top-0 bottom-0 shadow-xl transition-all duration-300 ease-in-out " +
						(toggleModalDetails ? "right-0" : "-right-full")
					}
				>
					<div className="w-full h-full p-5 pb-0 pl-0 relative overflow-auto overscroll-contain">
						{Object.keys(employerDetails).length > 0 && (
							<div>
								<div className="pl-5">
									<div className="flex justify-between">
										<div className="font-recoleta font-semibold text-2xl capitalize">
											{displayDetails(
												employer?.employer?.company
													?.companyName
											)}
										</div>
										<div
											onClick={() =>
												this.toggleViewDetails(false)
											}
											className="w-8 h-8 flex group hover:bg-red-400 rounded-lg cursor-pointer"
										>
											<Xclose className="customizeSVG group-hover:text-white m-auto" />
										</div>
									</div>
									<div className="text-fade text-sm">
										{displayDetails(
											employer?.employer?.email
										)}
									</div>

									<div className="my-3 text-sm">
										Business Type: &nbsp;
										<span className="text-ep-primary-500 capitalize">
											{employer?.employer?.company?.companyType?.includes(
												"freelance"
											)
												? "Un-registered business"
												: employer?.employer?.company
														?.companyType || "-"}
										</span>
									</div>

									<div className="mt-2 flex space-x-4">
										<div className="flex h-6">
											{employer?.employer?.status
												?.length && (
												<>
													<div
														className="w-2 h-2 my-auto mr-2 rounded-full"
														style={{
															backgroundColor:
																businessIsActive
																	? "#398D94"
																	: businessIsPending ||
																	  businessIsInReview
																	? "#F49D1B"
																	: businessIsRejected
																	? "#DC2626"
																	: "",
														}}
													></div>
													<div className="my-auto text-xs">
														{businessIsActive
															? "Active"
															: businessIsPending
															? "Pending"
															: businessIsInReview
															? "In Review"
															: businessIsRejected
															? "Rejected"
															: "-"}
													</div>
												</>
											)}
										</div>

										<Maybe
											condition={
												getEmployerLoading === false
											}
										>
											<div className="flex items-center">
												<Link
													to={
														"/users/employees?employerId=" +
														employer?.employer?._id
													}
													className="btn btn-sm bg-gray-200 btn-gray-hover mr-3"
												>
													View Employees
												</Link>

												<button
													onClick={() => {
														this.props.selectEmployer(
															this.state
																.selectedEmployer
														);
														this.props.history.push(
															`/businesses/transactions/${this.state.selectedEmployer?.accountName}`
														);
													}}
													className="btn btn-sm bg-gray-200 btn-gray-hover"
												>
													View Transactions
												</button>
											</div>
										</Maybe>
									</div>
								</div>

								<div className="w-full h-px my-7 bg-gray-200"></div>

								<div className="my-7 pb-5 pl-5">
									<div className="font-semibold text-xs mb-5">
										Business Details
									</div>

									<Maybe
										condition={getEmployerLoading === true}
									>
										<div className="h-20 flex flex-col items-center justify-center">
											<FontAwesomeIcon
												icon="spinner"
												spin
												className="text-md"
											/>
											<div className={detailValueClass}>
												Fetching Business Details...
											</div>
										</div>
									</Maybe>

									<Maybe
										condition={
											businessIsRegistered === false
										}
									>
										<div className="grid grid-cols-2 gap-x-2">
											<div className="space-y-7">
												<div className="my-auto">
													<div
														className={
															detailKeyClass
														}
													>
														Business Name
													</div>
													<div
														className={
															detailValueClass
														}
													>
														{employer?.employer
															?.company
															?.companyName ||
															"-"}
													</div>
												</div>

												<div className="my-auto">
													<div
														className={
															detailKeyClass
														}
													>
														Industry Type
													</div>
													<div
														className={
															detailValueClass
														}
													>
														{employer?.employer
															?.company
															?.industry || "-"}
													</div>
												</div>

												<div className="my-auto">
													<div
														className={
															detailKeyClass
														}
													>
														Business Address
													</div>
													<div
														className={
															detailValueClass
														}
													>
														{employer?.employer
															?.company
															?.address || "-"}
													</div>
												</div>
											</div>

											<div className="space-y-7">
												<div className="my-auto">
													<div
														className={
															detailKeyClass
														}
													>
														Business Size
													</div>
													<div
														className={
															detailValueClass
														}
													>
														{employeeGroups[
															employer?.employer
																?.company
																?.companySize
														] || "-"}
													</div>
												</div>

												<div className="my-auto">
													<div
														className={
															detailKeyClass
														}
													>
														Business Phone Number
													</div>
													<div
														className={
															detailValueClass
														}
													>
														{employer?.employer
															?.phoneNumber ||
															"-"}
													</div>
												</div>
											</div>
										</div>
									</Maybe>

									<Maybe condition={businessIsRegistered}>
										<div className="grid grid-cols-2 gap-x-2">
											<div className="space-y-7">
												<div className="my-auto">
													<div
														className={
															detailKeyClass
														}
													>
														Business Name
													</div>
													<div
														className={
															detailValueClass
														}
													>
														{employer?.employer
															?.company
															?.companyName ||
															"-"}
													</div>
												</div>

												<div className="my-auto">
													<div
														className={
															detailKeyClass
														}
													>
														Trading Name
													</div>
													<div
														className={
															detailValueClass
														}
													>
														{employer?.employer
															?.company
															?.businessAlias ||
															"-"}
													</div>
												</div>

												<div className="my-auto">
													<div
														className={
															detailKeyClass
														}
													>
														Number of Employees
													</div>
													<div
														className={
															detailValueClass
														}
													>
														{employeeGroups[
															employer?.employer
																?.company
																?.companySize
														] || "-"}
													</div>
												</div>

												<div className="my-auto">
													<div
														className={
															detailKeyClass
														}
													>
														Business Description
													</div>
													<div
														className={
															detailValueClass
														}
													>
														{employer?.employer
															?.company
															?.companyDescription ||
															"-"}
													</div>
												</div>

												<div className="my-auto">
													<div
														className={
															detailKeyClass
														}
													>
														Business Address
													</div>
													<div
														className={
															detailValueClass
														}
													>
														{employer?.employer
															?.company
															?.address || "-"}
													</div>
												</div>
											</div>

											<div className="space-y-7">
												<div className="my-auto">
													<div
														className={
															detailKeyClass
														}
													>
														Business Registration
														Number
													</div>
													<div
														className={
															detailValueClass +
															"text-ep-primary-500"
														}
													>
														{employer?.employer
															?.company
															?.registrationNumber ||
															"-"}
													</div>
												</div>

												<div className="my-auto">
													<div
														className={
															detailKeyClass
														}
													>
														Business Phone Number
													</div>
													<div
														className={
															detailValueClass
														}
													>
														{employer?.employer
															?.phoneNumber ||
															"-"}
													</div>
												</div>

												<div className="my-auto">
													<div
														className={
															detailKeyClass
														}
													>
														Registration Type
													</div>
													<div
														className={
															detailValueClass
														}
													>
														{employer?.employer
															?.company
															?.registrationType ||
															"-"}
													</div>
												</div>
											</div>
										</div>
									</Maybe>

									<Maybe
										condition={
											employer?.employer?.company
												?.businessDocuments?.length >
												0 &&
											businessIsRegistered === false
										}
									>
										<div className={documentContainerClass}>
											{employer?.employer?.company?.businessDocuments?.map(
												(document, index) =>
													document?.fileUrl
														?.length && (
														<div
															key={index}
															style={{
																width: "48%",
																marginBottom:
																	"10px",
															}}
														>
															<div className="text-xs mb-1 capitalize">
																{document?.name >
																18
																	? document?.name?.substring(
																			0,
																			18
																	  ) + "..."
																	: document?.name}
															</div>
															<DocumentWrapper
																documentTitle={truncate(
																	document?.name
																)}
																documentUrl={
																	document?.fileUrl
																}
															/>
														</div>
													)
											)}
										</div>
									</Maybe>

									<div className="w-full h-px my-5 bg-gray-200"></div>
									<div className="font-semibold text-xs mb-5">
										Personal Information
									</div>
									<div className="grid grid-cols-2 gap-x-2">
										<div className="space-y-7">
											<div className="my-auto">
												<div className={detailKeyClass}>
													Full Name
												</div>
												<div
													className={detailValueClass}
												>
													{displayDetails(
														`${
															employer?.employer
																?.firstName +
															" " +
															employer?.employer
																?.lastName
														}`
													)}
												</div>
											</div>

											<div className="my-auto">
												<div className={detailKeyClass}>
													Country
												</div>
												<div
													className={detailValueClass}
												>
													{displayDetails(
														employer?.employer
															?.company
															?.country || "-"
													)}
												</div>
											</div>
										</div>

										<Maybe
											condition={
												businessIsRegistered === false
											}
										>
											<div className="space-y-7">
												<div className="my-auto">
													<div
														className={
															detailKeyClass
														}
													>
														Date of Birth
													</div>
													<div
														className={
															detailValueClass
														}
													>
														{displayDetails(
															employer?.employer
																?.company
																?.dob || "-"
														)}
													</div>
												</div>

												<div className="my-auto">
													<div
														className={
															detailKeyClass
														}
													>
														BVN
													</div>
													<div
														className={
															detailValueClass
														}
													>
														{employer?.employer
															?.company?.bvn ||
															"-"}
													</div>
												</div>
											</div>
										</Maybe>
									</div>

									<Maybe
										condition={
											employer?.employer?.company
												?.businessDocuments?.length >
												0 &&
											businessIsRegistered === true
										}
									>
										<div className={documentContainerClass}>
											{employer?.employer?.company?.businessDocuments?.map(
												(document, index) =>
													document?.fileUrl
														?.length && (
														<div
															key={index}
															style={{
																width: "48%",
																marginBottom:
																	"10px",
															}}
														>
															<div className="text-xs mb-1 capitalize">
																{document?.name >
																18
																	? document?.name?.substring(
																			0,
																			18
																	  ) + "..."
																	: document?.name}
															</div>
															<DocumentWrapper
																documentTitle={truncate(
																	document?.name
																)}
																documentUrl={
																	document?.fileUrl
																}
															/>
														</div>
													)
											)}
										</div>
									</Maybe>

									<Maybe
										condition={
											employer?.employer?.company
												?.businessDirectors?.length > 0
										}
									>
										{employer?.employer?.company?.businessDirectors?.map(
											(director, index) => (
												<Fragment key={index}>
													<div className="w-full h-px my-5 bg-gray-200"></div>
													<div className="font-semibold text-xs mb-5">
														Director {index + 1}
													</div>
													<div className="grid grid-cols-2 gap-x-2">
														<div className="space-y-7">
															<div className="my-auto">
																<div
																	className={
																		detailKeyClass
																	}
																>
																	Full Name
																</div>
																<div
																	className={
																		detailValueClass
																	}
																>
																	{director?.directorFirstName ||
																		"-"}{" "}
																	{director?.directorLastName ||
																		"-"}
																</div>
															</div>

															<div className="my-auto">
																<div
																	className={
																		detailKeyClass
																	}
																>
																	Country
																</div>
																<div
																	className={
																		detailValueClass
																	}
																>
																	{director?.nationality ||
																		"-"}
																</div>
															</div>
														</div>

														<div className="space-y-7">
															<div className="my-auto">
																<div
																	className={
																		detailKeyClass
																	}
																>
																	Date of
																	Birth
																</div>
																<div
																	className={
																		detailValueClass
																	}
																>
																	{director?.directorDob ||
																		"-"}
																</div>
															</div>

															<div className="my-auto">
																<div
																	className={
																		detailKeyClass
																	}
																>
																	BVN
																</div>
																<div
																	className={
																		detailValueClass
																	}
																>
																	{director?.bvn ||
																		"-"}
																</div>
															</div>
														</div>
													</div>
													<div
														style={{
															width: "48%",
															marginBottom:
																"10px",
															marginTop: "15px",
														}}
													>
														<div className="text-xs mb-1 capitalize">
															{director?.documentType >
															18
																? director?.documentType?.substring(
																		0,
																		18
																  ) + "..."
																: director?.documentType}
														</div>
														<DocumentWrapper
															documentTitle={truncate(
																director?.documentType
															)}
															documentUrl={
																director?.documentFile
															}
														/>
													</div>
												</Fragment>
											)
										)}
									</Maybe>

									<div className="w-full h-px my-5 bg-gray-200"></div>
									<div className="font-semibold text-xs mb-5">
										Social Media Links
									</div>
									<div className="grid grid-cols-2 gap-x-2">
										<div className="space-y-4">
											<div className="my-auto">
												<div className={detailKeyClass}>
													Facebook
												</div>
												<div className="mt-1">
													{displayDetails(
														employer?.employer
															?.company?.socials
															?.facebook || "-"
													)}
												</div>
											</div>
											<div className="my-auto">
												<div className={detailKeyClass}>
													Twitter
												</div>
												<div className="mt-1">
													{displayDetails(
														employer?.employer
															?.company?.socials
															?.twitter || "-"
													)}
												</div>
											</div>
											<div className="my-auto">
												<div className={detailKeyClass}>
													Others
												</div>
												<div className="mt-1">
													{displayDetails(
														employer?.employer
															?.company?.socials
															?.others || "-"
													)}
												</div>
											</div>
										</div>
									</div>

									<Maybe
										condition={
											employer?.employer?.company
												?.bankStatementFile?.length > 0
										}
									>
										<div className="w-full h-px my-5 bg-gray-200"></div>
										<div className="font-semibold text-xs mb-5">
											On-Demand Details
										</div>
										<div className="grid grid-cols-2 gap-x-2 pb-8">
											<div className="space-y-4">
												<div className="my-auto">
													<div
														className={
															detailKeyClass
														}
													>
														Salary Details
													</div>
													<div className="mt-1">
														{numberOrdinalSuffix(
															employer?.employer
																?.company
																?.payrollCutOff
														) || "-"}
														&nbsp;of every month
													</div>
												</div>
												<div className="my-auto">
													<div
														className={
															detailKeyClass
														}
													>
														Withdrawal Fee paid by
													</div>
													<div className="mt-1 capitalize">
														{employer?.employer
															?.company
															?.paymentFee || "-"}
													</div>
												</div>
												<div className="my-auto">
													<div className="text-xs mb-1 capitalize">
														Bank Statement File
													</div>
													<DocumentWrapper
														documentTitle="Bank Statement"
														documentUrl={
															employer?.employer
																?.company
																?.bankStatementFile
														}
													/>
												</div>
											</div>
										</div>
										<Maybe
											condition={
												employer?.employer
													?.odpStatus === "pending"
											}
										>
											<div className="flex items-center w-full">
												<button
													type="button"
													onClick={() => {
														this.props.selectEmployer(
															this.state
																.selectedEmployer
														);
														this.toggleOdpRejectionModal();
													}}
													className="btn btn-md border border-red-500 text-red-500 mr-6"
												>
													Reject
												</button>

												<button
													type="button"
													onClick={() => {
														this.props.selectEmployer(
															this.state
																.selectedEmployer
														);
														this.toggleOdpApprovalModal();
													}}
													className="btn btn-md bg-ep-primary-500 text-white ml-3"
												>
													Approve
												</button>
											</div>
										</Maybe>
									</Maybe>

									<div className="w-full h-px my-5 bg-gray-200"></div>
									<div className="font-semibold text-xs mb-5">
										Business Audits
									</div>
									<Maybe
										condition={
											employer?.employer?.rejectComment
												?.length > 0
										}
									>
										<div className="w-full flex flex-col pr-4">
											{employer?.employer?.rejectComment?.map(
												(comment) => {
													const isActive =
														this.state
															.selectedComment
															?._id ===
														comment?._id;

													const toggleCommentView =
														() =>
															isActive
																? this.setState(
																		{
																			selectedComment:
																				null,
																		}
																  )
																: this.setState(
																		{
																			selectedComment:
																				comment,
																		}
																  );
													return (
														<StatusTimeline
															email={
																comment?.commentBy
															}
															text={
																comment?.comment
															}
															timestamp={
																comment?.timestamp
															}
															key={comment?._id}
															isActive={isActive}
															toggleCommentView={
																toggleCommentView
															}
														/>
													);
												}
											)}
										</div>
									</Maybe>

									<Maybe
										condition={
											employer?.employer?.approvedAt
												?.length > 0
										}
									>
										<StatusTimeline
											isApproved
											email={
												employer?.employer?.approvedBy
											}
											timestamp={
												employer?.employer?.approvedAt
											}
										/>
									</Maybe>

									<Maybe
										condition={
											employer?.employer?.rejectODPComment
												?.length > 0
										}
									>
										<div className="w-full h-px my-5 bg-gray-200"></div>
										<div className="font-semibold text-xs mb-5">
											On-Demand Rejection Audits
										</div>

										<div className="w-full flex flex-col pr-4">
											{employer?.employer?.rejectODPComment?.map(
												(comment) => {
													const isActive =
														this.state
															.selectedOnDemandRejectionComment
															?._id ===
														comment?._id;

													const toggleCommentView =
														() =>
															isActive
																? this.setState(
																		{
																			selectedOnDemandRejectionComment:
																				null,
																		}
																  )
																: this.setState(
																		{
																			selectedOnDemandRejectionComment:
																				comment,
																		}
																  );
													return (
														<StatusTimeline
															email={
																comment?.commentBy
															}
															text={
																comment?.comment
															}
															timestamp={
																comment?.timestamp
															}
															key={comment?._id}
															isActive={isActive}
															toggleCommentView={
																toggleCommentView
															}
														/>
													);
												}
											)}
										</div>
									</Maybe>
								</div>
							</div>
						)}
					</div>
				</div>

				<div>
					<div className="lg:flex">
						<div className="w-full lg:w-1/2">
							<div className="page-title">Businesses</div>
						</div>
						<div className="w-full lg:w-1/2 flex space-x-4 mt-2 lg:mt-0 lg:justify-end">
							{/* <div>
								<Download
									tooltip="Download businesses balance"
									format="csv"
									filename="All Businesses Balance.csv"
									columns={columns}
									ref={(instance) => {
										this.Download = instance;
									}}
									click={this.downloadEmployersBalance}
									loading={this.props.loading}
									response={this.transformDataDownload(
										this.props.allEmployersBalance?.data
											?.employers || []
									)}
								/>
							</div> */}

							<div>
								<SearchFilter
									search={{
										name: "keyword",
										placeholder: "Keyword search",
									}}
									filters={[
										{
											title: "Amount",
											dataType: "amount-range",
											range1: {
												name: "minBalance",
												placeholder: "Minimum amount",
											},
											range2: {
												name: "maxBalance",
												placeholder: "Maximum amount",
											},
										},
									]}
								/>
							</div>
						</div>
					</div>

					<div className="mt-6">
						<div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-6">
							<DashboardBoxes
								data={this.state.dashboardProps1}
								loading={loading}
								result={
									CurrencyFormat(
										(data?.data?.totalBalanceSum || 0) / 100
									) ?? "0"
								}
							/>
						</div>
					</div>

					<div className="mt-10">
						<div className="mt-3">
							<div className="table-container">
								<table className="table table-auto table-rounded table-border">
									<thead>
										<tr>
											<th className="whitespace-nowrap">
												Business Name
											</th>
											<th>Account Number</th>
											<th>Account Balance</th>
											<th>Actions</th>
										</tr>
									</thead>
									<tbody>
										{this.props.loading === true && (
											<tr>
												<td colSpan="4">
													<div className="table-info">
														<FontAwesomeIcon
															icon="spinner"
															spin
														/>
														<div className="font-bold uppercase">
															Loading
														</div>
													</div>
												</td>
											</tr>
										)}

										{this.props.loading === false &&
											!data.data?.employers && (
												<tr>
													<td colSpan="4">
														<div className="table-info">
															<FontAwesomeIcon icon="unlink" />
															<div className="font-bold uppercase">
																An error
																occurred
																<br />
																Please try again
																later.
															</div>
														</div>
													</td>
												</tr>
											)}

										{this.props.loading === false &&
											data.data?.employers &&
											data.data?.employers.length <=
												0 && (
												<tr>
													<td colSpan="4">
														{(data?.data?.page <=
															data?.data
																?.totalPages ||
															data?.data
																?.employers
																?.length <
																1) && (
															<div className="table-info">
																<FontAwesomeIcon icon="list" />
																<div className="font-bold uppercase">
																	No results
																	found
																</div>
															</div>
														)}
													</td>
												</tr>
											)}

										{this.props.loading === false &&
											data.data?.employers &&
											data.data?.employers.length > 0 &&
											data.data?.employers.map(
												(employer, index) => {
													return (
														<tr key={index}>
															<td className="w-4">
																<span
																	onClick={() => {
																		this.setState(
																			{
																				selectedEmployer:
																					employer,
																			}
																		);
																		this.toggleViewDetails(
																			true,
																			employer
																		);
																	}}
																	className="hover:underline cursor-pointer capitalize"
																>
																	{
																		employer?.accountName
																	}
																</span>
															</td>
															<td>
																{
																	employer?.accountNumber
																}
															</td>
															<td>
																{employer?.status ===
																	"pending" ||
																employer?.status ===
																	"review"
																	? "-"
																	: CurrencyFormat(
																			(employer?.balance ||
																				0) /
																				100
																	  )}
															</td>
															<td>
																<div>
																	<Dropdown
																		icon={
																			dropdownTableIcon
																		}
																		menu={[
																			{
																				type: "div",
																				text: "View Transactions",
																				click: () => {
																					this.setState(
																						{
																							selectedEmployer:
																								employer,
																						}
																					);
																					this.props.selectEmployer(
																						employer
																					);
																					this.props.history.push(
																						`/businesses/transactions/${employer?.accountName}`
																					);
																				},
																			},
																		]}
																	/>
																</div>
															</td>
														</tr>
													);
												}
											)}
									</tbody>
								</table>
							</div>

							{this.props.loading === false &&
								data.data?.employers &&
								data.data?.employers.length > 0 && (
									<div>
										<Pagination
											data={data?.data}
											url={this.props.location.search}
											limit={
												getAllEmployersBalanceParams.limit
											}
										/>
									</div>
								)}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const DocumentWrapper = ({ documentTitle, documentUrl }) => (
	<Link
		to={{ pathname: documentUrl }}
		target="_blank"
		className="w-2/4"
	>
		<div className="w-full bg-gray-100 rounded-lg px-2 py-1.5 flex items-center justify-between text-ep-primary-500">
			<div className="w-3/4 flex items-center">
				<IconFileUpload />
				<span className="text-sm ml-1 whitespace-nowrap capitalize">
					{truncate(documentTitle, 20)}
				</span>
			</div>
			<IconDownload width={25} />
		</div>
	</Link>
);

const StatusTimeline = ({
	email,
	timestamp,
	text,
	isApproved,
	isActive,
	toggleCommentView,
}) => {
	return (
		<div
			className={`w-full mb-5 pl-5 relative ${
				!isApproved && "border-l-2 border-[#E5E7EB]"
			}`}
		>
			<div className="absolute -top-1 -left-3 text-xs">
				{isApproved ? (
					<IconApproved
						className="text-xs"
						width={20}
					/>
				) : (
					<IconRejected
						className="text-xs"
						width={20}
					/>
				)}
			</div>

			<div className="flex items-center">
				<span className="font-semibold text-sm">
					{isApproved ? "Approved" : "Rejected"} by&nbsp;
				</span>
				<span className="text-ep-primary-500">{email}</span>
			</div>
			<div className="text-sm my-0.5">{DateTimeFormat(timestamp)}</div>

			<Maybe condition={text?.length > 0}>
				<div
					onClick={toggleCommentView}
					className="w-max text-xs mt-1 text-ep-primary-500 cursor-pointer flex items-center"
				>
					<span className="mr-1.5 text-md">
						<IconComment />
					</span>
					{isActive ? "Hide" : "See"} Comment
				</div>

				<Maybe condition={isActive}>
					<div className="w-full bg-gray-50 rounded-sm text-[#374151] text-justify text-xs p-2 mt-2">
						{text}
					</div>
				</Maybe>
			</Maybe>
		</div>
	);
};

const mapStateToProps = (state) => ({
	user: state.auth.userPersist.data,
	loading: state.entities.employers.loading,
	downloadLoading: state.entities.employers.downloadLoading,
	allEmployersBalance: state.entities.employers.allEmployersBalance,
	employerToken: state.entities.employers.employerToken,
	getEmployerLoading: state.entities.employers.getEmployerLoading,
	employer: state.entities.employers.getEmployer,
});

const mapDispatchToProps = (dispatch) => ({
	getAllEmployersBalance: (params) =>
		dispatch(employers.getAllEmployersBalance(params)),
	searchFilterEmployers: (params) =>
		dispatch(employers.searchFilterEmployers(params)),
	downloadGetAllEmployers: (params) =>
		dispatch(employers.downloadGetAllEmployers(params)),
	downloadSearchFilterEmployers: (params) =>
		dispatch(employers.downloadSearchFilterEmployers(params)),
	getEmployerToken: (params) => dispatch(employers.getEmployerToken(params)),
	getEmployer: (employer_id) => dispatch(employers.getEmployer(employer_id)),
	generateERA: (params) => dispatch(employers.generateERA(params)),
	resetReloadPage: () => dispatch(employers.resetReloadPage()),
	selectEmployer: (params) =>
		dispatch(
			employers.selectEmployer({ ...params, _id: params.employerId })
		),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(BusinessesBalanceList);
