import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TabSettingsWide, TabSettingsMobile } from "components/common/settingsTab";
import { ButtonLoader, Input } from "components/__new/common/form";
import { PageContent } from "components/__new/common/PageContent";
import { toast } from "react-toastify";
import Maybe from "components/__new/common/Maybe";
import * as settingsAction from "store/entities/settings/action";


const YieldSettings = () => {
	const dispatch = useDispatch();
    const { yieldSettingsLoading, yieldSettings, yieldSettingsUpdateLoading, yieldSettingsUpdate } = useSelector((s) => s.entities.settings);    

	const [form, setForm] = useState({
		interest_rate: "",
    })

	const getYieldSettings = () => {
        dispatch(settingsAction.getYieldSettings());
    }

	const populateForm = () => {
		setForm({
			...form,
			interest_rate: yieldSettings?.interestRate ?? "",
		})
	}

	const submitForm = async (e) => {
        e.preventDefault();
		let errors = 0;

		if (form.interest_rate === ""){
			toast.error("Please enter the interest rate");
			errors++;
		}
		if (typeof parseFloat(form.interest_rate) !== "number"){
			toast.error("Please enter a valid interest rate");
			errors++;
		}
		if (form.interest_rate < 0){
			toast.error("Interest rate cannot be less than zero");
			errors++;
		}

		if (errors === 0){
			dispatch(settingsAction.updateYieldSettings(form));
		}
    }

	useEffect(() => {
        getYieldSettings();
        // eslint-disable-next-line
    }, [])

	useEffect(() => {
		if (yieldSettings?._id !== undefined){
			populateForm();
		}
        // eslint-disable-next-line
    }, [yieldSettings])

	useEffect(() => {
		if (yieldSettingsUpdate?._id !== undefined){
			toast.success("Interest rate updated successfully");
			dispatch(settingsAction.resetUpdateYieldSettings());
		}
        // eslint-disable-next-line
    }, [yieldSettingsUpdate])

	return (
		<div>
			<TabSettingsMobile />

			<div className="box">
				<div className="flex">
					<TabSettingsWide />

					<div className="lg:w-3/4 xl:w-4/5 lg:pl-6 flex-grow">
						<div className="grid sm:grid-cols-2">
							<div className="font-bold text-2xl">Yield Settings</div>
							<div></div>
						</div>

						<div className="mt-6">
								
							<Maybe condition={yieldSettingsLoading === true}>
								<PageContent loading={yieldSettingsLoading} loadingText="Loading yield settings" />
							</Maybe>


							<Maybe condition={yieldSettingsLoading === false && yieldSettings?._id !== undefined}>
								<form onSubmit={submitForm} className="mt-6">
									<div className="font-medium mb-4">
										{/* Yield rate settings */}
									</div>

									<div className="w-64">
										<Input type="text" label="Yield Interest Rate (%)" value={form.interest_rate} onChange={(e) => setForm({...form, interest_rate: e.target.value})} />
									</div>

									<ButtonLoader type="submit" loading={yieldSettingsUpdateLoading} className="btn btn-ep-blue">
										Save Changes
									</ButtonLoader>
								</form>
							</Maybe>
							

						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default YieldSettings;