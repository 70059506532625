import CenterModal from "components/misc/CenterModal";
import { useEffect } from "react";
import { ButtonLoader } from "../common/form";

const ApproveRejectModal = ({control, onClose, approveMode, onProceed, reason, setReason, loading}) => {
	
    useEffect(() => {
        setReason("");
        // eslint-disable-next-line
    }, [control])
    
	return (
		<CenterModal control={control} onClose={onClose}>
		<h1 className="lg:text-lg font-semibold">Reason For {approveMode ? "Approval" : "Rejection"}</h1>
		
		<div className="form-group mt-8">
			<label>
            {approveMode ? "Approval" : "Rejection"} Reasons
			</label>
			<textarea style={{ height: "120px", resize: "none" }} className="form-input" value={reason} onChange={(e) => setReason(e.target.value)} />
        </div>
		
		<div className="flex items-center justify-center lg:flex-row lg:space-x-2">
			
			<button
				type="button"
				onClick={onClose}
				className="btn btn-md border border-ep-primary-500 text-ep-primary-500 mb-4 lg:mb-0 w-full lg:w-2/4 focus:ring-0"
				disabled={loading}>
				Cancel
			</button>
			
			<ButtonLoader
                loading={loading}
				disabled={!reason?.length || loading}
				type="button" 
				onClick={onProceed}
				className={`btn btn-md border text-white w-full lg:w-2/4 focus:ring-0 ${approveMode ? "bg-ep-primary-500" : "bg-red-500"}`}>
				{loading ? "Submitting" : approveMode ? "Approve" : "Reject"}
			</ButtonLoader>
		</div>
	</CenterModal>
	)
}

export default ApproveRejectModal