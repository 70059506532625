import * as actions from "./actionTypes";
import { toast } from "react-toastify";

const initialState = {
	loading: false,
	allInvoice: {},
	downloadLoading: false,
	downloadAllInvoice: {},
	getInvoiceLoading: false,
	getInvoice: {},
	downloadInvoicePDFLoading: false,
	invoicePDF: {},
	invoiceApproveLoading: false,
	invoiceRevokeLoading: false,
	reloadPage: false,
	paymentHistoryLoading: false,
	allPaymentHistory: {},
	downloadAllPaymentHistoryLoading: false,
	downloadAllPaymentHistory: {},
	paymentHandlersLoading: true,
	paymentHandlersData: [],

	singleHandlerLoading: true,
	singleHandlerInfo: null,
	isHandlerModalOpen: false,

	splitInstructionsLoading: false,
	splitInstructionsData: [],
};

export default function reducer(state = initialState, action) {
	switch (action.type) {

		case actions.GET_SPLIT_INSTRUCTIONS_START:
			state = {
				...state,
				splitInstructionsLoading: true,
			};
			return state;

		case actions.GET_SPLIT_INSTRUCTIONS_DONE:
			state = {
				...state,
				splitInstructionsLoading: false,
				splitInstructionsData: action.payload,
			};
			return state;

		case actions.GET_SPLIT_INSTRUCTIONS_FAILED:
			state = {
				...state,
				splitInstructionsLoading: false,
				splitInstructionsData: null,
			};
			return state;


		case actions.GET_SINGLE_HANDLER_START:
			state = {
				...state,
				singleHandlerLoading: true,
			};
			return state;

		case actions.GET_SINGLE_HANDLER_DONE:
			state = {
				...state,
				singleHandlerLoading: false,
				singleHandlerInfo: action.payload,
			};
			return state;

		case actions.GET_SINGLE_HANDLER_FAILED:
			state = {
				...state,
				singleHandlerLoading: false,
				singleHandlerInfo: null,
			};
			return state;

		case actions.GET_PAYMENT_HANDLERS_START:
			state = {
				...state,
				paymentHandlersLoading: true,
				paymentHandlersData: [],
			};
			return state;

		case actions.GET_PAYMENT_HANDLERS_DONE:
			state = {
				...state,
				paymentHandlersLoading: false,
				paymentHandlersData: action.payload,
			};
			return state;

		case actions.GET_PAYMENT_HANDLERS_FAILED:
			state = {
				...state,
				paymentHandlersLoading: false,
				paymentHandlersData: [],
			};
			return state;

		case actions.GET_ALL_INVOICE:
			state = {
				...state,
				loading: false,
				allInvoice: { ...action.payload },
			};
			return state;

		case actions.GET_ALL_INVOICE_START:
			state = {
				...state,
				loading: true,
			};
			return state;

		case actions.GET_ALL_INVOICE_FAILED:
			if (action.payload.message === "Validation Errors") {
				const payloadErrors = action.payload.errors;
				Object.keys(payloadErrors).forEach(function (key) {
					for (let i = 0; i < payloadErrors[key].length; i++) {
						toast.error(payloadErrors[key][i]);
					}
				});
			} else {
				toast.error(action.payload.message);
			}

			state = {
				...state,
				loading: false,
				allInvoice: {},
			};
			return state;

		case actions.DOWNLOAD_GET_ALL_INVOICE:
			state = {
				...state,
				downloadLoading: false,
				downloadAllInvoice: { ...action.payload.invoices },
			};
			return state;

		case actions.DOWNLOAD_GET_ALL_INVOICE_START:
			toast("Downloading Data...");
			state = {
				...state,
				downloadLoading: true,
				downloadAllInvoice: {},
			};
			return state;

		case actions.DOWNLOAD_GET_ALL_INVOICE_FAILED:
			if (action.payload.message === "Validation Errors") {
				const payloadErrors = action.payload.errors;
				Object.keys(payloadErrors).forEach(function (key) {
					for (let i = 0; i < payloadErrors[key].length; i++) {
						toast.error(payloadErrors[key][i]);
					}
				});
			} else {
				toast.error(action.payload.message);
			}

			state = {
				...state,
				downloadLoading: false,
				downloadAllInvoice: {},
			};
			return state;

		case actions.GET_INVOICE:
			state = {
				...state,
				getInvoiceLoading: false,
				getInvoice: { ...action.payload.invoice },
			};
			return state;

		case actions.GET_INVOICE_START:
			state = {
				...state,
				getInvoiceLoading: true,
			};
			return state;

		case actions.GET_INVOICE_FAILED:
			toast.error(action.payload.message);
			state = {
				...state,
				getInvoiceLoading: false,
				getInvoice: {},
			};
			return state;

		case actions.DOWNLOAD_INVOICE_PDF:
			// console.log(action.payload.pdf);
			state = {
				...state,
				downloadInvoicePDFLoading: false,
				invoicePDF: action.payload.pdf,
			};
			return state;

		case actions.DOWNLOAD_INVOICE_PDF_START:
			state = {
				...state,
				downloadInvoicePDFLoading: true,
			};
			return state;

		case actions.DOWNLOAD_INVOICE_PDF_FAILED:
			toast.error(action.payload.message);
			state = {
				...state,
				downloadInvoicePDFLoading: false,
				invoicePDF: {},
			};
			return state;

		case actions.APPROVE_INVOICE:
			toast.success("Payment Note Approved");
			// toast.success(action.payload.message);
			state = {
				...state,
				invoiceApproveLoading: false,
				reloadPage: true,
			};
			return state;

		case actions.APPROVE_INVOICE_START:
			toast("Approving Payment Note");
			state = {
				...state,
				invoiceApproveLoading: true,
			};
			return state;

		case actions.APPROVE_INVOICE_FAILED:
			toast.error(action.payload.message);
			state = {
				...state,
				invoiceApproveLoading: false,
			};
			return state;

		case actions.REVOKE_APPROVAL_INVOICE:
			toast.success("Approval Revoked");
			// toast.success(action.payload.message);
			state = {
				...state,
				invoiceApproveLoading: false,
				reloadPage: true,
			};
			return state;

		case actions.REVOKE_APPROVAL_INVOICE_START:
			toast("Revoking Approval");
			state = {
				...state,
				invoiceApproveLoading: true,
			};
			return state;

		case actions.REVOKE_APPROVAL_INVOICE_FAILED:
			toast.error(action.payload.message);
			state = {
				...state,
				invoiceApproveLoading: false,
			};
			return state;

		case actions.GET_ALL_PAYMENT_HISTORY:
			state = {
				...state,
				paymentHistoryLoading: false,
				allPaymentHistory: { ...action.payload.payments },
			};
			return state;

		case actions.GET_ALL_PAYMENT_HISTORY_START:
			state = {
				...state,
				paymentHistoryLoading: true,
				allPaymentHistory: {},
			};
			return state;

		case actions.GET_ALL_PAYMENT_HISTORY_FAILED:
			if (action.payload.message === "Validation Errors") {
				const payloadErrors = action.payload.errors;
				Object.keys(payloadErrors).forEach(function (key) {
					for (let i = 0; i < payloadErrors[key].length; i++) {
						toast.error(payloadErrors[key][i]);
					}
				});
			} else {
				toast.error(action.payload.message);
			}

			state = {
				...state,
				paymentHistoryLoading: false,
				allPaymentHistory: {},
			};
			return state;

		case actions.DOWNLOAD_GET_ALL_PAYMENT_HISTORY:
			state = {
				...state,
				downloadAllPaymentHistoryLoading: false,
				downloadAllPaymentHistory: { ...action.payload.payments },
			};
			return state;

		case actions.DOWNLOAD_GET_ALL_PAYMENT_HISTORY_START:
			toast("Downloading Data...");
			state = {
				...state,
				downloadAllPaymentHistoryLoading: true,
				downloadAllPaymentHistory: {},
			};
			return state;

		case actions.DOWNLOAD_GET_ALL_PAYMENT_HISTORY_FAILED:
			if (action.payload.message === "Validation Errors") {
				const payloadErrors = action.payload.errors;
				Object.keys(payloadErrors).forEach(function (key) {
					for (let i = 0; i < payloadErrors[key].length; i++) {
						toast.error(payloadErrors[key][i]);
					}
				});
			} else {
				toast.error(action.payload.message);
			}

			state = {
				...state,
				downloadAllPaymentHistoryLoading: false,
				downloadAllPaymentHistory: {},
			};
			return state;

		case actions.RELOAD_PAGE_RESET:
			state = {
				...state,
				reloadPage: false,
			};
			return state;

		default:
			return state;
	}
}
