import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactComponent as Xclose } from "assets/images/icons/project-icons/xclose.svg";
import { ReactComponent as IconCopy } from "assets/images/icons/project-icons/copy.svg";
import { toast } from "react-toastify";
import CurrencyFormat from "utils/currencyFormat";
import Maybe from "components/__new/common/Maybe";


const LoanHistoryRepaymentSchedule = ({control, onClose, loading}) => {
	const user = {
		_id: 918389283
	}
	const detailKeyClass = "text-xs"
	const detailValueClass = "mt-1 font-medium capitalize text-sm"

	const displayDetails = (details) => { 
		if (loading === true) {
			return <FontAwesomeIcon icon="spinner" spin className="text-md" />;
		}
		return details || "-";
	}
	
	const copyItem = (item, tag) => {
		navigator.clipboard.writeText(item);
		toast(`${tag} Copied`)
	}

	
	return (
		<div
			className={
				"max-w-md w-screen h-screen bg-white fixed z-50 top-0 bottom-0 shadow-xl transition-all duration-300 ease-in-out " +
				(control ? "right-0" : "-right-full")
			}>
			<div className="w-full h-full p-5 pb-10 pt-2 relative overflow-auto overscroll-contain">
				<div className="my-8">
					<div className='w-full'>
						<div>
							<div className="flex justify-between">
								<div className="font-recoleta font-semibold text-2xl capitalize">
									View Repayment Schedule
								</div>
								<div
									onClick={onClose}
									className="w-8 h-8 flex group hover:bg-red-400 rounded-lg cursor-pointer">
									<Xclose className="customizeSVG group-hover:text-white m-auto" />
								</div>
							</div>
						</div>
						
						<div className="w-full h-px mt-6 mb-6 bg-gray-200"></div>
						
						<div className="font-semibold text-sm">Loan Information</div>
						
						<div className="mt-5 grid grid-cols-2 gap-x-4">
							<div className="space-y-5">
								<div className="my-auto">
									<div className={detailKeyClass}>
										Loan ID
									</div>
									<div className="mt-1 font-medium capitalize text-sm flex items-center justify-start">
										{displayDetails(123454)}
										{/* <Maybe condition={user?._id?.length > 0}> */}
											<span onClick={() => copyItem(user?._id, "Loan ID")} className="ml-2 cursor-pointer z-10 text-ep-blue-deeper">
												<IconCopy />
											</span>
										{/* </Maybe> */}
									</div>
								</div>
								
								<div className="my-auto">
									<div className={detailKeyClass}>Interest Rate</div>
									<div className={detailValueClass}>
										{/* {displayDetails(user?.phoneNumber)} */}
										3.5% per month
									</div>
								</div>
								
								<div className="my-auto">
									<div className={detailKeyClass}>
										Loan Limit
									</div>
									<div className="mt-1 font-medium capitalize text-sm flex items-center justify-start">
										{CurrencyFormat("300000")}
									</div>
								</div>
								
								<div className="my-auto">
									<div className={detailKeyClass}>
										Loan Disbursement Date
									</div>
									<div className="mt-1 font-medium capitalize text-sm flex items-center justify-start">
										Mar 30, 2024
									</div>
								</div>
								
								<div className="my-auto">
									<div className={detailKeyClass}>
										Total Repayment Amount
									</div>
									<div className="mt-1 font-medium capitalize text-sm flex items-center justify-start">
										{CurrencyFormat("300000")}
									</div>
								</div>
							</div>
							<div className="space-y-5">
								<div className="my-auto">
									<div className={detailKeyClass}>Loan Amount</div>
									<div className="mt-1 font-medium text-sm">
										{/* {displayDetails(user?.accountNumber)} */}
										{CurrencyFormat("300000")}
									</div>
								</div>
								
								<div className="w-full my-auto">
									<div className={detailKeyClass}>Loan Tenor</div>
									<div className="mt-1 font-medium text-m">
										{/* {displayDetails(user?.points)} */}
										6 Months
									</div>
								</div>
								
								<div className="my-auto">
									<div className={detailKeyClass}>
										Loan Approval Date
									</div>
									<div className="mt-1 font-medium capitalize text-sm flex items-center justify-start">
										Mar 30, 2024
									</div>
								</div>

								<div className="my-auto">
									<div className={detailKeyClass}>Monthly Repayment Amount</div>
									<div className="mt-1 font-medium text-sm">
										{/* {displayDetails(user?.accountNumber)} */}
										{CurrencyFormat("3000")}
									</div>
								</div>

								<div className="my-auto">
									<div className={detailKeyClass}>
										Loan Request Date
									</div>
									<div className="mt-1 font-medium capitalize text-sm flex items-center justify-start">
										Mar 30, 2024
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="w-full h-px my-4 bg-gray-200"></div>

					<div className="font-semibold text-xs md:text-[13px] mb-5">
						Repayment Schedule
					</div>

					<div className="border border-gray-200 rounded-lg w-full px-4">
						<RepaymentTimeline
							status="pending"
						/>
						<RepaymentTimeline
							status="overdue"
						/>
						<RepaymentTimeline
							status="paid"
						/>
						<RepaymentTimeline
							status="paid"
						/>
						<RepaymentTimeline
							status="paid"
							isLastItem
						/>
					</div>
				</div>
			</div>
		</div>
	);
}


export const RepaymentTimeline = ({amount, date, status, isLastItem}) => {
	return (
		<div className={`w-full flex items-center justify-between text-sm py-4 ${!isLastItem && "border-b border-gray-200"}`}>
			<div className="flex-grow text-sm">
				<p className="mb-1 font-medium">
					{CurrencyFormat("20000")}
				</p>
				<div className="text-gray-400">
					Due Date: 18th February, 2024
				</div>
			</div>

			<div>
				<Maybe condition={status === "paid"}>
					<div className="text-xs w-[72px] flex items-center justify-center px-3 py-1 rounded-full bg-[#E5FDFF] text-[#1A202C]">
						Paid
					</div>
				</Maybe>
				<Maybe condition={status === "pending"}>
					<div className="text-xs w-[72px] flex items-center justify-center px-3 py-1 rounded-full bg-[#FFFCDA] text-[#1A202C]">
						Pending
					</div>
				</Maybe>
				<Maybe condition={status === "overdue"}>
					<div className="text-xs w-[72px] flex items-center justify-center px-3 py-1 rounded-full bg-[#FEE2E2] text-[#991B1B]">
						Overdue
					</div>
				</Maybe>
			</div>
		</div>
	)
}



export default LoanHistoryRepaymentSchedule;
