import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Maybe from "./Maybe";



export const PageContent = (props) => {
    const {loading, data, loadingText, emptyText, errorText} = props;
    
    return (
        <>


            <Maybe condition={loading === true}>
                <div className="table-info">
                    <FontAwesomeIcon icon="spinner" spin />
                    <div className="font-bold uppercase">
                        {loadingText || "Loading"}
                    </div>
                </div>
            </Maybe>

            
            <Maybe condition={loading === false && (data === undefined || data === null)}>
                <div className="table-info">
                    <FontAwesomeIcon icon="unlink" />
                    <div className="font-bold uppercase">
                        {errorText || <>Data not found <br /> Please try again later.</>}
                    </div>
                </div>
            </Maybe>

            
            <Maybe condition={loading === false && data && (data.length === 0 || Object.keys(data).length === 0)}>
                <div className="table-info">
                    <FontAwesomeIcon icon="list" />
                    <div className="font-bold uppercase">
                        {emptyText || "No data yet"}
                    </div>
                </div>
            </Maybe>


        </>
    )
}

export default PageContent;