/*
export const nameInitials = (fullName) => {
    
    const names = fullName.split(' ');
    var initials = names[0].substring(0, 1).toUpperCase();
    
    if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }

    return initials;

};
*/

export const nameInitials = (firstName, lastName='') => {   
    var initials = "";

    if (((firstName !== undefined) && (lastName !== '')) && ((firstName !== null) && (lastName !== ''))){
        initials = (firstName.substring(0, 1) + "" + lastName.substring(0, 1)).toUpperCase();
        return initials;
    }
    else if (((firstName !== undefined) && (lastName === '')) && ((firstName !== null) && (lastName === ''))){
        const name_split = firstName.split(" ");
        const name_split_1 = name_split[0].substring(0, 1);
        const name_split_2 = ((name_split.length > 1)  ?  name_split[(name_split.length - 1)].substring(0, 1)  :  "");
        initials = (name_split_1 + "" + name_split_2).toUpperCase();
        return initials;
    }
    else{
        return null;
    }
    
};
