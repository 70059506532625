import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import thankYouIcon from "assets/images/icons/thankYouIcon@svg.svg";
import * as auth from "store/auth/user/action";

class adminOnboardSuccess extends Component {
    state = {  }

    render() { 
        return (
            <div className="p-8 pb-14 sm:p-14 md:pt-20">
                <div className="lg:pl-4 lg:pr-4">

                    <div className="mt-8 text-center max-w-md">
                        <div className="h-48 sm:h-56">
                            <img src={thankYouIcon} alt="EarniPay" className="h-full object-contain mx-auto" />
                        </div>
                        <div className="mt-8 font-boing font-semibold text-2xl sm:text-3xl">
                            Account Activated
                        </div>
                        <div className="mt-4 text-gray-500">
                            You can now gain access to your account using your email address and your password.
                        </div>

                        {(this.props.loggedIn) &&
                            <button type="button" onClick={this.props.logout} className="btn btn-lg btn-block btn-ep-blue mt-8">
                                Login to your admin account
                            </button>
                        }

                        {!(this.props.loggedIn) &&
                            <Link to="/login" className="btn btn-lg btn-block btn-ep-blue mt-8">
                                Login to your admin account
                            </Link>
                        }

                    </div>

                </div>
            </div>
        )
    }
}
 


const mapStateToProps = (state) => ({
    loggedIn: state.auth.userPersist.loggedIn,
});

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(auth.logoutUser())
});

export default connect(mapStateToProps, mapDispatchToProps)(adminOnboardSuccess);