import * as actions from "./actionTypes";
import * as config from "config";
import ObjectToQueryString from 'utils/ObjectToQueryString';

export function getAllWithdrawals(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetAllWithdrawals,
            method: "post",
            data: params,
            onStart: actions.GET_ALL_WITHDRAWALS_START,
            onSuccess: actions.GET_ALL_WITHDRAWALS,
            onError: actions.GET_ALL_WITHDRAWALS_FAILED,
            // useLoginJwt: false,
        },
    }
}

export function getAllWithdrawalsCount(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetWithdrawalsCount + ObjectToQueryString(params),
            method: "get",
            data: {},
            onStart: actions.GET_ALL_WITHDRAWALS_COUNT_START,
            onSuccess: actions.GET_ALL_WITHDRAWALS_COUNT,
            onError: actions.GET_ALL_WITHDRAWALS_COUNT_FAILED,
            // useLoginJwt: false,
        },
    }
}

export function getWithdrawalsByEmployeeId(params) {
    // will be abstracted

    // console.log("params", params);
    const paramsNew = { ...params };
    delete paramsNew["employeeId"];
    let paramsQuery = Object.keys(paramsNew).map((key) => {
        return encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
    }).join("&");
    // console.log("paramsQuery", paramsQuery);

    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetWithdrawalsByEmployeeId + params.employeeId + "?" + paramsQuery,
            method: "get",
            data: {},
            onStart: actions.EMPLOYEE_WITHDRAWALS_START,
            onSuccess: actions.EMPLOYEE_WITHDRAWALS,
            onError: actions.EMPLOYEE_WITHDRAWALS_FAILED,
        },
    }
}

export function downloadGetAllWithdrawals(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetAllWithdrawals + ObjectToQueryString(params),
            method: "post",
            data: params,
            onStart: actions.DOWNLOAD_GET_ALL_WITHDRAWALS_START,
            onSuccess: actions.DOWNLOAD_GET_ALL_WITHDRAWALS,
            onError: actions.DOWNLOAD_GET_ALL_WITHDRAWALS_FAILED,
        },
    }
}

export function retryWithdrawal(id) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiRetryWithdrawal + id,
            method: "post",
            data: {},
            onStart: actions.RETRY_WITHDRAWAL_START,
            onSuccess: actions.RETRY_WITHDRAWAL,
            onError: actions.RETRY_WITHDRAWAL_FAILED,
        },
    }
}

export function getWalletByEmployeeId(id, params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetWalletByEmployeeId + id + "/details" + ObjectToQueryString(params),
            method: "get",
            data: {},
            onStart: actions.EMPLOYEE_WALLET_START,
            onSuccess: actions.EMPLOYEE_WALLET,
            onError: actions.EMPLOYEE_WALLET_FAILED,
        },
    }
}

export function resetReloadPage() {
    return {
        type: actions.RELOAD_PAGE_RESET,
        payload: {},
    }
}


export function getWalletSummary() {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetWalletSummary,
            method: "get",
            data: "",
            onStart: actions.GET_WALLET_SUMMARY_START,
            onSuccess: actions.GET_WALLET_SUMMARY,
            onError: actions.GET_WALLET_SUMMARY_FAILED,
            // useLoginJwt: false,
        },
    }
}


export function getWalletTransactions(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetWalletTransactions + ObjectToQueryString(params),
            method: "get",
            data: params,
            onStart: actions.GET_WALLET_TRANSACTIONS_START,
            onSuccess: actions.GET_WALLET_TRANSACTIONS,
            onError: actions.GET_WALLET_TRANSACTIONS_FAILED,
        },
    }
}


export function downloadWalletTransactions(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetWalletTransactions + ObjectToQueryString(params),
            method: "get",
            data: params,
            onStart: actions.DOWNLOAD_WALLET_TRANSACTIONS_START,
            onSuccess: actions.DOWNLOAD_WALLET_TRANSACTIONS,
            onError: actions.DOWNLOAD_WALLET_TRANSACTIONS_FAILED,
        },
    }
}
