import React from "react";
import queryString from 'query-string';
// import ReactTooltip from 'react-tooltip';
import { Menu } from "@headlessui/react";
import { Link } from "react-router-dom";
import { customizeSVG } from 'utils/customizeSVG.jsx';
import { ReactComponent as IconChevronLeft } from "assets/images/icons/project-icons/chevron-left.svg";
import { ReactComponent as IconChevronRight } from "assets/images/icons/project-icons/chevron-right.svg";
import { ReactComponent as AngleDown } from "assets/images/icons/project-icons/angle-down.svg";

const Pagination = (props) => {

    // console.log("props",props);
    customizeSVG();

    const qs = queryString.parse(props.url);
    delete qs['page'];
    // console.log("qs", qs);
    let qsToUrl = new URLSearchParams(qs).toString();
    qsToUrl = "?" + ((qsToUrl !== "")? qsToUrl + "&" : "");
    // console.log("qsToUrl", qsToUrl);

    const {limit: dataPerPage} = props;
    
    let data = {
        hasPrevPage: "",
        prevPage: "",
        page: "",
        totalPages: "",
        hasNextPage: "",
        nextPage: "",
    }

    data.page = props.data.page;
    data.totalPages = Math.ceil(props.data.total / dataPerPage);
    data.prevPage = (data.page - 1);
    data.hasPrevPage = (data.prevPage >= 1);
    data.nextPage = (data.page + 1);
    data.hasNextPage = (data.nextPage <= data.totalPages);
    // console.log("data",data);

    return (

        <>
            {/* <ReactTooltip /> */}

            <div className="py-4 text-sm flex justify-between">

                <div className="my-auto">
                    <div className="">
                        <div className="pagination">

                            {(data.hasPrevPage) && 
                                <Link to={qsToUrl + "page=" + data.prevPage} className="pagination-items border">
                                    <IconChevronRight className="customizeSVG pagination-items-icon" />
                                    <div>
                                        Prev
                                    </div>
                                </Link>
                            }


                            
                            {((data.page !== 1) && (data.page - 3) >= 1) &&
                                <Link to={qsToUrl + "page=1"} className="pagination-items">
                                    <div>
                                        1
                                    </div>
                                </Link>
                            }

                            {((data.page - 3) > 1) &&
                                <div className="pagination-items-null">
                                    <div>
                                        ...
                                    </div>
                                </div>
                            }
                            
                            {((data.page - 2) >= 1) &&
                                <Link to={qsToUrl + "page=" + (data.page - 2)} className="pagination-items">
                                    <div>
                                        {data.page - 2}
                                    </div>
                                </Link>
                            }
                            
                            {((data.page - 1) >= 1) &&
                                <Link to={qsToUrl + "page=" + (data.page - 1)} className="pagination-items">
                                    <div>
                                        {data.page - 1}
                                    </div>
                                </Link>
                            }
                            


                            <Link to={qsToUrl + "page=" + data.page} className="pagination-items pagination-items-active">
                                <div>
                                    {data.page}
                                </div>
                            </Link>



                            {((data.page + 1) <= (data.totalPages)) &&
                                <Link to={qsToUrl + "page=" + (data.page + 1)} className="pagination-items">
                                    <div>
                                        {data.page + 1}
                                    </div>
                                </Link>
                            }

                            {((data.page + 2) <= (data.totalPages)) &&
                                <Link to={qsToUrl + "page=" + (data.page + 2)} className="pagination-items">
                                    <div>
                                        {data.page + 2}
                                    </div>
                                </Link>
                            }

                            {((data.page + 3) < (data.totalPages)) &&
                                <div className="pagination-items-null">
                                    <div>
                                        ...
                                    </div>
                                </div>
                            }
                            
                            {((data.page !== (data.totalPages)) && (data.page + 2) < (data.totalPages)) &&
                                <Link to={qsToUrl + "page=" + (data.totalPages)} className="pagination-items">
                                    <div>
                                        {data.totalPages}
                                    </div>
                                </Link>
                            }


                            {(data.hasNextPage) && 
                                <Link to={qsToUrl + "page=" + data.nextPage} className="pagination-items border">
                                    <div>
                                        Next
                                    </div>
                                    <IconChevronLeft className="customizeSVG pagination-items-icon" />
                                </Link>
                            }
                            
                        </div>
                    </div>
                </div>

                <div className="hidden sm:block my-auto">
                    <div className="float-right">
                        <Menu as="div">
                            <div className="group inline-block">
                                <Menu.Button as="div">
                                    <button type="button" className="btn btn-md border btn-gray-hover">
                                        {dataPerPage} per page
                                        <AngleDown className="customizeSVG ml-2" />
                                    </button>
                                </Menu.Button>
                                <Menu.Items as="div" className="dropdown">
                                    <Menu.Item as="div">
                                        <div>
                                            <Link to="?limit=20" className="dropdown-item">
                                                20 per page
                                            </Link>
                                            <Link to="?limit=50" className="dropdown-item">
                                                50 per page
                                            </Link>
                                            <Link to="?limit=100" className="dropdown-item">
                                                100 per page
                                            </Link>
                                        </div>
                                    </Menu.Item>
                                </Menu.Items>
                            </div>
                        </Menu>
                    </div>
                </div>

            </div>
        </>
    );
};

export default Pagination;
