import React, { Component } from 'react';
import queryString from 'query-string';
import Joi from "joi-browser";
import DashboardBoxes from 'components/common/dashboard-boxes';
import CurrencyFormat from "utils/currencyFormat";
import Pagination from 'components/common/pagination';
import Download from 'components/common/download';
import { DateMiniFormat, MonthName, MonthNameToIndex } from "utils/dateFormat";
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { connect } from "react-redux";
import { customizeSVG } from 'utils/customizeSVG.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactComponent as IconChartWave } from "assets/images/icons/project-icons/ChartWave.svg";
import * as payments from "store/entities/payments/action";


const columns = [
    { label: "Payment ID", key: "invoiceId" },
    { label: "Date", key: "amount" },
    { label: "Amount", key: "createdAt" },
    { label: "Payment Type", key: "paymentType" },
];


class List extends Component {
    constructor(props) {
        super(props);
        this.csvLinkEl = React.createRef();
    }

    state = {
        getAllPaymentHistoryParams: {
            page: 1,
            limit: 20,
            download: false,
        },
        getAllInvoiceParams: {
            page: 1,
            status: "",
            limit: 20,
            download: false,
            year: "",
            month: "",
        },
        search: {
            searchPaymentHistory: "",
        },
        dashboardProps1: {
            iconBg: "bg-blue-200",
            icon: <IconChartWave className="customizeSVG text-blue-600 m-auto w-7 h-7" />,
            titleText: "Number of Withdrawal Payments",
            mainText: "-"
        },
        dashboardProps2: {
            iconBg: "bg-gray-200",
            icon: <IconChartWave className="customizeSVG text-gray-600 m-auto w-7 h-7" />,
            titleText: "Amount of Payment Due",
            mainText: "-"
        },
        searchErrors: "",
        dataDownload: [],
        storeUnsubscribe: "",
    }



    getAllPaymentHistory = () => {
        const qs = queryString.parse(this.props.location.search);
        let getAllPaymentHistoryParams = {...this.state.getAllPaymentHistoryParams};
        let searchQuery = "";
        
        if ((qs.page !== undefined) && (!isNaN(qs.page)) && (qs.page > 0)){
            getAllPaymentHistoryParams = {...getAllPaymentHistoryParams, page: qs.page};
        }
        else{
            getAllPaymentHistoryParams = {...getAllPaymentHistoryParams, page: 1};
        }
        
        if ((qs.limit !== undefined) && (!isNaN(qs.limit)) && (qs.limit > 0)){
            getAllPaymentHistoryParams = {...getAllPaymentHistoryParams, limit: qs.limit};
        }
        else{
            getAllPaymentHistoryParams = {...getAllPaymentHistoryParams, limit: 20};
        }

        if ((qs.search !== undefined) && (qs.search !== null)){
            searchQuery = qs.search;
            getAllPaymentHistoryParams = {...getAllPaymentHistoryParams, name: qs.search};
        }
        else{
            getAllPaymentHistoryParams = {...getAllPaymentHistoryParams, name: ""};
        }
        
        if ((qs.status !== undefined) && (qs.status !== null)){
            getAllPaymentHistoryParams = {...getAllPaymentHistoryParams, status: qs.status};
        }
        else{
            getAllPaymentHistoryParams = {...getAllPaymentHistoryParams, status: ""};
        }

        const searchParams = {
            ...this.state.search,
            searchPaymentHistory: searchQuery,
        }
        
        this.setState({getAllPaymentHistoryParams, search: searchParams});
        this.props.getAllPaymentHistory(getAllPaymentHistoryParams);
    }


    formChange = (e) => {
        const formValue = {...this.state.search};
        formValue[e.currentTarget.name] = e.currentTarget.value;
        this.setState({search: formValue});
    }


    schema = {
        searchPaymentHistory: Joi.string().allow(null,'').label("Search PaymentHistory"),
    }

    
    validate = () => {
        const options = { abortEarly: false };
        const result = Joi.validate(this.state.search, this.schema, options);
        // console.log(result);
        const error = result.error;
        if (!error) return null;
    
        const errors = {};
        for (let item of result.error.details){
            errors[item.path[0]] = item.message;
        }
        return errors;
    };


    searchPaymentHistory = async(e) => {
        e.preventDefault();
        const errors = this.validate();
        this.setState({searchErrors: errors || {}});
        if (errors){
            // console.log(errors);
            for (var x in errors) {
                toast.error(errors[x]);
            };
            return;
        }
        else{
            const searchUrl = encodeURIComponent(this.state.search.searchPaymentHistory);
            if (searchUrl !== ""){
                this.props.history.push("?search="+searchUrl);
            }
            else{
                this.props.history.push(this.props.location.pathname);
            }
        }
    }


    getAllInvoice = () => {
        const qs = queryString.parse(this.props.location.search);
        let getAllInvoiceParams = {...this.state.getAllInvoiceParams};
        let dashboardProps1 = {...this.state.dashboardProps1};
        let dashboardProps2 = {...this.state.dashboardProps2};


        const date = new Date();
        const currentMonthIndex = (date.getMonth() + 1);
        let month = MonthName(currentMonthIndex);
        // let month = currentMonthIndex;
        let year = date.getFullYear();


        if ((typeof qs.month !== undefined) && (qs.month !== undefined) && (qs.month !== null)){
            month = qs.month;
            getAllInvoiceParams = {...getAllInvoiceParams, month: qs.month};
        }
        else{
            getAllInvoiceParams = {...getAllInvoiceParams, month: month};
        }


        if ((typeof qs.year !== undefined) && (qs.year !== undefined) && (qs.year !== null)){
            year = qs.year;
            getAllInvoiceParams = {...getAllInvoiceParams, year: qs.year};
        }
        else{
            getAllInvoiceParams = {...getAllInvoiceParams, year: year};
        }


        dashboardProps1 = {...dashboardProps1, subText2: <span className='text-ep-blue capitalize'>{month}, {year}</span>};
        dashboardProps2 = {...dashboardProps2, subText2: <span className='text-ep-blue capitalize'>{month}, {year}</span>};
        
        this.setState({getAllInvoiceParams, dashboardProps1, dashboardProps2});

        getAllInvoiceParams = {...getAllInvoiceParams, month: MonthNameToIndex(month), year: year.toString()};  // now requests month index
        // console.log(getAllInvoiceParams);
        this.props.getAllInvoice(getAllInvoiceParams);
    }
    
    
    componentDidMount() {
        this.getAllPaymentHistory();
        this.getAllInvoice();
    }
    
    
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.getAllPaymentHistory();
        }
    }


    componentWillUnmount(){
        const storeUnsubscribe = this.state.storeUnsubscribe;
        if ((storeUnsubscribe !== "") && (storeUnsubscribe !== undefined) && (storeUnsubscribe !== null)){
            storeUnsubscribe();
        }
    }


    render() { 
        customizeSVG();
        const { allPaymentHistory: data, invoiceResponse } = this.props;
        const { getAllPaymentHistoryParams } = this.state;

        return ( 
            <div>


                <div>

                    <div className="lg:flex">
                        <div className="w-full lg:w-1/2">
                            <div className="page-title capitalize">
                                Payments
                            </div>
                        </div>
                        <div className="w-full lg:w-1/2 flex mt-2 lg:mt-0 lg:justify-end">
                            <div>
                                <Download
                                    tooltip="Download payment history list"
                                    format="csv"
                                    filename="Payment History.csv"
                                    columns={columns}
                                    ref={instance => { this.Download = instance; }}
                                    click={() => {
                                        this.props.downloadGetAllPaymentHistory({...getAllPaymentHistoryParams, page: 1, limit: 10000}).then(() => {
                                            this.Download.processDownload();
                                        });
                                    }}
                                    loading={this.props.downloadLoading}
                                    response={this.props.downloadAllPaymentHistory}
                                />
                            </div>
                        </div>
                    </div>


                    <div className="mt-6">
                        <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-6">
                            <DashboardBoxes data={this.state.dashboardProps1} loading={this.props.loading} result={invoiceResponse.totalWithdrawals} />
                            <DashboardBoxes data={this.state.dashboardProps2} loading={this.props.loading} result={CurrencyFormat(invoiceResponse.amountDue)} />
                        </div>
                    </div>


                    <div className="mt-6 w-full lg:w-auto inline-block box-border-only overflow-auto">
                        <div className="h-10 flex">
                            <Link to="/payments" className="page-nav">
                                <div className="whitespace-nowrap">
                                    All
                                </div>
                            </Link>
                            <Link to="/payments?status=paid" className="page-nav">
                                <div className="whitespace-nowrap">
                                    Paid
                                </div>
                            </Link>
                            <Link to="/payments?status=unpaid" className="page-nav">
                                <div className="whitespace-nowrap">
                                    Unpaid
                                </div>
                            </Link>
                            <Link to="/payments/history" className="page-nav active border-l">
                                <div className="whitespace-nowrap">
                                    History
                                </div>
                            </Link>
                        </div>
                    </div>


                    <div className="mt-2">
                        <div className="">

                            <div className="table-container">
                                <table className="table table-auto table-rounded table-border">
                                    <thead>
                                        <tr>
                                            <th>Payment ID</th>
                                            <th>Date</th>
                                            <th>Amount</th>
                                            <th>Payment Type</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        
                                        {(this.props.loading === true) && 
                                            <tr>
                                                <td colSpan="4">
                                                    <div className="table-info">
                                                        <FontAwesomeIcon icon="spinner" spin />
                                                        <div className="font-bold uppercase">
                                                            Loading
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        }

                                        {((this.props.loading === false) && (data) && (!data.docs)) && 
                                            <tr>
                                                <td colSpan="4">
                                                    <div className="table-info">
                                                        <FontAwesomeIcon icon="unlink" />
                                                        <div className="font-bold uppercase">
                                                            An error occurred
                                                            <br />
                                                            Please try again later.
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        }
                                        
                                        {((this.props.loading === false) && (data) && (data.docs) && (data.docs.length <= 0)) && 
                                            <tr>
                                                <td colSpan="4">

                                                    {(data.page <= data.totalPages) &&
                                                        <div className="table-info">
                                                            <FontAwesomeIcon icon="list" />
                                                            <div className="font-bold uppercase">
                                                                No results found
                                                            </div>
                                                        </div>
                                                    }
                                                    
                                                    {(data.page > data.totalPages) &&
                                                        <div className="table-info">
                                                            <FontAwesomeIcon icon="exclamation-triangle" />
                                                            <div className="font-bold uppercase">
                                                                Invalid Page Number
                                                            </div>
                                                            <div className="mt-2">
                                                                <Link to="?page=1" className="btn btn-ep-blue btn-sm mx-auto">Goto Page 1</Link>
                                                            </div>
                                                        </div>
                                                    }

                                                </td>
                                            </tr>
                                        }
                                        
                                        {((this.props.loading === false) && (data) && (data.docs) && (data.docs.length > 0)) && 
                                            data.docs.map((history, index) =>
                                                <tr key={index}>
                                                    <td>
                                                        <div className="text-ep-blue font-bold">
                                                            {history.invoiceId}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        {CurrencyFormat(history.amount)}
                                                    </td>
                                                    <td>
                                                        {DateMiniFormat(history.createdAt)}
                                                    </td>
                                                    <td>
                                                        {((history.paymentType === "transfer") || (history.paymentType === "bank transfer")) &&
                                                            <div className="label label-green uppercase">
                                                                Bank Transfer
                                                            </div>
                                                        }
                                                        {history.paymentType === "online payment" &&
                                                            <div className="label label-blue uppercase">
                                                                Online Payment
                                                            </div>
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        }
                                        
                                    </tbody>
                                </table>
                            </div>

                            {((this.props.loading === false) && (data) && (data.docs) && (data.docs.length > 0)) && 
                                <div>
                                    <Pagination data={data} url={this.props.location.search} limit={getAllPaymentHistoryParams.limit} />
                                </div>
                            }

                        </div>
                    </div>


                </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => ({
    invoiceResponse: state.entities.payments.allInvoice,
    loading: state.entities.payments.paymentHistoryLoading,
    downloadLoading: state.entities.payments.downloadAllPaymentHistoryLoading,
    allPaymentHistory: state.entities.payments.allPaymentHistory,
    downloadAllPaymentHistory: state.entities.payments.downloadAllPaymentHistory,
});

const mapDispatchToProps = (dispatch) => ({
    getAllInvoice: (params) => dispatch(payments.getAllInvoice(params)),
    getAllPaymentHistory: (params) => dispatch(payments.getAllPaymentHistory(params)),
    downloadGetAllPaymentHistory: (params) => dispatch(payments.downloadGetAllPaymentHistory(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(List);