import React, { Component } from 'react';
import Joi from "joi-browser";
import { connect } from "react-redux";
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as admin from "store/entities/admin/action";

const AdminRoles = {
    "super-admin": "Super Admin",
    "general-admin": "General Admin",
    "financial-admin": "Financial Admin",
    "customer-success-admin": "Customer Success Admin",
}

class Form extends Component {

    state = {
        adminForm: {
            firstName: "",
            lastName: "",
            username: "",
            email: "",
            adminType: "",
        },
        adminId: "",
        errors: {},
    }


    goBack = () => {
        this.props.history.goBack();
    }


    formChange = (e) => {
        const formValue = {...this.state.adminForm};
        formValue[e.currentTarget.name] = e.currentTarget.value;
        this.setState({adminForm: formValue});
    }

    
    schema = {
        firstName: Joi.string().required().label("Firstname").error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    case "any.empty":
                        err.message = "Please enter admin's firstname";
                        break;
                    default:
                        break;
                }
            });
            return errors;
        }),
        lastName: Joi.string().required().label("Lastname").error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    case "any.empty":
                        err.message = "Please enter admin's lastname";
                        break;
                    default:
                        break;
                }
            });
            return errors;
        }),
        username: Joi.string().required().label("Username").error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    case "any.empty":
                        err.message = "Please enter admin's username";
                        break;
                    default:
                        break;
                }
            });
            return errors;
        }),
        email: Joi.string().email().required().label("Email Address").error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    case "any.empty":
                        err.message = "Please enter your admin's email address";
                        break;
                    case "string.email":
                        err.message = "Please enter the admin's email address";
                        break;
                    default:
                        break;
                }
            });
            return errors;
        }),
        adminType: Joi.string().required().label("User Role").error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    case "any.empty":
                        err.message = "Please select the admin's role";
                        break;
                    default:
                        break;
                }
            });
            return errors;
        }),
    }


    validate = () => {
        const options = { abortEarly: false };
        const result = Joi.validate(this.state.adminForm, this.schema, options);
        // console.log(result);
        const error = result.error;
        if (!error) return null;
    
        const errors = {};
        for (let item of result.error.details){
            errors[item.path[0]] = item.message;
        }
        return errors;
    };


    submitForm = async e => {
        e.preventDefault();
        const errors = this.validate();
        this.setState({errors: errors || {}});
        if (errors){
            // console.log(errors);
            for (var x in errors) {
                toast.error(errors[x]);
            };
            return;
        }
        else{
            const {adminId, adminForm} = this.state;
            if (adminId === ""){
                this.props.createAdmin(adminForm).then(() => {
                    if (this.props.createResponse.status === "sucess"){
                        this.goBack();
                    }
                })
            }
            else{
                this.props.editAdmin(adminId, adminForm).then(() => {
                    if (this.props.updateResponse.status === "sucess"){
                        this.goBack();
                    }
                })
            }
        }
    }

    componentDidMount() {
        if ("id" in this.props.match.params){
            const {id} = this.props.match.params;
            this.setState({adminId: id});
            
            this.props.getAdmin(id).then(() => {
                const {admin} = this.props;
                const adminForm = {
                    firstName: admin.firstName,
                    lastName: admin.lastName,
                    username: admin.username,
                    email: admin.email,
                    adminType: admin.adminType,
                }
                this.setState({adminForm});
            })
        }
    }


    render() { 
        const {adminForm, adminId} = this.state;

        return ( 
            <div>
                
                <div className="flex">
                    <div className="flex-shrink-0">
                        <div className="page-title">
                            {adminId === "" ? "Add New Admin" : "Edit Admin"}
                        </div>
                    </div>
                    <div className="flex-grow flex space-x-3 justify-end">
                        <button className="btn btn-md btn-ep-blue" onClick={this.goBack}>
                            <FontAwesomeIcon icon="angle-left" className="text-lg" />
                            <span>
                                Back
                            </span>
                        </button>
                    </div>
                </div>
            

                {(adminId !== "") && (this.props.adminLoading === true) && 
                    <div className="table-info-full-page">
                        <div className="table-info">
                            <FontAwesomeIcon icon="spinner" spin />
                            <div className="font-bold uppercase">
                                Loading
                            </div>
                        </div>
                    </div>
                }

                {(adminId !== "") && (this.props.adminLoading === false) && (Object.keys(this.props.admin).length === 0) && 
                    <div className="table-info-full-page">
                        <div className="table-info">
                            <FontAwesomeIcon icon="clipboard" />
                            <div className="font-bold uppercase">
                                Admin not found
                            </div>
                        </div>
                    </div>
                }

                {((adminId === "") || ((adminId !== "") && (this.props.adminLoading === false) && (Object.keys(this.props.admin).length > 0))) && 
                    <div className="mt-6 space-y-6 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-x-6">
                        <div className="box col-span-2">

                            <form onSubmit={this.submitForm}>

                                <div className="font-bold text-gray-600">
                                    Enter Admin Details
                                </div>

                                <div className="mt-6 sm:grid sm:grid-cols-2 sm:gap-x-6">

                                    <div className="form-group">
                                        <label>
                                            Firstname
                                            <span className="form-input-required">*</span>
                                        </label>
                                        <input type="text" name="firstName" placeholder="Enter Admin's Firstname" className="form-input" value={adminForm.firstName} onChange={this.formChange} />
                                    </div>

                                    <div className="form-group">
                                        <label>
                                            Lastname
                                            <span className="form-input-required">*</span>
                                        </label>
                                        <input type="text" name="lastName" placeholder="Enter Admin's Lastname" className="form-input" value={adminForm.lastName} onChange={this.formChange} />
                                    </div>

                                    <div className="form-group">
                                        <label>
                                            Username
                                            <span className="form-input-required">*</span>
                                        </label>
                                        <input type="text" name="username" placeholder="Enter Admin's Username" className="form-input" value={adminForm.username} onChange={this.formChange} />
                                    </div>

                                    <div className="form-group">
                                        <label>
                                            Email Address
                                            <span className="form-input-required">*</span>
                                        </label>
                                        <input type="email" name="email" placeholder="Enter Admin's Email Address" className="form-input" value={adminForm.email} onChange={this.formChange} disabled={(adminId !== "")} />
                                    </div>

                                    <div className="form-group">
                                        <label>
                                            User Role
                                            <span className="form-input-required">*</span>
                                        </label>
                                        <select name="adminType" id="adminType" className="form-input" value={adminForm.adminType} onChange={this.formChange}>
                                            <option value="">Select</option>
                                            <option value="super-admin">{AdminRoles["super-admin"]}</option>
                                            <option value="general-admin">{AdminRoles["general-admin"]}</option>
                                            <option value="financial-admin">{AdminRoles["financial-admin"]}</option>
                                            <option value="customer-success-admin">{AdminRoles["customer-success-admin"]}</option>
                                        </select>
                                    </div>

                                </div>

                                <button type="submit" className="btn btn-lg btn-ep-blue mt-2" disabled={this.props.createLoading || this.props.updateLoading}>
                                    <FontAwesomeIcon icon="spinner" spin className={"text-xl mr-2 " + (this.props.createLoading || this.props.updateLoading?"inline":"hidden")} />
                                    {adminId === "" ? "Add" : "Edit"}
                                </button>

                            </form>

                        </div>
                    </div>
                }
            
            </div>
        );

    }
}


const mapStateToProps = (state) => ({
    admin: state.entities.admin.admin,
    adminLoading: state.entities.admin.adminLoading,
    createLoading: state.entities.admin.adminCreateLoading,
    createResponse: state.entities.admin.adminCreateResponse,
    updateLoading: state.entities.admin.adminUpdateLoading,
    updateResponse: state.entities.admin.adminUpdateResponse,
});

const mapDispatchToProps = (dispatch) => ({
    getAdmin: (id) => dispatch(admin.getAdmin(id)),
    createAdmin: (params) => dispatch(admin.createAdmin(params)),
    editAdmin: (id, params) => dispatch(admin.editAdmin(id, params)),
    resetAdminCreateResponse: () => dispatch(admin.resetAdminCreateResponse()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Form);