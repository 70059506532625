export const GET_ALL_DASHBOARD = "GET_ALL_DASHBOARD";
export const GET_ALL_DASHBOARD_START = "GET_ALL_DASHBOARD_START";
export const GET_ALL_DASHBOARD_FAILED = "GET_ALL_DASHBOARD_FAILED";

export const GET_DASHBOARD_PERFORMANCE_REVENUE = "GET_DASHBOARD_PERFORMANCE_REVENUE";
export const GET_DASHBOARD_PERFORMANCE_REVENUE_START = "GET_DASHBOARD_PERFORMANCE_REVENUE_START";
export const GET_DASHBOARD_PERFORMANCE_REVENUE_FAILED = "GET_DASHBOARD_PERFORMANCE_REVENUE_FAILED";

export const GET_DASHBOARD_PERFORMANCE_REPAYMENT = "GET_DASHBOARD_PERFORMANCE_REPAYMENT";
export const GET_DASHBOARD_PERFORMANCE_REPAYMENT_START = "GET_DASHBOARD_PERFORMANCE_REPAYMENT_START";
export const GET_DASHBOARD_PERFORMANCE_REPAYMENT_FAILED = "GET_DASHBOARD_PERFORMANCE_REPAYMENT_FAILED";

export const GET_DASHBOARD_CHARTS = "GET_DASHBOARD_CHARTS";
export const GET_DASHBOARD_CHARTS_START = "GET_DASHBOARD_CHARTS_START";
export const GET_DASHBOARD_CHARTS_FAILED = "GET_DASHBOARD_CHARTS_FAILED";

export const GET_COMPANY_STATS = "GET_COMPANY_STATS";
export const GET_COMPANY_STATS_START = "GET_COMPANY_STATS_START";
export const GET_COMPANY_STATS_FAILED = "GET_COMPANY_STATS_FAILED";

export const GET_WITHDRAWAL_MONTH_STATS = "GET_WITHDRAWAL_MONTH_STATS";
export const GET_WITHDRAWAL_MONTH_STATS_START = "GET_WITHDRAWAL_MONTH_STATS_START";
export const GET_WITHDRAWAL_MONTH_STATS_FAILED = "GET_WITHDRAWAL_MONTH_STATS_FAILED";
