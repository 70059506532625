
export const TOGGLE_APPROVAL_STATUS_START = "TOGGLE_APPROVAL_STATUS_START";
export const TOGGLE_APPROVAL_STATUS_DONE = "TOGGLE_APPROVAL_STATUS_DONE";
export const TOGGLE_APPROVAL_STATUS_FAILED = "TOGGLE_APPROVAL_STATUS_FAILED";

export const GET_SINGLE_APPROVAL_START = "GET_SINGLE_APPROVAL_START";
export const GET_SINGLE_APPROVAL_DONE = "GET_SINGLE_APPROVAL_DONE";
export const GET_SINGLE_APPROVAL_FAILED = "GET_SINGLE_APPROVAL_FAILED";

export const GET_ALL_APPROVALS_START = "GET_ALL_APPROVALS_START";
export const GET_ALL_APPROVALS_DONE = "GET_ALL_APPROVALS_DONE";
export const GET_ALL_APPROVALS_FAILED = "GET_ALL_APPROVALS_FAILED";

export const SELECT_APPROVAL_ITEM = "SELECT_APPROVAL_ITEM";