
export const GET_AMBASSADORS_PROGRAM_STATS = "GET_AMBASSADORS_PROGRAM_STATS";
export const GET_AMBASSADORS_PROGRAM_STATS_START = "GET_AMBASSADORS_PROGRAM_STATS_START";
export const GET_AMBASSADORS_PROGRAM_STATS_FAILED = "GET_AMBASSADORS_PROGRAM_STATS_FAILED";

export const GET_AMBASSADOR_PROGRAM = "GET_AMBASSADOR_PROGRAM";
export const GET_AMBASSADOR_PROGRAM_START = "GET_AMBASSADOR_PROGRAM_START";
export const GET_AMBASSADOR_PROGRAM_FAILED = "GET_AMBASSADOR_PROGRAM_FAILED";

export const DOWNLOAD_GET_AMBASSADOR_PROGRAM = "DOWNLOAD_GET_AMBASSADOR_PROGRAM";
export const DOWNLOAD_GET_AMBASSADOR_PROGRAM_START = "DOWNLOAD_GET_AMBASSADOR_PROGRAM_START";
export const DOWNLOAD_GET_AMBASSADOR_PROGRAM_FAILED = "DOWNLOAD_GET_AMBASSADOR_PROGRAM_FAILED";

export const GET_AMBASSADORS = "GET_AMBASSADORS";
export const GET_AMBASSADORS_START = "GET_AMBASSADORS_START";
export const GET_AMBASSADORS_FAILED = "GET_AMBASSADORS_FAILED";

export const DOWNLOAD_GET_AMBASSADORS = "DOWNLOAD_GET_AMBASSADORS";
export const DOWNLOAD_GET_AMBASSADORS_START = "DOWNLOAD_GET_AMBASSADORS_START";
export const DOWNLOAD_GET_AMBASSADORS_FAILED = "DOWNLOAD_GET_AMBASSADORS_FAILED";

export const GET_AMBASSADORS_DETAILS = "GET_AMBASSADORS_DETAILS";
export const GET_AMBASSADORS_DETAILS_START = "GET_AMBASSADORS_DETAILS_START";
export const GET_AMBASSADORS_DETAILS_FAILED = "GET_AMBASSADORS_DETAILS_FAILED";

export const ADD_AMBASSADORS = "ADD_AMBASSADORS";
export const ADD_AMBASSADORS_START = "ADD_AMBASSADORS_START";
export const ADD_AMBASSADORS_FAILED = "ADD_AMBASSADORS_FAILED";

export const DELETE_AMBASSADORS = "DELETE_AMBASSADORS";
export const DELETE_AMBASSADORS_START = "DELETE_AMBASSADORS_START";
export const DELETE_AMBASSADORS_FAILED = "DELETE_AMBASSADORS_FAILED";
export const RESET_DELETE_AMBASSADORS = "RESET_DELETE_AMBASSADORS";
