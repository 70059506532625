import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Maybe from "../Maybe";

export const ButtonLoader = (props) => {

    return (
        <>
            
            <button type={props.type} disabled={props.loading || props.disabled} className={props.className} onClick={props.onClick} style={props.style}>
                <Maybe condition={props.loading === true}>
                    <FontAwesomeIcon icon="spinner" spin className="text-xl mr-2" />
                </Maybe>
                {props.children}
            </button>

        </>
    )
}

export default ButtonLoader
