import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Maybe from "./Maybe";

const DashboardBox = (props) => {
    return (
        <div onClick={props.onClick} className={"p-4 box flex justify-start space-x-3 " + (props.onClick && "cursor-pointer")}>

            <Maybe condition={props.icon !== undefined}>
                <div className={"w-10 h-10 xl:w-12 xl:h-12 flex-shrink-0 rounded-md flex items-center justify-center " + (props.iconBackground ?? "")}>
                    {props.icon}
                </div>
            </Maybe>
            
            <div className="my-auto">
                <div className="text-xs text-[#757575]">
                    {props.title}
                </div>
                <div className="text-black text-xl font-semibold">
                    <Maybe condition={props.loading}>
                        <FontAwesomeIcon icon="spinner" spin />
                    </Maybe>
                    <Maybe condition={!props.loading}>
                        {props.value}
                    </Maybe>
                </div>
                <Maybe condition={props.subText !== undefined}>
                    <div className="text-xs text-[#757575]">
                        {props.subText}
                    </div>
                </Maybe>
            </div>

            <Maybe condition={props.rightContent !== undefined}>
                {props.rightContent}
            </Maybe>

        </div>
    )
}

export default DashboardBox
