import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react'
import DateFormat from 'utils/dateFormat';
import Pagination from 'components/common/pagination';
import { Link, useLocation } from 'react-router-dom';
import { renderStatusColor } from 'utils/statusColor';
import { useDispatch, useSelector } from 'react-redux';
import * as employers from "store/entities/employers/action";
import DialogBox from 'utils/dialogBox';
import { toast } from 'react-toastify';
import SetEarningsModal from 'components/payments/SetEarningsModal';
import { ReactComponent as IconNav } from "assets/images/icons/project-icons/Navicon-v.svg";
import Dropdown from 'utils/dropdown';

const dropdownTableIcon = (
	<IconNav className="w-8 h-8 p-1.5 relative top-1 cursor-pointer group-hover:bg-gray-200 rounded" />
);


const SubAccountsTable = ({refinedData, subAccountParams, allSubAccountsLoading, fetchSubAccounts, toggleAccountDetails}) => {
    
    const location = useLocation();
    const dispatch = useDispatch();
    const { userType: role } = useSelector((state) => state.auth.userPersist.data)
    const [earningsModalOpen, setEarningsModalOpen] = useState(false);
    const [hasMultipleEmployees, setHasMultipleEmployees] = useState(false);
    const [selectedEmployer, setSelectedEmployer] = useState(null);

    const isSuperAdmin = role === "super-admin";
	const isCustomerSuccessAdmin = role === "customer-success-admin"

    const onDeactivate = async (id) => {
		const result = await DialogBox({
			theme: "red",
		});
		if (result) {
			dispatch(employers.deactivateEmployer(id)).then(() => fetchSubAccounts())
		}
	};

    const onActivate = async (id) => {
		const result = await DialogBox({
		});
		if (result) {
			dispatch(employers.activateEmployer(id)).then(() => fetchSubAccounts())
		}
	};

    const resendInvite = async (id) => {
		const result = await DialogBox({
			title: "Resend Invite",
			content: "Resend Invite to Unenrolled employees",
			buttonYes: "Resend",
		});
		if (result) {
			const params = {
				employerId: id,
			};
			dispatch(employers.resendUnenrolledEmployeesInvite(params));
			toast("Resending invites");
		}
	};

    const toggleRollOverStatus = (employer_id, rolloverStatus) => {
			const payload = {
				id: employer_id,
				rollOver: rolloverStatus,
			};
            dispatch(employers.toggleEmployerRollover(payload))
            toast("Updating roll over status")
		};

    return (
        <div className="mt-5">

            <SetEarningsModal
                control={earningsModalOpen}
                employerId={selectedEmployer?._id}
                hasMultiple={hasMultipleEmployees}
                onClose={() => setEarningsModalOpen(false)}
                companyName={selectedEmployer?.company?.companyName}
                refreshFunction={() => fetchSubAccounts()}
            />

            <div className="table-container">
                <table className="table table-auto table-rounded table-border">
                    <thead>
                        <tr>
                            <th>Date Joined</th>
                            <th>Sub-account Name</th>
                            <th>Email</th>
                            <th>Status</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        
                        {allSubAccountsLoading === true && 
                            <tr>
                                <td colSpan="11">
                                    <div className="table-info">
                                        <FontAwesomeIcon icon="spinner" spin />
                                        <div className="font-bold uppercase">
                                            Loading
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        }

                        {((allSubAccountsLoading === false) && (!refinedData.docs)) && 
                            <tr>
                                <td colSpan="11">
                                    <div className="table-info">
                                        <FontAwesomeIcon icon="unlink" />
                                        <div className="font-bold uppercase">
                                            An error occurred
                                            <br />
                                            Please try again later.
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        }
                        
                        {((allSubAccountsLoading === false) && (refinedData.docs) && (refinedData.docs.length <= 0)) && 
                            <tr>
                                <td colSpan="11">

                                    {(refinedData.page <= refinedData.totalPages || refinedData?.docs?.length < 1) &&
                                        <div className="table-info">
                                            <FontAwesomeIcon icon="list" />
                                            <div className="font-bold uppercase">
                                                No results found
                                            </div>
                                        </div>
                                    }
                                    
                                    {(refinedData.page > refinedData.totalPages && refinedData?.docs?.length > 0) &&
                                        <div className="table-info">
                                            <FontAwesomeIcon icon="exclamation-triangle" />
                                            <div className="font-bold uppercase">
                                                Invalid Page Number
                                            </div>
                                            <div className="mt-2">
                                                <Link to="?page=1" className="btn btn-ep-blue btn-sm mx-auto">Goto Page 1</Link>
                                            </div>
                                        </div>
                                    }

                                </td>
                            </tr>
                        }
                        
                        {(allSubAccountsLoading === false && refinedData.docs && (refinedData.docs.length > 0)) && 
                            refinedData.docs.map((account, index) => {
                                
                                const rollOverActive = account?.company?.rollOver === true;
                                const hasBulkPay = account?.products?.includes("BULK_PAY")
                                const hasOnDemandPay = account?.products?.includes("ON_DEMAND_PAY")
                                const hasPayroll = account?.products?.includes("PAYROLL")

                                const accountIsDisabled =  account?.disabled === true
                                const withdrawalsStopped = account?.company?.stopWithdrawals === true

                                return (
                                    <tr key={index}>
                                        <td>
                                            <div>
                                                {DateFormat(account?.createdAt?.split("T")[0])}
                                            </div>
                                        </td>
                                        <td>
                                            {account?.company?.companyName}
                                        </td>
                                        <td>
                                            {account?.email}
                                        </td>
                                        <td>
                                            <div className={"label uppercase " + renderStatusColor((account?.status === "rejected" || accountIsDisabled) ? "failed" : account?.status === "review" ? "pending" : account?.status)}>
                                                {account?.disabled === true ? "deactivated" : account?.status}
                                            </div>
                                        </td>

                                        <td>
                                            <div>
                                                <Dropdown
                                                    icon={dropdownTableIcon}
                                                    menu={[
                                                        {
                                                            type: "div",
                                                            text: "View Details",
                                                            click: () => {
                                                                dispatch(employers.selectEmployer(account))
                                                                dispatch(employers.getEmployer(account?._id));
                                                                toggleAccountDetails();
                                                            }
                                                        },

                                                        ((hasBulkPay || hasPayroll) && {
                                                            type: "link",
															text: "View Employees",
                                                            link: `/users/employees?employerId=${account?._id}&accountType=sub`
                                                        }),

                                                        ((isSuperAdmin || isCustomerSuccessAdmin) && {
                                                            type: "div",
                                                            text: accountIsDisabled ? "Activate" : "Deactivate",
                                                            click: () => accountIsDisabled ? onActivate(account?._id) : onDeactivate(account?._id)
                                                        }),

                                                        ((isSuperAdmin) && {
                                                            type: "div",
															text: "Resend invite to pending employees",
                                                            click: () => resendInvite(account?._id)
                                                        }),

                                                        ((isSuperAdmin) && {
                                                            type: "div",
															text: rollOverActive ?  "Deactivate Roll Over" : "Activate Roll OVer",
                                                            click: () => toggleRollOverStatus(account._id, account?.company?.rollOver)
                                                        }),

                                                        ((isSuperAdmin && hasOnDemandPay) && {
                                                            type: "div",
															text: `${withdrawalsStopped ? "Resume" : "Stop"} Employee withdrawals`,
                                                            click: () => {
                                                                if(withdrawalsStopped) {
                                                                    dispatch(employers.resumeEmployeeWithdrawals((account._id))).then(() => fetchSubAccounts())
                                                                } else {
                                                                    dispatch(employers.stopEmployeeWithdrawals((account._id))).then(() => fetchSubAccounts())
                                                                }
                                                            }
                                                        }),

                                                        ((isSuperAdmin) && {
                                                            type: "div",
															text: "Set all employee earnings",
                                                            click: () => {
                                                                setSelectedEmployer(account);
                                                                setHasMultipleEmployees(false);
                                                                setEarningsModalOpen(true);
                                                            }
                                                        }),

                                                        ((isSuperAdmin) && {
                                                            type: "div",
															text: "Set multiple employee earnings",
                                                            click: () => {
                                                                setSelectedEmployer(account);
                                                                setHasMultipleEmployees(true);
                                                                setEarningsModalOpen(true);
                                                            }
                                                        }),
                                                        
                                                    ]}

                                                />
                                            </div>
                                        </td>
                                    </tr>
                                )
                                }
                            )
                        }
                    </tbody>
                </table>
            </div>

            {((allSubAccountsLoading === false) && (refinedData.docs) && (refinedData.docs.length > 0)) && 
                <div>
                    <Pagination data={refinedData} url={location.search} limit={subAccountParams.limit} />
                </div>
            }
    </div>
    )
}


export default SubAccountsTable