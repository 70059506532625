export const GET_AUDIT_TRAIL = "GET_AUDIT_TRAIL";
export const GET_AUDIT_TRAIL_START = "GET_AUDIT_TRAIL_START";
export const GET_AUDIT_TRAIL_FAILED = "GET_AUDIT_TRAIL_FAILED";

export const DOWNLOAD_GET_AUDIT_TRAIL = "DOWNLOAD_GET_AUDIT_TRAIL";
export const DOWNLOAD_GET_AUDIT_TRAIL_START = "DOWNLOAD_GET_AUDIT_TRAIL_START";
export const DOWNLOAD_GET_AUDIT_TRAIL_FAILED = "DOWNLOAD_GET_AUDIT_TRAIL_FAILED";

export const GET_AUDIT_TRAIL_ACTIONS = "GET_AUDIT_TRAIL_ACTIONS";
export const GET_AUDIT_TRAIL_ACTIONS_START = "GET_AUDIT_TRAIL_ACTIONS_START";
export const GET_AUDIT_TRAIL_ACTIONS_FAILED = "GET_AUDIT_TRAIL_ACTIONS_FAILED";