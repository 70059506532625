export const DOWNLOAD_GOALS_SAVINGS_START = "DOWNLOAD_GOALS_SAVINGS_START";
export const DOWNLOAD_GOALS_SAVINGS_DONE = "DOWNLOAD_GOALS_SAVINGS_DONE";
export const DOWNLOAD_GOALS_SAVINGS_FAILED = "DOWNLOAD_GOALS_SAVINGS_FAILED";

export const GET_ALL_GOALS_SAVINGS_START = "GET_ALL_GOALS_SAVINGS_START";
export const GET_ALL_GOALS_SAVINGS_DONE = "GET_ALL_GOALS_SAVINGS_DONE";
export const GET_ALL_GOALS_SAVINGS_FAILED = "GET_ALL_GOALS_SAVINGS_FAILED";

export const PAUSE_GOALS_SAVINGS_START = "PAUSE_GOALS_SAVINGS_START";
export const PAUSE_GOALS_SAVINGS_DONE = "PAUSE_GOALS_SAVINGS_DONE";
export const PAUSE_GOALS_SAVINGS_FAILED = "PAUSE_GOALS_SAVINGS_FAILED";

export const CANCEL_GOALS_SAVINGS_START = "CANCEL_GOALS_SAVINGS_START";
export const CANCEL_GOALS_SAVINGS_DONE = "CANCEL_GOALS_SAVINGS_DONE";
export const CANCEL_GOALS_SAVINGS_FAILED = "CANCEL_GOALS_SAVINGS_FAILED";

export const GET_ALL_FLEX_SAVINGS_START = "GET_ALL_FLEX_SAVINGS_START";
export const GET_ALL_FLEX_SAVINGS_DONE = "GET_ALL_FLEX_SAVINGS_DONE";
export const GET_ALL_FLEX_SAVINGS_FAILED = "GET_ALL_FLEX_SAVINGS_FAILED";

export const GET_SAVINGS_DETAILS_START = "GET_SAVINGS_DETAILS_START";
export const GET_SAVINGS_DETAILS_DONE = "GET_SAVINGS_DETAILS_DONE";
export const GET_SAVINGS_DETAILS_FAILED = "GET_SAVINGS_DETAILS_FAILED";

export const SELECT_SAVINGS_ITEM = "SELECT_SAVINGS_ITEM";

export const GET_SAVINGS_TRX_START = "GET_SAVINGS_TRX_START";
export const GET_SAVINGS_TRX_DONE = "GET_SAVINGS_TRX_DONE";
export const GET_SAVINGS_TRX_FAILED = "GET_SAVINGS_TRX_FAILED";

export const GET_SAVINGS_METRICS_START = "GET_SAVINGS_METRICS_START";
export const GET_SAVINGS_METRICS_DONE = "GET_SAVINGS_METRICS_DONE";
export const GET_SAVINGS_METRICS_FAILED = "GET_SAVINGS_METRICS_FAILED";
