import React, { Component } from 'react';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import DashboardBoxes from 'components/common/dashboard-boxes';
import numberOrdinalSuffix from "utils/numberOrdinalSuffix";
import CurrencyFormat from "utils/currencyFormat";
import { NumberChartFormat } from "utils/numberFormat";
import Select from 'react-select'
import { Line, Bar, Doughnut } from 'react-chartjs-2';
import { Dialog } from '@headlessui/react';
import { connect } from "react-redux";
import { toast } from 'react-toastify';
import ReactToolTip from 'react-tooltip';
import {monthNamesShort, DateMiniFormat} from "utils/dateFormat";
import { customizeSVG } from 'utils/customizeSVG.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactComponent as IconUsers } from "assets/images/icons/project-icons/employees.svg";
import { ReactComponent as IconChart } from "assets/images/icons/project-icons/Chart.svg";
import { ReactComponent as IconChartPie } from "assets/images/icons/project-icons/ChartPie.svg";
import { ReactComponent as IconWallet } from "assets/images/icons/project-icons/Wallet.svg";
import SwapRight from "assets/images/icons/project-icons/SwapRight.svg";
import * as dashboard from "store/entities/dashboard/action";
import * as employers from "store/entities/employers/action";
import ChartDataLabels from 'chartjs-plugin-datalabels';

const withdrawalSizeDataColors = [
    '#05083F',
    '#D8DAE5',
    '#D3B418',
    '#36CAF5',
]

const doughnutChartOptions = {
    plugins: {
        legend: false,
        tooltip: {
            callbacks: {
                label: function(context){
                    var label = context.label,
                        currentValue = context.raw,
                        total = context.chart._metasets[context.datasetIndex].total;
                    var percentage = parseFloat((currentValue/total*100)?.toFixed(1));
                    // return label + ": " +currentValue + ' (' + percentage + '%)';
                    return label + ": " + percentage + "%";
                }
            }
        }
    },
    layout: {
        padding: 5
    },
};


const lineChartOptions = {
    maintainAspectRatio: false,
    scales: {
        x: {
            grid:{
                display: false,
            },
        },
        y: {
            beginAtZero: true,
            min: 0,
        },
    },
};

const barChartOptions = {
    maintainAspectRatio: false,
    scales: {
        x: {
            offset: true,
            grid:{
                display: false,
            },
        },
        y: {
            beginAtZero: true,
            grace: "5%",
        },
    },
    plugins: {
        datalabels: {
            display: true,
            color: "black",
            backgroundColor: "white",
            padding: {
                left: 5,
                right: 5,
            },
            anchor: "end",
            offset: -15,
            align: "start",
            formatter: (value, context) => {
                return NumberChartFormat(value, "");
            },
        }
      },
    legend: {
        display: false
    }
};


class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.completeButtonRef = React.createRef(null);
    }

    state = { 
        getDashboardParams: {
            startDate: "",
            endDate: "",
            employerId: "",
        },
        filter: {
            startDate: "",
            endDate: "",
        },
        dashboardProps1: {
            iconBg: "bg-gray-200",
            icon: <IconChart className="customizeSVG text-gray-600 m-auto w-7 h-7" />,
            titleText: "Total Transaction Value (TTV)",
            mainText: "-"
        },
        dashboardPropsTotalPayout: {
            iconBg: "bg-green-200",
            icon: <IconChart className="customizeSVG text-green-600 m-auto w-7 h-7" />,
            titleText: "Total Payouts",
            mainText: "-"
        },
        dashboardProps2: {
            iconBg: "bg-blue-200",
            icon: <IconChart className="customizeSVG text-blue-600 m-auto w-7 h-7" />,
            titleText: "Revenue",
            mainText: "-"
        },
        dashboardProps3: {
            iconBg: "bg-green-200",
            icon: <IconChartPie className="customizeSVG text-green-600 m-auto w-7 h-7" />,
            titleText: "Revenue Payout Ratio %",
            mainText: "-"
        },
        dashboardProps4: {
            iconBg: "bg-green-200",
            icon: <IconWallet className="customizeSVG text-green-600 m-auto w-7 h-7" />,
            titleText: "Confirmed Repayments",
            mainText: "-"
        },
        dashboardProps5: {
            iconBg: "bg-red-200",
            icon: <IconWallet className="customizeSVG text-red-600 m-auto w-7 h-7" />,
            titleText: "Outstanding Payments",
            mainText: "-"
        },
        dashboardProps6: {
            iconBg: "bg-blue-200",
            icon: <IconUsers className="customizeSVG text-blue-600 m-auto w-7 h-7" />,
            titleText: "No. of companies",
            mainText: "-"
        },
        dashboardProps7: {
            iconBg: "bg-gray-200",
            icon: <IconUsers className="customizeSVG text-gray-600 m-auto w-7 h-7" />,
            titleText: "Wage Size",
            mainText: "-"
        },
        employerFilterOptions: [],
        employerFilter: "",
        toggleWithdrawalSizeModal: false,
        toggleSearch: false,
        withdrawalSizeDisplayCount: 3,
        prevWithdrawalSizeDisplayCount: 3,
        weeklyWithdrawalsData: {
            labels: ['SUN', 'MON', 'TUE', 'WED', 'THUR', 'FRI', 'SAT'],
            datasets: [
              {
                label: 'Withdrawal',
                // data: [12, 19, 3, 5, 2, 3, 8],
                data: [0,0,0,0,0,0,0],
                fill: false,
                backgroundColor: 'rgba(65,109,212,1)',
                borderColor: 'rgba(65,109,212,1)',
                pointBackgroundColor: 'rgba(0,0,0,0)',
                pointBorderColor: 'rgba(0,0,0,0)',
                tension: 0.3,
              },
            ],
        },
        monthlyWithdrawalsData: {
            labels: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'],
            datasets: [
              {
                label: 'Withdrawal',
                // data: [12, 10, 3, 5, 6, 3, 8, 13, 9, 4, 10, 7],
                data: [0,0,0,0,0,0,0,0,0,0,0,0],
                backgroundColor: 'rgba(210,179,48,1)',
                borderColor: 'rgba(210,179,48,1)',
                borderRadius: Number.MAX_VALUE,
                barPercentage: 0.5,
              },
            ],
        },
        monthlyStatisticsData: {
            labels: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'],
            datasets: [
              {
                label: 'Withdrawal',
                // data: [12, 10, 3, 5, 6, 3, 8, 13, 9, 4, 10, 7],
                data: [0,0,0,0,0,0,0,0,0,0,0,0],
                backgroundColor: '#36CAF5',
                // borderRadius: Number.MAX_VALUE,
                // barPercentage: 0.5,
                barPercentage: 1.0,
              },
              {
                label: 'Active employees',
                // data: [7, 10, 2, 4, 8, 3, 6, 5, 10, 2, 7, 5],
                data: [0,0,0,0,0,0,0,0,0,0,0,0],
                backgroundColor: '#05083F',
                barPercentage: 1.0,
              },
              {
                label: 'Active companies',
                // data: [4, 2, 7, 2, 7, 10, 9, 3, 2, 8, 3, 2],
                data: [0,0,0,0,0,0,0,0,0,0,0,0],
                backgroundColor: '#0E26AB',
                barPercentage: 1.0,
              },
            ],
        },
        withdrawalMonthStatsChartView: "count",
        withdrawalMonthStatsChart: {
            labels: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'],
            datasets: [
              {
                label: 'Withdrawal',
                // data: [123465, 106676767, 4343321, 34454555, 67766346, 3, 8, 13, 950500, 4, 350000, 5585366.87],
                data: [0,0,0,0,0,0,0,0,0,0,0,0],
                backgroundColor: 'rgba(210,179,48,1)',
                borderColor: 'rgba(210,179,48,1)',
                borderRadius: Number.MAX_VALUE,
                barPercentage: 0.5,
              },
            ],
        },
        companyStatsWithdrawalSize: {},
        withdrawalSizeData: {
            labels: [
                'Company 1',
                'Company 2',
                'Company 3',
                'Others',
            ],
            datasets: [{
                label: 'Withdrawals',
                data: [40, 15, 25, 20],
                backgroundColor: [...withdrawalSizeDataColors],
                hoverOffset: 5
            }]
        },
        employeeStatsData: {
            labels: [
                'Active',
                'Inactive',
            ],
            datasets: [{
                label: 'Users',
                data: [70, 30],
                backgroundColor: [
                    '#0E26AB',
                    '#D9E3FF',
                ],
                hoverOffset: 5
            }]
        },
        platformStats: {},
        platformStatsData: {
            labels: [
                'Web',
                'Mobile',
                'USSD',
            ],
            datasets: [{
                label: 'Channels',
                data: [0, 0, 0],
                backgroundColor: [
                    '#0E26AB',
                    '#36CAF5',
                    '#D3B418',
                ],
                hoverOffset: 5
            }]
        },
    }


    formChange = (e) => {
        const formValue = {...this.state.filter};
        formValue[e.currentTarget.name] = e.currentTarget.value;
        this.setState({filter: formValue});
    }


    getAllEmployers = () => {
        // const prevParams = {
        //     page: 1,
        //     limit: 1000,
        //     status: "active",
        //     name: "",
        //     download: false,
        // }
        
        const getAllEmployersParams = {
            page: 1,
            limit: 1000,
            download: false,
            status: "",
            name: "",
            keyword: "",
            registeredFrom: "",
            registeredTo: "",
            features: "",
            disabled: ""
        }
        this.props.getAllEmployers(getAllEmployersParams).then(() => {
            const allEmployers = this.props.allEmployers.docs;
            let employerFilterOptions = [];
            if ((allEmployers !== undefined) && (allEmployers !== null)){
                Object.keys(allEmployers).forEach(function(key) {
                    employerFilterOptions.push(
                        { value: allEmployers[key]._id, label: allEmployers[key].companyName }
                    );
                });
            }
            this.setState({employerFilterOptions}, () => {
                const qs = queryString.parse(this.props.location.search);
                if ((typeof qs.employerId !== undefined) && (qs.employerId !== undefined) && (qs.employerId !== null)){
                    this.setEmployerFilterValue(qs.employerId);
                }
            });
        });
    }


    employerFilterValue = (employerId) => {
        // console.log(employerId);
        this.setState({employerFilter: employerId});
        this.gotoUrlQuery({"employerId": employerId.value});
    }


    setEmployerFilterValue = (employerId) => {
        const { employerFilterOptions } = this.state;
        let selectedFilterOptions;
        // console.log(employerFilterOptions);
        
        Object.keys(employerFilterOptions).forEach(function(key) {
            if (employerId === employerFilterOptions[key].value){
                selectedFilterOptions = { value: employerFilterOptions[key].value, label: employerFilterOptions[key].label };
            }
        });

        this.employerFilterValue(selectedFilterOptions);
    }

    
    clearEmployerFilterOptions = () => {
        this.setState({employerFilter: ""});
        this.gotoUrlQuery({"employerId": ""});
    }


    getDashboard = () => {
        const qs = queryString.parse(this.props.location.search);
        let getDashboardParams = {...this.state.getDashboardParams};
        let {filter} = this.state;

        let dashboardProps1 = {...this.state.dashboardProps1};
        let dashboardPropsTotalPayout = {...this.state.dashboardPropsTotalPayout};
        let dashboardProps2 = {...this.state.dashboardProps2};
        let dashboardProps3 = {...this.state.dashboardProps3};
        let dashboardProps4 = {...this.state.dashboardProps4};
        let dashboardProps5 = {...this.state.dashboardProps5};
        let dashboardProps6 = {...this.state.dashboardProps6};
        let dashboardProps7 = {...this.state.dashboardProps7};


        let startDate = "";
        let endDate = "";
        

        if ((typeof qs.startDate !== undefined) && (qs.startDate !== undefined) && (qs.startDate !== null)){
            startDate = qs.startDate;
            getDashboardParams = {...getDashboardParams, startDate: qs.startDate};
        }
        else{
            getDashboardParams = {...getDashboardParams, startDate: startDate};
        }


        if ((typeof qs.endDate !== undefined) && (qs.endDate !== undefined) && (qs.endDate !== null)){
            endDate = qs.endDate;
            getDashboardParams = {...getDashboardParams, endDate: qs.endDate};
        }
        else{
            getDashboardParams = {...getDashboardParams, endDate: endDate};
        }
        
        
        if ((typeof qs.employerId !== undefined) && (qs.employerId !== undefined) && (qs.employerId !== null)){
            getDashboardParams = {...getDashboardParams, employerId: qs.employerId};
        }
        else{
            getDashboardParams = {...getDashboardParams, employerId: ""};
        }
        
        
        filter = {...filter, startDate: getDashboardParams.startDate, endDate: getDashboardParams.endDate};
        // const dashboardLabel = "From: " + ((startDate !== "") ? DateMiniFormat(startDate) : "Infinity") + ", To: " + ((endDate !== "") ? DateMiniFormat(endDate) : "Infinity");
        let dashboardLabel = "Date: " + (DateMiniFormat(startDate) ?? "-") + " - " + (DateMiniFormat(endDate) ?? "");
        if (startDate === "" || endDate === ""){
            dashboardLabel = "";
        }

        dashboardProps1 = {...dashboardProps1, subText2: <span className='text-ep-blue capitalize'>{dashboardLabel}</span>};
        dashboardPropsTotalPayout = {...dashboardPropsTotalPayout, subText2: <span className='text-ep-blue capitalize'>{dashboardLabel}</span>};
        dashboardProps2 = {...dashboardProps2, subText2: <span className='text-ep-blue capitalize'>{dashboardLabel}</span>};
        dashboardProps3 = {...dashboardProps3, subText2: <span className='text-ep-blue capitalize'>{dashboardLabel}</span>};
        dashboardProps4 = {...dashboardProps4, subText2: <span className='text-ep-blue capitalize'>{dashboardLabel}</span>};
        dashboardProps5 = {...dashboardProps5, subText2: <span className='text-ep-blue capitalize'>{dashboardLabel}</span>};
        dashboardProps6 = {...dashboardProps6, subText2: <span className='text-ep-blue capitalize'>{dashboardLabel}</span>};
        dashboardProps7 = {...dashboardProps7, subText2: <span className='text-ep-blue capitalize'>{dashboardLabel}</span>};
        
        
        this.setState({getDashboardParams, filter, dashboardProps1, dashboardPropsTotalPayout, dashboardProps2, dashboardProps3, dashboardProps4, dashboardProps5, dashboardProps6, dashboardProps7});
        
        
        this.props.getDashboardPerformanceRevenue(getDashboardParams);
        this.props.getDashboardPerformanceRepayment(getDashboardParams);
        this.props.getDashboard(getDashboardParams).then(() => {
            this.doWeeklyMonthlyWithdrawalsChart();
        });
        this.props.getDashboardCharts(getDashboardParams).then(() => {
            this.doMonthlyStatsChart();
        });
        this.props.getDashboardWithdrawalMonthStats({employerId: getDashboardParams.employerId}).then(() => {
            this.doWithdrawalMonthStatsChart();
        });
        this.props.getCompanyStats(getDashboardParams).then(() => {
            this.doWithdrawalsChart();
            this.doEmployeeStatsChart();
            this.doPlatformStatsChart();
        });
    }


    doWeeklyMonthlyWithdrawalsChart = () => {
        if ((this.props.dashboardWithdrawals) && (Object.keys(this.props.dashboardWithdrawals).length > 0)){
            
            // console.log("data.daysGraph",this.props.dashboardWithdrawals.dashboard.daysGraph);
            // console.log("data.monthsGraph",this.props.dashboardWithdrawals.dashboard.monthsGraph);

            const weeklyWithdrawals = this.props.dashboardWithdrawals.dashboard.daysGraph;
            const monthlyWithdrawals = this.props.dashboardWithdrawals.dashboard.monthsGraph;

            let weeklyWithdrawalsArray = [];
            Object.keys(weeklyWithdrawals).forEach(function(key) {
                weeklyWithdrawalsArray.push(weeklyWithdrawals[key]);
            });
            let monthlyWithdrawalsArray = [];
            Object.keys(monthlyWithdrawals).forEach(function(key) {
                monthlyWithdrawalsArray.push(monthlyWithdrawals[key]);
            });

            // console.log("weeklyWithdrawalsArray", weeklyWithdrawalsArray);
            // console.log("monthlyWithdrawalsArray", monthlyWithdrawalsArray);

            const weeklyWithdrawalsData = {
                ...this.state.weeklyWithdrawalsData,
                datasets: [{
                    ...this.state.weeklyWithdrawalsData.datasets[0],
                    data: weeklyWithdrawalsArray,
                }],
            }

            const monthlyWithdrawalsData = {
                ...this.state.monthlyWithdrawalsData,
                datasets: [{
                    ...this.state.monthlyWithdrawalsData.datasets[0],
                    data: monthlyWithdrawalsArray,
                }],
            }

            /*
            console.log("aaa");
            console.log(this.state.weeklyWithdrawalsData);
            console.log("bbb");
            console.log(weeklyWithdrawalsData);
            */

            this.setState({weeklyWithdrawalsData, monthlyWithdrawalsData});
        }
    }


    doMonthlyStatsChart = () => {
        const {dashboardCharts} = this.props;
        if (Object.keys(dashboardCharts).length > 0){

            let months = [];
            let withdrawals = [];
            let activeCompany = [];
            let activeEmployees = [];

            dashboardCharts.withdrawalStats.forEach((stat) => {
                withdrawals.push(stat.count);
                months.push(monthNamesShort[stat.month - 1]);
            });
            dashboardCharts.activeCompanyStats.forEach((stat) => {
                activeCompany.push(stat.count);
            });
            dashboardCharts.activeEmployeeStats.forEach((stat) => {
                activeEmployees.push(stat.count);
            });

            const monthlyStatisticsData = {
                ...this.state.monthlyStatisticsData,
                labels: months,
                datasets: [
                    {
                        ...this.state.monthlyStatisticsData.datasets[0],
                        data: withdrawals,
                    },
                    {
                        ...this.state.monthlyStatisticsData.datasets[1],
                        data: activeEmployees,
                    },
                    {
                        ...this.state.monthlyStatisticsData.datasets[2],
                        data: activeCompany,
                    },
                ],
            }
            this.setState({monthlyStatisticsData});
        }
    }


    doWithdrawalsChart = () => {
        const {companyStats} = this.props;
        const {withdrawalSizeDisplayCount} = this.state;
        // console.log(companyStats.withdrawalSize);
        

        if (companyStats && companyStats.withdrawalSize){

            let companyStatsWithdrawalSize = [];
            let withdrawalSizeDataOthers = {companyName: "Others", count: 0, percentage: 0};
            const companyStatsWithdrawalSizeMax = withdrawalSizeDisplayCount;
            companyStats.withdrawalSize.forEach((stat, index) => {
                if (index < companyStatsWithdrawalSizeMax){
                    companyStatsWithdrawalSize.push(stat);
                }
                else{
                    withdrawalSizeDataOthers = {
                        companyName: "Others",
                        count: (withdrawalSizeDataOthers.count + stat.count),
                        percentage: (withdrawalSizeDataOthers.percentage + stat.percentage),
                    }
                }
            })
            withdrawalSizeDataOthers = {
                ...withdrawalSizeDataOthers,
                percentage: parseFloat((withdrawalSizeDataOthers.percentage)?.toFixed(2)),
            }
            if (companyStats.withdrawalSize.length > withdrawalSizeDisplayCount){
                companyStatsWithdrawalSize.push(withdrawalSizeDataOthers);
            }

            
            
            const withdrawalSizeDataCompany = [];
            const withdrawalSizeDataCount = [];
            const withdrawalSizeDataPercentage = [];
            companyStatsWithdrawalSize.forEach(stat => {
                withdrawalSizeDataCompany.push(stat.companyName);
                withdrawalSizeDataCount.push(stat.count);
                withdrawalSizeDataPercentage.push(stat.percentage);
            })

            /*
            const withdrawalSizeData = {
                ...this.state.withdrawalSizeData,
                labels: [...withdrawalSizeDataCompany],
                datasets: [{
                    ...this.state.withdrawalSizeData.datasets[0],
                    data: [...withdrawalSizeDataPercentage],
                }]
            }
            */

            const withdrawalSizeData = {
                ...this.state.withdrawalSizeData,
                datasets: [{
                    ...this.state.withdrawalSizeData.datasets[0],
                    data: (withdrawalSizeDataPercentage.length !== 0) ? [...withdrawalSizeDataPercentage] : [100],
                    backgroundColor: (withdrawalSizeDataPercentage.length !== 0) ? [...withdrawalSizeDataColors] : ["#eee"],
                }],
                labels: (withdrawalSizeDataPercentage.length !== 0) ? [...withdrawalSizeDataCompany] : ["EMPTY"],
            }
            
            this.setState({companyStatsWithdrawalSize, withdrawalSizeData});
            
        }
    }


    setWithdrawalMonthStatsChartView = (view) => {
        this.setState({withdrawalMonthStatsChartView: view}, () => {
            this.doWithdrawalMonthStatsChart();
        });
    }
    

    doWithdrawalMonthStatsChart = () => {
        const {withdrawalMonthStats} = this.props;
        const {withdrawalMonthStatsChartView} = this.state;

        if (Object.keys(withdrawalMonthStats).length > 0){

            let chartDataArray = [...Array(12).fill(0)].slice(1);
            Object.keys(withdrawalMonthStats).forEach(function(key) {
                const index = parseInt(withdrawalMonthStats[key].month);
                const data = withdrawalMonthStats[key][withdrawalMonthStatsChartView];
                chartDataArray[index-1] = data;
            });
            
            const withdrawalMonthStatsChart = {
                ...this.state.withdrawalMonthStatsChart,
                datasets: [{
                    ...this.state.withdrawalMonthStatsChart.datasets[0],
                    data: chartDataArray,
                }],
            }

            this.setState({withdrawalMonthStatsChart});
        }
    }

    
    doEmployeeStatsChart = () => {
        const {companyStats} = this.props;

        if (companyStats && companyStats.employeeStats){
            /*
            const employeeStatsData = {
                ...this.state.employeeStatsData,
                datasets: [{
                    ...this.state.employeeStatsData.datasets[0],
                    data: [
                        companyStats.employeeStats.activePercentage,
                        companyStats.employeeStats.inactivePercentage,
                    ],
                }]
            }
            */
            const employeeStatsData = {
                ...this.state.employeeStatsData,
                datasets: [{
                    ...this.state.employeeStatsData.datasets[0],
                    data: [
                        (companyStats.employeeStats.activePercentage === null && companyStats.employeeStats.inactivePercentage === null) ? 100 : companyStats.employeeStats.activePercentage,
                        companyStats.employeeStats.inactivePercentage,
                    ],
                    backgroundColor: [
                        (companyStats.employeeStats.activePercentage === null && companyStats.employeeStats.inactivePercentage === null) ? '#eee' : '#0E26AB',
                        '#D9E3FF',
                    ]
                }],
                labels: [
                    (companyStats.employeeStats.activePercentage === null && companyStats.employeeStats.inactivePercentage === null) ? 'EMPTY' : 'Active',
                    'Inactive',
                ]
            }

            this.setState({employeeStatsData});
        }
    }
    
    
    doPlatformStatsChart = () => {
        const {companyStats} = this.props;

        if (companyStats && companyStats.platformStats){
            const platformStats = {
                web: null,
                mobile: null,
                ussd: null,
            };
            companyStats.platformStats.forEach(platformStat => {
                platformStats[platformStat._id] = platformStat.count;
            })

            const platformStatsData = {
                ...this.state.platformStatsData,
                datasets: [{
                    ...this.state.platformStatsData.datasets[0],
                    data: [
                        (platformStats.web === null && platformStats.mobile === null && platformStats.ussd === null) ? 100 :
                        platformStats.web,
                        platformStats.mobile,
                        platformStats.ussd,
                    ],
                    backgroundColor: [
                        (platformStats.web === null && platformStats.mobile === null && platformStats.ussd === null) ? '#eee' :
                        '#0E26AB',
                        '#36CAF5',
                        '#D3B418',
                    ]
                }],
                labels: [
                    (platformStats.web === null && platformStats.mobile === null && platformStats.ussd === null) ? 'EMPTY' :
                    'Web',
                    'Mobile',
                    'USSD',
                ]
            }

            this.setState({platformStatsData, platformStats});
        }
    }
    

    toggleSearch = () => {
        this.setState({toggleSearch: !this.state.toggleSearch});
    }


    toggleDateSelect = (e) => {
        e.preventDefault();
        
        const { startDate, endDate } = this.state.filter;
        // console.log("startDate", startDate);
        // console.log("endDate", endDate);

        if ((startDate === "") || (endDate === "") || (startDate <= endDate)){
            this.gotoUrlQuery({"startDate": startDate, "endDate": endDate});
            const filter = {...this.state.filter};
            this.setState({filter});
        }
        else{
            toast.error("Invalid date range selected");
        }
    }


    gotoUrlQuery = (params) => {
        const qs = queryString.parse(this.props.location.search);

        Object.keys(params).forEach((paramName) => {
            // console.log("qs", qs[paramName]);
            delete qs[paramName];
        });

        let qsToUrl = new URLSearchParams(qs).toString();
        qsToUrl = this.props.location.pathname + "?" + ((qsToUrl !== "")? qsToUrl + "&" : "");

        Object.keys(params).forEach((paramName) => {
            if (params[paramName] !== ""){
                qsToUrl += paramName + "=" + params[paramName] + "&";
            }
        });

        qsToUrl = qsToUrl.substring(0, qsToUrl.length - 1);
        this.props.history.push(qsToUrl);
    }


    toggleWithdrawalSizeModal = () => {
        const {toggleWithdrawalSizeModal, prevWithdrawalSizeDisplayCount} = this.state;
        const {companyStats} = this.props;
        
        if (toggleWithdrawalSizeModal){
            this.setState({toggleWithdrawalSizeModal: !toggleWithdrawalSizeModal, withdrawalSizeDisplayCount: prevWithdrawalSizeDisplayCount}, () => {
                this.doWithdrawalsChart();
            });
        }
        else{
            if (companyStats && companyStats.withdrawalSize){
                this.setState({toggleWithdrawalSizeModal: !toggleWithdrawalSizeModal, withdrawalSizeDisplayCount: companyStats.withdrawalSize.length}, () => {
                    this.doWithdrawalsChart();
                });
            }
        }
        
    }


    componentDidMount() {
        this.getDashboard();
        this.getAllEmployers();
    }

    
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.getDashboard();
        }
        if (this.props.reloadPage === true) {
            setTimeout(()=>{
                this.props.resetReloadPage();
                this.props.history.replace(this.props.location.pathname + this.props.location.search);
            },1000);
        }
    }


    render() { 
        customizeSVG();
        const { filter, employerFilterOptions, employerFilter, platformStats, dashboardProps1, dashboardPropsTotalPayout, dashboardProps2, dashboardProps3, dashboardProps4, dashboardProps5, dashboardProps6, dashboardProps7, companyStatsWithdrawalSize, toggleWithdrawalSizeModal, withdrawalMonthStatsChartView } = this.state;
        const { loadingDashboardPerformanceRevenue, dashboardPerformanceRevenue, loadingDashboardPerformanceRepayment, dashboardPerformanceRepayment, allEmployersLoading } = this.props;
        
        let barChartOptionsDisplay = {};
        barChartOptionsDisplay = barChartOptions;

        return ( 
            <div>


                {(toggleWithdrawalSizeModal === true) &&
                    <Dialog open={toggleWithdrawalSizeModal} initialFocus={this.completeButtonRef} onClose={this.toggleWithdrawalSizeModal} className="fixed z-10 inset-0 overflow-y-auto">
                        <div className="flex items-center justify-center min-h-screen">
                            <Dialog.Overlay className="fixed inset-0 bg-white bg-blur bg-opacity-50" />

                            <div className="w-full max-w-lg mx-auto relative bg-white rounded-md overflow-hidden shadow-xl">
                                <div className="h-14 px-4 bg-ep-blue flex justify-between overflow-hidden">
                                    <div className="font-medium flex-grow my-auto text-white">
                                        Withdrawal Size
                                    </div>
                                    <div onClick={this.toggleWithdrawalSizeModal} ref={this.completeButtonRef} className="w-7 h-7 flex flex-shrink-0 my-auto border border-transparent group hover:bg-red-400 hover:border-red-400 rounded-lg cursor-pointer">
                                        <FontAwesomeIcon icon="times" className="text-white m-auto" />
                                    </div>
                                </div>


                                <div className="p-6">
                                    {((this.props.companyStatsLoading === false) && (this.props.companyStats)) && (Object.keys(this.props.companyStats).length > 0) && 
                                        <div className="sm:flex sm:space-x-2 space-y-6 sm:space-y-0 justify-between">
                                            <div className="max-w-xs w-full sm:w-1/2 mx-auto">
                                                <div>
                                                    <Doughnut data={this.state.withdrawalSizeData} options={doughnutChartOptions} />
                                                </div>
                                            </div>
                                            <div className="w-full sm:w-1/2">
                                                <div className="h-full flex flex-col space-y-4 justify-center">
                                                    {(Object.keys(companyStatsWithdrawalSize).length > 0) && companyStatsWithdrawalSize.map((stat, index) => (
                                                        <div key={index} className="flex space-x-2">
                                                            <div className="w-3 h-3 mt-1 flex-shrink-0 rounded-full" style={{background: withdrawalSizeDataColors[index % 4]}}></div>
                                                            <div className="text-sm">
                                                                {stat.companyName} — ({stat.count})
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>

                            </div>

                        </div>
                    </Dialog>
                }


                <div className="xl:flex">
                    <div className="w-full xl:w-1/2 relative">
                        <div className="hidden lg:block absolute -top-5 text-sm font-bold">
                            Hello
                        </div>
                        <div className="page-title capitalize">
                            <span className="inline lg:hidden">Hello </span>
                            {this.props.user.userName}
                        </div>
                    </div>
                    <div className="w-full xl:w-1/2 flex mt-2 xl:mt-0 xl:justify-end overflow-auto">
                        
                        <div className="flex space-x-4">
                            <div onClick={this.toggleSearch} className="w-9 h-9 flex box-border-only border-gray-300 cursor-pointer">
                                <FontAwesomeIcon icon="search" className={"m-auto " + (!this.state.toggleSearch?"inline":"hidden")} />
                                <FontAwesomeIcon icon="times" className={"m-auto " + (this.state.toggleSearch?"inline":"hidden")} />
                            </div>
                            <form onSubmit={this.toggleDateSelect} className="flex box-border-only border-gray-300">
                                <div className="form-group mb-0 flex-grow">
                                    <input type="date" name="startDate" value={filter.startDate} className="form-input h-full" onChange={this.formChange} style={{border: "none"}} />
                                </div>
                                <div className="w-7 flex-shrink-0 flex">
                                    <img src={SwapRight} alt="arrow" className="m-auto h-5" />
                                </div>
                                <div className="form-group mb-0 flex-grow">
                                    <input type="date" name="endDate" value={filter.endDate} className="form-input h-full" onChange={this.formChange} style={{border: "none"}} />
                                </div>
                                <button type="submit" className="h-full px-3 py-1 flex items-center justify-center btn-ep-blue rounded-md rounded-l-none">
                                    <FontAwesomeIcon icon="angle-right" className="text-xl" />
                                </button>
                            </form>
                        </div>

                    </div>
                </div>



                {this.state.toggleSearch &&
                    <div className="mt-6 mb-4 sm:mb-0 flex space-x-4">
                        <div className="w-full max-w-xs">
                            {(allEmployersLoading === true) && 
                                <div className="w-full px-3 py-2 border border-gray-200 rounded">
                                    <FontAwesomeIcon icon="spinner" spin className="mr-1.5" />
                                    Loading
                                </div>
                            }
                            {(allEmployersLoading === false) && 
                                <Select options={employerFilterOptions} placeholder="Search Employees Firm" value={employerFilter} onChange={this.employerFilterValue} />
                            }
                        </div>
                        <div className="lg:col-span-2 flex">
                            {(allEmployersLoading === false) && (employerFilter !== "") &&
                                <div className="mx-auto sm:mx-0 my-auto flex space-x-6 text-sm">
                                    <div>
                                        {/* {(data) ? data.totalDocs : ""} {pluralize("result", (data) ? data.totalDocs : "")} found */}
                                    </div>
                                    <div onClick={this.clearEmployerFilterOptions} className="cursor-pointer text-red-500 border-b-2 border-transparent hover:border-red-500">
                                        <FontAwesomeIcon icon="times" className="mr-1" />
                                        Clear Filter
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                }



                <div className="mt-6 overflow-auto overscroll-x-contain">
                    <div className="w-full flex space-x-6">
                        <div className="w-80 flex-shrink-0">
                            <DashboardBoxes data={dashboardProps1} loading={loadingDashboardPerformanceRevenue} result={(dashboardPerformanceRevenue.result)? CurrencyFormat(dashboardPerformanceRevenue.result.totalTransactionVolume) : "-"} />                            
                        </div>
                        <div className="w-80 flex-shrink-0">
                            <DashboardBoxes data={dashboardPropsTotalPayout} loading={loadingDashboardPerformanceRevenue} result={(dashboardPerformanceRevenue.result)? CurrencyFormat(dashboardPerformanceRevenue.result.totalPayout) : "-"} />                            
                        </div>
                        {(this.props.user.userType === "super-admin" || this.props.user.userType === "financial-admin") &&
                            <div className="w-80 flex-shrink-0">
                                <DashboardBoxes data={dashboardProps2} loading={loadingDashboardPerformanceRevenue} result={(dashboardPerformanceRevenue.result)? CurrencyFormat(dashboardPerformanceRevenue.result.totalRevenue) : "-"} />
                            </div>
                        }
                        <div className="w-80 flex-shrink-0">
                            <DashboardBoxes data={dashboardProps3} loading={loadingDashboardPerformanceRevenue} result={(dashboardPerformanceRevenue.result)? dashboardPerformanceRevenue.result.revenuePayoutRatio?.toFixed(2) ?? "-" : "-"} />
                        </div>
                        <div className="w-80 flex-shrink-0">
                            <DashboardBoxes data={dashboardProps4} loading={loadingDashboardPerformanceRepayment} result={(dashboardPerformanceRepayment.result)? CurrencyFormat(dashboardPerformanceRepayment.result.totalConfirmedRepayment) : "-"} />
                        </div>
                        <div className="w-80 flex-shrink-0">
                            <DashboardBoxes data={dashboardProps5} loading={loadingDashboardPerformanceRepayment} result={(dashboardPerformanceRepayment.result)? CurrencyFormat(dashboardPerformanceRepayment.result.totalOutstandingPayments) : "-"} />
                        </div>
                        <div className="w-80 flex-shrink-0">
                            <DashboardBoxes data={dashboardProps6} loading={loadingDashboardPerformanceRepayment} result={(dashboardPerformanceRepayment.result)? dashboardPerformanceRepayment.result.totalCompanyCount : "-"} />
                        </div>
                        <div className="w-80 flex-shrink-0">
                            <DashboardBoxes data={dashboardProps7} loading={loadingDashboardPerformanceRevenue} result={(dashboardPerformanceRevenue.result)? CurrencyFormat(dashboardPerformanceRevenue.result.totalWageSize) : "-"} />
                        </div>
                    </div>
                </div>


                <div className="mt-6">
                    <div className="grid grid-cols-1 xl:grid-cols-3 gap-6 items-start">
                        <div className="xl:col-span-2 space-y-6">

                            <div className="grid grid-cols-1 2xl:grid-cols-2 gap-6">
                                <div className="box">
                                    <div className="page-title-mini">
                                        Weekly Withdrawals
                                    </div>
                                    <div style={{"height":"350px"}}>

                                        {(this.props.dashboardWithdrawalsLoading === true) && 
                                            <div className="table-info">
                                                <FontAwesomeIcon icon="spinner" spin />
                                                <div className="font-bold uppercase">
                                                    Loading
                                                </div>
                                            </div>
                                        }

                                        {((this.props.dashboardWithdrawalsLoading === false) && (this.props.dashboardWithdrawals)) && (Object.keys(this.props.dashboardWithdrawals).length <= 0) && 
                                            <div className="table-info">
                                                <FontAwesomeIcon icon="list" />
                                                <div className="font-bold uppercase">
                                                    No results found
                                                </div>
                                            </div>
                                        }

                                        {((this.props.dashboardWithdrawalsLoading === false) && (this.props.dashboardWithdrawals)) && (Object.keys(this.props.dashboardWithdrawals).length > 0) && 
                                            <div className="h-full mt-4 overflow-hidden">
                                                <div className="h-full relative -top-6">
                                                    <Line data={this.state.weeklyWithdrawalsData} options={lineChartOptions} />
                                                </div>
                                            </div>
                                        }

                                    </div>
                                </div>

                                <div className="box">
                                    <div className="page-title-mini">
                                        Monthly Withdrawals (₦)
                                    </div>
                                    <div style={{"height":"350px"}}>

                                        {(this.props.dashboardWithdrawalsLoading === true) && 
                                            <div className="table-info">
                                                <FontAwesomeIcon icon="spinner" spin />
                                                <div className="font-bold uppercase">
                                                    Loading
                                                </div>
                                            </div>
                                        }

                                        {((this.props.dashboardWithdrawalsLoading === false) && (this.props.dashboardWithdrawals)) && (Object.keys(this.props.dashboardWithdrawals).length <= 0) && 
                                            <div className="table-info">
                                                <FontAwesomeIcon icon="list" />
                                                <div className="font-bold uppercase">
                                                    No results found
                                                </div>
                                            </div>
                                        }

                                        {((this.props.dashboardWithdrawalsLoading === false) && (this.props.dashboardWithdrawals)) && (Object.keys(this.props.dashboardWithdrawals).length > 0) && 
                                            <div className="h-full mt-4 overflow-hidden">
                                                <div className="h-full relative -top-6">
                                                    <Bar data={this.state.monthlyWithdrawalsData} options={barChartOptionsDisplay} />
                                                </div>
                                            </div>
                                        }

                                    </div>
                                </div>
                            </div>


                            <div className="box">
                                <div className="page-title-mini">
                                    Monthly Statistics
                                </div>
                                <div className="h-96">

                                    {(this.props.dashboardChartsLoading === true) && 
                                        <div className="table-info">
                                            <FontAwesomeIcon icon="spinner" spin />
                                            <div className="font-bold uppercase">
                                                Loading
                                            </div>
                                        </div>
                                    }

                                    {((this.props.dashboardChartsLoading === false) && (this.props.dashboardCharts)) && (Object.keys(this.props.dashboardCharts).length <= 0) && 
                                        <div className="table-info">
                                            <FontAwesomeIcon icon="list" />
                                            <div className="font-bold uppercase">
                                                No results found
                                            </div>
                                        </div>
                                    }

                                    {((this.props.dashboardChartsLoading === false) && (this.props.dashboardCharts)) && (Object.keys(this.props.dashboardCharts).length > 0) && 
                                        <div className="h-full mt-4 overflow-hidden">
                                            <div className="h-full relative -top-6">
                                                <Bar data={this.state.monthlyStatisticsData} options={barChartOptionsDisplay} />
                                            </div>
                                        </div>
                                    }

                                </div>
                                <div className="flex space-x-8 justify-center">
                                    <div className="flex space-x-2">
                                        <div className="w-4 h-4 flex-shrink-0" style={{background:"#36CAF5"}}></div>
                                        <div className="text-sm">
                                            No. of Withdrawals
                                        </div>
                                    </div>
                                    <div className="flex space-x-2">
                                        <div className="w-4 h-4 flex-shrink-0" style={{background:"#0E26AB"}}></div>
                                        <div className="text-sm">
                                            No. of Active companies
                                        </div>
                                    </div>
                                    <div className="flex space-x-2">
                                        <div className="w-4 h-4 flex-shrink-0" style={{background:"#05083F"}}></div>
                                        <div className="text-sm">
                                            No. of Active employees
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="box">
                                <div className="xl:flex xl:justify-between space-y-2 xl:space-y-0">
                                    <div>
                                        <div className="page-title-mini">
                                            Monthly Withdrawals
                                        </div>
                                        <div className="text-fade">
                                            Showing: 1st of each month - {numberOrdinalSuffix(new Date().getDate())} of each month
                                        </div>
                                    </div>
                                    <div>
                                        <div className="inline-block">
                                            <div className="tab-design-2">
                                                <div className={withdrawalMonthStatsChartView === "count" ? "active":""} onClick={() => this.setWithdrawalMonthStatsChartView("count")}>
                                                    Count
                                                </div>
                                                <div className={withdrawalMonthStatsChartView === "amount" ? "active":""} onClick={() => this.setWithdrawalMonthStatsChartView("amount")}>
                                                    Amount (₦)
                                                </div>
                                                <div className={withdrawalMonthStatsChartView === "revenue" ? "active":""} onClick={() => this.setWithdrawalMonthStatsChartView("revenue")}>
                                                    Revenue (₦)
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{"height":"350px"}}>

                                    {(this.props.withdrawalMonthStatsLoading === true) && 
                                        <div className="table-info">
                                            <FontAwesomeIcon icon="spinner" spin />
                                            <div className="font-bold uppercase">
                                                Loading
                                            </div>
                                        </div>
                                    }

                                    {((this.props.withdrawalMonthStatsLoading === false) && (this.props.withdrawalMonthStats)) && (Object.keys(this.props.withdrawalMonthStats).length <= 0) && 
                                        <div className="table-info">
                                            <FontAwesomeIcon icon="list" />
                                            <div className="font-bold uppercase">
                                                No results found
                                            </div>
                                        </div>
                                    }

                                    {((this.props.withdrawalMonthStatsLoading === false) && (this.props.withdrawalMonthStats)) && (Object.keys(this.props.withdrawalMonthStats).length > 0) && 
                                        <div className="h-full mt-4 overflow-hidden">
                                            <div className="h-full relative -top-6">
                                                <Bar data={this.state.withdrawalMonthStatsChart} plugins={[ChartDataLabels]} options={barChartOptionsDisplay} />
                                            </div>
                                        </div>
                                    }

                                </div>
                            </div>


                        </div>
                        <div className="w-full grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-1 gap-6">
                            <div className="box">

                                <div>
                                    <div className="page-title-mini">
                                        Withdrawal Size
                                    </div>
                                    <div className="mt-2">

                                        {(this.props.companyStatsLoading === true) && 
                                            <div className="table-info">
                                                <FontAwesomeIcon icon="spinner" spin />
                                                <div className="font-bold uppercase">
                                                    Loading
                                                </div>
                                            </div>
                                        }

                                        {((this.props.companyStatsLoading === false) && (this.props.companyStats)) && (Object.keys(this.props.companyStats).length <= 0) && 
                                            <div className="table-info">
                                                <FontAwesomeIcon icon="list" />
                                                <div className="font-bold uppercase">
                                                    No data found
                                                </div>
                                            </div>
                                        }

                                        {((this.props.companyStatsLoading === false) && (this.props.companyStats)) && (Object.keys(this.props.companyStats).length > 0) && 
                                            <div className="sm:flex sm:space-x-2 space-y-2 sm:space-y-0 justify-between">
                                                <div className="w-full sm:w-1/2">
                                                    <div>
                                                        <Doughnut data={this.state.withdrawalSizeData} options={doughnutChartOptions} />
                                                    </div>
                                                </div>
                                                <div className="w-full sm:w-1/2">
                                                    <div className="h-full flex flex-col space-y-2 justify-center">
                                                        {(Object.keys(companyStatsWithdrawalSize).length > 0) && companyStatsWithdrawalSize.map((stat, index) => (
                                                            <div key={index} className="flex space-x-2">
                                                                <div className="w-3 h-3 mt-1 flex-shrink-0 rounded-full" style={{background: withdrawalSizeDataColors[index % 4]}}></div>
                                                                <div className="text-sm">
                                                                    {stat.companyName} — ({stat.count})
                                                                </div>
                                                            </div>
                                                        ))}

                                                        {(Object.keys(companyStatsWithdrawalSize).length > 0) && !toggleWithdrawalSizeModal &&
                                                            <div>
                                                                <span onClick={this.toggleWithdrawalSizeModal} className="text-sm underline cursor-pointer">
                                                                    View All
                                                                </span>
                                                            </div>
                                                        }

                                                        {(Object.keys(companyStatsWithdrawalSize).length === 0) &&
                                                            <div>
                                                                <span className="text-sm italic">
                                                                    No record found
                                                                </span>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                    </div>
                                </div>
                            </div>

                            <div className="box">

                                <div>
                                    <div className="page-title-mini">
                                        Employee Statistics
                                    </div>
                                    <div className="mt-2">

                                        {(this.props.companyStatsLoading === true) && 
                                            <div className="table-info">
                                                <FontAwesomeIcon icon="spinner" spin />
                                                <div className="font-bold uppercase">
                                                    Loading
                                                </div>
                                            </div>
                                        }

                                        {((this.props.companyStatsLoading === false) && (this.props.companyStats)) && (Object.keys(this.props.companyStats).length <= 0) && 
                                            <div className="table-info">
                                                <FontAwesomeIcon icon="list" />
                                                <div className="font-bold uppercase">
                                                    No data found
                                                </div>
                                            </div>
                                        }

                                        {((this.props.companyStatsLoading === false) && (this.props.companyStats)) && (Object.keys(this.props.companyStats).length > 0) && 
                                            <div className="sm:flex sm:space-x-2 space-y-2 sm:space-y-0 justify-between">
                                                <div className="w-full sm:w-1/2">
                                                    <div>
                                                        <Doughnut data={this.state.employeeStatsData} options={doughnutChartOptions} />
                                                    </div>
                                                </div>
                                                <div className="w-full sm:w-1/2">
                                                    <div className="h-full flex flex-col space-y-2 justify-center">
                                                        <Link to="/users/employees/withdraw-once-or-more" className="flex space-x-2 group">
                                                            <FontAwesomeIcon icon="info-circle" data-tip data-for="active-employee" className="w-4 h-4 mt-px flex-shrink-0 rounded-full" style={{color:"#0E26AB"}} />
                                                            <ReactToolTip id="active-employee" className="tooptip-size">
                                                                Employees that have withdrawn at least once
                                                            </ReactToolTip>

                                                            {/* <div className="w-3 h-3 mt-1 flex-shrink-0 rounded-full" style={{background:"#0E26AB"}}></div> */}
                                                            <div className="text-sm group-hover:underline">
                                                                Active Employee
                                                            </div>
                                                        </Link>
                                                        <div className="flex space-x-2">
                                                            <FontAwesomeIcon icon="info-circle" data-tip data-for="inactive-employee" className="w-4 h-4 mt-px flex-shrink-0 rounded-full" style={{color:"#D9E3FF"}} />
                                                            <ReactToolTip id="inactive-employee" className="tooptip-size">
                                                                Employees that have NOT withdrawn at all
                                                            </ReactToolTip>

                                                            {/* <div className="w-3 h-3 mt-1 flex-shrink-0 rounded-full" style={{background:"#D9E3FF"}}></div> */}
                                                            <div className="text-sm">
                                                                Inactive Employee
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                    </div>
                                </div>
                            </div>

                            <div className="box">

                                <div>
                                    <div className="page-title-mini">
                                        Withdrawal Channels
                                    </div>
                                    <div className="mt-2">

                                        {(this.props.companyStatsLoading === true) && 
                                            <div className="table-info">
                                                <FontAwesomeIcon icon="spinner" spin />
                                                <div className="font-bold uppercase">
                                                    Loading
                                                </div>
                                            </div>
                                        }

                                        {((this.props.companyStatsLoading === false) && (this.props.companyStats)) && (Object.keys(this.props.companyStats).length <= 0) && 
                                            <div className="table-info">
                                                <FontAwesomeIcon icon="list" />
                                                <div className="font-bold uppercase">
                                                    No data found
                                                </div>
                                            </div>
                                        }

                                        {((this.props.companyStatsLoading === false) && (this.props.companyStats)) && (Object.keys(this.props.companyStats).length > 0) && 
                                            <div className="sm:flex sm:space-x-2 space-y-2 sm:space-y-0 justify-between">
                                                <div className="w-full sm:w-1/2">
                                                    <div>
                                                        <Doughnut data={this.state.platformStatsData} options={doughnutChartOptions} />
                                                    </div>
                                                </div>
                                                <div className="w-full sm:w-1/2">
                                                    <div className="h-full flex flex-col space-y-2 justify-center">
                                                        <div className="flex space-x-2">
                                                            <div className="w-3 h-3 mt-1 flex-shrink-0 rounded-full" style={{background:"#0E26AB"}}></div>
                                                            <div className="text-sm group-hover:underline">
                                                                Web ({platformStats.web})
                                                            </div>
                                                        </div>
                                                        <div className="flex space-x-2">
                                                            <div className="w-3 h-3 mt-1 flex-shrink-0 rounded-full" style={{background:"#36CAF5"}}></div>
                                                            <div className="text-sm">
                                                                Mobile ({platformStats.mobile})
                                                            </div>
                                                        </div>
                                                        {/* 
                                                        <div className="flex space-x-2">
                                                            <div className="w-3 h-3 mt-1 flex-shrink-0 rounded-full" style={{background:"#D3B418"}}></div>
                                                            <div className="text-sm">
                                                                USSD ({platformStats.ussd})
                                                            </div>
                                                        </div>
                                                         */}
                                                    </div>
                                                </div>
                                            </div>
                                        }

                                    </div>
                                </div>

                            </div>
                            
                        </div>
                    </div>
                </div>



            </div>
        );
    }
}


const mapStateToProps = (state) => ({
    user: state.auth.userPersist.data,

    dashboardWithdrawalsLoading: state.entities.dashboard.dashboardWithdrawalsLoading,
    dashboardWithdrawals: state.entities.dashboard.dashboardWithdrawals,

    loadingDashboardPerformanceRevenue: state.entities.dashboard.loadingDashboardPerformanceRevenue,
    dashboardPerformanceRevenue: state.entities.dashboard.dashboardPerformanceRevenue,

    loadingDashboardPerformanceRepayment: state.entities.dashboard.loadingDashboardPerformanceRepayment,
    dashboardPerformanceRepayment: state.entities.dashboard.dashboardPerformanceRepayment,

    dashboardChartsLoading: state.entities.dashboard.dashboardChartsLoading,
    dashboardCharts: state.entities.dashboard.dashboardCharts,

    companyStatsLoading: state.entities.dashboard.companyStatsLoading,
    companyStats: state.entities.dashboard.companyStats,

    withdrawalMonthStatsLoading: state.entities.dashboard.withdrawalMonthStatsLoading,
    withdrawalMonthStats: state.entities.dashboard.withdrawalMonthStats,

    allEmployersLoading: state.entities.employers.loading,
    allEmployers: state.entities.employers.allEmployers,
});

const mapDispatchToProps = (dispatch) => ({
    getDashboard: (params) => dispatch(dashboard.getAllDashboard(params)),
    getDashboardPerformanceRevenue: (params) => dispatch(dashboard.getDashboardPerformanceRevenue(params)),
    getDashboardPerformanceRepayment: (params) => dispatch(dashboard.getDashboardPerformanceRepayment(params)),
    getDashboardCharts: (params) => dispatch(dashboard.getDashboardCharts(params)),
    getCompanyStats: (params) => dispatch(dashboard.getCompanyStats(params)),
    getDashboardWithdrawalMonthStats: (params) => dispatch(dashboard.getDashboardWithdrawalMonthStats(params)),
    getAllEmployers: (params) => dispatch(employers.getAllEmployers(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);