import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Maybe from "components/__new/common/Maybe";
import useToolkit from "utils/useToolkit";

const SavingsDetails = ({ control, onClose }) => {
	const {
		useSelector,
		Spinner,
		Xclose,
		CurrencyFormat,
		DateFormat,
		avail,
	} = useToolkit();

	const { savingsDetailsLoading: loading, savingsDetailsData: details, savingsTrxLoading: trxLoading } = useSelector((state) => state.entities.savings);
	
	const detailsNotFound = loading === false && details === null
	const data = details?.goal
	const wallet = data?.wallet
	const isGoalSavings = data?.type === 'target'
	const email = wallet?.email
	const phone =  wallet?.phoneNumber
	const firstName = wallet?.firstName
	const lastName = wallet?.lastName
	const dailyDepositValue = CurrencyFormat(data?.auto_save?.amount / 100)

	return (
		<div className={
			"max-w-md w-screen h-screen bg-white fixed z-50 top-0 bottom-0 shadow-xl transition-all duration-300 ease-in-out " +
			(control ? "right-0" : "-right-full")
			}>
			<div className="w-full h-full p-5 pb-10 relative overflow-auto overscroll-contain">
				<Maybe condition={loading || trxLoading}>
					<Spinner text='Fetching Details' />
				</Maybe>
				
				<Maybe condition={detailsNotFound}>
					<div className="table-info">
						<FontAwesomeIcon icon="list" />
						<div className="font-bold uppercase">
							Savings details not found
						</div>
					</div>
				</Maybe>
				
				<Maybe condition={loading === false && detailsNotFound === false}>
					<div className="w-full">
						<div>
							<div className="flex justify-between">
								<div className="font-recoleta font-semibold text-2xl capitalize">
									{firstName} {lastName || ''}
								</div>
								<div
									onClick={onClose}
									className="w-8 h-8 flex group hover:bg-red-400 rounded-lg cursor-pointer">
									<Xclose className="customizeSVG group-hover:text-white m-auto" />
								</div>
							</div>
							<div className="text-fade text-xs">
								{avail(email)}
							</div>
							<div className="text-fade text-xs">
								<strong className="text-ep-blue-deeper">{isGoalSavings ?'Goal' : 'Flex'} Savings</strong>
							</div>
						</div>

						<div className="w-full h-px mt-5 bg-gray-200"></div>
						<div className="my-6">
							<div className="font-semibold text-sm">Saving Details</div>

							<div className="mt-5 grid grid-cols-2 gap-x-4">
								<div className="space-y-5">
									<div className="my-auto">
										<div className="font-semibold text-xs">Saving Title</div>
										<div className="mt-1 font-medium text-xs capitalize">
										{data?.goal}
										</div>
									</div>

									<div className="w-full my-auto">
										<div className="font-semibold text-xs">Status</div>
										<div className="mt-1 font-medium text-xs break-words text-ep-primary-500 capitalize">
										{data?.status}
										</div>
									</div>

									<div className="w-full my-auto">
										<div className="font-semibold text-xs">Start Date</div>
										<div className="mt-1 font-medium text-xs break-words">
										{DateFormat(data?.startsAt)}
										</div>
									</div>

									<div className="w-full my-auto">
										<div className="font-semibold text-xs">Amount Saved</div>
										<div
											style={{ color: "#00535C" }}
											className="mt-1 font-medium text-xs break-words">
										{CurrencyFormat(details?.savings)}
										</div>
									</div>
									
									<div className="w-full my-auto">
										<div className="font-semibold text-xs">Savings source</div>
										<div
											style={{ color: "#00535C" }}
											className="mt-1 font-medium text-xs break-words capitalize">
											{data?.source}
										</div>
									</div>
								</div>
								<div className="space-y-5">
									<div className="my-auto">
										<div className="font-semibold text-xs">Target Amount</div>
										<div className="mt-1 font-medium text-xs">
											{CurrencyFormat(data?.target / 100)}
										</div>
									</div>

									<div className="my-auto">
										<div className="font-semibold text-xs">Interest Accrued</div>
										<div className="mt-1 font-medium text-xs">
											{CurrencyFormat(details?.interest_accrued / 100)}
										</div>
									</div>

									<div className="my-auto">
										<div className="font-semibold text-xs">End Date</div>
										<div className="mt-1 font-medium text-xs">
											{DateFormat(data?.endsAt)}
										</div>
									</div>

									<div className="my-auto">
										<div className="font-semibold text-xs"><span className="capitalize">{data?.auto_save?.plan}</span> Deposit</div>
										<div className="mt-1 font-medium text-xs">
											{dailyDepositValue}
										</div>
									</div>

									<div className="my-auto">
										<div className="font-semibold text-xs">Amount Withdrawn</div>
										<div
											style={{ color: "#EF4444" }}
											className="mt-1 font-medium text-xs">
											{CurrencyFormat(details?.withdrawals)}
										</div>
									</div>
									
								</div>
							</div>

							<div className="w-full h-px mt-8 bg-gray-200"></div>
							<div className="mt-6 font-semibold text-sm">User Details</div>

							<div className="mt-5 grid grid-cols-2 gap-x-4">
								<div className="space-y-5">
									<div className="my-auto">
										<div className="font-semibold text-xs">Name</div>
										<div className="mt-1 font-medium text-xs capitalize">
											{firstName} {lastName || ''}
										</div>
									</div>
									
									<div className="my-auto">
										<div className="font-semibold text-xs">Phone Number</div>
										<div className="mt-1 font-medium text-xs">
										{avail(phone)}
										</div>
									</div>
								</div>
								<div className="space-y-5">
									<div className="my-auto">
										<div className="font-semibold text-xs">Email Address</div>
										<div className="mt-1 font-medium text-xs">
											{avail(email)}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Maybe>
			</div>
		</div>
	);
};

export default SavingsDetails;
