import React, { Component } from 'react';
import Joi from "joi-browser";
import store from "store/store";
import queryString from 'query-string';
import DashboardBoxes from 'components/common/dashboard-boxes';
import Pagination from 'components/common/pagination';
import Dropdown from "utils/dropdown";
import Select from 'react-select'
import pluralize from "utils/pluralize";
import DialogBox from 'utils/dialogBox';
import { CSVLink } from "react-csv";
import Download from 'components/common/download';
import ReactToolTip from 'react-tooltip';
import SearchFilter from 'components/common/filter';
import { DateTimeFormat } from "utils/dateFormat";
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import { toast } from 'react-toastify';
import { customizeSVG } from 'utils/customizeSVG.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactComponent as IconEmployees } from "assets/images/icons/project-icons/employees.svg";
import { ReactComponent as IconNav } from "assets/images/icons/project-icons/Navicon-v.svg";
import * as employees from "store/entities/employees/action";
import * as employers from "store/entities/employers/action";

const dropdownTableIcon = <IconNav className="w-8 h-8 p-1.5 relative top-1 cursor-pointer group-hover:bg-gray-200 rounded" />;

const columns = [
    { label: "ID", key: "employee._id" },
    { label: "Employee ID", key: "employee.employeeId" },
    { label: "Full Name", key: "employee.fullName" },
    { label: "Email Address", key: "employee.email" },
    { label: "Phone Number", key: "employee.phoneNumber" },
    { label: "Company Name", key: "employer.companyName" },
    { label: "Bank Name", key: "employee.bankName" },
    { label: "Account Number", key: "employee.bankAccountNumber" },
    { label: "Job Title", key: "employee.jobTitle" },
    { label: "Group", key: "employee.employeeGroup" },
    { label: "Date Added", key: "employee.createdAt" },
    { label: "Status", key: "employee.status" },
    { label: "Stop Earnings", key: "employee.stopEarning" },
    { label: "Disabed", key: "employee.disabled" },
];
class List extends Component {
    constructor(props) {
        super(props);
        this.csvLinkEl = React.createRef();
    }

    state = {
        getAllEmployeesParams: {
            page: 1,
            limit: 20,
            status: "",
            download: false,
            employerId: "",
        },
        dashboardProps1: {
            iconBg: "bg-blue-200",
            icon: <IconEmployees className="customizeSVG text-blue-600 m-auto w-5 h-5" />,
            titleText: "Total Employees",
            mainText: "-"
        },
        dashboardProps2: {
            iconBg: "bg-green-200",
            icon: <FontAwesomeIcon icon="check-circle" className="customizeSVG text-green-600 m-auto w-5 h-5" />,
            titleText: "Enrolled Employees",
            mainText: "-"
        },
        dashboardProps3: {
            iconBg: "bg-yellow-200",
            icon: <FontAwesomeIcon icon="dot-circle" className="customizeSVG text-yellow-600 m-auto w-5 h-5" />,
            titleText: "Active Employees",
            mainText: "-"
        },
        dataDownload: [],
        storeUnsubscribe: "",
        employerFilterOptions: [],
        employerFilter: "",
    }


    formChange = (e) => {
        const formValue = {...this.state.search};
        formValue[e.currentTarget.name] = e.currentTarget.value;
        this.setState({search: formValue});
    }


    schema = {
        searchEmployee: Joi.string().allow(null,'').label("Search Employee"),
    }

    
    validate = () => {
        const options = { abortEarly: false };
        const result = Joi.validate(this.state.search, this.schema, options);
        // console.log(result);
        const error = result.error;
        if (!error) return null;
    
        const errors = {};
        for (let item of result.error.details){
            errors[item.path[0]] = item.message;
        }
        return errors;
    };


    searchEmployee = async(e) => {
        e.preventDefault();
        const errors = this.validate();
        this.setState({searchErrors: errors || {}});
        if (errors){
            // console.log(errors);
            for (var x in errors) {
                toast.error(errors[x]);
            };
            return;
        }
        else{
            const searchUrl = encodeURIComponent(this.state.search.searchEmployee);
            if (searchUrl !== ""){
                this.props.history.push("?search="+searchUrl);
            }
            else{
                this.props.history.push(this.props.location.pathname);
            }
        }
    }

    
    downloadData = async () => {
        const downloadDataParams = {...this.state.getAllEmployeesParams, download: true};
        var paramsQueryString = Object.keys(downloadDataParams).map((key) => {
            return encodeURIComponent(key) + '=' + encodeURIComponent(downloadDataParams[key])
        }).join('&');
        // console.log("paramsQueryString", paramsQueryString);
        this.props.downloadGetAllEmployeesWithdrawOnceOrMore(paramsQueryString);

        this.unsubscribe = store.subscribe(() => {
            if (this.props.downloadEmployeesWithdrawOnceOrMoreLoading === false){
                // if ((this.props.downloadEmployeesWithdrawOnceOrMoreLoading) && (Object.keys(this.props.downloadEmployeesWithdrawOnceOrMoreLoading).length > 0)){
                // console.log("this.props.downloadEmployeesWithdrawOnceOrMoreLoading",this.props.downloadEmployeesWithdrawOnceOrMoreLoading);
                if (this.props.downloadEmployeesWithdrawOnceOrMore){
                    const dataJSON = this.props.downloadEmployeesWithdrawOnceOrMore.withdrawals;
                    // console.log("dataJSON", dataJSON);

                    Object.keys(dataJSON).forEach((key) => {
                        if ((dataJSON[key].groupPolicy)){
                            dataJSON[key].employee.employeeGroup = dataJSON[key].groupPolicy.name;
                        }
                        else if ((dataJSON[key].employee.employeeGroup) && (dataJSON[key].employee.employeeGroup === "")){
                            dataJSON[key].employee.employeeGroup = "General Group";
                        }
                        else if (!(dataJSON[key].employee.employeeGroup)){
                            dataJSON[key].employee.employeeGroup = "General Group";
                        }
                    });

                    const data = Object.values(dataJSON);
                    // console.log("data", data);
                    this.setState({ dataDownload: data }, () => {
                        setTimeout(() => {
                            this.csvLinkEl.current.link.click();
                            toast.success("Data Downloaded");
                        });
                    });
                }
                else{
                    toast.error("An error occurred while downloading file.");
                }
                this.unsubscribe();
            }
        });
        this.setState({storeUnsubscribe: this.unsubscribe});
    }


    employerFilterValue = (employerId) => {
        // console.log(employerId);
        this.setState({employerFilter: employerId});
        this.gotoUrlQuery({"employerId": employerId.value});
    }

    
    clearEmployerFilterOptions = () => {
        this.setState({employerFilter: ""});
        this.gotoUrlQuery({"employerId": ""});
    }


    gotoUrlQuery = (params) => {
        const qs = queryString.parse(this.props.location.search);

        Object.keys(params).forEach((paramName) => {
            // console.log("qs", qs[paramName]);
            delete qs[paramName];
        });

        let qsToUrl = new URLSearchParams(qs).toString();
        qsToUrl = this.props.location.pathname + "?" + ((qsToUrl !== "")? qsToUrl + "&" : "");

        Object.keys(params).forEach((paramName) => {
            if (params[paramName] !== ""){
                qsToUrl += paramName + "=" + params[paramName] + "&";
            }
        });

        qsToUrl = qsToUrl.substring(0, qsToUrl.length - 1);
        this.props.history.push(qsToUrl);
    }


    selectEmployerFilter = () => {
        const qs = queryString.parse(this.props.location.search);
        const { employerFilterOptions } = this.state;
        let employerName = "";
        
        if ((typeof qs.employerId !== undefined) && (qs.employerId !== undefined) && (qs.employerId !== null)){
            let employerFilter = {value: qs.employerId, label: ""};
            // console.log(employerFilterOptions);
            
            if (Object.keys(employerFilterOptions).length > 0){
                Object.keys(employerFilterOptions).forEach((employer) => {
                    if (employerFilterOptions[employer].value === qs.employerId){
                        employerName = employerFilterOptions[employer].label;
                    }
                });

                if (employerName !== ""){
                    employerFilter = {...employerFilter, label: employerName};
                }
                else{
                    toast.error("Invalid employee firm selected");
                }
            }
            this.setState({employerFilter});
        }
    }


    getAllEmployers = () => {
        const getAllEmployersParams = {
            page: 1,
            limit: 1000,
            status: "active",
            name: "",
            download: false,
        }
        this.props.getAllEmployers(getAllEmployersParams);
    }


    getAllQueryFilters = (params) => {
        const qs = queryString.parse(this.props.location.search);

        if ((qs.search !== undefined) && (qs.search !== null)){
            params = {...params, keyword: qs.search};
        }
        else{
            params = {...params, keyword: ""};
        }

        if ((qs.isActive !== undefined) && (qs.isActive !== null)){
            params = {...params, isActive: qs.isActive === "true" ? true : false};
        }
        else{
            params = {...params, isActive: ""};
        }

        if ((qs.registeredFrom !== undefined) && (qs.registeredFrom !== null)){
            params = {...params, registeredFrom: qs.registeredFrom};
        }
        else{
            params = {...params, registeredFrom: ""};
        }

        if ((qs.registeredTo !== undefined) && (qs.registeredTo !== null)){
            params = {...params, registeredTo: qs.registeredTo};
        }
        else{
            params = {...params, registeredTo: ""};
        }

        if ((qs.salaryFrom !== undefined) && (qs.salaryFrom !== null)){
            params = {...params, salaryFrom: parseInt(qs.salaryFrom)};
        }
        else{
            params = {...params, salaryFrom: ""};
        }

        if ((qs.salaryTo !== undefined) && (qs.salaryTo !== null)){
            params = {...params, salaryTo: parseInt(qs.salaryTo)};
        }
        else{
            params = {...params, salaryTo: ""};
        }

        if ((typeof qs.employerId !== undefined) && (qs.employerId !== undefined) && (qs.employerId !== null)){
            params = {...params, employerId: qs.employerId};
        }
        else{
            params = {...params, employerId: ""};
        }

        return params;
    }


    getAllEmployees = () => {
        const qs = queryString.parse(this.props.location.search);
        let getAllEmployeesParams = {...this.state.getAllEmployeesParams};
        
        if ((qs.page !== undefined) && (!isNaN(qs.page)) && (qs.page > 0)){
            getAllEmployeesParams = {...getAllEmployeesParams, page: qs.page};
        }
        else{
            getAllEmployeesParams = {...getAllEmployeesParams, page: 1};
        }
        
        if ((qs.limit !== undefined) && (!isNaN(qs.limit)) && (qs.limit > 0)){
            getAllEmployeesParams = {...getAllEmployeesParams, limit: qs.limit};
        }
        else{
            getAllEmployeesParams = {...getAllEmployeesParams, limit: 20};
        }
        
        if ((qs.status !== undefined) && (qs.status !== null)){
            getAllEmployeesParams = {...getAllEmployeesParams, status: qs.status};
        }
        else{
            getAllEmployeesParams = {...getAllEmployeesParams, status: ""};
        }

        getAllEmployeesParams = this.getAllQueryFilters(getAllEmployeesParams);

        this.setState({getAllEmployeesParams});
        this.props.getAllEmployeesWithdrawOnceOrMore(getAllEmployeesParams);
    }


    getAllEmployeesCount = () => {
        const { getAllEmployeesParams } = this.state;
        const params = this.getAllQueryFilters(getAllEmployeesParams);
        this.props.getAllEmployeesCount(params);
    }


    activateEmployee = async (employee_id) => {
        const result = await DialogBox();
        if (result) {
            this.props.activateEmployee(employee_id);
        }
    }


    deactivateEmployee = async (employee_id) => {
        const result = await DialogBox({
            theme: "red",
        });
        if (result) {
            this.props.deactivateEmployee(employee_id);
        }
    }
    

    resendInvite = async (name, phoneNumber) => {
        const result = await DialogBox();
        if (result) {
            const resendInviteParams = {
                phoneNumber: phoneNumber,
            }
            this.props.resendInvite(resendInviteParams);
            toast("Resending invite to " + name);
        }
    }


    generateERA = async (userId, type) => {
        const result = await DialogBox();
        if (result) {
            const params = {
                userId,
                type,
            }
            this.props.generateERA(params);
            toast("Generating Reconciliation Account");
        }
    }
    

    componentDidMount() {
        this.getAllEmployees();
        this.getAllEmployeesCount();
        this.getAllEmployers();
        this.selectEmployerFilter();
        
        this.unsubscribe = store.subscribe(() => {
            if (this.props.allEmployersLoading === false){
                this.unsubscribe();
                const allEmployers = this.props.allEmployers.docs;
                let employerFilterOptions = [];
                if ((allEmployers !== undefined) && (allEmployers !== null)){
                    Object.keys(allEmployers).forEach(function(key) {
                        employerFilterOptions.push(
                            { value: allEmployers[key]._id, label: allEmployers[key].companyName }
                        );
                    });
                }
                this.setState({employerFilterOptions});
                this.selectEmployerFilter();
            }
        });
        this.setState({storeUnsubscribe: this.unsubscribe});
    }


    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.getAllEmployees();
            this.getAllEmployeesCount();
            this.selectEmployerFilter();
        }
        if (this.props.reloadPage === true) {
            setTimeout(()=>{
                this.props.resetReloadPage();
                this.props.history.replace(this.props.location.pathname + this.props.location.search);
            },1000);
        }
    }


    componentWillUnmount() {
        const storeUnsubscribe = this.state.storeUnsubscribe;
        if ((storeUnsubscribe !== "") && (storeUnsubscribe !== undefined) && (storeUnsubscribe !== null)){
            storeUnsubscribe();
        }
    }


    render() { 
        customizeSVG();
        const {userType: userRole} = this.props.user;
        const { allEmployeesCount, allEmployersLoading } = this.props;
        let { allEmployeesWithdrawOnceOrMore: data, allEmployeesWithdrawOnceOrMoreLoading: loading, downloadEmployeesWithdrawOnceOrMoreLoading } = this.props;
        const { getAllEmployeesParams, dataDownload, employerFilterOptions, employerFilter } = this.state;
        
        
        return ( 
            <div>

                <div>

                    <div className="lg:flex">
                        <div className="w-full lg:w-1/2">
                            <div className="page-title">
                                Employees
                            </div>
                        </div>
                        <div className="w-full lg:w-1/2 flex space-x-4 mt-2 lg:mt-0 lg:justify-end">
                            <div>
                                <CSVLink filename="All withdraw-once-or-more Employees.csv" data={dataDownload} headers={columns} ref={this.csvLinkEl} />
                                <div onClick={this.downloadData} data-tip data-for='download' className="w-9 h-9 flex box-border-only cursor-pointer">
                                    <FontAwesomeIcon icon="spinner" spin className={"m-auto " + (downloadEmployeesWithdrawOnceOrMoreLoading === true?"inline":"hidden")} />
                                    <Download className={"customizeSVG m-auto " + (downloadEmployeesWithdrawOnceOrMoreLoading === true?"hidden":"inline")} />
                                </div>
                                <ReactToolTip id='download' className='tooptip-size'>
                                    Download list as excel
                                </ReactToolTip>
                            </div>


                            <div>
                                <SearchFilter
                                    search={{
                                        name: "search",
                                        placeholder: "Keyword search",
                                    }}
                                    filters={[
                                        {
                                            title: "Status",
                                            name: "status",
                                            dataType: "radio",
                                            options: [
                                                {
                                                    display: "All",
                                                    payload: "",
                                                },
                                                {
                                                    display: "Enrolled",
                                                    payload: "active",
                                                },
                                                {
                                                    display: "Unenrolled",
                                                    payload: "pending",
                                                },
                                            ],
                                        },
                                        {
                                            title: "Disabled",
                                            name: "isActive",
                                            dataType: "radio",
                                            options: [
                                                {
                                                    display: "All",
                                                    payload: "",
                                                },
                                                {
                                                    display: "Active",
                                                    payload: true,
                                                },
                                                {
                                                    display: "Inactive",
                                                    payload: false,
                                                },
                                            ],
                                        },
                                        {
                                            title: "Registration Date",
                                            dataType: "date-range",
                                            options: {
                                                from: {
                                                    name: "startDate",
                                                    placeholder: "Start Date",
                                                },
                                                to: {
                                                    name: "endDate",
                                                    placeholder: "End Date",
                                                },
                                            },
                                        },
                                        {
                                            title: "Salary",
                                            dataType: "number-range",
                                            options: {
                                                from: {
                                                    name: "salaryFrom",
                                                    placeholder: "₦0.00",
                                                },
                                                to: {
                                                    name: "salaryTo",
                                                    placeholder: "₦0.00",
                                                },
                                            },
                                        },
                                    ]}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="mt-6">
                        <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-6">
                            <DashboardBoxes data={this.state.dashboardProps1} loading={this.props.allEmployeesCountLoading} result={(allEmployeesCount)?allEmployeesCount.totalEmployees:""} />
                            <DashboardBoxes data={this.state.dashboardProps2} loading={this.props.allEmployeesCountLoading} result={(allEmployeesCount)?allEmployeesCount.enrolledEmployees:""} />
                            <DashboardBoxes data={this.state.dashboardProps3} loading={this.props.allEmployeesCountLoading}
                                tooltip={<span>These are Employees that have Withdrawn once/more. <br />The number in bracket () are the enabled employees that have withdrawm Once/More</span>}
                                tooltipIndex="withdraw-once-more"
                                result={allEmployeesCount && <>
                                    <span>{allEmployeesCount.totalEmployeesWithdrawn}&nbsp;</span>
                                    <span className="text-green-500">({allEmployeesCount.totalEnabledEmployeesWithdrawn})</span>
                                </>}
                            />
                        </div>
                    </div>

                    <div className="w-full lg:flex mt-6 mb-4 sm:mb-0">
                        <div className="w-full lg:w-1/2 flex space-x-3">
                            <div>
                                {(allEmployersLoading === true) && 
                                    <div className="w-full px-3 py-2 border border-gray-200 rounded">
                                        <FontAwesomeIcon icon="spinner" spin className="mr-1.5" />
                                        Loading
                                    </div>
                                }
                                {(allEmployersLoading === false) && 
                                    <Select options={employerFilterOptions} placeholder="Search Employees Firm" value={employerFilter} onChange={this.employerFilterValue} />
                                }
                            </div>
                            <div className="lg:col-span-2 flex">

                                {(allEmployersLoading === false) && (employerFilter !== "") &&
                                    <div className="mx-auto sm:mx-0 my-auto flex space-x-6 text-sm">
                                        <div>
                                            {data.totalEmployeeCount} {pluralize("result", data.totalEmployeeCount)} found
                                        </div>
                                        <div onClick={this.clearEmployerFilterOptions} className="cursor-pointer text-red-500 border-b-2 border-transparent hover:border-red-500">
                                            <FontAwesomeIcon icon="times" className="mr-1" />
                                            Clear Filter
                                        </div>
                                    </div>
                                }

                            </div>
                        </div>

                        <div className="w-full lg:w-1/2 lg:flex lg:justify-end mt-1 lg:mt-0">

                            <div className="w-full lg:w-auto inline-block box-border-only overflow-auto">
                                <div className="h-10 flex">
                                <Link to="/users/employees" className="cursor-pointer page-nav">
                                    <div className="whitespace-nowrap">
                                        All Employees
                                    </div>
                                </Link>
                                <Link to="/users/employees?status=active" className="cursor-pointer page-nav">
                                    <div className="whitespace-nowrap">
                                        Enrolled Employees
                                    </div>
                                </Link>
                                <Link to="/users/employees?status=pending" className="cursor-pointer page-nav">
                                    <div className="whitespace-nowrap">
                                        Unenrolled Employees
                                    </div>
                                </Link>
                                <Link to="/users/employees/withdraw-once-or-more" className="cursor-pointer page-nav active">
                                    <div className="whitespace-nowrap">
                                        Active Employees
                                    </div>
                                </Link>
                                </div>
                            </div>

                        </div>
                    </div>


                    <div className="mt-3">
                        <div className="">

                            <div className="table-container">
                                <table className="table table-auto table-rounded table-border">
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Name</th>
                                            <th>Job Title</th>
                                            <th>Company Name</th>
                                            <th>Status</th>
                                            <th>Disabled</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        
                                        {(loading === true) && 
                                            <tr>
                                                <td colSpan="7">
                                                    <div className="table-info">
                                                        <FontAwesomeIcon icon="spinner" spin />
                                                        <div className="font-bold uppercase">
                                                            Loading
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        }

                                        {((loading === false) && (data) && (!data.withdrawals)) && 
                                            <tr>
                                                <td colSpan="7">
                                                    <div className="table-info">
                                                        <FontAwesomeIcon icon="unlink" />
                                                        <div className="font-bold uppercase">
                                                            An error occurred
                                                            <br />
                                                            Please try again later.
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        }
                                        
                                        {((loading === false) && (data) && (data.withdrawals) && (data.withdrawals.length <= 0)) && 
                                            <tr>
                                                <td colSpan="7">

                                                    {(data.page <= data.totalPages) &&
                                                        <div className="table-info">
                                                            <FontAwesomeIcon icon="list" />
                                                            <div className="font-bold uppercase">
                                                                No results found
                                                            </div>
                                                        </div>
                                                    }
                                                    
                                                    {(data.page > data.totalPages) &&
                                                        <div className="table-info">
                                                            <FontAwesomeIcon icon="exclamation-triangle" />
                                                            <div className="font-bold uppercase">
                                                                Invalid Page Number
                                                            </div>
                                                            <div className="mt-2">
                                                                <div onClick={() => this.gotoUrlQuery({"page": "1"})} className="btn btn-ep-blue btn-sm mx-auto">Goto Page 1</div>
                                                            </div>
                                                        </div>
                                                    }

                                                </td>
                                            </tr>
                                        }
                                        
                                        {((loading === false) && (data) && (data.withdrawals) && (data.withdrawals.length > 0)) && 
                                            data.withdrawals.map((employee, index) =>
                                                <tr key={index}>
                                                    <td>
                                                        {DateTimeFormat(employee.employee.createdAt)}
                                                    </td>
                                                    <td>
                                                        <Link to={"/users/employees/details/" + employee.employee._id} className="hover:underline">
                                                            {employee.employee.fullName}
                                                        </Link>
                                                    </td>
                                                    <td>
                                                        {employee.employee.jobTitle}
                                                    </td>
                                                    <td>
                                                        {(employee.employer) ? employee.employer.companyName : ""}
                                                    </td>
                                                    <td>
                                                        {employee.employee.status === "pending" &&
                                                            <div className="label label-yellow uppercase">
                                                                Pending
                                                            </div>
                                                        }
                                                        {employee.employee.status === "active" &&
                                                            <div className="label label-blue uppercase">
                                                                Enrolled
                                                            </div>
                                                        }
                                                    </td>
                                                    <td>
                                                        {(employee.employee.disabled === false) &&
                                                            <div className="label label-green uppercase">
                                                                Active
                                                            </div>
                                                        }
                                                        {(employee.employee.disabled === true) &&
                                                            <div className="label label-red uppercase">
                                                                Disabled
                                                            </div>
                                                        }
                                                    </td>
                                                    <td>
                                                        <div>
                                                            <Dropdown icon={dropdownTableIcon} menu={[
                                                                {type: "link", text: "View Details", link: `/users/employees/details/${employee.employee._id}`},
                                                                (userRole === "super-admin" || userRole === "customer-success-admin")?
                                                                    (employee.employee.disabled)?
                                                                        {type: "div", text: "Activate", click: () => this.activateEmployee(employee.employee._id)} :
                                                                        {type: "div", text: "Deactivate", click: () => this.deactivateEmployee(employee.employee._id)}
                                                                    :"",
                                                                (employee.employee.status === "pending")?
                                                                    {type: "div", text: "Resend Invite", click: () => this.resendInvite(employee.employee.fullName, employee.employee.phoneNumber) } : "",
                                                                (userRole === "super-admin")?
                                                                    {type: "div", text: "Generate EVA", click: () => this.generateERA(employee.employee._id, "employee") }
                                                                    :"",
                                                            ]} />
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                        
                                    </tbody>
                                </table>
                            </div>

                            {((loading === false) && (data) && (data.withdrawals) && (data.withdrawals.length > 0)) && 
                                <div>
                                    <Pagination data={data} url={this.props.location.search} limit={getAllEmployeesParams.limit} />
                                </div>
                            }

                        </div>
                    </div>


                </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => ({
    user: state.auth.userPersist.data,

    allEmployeesLoading: state.entities.employees.loading,
    allEmployees: state.entities.employees.allEmployees,
    downloadLoading: state.entities.employees.downloadLoading,
    downloadAllEmployees: state.entities.employees.downloadAllEmployees,

    allEmployerEmployeeLoading: state.entities.employers.employerEmployeeLoading,
    allEmployerEmployee: state.entities.employers.allEmployerEmployee,
    
    allEmployeesWithdrawOnceOrMoreLoading: state.entities.employees.employeesWithdrawOnceOrMoreLoading,
    allEmployeesWithdrawOnceOrMore: state.entities.employees.employeesWithdrawOnceOrMore,
    downloadEmployeesWithdrawOnceOrMoreLoading: state.entities.employees.downloadEmployeesWithdrawOnceOrMoreLoading,
    downloadEmployeesWithdrawOnceOrMore: state.entities.employees.downloadEmployeesWithdrawOnceOrMore,

    allEmployeesCountLoading: state.entities.employees.allEmployeesCountLoading,
    allEmployeesCount: state.entities.employees.allEmployeesCount,
    reloadPage: state.entities.employees.reloadPage,
    allEmployersLoading: state.entities.employers.loading,
    allEmployers: state.entities.employers.allEmployers,
});

const mapDispatchToProps = (dispatch) => ({
    getAllEmployees: (params) => dispatch(employees.getAllEmployees(params)),
    downloadGetAllEmployees: (params) => dispatch(employees.downloadGetAllEmployees(params)),
    getAllEmployeesCount: (params) => dispatch(employees.getAllEmployeesCount(params)),
    getAllEmployeesWithdrawOnceOrMore: (params) => dispatch(employees.getAllEmployeesWithdrawOnceOrMore(params)),
    downloadGetAllEmployeesWithdrawOnceOrMore: (params) => dispatch(employees.downloadGetAllEmployeesWithdrawOnceOrMore(params)),
    activateEmployee: (employee_id) => dispatch(employees.activateEmployee(employee_id)),
    deactivateEmployee: (employee_id) => dispatch(employees.deactivateEmployee(employee_id)),
    resendInvite: (params) => dispatch(employees.resendInvite(params)),
    generateERA: (params) => dispatch(employees.generateERA(params)),
    resetReloadPage: () => dispatch(employees.resetReloadPage()),
    getAllEmployers: (params) => dispatch(employers.getAllEmployers(params)),
    getAllEmployerEmployees: (employerId, params) => dispatch(employers.getAllEmployerEmployees(employerId, params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(List);
