import { useDispatch, useSelector } from "react-redux";
import { ButtonLoader } from "../common/form";
import FormDrawer from "../common/form-drawer--new";
import { ReactComponent as IconCircleQuestion } from "assets/images/icons/project-icons/circle-question.svg";
import {
  toggleUpdateBankDetails,
  updateBankDetails,
  getCreditRequests,
} from "store/entities/credit/action";

const UpdateBankConfirmation = ({ user, refetch }) => {
  const dispatch = useDispatch();
  // eslint-disable-next-line
  const {
    updateUserBankLoading: loading,
    selectedCreditItem,
    isUpdateUserBankOpen,
  } = useSelector((state) => state.entities.credit);

  const toggleFn = () => dispatch(toggleUpdateBankDetails());

  const onProceed = () => {
    const payload = {
      userId: selectedCreditItem?.userId,
    };

    // make request to approve loan
    dispatch(updateBankDetails(payload));

    // close modal
    toggleFn();
    // refetch loan items
    refetch();
    dispatch(getCreditRequests());
  };

  return (
    <FormDrawer
      display="center"
      size="sm"
      isOpen={isUpdateUserBankOpen}
      setIsOpen={() => {}}
    >
      <div className="p-5 max-w-sm">
        <div className="flex items-center justify-center flex-col pb-8">
          <div className="text-center">
            <IconCircleQuestion />
          </div>

          <h1 className="mt-5 mb-2 font-semibold">Update Bank Details</h1>

          <p className="mb-0 text-center">
            Are you sure you want to update this users bank details?
          </p>
        </div>

        <div className="flex items-center justify-center lg:flex-row pb-2">
          <button
            onClick={toggleFn}
            className="btn btn-md border border-ep-primary-500 text-ep-primary-500 focus:ring-0 w-full lg:w-2/4 mr-4"
            disabled={loading}
          >
            Cancel
          </button>

          <ButtonLoader
            loading={loading}
            onClick={onProceed}
            className="z-5 btn btn-md border bg-ep-primary text-white focus:ring-0 w-full lg:w-2/4"
          >
            {loading ? "Submitting..." : "Yes"}
          </ButtonLoader>
        </div>
      </div>
    </FormDrawer>
  );
};

export default UpdateBankConfirmation;
