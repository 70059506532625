import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Table from "components/__new/common/Table";
import processParams from "utils/processParams";
import useUrlQueryParams from "hooks/useUrlQueryParams";
import { TableContent } from "components/__new/common/TableContent";
// import SearchFilter from "components/common/filter";
import Download from "components/common/download";
import Pagination from "components/common/pagination";
import DialogBox from 'utils/dialogBox';
import { renderStatusColor } from "utils/statusColor";
import { ReactComponent as IconCircleQuestion } from "assets/images/icons/project-icons/circle-question.svg";
import Maybe from "components/__new/common/Maybe";
import DropdownNew from "utils/dropdown-new";
import RewardsDashboard from "components/__new/rewards/RewardsDashboard";
import RewardsTab from "components/__new/rewards/RewardsTab";
import NumberFormat from "utils/numberFormat";
import AssignActivityForm from "components/__new/rewards/AssignActivityForm";
import * as rewardsActions from "store/entities/rewards/action";
import CenterModal from "components/misc/CenterModal";


const RewardsPointsHistoryList = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const {urlQueryParams} = useUrlQueryParams();
    const { getPointsHistoryLoading, getPointsHistoryData, downloadPointsHistoryLoading, downloadPointsHistoryData, rejectGiftPointLoading } = useSelector((s) => s.entities.rewards);
    

    const downloadRef = useRef();
    const [isOpenAssignPoint, setIsOpenAssignPoint] = useState(false);
    const [reason, setReason] = useState("");
    const [rejectionOpen, setRejectionOpen] = useState(false);
    const [selected, setSelected] = useState(null)
    const [listParams, setListParams] = useState({
        page: 1,
        limit: 20,
    });


    const getRewardPoints = () => {
        let params = {...listParams};

        params = processParams(urlQueryParams, params, "page", "number", 1, "page");
        params = processParams(urlQueryParams, params, "limit", "number", 20, "limit");
        // params = processParams(urlQueryParams, params, "search", "string", "", "keyword");
        // params = processParams(urlQueryParams, params, "status", "string", "", "status");
        // params = processParams(urlQueryParams, params, "from", "string", "", "from");
        // params = processParams(urlQueryParams, params, "to", "string", "", "to");
        
        setListParams(params);
        dispatch(rewardsActions.getAllGiftPointsHistory(params));
    }


    const transformDownloadResponse = (rewards) => {
        const data = rewards?.map((reward) => {
            return {
                ...reward,
                isActive: reward.isActive ? "true":"false",
            }
        });
        return data;
    }

    const assignPoints = () => {
        setIsOpenAssignPoint(true);
    }

    const onApprove = async (rewardPoint) => {
        const result = await DialogBox({
            icon: <IconCircleQuestion className="w-16 h-16" />,
			title: "Approve This Gift Point",
			content: "Are you sure you want to approve this gift point?",
			buttonYes: "Yes",
		});
        if (result) {
            dispatch(rewardsActions.approveGiftPoint(rewardPoint._id)).then(() => {
                getRewardPoints();
            })
        }
    }


    useEffect(() => {
        getRewardPoints();
        // eslint-disable-next-line
    }, [location]);


    return (
        <div>

            <AssignActivityForm
                isOpen={isOpenAssignPoint}
                setIsOpen={setIsOpenAssignPoint}
            />
            
            <RejectionModal 
                control={rejectionOpen}
                onClose={() => setRejectionOpen(false)}
                reason={reason}
                setReason={setReason}
                loading={rejectGiftPointLoading}
                onProceed={() => dispatch(rewardsActions.rejectGiftPoint({id: selected?._id, reason})).then(() => {
                    setRejectionOpen(false);
                    getRewardPoints();
                })}
            />

            <div className="lg:flex">
                <div className="w-full lg:w-1/2">
                    <div className="page-title capitalize">
                        Rewards
                    </div>
                </div>
                <div className="w-full lg:w-1/2 flex space-x-4 mt-2 lg:mt-0 lg:justify-end">
                    <div>
                        <Download
                            tooltip="Download points history"
                            filename="Points History.csv"
                            format="csv"
                            columns={[
                                { label: "User", key: "user" },
                                { label: "Email", key: "email" },
                                { label: "Points Awarded", key: "pointsAwarded" },
                                { label: "Reason", key: "reason" },
                                { label: "Status", key: "status" },
                            ]}
                            ref={downloadRef}
                            click={() => {
                                dispatch(rewardsActions.downloadPointsHistory({...listParams, page: 1, limit: 2000, download: true})).then(() => {
                                    downloadRef.current.processDownload();
                                });
                            }}
                            loading={downloadPointsHistoryLoading}
                            response={transformDownloadResponse(downloadPointsHistoryData?.data?.history)}
                        />
                    </div>
                    {/* 
                    <div> 
                        <SearchFilter
                            search={{
                                name: "keyword",
                                placeholder: "Keyword search",
                            }}
                            filters={[
                                {
                                    title: "Date Range",
                                    dataType: "date-range",
                                    options: {
                                        from: {
                                            name: "startDate",
                                            placeholder: "Start Date",
                                        },
                                        to: {
                                            name: "endDate",
                                            placeholder: "End Date",
                                        },
                                    },
                                },
                                {
                                    title: "Usage Range",
                                    name: "usage-range",
                                    dataType: "select",
                                    options: [
                                        {
                                            display: "1111",
                                            payload: "1111",
                                        },
                                        {
                                            display: "2222",
                                            payload: "2222",
                                        },
                                        {
                                            display: "3333",
                                            payload: "3333",
                                        },
                                    ],
                                },
                                {
                                    title: "Point Range",
                                    name: "point-range",
                                    dataType: "select",
                                    options: [
                                        {
                                            display: "1111",
                                            payload: "1111",
                                        },
                                        {
                                            display: "2222",
                                            payload: "2222",
                                        },
                                        {
                                            display: "3333",
                                            payload: "3333",
                                        },
                                    ],
                                },
                                {
                                    title: "Rewarder",
                                    name: "rewarder",
                                    dataType: "text",
                                    placeholder: "Enter the name of rewarder",
                                },
                            ]}
                        />
                    </div>
                    */}
                </div>
            </div>


            <RewardsDashboard />


            <RewardsTab />


            <div className="mt-6 lg:flex lg:items-center">
                <div className="w-full lg:w-1/2">
                    <div className="page-title-mini">
                        Gift Points History Data
                    </div>
                </div>
                <div className="w-full lg:w-1/2 flex space-x-4 mt-2 lg:mt-0 lg:justify-end">
                    <button type="button" onClick={assignPoints} className="btn btn-md btn-white">
                        Assign Points
                    </button>
                </div>
            </div>


            <div className="mt-6">
                <Table>
                    <Table.Head>
                        <th>User</th>
                        <th>Email</th>
                        <th>No. of points awarded</th>
                        <th>Reason for points award</th>
                        <th>Approval</th>
                        <th>Actions</th>
                    </Table.Head>
                    <Table.Body>

                        <TableContent
                            loading={getPointsHistoryLoading}
                            loadingText="Loading points history"
                            data={getPointsHistoryData?.data?.history}
                            total={getPointsHistoryData?.data?.totalDocs}
                            colspan={6}
                        />
                        
                        <Maybe condition={getPointsHistoryLoading === false && getPointsHistoryData?.data?.history?.length > 0}>
                            {getPointsHistoryData?.data?.history?.map((pointHistory, index) =>
                                <tr key={index}>
                                    <td>
                                        {pointHistory?.user?.firstName ?? "-"} {pointHistory?.user?.lastName ?? "-"}
                                    </td>
                                    <td>
                                        {pointHistory?.user?.email ?? "-"}
                                    </td>
                                    <td>
                                        {NumberFormat(pointHistory?.pointsGifted) ?? "-"}
                                    </td>
                                    <td>
                                        {pointHistory?.reason ?? "-"}
                                    </td>
                                    <td>
                                        <div className={"label uppercase " + renderStatusColor(pointHistory?.status === "rejected" ? "failed" : pointHistory?.status)}>
                                            {pointHistory?.status}
                                        </div>
                                    </td>
                                    <td>
                                        <DropdownNew>
                                            <></>
                                            <div>
                                                <Maybe condition={pointHistory?.status !== "approved"}>
                                                    <div onClick={() => onApprove(pointHistory)} className="dropdown-item">Approve</div>
                                                </Maybe>
                                                <Maybe condition={pointHistory?.status !== "rejected"}>
                                                    <div onClick={() => {
                                                        setSelected(pointHistory);
                                                        setRejectionOpen(true);
                                                    }} className="dropdown-item">Reject</div>
                                                </Maybe>
                                            </div>
                                        </DropdownNew>
                                    </td>
                                </tr>
                            )}
                        </Maybe>

                    </Table.Body>
                </Table>

                <Maybe condition={getPointsHistoryLoading === false && getPointsHistoryData?.data?.history?.length > 0}>
                    <Pagination data={getPointsHistoryData?.data} limit={listParams.limit} />
                </Maybe>
            </div>


        </div>
    )
}

const RejectionModal = ({control, onClose, onProceed, loading, reason, setReason }) => {
	
	return (
		<CenterModal control={control} onClose={onClose}>
		<h1 className="lg:text-lg font-semibold">Reason For Rejection</h1>
		
		<div className="form-group mt-8">
			<label>
				Rejection Reasons
			</label>
			<textarea style={{ height: "120px", resize: "none" }} className="p-2 w-full border border-[#D1D5DB] rounded-md focus:ring-0 outline-none" value={reason} onChange={(e) => setReason(e.target.value)} />
        </div>
		
		<div className="flex items-center justify-center lg:flex-row lg:space-x-2">
			
			<button
				type="button"
				onClick={onClose}
				className="btn btn-md border border-ep-primary-500 text-ep-primary-500 mb-4 lg:mb-0 w-full lg:w-2/4 focus:ring-0"
				disabled={loading}>
				Cancel
			</button>
			
			<button
				disabled={!reason?.length || loading}
				type="button" 
				onClick={onProceed}
				className="btn btn-md border bg-red-500 text-white w-full lg:w-2/4 focus:ring-0">
				{loading ? "Submitting..." : "Reject"}
			</button>
		</div>
	</CenterModal>
	)
}


export default RewardsPointsHistoryList;