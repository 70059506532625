export const GET_MOBILE_SETTINGS = "GET_MOBILE_SETTINGS";
export const GET_MOBILE_SETTINGS_START = "GET_MOBILE_SETTINGS_START";
export const GET_MOBILE_SETTINGS_FAILED = "GET_MOBILE_SETTINGS_FAILED";

export const UPDATE_MOBILE_SETTINGS = "UPDATE_MOBILE_SETTINGS";
export const UPDATE_MOBILE_SETTINGS_START = "UPDATE_MOBILE_SETTINGS_START";
export const UPDATE_MOBILE_SETTINGS_FAILED = "UPDATE_MOBILE_SETTINGS_FAILED";

export const GET_FEES_SETTINGS = "GET_FEES_SETTINGS";
export const GET_FEES_SETTINGS_START = "GET_FEES_SETTINGS_START";
export const GET_FEES_SETTINGS_FAILED = "GET_FEES_SETTINGS_FAILED";

export const GET_GROUPED_FEES_SETTINGS = "GET_GROUPED_FEES_SETTINGS";
export const GET_GROUPED_FEES_SETTINGS_START = "GET_GROUPED_FEES_SETTINGS_START";
export const GET_GROUPED_FEES_SETTINGS_FAILED = "GET_GROUPED_FEES_SETTINGS_FAILED";

export const GET_FEE_SETTINGS = "GET_FEE_SETTINGS";
export const GET_FEE_SETTINGS_START = "GET_FEE_SETTINGS_START";
export const GET_FEE_SETTINGS_FAILED = "GET_FEE_SETTINGS_FAILED";

export const GET_GOAL_SAVINGS_SETTINGS_START = 'GET_GOAL_SAVINGS_SETTINGS_START'
export const GET_GOAL_SAVINGS_SETTINGS_DONE = 'GET_GOAL_SAVINGS_SETTINGS_DONE'
export const GET_GOAL_SAVINGS_SETTINGS_FAILED = 'GET_GOAL_SAVINGS_SETTINGS_FAILED'

export const GET_FLEX_SAVINGS_SETTINGS_START = 'GET_FLEX_SAVINGS_SETTINGS_START'
export const GET_FLEX_SAVINGS_SETTINGS_DONE = 'GET_FLEX_SAVINGS_SETTINGS_DONE'
export const GET_FLEX_SAVINGS_SETTINGS_FAILED = 'GET_FLEX_SAVINGS_SETTINGS_FAILED'


export const UPDATE_GOAL_SAVINGS_SETTINGS_START = 'UPDATE_GOAL_SAVINGS_SETTINGS_START'
export const UPDATE_GOAL_SAVINGS_SETTINGS_DONE = 'UPDATE_GOAL_SAVINGS_SETTINGS_DONE'
export const UPDATE_GOAL_SAVINGS_SETTINGS_FAILED = 'UPDATE_GOAL_SAVINGS_SETTINGS_FAILED'


export const UPDATE_FLEX_SAVINGS_SETTINGS_START = 'UPDATE_FLEX_SAVINGS_SETTINGS_START'
export const UPDATE_FLEX_SAVINGS_SETTINGS_DONE = 'UPDATE_FLEX_SAVINGS_SETTINGS_DONE'
export const UPDATE_FLEX_SAVINGS_SETTINGS_FAILED = 'UPDATE_FLEX_SAVINGS_SETTINGS_FAILED'

export const CREATE_FEES_SETTINGS = "CREATE_FEES_SETTINGS";
export const CREATE_FEES_SETTINGS_START = "CREATE_FEES_SETTINGS_START";
export const CREATE_FEES_SETTINGS_FAILED = "CREATE_FEES_SETTINGS_FAILED";

export const UPDATE_FEES_SETTINGS = "UPDATE_FEES_SETTINGS";
export const UPDATE_FEES_SETTINGS_START = "UPDATE_FEES_SETTINGS_START";
export const UPDATE_FEES_SETTINGS_FAILED = "UPDATE_FEES_SETTINGS_FAILED";

export const DELETE_FEES_SETTINGS = "DELETE_FEES_SETTINGS";
export const DELETE_FEES_SETTINGS_START = "DELETE_FEES_SETTINGS_START";
export const DELETE_FEES_SETTINGS_FAILED = "DELETE_FEES_SETTINGS_FAILED";

export const SELECT_PLATFORM_FEE = "SELECT_PLATFORM_FEE";

export const GET_BILL_PAYMENT_SETTINGS = "GET_BILL_PAYMENT_SETTINGS";
export const GET_BILL_PAYMENT_SETTINGS_START = "GET_BILL_PAYMENT_SETTINGS_START";
export const GET_BILL_PAYMENT_SETTINGS_FAILED = "GET_BILL_PAYMENT_SETTINGS_FAILED";

export const CREATE_BILL_PAYMENT_SETTINGS = "CREATE_BILL_PAYMENT_SETTINGS";
export const CREATE_BILL_PAYMENT_SETTINGS_START = "CREATE_BILL_PAYMENT_SETTINGS_START";
export const CREATE_BILL_PAYMENT_SETTINGS_FAILED = "CREATE_BILL_PAYMENT_SETTINGS_FAILED";

export const UPDATE_BILL_PAYMENT_SETTINGS = "UPDATE_BILL_PAYMENT_SETTINGS";
export const UPDATE_BILL_PAYMENT_SETTINGS_START = "UPDATE_BILL_PAYMENT_SETTINGS_START";
export const UPDATE_BILL_PAYMENT_SETTINGS_FAILED = "UPDATE_BILL_PAYMENT_SETTINGS_FAILED";

export const DELETE_BILL_PAYMENT_SETTINGS = "DELETE_BILL_PAYMENT_SETTINGS";
export const DELETE_BILL_PAYMENT_SETTINGS_START = "DELETE_BILL_PAYMENT_SETTINGS_START";
export const DELETE_BILL_PAYMENT_SETTINGS_FAILED = "DELETE_BILL_PAYMENT_SETTINGS_FAILED";
export const BILL_PAYMENT_SETTINGS_RESET = "BILL_PAYMENT_SETTINGS_RESET";

export const GET_YIELD_SETTINGS = "GET_YIELD_SETTINGS";
export const GET_YIELD_SETTINGS_START = "GET_YIELD_SETTINGS_START";
export const GET_YIELD_SETTINGS_FAILED = "GET_YIELD_SETTINGS_FAILED";

export const UPDATE_YIELD_SETTINGS = "UPDATE_YIELD_SETTINGS";
export const UPDATE_YIELD_SETTINGS_START = "UPDATE_YIELD_SETTINGS_START";
export const UPDATE_YIELD_SETTINGS_FAILED = "UPDATE_YIELD_SETTINGS_FAILED";
export const UPDATE_YIELD_SETTINGS_RESET = "UPDATE_YIELD_SETTINGS_RESET";


export const RELOAD_PAGE_RESET = "RELOAD_PAGE_RESET";