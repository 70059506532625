import React, { Component } from 'react';
import queryString from 'query-string';
import DashboardBoxes from 'components/common/dashboard-boxes';
import Dropdown from "utils/dropdown";
import Pagination from 'components/common/pagination';
import { DateTimeFormat } from "utils/dateFormat";
import { customizeSVG } from 'utils/customizeSVG.jsx';
import { connect } from "react-redux";
import DialogBox from 'utils/dialogBox';
import { Link } from "react-router-dom";
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactComponent as IconEmployees } from "assets/images/icons/project-icons/employees.svg";
import { ReactComponent as IconNav } from "assets/images/icons/project-icons/Navicon-v.svg";
import iconPlus from "assets/images/icons/project-icons/Plus.svg";
import * as admin from "store/entities/admin/action";

const dropdownTableIcon = <IconNav className="w-8 h-8 p-1.5 relative top-1 cursor-pointer group-hover:bg-gray-200 rounded" />;
const AdminRoles = {
    "super-admin": "Super Admin",
    "general-admin": "General Admin",
    "financial-admin": "Financial Admin",
    "customer-success-admin": "Customer Success Admin",
}

class List extends Component {

    state = {
        getAllAdminParams: {
            page: 1,
            limit: 20,
        },
        dashboardProps1: {
            iconBg: "bg-blue-200",
            icon: <IconEmployees className="customizeSVG text-blue-600 m-auto w-7 h-7" />,
            titleText: "All Admins",
            mainText: "-"
        },
    }


    getAllAdmins = () => {
        const qs = queryString.parse(this.props.location.search);
        let getAllAdminParams = {...this.state.getAllAdminParams};
        
        if ((qs.page !== undefined) && (!isNaN(qs.page)) && (qs.page > 0)){
            getAllAdminParams = {...getAllAdminParams, page: qs.page};
        }
        else{
            getAllAdminParams = {...getAllAdminParams, page: 1};
        }
        
        if ((qs.limit !== undefined) && (!isNaN(qs.limit)) && (qs.limit > 0)){
            getAllAdminParams = {...getAllAdminParams, limit: qs.limit};
        }
        else{
            getAllAdminParams = {...getAllAdminParams, limit: 20};
        }
        
        this.setState({getAllAdminParams});
        this.props.getAllAdmins(getAllAdminParams);
    }


    enableAdmin = async (adminId) => {
        const result = await DialogBox();
        if (result) {
            const params = {
                adminId,
                type: "enable",
            }
            this.props.enableAdmin(params).then(() => {
                this.getAllAdmins();
            })
        }
    }


    disableAdmin = async (adminId) => {
        const result = await DialogBox({
            theme: "red",
        });
        if (result) {
            const params = {
                adminId,
                type: "disable",
            }
            this.props.disableAdmin(params).then(() => {
                this.getAllAdmins();
            })
        }
    }

    
    resendInvite = async (email) => {
        const result = await DialogBox();
        if (result) {
            const params = {
                email,
            }
            this.props.resendInvite(params);
            toast("Resending verification mail to " + email);
        }
    }


    componentDidMount() {
        this.getAllAdmins();
    }
    
    
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.getAllAdmins();
        }
    }

    
    render() { 
        customizeSVG();
        const {getAllAdminParams} = this.state;
        const {loading, allAdmins: data} = this.props;

        return ( 
            <div>
                
                <div className="lg:flex">
                    <div className="w-full lg:w-1/2">
                        <div className="page-title">
                            Administrators
                        </div>
                    </div>
                    <div className="w-full flex mt-2 lg:mt-0 lg:justify-end space-x-3 lg:w-1/2">
                        <Link to="/admin/add" className="btn btn-md btn-ep-blue">
                            <img src={iconPlus} alt="iconPlus" className="w-3 h-3 mr-2 inline" />
                            Add New Admin
                        </Link>
                    </div>
                </div>
                
                
                <div className="mt-6">
                    <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
                        <DashboardBoxes data={this.state.dashboardProps1} loading={loading} result={data.totalDocs} />
                        {/* <DashboardBoxes data={this.state.dashboardProps2} result="" /> */}
                    </div>
                </div>


                <div className="mt-6">
                    <div className="">

                        <div className="table-container">
                            <table className="table table-auto table-border">
                                <thead>
                                    <tr>
                                        <th>Date Created</th>
                                        <th>Name (Username)</th>
                                        <th>Email Address</th>
                                        <th>Role</th>
                                        <th>Status</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    
                                    {(this.props.loading === true) && 
                                        <tr>
                                            <td colSpan="6">
                                                <div className="table-info">
                                                    <FontAwesomeIcon icon="spinner" spin />
                                                    <div className="font-bold uppercase">
                                                        Loading
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    }

                                    {((this.props.loading === false) && (!data.docs)) && 
                                        <tr>
                                            <td colSpan="6">
                                                <div className="table-info">
                                                    <FontAwesomeIcon icon="unlink" />
                                                    <div className="font-bold uppercase">
                                                        An error occurred
                                                        <br />
                                                        Please try again later.
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    }
                                    
                                    {((this.props.loading === false) && (data.docs) && (data.docs.length <= 0)) && 
                                        <tr>
                                            <td colSpan="6">

                                                {(data.page <= data.totalPages) &&
                                                    <div className="table-info">
                                                        <FontAwesomeIcon icon="list" />
                                                        <div className="font-bold uppercase">
                                                            No results found
                                                        </div>
                                                    </div>
                                                }
                                                
                                                {(data.page > data.totalPages) &&
                                                    <div className="table-info">
                                                        <FontAwesomeIcon icon="exclamation-triangle" />
                                                        <div className="font-bold uppercase">
                                                            Invalid Page Number
                                                        </div>
                                                        <div className="mt-2">
                                                            <Link to="?page=1" className="btn btn-ep-blue btn-sm mx-auto">Goto Page 1</Link>
                                                        </div>
                                                    </div>
                                                }

                                            </td>
                                        </tr>
                                    }
                                    
                                    {((this.props.loading === false) && (data.docs) && (data.docs.length > 0)) && 
                                        data.docs.map((admin, index) =>
                                            <tr key={index}>
                                                <td>
                                                    {DateTimeFormat(admin.createdAt)}
                                                </td>
                                                <td>
                                                    <div>
                                                        {admin.firstName && admin.lastName &&
                                                            <div>
                                                                {admin.firstName} {admin.lastName}
                                                            </div>
                                                        }
                                                    </div>
                                                    <div>
                                                        {!admin.firstName && !admin.lastName && <div>-</div>}
                                                    </div>
                                                    <div>
                                                        ({admin.username})
                                                    </div>
                                                </td>
                                                <td>
                                                    {admin.email}
                                                </td>
                                                <td>
                                                    {AdminRoles[admin.adminType]}
                                                </td>
                                                <td>
                                                    {admin.disabled &&
                                                        <div className="label label-red uppercase">
                                                            Disabled
                                                        </div>
                                                    }

                                                    {!admin.disabled &&
                                                        <>
                                                            {admin.isActive &&
                                                                <div className="label label-green uppercase">
                                                                    Active
                                                                </div>
                                                            }
                                                            {!admin.isActive &&
                                                                <div className="label label-yellow uppercase">
                                                                    Inactive
                                                                </div>
                                                            }
                                                        </>
                                                    }
                                                </td>
                                                <td>
                                                    <div className="table-dropdown-align-top">
                                                        <Dropdown icon={dropdownTableIcon} menu={[
                                                            {type: "link", text:"Edit", link:`/admin/edit/${admin._id}`},
                                                            (!admin.isActive)?
                                                                {type: "div", text: "Resend Invite", click: () => this.resendInvite(admin.email) } : "",
                                                            (admin.isActive)?
                                                                (admin.disabled)?
                                                                    {type: "div", text: "Enable", click: () => this.enableAdmin(admin._id)} :
                                                                    {type: "div", text: "Disable", click: () => this.disableAdmin(admin._id)}
                                                                : "",
                                                        ]} />
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    }
                                    
                                </tbody>
                            </table>
                        </div>

                        {((this.props.loading === false) && (data.docs) && (data.docs.length > 0)) && 
                            <div>
                                <Pagination data={data} url={this.props.location.search} limit={getAllAdminParams.limit} />
                            </div>
                        }

                    </div>
                </div>

            </div>
        );
    }
}


const mapStateToProps = (state) => ({
    loading: state.entities.admin.loading,
    allAdmins: state.entities.admin.allAdmins,
    reloadPage: state.entities.admin.reloadPage,
});

const mapDispatchToProps = (dispatch) => ({
    getAllAdmins: (params) => dispatch(admin.getAllAdmins(params)),
    
    enableAdmin: (params) => dispatch(admin.updateAdminStatus(params)),
    disableAdmin: (params) => dispatch(admin.updateAdminStatus(params)),
    resendInvite: (params) => dispatch(admin.resendInvite(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(List);