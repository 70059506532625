import { useEffect, useState } from "react";

export function TabSelector(props) {
  const { selectOptions, onValueChange, allowValueChange } = props;
  const [items, setItems] = useState(selectOptions);

  const handleClick = (data, index) => {
    if (allowValueChange === undefined || allowValueChange === true){
      setItems(
        items.map((obj, i) => {
          if (index === i) {
            return { ...obj, selected: true };
          }
          return { ...obj, selected: false };
        })
      );
      onValueChange(data)
    }
  }

  const isActive = (item) => {
    if (item?.selected === true){
      return "active";
    }
    return "";
}

  useEffect(() => {
    setItems(selectOptions);
  }, [selectOptions])
  

  return (
    <div className="overflow-auto">
        
      <div className="flex border-b border-gray-200">
        {items.map((item, index) =>
          <div key={index} onClick={() => handleClick(item?.value, index)} className={"tab-selector flex-shrink-0 " + (isActive(item))}>
              {item?.label}
          </div>
        )}
      </div>

    </div>
  );
}

export default TabSelector;
