import * as actions from "./actionTypes";
import * as config from "config";

export function getAllPayroll(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetAllPayroll,
            method: "post",
            data: params,
            onStart: actions.GET_ALL_PAYROLL_START,
            onSuccess: actions.GET_ALL_PAYROLL,
            onError: actions.GET_ALL_PAYROLL_FAILED,
            // useLoginJwt: false,
        },
    }
}

export function downloadGetAllPayroll(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetAllPayroll,
            method: "post",
            data: params,
            onStart: actions.DOWNLOAD_GET_ALL_PAYROLL_START,
            onSuccess: actions.DOWNLOAD_GET_ALL_PAYROLL,
            onError: actions.DOWNLOAD_GET_ALL_PAYROLL_FAILED,
        },
    }
}
