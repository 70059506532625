import crypto from "crypto";
import CryptoJS, { AES, enc, lib, SHA256 } from "crypto-js";
import fs from "fs";

/*
import { CryptoJS } from 'crypto-js';

export const encrypt = (text) => {
  // const passphrase = '?!20eaRNiPaY21##';
  const passphrase = 'testpassword';
  return CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(text), passphrase).toString();
};


export const decrypt = (ciphertext) => {
  // const passphrase = '?!20eaRNiPaY21##';
  const passphrase = 'testpassword';
  const bytes = CryptoJS.AES.decrypt(ciphertext, passphrase);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
};
*/



export const encrypt = (text) => {
  return btoa(text);
};


export const decrypt = (text) => {
	try {
		return atob(text);
	} catch (e) {
		return false;
	}
};

export const encryptPayload = (data) => {
	const secret = process.env.REACT_APP_API_DECRYPT_KEY;
	const iv = lib.WordArray.random(16);
	const key = SHA256(secret);
	const value = enc.Utf8.parse(JSON.stringify(data));
	const encryptedHash = AES.encrypt(value, key, { iv }).toString();
	const encryptedValue = enc.Base64.parse(encryptedHash).toString(enc.HEX);
	return { iv: iv.toString(), encryptedValue };
};

export const decryptPayload = (payload) => {
	const secret = process.env.REACT_APP_API_DECRYPT_KEY;
	const { encryptedValue, iv } = payload;
	const IV = Buffer.from(iv, "hex");
	const encryptedPayload = Buffer.from(encryptedValue, "hex");

	const key = crypto.createHash("sha256").update(secret).digest();

	const decipher = crypto.createDecipheriv("aes-256-cbc", key, IV);
	let decryptedValue = decipher.update(encryptedPayload);

	decryptedValue = Buffer.concat([decryptedValue, decipher.final()]);
	if (decryptedValue.toString() === "undefined") return undefined;
	return JSON.parse(decryptedValue.toString());
};

const calculateMD5 = (data) => {
	return CryptoJS.MD5(data).toString();
};

// Encryption function
export const getFileBufferObject = (file) => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onload = (event) => {
			const fileData = new Uint8Array(event.target.result);

			// Calculate the MD5 hash of the file data
			const md5 = calculateMD5(fileData);

			// Prepare the file object
			const fileObject = {
				name: file.name,
				data: {
					type: "Buffer",
					data: Array.from(fileData), // Convert Uint8Array to regular array
				},
				size: file.size,
				encoding: "7bit",
				mimetype: file.type,
				md5: md5,
			};

			resolve(fileObject);
		};
		reader.onerror = (error) => {
			reject(error);
		};
		reader.readAsArrayBuffer(file);
	});
};

// Decryption function
export const decryptFile = (inputPath, outputPath) => {
	const key = process.env.REACT_APP_API_DECRYPT_KEY;
	const decipher = crypto.createDecipher("aes-256-cbc", key);
	const input = fs.createReadStream(inputPath);
	const output = fs.createWriteStream(outputPath);

	input.pipe(decipher).pipe(output);
};
