import * as actions from "./actionTypes";
import * as config from "config";

export function uploadFile(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiFileUpload,
            method: "post",
            data: params,
            onStart: actions.FILE_UPLOAD_START,
            onSuccess: actions.FILE_UPLOAD,
            onError: actions.FILE_UPLOAD_FAILED,
            baseUrl: config.apiMediaBaseUrl,
            // useLoginJwt: false,
        },
    }
}
