
export const renderStatusColor = (status) => { 
  if (status !== "" && status !== undefined){
    const statusObject = {
      "pending": "label-yellow",
      "review": "label-yellow",
      "success": "label-green",
      "successful": "label-green",
      "approved": "label-green",
      "active": "label-green",
      "failed": "label-red",
      "failed-credit": "label-red",
      "deactivate": "label-red",
      "deactivated": "label-red",
      "rejected": "label-red",
      "disable": "label-red",
      "disabled": "label-red",
      "completed": "label-blue",
      "onboarded": "label-green",
    }
    return statusObject[status?.toLocaleLowerCase()] || "";
  }
  return "";
};