import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from "react-redux";
import SearchFilter from 'components/common/filter';
import Download from 'components/common/download';
import nameToSentenceCase from "utils/nameToSentenceCase";
import { ReactComponent as IconTotalAmountDisbursed } from "assets/images/icons/project-icons/cash-hand.svg";
import { ReactComponent as IconTotalDisbursedLoans } from "assets/images/icons/project-icons/kiosk-sm.svg";
import { ReactComponent as IconTotalRepaymentAmount } from "assets/images/icons/project-icons/calculator.svg";
import { ReactComponent as IconCurrentExposure } from "assets/images/icons/project-icons/kiosk-red.svg";
import { ReactComponent as IconLoansCount } from "assets/images/icons/project-icons/dark-green-card.svg";
import useUrlQueryParams from 'hooks/useUrlQueryParams';
import processParams from 'utils/processParams';
import DashboardBoxes from 'components/common/dashboard-boxes';
import CurrencyFormat from 'utils/currencyFormat';
import TransactionsPageRouter from 'components/__new/common/TransactionsPageRouter';
import CreditTransactionsTable from 'components/__new/credit/credit-transactions/CreditTransactionsTable';
import CreditTransactionDetails from 'components/__new/credit/credit-transactions/CreditTransactionDetails';
import CreditRepaymentSchedule from 'components/__new/credit/credit-transactions/CreditRepaymentSchedule';
import { getCreditApplicationMetrics, getCreditApplicationRequests, getCreditViewerRequests } from 'store/entities/credit/action';
import { useLocation } from 'react-router-dom';

const CreditTransactionsList = () => {
    const { urlQueryParams } = useUrlQueryParams();
    const location = useLocation();
    const [isDetailsOpen, setIsDetailsOpen] = useState(false);
    const [scheduleOpen, setScheduleOpen] = useState(false);
    const dispatch = useDispatch();
    const [creditParams, setCreditParams] = useState({
        // page: 1,
        // limit: 10
    });
    const toggleDetails = (userId) => {
        setIsDetailsOpen(!isDetailsOpen);
    }
    const toggleSchedule = (user) => {
        dispatch(getCreditViewerRequests(user.userId));
        setScheduleOpen(!scheduleOpen);
    }
    const { allCustomersData, downloadAllCustomersLoading, downloadAllCustomersData } = useSelector(s => s.entities.employees);
    const {
        creditApplicationLoading,
        creditApplicationData,
        creditApplicationMetricsLoading,
        creditApplicationMetricsData,
        creditViewerLoading,
        creditViewerData
    } = useSelector(s => s.entities.credit);
  

    const creditMetrics = {
        totalAmountDisbursed: {
			iconBg: "bg-[#CDB8FF]",
			icon: (
				<IconTotalAmountDisbursed className="customizeSVG m-auto w-5 h-5" />
			),
			titleText: "Total Amount Disbursed",
			mainText: "-",
		},
        totalDisbursedLoans: {
			iconBg: "bg-ep-primary-100",
			icon: (
				<IconTotalDisbursedLoans className="customizeSVG m-auto w-5 h-5" />
			),
			titleText: "Total Disbursed Loans",
			mainText: "-",
		},
        currentExposure: {
            iconBg: "bg-[#FFD5D5]",
            icon: (
                <IconCurrentExposure className="customizeSVG m-auto w-5 h-5" />
            ),
            titleText: "Current Exposure",
            mainText: "-",
        },
		totalRepaymentAmount: {
			iconBg: "bg-[#BFFDCF]",
			icon: (
				<IconTotalRepaymentAmount className="customizeSVG m-auto w-5 h-5" />
			),
			titleText: "Total Repayment Amount",
			mainText: "-",
		},
		totalCompletedLoans: {
			iconBg: "bg-[#DEFFEE]",
			icon: (
				<IconLoansCount className="customizeSVG m-auto w-5 h-5" />
			),
			titleText: "No. of Completed Loans",
			mainText: "-",
		},
		totalRunningLoans: {
			iconBg: "bg-[#DEFFEE]",
			icon: (
				<IconLoansCount className="customizeSVG m-auto w-5 h-5" />
			),
			titleText: "No. of Running Loans",
			mainText: "-",
		},
    }

    const columns = [
        { label: "Date Joined", key: "createdAt" },
        { label: "Date Updated", key: "updatedAt" },
        { label: "Full Name", key: "fullName" },
        { label: "Email Address", key: "email" },
        { label: "Phone Number", key: "phone" },
        { label: "Points", key: "points" },
        { label: "IsCustomerEmployee", key: "isEmployee" },
        { label: "User Status", key: "userStatus" },
        { label: "Account Tier", key: "accountTier" },
        { label: "KYC Status", key: "kycStatus" },
        { label: "User Type", key: "userType" },
    ];
    const downloadRef = useRef();

    const fetchCreditTransactions = () => {
        let params = { ...creditParams };
        
        params = processParams(urlQueryParams, params, "page", "number", 1, "page");
        params = processParams(urlQueryParams, params, "limit", "number", 10, "limit");
		params = processParams(urlQueryParams, params, "status", "string", "", "status");
		params = processParams(urlQueryParams, params, "keyword", "string", "", "keyword");
		params = processParams(urlQueryParams, params, "dateFrom", "string", "", "dateFrom");
		params = processParams(urlQueryParams, params, "dateTo", "string", "", "dateTo");
		params = processParams(urlQueryParams, params, "kycStatus", "string", "", "kycStatus");
		params = processParams(urlQueryParams, params, "accountNumber", "string", "", "accountNumber");
		params = processParams(urlQueryParams, params, "pointFrom", "string", "", "pointFrom");
		params = processParams(urlQueryParams, params, "pointTo", "string", "", "pointTo");
		params = processParams(urlQueryParams, params, "userTier", "string", "", "userTier");
		params = processParams(urlQueryParams, params, "updatedFrom", "string", "", "updatedFrom");
		params = processParams(urlQueryParams, params, "updatedTo", "string", "", "updatedTo");
        
        setCreditParams(params);
    
        dispatch(getCreditApplicationRequests(params));
        if(params?.keyword?.length || params?.dateFrom?.length || 
            params?.dateTo?.length || params?.kycStatus?.length || 
            params?.status?.length || params?.accountNumber?.length || 
            (params?.pointFrom?.length && params?.pointTo?.length) ||
            params?.userTier?.length || (params?.updatedFrom?.length && params?.updatedTo?.length)) {
            // dispatch(searchAndFilterCustomers(params));
        } else {
            // dispatch(getAllCustomers(params));
        }
        
    }
    
    const transformDataDownload = (data) => {
        const newData = data?.docs?.map((row) => {
            const isActive = row?.isActive === true && row?.disabled === false;
            const isInactive = row?.isActive === false && row?.disabled === false;
            const isDeactivated = row?.isActive === false && row?.disabled === true;
            
            const userStatus = isActive ? "Active" : isInactive ? "Inactive" : isDeactivated ? "Deactivated" : ""
            
			const newRow = {
				...row,
				fullName: nameToSentenceCase(row.fullName) ?? "",
                isEmployee: row.employeeId && row.employeeId !== "" ? "true" : "false",
                userStatus,
                accountTier: `Tier ${row?.accountTier}`
			}
			return newRow;
		})
		return newData;
	}

    
    useEffect(() => {
        fetchCreditTransactions();
        dispatch(getCreditApplicationMetrics());
        // eslint-disable-next-line
    }, [location])
    

    return (
        <div>
            
            <CreditTransactionDetails
                control={isDetailsOpen}
                onClose={toggleDetails}
            />
            
            <CreditRepaymentSchedule
                control={scheduleOpen}
                onClose={toggleSchedule}
            />
            
            <div>
                <TransactionsPageRouter />

                <div className="mt-6 lg:flex">
                    <div className="w-full lg:w-1/2">
                        <div className="page-title capitalize">
                            Credit
                        </div>
                    </div>
                    <div className="w-full lg:w-1/2 flex space-x-4 mt-2 lg:mt-0 lg:justify-end">
                        <div>
                            <Download
                                tooltip="Download credit items"
                                format="csv"
                                filename="All Credit items.csv"
                                columns={columns}
                                ref={downloadRef}
                                click={() => {
                                    alert("Cannot download")
                                    // dispatch(downloadAllCustomers({page: 1, limit: 2000})).then(() => {
                                    //     downloadRef.current.processDownload();
                                    // });
                                }}
                                loading={downloadAllCustomersLoading}
                                // response={transformDataDownload(downloadAllCustomersData?.data)}
                            />
                        </div>
                        <div>
                            <SearchFilter
                                search={{
                                    name: "keyword",
                                    placeholder: "Keyword search",
                                }}
                                filters={[
                                    {
                                        title: "Disbursement Date",
                                        dataType: "date-range",
                                        options: {
                                            from: {
                                                name: "disbursementDateFrom",
                                                placeholder: "Start Date",
                                            },
                                            to: {
                                                name: "disbursementDateTo",

                                                placeholder: "End Date",
                                            },
                                        },
                                    },
                                    {
                                        title: "Status",
                                        dataType: "checkbox",
                                        options: [
                                            {
                                                display: "Running",
                                                payload: "running"
                                            },
                                            {
                                                display: "Completed",
                                                payload: "completed"
                                            },
                                            {
                                                display: "Overdue",
                                                payload: "overdue"
                                            }
                                        ]
                                    }
                                ]}
                            />
                        </div>
                    </div>
                </div>

                <div className="my-10">
                    <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-6">
                        <DashboardBoxes
                            data={creditMetrics.totalAmountDisbursed}
                            loading={false}
                            result={
                                CurrencyFormat(creditApplicationMetricsData?.totalAmountDisbursed, "no-kobo")
                            }
                        />
                        <DashboardBoxes
                            data={creditMetrics.totalDisbursedLoans}
                            loading={false}
                            result={
                                creditApplicationMetricsData?.totalDisbursedLoans
                            }
                        />
                        <DashboardBoxes
                            data={creditMetrics.currentExposure}
                            loading={false}
                            result={
                                CurrencyFormat("5000000", "no-kobo")
                            }
                        />
                        <DashboardBoxes
                            data={creditMetrics.totalRepaymentAmount}
                            loading={false}
                            result={
                                CurrencyFormat("15000000", "no-kobo")
                            }
                        />
                        <DashboardBoxes
                            data={creditMetrics.totalCompletedLoans}
                            loading={false}
                            result={
                                CurrencyFormat("1500", "no-kobo")
                            }
                        />
                        <DashboardBoxes
                            data={creditMetrics.totalRunningLoans}
                            loading={false}
                            result={
                                CurrencyFormat("500", "no-kobo")
                            }
                        />
                    </div>
				</div>

                <div className="w-full flex items-center">
                    <div className="font-medium lg:text-lg whitespace-nowrap">Credit History</div>
                </div>
                
                <CreditTransactionsTable 
                    refinedData={creditApplicationData}
                    creditParams={creditParams}
                    toggleDetails={toggleDetails}
                    toggleSchedule={toggleSchedule}
                    fetchCreditTransactions={fetchCreditTransactions}
                />
                
            </div>
        </div>
    )
    
    
}

export default CreditTransactionsList;