import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from "react-redux";
import SearchFilter from 'components/common/filter';
import Download from 'components/common/download';
import nameToSentenceCase from "utils/nameToSentenceCase";
import useUrlQueryParams from 'hooks/useUrlQueryParams';
import processParams from 'utils/processParams';
import { ReactComponent as IconEmployers } from "assets/images/icons/project-icons/employers.svg";
import SubAccountsTable from 'components/__new/subAccounts/SubAccountsTable';
import { downloadAllSubAccounts, getAllSubAccounts, getSubAccountMetrics } from 'store/entities/subAccounts/action';
import { useLocation } from 'react-router-dom';
import TableFilterTabsWithQuery from 'components/__new/common/TableFilterTabsWithQuery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DashboardBoxes from 'components/common/dashboard-boxes';
import SubAccountDetails from 'components/__new/subAccounts/SubAccountDetails';


const SubAccountsList = (props) => {
    const dispatch = useDispatch();
    const location = useLocation();

    const { urlQueryParams } = useUrlQueryParams();
    const [subAccountParams, setSubAccountParams] = useState({
        page: 1,
        limit: 10,
    });

    const [detailsOpen, setDetailsOpen] = useState(false);

    const {  allSubAccountsLoading, allSubAccountsData, subAccountMetricsLoading, subAccountsMetricsData, downloadSubAccountsLoading, downloadSubAccountsData } = useSelector(s => s.entities.subAccounts);

    const refinedData = {
        ...allSubAccountsData?.data,
        docs: allSubAccountsData?.data?.employers
    }

    const columns = [
        { label: "Date Joined", key: "createdAt" },
        { label: "Date Updated", key: "updatedAt" },
        { label: "Full Name", key: "fullName" },
        { label: "Email Address", key: "email" },
        { label: "Phone Number", key: "phone" },
        {label: "BVN Validated", key: "bvnValidated"},
        { label: "Points", key: "points" },
        { label: "IsCustomerEmployee", key: "isEmployee" },
        { label: "User Status", key: "userStatus" },
        { label: "Account Tier", key: "accountTier" },
        { label: "KYC Status", key: "kycStatus" },
        { label: "User Type", key: "userType" },
    ];
    const downloadRef = useRef();

    const fetchSubAccounts = () => {
        let params = { ...subAccountParams };
        
        params = processParams(urlQueryParams, params, "page", "number", 1, "page");
        params = processParams(urlQueryParams, params, "limit", "number", 10, "limit");
		params = processParams(urlQueryParams, params, "status", "string", "", "status");
		params = processParams(urlQueryParams, params, "keyword", "string", "", "keyword");
		params = processParams(urlQueryParams, params, "registeredFrom", "string", "", "registeredFrom");
		params = processParams(urlQueryParams, params, "registeredTo", "string", "", "registeredTo");
        
        setSubAccountParams(params);

        dispatch(getAllSubAccounts(params));
    }

    const paramsDataset = [
        {
            title: "All sub accounts",
            loading: allSubAccountsLoading,
            onClickPayload: {"status": ""},
            data: refinedData?.docs?.length,
            isActive: (!subAccountParams?.status || subAccountParams?.status === "")
        },
        {
            title: "Active sub accounts",
            loading: allSubAccountsLoading,
            onClickPayload: {"status": "approved"},
            data: refinedData?.docs?.length,
            isActive: subAccountParams?.status === "approved"
        },
        {
            title: "Inactive sub accounts",
            loading: allSubAccountsLoading,
            onClickPayload: {"status": "pending"},
            data: refinedData?.docs?.length,
            isActive: subAccountParams?.status === "pending"
        },
    ]

    const metrics = {
        dashboardProps1: {
			iconBg: "bg-blue-200",
			icon: (
				<IconEmployers className="customizeSVG text-blue-600 m-auto w-5 h-5" />
			),
			titleText: "Total Sub accounts",
			mainText: "-",
		},
		dashboardProps2: {
			iconBg: "bg-green-200",
			icon: (
				<FontAwesomeIcon
					icon="check-circle"
					className="customizeSVG text-green-600 m-auto w-5 h-5"
				/>
			),
			titleText: "Active Sub accounts",
			mainText: "-",
		},
		dashboardProps3: {
			iconBg: "bg-red-200",
			icon: (
				<FontAwesomeIcon
					icon="times-circle"
					className="customizeSVG text-red-600 m-auto w-5 h-5"
				/>
			),
			titleText: "Approved Employers",
			mainText: "-",
		},
		dashboardProps4: {
			iconBg: "bg-red-200",
			icon: (
				<FontAwesomeIcon
					icon="times-circle"
					className="customizeSVG text-red-600 m-auto w-5 h-5"
				/>
			),
			titleText: "Inactive Sub accounts",
			mainText: "-",
		},
		dashboardProps5: {
			iconBg: "bg-red-200",
			icon: (
				<FontAwesomeIcon
					icon="times-circle"
					className="customizeSVG text-red-600 m-auto w-5 h-5"
				/>
			),
			titleText: "Disabled Employers",
			mainText: "-",
		},
    }

	const transformDataDownload = (data) => {
        const newData = data?.map((row) => {
			const newRow = {
				...row,
				firstName: nameToSentenceCase(row.firstName) ?? "",
				lastName: nameToSentenceCase(row.lastName) ?? "",
			}
			return newRow;
		})
		return newData;
	}
    
    useEffect(() => {
        fetchSubAccounts();
        // eslint-disable-next-line
    }, [location])
    
    useEffect(() => {
        dispatch(getSubAccountMetrics())
        // eslint-disable-next-line
    }, [])
    

    return (
        <div>
            <div className="lg:flex mb-5">
                <div className="w-full lg:w-1/2">
                    <div className="page-title capitalize">
                        Sub Accounts
                    </div>
                </div>
                <div className="w-full lg:w-1/2 flex space-x-4 mt-2 lg:mt-0 lg:justify-end">
                    <div>
                        <Download
                            tooltip="Download Sub accounts"
                            format="csv"
                            filename="All Sub accounts.csv"
                            columns={columns}
                            ref={downloadRef}
                            click={() => {
                                dispatch(downloadAllSubAccounts({...subAccountParams, limit: 2000, download: true})).then(() => {
                                    downloadRef.current.processDownload();
                                });
                            }}
                            loading={downloadSubAccountsLoading}
                            response={transformDataDownload(downloadSubAccountsData?.data)}
                        />
                    </div>
                    <div>
                        <SearchFilter
                            search={{
                                name: "keyword",
                                placeholder: "Keyword search",
                            }}
                            filters={[
                                {
                                    title: "Status",
                                    name: "status",
                                    dataType: "radio",
                                    options: [
                                        {
                                            display: "All",
                                            payload: "",
                                        },
                                        {
                                            display: "Approved",
                                            payload: "approved",
                                        },
                                        {
                                            display: "Pending",
                                            payload: "pending",
                                        },
                                        {
                                            display: "Review",
                                            payload: "review",
                                        },
                                    ],
                                },
                                {
                                    title: "Active Status",
                                    name: "status",
                                    dataType: "radio",
                                    options: [
                                        {
                                            display: "All Statuses",
                                            payload: "",
                                        },
                                        {
                                            display: "Active",
                                            payload: "approved",
                                        },
                                        {
                                            display: "Inactive",
                                            payload: "pending",
                                        },
                                    ],
                                },
                                {
                                    title: "Registration Date",
                                    dataType: "date-range",
                                    options: {
                                        from: {
                                            name: "registeredFrom",
                                            placeholder: "Start Date",
                                        },
                                        to: {
                                            name: "registeredTo",
                                            placeholder: "End Date",
                                        },
                                    },
                                },
                            ]}
                        />
                    </div>
                </div>
            </div>

            <div className="my-6 w-full border">
                <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-6">
                    <DashboardBoxes
                        data={metrics?.dashboardProps1}
                        loading={subAccountMetricsLoading}
                        result={
                            subAccountsMetricsData?.totalSubBusinesses ?? "0"
                        }
                    />
                    <DashboardBoxes
                        data={metrics?.dashboardProps2}
                        loading={subAccountMetricsLoading}
                        result={
                            subAccountsMetricsData?.activeSubBusinesses ?? "0"
                        }
                    />
                    <DashboardBoxes
                        data={metrics?.dashboardProps4}
                        loading={subAccountMetricsLoading}
                        result={
                            subAccountsMetricsData?.inActiveSubBusinesses ?? "0"
                        }
                    />
                </div>
            </div>

            <div className="lg:flex">
                <div className="w-full lg:w-1/2 flex">
                    <div className="page-title-mini my-auto">
                        Sub Accounts Data
                    </div>
                </div>

                <TableFilterTabsWithQuery 
                    queryDataset={paramsDataset}
                    params={{...subAccountParams}}
                />
            </div>
            
            <SubAccountsTable
                isOpen={detailsOpen}
                toggleAccountDetails={() => setDetailsOpen(!detailsOpen)}
                refinedData={refinedData}
                fetchSubAccounts={fetchSubAccounts}
                subAccountParams={subAccountParams}
                allSubAccountsData={allSubAccountsData}
                allSubAccountsLoading={allSubAccountsLoading}
            />
            
            <SubAccountDetails 
                isOpen={detailsOpen}
                setIsOpen={setDetailsOpen}
            />
        </div>
    )
    
    
}

export default SubAccountsList;