import React from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import ContentLoading from '../common/ContentLoading';

const TableFilterTabs = ({usersParams, refinedData, allCustomersLoading}) => {
    const history = useHistory();
    const location = useLocation();
    
    const gotoUrlQuery = (params) => {
        const qs = queryString.parse(location.search);

        Object.keys(params).forEach((paramName) => {
            delete qs[paramName];
        });

        let qsToUrl = new URLSearchParams(qs).toString();
        qsToUrl = location.pathname + "?" + ((qsToUrl !== "")? qsToUrl + "&" : "");

        Object.keys(params).forEach((paramName) => {
            if (params[paramName] !== ""){
                qsToUrl += paramName + "=" + params[paramName] + "&";
            }
        });

        qsToUrl = qsToUrl.substring(0, qsToUrl.length - 1);
        history.push(qsToUrl);
    }
    
    
    return (
        <div className="w-full md:flex md:justify-end mt-6 mb-4 sm:mb-0 pb-5">
            <div className="xl:flex xl:justify-end mt-1 xl:mt-0">
                <div className="w-full xl:w-auto inline-block box-border-only overflow-auto">
                    <div className="h-10 flex">
                        <div onClick={() => gotoUrlQuery({"kycStatus": "", "userTier": ""})} className={"cursor-pointer page-nav " + ((!usersParams?.kycStatus && !usersParams?.userTier) ? "active" : "")}>
                            <div className="whitespace-nowrap">
                                All
                                (<ContentLoading loading={allCustomersLoading} data={!usersParams?.kycStatus ? refinedData?.totalDocs : "-"} />)
                            </div>
                        </div>
                        <div onClick={() => gotoUrlQuery({"userTier": "0"})} className={"cursor-pointer page-nav " + ((usersParams.userTier === "0") ? "active" : "")}>
                            <div className="whitespace-nowrap">
                                Pending
                                (<ContentLoading
                                    loading={allCustomersLoading}
                                    data={usersParams?.userTier === "0" ? refinedData?.totalDocs : "-"}
                                />)
                            </div>
                        </div>
                        <div onClick={() => gotoUrlQuery({"kycStatus": "", "userTier": "1"})} className={"cursor-pointer page-nav " + ((usersParams.userTier === "1") ? "active" : "")}>
                            <div className="whitespace-nowrap">
                                Tier 1
                                (<ContentLoading
                                    loading={allCustomersLoading}
                                    data={usersParams?.userTier === "1" ? refinedData?.totalDocs : "-"}
                                />)
                            </div>
                        </div>
                        <div onClick={() => gotoUrlQuery({"kycStatus": "", "userTier": "3"})} className={"cursor-pointer page-nav " + ((usersParams.userTier === "3") ? "active" : "")}>
                            <div className="whitespace-nowrap">
                                Tier 3
                                (<ContentLoading
                                    loading={allCustomersLoading}
                                    data={usersParams?.userTier === "3" ? refinedData?.totalDocs : "-"}
                                />)
                            </div>
                        </div>
                        <div onClick={() => gotoUrlQuery({"kycStatus": "rejected", "userTier": ""})} className={"cursor-pointer page-nav " + ((usersParams.kycStatus === "rejected") ? "active" : "")}>
                            <div className="whitespace-nowrap">
                                Rejected
                                (<ContentLoading
                                    loading={allCustomersLoading}
                                    data={usersParams?.kycStatus === "rejected" ? refinedData?.totalDocs : "-"}
                                />)
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TableFilterTabs