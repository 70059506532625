import FormDrawer from "components/__new/common/form-drawer";
import React, { useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import pluralize from "utils/pluralize";
import useLoginInfo from "./useLoginInfo";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


const useIdleChecker = () => {
    const idleCountdown = 300;   //seconds
    const promptCountdown = 60;  //seconds
    const {logoutUser} = useLoginInfo();


    const [isIdle, setIsIdle] = useState(false);
    const [activityCounter, setActivityCounter] = useState(0)
    const [timeRemaining, setTimeRemaining] = useState(0)
    const [isOpenPrompt, setIsOpenPrompt] = useState(false);


    const onIdle = () => {
        // console.log("Idle - logout now");
        setIsIdle(true);
        logoutUser();
    }
    
    const onActive = () => {
        // console.log("Active");
        setIsIdle(false);
    }
    
    const onAction = () => {
        setActivityCounter(activityCounter => activityCounter + 1)
    }
    
    const onPrompt = () => {
        // console.log("about to timeout => show modal");
        setIsOpenPrompt(true);
    }
    

    const idleTimer = useIdleTimer({
        onIdle,
        onActive,
        onAction,
        onPrompt,
        timeout: idleCountdown * 1000,
        promptBeforeIdle: promptCountdown * 1000,
        throttle: 500,
    })
    

    const stayLoggedIn = () => {
        // console.log("keep me logged in");
        setIsOpenPrompt(false);
        idleTimer.reset();
    }
    

    useEffect(() => {
        const interval = setInterval(() => {
            setTimeRemaining(Math.ceil(idleTimer.getRemainingTime() / 1000))
        }, 500)
    
        return () => {
            clearInterval(interval)
        }
    })


    return (
        <>
            <div className="hidden">
                <h1>Idle Check</h1>
                <br />
                <p>Current Status: {isIdle ? "idle" : "active"}</p>
                <p>Action Events: {activityCounter}</p>
                <p>{timeRemaining} seconds remaining</p>
            </div>

            <FormDrawer display="center" size="sm" isOpen={isOpenPrompt} setIsOpen={setIsOpenPrompt} backdropClickable={false}>
                <div className="p-6 max-w-sm text-center">

                    <div>
                        <FontAwesomeIcon icon="exclamation-triangle" className="text-red-300 text-6xl" />
                    </div>

                    <div className="mt-4 font-bold text-xl">
                        Login expiring...
                    </div>

                    <div className="mt-3 text-fade">
                        Your login session is about to expire.
                        <br />
                        You will be automatically logged out in&nbsp;
                        <span className="text-red-500 whitespace-nowrap">
                            {timeRemaining} {pluralize("second", timeRemaining, "s")}
                        </span>
                    </div>
                    
                    <div className="mt-6 flex space-x-4">
                        <button type="button" onClick={stayLoggedIn} className="btn btn-block btn-white">
                            Stay logged in
                        </button>
                        <button type="button" onClick={logoutUser} className="btn btn-block btn-ep-primary">
                            Logout
                        </button>
                    </div>
                </div>
            </FormDrawer>
        </>
    );
}


export default useIdleChecker;