
export const GET_REPORT_FILTERS_EMPLOYERS = "GET_REPORT_FILTERS_EMPLOYERS";
export const GET_REPORT_FILTERS_EMPLOYERS_START = "GET_REPORT_FILTERS_EMPLOYERS_START";
export const GET_REPORT_FILTERS_EMPLOYERS_FAILED = "GET_REPORT_FILTERS_EMPLOYERS_FAILED";

export const GET_REPORT_EMPLOYERS = "GET_REPORT_EMPLOYERS";
export const GET_REPORT_EMPLOYERS_START = "GET_REPORT_EMPLOYERS_START";
export const GET_REPORT_EMPLOYERS_FAILED = "GET_REPORT_EMPLOYERS_FAILED";

export const DOWNLOAD_REPORT_EMPLOYERS = "DOWNLOAD_REPORT_EMPLOYERS";
export const DOWNLOAD_REPORT_EMPLOYERS_START = "DOWNLOAD_REPORT_EMPLOYERS_START";
export const DOWNLOAD_REPORT_EMPLOYERS_FAILED = "DOWNLOAD_REPORT_EMPLOYERS_FAILED";

export const GET_REPORT_FILTERS_EMPLOYEES = "GET_REPORT_FILTERS_EMPLOYEES";
export const GET_REPORT_FILTERS_EMPLOYEES_START = "GET_REPORT_FILTERS_EMPLOYEES_START";
export const GET_REPORT_FILTERS_EMPLOYEES_FAILED = "GET_REPORT_FILTERS_EMPLOYEES_FAILED";

export const GET_REPORT_EMPLOYEES = "GET_REPORT_EMPLOYEES";
export const GET_REPORT_EMPLOYEES_START = "GET_REPORT_EMPLOYEES_START";
export const GET_REPORT_EMPLOYEES_FAILED = "GET_REPORT_EMPLOYEES_FAILED";

export const DOWNLOAD_REPORT_EMPLOYEES = "DOWNLOAD_REPORT_EMPLOYEES";
export const DOWNLOAD_REPORT_EMPLOYEES_START = "DOWNLOAD_REPORT_EMPLOYEES_START";
export const DOWNLOAD_REPORT_EMPLOYEES_FAILED = "DOWNLOAD_REPORT_EMPLOYEES_FAILED";

export const GET_REPORT_FILTERS_WITHDRAWALS = "GET_REPORT_FILTERS_WITHDRAWALS";
export const GET_REPORT_FILTERS_WITHDRAWALS_START = "GET_REPORT_FILTERS_WITHDRAWALS_START";
export const GET_REPORT_FILTERS_WITHDRAWALS_FAILED = "GET_REPORT_FILTERS_WITHDRAWALS_FAILED";

export const GET_REPORT_WITHDRAWALS = "GET_REPORT_WITHDRAWALS";
export const GET_REPORT_WITHDRAWALS_START = "GET_REPORT_WITHDRAWALS_START";
export const GET_REPORT_WITHDRAWALS_FAILED = "GET_REPORT_WITHDRAWALS_FAILED";

export const DOWNLOAD_REPORT_WITHDRAWALS = "DOWNLOAD_REPORT_WITHDRAWALS";
export const DOWNLOAD_REPORT_WITHDRAWALS_START = "DOWNLOAD_REPORT_WITHDRAWALS_START";
export const DOWNLOAD_REPORT_WITHDRAWALS_FAILED = "DOWNLOAD_REPORT_WITHDRAWALS_FAILED";
