import { useState } from "react";
import Maybe from "../Maybe";
import validate from "utils/validate";

export const Input = (props) => {
    const { type, label, placeholder, min, max, value, onChange, onBlur, required, multiple, accept, readonly, disabled, defaultProps, autoComplete, containerClass, inputClass, leftIcon, leftIconClass, leftIconClicked, rightIcon, rightIconClicked, rightIconClass, validationName, checkFormValidation, hideValidationMessage } = props;
    const [errors, setErrors] = useState({});

    const doValidation = (e) => {
        if (validationName !== undefined){
            const data = {[validationName]: e.target.value};
            const vErrors = validate(data);
            if (vErrors){
                setErrors(vErrors);
            }
            else{
                setErrors({});
            }
            checkFormValidation(undefined, validationName, e.target.value);
        }
    }

    const doOnBlur = (e) => {
        if (onBlur !== undefined){
            onBlur(e);
        }
    }

    return (
        <div className={`form-group ${containerClass || ""}`}>
            <label>
                {label || ""}
                <Maybe condition={required === true}>
                    <span className="form-input-required">*</span>
                </Maybe>

                <div className="relative">
                    <Maybe condition={leftIcon !== undefined}>
                        <div className={`${leftIconClass} w-max h-full flex justify-center items-center absolute top-0 left-0 text-gray-500 ${(leftIconClicked && "cursor-pointer")}`} onClick={leftIconClicked || null}>
                            {leftIcon}
                        </div>
                    </Maybe>

                    <input
                        type={type || "text"}
                        placeholder={placeholder || ""}
                        
                        value={value}
                        onChange={(e) => {onChange(e); doValidation(e)}}
                        onBlur={(e) => {doOnBlur(e); doValidation(e)}}
                        // onBlur={onBlur}
                        min={min}
                        max={max}
                        multiple={multiple || false}
                        accept={accept || "*"}
                        
                        readOnly={readonly || disabled}
                        autoComplete={autoComplete}

                        {...defaultProps}
                        className={`
                            form-input ${inputClass || ""}
                            ${((readonly || disabled) === true && "disabled")}
                            ${leftIcon ? "pl-10" : ""}
                            ${rightIcon ? "pr-10" : ""}
                        `}
                    />

                    <Maybe condition={rightIcon !== undefined}>
                        <div className={`${rightIconClass} w-max h-full flex justify-center items-center absolute top-0 right-0 text-gray-500 ${(rightIconClicked && "cursor-pointer")}`} onClick={rightIconClicked || null}>
                            {rightIcon}
                        </div>
                    </Maybe>
                </div>
            </label>

            {errors[validationName] !== undefined && !hideValidationMessage &&
                <div className="form-input-message">
                    {errors[validationName]}
                </div>
            }
        </div>
    )
}

export default Input
