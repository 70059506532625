import React, { Component } from 'react';
import store from "store/store";
import CurrencyFormat from "utils/currencyFormat";
import numberOrdinalSuffix from "utils/numberOrdinalSuffix";
import { Link } from "react-router-dom";
import { nameInitials } from 'utils/nameInitials.jsx';
import { DateTimeFormat } from "utils/dateFormat";
import { slug } from 'utils/slug.jsx';
import { customizeSVG } from 'utils/customizeSVG.jsx';
import { connect } from "react-redux";
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactComponent as IconUsers } from "assets/images/icons/project-icons/Users.svg";
import iconMail from "assets/images/icons/project-icons/Envelope.svg";
import iconPhone from "assets/images/icons/project-icons/Phone.svg";
import iconPane from "assets/images/icons/project-icons/Pane.svg";
import * as employers from "store/entities/employers/action";


class Details extends Component {
    state = {
        storeUnsubscribe1: "",
    }


    goBack = () => {
        this.props.history.goBack();
    }
    

    approveEmployer = (employer_id) => {
        this.props.approveEmployer(employer_id);
    }


    activateEmployer = (employer_id) => {
        this.props.activateEmployer(employer_id);
    }


    deactivateEmployer = (employer_id) => {
        this.props.deactivateEmployer(employer_id);
    }

    
    resendVerificationMail = (email) => {
        const resendVerificationMailParams = {
            email: email,
        }
        this.props.resendVerificationMail(resendVerificationMailParams);
        toast("Resending verification mail to " + email);
    }


    resendCompanyInfoPrompt = (email) => {
        const resendCompanyInfoPromptParams = {
            email: email,
        }
        this.props.resendCompanyInfoPrompt(resendCompanyInfoPromptParams);
        toast("Resending company info prompt mail to " + email);
    }

    
    slug = (text) => {
        return slug(text);
    }


    componentDidMount() {
        if ("id" in this.props.match.params){
            
            const {id, name} = this.props.match.params;
            this.props.getEmployer(id);

            this.unsubscribe1 = store.subscribe(() => {

                if ((this.props.getEmployerLoading === false) && (Object.keys(this.props.employer).length > 0)){
                    this.unsubscribe1();

                    const { employer } = this.props.employer;
                    // console.log(employer);
                    const actualSlug = this.slug(employer.firstName + "-" + employer.lastName);
                    
                    if (name !== actualSlug){
                        this.props.history.replace(`/employers/${employer._id}/${actualSlug}`);
                    }
                }
                
            });
            this.setState({storeUnsubscribe1: this.unsubscribe1});
            
        }
    }
    
    
    componentDidUpdate(prevProps) {
        if (this.props.reloadPage === true) {
            setTimeout(()=>{
                this.props.resetReloadPage();
                this.props.history.replace(this.props.location.pathname + this.props.location.search);
            },1000);
        }
    }

    
    componentWillUnmount(){
        const { storeUnsubscribe1 } = this.state;
        // console.log("storeUnsubscribe1", storeUnsubscribe1);
        if ((storeUnsubscribe1 !== "") && (storeUnsubscribe1 !== undefined) && (storeUnsubscribe1 !== null)){
            storeUnsubscribe1();
        }
    }
    
    
    render() { 
        customizeSVG();
        const allDetails = this.props.employer;
        const { employer, companyInfo} = allDetails;

        return ( 
            <div>

                <div className="xl:flex">
                    <div className="w-full xl:w-2/5">
                        <div className="page-title">
                            Employer Details
                        </div>
                    </div>
                    <div className="w-full flex flex-wrap mt-2 xl:mt-0 xl:justify-end xl:w-3/5">
                        {(this.props.getEmployerLoading === false) && (Object.keys(allDetails).length > 0) && 
                            <>
                                <div className="mr-2 mb-2">
                                    <Link to={`/employers/employees/${employer._id}`} className="btn btn-md btn-transparent-black">
                                        <FontAwesomeIcon icon="users" className="text-lg" />
                                        <span>
                                            View Employees
                                        </span>
                                    </Link>
                                </div>
                                <div className="mr-2 mb-2">
                                    <button type="button" className="btn btn-md btn-transparent-black" onClick={() => this.resendVerificationMail(employer.email)}>
                                        <FontAwesomeIcon icon="envelope" className="text-lg" />
                                        <span>
                                            Resend Invite
                                        </span>
                                    </button>
                                </div>
                                <div className="mr-2 mb-2">
                                    <button type="button" className="btn btn-md btn-transparent-black" onClick={() => this.resendCompanyInfoPrompt(employer.email)}>
                                        <FontAwesomeIcon icon="envelope" className="text-lg" />
                                        <span>
                                            Resend Company Info Prompmt
                                        </span>
                                    </button>
                                </div>
                                {(!employer.isActive) &&
                                    <div className="mr-2 mb-2">
                                        <button type="button" className="btn btn-md btn-transparent-black" onClick={() => this.approveEmployer(employer._id)}>
                                            <FontAwesomeIcon icon="check-circle" className="text-lg" />
                                            <span>
                                                Approve
                                            </span>
                                        </button>
                                    </div>
                                }
                                {(employer.disabled) &&
                                    <div className="mr-2 mb-2">
                                        <button type="button" className="btn btn-md btn-transparent-black" onClick={() => this.activateEmployer(employer._id)}>
                                            <FontAwesomeIcon icon="check-circle" className="text-lg" />
                                            <span>
                                                Activate
                                            </span>
                                        </button>
                                    </div>
                                }
                                {(!employer.disabled) &&
                                    <div className="mr-2 mb-2">
                                        <button type="button" className="btn btn-md btn-transparent-black" onClick={() => this.deactivateEmployer(employer._id)}>
                                            <FontAwesomeIcon icon="times-circle" className="text-lg" />
                                            <span>
                                                Deactivate
                                            </span>
                                        </button>
                                    </div>
                                }
                            </>
                        }
                        <div className="mr-2 mb-2">
                            <button type="button" className="btn btn-md btn-ep-blue" onClick={this.goBack}>
                                <FontAwesomeIcon icon="angle-left" className="text-lg" />
                                <span>
                                    Back
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
                
                <div className="mt-6">

                    {(this.props.getEmployerLoading === true) && 
                        <div className="table-info-full-page">
                            <div className="table-info">
                                <FontAwesomeIcon icon="spinner" spin />
                                <div className="font-bold uppercase">
                                    Loading
                                </div>
                            </div>
                        </div>
                    }
                
                    {(this.props.getEmployerLoading === false) && (Object.keys(allDetails).length === 0) && 
                        <div className="table-info-full-page">
                            <div className="table-info">
                                <FontAwesomeIcon icon="clipboard" />
                                <div className="font-bold uppercase">
                                    Employer not found
                                </div>
                            </div>
                        </div>
                    }

                    {(this.props.getEmployerLoading === false) && (Object.keys(allDetails).length > 0) && 
                        <div className="grid sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-6">

                            <div className="box">

                                <div className="font-bold text-gray-600">
                                    Personal Information
                                </div>

                                <div className="mt-8">

                                    <div className="flex">
                                        <div className="flex-shrink-0 w-12 h-12 rounded-xl mr-3 bg-gray-400 flex">
                                            <span className="m-auto font-bold text-white">
                                                {nameInitials(employer.firstName, employer.lastName)}
                                            </span>
                                        </div>
                                        <div className="flex">
                                            <span className="m-auto font-bold">
                                                {employer.firstName} {employer.lastName}
                                            </span>
                                        </div>
                                    </div>

                                    <div className="mt-6 space-y-6">

                                        <div className="flex">
                                            <div className="w-7 flex-shrink-0 h-full mt-0.5 mr-1 flex overflow-hidden">
                                                <img src={iconPane} alt="icon_pane" className="w-4 h-4 m-auto" />
                                            </div>
                                            <div className="my-auto text-sm text-ep-yellow break-all">
                                                <div className="text-gray-400">
                                                    Employer ID
                                                </div>
                                                {employer._id}
                                            </div>
                                        </div>

                                        <div className="flex">
                                            <div className="w-7 flex-shrink-0 h-full mt-0.5 mr-1 flex overflow-hidden">
                                                <img src={iconMail} alt="icon_mail" className="w-4 h-4 m-auto" />
                                            </div>
                                            <div className="my-auto text-sm">
                                                <div className="text-gray-400">
                                                    Email Address
                                                </div>
                                                <a href={`mailto:${employer.email}`} className="hover:underline">{employer.email}</a>
                                            </div>
                                        </div>

                                        <div className="flex">
                                            <div className="w-7 flex-shrink-0 h-full mt-0.5 mr-1 flex overflow-hidden">
                                                <img src={iconPhone} alt="icon_phone" className="w-4 h-4 m-auto" />
                                            </div>
                                            <div className="my-auto text-sm">
                                                <div className="text-gray-400">
                                                    Phone Number
                                                </div>
                                                <a href={`tel:${employer.phoneNumber}`} className="hover:underline">{employer.phoneNumber}</a>
                                            </div>
                                        </div>

                                        <div className="flex">
                                            <div className="w-7 flex-shrink-0 h-full mt-0.5 mr-1 flex overflow-hidden">
                                                <FontAwesomeIcon icon="briefcase" className="w-4 h-4 m-auto" />
                                            </div>
                                            <div className="my-auto text-sm"> 
                                                <div className="text-gray-400">
                                                    Job Title
                                                </div>
                                                {employer.jobTitle}
                                            </div>
                                        </div>

                                    </div>

                                </div>

                            </div>
                        
                            <div className="box">

                                <div className="font-bold text-gray-600">
                                    Company Information
                                </div>

                                <div className="mt-6">

                                    <div className="space-y-6">
                                        <div className="flex">
                                            <div className="w-7 flex-shrink-0 h-full mt-0.5 mr-1 flex overflow-hidden">
                                                <FontAwesomeIcon icon="building" className="w-4 h-4 m-auto" />
                                            </div>
                                            <div className="my-auto text-sm">   
                                                <div className="text-gray-400">
                                                    Company Name
                                                </div>
                                                {employer.companyName}
                                            </div>
                                        </div>

                                        <div className="flex">
                                            <div className="w-7 flex-shrink-0 h-full mt-0.5 mr-1 flex overflow-hidden">
                                                <IconUsers className="customizeSVG m-auto w-5 h-5" />
                                            </div>
                                            <div className="my-auto text-sm">   
                                                <div className="text-gray-400">
                                                    Company Size
                                                </div>
                                                {employer.companySize}
                                            </div>
                                        </div>

                                        <div className="flex">
                                            <div className="w-7 flex-shrink-0 h-full mt-0.5 mr-1 flex overflow-hidden">
                                                <FontAwesomeIcon icon="file-invoice" className="w-4 h-4 m-auto" />
                                            </div>
                                            <div className="my-auto text-sm">   
                                                <div className="text-gray-400">
                                                    Balance
                                                </div>
                                                {CurrencyFormat(employer.balance)}
                                            </div>
                                        </div>
                                        
                                        <div className="flex">
                                            <div className="w-7 flex-shrink-0 h-full mt-0.5 mr-1 flex overflow-hidden">
                                                <FontAwesomeIcon icon="percentage" className="w-4 h-4 m-auto" />
                                            </div>
                                            <div className="my-auto text-sm">
                                                <div className="text-gray-400">
                                                    Salary Withdrawal Limit
                                                </div>
                                                {employer.salaryWithdrawalLimit}%
                                            </div>
                                        </div>

                                    </div>

                                </div>

                            </div>

                            <div className="box">

                                <div className="font-bold text-gray-600">
                                    Other Information
                                </div>

                                <div className="mt-6">

                                    <div className="space-y-6">

                                        <div className="flex">
                                            <div className="w-7 flex-shrink-0 h-full mt-0.5 mr-1 flex overflow-hidden">
                                            <FontAwesomeIcon icon="calendar" className="w-4 h-4 m-auto" />
                                            </div>
                                            <div className="my-auto text-sm">   
                                                <div className="text-gray-400">
                                                    Date Registered
                                                </div>
                                                {DateTimeFormat(employer.createdAt)}
                                            </div>
                                        </div>

                                        <div className="flex">
                                            <div className="w-7 flex-shrink-0 h-full mt-0.5 mr-1 flex overflow-hidden">
                                                <FontAwesomeIcon icon="user-check" className="w-4 h-4 m-auto" />
                                            </div>
                                            <div className="my-auto">   
                                                <div className="text-gray-400 mb-1">
                                                    Approval Status
                                                </div>
                                                {employer.isActive &&
                                                    <div className="label label-green uppercase">
                                                        Active
                                                    </div>
                                                }
                                                {!employer.isActive &&
                                                    <div className="label label-yellow uppercase">
                                                        Pending
                                                    </div>
                                                }
                                            </div>
                                        </div>

                                        <div className="flex">
                                            <div className="w-7 flex-shrink-0 h-full mt-0.5 mr-1 flex overflow-hidden">
                                                <FontAwesomeIcon icon="user-slash" className="w-4 h-4 m-auto" />
                                            </div>
                                            <div className="my-auto text-sm"> 
                                                <div className="text-gray-400 mb-1">
                                                    Disabled Status
                                                </div>  
                                                {employer.disabled &&
                                                    <div className="label label-red uppercase">
                                                        Disabled
                                                    </div>
                                                }
                                                {!employer.disabled &&
                                                    <div className="label label-blue uppercase">
                                                        Enabled
                                                    </div>
                                                }
                                            </div>
                                        </div>

                                    </div>

                                </div>

                            </div>

                            <div className="box">

                                <div className="font-bold text-gray-600">
                                    Legal Information
                                </div>

                                <div className="mt-6">

                                    <div className="space-y-6">

                                        <div className="flex">
                                            <div className="w-7 flex-shrink-0 h-full mt-0.5 mr-1 flex overflow-hidden">
                                                <FontAwesomeIcon icon="user-check" className="w-4 h-4 m-auto" />
                                            </div>
                                            <div className="my-auto">   
                                                <div className="text-gray-400">
                                                    Payment Email
                                                </div>
                                                {companyInfo.invoiceEmail}
                                            </div>
                                        </div>

                                        <div className="flex">
                                            <div className="w-7 flex-shrink-0 h-full mt-0.5 mr-1 flex overflow-hidden">
                                                <FontAwesomeIcon icon="user-check" className="w-4 h-4 m-auto" />
                                            </div>
                                            <div className="my-auto">   
                                                <div className="text-gray-400">
                                                    RC Number
                                                </div>
                                                {companyInfo.rcNumber}
                                            </div>
                                        </div>

                                        <div className="flex">
                                            <div className="w-7 flex-shrink-0 h-full mt-0.5 mr-1 flex overflow-hidden">
                                                <FontAwesomeIcon icon="user-check" className="w-4 h-4 m-auto" />
                                            </div>
                                            <div className="my-auto">   
                                                <div className="text-gray-400">
                                                    TIN Number
                                                </div>
                                                {companyInfo.tinNumber}
                                            </div>
                                        </div>

                                        <div className="flex">
                                            <div className="w-7 flex-shrink-0 h-full mt-0.5 mr-1 flex overflow-hidden">
                                                <FontAwesomeIcon icon="user-check" className="w-4 h-4 m-auto" />
                                            </div>
                                            <div className="my-auto">   
                                                <div className="text-gray-400">
                                                    Registered Address
                                                </div>
                                                {companyInfo.address}
                                            </div>
                                        </div>

                                        <div className="flex">
                                            <div className="w-7 flex-shrink-0 h-full mt-0.5 mr-1 flex overflow-hidden">
                                                <FontAwesomeIcon icon="user-check" className="w-4 h-4 m-auto" />
                                            </div>
                                            <div className="my-auto">   
                                                <div className="text-gray-400">
                                                    Estimated Number of Employees
                                                </div>
                                                {companyInfo.estimatedEmployees}
                                            </div>
                                        </div>

                                        <div className="flex">
                                            <div className="w-7 flex-shrink-0 h-full mt-0.5 mr-1 flex overflow-hidden">
                                                <FontAwesomeIcon icon="user-check" className="w-4 h-4 m-auto" />
                                            </div>
                                            <div className="my-auto">   
                                                <div className="text-gray-400">
                                                    Estimated Salary
                                                </div>
                                                {CurrencyFormat(companyInfo.estimatedSalary)}
                                            </div>
                                        </div>

                                    </div>

                                </div>

                            </div>

                            <div className="box">

                                <div className="font-bold text-gray-600">
                                    Policy Information
                                </div>

                                <div className="mt-6">

                                    <div className="space-y-6">

                                        <div className="flex">
                                            <div className="w-7 flex-shrink-0 h-full mt-0.5 mr-1 flex overflow-hidden">
                                                <FontAwesomeIcon icon="user-check" className="w-4 h-4 m-auto" />
                                            </div>
                                            <div className="my-auto">   
                                                <div className="text-gray-400">
                                                    Salary Date
                                                </div>
                                                {numberOrdinalSuffix(companyInfo.payrollCutOff)} of every month
                                            </div>
                                        </div>

                                        <div className="flex">
                                            <div className="w-7 flex-shrink-0 h-full mt-0.5 mr-1 flex overflow-hidden">
                                                <FontAwesomeIcon icon="user-check" className="w-4 h-4 m-auto" />
                                            </div>
                                            <div className="my-auto">   
                                                <div className="text-gray-400">
                                                    Salary Withdrawal Percentage
                                                </div>
                                                {companyInfo.withdrawalPercent}%
                                            </div>
                                        </div>

                                        <div className="flex">
                                            <div className="w-7 flex-shrink-0 h-full mt-0.5 mr-1 flex overflow-hidden">
                                            <FontAwesomeIcon icon="calendar" className="w-4 h-4 m-auto" />
                                            </div>
                                            <div className="my-auto text-sm">   
                                                <div className="text-gray-400 mb-1">
                                                    Auto-Withdrawal
                                                </div>
                                                {companyInfo.autoWithdrawals &&
                                                    <div className="label label-green uppercase">
                                                        Enabled
                                                    </div>
                                                }
                                                {!companyInfo.autoWithdrawals &&
                                                    <div className="label label-red uppercase">
                                                        Disabled
                                                    </div>
                                                }
                                            </div>
                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>
                    }

                </div>

            </div>
        );
    }
}


const mapStateToProps = (state) => ({
    getEmployerLoading: state.entities.employers.getEmployerLoading,
    employer: state.entities.employers.getEmployer,
    reloadPage: state.entities.employers.reloadPage,
});

const mapDispatchToProps = (dispatch) => ({
    getEmployer: (employer_id) => dispatch(employers.getEmployer(employer_id)),
    approveEmployer: (employer_id) => dispatch(employers.approveEmployer(employer_id)),
    activateEmployer: (employer_id) => dispatch(employers.activateEmployer(employer_id)),
    deactivateEmployer: (employer_id) => dispatch(employers.deactivateEmployer(employer_id)),
    resendVerificationMail: (params) => dispatch(employers.resendVerificationMail(params)),
    resendCompanyInfoPrompt: (params) => dispatch(employers.resendCompanyInfoPrompt(params)),
    resetReloadPage: () => dispatch(employers.resetReloadPage()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Details);