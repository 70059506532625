import React, { useEffect, useState } from "react";
import store from "store/store";

export const useGetBanks = () => {
	const [banks, setBanks] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	const getBanks = async () => {
		setIsLoading(true);
		const endpoint = `${process.env.REACT_APP_CREDIT_BASE_URL}/accounts/transactions/bank-list`;
		const token = store.getState().auth.userPersist.data.token;
		const response = await fetch(endpoint, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		});
		const result = await response.json();
		if (!result?.error) {
			setBanks(result?.data || []);
		}
		setIsLoading(false);
	};

	useEffect(() => {
		getBanks();
	}, []);

	return { banks, isLoading };
};
