import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from "react-redux";
import queryString from 'query-string';
import { Link, NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SearchFilter from 'components/common/filter';
import Download from 'components/common/download';
import Dropdown from 'utils/dropdown';
import { ReactComponent as IconNav } from "assets/images/icons/project-icons/Navicon-v.svg";
import DateFormat from "utils/dateFormat";
import { approveCustomer, downloadAllCustomers, getAllCustomers, getSingleCustomer, searchAndFilterCustomers, selectCustomer, toggleCustomerStatus } from 'store/entities/employees/action';

import CustomerDetailsModal from 'components/__new/customers/CustomerDetailsModal';
import ApproveRejectModal from 'components/__new/customers/ApproveRejectModal';
import Pagination from 'components/common/pagination';
import DialogBox from 'utils/dialogBox';


const dropdownTableIcon = (
	<IconNav className="w-8 h-8 p-1.5 relative top-1 cursor-pointer group-hover:bg-gray-200 rounded" />
);

const AccountDeletionRequests = (props) => {
    const dispatch = useDispatch();
    const [isDetailsOpen, setIsDetailsOpen] = useState(false);
    const [isApproving, setIsApproving] = useState(null);
    const [isActionModalOpen, setIsActionModalOpen] = useState(false);
    const [reason, setReason] = useState("");
    const [usersParams, setUsersParams] = useState({
        page: 1,
        limit: 10,
    });
    
    const approveMode = isApproving === true;
    const toggleDetails = () => setIsDetailsOpen(!isDetailsOpen)
    const toggleActionModal = () => setIsActionModalOpen(!isActionModalOpen)
    const { singleCustomerLoading, singleCustomerData, allCustomersLoading, allCustomersData, selectedCustomer, toggleCustomerStatusLoading, downloadAllCustomersLoading, downloadAllCustomersData } = useSelector(s => s.entities.employees);

    const refinedData = {
        ...allCustomersData?.data,
        docs: allCustomersData?.data?.users
    }
    
    const columns = [
        { label: "Date Joined", key: "dateJoined" },
        { label: "Full Name", key: "fullName" },
        { label: "Email Address", key: "email" },
        { label: "Phone Number", key: "phone" },
        { label: "Points", key: "points" },
        { label: "KYC Status", key: "kycStatus" },
        { label: "User Type", key: "userType" },
    ];
    const downloadRef = useRef();
    
    // const isSuperAdmin = userRole === "super-admin"
    // const isCsAdmin = userRole === "customer-success-admin"

    const gotoUrlQuery = (params) => {
        const qs = queryString.parse(props.location.search);

        Object.keys(params).forEach((paramName) => {
            delete qs[paramName];
        });

        let qsToUrl = new URLSearchParams(qs).toString();
        qsToUrl = props.location.pathname + "?" + ((qsToUrl !== "")? qsToUrl + "&" : "");

        Object.keys(params).forEach((paramName) => {
            if (params[paramName] !== ""){
                qsToUrl += paramName + "=" + params[paramName] + "&";
            }
        });

        qsToUrl = qsToUrl.substring(0, qsToUrl.length - 1);
        props.history.push(qsToUrl);
    }
    
    const fetchCustomers = () => {
        const qs = queryString.parse(props.location.search);
        let params = usersParams;
        
        if ((qs.page !== undefined) && (!isNaN(qs.page)) && (qs.page > 0)){
            params = {...params, page: qs.page};
        }
        else{
            params = {...params, page: 1};
        }
        
        if ((qs.limit !== undefined) && (!isNaN(qs.limit)) && (qs.limit > 0)){
            params = {...params, limit: qs.limit};
        }
        else{
            params = {...params, limit: 10};
        }
        
		if (qs.keyword !== undefined && qs.keyword !== null) {
			params = { ...params, keyword: qs.keyword };
		} else {
			params = { ...params, keyword: "" };
		}
        
		if (qs.dateFrom !== undefined && qs.dateFrom !== null) {
			params = { ...params, dateFrom: qs.dateFrom };
		} else {
			params = { ...params, dateFrom: "" };
		}
        
		if (qs.dateTo !== undefined && qs.dateTo !== null) {
			params = { ...params, dateTo: qs.dateTo };
		} else {
			params = { ...params, dateTo: "" };
		}
        
		if (qs.kycStatus !== undefined && qs.kycStatus !== null) {
			params = { ...params, kycStatus: qs.kycStatus };
		} else {
			params = { ...params, kycStatus: "" };
		}
        // params = getAllQueryFilters(params);
        
        setUsersParams(params);
        
        if(params?.keyword?.length || params?.dateFrom?.length || params?.dateTo?.length || params?.kycStatus?.length) {
            dispatch(searchAndFilterCustomers(params));
        } else {
            dispatch(getAllCustomers(params));
        }
        
    }

    const clearState = () => {
        if(isDetailsOpen) {
            setIsDetailsOpen(false);
        }
        if(isActionModalOpen) {
            toggleActionModal();
        }
        
        setReason("");
        fetchCustomers();
    }
    
	const proceedToApprove = async () => {
        dispatch(approveCustomer({id: selectedCustomer?._id})).then(() => clearState())
	};
    
	const proceedToReject = async () => {
        dispatch(toggleCustomerStatus({
            id: selectedCustomer?._id,
            action: "rejected",
            reason
        })).then(() => clearState())
	};
    
    const onApprove = async () => {
    const result = await DialogBox({
        theme: "blue",
        content: "Are you sure you want to approve this customer?"
    });
    if (result) {
        proceedToApprove();
    }
	};

    useEffect(() => {
        fetchCustomers();
        // eslint-disable-next-line
    }, [props.location])
    

    return (
        <div>
            
            <CustomerDetailsModal
                control={isDetailsOpen}
                onClose={toggleDetails}
                loading={singleCustomerLoading}
                customer={singleCustomerData}
                setIsDetailsOpen={setIsDetailsOpen}
                onApprove={onApprove}
                onReject={() => {
                    toggleActionModal();
                    setIsApproving(false);
                }}
                approveCustomer={proceedToApprove}
                deactivateCustomer={proceedToReject}
            />
            
            <ApproveRejectModal
                onProceed={() => {
                    if(approveMode) {
                        proceedToApprove();
                    } else if(isApproving === false) {
                        proceedToReject();
                    }
                }}
                reason={reason}
                setReason={setReason}
                approveMode={approveMode}
                isApproving={isApproving}
                control={isActionModalOpen}
                onClose={toggleActionModal}
                loading={toggleCustomerStatusLoading}
            />
            
            <div>
                <div className="bg-white overflow-auto mb-7">
                    <div className="flex text-center space-x-0">
                        <NavLink
                            to="/users/employees"
                            className="tab px-10 md:px-20 flex-shrink-0">
                            Employees
                        </NavLink>
                        <NavLink
                            to="/users/customers/"
                            className="tab px-10 md:px-20 flex-shrink-0">
                            Customers
                        </NavLink>
                        {/* <NavLink
							to="/users/account-deletion/"
							className="tab px-10 md:px-20 flex-shrink-0">
							Customers (Account Deletion)
						</NavLink> */}
                    </div>
                </div>
                <div className="lg:flex">
                    <div className="w-full lg:w-1/2">
                        <div className="page-title capitalize">
                            Account Deletion Requests
                        </div>
                    </div>
                    <div className="w-full lg:w-1/2 flex space-x-4 mt-2 lg:mt-0 lg:justify-end">
                        <div>
                            <Download
                                tooltip="Download customers"
                                format="csv"
                                filename="All Customers.csv"
                                columns={columns}
                                ref={downloadRef}
                                click={() => {
                                    dispatch(downloadAllCustomers({page: 1, limit: 2000})).then(() => {
                                        downloadRef.current.processDownload();
                                    });
                                }}
                                loading={downloadAllCustomersLoading}
                                response={downloadAllCustomersData?.data}
                            />
                        </div>
                        <div>
                            <SearchFilter
                                search={{
                                    name: "keyword",
                                    placeholder: "Keyword search",
                                }}
                                filters={[
                                    {
                                        title: "KYC Status",
                                        name: "kycStatus",

                                        dataType: "checkbox",
                                        options: [
                                            {
                                                display: "All",
                                                payload: "",
                                            },
                                            {
                                                display: "Approved",
                                                payload: "approved",
                                            },
                                            {
                                                display: "Rejected",
                                                payload: "rejected",
                                            },
                                            {
                                                display: "Pending",
                                                payload: "pending",
                                            },
                                        ],
                                    },
                                    {
                                        title: "Points range",
                                        dataType: "number-range",
                                        options: {
                                            from: {
                                                name: "startPoint",
                                                placeholder: "points",
                                            },
                                            to: {
                                                name: "endPoint",
                                                placeholder: "points",
                                            },
                                        },
                                    },
                                    {
                                        title: "Date",
                                        dataType: "date-range",
                                        options: {
                                            from: {
                                                name: "dateFrom",
                                                placeholder: "Start Date",
                                            },
                                            to: {
                                                name: "dateTo",

                                                placeholder: "End Date",
                                            },
                                        },
                                    },
                                ]}
                            />
                        </div>
                    </div>
                </div>

                <div className="w-full xl:flex xl:justify-between mt-6 mb-4 sm:mb-0">
                    <div className="xl:flex xl:justify-end mt-1 xl:mt-0">
                        <div className="w-full xl:w-auto inline-block box-border-only overflow-auto">
                            <div className="h-10 flex">
                                <div onClick={() => gotoUrlQuery({"status": ""})} className={"cursor-pointer page-nav " + ((usersParams.status === "") ? "active" : "")}>
                                    <div className="whitespace-nowrap">
                                        All Customers (-)
                                    </div>
                                </div>
                                <div onClick={() => gotoUrlQuery({"status": "active"})} className={"cursor-pointer page-nav " + ((usersParams.status === "active") ? "active" : "")}>
                                    <div className="whitespace-nowrap">
                                        Active Customers (-)
                                    </div>
                                </div>
                                <div onClick={() => gotoUrlQuery({"status": "inactive"})} className={"cursor-pointer page-nav " + ((usersParams.status === "inactive") ? "active" : "")}>
                                    <div className="whitespace-nowrap">
                                        Deactivated Customers (-)
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="mt-2">
                    <div className="">

                        <div className="table-container">
                            <table className="table table-auto table-rounded table-border">
                                <thead>
                                    <tr>
                                        <th>Date Joined</th>
                                        <th>Name</th>
                                        <th>Email address</th>
                                        <th>Account number</th>
                                        <th>Phone number</th>
                                        <th>Points</th>
                                        <th>KYC Status</th>
                                        <th>Status</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    
                                    {(allCustomersLoading === true) && 
                                        <tr>
                                            <td colSpan="9">
                                                <div className="table-info">
                                                    <FontAwesomeIcon icon="spinner" spin />
                                                    <div className="font-bold uppercase">
                                                        Loading
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    }

                                    {((allCustomersLoading === false) && (!refinedData.docs)) && 
                                        <tr>
                                            <td colSpan="9">
                                                <div className="table-info">
                                                    <FontAwesomeIcon icon="unlink" />
                                                    <div className="font-bold uppercase">
                                                        An error occurred
                                                        <br />
                                                        Please try again later.
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    }
                                    
                                    {((allCustomersLoading === false) && (refinedData.docs) && (refinedData.docs.length <= 0)) && 
                                        <tr>
                                            <td colSpan="9">

                                                {(refinedData.page <= refinedData.totalPages) &&
                                                    <div className="table-info">
                                                        <FontAwesomeIcon icon="list" />
                                                        <div className="font-bold uppercase">
                                                            No results found
                                                        </div>
                                                    </div>
                                                }
                                                
                                                {(refinedData.page > refinedData.totalPages) &&
                                                    <div className="table-info">
                                                        <FontAwesomeIcon icon="exclamation-triangle" />
                                                        <div className="font-bold uppercase">
                                                            Invalid Page Number
                                                        </div>
                                                        <div className="mt-2">
                                                            <Link to="?page=1" className="btn btn-ep-blue btn-sm mx-auto">Goto Page 1</Link>
                                                        </div>
                                                    </div>
                                                }

                                            </td>
                                        </tr>
                                    }
                                    
                                    {((allCustomersLoading === false) && (refinedData.docs) && (refinedData.docs.length > 0)) && 
                                        refinedData.docs.map((user, index) => {
                                            
                                            const allowApproval = (user?.kycStatus === "pending" || user?.kycStatus === "rejected") && user?.adminVerify === false;

                                            return (
                                                <tr key={index}>
                                                    <td>
                                                        <div>
                                                            {DateFormat(user?.createdAt?.split("T")[0])}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="capitalize">
                                                            {user?.firstName} {user?.lastName}
                                                        </div>
                                                    </td>
                                                    <td>{user?.email || "-"}</td>
                                                    <td>{user?.accountNumber || "-"}</td>
                                                    <td>{user?.phoneNumber || "-"}</td>
                                                    <td>{user?.points || "-"}</td>
                                                    <td>
                                                        <div className="capitalize">
                                                            {user?.kycStatus}
                                                        </div>
                                                    </td>
                                                    <td>
                                                        {(() => {
                                                            if (user?.isActive === true){
                                                                return <div className="label label-green uppercase">Active</div>
                                                            }
                                                            else if (user?.isActive === false){
                                                                return <div className="label label-red uppercase">Inactive</div>
                                                            }
                                                        })()}
                                                    </td>
                                                    <td>
                                                        <div>
                                                            <Dropdown
                                                                icon={dropdownTableIcon}
                                                                menu={[
                                                                        {
                                                                            type: "div",
                                                                            text: "View Details",
                                                                            click: () => {
                                                                                dispatch(selectCustomer(user));
                                                                                dispatch(getSingleCustomer(user));
                                                                                toggleDetails();
                                                                            }
                                                                        },
                                                                        {
                                                                            type: "div",
                                                                            text: "View Transactions",
                                                                            click: () => {},
                                                                            // click: () => props.history.push(`/users/customers/298389823`),
                                                                        },
                                                                        (allowApproval ?
                                                                            {
                                                                                type: "div",
                                                                                text: "Approve User",
                                                                                click: () => {
                                                                                    dispatch(selectCustomer(user));
                                                                                    onApprove();
                                                                                },
                                                                            } :
                                                                            {
                                                                            type: "div",
                                                                            text: "Deactivate User",
                                                                            click: () => {
                                                                                setIsApproving(false);
                                                                                dispatch(selectCustomer(user));
                                                                                toggleActionModal();
                                                                            },
                                                                            }),
                                                                        // (noVirtualAccount ?
                                                                        //     {
                                                                        //     type: "div",
                                                                        //     text: "Generate EVA",
                                                                        //     click: () => generateVirtualAccount(user?._id)
                                                                        // }: "")

                                                                ]}
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                            }
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>

                        {((allCustomersLoading === false) && (refinedData.docs) && (refinedData.docs.length > 0)) && 
                            <div>
                                <Pagination data={refinedData} url={props.location.search} limit={usersParams.limit} />
                            </div>
                        }

                    </div>
                </div>


            </div>
        </div>
    )
    
    
}

export default AccountDeletionRequests;