import * as actions from "./actionTypes";
import * as config from "config";

export function getReportFiltersEmployer() {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetReportFiltersEmployer,
            method: "get",
            data: {},
            onStart: actions.GET_REPORT_FILTERS_EMPLOYERS_START,
            onSuccess: actions.GET_REPORT_FILTERS_EMPLOYERS,
            onError: actions.GET_REPORT_FILTERS_EMPLOYERS_FAILED,
            // useLoginJwt: false,
        },
    }
}

export function getReportsEmployer(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetReportEmployer + params,
            method: "get",
            data: {},
            onStart: actions.GET_REPORT_EMPLOYERS_START,
            onSuccess: actions.GET_REPORT_EMPLOYERS,
            onError: actions.GET_REPORT_EMPLOYERS_FAILED,
        },
    }
}

export function downloadReportsEmployer(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetReportEmployer + params,
            method: "get",
            data: {},
            onStart: actions.DOWNLOAD_REPORT_EMPLOYERS_START,
            onSuccess: actions.DOWNLOAD_REPORT_EMPLOYERS,
            onError: actions.DOWNLOAD_REPORT_EMPLOYERS_FAILED,
        },
    }
}

export function getReportFiltersEmployee() {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetReportFiltersEmployee,
            method: "get",
            data: {},
            onStart: actions.GET_REPORT_FILTERS_EMPLOYEES_START,
            onSuccess: actions.GET_REPORT_FILTERS_EMPLOYEES,
            onError: actions.GET_REPORT_FILTERS_EMPLOYEES_FAILED,
        },
    }
}

export function getReportsEmployee(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetReportEmployee + params,
            method: "get",
            data: {},
            onStart: actions.GET_REPORT_EMPLOYEES_START,
            onSuccess: actions.GET_REPORT_EMPLOYEES,
            onError: actions.GET_REPORT_EMPLOYEES_FAILED,
        },
    }
}

export function downloadReportsEmployee(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetReportEmployee + params,
            method: "get",
            data: {},
            onStart: actions.DOWNLOAD_REPORT_EMPLOYEES_START,
            onSuccess: actions.DOWNLOAD_REPORT_EMPLOYEES,
            onError: actions.DOWNLOAD_REPORT_EMPLOYEES_FAILED,
        },
    }
}

export function getReportFiltersWithdrawal() {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetReportFiltersWithdrawal,
            method: "get",
            data: {},
            onStart: actions.GET_REPORT_FILTERS_WITHDRAWALS_START,
            onSuccess: actions.GET_REPORT_FILTERS_WITHDRAWALS,
            onError: actions.GET_REPORT_FILTERS_WITHDRAWALS_FAILED,
        },
    }
}

export function getReportsWithdrawal(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetReportWithdrawal + params,
            method: "get",
            data: {},
            onStart: actions.GET_REPORT_WITHDRAWALS_START,
            onSuccess: actions.GET_REPORT_WITHDRAWALS,
            onError: actions.GET_REPORT_WITHDRAWALS_FAILED,
        },
    }
}

export function downloadReportsWithdrawal(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetReportWithdrawal + params,
            method: "get",
            data: {},
            onStart: actions.DOWNLOAD_REPORT_WITHDRAWALS_START,
            onSuccess: actions.DOWNLOAD_REPORT_WITHDRAWALS,
            onError: actions.DOWNLOAD_REPORT_WITHDRAWALS_FAILED,
        },
    }
}