import ObjectToQueryString from "utils/ObjectToQueryString";
import * as actions from "./actionTypes";
import * as config from "config";

export function getAllApprovals(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetAllApprovals + ObjectToQueryString(params),
            method: "get",
            data: {},
            onStart: actions.GET_ALL_APPROVALS_START,
            onSuccess: actions.GET_ALL_APPROVALS_DONE,
            onError: actions.GET_ALL_APPROVALS_FAILED,
        },
    }
}

export function getSingleApprovals(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetSingleApproval + ObjectToQueryString(params),
            method: "get",
            data: {},
            onStart: actions.GET_SINGLE_APPROVAL_START,
            onSuccess: actions.GET_SINGLE_APPROVAL_DONE,
            onError: actions.GET_SINGLE_APPROVAL_FAILED,
        },
    }
}

export function toggleApprovalStatus(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiToggleApprovalStatus + ObjectToQueryString(params),
            method: "get",
            data: {},
            onStart: actions.TOGGLE_APPROVAL_STATUS_START,
            onSuccess: actions.TOGGLE_APPROVAL_STATUS_DONE,
            onError: actions.TOGGLE_APPROVAL_STATUS_FAILED,
        },
    }
}

export function selectApprovalItem(payload) {
    return {
        type: actions.SELECT_APPROVAL_ITEM,
        payload
    }
}
