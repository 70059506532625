import React, { Component } from 'react';
import store from "store/store";
import queryString from 'query-string';
import DashboardBoxes from 'components/common/dashboard-boxes';
import Pagination2 from 'components/common/pagination2';
import { DateMiniFormat, TimeFormat } from "utils/dateFormat";
import { connect } from "react-redux";
import { toast } from 'react-toastify';
import { CSVLink } from "react-csv";
import { customizeSVG } from 'utils/customizeSVG.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactComponent as Calendar } from "assets/images/icons/project-icons/calendar4-week.svg";
import { ReactComponent as Download } from "assets/images/icons/project-icons/Download.svg";
import { ReactComponent as IconChart } from "assets/images/icons/project-icons/ChartPie.svg";
import * as reports from "store/entities/reports/action";


const headers = [
    { label: "Created At", key: "createdAt" },
    { label: "Employee Name", key: "fullName" },
    { label: "Email Address", key: "email" },
    { label: "Phone Number", key: "phoneNumber" },
    { label: "Job Title", key: "jobTitle" },
    { label: "Company Name", key: "employer.companyName" },
];

class QuerySearchCategory extends Component {
    constructor(props) {
        super(props);
        this.csvLinkEl = React.createRef();
    }

    state = { 
        dashboardProps1: {
            iconBg: "bg-blue-200",
            icon: <IconChart className="customizeSVG text-blue-600 m-auto w-7 h-7" />,
            titleText: "Search Results",
            mainText: "-"
        },
        pageUi: {
            page: 1,
            limit: 20,   // @endpoint, limit: 0 === all
        },
        storeUnsubscribe1: "",
        storeUnsubscribe2: "",

        showSearchForm: true,
        showSearchQuerySelector: true,
        showSearchResults: true,
        allFilters: {},
        excludedFilters: ["groupPolicyId", "page", "limit", "sortBy", "order"],
        selectedFilters: [],
        selectedFiltersKeys: [],
        searchQueryForm: "",
        searchQueryPayload: "",
        dataDownload: [],
    }


    fieldNameChange = (e) => {
        // console.log(e);
        const selectedFieldName = e.target.value;
        const { allFilters } = this.state;
        let newFilter = {};
        let filterType = "";

        for (var filter in allFilters){
            if (allFilters[filter].name === selectedFieldName){
                filterType = allFilters[filter].type;
                break;
            }
        }

        const selectedFilters = [...this.state.selectedFilters];
        newFilter = {
            name: selectedFieldName,
            type: filterType,
        }
        selectedFilters.push(newFilter);
        // console.log("selectedFilters",selectedFilters);
        
        const selectedFiltersKeys = [...this.state.selectedFiltersKeys];
        selectedFiltersKeys.push(selectedFieldName);
        // console.log("selectedFiltersKeys",selectedFiltersKeys);

        this.setState({selectedFilters, selectedFiltersKeys, showSearchQuerySelector: false});
    }


    formChange = () => {
        const selectedFiltersKeys = [...this.state.selectedFiltersKeys];
        let searchQueryForm = {};

        selectedFiltersKeys.forEach((filter) => {
            const fieldValue = document.querySelector("." + filter + "Field").value;
            const fieldQuery = document.querySelector("." + filter + "Query").value;
            const fieldSearch = document.querySelector("." + filter + "Search").value;

            searchQueryForm[fieldValue] = {
                "query" : fieldQuery,
                "search" : fieldSearch,
            }
        });

        // console.log("searchQueryForm", searchQueryForm);
        this.setState({searchQueryForm});
    }


    showSearchQuerySelector = () => {
        this.setState({showSearchQuerySelector: true});
    }


    hideSearchQuerySelector = () => {
        this.setState({showSearchQuerySelector: false});
    }


    deleteRow = (fieldName) => {
        const selectedFiltersKeys = [...this.state.selectedFiltersKeys];
        const removeArrayIndex = selectedFiltersKeys.indexOf(fieldName);
        if (removeArrayIndex > -1) {
            selectedFiltersKeys.splice(removeArrayIndex, 1);
        }

        const selectedFilters = [...this.state.selectedFilters];
        let removeJsonIndex = -1;
        for (var filter in selectedFilters){
            if (selectedFilters[filter].name === fieldName){
                removeJsonIndex = filter;
            }
        }
        if (removeJsonIndex > -1) {
            selectedFilters.splice(removeJsonIndex, 1);
        }

        // console.log("selectedFiltersKeys", selectedFiltersKeys);
        // console.log("selectedFilters", selectedFilters);
        this.setState({selectedFilters, selectedFiltersKeys});
    }


    resetForm = () => {
        const selectedFiltersKeys = [];
        const selectedFilters = [];
        this.setState({selectedFilters, selectedFiltersKeys, showSearchQuerySelector: true});
    }


    submitForm = (e) => {
        e.preventDefault();
        let error = 0;
        const selectedFiltersKeys = [...this.state.selectedFiltersKeys];
        let searchQueryPayload = [];
        let searchQueryPayloadElement = [];
        let searchQueryPayloadSubmit = "";

        selectedFiltersKeys.forEach((filter) => {
            searchQueryPayloadElement = [];
            const fieldValue = document.querySelector("." + filter + "Field").value;
            const fieldQuery = document.querySelector("." + filter + "Query").value;
            const fieldSearch = document.querySelector("." + filter + "Search").value;
            // console.log("fieldValue", fieldValue);
            // console.log("fieldQuery", fieldQuery);
            // console.log("fieldSearch", fieldSearch);
            
            if ((fieldValue !== "") && (fieldQuery !== "") && (fieldSearch !== "")){
                searchQueryPayloadElement = [fieldValue, fieldQuery, fieldSearch];
                // console.log("searchQueryPayloadElement", searchQueryPayloadElement);
                searchQueryPayload.push(searchQueryPayloadElement);
                searchQueryPayloadSubmit += "&" + fieldValue + fieldQuery + fieldSearch;
            }
            else{
                error++;
                if (fieldValue === ""){
                    toast.error("Please select a valid field name for " + filter);
                }
                if (fieldQuery === ""){
                    toast.error("Please select a valid query criteria for " + filter);
                }
                if (fieldSearch === ""){
                    toast.error("Please select a valid search param for " + filter);
                }
            }
        });

        if (error === 0){
            // alert('all good');
            // console.log("searchQueryPayloadElement", searchQueryPayloadElement);
            // console.log("searchQueryPayloadSubmit", searchQueryPayloadSubmit);
            this.setState({searchQueryPayload: searchQueryPayloadSubmit});
            this.getSearchResults(searchQueryPayloadSubmit);
        }
    }
    
    
    getSearchResults = (payload = null) => {
        if (payload === null){
            payload = this.state.searchQueryPayload;
        }

        const qs = queryString.parse(this.props.location.search);
        let { page, limit } = this.state.pageUi;

        if ((qs.page !== undefined) && (!isNaN(qs.page)) && (qs.page > 0)){
            page = qs.page;
        }
        else{
            page = 1;
        }

        if ((qs.limit !== undefined) && (!isNaN(qs.limit)) && (qs.limit > 0)){
            limit = qs.limit;
        }
        else{
            limit = 20;
        }

        const pageUi = {
            page: page,
            limit: limit,
        }
        this.setState({pageUi});

        const payloadSubmit = "?output=json" + payload + "&page=" + page + "&limit=" + limit;
        // console.log("payload", payload);
        this.props.getReportsEmployee(payloadSubmit);
    }


    downloadResult = () => {
        /*
        const { searchQueryPayload } = this.state;
        // console.log(searchQueryPayload);
        const payloadSubmit = "?output=csv" + searchQueryPayload + "&limit=0";
        this.props.downloadReportsEmployee(payloadSubmit);

        this.unsubscribe2 = store.subscribe(() => {
            if (this.props.downloadReportsEmployeesLoading === false){
                this.unsubscribe2();
                const downloadLink = this.props.downloadReportsEmployees.data;
                // console.log(downloadLink);
                window.location = downloadLink;
                toast.success("Data downloaded");
            }
        });
        this.setState({storeUnsubscribe2: this.unsubscribe2});
        */

        const pageUi = {
            page: 1,
            limit: 1000,
        }
        const {searchQueryPayload: payload} = this.state;
        const payloadSubmit = "?output=json" + payload + "&page=" + pageUi.page + "&limit=" + pageUi.limit;
        this.props.downloadReportsEmployeeFE(payloadSubmit).then(() => {

            const {downloadReportsEmployees} = this.props;
            // console.log("downloadReportsEmployees", downloadReportsEmployees);

            if (downloadReportsEmployees){
                const dataJSON = downloadReportsEmployees.data.result;
                // console.log("dataJSON", dataJSON);
                const data = Object.values(dataJSON);
                // console.log("data", data);
                this.setState({ dataDownload: data }, () => {
                    setTimeout(() => {
                        this.csvLinkEl.current.link.click();
                        toast.success("Data Downloaded");
                    });
                });
            }
            
        }).catch((error) => {
            toast.success("Unable to download file");
        })
    }


    getFilterName = (filter) => {
        const filterNames = {
            "fullName" : "Full Name",
            "email" : "Email Address",
            "employeeId" : "Employee Id",
            "jobTitle" : "Job Title",
            "employerId" : "Employer Id",
            "employeeGroup" : "Employee Group",
            "companyName" : "Company Name",
            "netMonthlySalary" : "Net Monthly Salary",
            "phoneNumber" : "Phone Number",
            "bankCode" : "Bank Code",
            "balance" : "Balance",
            "totalAmountWithdrawn" : "Total Amount Withdrawn",
            "bankName" : "Bank Name",
            "bankAccountNumber" : "Bank Account Number",
            "earningNotification" : "Earning Notification",
            "status" : "Enrollment",
            "stopEarning" : "Stop Earning",
            "disabled" : "Disabled",
        };

        if (filter in filterNames) {
            return filterNames[filter];
        }
        else{
            return filter;
        }
    }


    componentDidMount() {
        this.props.getReportFiltersEmployee();

        this.unsubscribe1 = store.subscribe(() => {
            if (this.props.allReportFiltersEmployeesLoading === false){
                this.unsubscribe1();
                // console.log(this.props.allReportFiltersEmployees);
                const allFilters = {...this.props.allReportFiltersEmployees.data};
                this.setState({allFilters});
            }
        });
        this.setState({storeUnsubscribe1: this.unsubscribe1});
    }


    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.getSearchResults();
        }
        if (this.props.reloadPage === true) {
            setTimeout(()=>{
                this.props.resetReloadPage();
                this.props.history.replace(this.props.location.pathname + this.props.location.search);
            },1000);
        }
    }


    componentWillUnmount() {
        const { storeUnsubscribe1, storeUnsubscribe2 } = this.state;
        if ((storeUnsubscribe1 !== "") && (storeUnsubscribe1 !== undefined) && (storeUnsubscribe1 !== null)){
            storeUnsubscribe1();
        }
        if ((storeUnsubscribe2 !== "") && (storeUnsubscribe2 !== undefined) && (storeUnsubscribe2 !== null)){
            storeUnsubscribe2();
        }
    }


    render() { 
        customizeSVG();
        const {allReportFiltersEmployees: data, allReportsEmployees, downloadReportsEmployeesLoading} = this.props;
        const { showSearchForm, showSearchQuerySelector, showSearchResults, excludedFilters, selectedFilters, selectedFiltersKeys, searchQueryForm, dataDownload, dashboardProps1, pageUi } = this.state;
        // console.log("allReportsEmployees", allReportsEmployees);

        return ( 
            <div>

                {(this.props.allReportFiltersEmployeesLoading === true) && 
                    <div>
                        <div className="table-info">
                            <FontAwesomeIcon icon="spinner" spin />
                            <div className="font-bold uppercase">
                                Loading
                            </div>
                        </div>
                    </div>
                }

                {(this.props.allReportFiltersEmployeesLoading === false) && (!data.data) && 
                    <div>
                        <div className="table-info">
                            <FontAwesomeIcon icon="unlink" />
                            <div className="font-bold uppercase">
                                An error occurred
                                <br />
                                Please try again later.
                            </div>
                        </div>
                    </div>
                }

                {((this.props.allReportFiltersEmployeesLoading === false) && (data.data) && (data.data.length <= 0)) && 
                    <div>
                        <div className="table-info">
                            <FontAwesomeIcon icon="list" />
                            <div className="font-bold uppercase">
                                No filters found
                            </div>
                        </div>
                    </div>
                }

                {((this.props.allReportFiltersEmployeesLoading === false) && (data.data) && (data.data.length > 0)) && 
                    <>

                        <div>
                            <div className="lg:flex">
                                <div className="w-full lg:w-1/2">
                                    <div className="page-title capitalize">
                                        Search Employees
                                    </div>
                                </div>
                                <div className="w-full lg:w-1/2 flex mt-2 lg:mt-0 lg:justify-end"></div>
                            </div>
                        </div>

                        {(showSearchForm === true) &&
                            <div className="overflow-x-auto overflow-y-hidden">
                                <div style={{"minWidth":"440px"}}>
                                    <form onSubmit={this.submitForm} className="mt-6 box" style={{"minWidth":"400px"}}>

                                        <div className="mb-3 text-sm flex space-x-6">
                                            <div className="w-1/4">
                                                Field Name
                                                <span className="form-input-required">*</span>
                                            </div>
                                            <div className="w-1/4">
                                                Query Criteria
                                                <span className="form-input-required">*</span>
                                            </div>
                                            <div className="w-2/4">
                                                Search Param
                                                <span className="form-input-required">*</span>
                                            </div>
                                        </div>


                                        {/* {data.data.filter((filter) => {return selectedFilters.includes(filter.name)}).map((filter, index) => */}
                                        {selectedFilters.map((filter, index) =>
                                            <div key={index} className="flex space-x-6">
                                                <div className="w-1/4 form-group">
                                                    <input type="text" className="form-input" value={this.getFilterName(filter.name)} disabled />
                                                    <input type="hidden" className={"form-input hidden " + filter.name + "Field"} value={filter.name} onChange={this.fieldNameChange} disabled />
                                                </div>

                                                <div className="w-1/4 form-group">
                                                    {(filter.type === "String") &&
                                                        <select className={"form-input " + filter.name + "Query"} value={(searchQueryForm[filter["name"]]) ? searchQueryForm[filter["name"]].query : ""} onChange={this.formChange}>
                                                            <option value="=%23">Contains</option>
                                                            {/* <option value="">Select</option> */}
                                                            {/* <option value="!#">Does not contain</option> */}
                                                        </select>
                                                    }
                                                    {((filter.type === "Number") || (filter.type === "Date")) &&
                                                        <select className={"form-input " + filter.name + "Query"} value={(searchQueryForm[filter["name"]]) ? searchQueryForm[filter["name"]].query : ""} onChange={this.formChange}>
                                                            <option value="">Select</option>
                                                            <option value="=>">Greater than</option>
                                                            <option value="=>=">Greater than & Equals to</option>
                                                            <option value="==">Equals to</option>
                                                            <option value="=<=">Less than & Equals to</option>
                                                            <option value="=<">Less than</option>
                                                        </select>
                                                    }
                                                    {(filter.type === "Boolean") &&
                                                        <select className={"form-input " + filter.name + "Query"} value={(searchQueryForm[filter["name"]]) ? searchQueryForm[filter["name"]].query : ""} onChange={this.formChange}>
                                                            <option value="=">is</option>
                                                            {/* 
                                                            <option value="">Select</option>
                                                            <option value="=">is</option>
                                                            <option value="!=">is not</option>
                                                            */}
                                                        </select>
                                                    }
                                                </div>

                                                <div className="w-2/4 flex space-x-6">
                                                    <div className="flex-grow form-group">
                                                        {(filter.type === "String") &&
                                                            <input type="text" placeholder="Enter your search query" className={"form-input " + filter.name + "Search"} value={(searchQueryForm[filter["name"]]) ? searchQueryForm[filter["name"]].search : ""} onChange={this.formChange} />
                                                        }
                                                        {(filter.type === "Number") &&
                                                            <input type="number" placeholder="Enter your search query" className={"form-input " + filter.name + "Search"} value={(searchQueryForm[filter["name"]]) ? searchQueryForm[filter["name"]].search : ""} onChange={this.formChange} />
                                                        }
                                                        {(filter.type === "Date") &&
                                                            <input type="date" className={"form-input " + filter.name + "Search"} value={(searchQueryForm[filter["name"]]) ? searchQueryForm[filter["name"]].search : ""} onChange={this.formChange} />
                                                        }
                                                        {(filter.type === "Boolean") &&
                                                            <select className={"form-input " + filter.name + "Search"} value={(searchQueryForm[filter["name"]]) ? searchQueryForm[filter["name"]].search : ""} onChange={this.formChange}>
                                                                <option value="">Select</option>
                                                                <option value="true">Yes</option>
                                                                <option value="false">No</option>
                                                            </select>
                                                        }
                                                    </div>

                                                    <div className="flex-shrink-0 form-group">
                                                        <button type="button" onClick={() => this.deleteRow(filter.name)} className="h-10 flex rounded px-3 py-2 border border-gray-300">
                                                            <FontAwesomeIcon icon="times" className="my-auto" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        

                                        {(showSearchQuerySelector === true) &&
                                            <div className="flex space-x-6">
                                                <div className="w-1/4 form-group">
                                                    <select id="searchField1" className="form-input" value="" onChange={this.fieldNameChange}>
                                                        <option value="">Select field</option>
                                                        {data.data.filter((filter) => {return (!excludedFilters.includes(filter.name) && !selectedFiltersKeys.includes(filter.name))}).map((filter, index) =>
                                                            <option key={index} value={filter.name}>{this.getFilterName(filter.name)}</option>
                                                        )}
                                                    </select>
                                                </div>
                                                <div className="w-1/4 form-group">
                                                    <input type="text" className="form-input" disabled />
                                                </div>
                                                <div className="w-2/4 flex space-x-6">
                                                    <div className="flex-grow form-group">
                                                        <input type="text" className="form-input" disabled />
                                                    </div>
                                                    <div className="flex-shrink-0 form-group">
                                                        <button type="button" onClick={this.hideSearchQuerySelector} className="h-10 flex rounded px-3 py-2 border border-gray-300">
                                                            <FontAwesomeIcon icon="times" className="my-auto" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        

                                        <div className="flex justify-between">
                                            <div>
                                                {(showSearchQuerySelector === false) &&
                                                    <button type="button" onClick={this.showSearchQuerySelector} className="btn btn-transparent-black btn-md">
                                                        <FontAwesomeIcon icon="plus" className="mr-2" />
                                                        Add filter
                                                    </button>
                                                }
                                            </div>
                                            <div className="flex justify-end space-x-4">
                                                <button type="button" onClick={this.resetForm} className="btn btn-red btn-md">
                                                    <FontAwesomeIcon icon="times" className="mr-2" />
                                                    Reset
                                                </button>
                                                <button type="submit" className="btn btn-ep-blue btn-md">
                                                    <FontAwesomeIcon icon="search" className="mr-2" />
                                                    Search
                                                </button>
                                            </div>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        }

                        {(showSearchResults === true) &&
                            <div>
                                <div className="mt-6">
                                    <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6 items-end">
                                        <DashboardBoxes data={dashboardProps1} result={(allReportsEmployees.data !== undefined) ? allReportsEmployees.data.total : "-"} />
                                        <div className="hidden lg:block"></div>

                                        {((this.props.allReportsEmployeesLoading === false) && (allReportsEmployees.data) && (allReportsEmployees.data.result.length > 0)) && 
                                            <div>
                                                <CSVLink filename="All Employees.csv" data={dataDownload} headers={headers} ref={this.csvLinkEl} />
                                                <button type="button" onClick={this.downloadResult} className="btn btn-md btn-gray-hover btn-transparent-black md:float-right" disabled={downloadReportsEmployeesLoading}>
                                                    <FontAwesomeIcon icon="spinner" spin className={"text-lg mr-2 " + (downloadReportsEmployeesLoading?"inline":"hidden")} />
                                                    Download
                                                    <Download className="customizeSVG ml-2 -mt-px" />
                                                </button>
                                            </div>
                                        }

                                        {/* 
                                        {((this.props.allReportsEmployeesLoading === false) && (allReportsEmployees.data) && (allReportsEmployees.data.result.length > 0)) && 
                                            <div>
                                                <button type="button" onClick={this.downloadResult} className="btn btn-md btn-gray-hover btn-transparent-black md:float-right" disabled={downloadReportsEmployeesLoading}>
                                                    <FontAwesomeIcon icon="spinner" spin className={"text-lg mr-2 " + (downloadReportsEmployeesLoading?"inline":"hidden")} />
                                                    Download
                                                    <Download className="customizeSVG ml-2 -mt-px" />
                                                </button>
                                            </div>
                                        }
                                        */}

                                    </div>
                                </div>
                                
                                <div className="mt-6">

                                    <div className="table-container">
                                        <table className="table table-auto table-rounded table-border table-align-top">
                                            <thead>
                                                <tr>
                                                    <th>Date Enrolled</th>
                                                    <th>Employee Name</th>
                                                    <th>Email Address</th>
                                                    <th>Phone Number</th>
                                                    <th>Job Title</th>
                                                    <th>Company Name</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {(this.props.allReportsEmployeesLoading === true) && 
                                                    <tr>
                                                        <td colSpan="6">
                                                            <div className="table-info">
                                                                <FontAwesomeIcon icon="spinner" spin />
                                                                <div className="font-bold uppercase">
                                                                    Loading
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                }

                                                {((this.props.allReportsEmployeesLoading === false) && (!allReportsEmployees.data)) && 
                                                    <tr>
                                                        <td colSpan="6">
                                                            <div className="table-info">
                                                                <FontAwesomeIcon icon="search" />
                                                                <div className="font-bold uppercase">
                                                                    Enter your
                                                                    <br />
                                                                    search query
                                                                </div>
                                                            </div>
                                                            {/* 
                                                            <div className="table-info">
                                                                <FontAwesomeIcon icon="unlink" />
                                                                <div className="font-bold uppercase">
                                                                    An error occurred
                                                                    <br />
                                                                    Please try again later.
                                                                </div>
                                                            </div>
                                                             */}
                                                        </td>
                                                    </tr>
                                                }
                                        
                                                {((this.props.allReportsEmployeesLoading === false) && (allReportsEmployees.data) && (allReportsEmployees.data.result.length <= 0)) && 
                                                    <tr>
                                                        <td colSpan="6">
                                                            <div className="table-info">
                                                                <FontAwesomeIcon icon="list" />
                                                                <div className="font-bold uppercase">
                                                                    No results found
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                }

                                                {((this.props.allReportsEmployeesLoading === false) && (allReportsEmployees.data) && (allReportsEmployees.data.result.length > 0)) && 
                                                    allReportsEmployees.data.result.map((employee, index) =>
                                                        <tr key={index}>
                                                            <td>
                                                                <div className="flex">
                                                                    <Calendar className="customizeSVG mt-0.5 mr-2" />
                                                                    <div>
                                                                        {DateMiniFormat(employee.createdAt)}
                                                                        <br />
                                                                        {TimeFormat(employee.createdAt)}
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                {employee.fullName}
                                                            </td>
                                                            <td>
                                                                {employee.email}
                                                            </td>
                                                            <td>
                                                                {employee.phoneNumber}
                                                            </td>
                                                            <td>
                                                                {employee.jobTitle}
                                                            </td>
                                                            <td>
                                                                {employee.employer.companyName}
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                                
                                            </tbody>
                                        </table>
                                    </div>

                                    {((this.props.allReportsEmployeesLoading === false) && (allReportsEmployees.data) && (allReportsEmployees.data.result.length > 0)) && 
                                        <div>
                                            <Pagination2 data={allReportsEmployees.data} url={this.props.location.search} limit={pageUi.limit} />
                                        </div>
                                    }

                                </div>
                            </div>
                        }
                        
                    </>
                }

            </div>
        );
    }
}


const mapStateToProps = (state) => ({
    allReportFiltersEmployeesLoading: state.entities.reports.allReportFiltersEmployeesLoading,
    allReportFiltersEmployees: state.entities.reports.allReportFiltersEmployees,
    allReportsEmployeesLoading: state.entities.reports.allReportsEmployeesLoading,
    allReportsEmployees: state.entities.reports.allReportsEmployees,
    downloadReportsEmployeesLoading: state.entities.reports.downloadReportsEmployeesLoading,
    downloadReportsEmployees: state.entities.reports.downloadReportsEmployees,
});

const mapDispatchToProps = (dispatch) => ({
    getReportFiltersEmployee: () => dispatch(reports.getReportFiltersEmployee()),
    getReportsEmployee: (params) => dispatch(reports.getReportsEmployee(params)),
    downloadReportsEmployee: (params) => dispatch(reports.downloadReportsEmployee(params)),
    downloadReportsEmployeeFE: (params) => dispatch(reports.downloadReportsEmployee(params)),   
});

export default connect(mapStateToProps, mapDispatchToProps)(QuerySearchCategory);