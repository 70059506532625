import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FormDrawer from "components/__new/common/form-drawer";
import { ButtonLoader, Input, Textarea } from "components/__new/common/form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactComponent as IconCoin } from "assets/images/icons/project-icons/coin-gold.svg";
import { ReactComponent as IconArrowRight } from "assets/images/icons/project-icons/arrow-right.svg";
import { ReactComponent as IconImageDefault } from "assets/images/icons/project-icons/image-default.svg";
import { ReactComponent as IconTimes } from "assets/images/icons/project-icons/times.svg";
import { ReactComponent as IconCircleTimes } from "assets/images/icons/project-icons/circle-times.svg";
import validate from "utils/validate";
import { toast } from "react-toastify";
import Maybe from "components/__new/common/Maybe";
import CustomSwitch from "../common/form/CustomSwitch";
import FileUploadForm from "../common/form/FileUploadForm";
import useMediaService from "hooks/useMediaService";
import store from "store/store";
import moment from "moment";
import DialogBox from "utils/dialogBox";
import * as rewardsActions from "store/entities/rewards/action";


const RewardForm = (props) => {
    const { isOpen, setIsOpen, selectedReward, getRewards } = props;
    const formAction = Object.keys(selectedReward).length === 0 ? "create" : "edit";
    

    const dispatch = useDispatch();
    const { uploadFile, uploadLoading } = useMediaService();
    //eslint-disable-next-line no-unused-vars
    const { rewardCreatedLoading, rewardCreated, rewardUpdatedLoading, rewardUpdated, promoCodeUploadLoading, promoCodeUpload } = useSelector((s) => s.entities.rewards);
    

    const formDefault1 = {
        title: "",
        partner: "",
        description: "",
        pointRequired: true,
        pointCost: "",
        expiryDate: "",
        partnerLogo: "",
        thumbnail: "",
    }
    const formDefault2 = {
        promoCode: [],
        promoUpload: "",
        usageCount: "",
        instructionOne: "",
        instructionTwo: "",
        instructionThree: "",
    }
    const [isFormValidated, setIsFormValidated] = useState(false);
    const [form1, setForm1] = useState(formDefault1);
    const [form2, setForm2] = useState(formDefault2);
    const [formStep, setFormStep] = useState(1);
    const [formErrors, setFormErrors] = useState(null);


    const checkFormValidation = (data, appendKey, appendValue) => {
        let params;
        if (data === undefined){
            if (formStep === 1){
                params = {...form1}
            }
            else if (formStep === 2){
                params = {...form2};
                delete params["promoCode"];
                delete params["instructionTwo"];
                delete params["instructionThree"];
            }
        }
        else{ params = {...data} }

        if (appendKey){
            params = { ...params, [appendKey]: appendValue }
        }

        let errors = validate(params);
        if (formAction === "edit" && errors !== null && errors["promoUpload"] !== undefined){
            delete errors["promoUpload"];
            if (Object.keys(errors).length === 0){ errors = null }
        }

        if (errors){
            setIsFormValidated(false);
            // console.log("checkFormValidation", errors);
        }
        else{
            setIsFormValidated(true);
        }
        setFormErrors(errors);
    }


    const getLoading = () => {
        if (rewardCreatedLoading === true || rewardUpdatedLoading === true || promoCodeUploadLoading === true || uploadLoading === true){
            return true;
        }
        return false;
    }


    const updatePointRequired = () => {
        const newPointRequired = !form1.pointRequired;
        const newPointCost = (newPointRequired === true) ? "" : 0;
        setForm1({
            ...form1,
            pointRequired: newPointRequired,
            pointCost: newPointCost,
        });
        checkFormValidation(form1, "pointCost", newPointCost);
    }


    const deletePromoCode = async (code) => {
        const result = await DialogBox({
            icon: <IconCircleTimes className="w-16 h-16" />,
            title: "Delete this Promo Code?",
            content: "Are you sure you want to delete this promo code?",
            buttonYesClass: "btn-red",
        });
        if (result) {
            const newPromoCodes = form2.promoCode.filter((c) => c.code !== code.code);
            setForm2({...form2, promoCode: newPromoCodes});
        }
    }


    /*
    const submitForm = async (e) => {
        e?.preventDefault();

        if (form2.promoUpload === ""){
            submitFormReward(form2.promoCode);
        }
        else{
            let data = new FormData();
            data.append("file", form2.promoUpload);
            dispatch(rewardsActions.uploadPromoCode(data));
        }
    }


    const submitFormReward = async (promoCode) => {
        let error = 0;
        let params = { ...form1, ...form2, promoCode }
        
        const logo = await doFileUploads(form1.partnerLogo);
        if (logo !== ""){
            params = { ...params, partnerLogo: logo }
        }
        else{ if(formAction === "create"){error++} }
        
        const thumbnail = await doFileUploads(form1.thumbnail);
        if (thumbnail !== ""){
            params = { ...params, thumbnail: thumbnail }
        }
        else{ if(formAction === "create"){error++} }
        
        delete params["promoUpload"];
        if (error === 0){
            if (formAction === "create"){
                dispatch(rewardsActions.createReward(params));
            }
            else{
                console.log("edit");
                // dispatch(rewardsActions.updateReward(selectedReward._id, params));
            }
        }
    }
    */


    const submitForm = async (e) => {
        e?.preventDefault();

        if (formAction === "create"){
            submitFormCreate();
        }
        else{
            submitFormEdit();
        }
    }


    const submitFormCreate = async () => {
        let params = { ...form1, ...form2 }
        let promoCode = [];
        let errors = 0;

        if (form2.promoUpload !== "" && form2.promoUpload !== null){
            const promoUpload = new FormData();
            promoUpload.append("file", form2.promoUpload);
            await dispatch(rewardsActions.uploadPromoCode(promoUpload));
            const promoUploadResponse = store.getState().entities.rewards.promoCodeUpload;
            if (promoUploadResponse?.status === "success"){
                promoCode = promoUploadResponse?.data[0] !== null ? promoUploadResponse?.data : [];
                params = { ...params, promoCode }
                setForm2({...form2, promoCode});
            }
        }

        const logo = await doFileUploads(form1.partnerLogo);
        if (logo !== ""){ params = { ...params, partnerLogo: logo } }
        else{ errors++ }
        
        const thumbnail = await doFileUploads(form1.thumbnail);
        if (thumbnail !== ""){ params = { ...params, thumbnail: thumbnail } }
        else{ errors++ }

        if (errors === 0){
            delete params["promoUpload"];
            if (params.usageCount === ""){
                delete params["usageCount"];
            }
            dispatch(rewardsActions.createReward(params));
        }
    }


    const submitFormEdit = async () => {
        let params = { ...form1, ...form2 }
        let promoCode = [];
        let errors = 0;

        if (form2.promoUpload !== "" && form2.promoUpload !== null){
            const promoUpload = new FormData();
            promoUpload.append("file", form2.promoUpload);
            await dispatch(rewardsActions.uploadPromoCode(promoUpload));
            const promoUploadResponse = store.getState().entities.rewards.promoCodeUpload;
            if (promoUploadResponse?.status === "success"){
                const newCodes = promoUploadResponse?.data[0] !== null ? promoUploadResponse?.data : [];
                const oldCodes = form2.promoCode.map((e) => e.code);
                promoCode = [...oldCodes, ...newCodes];
                params = { ...params, promoCode }
            }
        }

        const logo = await doFileUploads(form1.partnerLogo);
        if (logo !== ""){ params = { ...params, partnerLogo: logo } }
        
        const thumbnail = await doFileUploads(form1.thumbnail);
        if (thumbnail !== ""){ params = { ...params, thumbnail: thumbnail } }

        if (errors === 0){
            delete params["promoUpload"];
            if (params.usageCount === ""){
                delete params["usageCount"];
            }
            dispatch(rewardsActions.updateReward(selectedReward._id, params));
        }
    }


    const doFileUploads = async (file) => {
        if (file !== "" && typeof file !== "string" && file?.lastModified !== undefined){
            const response = await uploadFile(file);
            if (response?.status === "success"){
                return response?.data?.url ?? ""
            }
            else{
                toast.error(file?.name + " could not be uploaded");
            }
        }
        return "";
    }


    const clearForm = () => {
        setForm1(formDefault1);
        setForm2(formDefault2);
    }


    useEffect(() => {
        checkFormValidation();
        // eslint-disable-next-line
    }, [formStep])


    useEffect(() => {
        if (isOpen === true){
            let data1 = {...formDefault1};
            let data2 = {...formDefault2};
            if (formAction === "edit"){
                data1 = {
                    ...data1,
                    title: selectedReward?.title ?? "",
                    partner: selectedReward?.partner ?? "",
                    description: selectedReward?.description ?? "",
                    pointRequired: selectedReward?.pointRequired ?? true,
                    pointCost: selectedReward?.pointCost ?? "",
                    expiryDate: selectedReward?.expiryDate ?? "",
                    partnerLogo: selectedReward?.partnerLogo ?? "",
                    thumbnail: selectedReward?.thumbnail ?? "",
                };
                data2 = {
                    ...data2,
                    promoCode: selectedReward?.promoCode ?? [],
                    promoUpload: "",
                    usageCount: selectedReward?.usageCount ?? "",
                    instructionOne: selectedReward?.instructionOne ?? "",
                    instructionTwo: selectedReward?.instructionTwo ?? "",
                    instructionThree: selectedReward?.instructionThree ?? "",
                };
                setForm1(data1);
                setForm2(data2);
            }
            else{
                clearForm();
            }
            setFormStep(1);
            checkFormValidation(data1);
        }
        // eslint-disable-next-line
    }, [isOpen])
    
    
    /*
    useEffect(() => {
        if (promoCodeUpload?.status === "success"){
            const data = promoCodeUpload?.data[0] !== null ? promoCodeUpload?.data : [];
            const newPromoCodes = [...form2.promoCode, ...data];
            setForm2({...form2, promoCode: newPromoCodes});
            submitFormReward(newPromoCodes);
            dispatch(rewardsActions.resetUploadPromoCode());
        }
        // eslint-disable-next-line
    }, [promoCodeUpload])
    */


    useEffect(() => {
        if (rewardCreated?.status === "success" || rewardUpdated?.status === "success"){
            toast.success(`Reward ${formAction === "create" ? "created" : "edited"} successfully`);
            dispatch(rewardsActions.resetCreateReward());
            dispatch(rewardsActions.resetUpdateReward());
            clearForm();
            setIsOpen(false);
            getRewards();
        }
        // eslint-disable-next-line
    }, [rewardCreated, rewardUpdated])


    return (
        <FormDrawer display="center" size={(formStep === 1 || formAction === "edit") ? "4xl":"md"} isOpen={isOpen} setIsOpen={setIsOpen}>
            <form onSubmit={submitForm} className="p-6 max-w-4xl">
                <div className="flex items-center justify-between">
                    <div className="page-title-mini capitalize flex items-center">
                        <Maybe condition={formStep === 2}>
                            <div onClick={() => setFormStep(1)} className="w-8 h-8 mr-2 flex items-center justify-start hover:bg-gray-100 cursor-pointer">
                                <IconArrowRight />
                            </div>
                        </Maybe>
                        {formAction === "create" ? "Add New" : "Edit"} Reward
                    </div>
                    <div onClick={() => setIsOpen(false)} className="modal-close group">
                        <FontAwesomeIcon icon="times" className="group-hover:text-white m-auto" />
                    </div>
                </div>
                

                <div className="mt-10">
                    <Maybe condition={formStep === 1}>
                        <div className="flex space-x-6">
                            <div className="w-1/2">
                                <Input type="text" label="Reward Title" placeholder="Enter the title of the reward" required={true} value={form1.title} onChange={(e) => setForm1({...form1, title: e.target.value})} validationName="title" checkFormValidation={checkFormValidation} />
                                <Input type="text" label="Rewarder" placeholder="Enter rewarder’s Name" required={true} value={form1.partner} onChange={(e) => setForm1({...form1, partner: e.target.value})} validationName="partner" checkFormValidation={checkFormValidation} />
                                <Textarea label="Description" placeholder="Enter a description of the reward..." required={true} styles={{height: "100px", resize: "none"}} value={form1.description} onChange={(e) => setForm1({...form1, description: e.target.value})} validationName="description" checkFormValidation={checkFormValidation} />

                                <div className="relative">
                                    <div className="absolute top-0 right-0 flex">
                                        <CustomSwitch
                                            active={form1.pointRequired}
                                            activeText="Points Required"
                                            inactiveText="No Points Required"
                                            onChange={updatePointRequired}
                                        />
                                    </div>
                                    <Input type="number" label="Point Cost" placeholder="0" disabled={form1.pointRequired === false} required={true} value={form1.pointCost} onChange={(e) => setForm1({...form1, pointCost: e.target.value})} inputClass="mt-1" validationName="pointCost" checkFormValidation={checkFormValidation} hideValidationMessage={(formErrors?.pointCost === undefined)} />
                                </div>
                                <Input type="date" label="Reward Validity" placeholder="Choose a date" min={moment().format("YYYY-MM-DD")} required={true} value={form1.expiryDate} onChange={(e) => setForm1({...form1, expiryDate: e.target.value})} validationName="expiryDate" checkFormValidation={checkFormValidation} />

                                <FileUploadForm
                                    label="Upload Rewarder's Logo"
                                    placeholder="Choose Image to upload"
                                    filetype={["jpg","jpeg","png","gif"]}
                                    data={form1.partnerLogo}
                                    onchange={(e) => setForm1({...form1, partnerLogo: e})}
                                    filename={form1.partnerLogo?.name}
                                    validationName="partnerLogo"
                                    checkFormValidation={checkFormValidation}
                                    designStyle="input"
                                />
                                <FileUploadForm
                                    label="Upload Thumbnail"
                                    placeholder="Choose Image to upload"
                                    filetype={["jpg","jpeg","png","gif"]}
                                    data={form1.thumbnail}
                                    onchange={(e) => setForm1({...form1, thumbnail: e})}
                                    filename={form1.thumbnail?.name}
                                    validationName="thumbnail"
                                    checkFormValidation={checkFormValidation}
                                    designStyle="input"
                                />
                            </div>
                            <div className="w-1/2 pl-6 flex items-center border-l border-gray-200">
                                <RewardPreview form={form1} />
                            </div>
                        </div>
                    </Maybe>


                    <Maybe condition={formStep === 2}>
                        <div className="flex space-x-6">
                            <div className={formAction === "edit" ? "w-1/2" : "w-full"}>
                                <div>
                                    <div className="text-sm mb-1">
                                        Promo Codes
                                        {/* {formAction === "create" && <span className="form-input-required">*</span>} */}
                                        {formAction === "edit" && <span className="form-input-required">(new upload disallowed)</span>}
                                    </div>
                                    <FileUploadForm
                                        label="Promo Codes"
                                        filetype={["csv"]}
                                        disabled={formAction === "edit"}
                                        data={form2.promoUpload}
                                        onchange={(e) => {setForm2({...form2, promoUpload: e}); checkFormValidation(undefined, "promoUpload", e)}}
                                        filename="Promo codes"
                                        validationName="promoUpload"
                                        checkFormValidation={checkFormValidation}
                                    />
                                    <div className="-mt-4 mb-4 text-right">
                                        <a href="https://ep-mediafiles.s3.eu-west-1.amazonaws.com/promo_code_sample_file_Sheet1_csv_1701764999909.csv" target="_blank" rel="noreferrer" download className="text-ep-primary text-sm underline cursor-pointer">
                                            Download sample file
                                        </a>
                                    </div>
                                </div>
                                <Input type="number" label="Promo Code Usage Count" placeholder="Enter usage count" value={form2.usageCount} onChange={(e) => setForm2({...form2, usageCount: e.target.value})} validationName="usageCount" checkFormValidation={checkFormValidation} />
                                <div className="text-sm">
                                    How to Use (Keep each instruction to under 2 lines)
                                    <span className="form-input-required">*</span>
                                </div>
                                <Textarea label="" containerClass="!mb-0" placeholder="Instruction 1" required={false} styles={{height: "70px", resize: "none"}} value={form2.instructionOne} onChange={(e) => setForm2({...form2, instructionOne: e.target.value})} validationName="instructionOne" checkFormValidation={checkFormValidation} />
                                <Textarea label="" containerClass="!mb-0" placeholder="Instruction 2" required={false} styles={{height: "70px", resize: "none"}} value={form2.instructionTwo} onChange={(e) => setForm2({...form2, instructionTwo: e.target.value})} />
                                <Textarea label="" containerClass="!mb-0" placeholder="Instruction 3" required={false} styles={{height: "70px", resize: "none"}} value={form2.instructionThree} onChange={(e) => setForm2({...form2, instructionThree: e.target.value})} />
                            </div>

                            <Maybe condition={formAction === "edit"}>
                                <div className="w-1/2 pl-6 border-l border-gray-200">
                                    
                                    <div className="text-sm">
                                        Existing promo codes ({form2?.promoCode?.length ?? 0})
                                    </div>
                                    <div className="mt-1 h-[428px] text-sm border overflow-auto">
                                        <Maybe condition={form2?.promoCode?.length > 0}>
                                            {form2?.promoCode?.map((code, index) => (
                                                <div key={index} className="w-full p-4 flex items-center justify-between space-x-6 border-t">
                                                    <div>
                                                        {code?.code ?? "-"}
                                                        {code?.available && <FontAwesomeIcon icon="check" className="ml-1" />}
                                                    </div>
                                                    <div>
                                                        <button type="button" onClick={() => deletePromoCode(code)} disabled={true} className="btn btn-red btn-sm">
                                                            <IconTimes className="w-3 h-3 mr-2 svg-fill-white" />
                                                            Delete
                                                        </button>
                                                    </div>
                                                </div>
                                            ))}
                                        </Maybe>
                                        <Maybe condition={form2?.promoCode?.length === 0}>
                                            <div className="h-full flex items-center justify-center italic">
                                                No promo codes
                                            </div>
                                        </Maybe>
                                    </div>

                                </div>
                            </Maybe>
                        </div>
                    </Maybe>
                </div>
                

                <div className="mt-10 flex justify-end space-x-4">
                    <Maybe condition={formStep === 1}>
                        <ButtonLoader type="button" onClick={() => setIsOpen(false)} className="px-10 btn btn-white">
                            Cancel
                        </ButtonLoader>
                        <ButtonLoader type="button" onClick={() => setFormStep(2)} className="px-10 btn btn-ep-blue" disabled={!isFormValidated}>
                            Next
                        </ButtonLoader>
                    </Maybe>
                    <Maybe condition={formStep === 2}>
                        <ButtonLoader type="button" onClick={() => setIsOpen(false)} className={`${formAction === "create" ? "btn-block" : "px-10"} btn btn-white`}>
                            Cancel
                        </ButtonLoader>
                        <ButtonLoader type="submit" loading={getLoading()} className={`${formAction === "create" ? "btn-block" : "px-10"} btn btn-ep-blue`} disabled={!isFormValidated}>
                            Save {formAction === "edit" && "Changes"}
                        </ButtonLoader>
                    </Maybe>
                </div>
            </form>
        </FormDrawer>
    )
}


const RewardPreview = ({form}) => {
    
    const display = (data, fallback) => {
        if (data === ""){ return fallback }
        return data;
    }

    return (
        <div className="w-full">
            <div className="text-center">
                <div className="font-bold">
                    Preview
                </div>
                <div className="text-fade text-sm">
                    Rewards added will show up this way on the App
                </div>
            </div>
            <div className="p-6 mt-4 bg-gray-100 rounded-xl">
                <div className="w-full p-2 flex-shrink-0 flex space-x-2 bg-white border border-gray-200 rounded-xl">
                    <div className="w-24 h-24 flex-shrink-0 bg-gray-200 rounded-xl rounded-tl-none relative overflow-hidden">
                        <div className="w-10 h-10 absolute -top-2 -left-2 flex items-center justify-center rounded-lg border-4 border-white bg-gray-200 overflow-hidden">
                            <Maybe condition={form.partnerLogo === "" || form.partnerLogo === null}>
                                -
                            </Maybe>
                            <Maybe condition={form.partnerLogo !== ""}>
                                <Maybe condition={typeof form.partnerLogo === "string" && form.partnerLogo?.lastModified === undefined}>
                                    <img src={form.partnerLogo} alt="logo" className="object-cover" />
                                </Maybe>
                                {typeof form.partnerLogo === "object" && form.partnerLogo?.lastModified !== undefined &&
                                    <img src={URL.createObjectURL(form.partnerLogo)} alt="logo" className="object-cover" />
                                }
                            </Maybe>
                        </div>

                        <Maybe condition={form.thumbnail === "" || form.thumbnail === null}>
                            <div className="w-full h-full flex">
                                <IconImageDefault className="m-auto" />
                            </div>
                        </Maybe>
                        <Maybe condition={form.thumbnail !== ""}>
                            <Maybe condition={typeof form.thumbnail === "string" && form.thumbnail?.lastModified === undefined}>
                                <img src={form.thumbnail} alt="thumbnail" className="object-cover" />
                            </Maybe>
                            {typeof form.thumbnail === "object" && form.thumbnail?.lastModified !== undefined &&
                                <img src={URL.createObjectURL(form.thumbnail)} alt="thumbnail" className="object-cover" />
                            }
                        </Maybe>
                    </div>
                    <div className="flex-grow">
                        <div className="font-medium text-sm">
                            {display(form.title, "Reward Title")}
                        </div>
                        <div className="mt-0.5 text-fade text-xs ellipsis-2-lines">
                            {display(form.description, "The Reward Description will appear here as you enter it...")}
                        </div>
                        <div className="mt-3 flex items-center justify-between">
                            <Maybe condition={form.pointRequired === true}>
                                <div className="text-sm flex">
                                    <IconCoin className="mr-1" />
                                    {display(form.pointCost, "0")}
                                </div>
                                <div>
                                    <ButtonLoader type="button" onClick={() => alert("This is just a preview")} className="btn btn-sm btn-ep-blue">
                                        Redeem
                                    </ButtonLoader>
                                </div>
                            </Maybe>
                            <Maybe condition={form.pointRequired === false}>
                                <div className="label label-green label-sm">
                                    Ongoing
                                </div>
                            </Maybe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default RewardForm;