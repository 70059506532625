import { toast } from "react-toastify";
import * as actions from "./actionTypes";
import errorReducer from "utils/errorReducer";


const initialState = {
    rewardPointsLoading: false,
    rewardPoints: {},
    rewardPointsDownloadLoading: false,
    rewardPointsDownload: {},

    rewardPointsCreatedLoading: false,
    rewardPointsCreated: {},
    rewardPointsUpdatedLoading: false,
    rewardPointsUpdated: {},
    rewardPointsDeletedLoading: false,

    rewardPointsAssignedLoading: false,
    rewardPointsAssigned: {},

    rewardPointsEnabledLoading: false,
    rewardPointsDisabledLoading: false,

    rewardsLoading: false,
    rewards: {},
    rewardsDownloadLoading: false,
    rewardsDownload: {},

    rewardCreatedLoading: false,
    rewardCreated: {},
    rewardUpdatedLoading: false,
    rewardUpdated: {},

    rewardEnabledLoading: false,
    rewardDisabledLoading: false,

    redeemedRewardsLoading: false,
    redeemedRewards: {},
    redeemedRewardsDownloadLoading: false,
    redeemedRewardsDownload: {},

    rewardMetricsLoading: false,
    rewardMetrics: {},
    
    getPointsHistoryLoading: false,
    getPointsHistoryData: {},
    
    approveGiftPointLoading: false,
    rejectGiftPointLoading: false,
    
    downloadPointsHistoryLoading: false,
    downloadPointsHistoryData: {},

    approveRewardLoading: false,
    approveRewardData: {},
    
    approvePointActivityLoading: false,
    approvePointActivityData: {},
};


export default function reducer(state = initialState, action) {
    switch (action.type) {

        case actions.GET_REWARD_POINTS:
            state = {
                ...state,
                rewardPointsLoading: false,
                rewardPoints: action.payload,
            }
            return state;


        case actions.GET_REWARD_POINTS_START:
            state = {
                ...state,
                rewardPointsLoading: true,
                rewardPoints: {},
            }
            return state;


        case actions.GET_REWARD_POINTS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                rewardPointsLoading: false,
                rewardPoints: action.payload,
            }
            return state;


        case actions.DOWNLOAD_REWARD_POINTS:
            state = {
                ...state,
                rewardPointsDownloadLoading: false,
                rewardPointsDownload: action.payload,
            }
            return state;


        case actions.DOWNLOAD_REWARD_POINTS_START:
            state = {
                ...state,
                rewardPointsDownloadLoading: true,
                rewardPointsDownload: {},
            }
            return state;


        case actions.DOWNLOAD_REWARD_POINTS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                rewardPointsDownloadLoading: false,
                rewardPointsDownload: action.payload,
            }
            return state;


        case actions.CREATE_REWARD_POINTS:
            state = {
                ...state,
                rewardPointsCreatedLoading: false,
                rewardPointsCreated: action.payload,
            }
            return state;


        case actions.CREATE_REWARD_POINTS_START:
            state = {
                ...state,
                rewardPointsCreatedLoading: true,
                rewardPointsCreated: {},
            }
            return state;


        case actions.CREATE_REWARD_POINTS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                rewardPointsCreatedLoading: false,
                rewardPointsCreated: action.payload,
            }
            return state;


        case actions.RESET_CREATE_REWARD_POINTS:
            state = {
                ...state,
                rewardPointsCreatedLoading: false,
                rewardPointsCreated: {},
            }
            return state;


        case actions.UPLOAD_PROMO_CODE:
            state = {
                ...state,
                promoCodeUploadLoading: false,
                promoCodeUpload: action.payload,
            }
            return state;


        case actions.UPLOAD_PROMO_CODE_START:
            state = {
                ...state,
                promoCodeUploadLoading: true,
                promoCodeUpload: {},
            }
            return state;


        case actions.UPLOAD_PROMO_CODE_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                promoCodeUploadLoading: false,
                promoCodeUpload: action.payload,
            }
            return state;


        case actions.UPLOAD_PROMO_CODE_RESET:
            state = {
                ...state,
                promoCodeUploadLoading: false,
                promoCodeUpload: {},
            }
            return state;


        case actions.UPDATE_REWARD_POINTS:
            state = {
                ...state,
                rewardPointsUpdatedLoading: false,
                rewardPointsUpdated: action.payload,
            }
            return state;


        case actions.UPDATE_REWARD_POINTS_START:
            state = {
                ...state,
                rewardPointsUpdatedLoading: true,
                rewardPointsUpdated: {},
            }
            return state;


        case actions.UPDATE_REWARD_POINTS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                rewardPointsUpdatedLoading: false,
                rewardPointsUpdated: action.payload,
            }
            return state;


        case actions.RESET_UPDATE_REWARD_POINTS:
            state = {
                ...state,
                rewardPointsUpdatedLoading: false,
                rewardPointsUpdated: {},
            }
            return state;


        case actions.ENABLE_REWARD_POINTS:
            toast.success("Reward point enabled");
            state = {
                ...state,
                rewardPointsEnabledLoading: false,
            }
            return state;


        case actions.ENABLE_REWARD_POINTS_START:
            toast("Enabling reward point");
            state = {
                ...state,
                rewardPointsEnabledLoading: true,
            }
            return state;


        case actions.ENABLE_REWARD_POINTS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                rewardPointsEnabledLoading: false,
            }
            return state;


        case actions.DISABLE_REWARD_POINTS:
            toast.success("Reward point diaabled");
            state = {
                ...state,
                rewardPointsDeletedLoading: false,
            }
            return state;


        case actions.DISABLE_REWARD_POINTS_START:
            toast("Disabling reward point");
            state = {
                ...state,
                rewardPointsDeletedLoading: true,
            }
            return state;


        case actions.DISABLE_REWARD_POINTS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                rewardPointsDeletedLoading: false,
            }
            return state;


        case actions.APPROVE_POINT_ACTIVITY_START:
            toast("Approving")
            state = {
                ...state,
                approvePointActivityLoading: true,
                approvePointActivityData: {},
            }
            return state;


        case actions.APPROVE_POINT_ACTIVITY_DONE:
            toast(action.payload.message)
            state = {
                ...state,
                approvePointActivityLoading: false,
                approvePointActivityData: action.payload,
            }
            return state;


        case actions.APPROVE_POINT_ACTIVITY_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                approvePointActivityLoading: false,
                approvePointActivityData: {},
            }
            return state;



        case actions.APPROVE_REWARD_START:
            toast("Approving")
            state = {
                ...state,
                approveRewardLoading: true,
                approveRewardData: {},
            }
            return state;


        case actions.APPROVE_REWARD_DONE:
            toast(action.payload.message)
            state = {
                ...state,
                approveRewardLoading: false,
                approveRewardData: action.payload,
            }
            return state;


        case actions.APPROVE_REWARD_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                approveRewardLoading: false,
                approveRewardData: {},
            }
            return state;


        case actions.DELETE_REWARD_POINTS:
            toast.success("Reward point deleted");
            state = {
                ...state,
                rewardPointsDeletedLoading: false,
            }
            return state;


        case actions.DELETE_REWARD_POINTS_START:
            toast("Deleting reward point");
            state = {
                ...state,
                rewardPointsDeletedLoading: true,
            }
            return state;


        case actions.DELETE_REWARD_POINTS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                rewardPointsDeletedLoading: false,
            }
            return state;


        case actions.ASSIGN_REWARD_POINTS:
            state = {
                ...state,
                rewardPointsAssignedLoading: false,
                rewardPointsAssigned: action.payload,
            }
            return state;


        case actions.ASSIGN_REWARD_POINTS_START:
            state = {
                ...state,
                rewardPointsAssignedLoading: true,
                rewardPointsAssigned: {},
            }
            return state;


        case actions.ASSIGN_REWARD_POINTS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                rewardPointsAssignedLoading: false,
                rewardPointsAssigned: action.payload,
            }
            return state;

        
        case actions.ASSIGN_REWARD_POINTS_RESET:
            state = {
                ...state,
                rewardPointsAssignedLoading: false,
                rewardPointsAssigned: {},
            }
            return state;


        case actions.GET_REWARDS:
            state = {
                ...state,
                rewardsLoading: false,
                rewards: action.payload,
            }
            return state;


        case actions.GET_REWARDS_START:
            state = {
                ...state,
                rewardsLoading: true,
                rewards: {},
            }
            return state;


        case actions.GET_REWARDS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                rewardsLoading: false,
                rewards: action.payload,
            }
            return state;


        case actions.DOWNLOAD_REWARDS:
            state = {
                ...state,
                rewardsDownloadLoading: false,
                rewardsDownload: action.payload,
            }
            return state;


        case actions.DOWNLOAD_REWARDS_START:
            state = {
                ...state,
                rewardsDownloadLoading: true,
                rewardsDownload: {},
            }
            return state;


        case actions.DOWNLOAD_REWARDS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                rewardsDownloadLoading: false,
                rewardsDownload: action.payload,
            }
            return state;


        case actions.CREATE_REWARD:
            state = {
                ...state,
                rewardCreatedLoading: false,
                rewardCreated: action.payload,
            }
            return state;


        case actions.CREATE_REWARD_START:
            state = {
                ...state,
                rewardCreatedLoading: true,
                rewardCreated: {},
            }
            return state;


        case actions.CREATE_REWARD_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                rewardCreatedLoading: false,
                rewardCreated: action.payload,
            }
            return state;


        case actions.CREATE_REWARD_RESET:
            state = {
                ...state,
                rewardCreatedLoading: false,
                rewardCreated: {},
            }
            return state;


        case actions.UPDATE_REWARD:
            state = {
                ...state,
                rewardUpdatedLoading: false,
                rewardUpdated: action.payload,
            }
            return state;


        case actions.UPDATE_REWARD_START:
            state = {
                ...state,
                rewardUpdatedLoading: true,
                rewardUpdated: {},
            }
            return state;


        case actions.UPDATE_REWARD_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                rewardUpdatedLoading: false,
                rewardUpdated: action.payload,
            }
            return state;


        case actions.UPDATE_REWARD_RESET:
            state = {
                ...state,
                rewardUpdatedLoading: false,
                rewardUpdated: {},
            }
            return state;


        case actions.ENABLE_REWARD:
            toast.success("Reward enabled");
            state = {
                ...state,
                rewardEnabledLoading: false,
            }
            return state;


        case actions.ENABLE_REWARD_START:
            toast("Enabling reward");
            state = {
                ...state,
                rewardEnabledLoading: true,
            }
            return state;


        case actions.ENABLE_REWARD_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                rewardEnabledLoading: false,
            }
            return state;


        case actions.DISABLE_REWARD:
            toast.success("Reward diaabled");
            state = {
                ...state,
                rewardDeletedLoading: false,
            }
            return state;


        case actions.DISABLE_REWARD_START:
            toast("Disabling reward");
            state = {
                ...state,
                rewardDeletedLoading: true,
            }
            return state;


        case actions.DISABLE_REWARD_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                rewardDeletedLoading: false,
            }
            return state;


        case actions.GET_REDEEMED_REWARDS:
            state = {
                ...state,
                redeemedRewardsLoading: false,
                redeemedRewards: action.payload,
            }
            return state;


        case actions.GET_REDEEMED_REWARDS_START:
            state = {
                ...state,
                redeemedRewardsLoading: true,
                redeemedRewards: {},
            }
            return state;


        case actions.GET_REDEEMED_REWARDS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                redeemedRewardsLoading: false,
                redeemedRewards: action.payload,
            }
            return state;


        case actions.DOWNLOAD_REDEEMED_REWARDS:
            state = {
                ...state,
                redeemedRewardsDownloadLoading: false,
                redeemedRewardsDownload: action.payload,
            }
            return state;


        case actions.DOWNLOAD_REDEEMED_REWARDS_START:
            state = {
                ...state,
                redeemedRewardsDownloadLoading: true,
                redeemedRewardsDownload: {},
            }
            return state;


        case actions.DOWNLOAD_REDEEMED_REWARDS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                redeemedRewardsDownloadLoading: false,
                redeemedRewardsDownload: action.payload,
            }
            return state;


        case actions.GET_REWARD_METRICS:
            state = {
                ...state,
                rewardMetricsLoading: false,
                rewardMetrics: action.payload,
            }
            return state;


        case actions.GET_REWARD_METRICS_START:
            state = {
                ...state,
                rewardMetricsLoading: true,
                rewardMetrics: {},
            }
            return state;


        case actions.GET_REWARD_METRICS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                rewardMetricsLoading: false,
                rewardMetrics: action.payload,
            }
            return state;


        case actions.GET_ALL_POINTS_HISTORY_START:
            state = {
                ...state,
                getPointsHistoryLoading: true,
                getPointsHistoryData: {},
            }
            return state;


        case actions.GET_ALL_POINTS_HISTORY_DONE:
            state = {
                ...state,
                getPointsHistoryLoading: false,
                getPointsHistoryData: action.payload,
            }
            return state;


        case actions.GET_ALL_POINTS_HISTORY_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                getPointsHistoryLoading: false,
                getPointsHistoryData: {},
            }
            return state;


        case actions.APPROVE_GIFT_POINT_START:
            toast("Approving")
            state = {
                ...state,
                approveGiftPointLoading: true,
            }
            return state;


        case actions.APPROVE_GIFT_POINT_DONE:
            toast(action.payload.message);
            state = {
                ...state,
                approveGiftPointLoading: false,
            }
            return state;


        case actions.APPROVE_GIFT_POINT_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                approveGiftPointLoading: false,
            }
            return state;


        case actions.REJECT_GIFT_POINT_START:
            toast("Rejecting")
            state = {
                ...state,
                rejectGiftPointLoading: true,
            }
            return state;


        case actions.REJECT_GIFT_POINT_DONE:
            toast(action.payload.message);
            state = {
                ...state,
                rejectGiftPointLoading: false,
            }
            return state;


        case actions.REJECT_GIFT_POINT_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                rejectGiftPointLoading: false,
            }
            return state;


        case actions.DOWNLOAD_POINTS_HISTORY_START:
            state = {
                ...state,
                downloadPointsHistoryLoading: true,
                downloadPointsHistoryData: {},
            }
            return state;


        case actions.DOWNLOAD_POINTS_HISTORY_DONE:
            const formattedResponse = {
                ...action.payload,
                data: {
                    ...action.payload?.data,
                    history: action.payload?.data?.history?.map((item) => (
                        {
                            user: `${item?.user?.firstName ?? "-"} ${item?.user?.lastName ?? "-"}`,
                            email: item.user?.email,
                            pointsAwarded: item?.pointsGifted,
                            reason: item?.reason,
                            status: item?.status,
                        }
                    ))
                },
                
            }
            state = {
                ...state,
                downloadPointsHistoryLoading: false,
                downloadPointsHistoryData: formattedResponse,
            }
            return state;


        case actions.DOWNLOAD_POINTS_HISTORY_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                downloadPointsHistoryLoading: false,
                downloadPointsHistoryData: {},
            }
            return state;

        default:
            return state;
    }
}