import * as actions from "./actionTypes";
import { toast } from 'react-toastify';

const initialState = {
    auditTrailLoading: false,
    auditTrail: {},
    downloadAuditTrailLoading: false,
    downloadAuditTrail: {},
    auditTrailActionsListLoading: false,
    auditTrailActionsList: {},
};


export default function reducer(state = initialState, action) {
    switch (action.type) {

        case actions.GET_AUDIT_TRAIL:
            state = {
                ...state,
                auditTrailLoading: false,
                auditTrail: { ...action.payload.data }
            }
            return state;


        case actions.GET_AUDIT_TRAIL_START:
            state = {
                ...state,
                auditTrailLoading: true,
                auditTrail: {},
            }
            return state;


        case actions.GET_AUDIT_TRAIL_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }

            state = {
                ...state,
                auditTrailLoading: false,
                auditTrail: {},
            }
            return state;


        case actions.DOWNLOAD_GET_AUDIT_TRAIL:
            state = {
                ...state,
                downloadAuditTrailLoading: false,
                downloadAuditTrail: { ...action.payload.data }
            }
            return state;


        case actions.DOWNLOAD_GET_AUDIT_TRAIL_START:
            toast("Downloading Data...");
            state = {
                ...state,
                downloadAuditTrailLoading: true,
                downloadAuditTrail: {},
            }
            return state;


        case actions.DOWNLOAD_GET_AUDIT_TRAIL_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }

            state = {
                ...state,
                downloadAuditTrailLoading: false,
                downloadAuditTrail: {},
            }
            return state;


        case actions.GET_AUDIT_TRAIL_ACTIONS:
            state = {
                ...state,
                auditTrailActionsListLoading: false,
                auditTrailActionsList: { ...action.payload }
            }
            return state;


        case actions.GET_AUDIT_TRAIL_ACTIONS_START:
            state = {
                ...state,
                auditTrailActionsListLoading: true,
                auditTrailActionsList: {},
            }
            return state;


        case actions.GET_AUDIT_TRAIL_ACTIONS_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }

            state = {
                ...state,
                auditTrailActionsListLoading: false,
                auditTrailActionsList: {},
            }
            return state;

    
        default:
            return state;
    }
}