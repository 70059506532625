import React from 'react';
import { Menu } from "@headlessui/react";
import isReactFragment from "utils/isReactFragment";
import { ReactComponent as IconNav } from "assets/images/icons/project-icons/Navicon-v.svg";


const DropdownNew = (props) => {
    return (
        <Menu as="div">
            <div className={"dropdownNew group inline-block " + props.className}>
                <Menu.Button as="div" className="cursor-pointer">
                    {(isReactFragment(props.children[0].type)) &&
                        <IconNav style={{ height: "26px" }} className="w-8 px-1.5 py-0.5 relative cursor-pointer group-hover:bg-gray-300 rounded" />
                    }

                    {!(isReactFragment(props.children[0].type)) &&
                        props.children[0]
                    }
                </Menu.Button>

                <Menu.Items as="div" className="dropdown z-[9999]">
                    <div className="dropdown-content">
                        <Menu.Item>
                            {(props.children) ? props.children[1] : ""}
                        </Menu.Item>
                    </div>
                </Menu.Items>
            </div>
        </Menu>
    );
};


export default DropdownNew;