import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import errorIcon from "assets/images/icons/error-icon-only.svg";
import * as auth from "store/auth/user/action";

class Error404 extends Component {
    state = {  }


    goBack = () => {
        this.props.history.goBack();
    }

    
    render() { 
        return (
            <div className="w-screen flex overflow-hidden">
                <div className="mx-auto px-8 mt-20 text-center max-w-md">

                    <div className="h-24 sm:h-32 md:h-56">
                        <img src={errorIcon} alt="EarniPay" className="h-full object-contain mx-auto" />
                    </div>
                    <div className="mt-8 font-boing font-semibold text-2xl sm:text-3xl">
                        401 - Unauthorized
                    </div>
                    <div className="mt-4 text-gray-500">
                        Sorry, you are not permitted to access this page.
                        {/* Please contact us if you feel this is an error from us. */}
                    </div>

                    <div className="mt-8 space-y-2">
                        <button type="button" onClick={this.goBack} className="btn btn-block btn-lg btn-ep-blue mx-auto">
                            Back to previous page
                        </button>
                        
                        {(this.props.loggedIn) &&
                            <button type="button" onClick={this.props.logout} className="btn btn-block btn-lg btn-transparent-black mx-auto">
                                Login as another user
                            </button>
                        }

                        {!(this.props.loggedIn) &&
                            <Link to="/login" className="btn btn-block btn-lg btn-transparent-black mx-auto">
                                Login to your account
                            </Link>
                        }

                    </div>

                </div>
            </div>
         );
    }
}



const mapStateToProps = (state) => ({
    loggedIn: state.auth.userPersist.loggedIn,
    user: state.auth.userPersist.data
});

const mapDispatchToProps = (dispatch) => ({
    logout: () => dispatch(auth.logoutUser())
});

export default connect(mapStateToProps, mapDispatchToProps)(Error404);