import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import CurrencyFormat from "./currencyFormat";
import { ReactComponent as IconNav } from "assets/images/icons/project-icons/Navicon-v.svg";
import { ReactComponent as Xclose } from "assets/images/icons/project-icons/xclose.svg";
import DateFormat, { DateTimeFormat } from "./dateFormat";
import { DateTimeMiniFormat } from "./dateFormat";
import { toast } from "react-toastify";

const useToolkit = () => {
	const dispatch = useDispatch();
	const {
		auth: {
			userPersist: { data: userObj },
		},
	} = useSelector((state) => state);

	const role = userObj?.userType;
	const isSuperAdmin = role === "super-admin";
	const isFinancialAdmin = role === "financial-admin";

	const Spinner = ({ text }) => (
		<div className="mx-auto my-10 flex items-center justify-center flex-col space-y-3">
			<FontAwesomeIcon icon="spinner" spin />
			<div className="font-bold uppercase">{text || "Loading"}</div>
		</div>
	);

	const dropdownTableIcon = (
		<IconNav className="w-8 h-8 p-1.5 relative top-1 cursor-pointer group-hover:bg-gray-200 rounded" />
	);

	<Xclose className="customizeSVG group-hover:text-white m-auto" />;

	const toastSuccess = (message) => toast.success(message);
	const toastInfo = (message) => toast.info(message);
	const toastError = (message) => toast.error(message);

	const copyText = (text, title) => {
		navigator.clipboard.writeText(text);
		toastSuccess(`${title} copied`);
	};

	const ErrorUI = () => (
		<div className="table-info">
			<FontAwesomeIcon icon="unlink" />
			<div className="font-bold uppercase">
				An error occurred
				<br />
				Please try again later.
			</div>
		</div>
	);

	const EmptyUI = ({text}) => (
		<div className="table-info w-full">
			<FontAwesomeIcon icon="list" />
			<div className="font-bold uppercase">{text  ||'No results found'}</div>
		</div>
	);

	const avail = (text) => text || 'N/A'

	return {
		useState,
		useEffect,
		Spinner,
		dispatch,
		useSelector,
		isSuperAdmin,
		isFinancialAdmin,
		moment,
		CurrencyFormat,
		dropdownTableIcon,
		Xclose,
		DateTimeFormat,
		DateTimeMiniFormat,
		toastSuccess,
		toastInfo,
		toastError,
		copyText,
		ErrorUI,
		EmptyUI,
		DateFormat,
		avail
	};
};

export default useToolkit;
