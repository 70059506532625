import React, { Component } from 'react';
import store from "store/store";
import Joi from "joi-browser";
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { connect } from "react-redux";
import { customizeSVG } from 'utils/customizeSVG.jsx';
import { TabSettingsWide, TabSettingsMobile } from 'components/common/settingsTab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as settings from "store/entities/settings/action";

class Form extends Component {

    state = {
        appForm: {
            androidVersion: "",
            androidForceUpdate: "",
            iOSVersion: "",
            iOSForceUpdate: "",
        },
        errors: {},
    }


    goBack = () => {
        this.props.history.goBack();
    }


    formChange = (e) => {
        const formValue = {...this.state.appForm};
        formValue[e.currentTarget.name] = e.currentTarget.value;
        this.setState({appForm: formValue});
    }


    schema = {
        androidVersion: Joi.string().required().label("Android Version").error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    case "any.empty":
                        err.message = "Please enter the android version";
                        break;
                    default:
                        break;
                }
            });
            return errors;
        }),
        androidForceUpdate: Joi.boolean().required().label("Android Force Update").error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    case "any.empty":
                        err.message = "Please enter the android force update";
                        break;
                    default:
                        break;
                }
            });
            return errors;
        }),
        iOSVersion: Joi.string().required().label("iOS Version").error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    case "any.empty":
                        err.message = "Please enter the iOS version";
                        break;
                    default:
                        break;
                }
            });
            return errors;
        }),
        iOSForceUpdate: Joi.boolean().required().label("iOS Force Update").error(errors => {
            errors.forEach(err => {
                switch (err.type) {
                    case "any.empty":
                        err.message = "Please enter the iOS force update";
                        break;
                    default:
                        break;
                }
            });
            return errors;
        }),
    }

    
    validate = () => {
        const options = { abortEarly: false };
        const result = Joi.validate(this.state.appForm, this.schema, options);
        // console.log(result);
        const error = result.error;
        if (!error) return null;
    
        const errors = {};
        for (let item of result.error.details){
            errors[item.path[0]] = item.message;
        }
        return errors;
    };


    submitForm = async e => {
        e.preventDefault();
        const errors = this.validate();
        this.setState({errors: errors || {}});
        if (errors){
            // console.log(errors);
            for (var x in errors) {
                toast.error(errors[x]);
            };
            return;
        }
        else{
            const { appForm } = this.state;
            const appFormSubmit = {
                "android": {
                    "version": appForm.androidVersion,
                    "forceUpdate": ((appForm.androidForceUpdate === "true") || appForm.androidForceUpdate === true),
                },
                "ios": {
                    "version": appForm.iOSVersion,
                    "forceUpdate": ((appForm.iOSForceUpdate === "true") || appForm.iOSForceUpdate === true),
                }
            };
            // console.log("appFormSubmit", appFormSubmit);
            this.props.updateMobileSettings(appFormSubmit);

            this.unsubscribe2 = store.subscribe(() => {
                if (this.props.mobileSettingsUpdateSuccess === true){
                    this.unsubscribe2();
                    this.props.history.push("/settings");
                    // this.goBack();
                }
            });
            this.setState({storeUnsubscribe2: this.unsubscribe2});
        }
    }



    componentDidMount() {
        this.props.getMobileSettings();

        this.unsubscribe1 = store.subscribe(() => {
            if ((this.props.mobileSettingsLoading === false) && (Object.keys(this.props.mobileSettings).length > 0)){
                this.unsubscribe1();
                const { mobileSettings } = this.props;
                // console.log("mobileSettings", mobileSettings);
                const appForm = {
                    androidVersion: mobileSettings.android.version,
                    androidForceUpdate: mobileSettings.android.forceUpdate,
                    iOSVersion: mobileSettings.ios.version,
                    iOSForceUpdate: mobileSettings.ios.forceUpdate,
                };
                this.setState({appForm});
            }
        });
        this.setState({storeUnsubscribe1: this.unsubscribe1});
    }


    componentWillUnmount(){
        const { storeUnsubscribe1, storeUnsubscribe2 } = this.state;
        if ((storeUnsubscribe1 !== "") && (storeUnsubscribe1 !== undefined) && (storeUnsubscribe1 !== null)){
            storeUnsubscribe1();
        }
        if ((storeUnsubscribe2 !== "") && (storeUnsubscribe2 !== undefined) && (storeUnsubscribe2 !== null)){
            storeUnsubscribe2();
        }
    }
    

    render() { 
        customizeSVG();
        const { mobileSettingsLoading, mobileSettings } = this.props;
        const {appForm} = this.state;
        // console.log(mobileSettings);

        return ( 
            
            <div>
                    
                <TabSettingsMobile />

                <div className="box">
                    <div className="flex">
                        <TabSettingsWide />

                        <div className="lg:w-3/4 xl:w-4/5 lg:pl-6 flex-grow">
                        
                            <div className="grid sm:grid-cols-2">
                                <div className="page-title">
                                    Edit App
                                </div>
                                <div className="flex justify-end">
                                    <Link to="/settings/app" className="btn btn-transparent-black btn-md">
                                        Back
                                    </Link>
                                </div>
                            </div>

                            <div className="pt-6 mt-6 border-t">
                                    
                                {(mobileSettingsLoading === true) && 
                                    <div className="table-info">
                                        <FontAwesomeIcon icon="spinner" spin />
                                        <div className="font-bold uppercase">
                                            Loading
                                        </div>
                                    </div>
                                }

                                {((mobileSettingsLoading === false) && (!mobileSettings)) && 
                                    <div className="table-info">
                                        <FontAwesomeIcon icon="unlink" />
                                        <div className="font-bold uppercase">
                                            An error occurred
                                            <br />
                                            Please try again later.
                                        </div>
                                    </div>
                                }
                                
                                {((mobileSettingsLoading === false) && (mobileSettings) && (Object.keys(mobileSettings).length) > 0) && 
                                    <form className="" onSubmit={this.submitForm}>
                                        <div className="">
                                        
                                            <div className="">
                                                <div className="font-bold text-gray-600">
                                                    Android App
                                                </div>

                                                <div className="mt-6 sm:grid sm:grid-cols-2 sm:gap-x-6">
                                                    
                                                    <div className="form-group">
                                                        <label>
                                                            App Version
                                                            <span className="form-input-required">*</span>
                                                        </label>
                                                        <input type="text" name="androidVersion" placeholder="Enter the android version" className="form-input" value={appForm.androidVersion} onChange={this.formChange} />
                                                    </div>

                                                    <div className="form-group">
                                                        <label>
                                                            Force Update
                                                            <span className="form-input-required">*</span>
                                                        </label>
                                                        <select name="androidForceUpdate" id="androidForceUpdate" className="form-input" value={appForm.androidForceUpdate} onChange={this.formChange}>
                                                            <option value="">Select</option>
                                                            <option value={true}>Allow</option>
                                                            <option value={false}>Disallow</option>
                                                        </select>
                                                    </div>

                                                </div>
                                            </div>

                                            <div className="mt-5">
                                                <div className="font-bold text-gray-600">
                                                    iOS App
                                                </div>

                                                <div className="mt-6 sm:grid sm:grid-cols-2 sm:gap-x-6">
                                                    
                                                    <div className="form-group">
                                                        <label>
                                                            App Version
                                                            <span className="form-input-required">*</span>
                                                        </label>
                                                        <input type="text" name="iOSVersion" placeholder="Enter the iOS version" className="form-input" value={appForm.iOSVersion} onChange={this.formChange} />
                                                    </div>

                                                    <div className="form-group">
                                                        <label>
                                                            Force Update
                                                            <span className="form-input-required">*</span>
                                                        </label>
                                                        <select name="iOSForceUpdate" id="iOSForceUpdate" className="form-input" value={appForm.iOSForceUpdate} onChange={this.formChange}>
                                                            <option value="">Select</option>
                                                            <option value={true}>Allow</option>
                                                            <option value={false}>Disallow</option>
                                                        </select>
                                                    </div>

                                                </div>
                                            </div>

                                            <div className="mt-5">
                                                <button type="submit" className="btn btn-lg btn-ep-blue mt-2" disabled={this.props.mobileSettingsUpdateLoading}>
                                                    <FontAwesomeIcon icon="spinner" spin className={"text-xl mr-2 " + (this.props.mobileSettingsUpdateLoading?"inline":"hidden")} />
                                                    Save Changes
                                                </button>
                                            </div>

                                        </div>
                                    </form>
                                }
                            
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => ({
    mobileSettingsLoading: state.entities.settings.mobileSettingsLoading,
    mobileSettings: state.entities.settings.mobileSettings,
    mobileSettingsUpdateLoading: state.entities.settings.mobileSettingsUpdateLoading,
    mobileSettingsUpdateSuccess: state.entities.settings.mobileSettingsUpdateSuccess,
    reloadPage: state.entities.settings.reloadPage,
});

const mapDispatchToProps = (dispatch) => ({
    getMobileSettings: (params) => dispatch(settings.getMobileSettings(params)),
    updateMobileSettings: (params) => dispatch(settings.updateMobileSettings(params)),
    resetReloadPage: () => dispatch(settings.resetReloadPage()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Form);
