import ObjectToQueryString from "utils/ObjectToQueryString";
import * as actions from "./actionTypes";
import * as config from "config";

export function downloadAllSubAccounts(params) {
    let endpointUrl = config.apiGetAllSubAccounts;
    if (Object.keys(params).length > 2){
        endpointUrl = config.apiSearchFilterSubAccounts
    }

    return {
        type: config.apiRequestStart,
        payload: {
            url: endpointUrl + ObjectToQueryString(params),
            method: "get",
            data: {},
            onStart: actions.DOWNLOAD_SUB_ACCOUNTS_START,
            onSuccess: actions.DOWNLOAD_SUB_ACCOUNTS_DONE,
            onError: actions.DOWNLOAD_SUB_ACCOUNTS_FAILED,
        },
    }
}

export function getAllSubAccounts(params) {
    let endpointUrl = config.apiGetAllSubAccounts;
    if (Object.keys(params).length > 2){
        endpointUrl = config.apiSearchFilterSubAccounts
    }

    return {
        type: config.apiRequestStart,
        payload: {
            url: endpointUrl + ObjectToQueryString(params),
            method: "get",
            data: {},
            onStart: actions.GET_ALL_SUB_ACCOUNTS_START,
            onSuccess: actions.GET_ALL_SUB_ACCOUNTS_DONE,
            onError: actions.GET_ALL_SUB_ACCOUNTS_FAILED,
        },
    }
}

export function getSingleSubAccount(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetAllSubAccounts + ObjectToQueryString(params),
            method: "get",
            data: {},
            onStart: actions.GET_ALL_SUB_ACCOUNTS_START,
            onSuccess: actions.GET_ALL_SUB_ACCOUNTS_DONE,
            onError: actions.GET_ALL_SUB_ACCOUNTS_FAILED,
        },
    }
}

export function getSubAccountMetrics() {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetSubAccountMetrics,
            method: "get",
            data: {},
            onStart: actions.GET_SUB_ACCOUNT_METRICS_START,
            onSuccess: actions.GET_SUB_ACCOUNT_METRICS_DONE,
            onError: actions.GET_SUB_ACCOUNT_METRICS_FAILED,
        },
    }
}