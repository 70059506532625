import { useEffect } from "react";
import { ReactComponent as IconCoin } from "assets/images/icons/project-icons/coin.svg";
import { ReactComponent as IconRewards } from "assets/images/icons/project-icons/rewards.svg";
import { ReactComponent as IconBadgeCheck } from "assets/images/icons/project-icons/Badge-check.svg";import DashboardBox from "../common/DashboardBox";
import { useDispatch, useSelector } from "react-redux";
import NumberFormat from "utils/numberFormat";
import * as rewardsActions from "store/entities/rewards/action";
import pluralize from "utils/pluralize";


const RewardsDashboard = () => {
    const dispatch = useDispatch();
    const { rewardMetricsLoading, rewardMetrics } = useSelector((s) => s.entities.rewards);


    const getRewardMetrics = () => {
        if(!rewardMetricsLoading && (Object.keys(rewardMetrics).length === 0 || rewardMetrics.status !== "success")){
            dispatch(rewardsActions.getRewardMetrics());
        }
    }


    useEffect(() => {
        getRewardMetrics();
        // eslint-disable-next-line
    }, []);


    return (
        <div className="mt-6">
            <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-6">
                <DashboardBox
                    title="Total Points Earned"
                    icon={<IconCoin className="w-5 h-5" />}
                    iconBackground="bg-[#E5FDFF]"
                    loading={rewardMetricsLoading}
                    value={NumberFormat(rewardMetrics?.data?.pointsEarned ?? "-")}
                />
                <DashboardBox
                    title="Total Rewards Value"
                    icon={<IconRewards className="svg-fill-black w-5 h-5" />}
                    iconBackground="bg-[#FEF3BF]"
                    loading={rewardMetricsLoading}
                    value={<>
                        {rewardMetrics?.data?.totalRewards &&
                            <>
                                {NumberFormat(rewardMetrics?.data?.totalRewards)}
                                <span className="text-xs font-normal ml-0.5">
                                    {pluralize("point", rewardMetrics?.data?.totalRewards ?? 0, "s")}
                                </span>
                            </>
                        }
                        {!rewardMetrics?.data?.totalRewards && <>-</>}
                    </>}
                />
                <DashboardBox
                    title="Total Redeemed Rewards Value"
                    icon={<IconBadgeCheck className="svg-fill-primary w-5 h-5" />}
                    iconBackground="bg-[#CFFFE3]"
                    loading={rewardMetricsLoading}
                    value={<>
                        {rewardMetrics?.data?.totalRedeemedRewards &&
                            <>
                                {NumberFormat(rewardMetrics?.data?.totalRedeemedRewards)}
                                <span className="text-xs font-normal ml-0.5">
                                    {pluralize("point", rewardMetrics?.data?.totalRedeemedRewards ?? 0, "s")}
                                </span>
                            </>
                        }
                        {!rewardMetrics?.data?.totalRedeemedRewards && <>-</>}
                    </>}
                />
            </div>
        </div>
    )
}


export default RewardsDashboard;