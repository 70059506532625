import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ButtonLoader } from "../common/form";
import FormDrawer from "../common/form-drawer--new";
import { toggleLoanLimitUpdate } from "store/entities/credit/action";
import Maybe from "../common/Maybe";
import CurrencyFormat from "utils/currencyFormat";
import { ReactComponent as IconCircleQuestion } from "assets/images/icons/project-icons/circle-question.svg";


const LoanLimitUpdateRequest = ({user, refetch}) => {
	
	const dispatch = useDispatch();
	const [currentView, setCurrentView] = useState("request");
	const [updateAmount, setUpdateAmount] = useState("");

	const { requestLimitUpdateLoading: loading, isLimitUpdateRequestOpen } = useSelector((state) => state.entities.credit);

	const toggleFn = () => dispatch(toggleLoanLimitUpdate());

	
	const onProceed = () => { 
		if(currentView === "request") {
			setCurrentView("confirm")
		} else if(currentView === "confirm") {
			// make call to send loan for limit update
		}
	}

	
	const onCancel = () => { 
		if(currentView === "request") {
			toggleFn();
		} else if(currentView === "confirm") {
			setCurrentView("request")
		}
	}
	
	return (
		<FormDrawer display="center" size="md" isOpen={isLimitUpdateRequestOpen} setIsOpen={() => {}}>

			<div className="p-5 max-w-md">
				<Maybe condition={currentView === "request"}>
						<h1 className="lg:text-lg font-semibold">Request Loan Limit Update</h1>

						<div className="font-medium text-sm my-6">
							<div className="text-gray-600">
								Current Loan Limit: &nbsp;
							</div>

							<div>
								{CurrencyFormat(500000)}
							</div>
						</div>
						
						<div className="w-full mr-3 my-5">
							<input className="form-input" id="number-input" type="number" label="New Loan Limit" placeholder="₦0.00" value={updateAmount || ""} onChange={(e) => setUpdateAmount(e.target.value)} />
						</div>
				</Maybe>

				<Maybe condition={currentView === "confirm"}>
					<div className="flex items-center justify-center flex-col pb-8">
						<div className="text-center">
							<IconCircleQuestion />
						</div>
						
						<h1 className="mt-5 mb-2 font-semibold">
							Request Loan Limit Update
						</h1>
						
						<p className="mb-0 text-center">Are you sure you want to request loan limit update?</p>
					</div>
				</Maybe>


				<div className="flex items-center justify-center lg:flex-row pb-2 pt-4">
					<button
						onClick={onCancel}
						className="btn btn-md border border-ep-primary-500 text-ep-primary-500 focus:ring-0 w-full lg:w-2/4 mr-4"
						disabled={loading}>
						Cancel
					</button>
					
					<ButtonLoader
						disabled={currentView === "request" && !updateAmount?.length}
						onClick={onProceed}
						className="z-5 btn btn-md border bg-ep-primary text-white focus:ring-0 w-full lg:w-2/4">
						{currentView === "request" ? "Continue" : "Yes"}
					</ButtonLoader>
				</div>
			</div>

		</FormDrawer>
	)
}

export default LoanLimitUpdateRequest