import Maybe from "../Maybe";

export function SelectInput(props) {
  const { value, label, required, defaultValue, readonly, disabled, defaultProps, onChange } = props;

  return (
    <div className="form-group">
      <label>
          {label || ""}
          <Maybe condition={required === true}>
              <span className="form-input-required">*</span>
          </Maybe>
      </label>
      <select
        className={"form-input " + ((readonly || disabled) === true && "disabled")}
        value={value}
        // readOnly={readonly || disabled}
        disabled={readonly || disabled}
        defaultValue={defaultValue}
        onChange={onChange}
        {...defaultProps }
      >
        {props.children}
      </select>
    </div>
  );
}

export default SelectInput;
