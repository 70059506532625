import errorReducer from "utils/errorReducer";
import * as actions from "./actionTypes";

const initialState = {
    allSubAccountsLoading: false,
    allSubAccountsData: {},

    singleSubAccountLoading: false,
    singleSubAccountData: {},

    subAccountMetricsLoading: false,
    subAccountsMetricsData: {},

    selectedSubAccount: null,

    downloadSubAccountsLoading: false,
    downloadSubAccountsdata: null,
};


export default function reducer(state = initialState, action) {
    switch (action.type) {

        case actions.GET_ALL_SUB_ACCOUNTS_START:
            state = {
                ...state,
                allSubAccountsLoading: true,
                allSubAccountsData: {},
            }
            return state;

        case actions.GET_ALL_SUB_ACCOUNTS_DONE:
            state = {
                ...state,
                allSubAccountsLoading: false,
                allSubAccountsData: action.payload,
            }
            return state;

        case actions.GET_ALL_SUB_ACCOUNTS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                allSubAccountsLoading: false,
                allSubAccountsData: {},
            }
            return state;

        case actions.GET_SINGLE_SUB_ACCOUNT_START:
            state = {
                ...state,
                singleSubAccountLoading: true,
                singleSubAccountData: {},
            }
            return state;

        case actions.GET_SINGLE_SUB_ACCOUNT_DONE:
            state = {
                ...state,
                singleSubAccountLoading: false,
                singleSubAccountData: action.payload,
            }
            return state;

        case actions.GET_SINGLE_SUB_ACCOUNT_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                singleSubAccountLoading: false,
                singleSubAccountData: {},
            }
            return state;

        case actions.GET_SUB_ACCOUNT_METRICS_START:
            state = {
                ...state,
                subAccountMetricsLoading: true,
                subAccountsMetricsData: {},
            }
            return state;

        case actions.GET_SUB_ACCOUNT_METRICS_DONE:
            state = {
                ...state,
                subAccountMetricsLoading: false,
                subAccountsMetricsData: action.payload?.data,
            }
            return state;

        case actions.GET_SUB_ACCOUNT_METRICS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                subAccountMetricsLoading: false,
                subAccountsMetricsData: {},
            }
            return state;

        case actions.DOWNLOAD_SUB_ACCOUNTS_START:
            state = {
                ...state,
                downloadSubAccountsLoading: true,
                downloadSubAccountsData: {},
            }
            return state;

        case actions.DOWNLOAD_SUB_ACCOUNTS_DONE:
            state = {
                ...state,
                downloadSubAccountsLoading: false,
                downloadSubAccountsData: action.payload,
            }
            return state;

        case actions.DOWNLOAD_SUB_ACCOUNTS_FAILED:
            errorReducer(action.payload);
            state = {
                ...state,
                downloadSubAccountsLoading: false,
                downloadSubAccountsData: {},
            }
            return state;

        case actions.SELECT_SUB_ACCOUNT:
            state = {
                ...state,
                selectedSubAccount: action.payload,
            }
            return state;

        default:
            return state;
    }
}