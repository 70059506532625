import React, { Component } from "react";
import queryString from "query-string";
import DashboardBoxes from "components/common/dashboard-boxes";
import CurrencyFormat from "utils/currencyFormat";
import Pagination from "components/common/pagination";
import Dropdown from "utils/dropdown";
// import moment from "moment";
import { DateTimeFormat } from "utils/dateFormat";
import { Link } from "react-router-dom";
import Download from "components/common/download";
import SearchFilter from "components/common/filter";
import { connect } from "react-redux";
import { customizeSVG } from "utils/customizeSVG.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactComponent as IconNav } from "assets/images/icons/project-icons/Navicon-v.svg";
import { ReactComponent as IconWallet } from "assets/images/icons/project-icons/Wallet-Solid.svg";
import { ReactComponent as IconStack } from "assets/images/icons/project-icons/Stack.svg";
// import { ReactComponent as IconMoney } from "assets/images/icons/project-icons/Money.svg";
import { ReactComponent as Xclose } from "assets/images/icons/project-icons/xclose.svg";
import * as withdrawals from "store/entities/withdrawals/action";
import TransactionsPageRouter from "components/__new/common/TransactionsPageRouter";

const dropdownTableIcon = (
	<IconNav className="w-8 h-8 p-1.5 relative top-1 cursor-pointer group-hover:bg-gray-200 rounded" />
);

const columns = [
	// { label: "Id", key: "_id" },
	{ label: "Date Created", key: "createdAt" },
	{ label: "Wallet ID", key: "walletId.walletId" },
	{ label: "Firstname", key: "walletId.firstName" },
	{ label: "Lastname", key: "walletId.lastName" },
	{ label: "Email Address", key: "walletId.email" },
	// { label: "Company Name", key: "companyName" },
	{ label: "Previous Balance", key: "previousBalance" },
	{ label: "Amount", key: "amount" },
	{ label: "Type", key: "type" },
	{ label: "Charge", key: "charge" },
	{ label: "Current Balance", key: "newBalance" },
	{ label: "Revenue", key: "revenue" },
	{ label: "Source Type", key: "sourceType" },
	{ label: "Product", key: "product" },
	{ label: "Target", key: "target" },
	{ label: "Reference", key: "reference" },
	{ label: "Transaction Reference", key: "transactionRef" },
	{ label: "Description", key: "description" },
	{ label: "Session ID", key: "sessionId" },
	{ label: "Status", key: "status" },
];

class List extends Component {
	constructor(props) {
		super(props);
		this.csvLinkEl = React.createRef();
		this.completeButtonRef = React.createRef(null);
	}

	state = {
		getWalletParams: {
			page: 1,
			limit: 20,
			status: "",
		},
		dashboardProps1: {
			iconBg: "bg-green-200",
			icon: (
				<IconWallet className="customizeSVG text-green-600 m-auto w-7 h-7" />
			),
			titleText: "Total Wallet Balance",
			mainText: "-",
		},
		dashboardProps2: {
			iconBg: "bg-purple-200",
			icon: (
				<IconStack className="customizeSVG text-purple-600 m-auto w-7 h-7" />
			),
			titleText: "Wallet Transactions Count",
			mainText: "-",
		},
		// dashboardProps5: {
		// 	iconBg: "bg-blue-200",
		// 	icon: <IconMoney className="customizeSVG text-blue-600 m-auto w-7 h-7" />,
		// 	titleText: "Total Savings",
		// 	mainText: "-",
		// },
		dataDownload: [],
		toggleModalDetails: false,
		walletDetails: "",
	};

	formChange = (e) => {
		const formValue = { ...this.state.filter };
		formValue[e.currentTarget.name] = e.currentTarget.value;
		this.setState({ filter: formValue });
	};

	getWalletSummary = () => {
		this.props.getWalletSummary();
	};

	getWalletTransactions = () => {
		const qs = queryString.parse(this.props.location.search);
		let getWalletParams = { ...this.state.getWalletParams };

		if (qs.page !== undefined && !isNaN(qs.page) && qs.page > 0) {
			getWalletParams = { ...getWalletParams, page: qs.page };
		} else {
			getWalletParams = { ...getWalletParams, page: 1 };
		}

		if (qs.limit !== undefined && !isNaN(qs.limit) && qs.limit > 0) {
			getWalletParams = { ...getWalletParams, limit: qs.limit };
		} else {
			getWalletParams = { ...getWalletParams, limit: 20 };
		}

		if (qs.status !== undefined && qs.status !== null) {
			getWalletParams = { ...getWalletParams, status: qs.status };
		} else {
			getWalletParams = { ...getWalletParams, status: "" };
		}

		if (qs.startDate !== undefined && qs.startDate !== null) {
			getWalletParams = { ...getWalletParams, startDate: qs.startDate };
		} else {
			// const initialDate = moment().startOf("month").format("YYYY-MM-DD");
			// this.gotoUrlQuery({ startDate: initialDate });
			getWalletParams = { ...getWalletParams, startDate: "" };
		}

		if (qs.endDate !== undefined && qs.endDate !== null) {
			getWalletParams = { ...getWalletParams, endDate: qs.endDate };
		} else {
			getWalletParams = { ...getWalletParams, endDate: "" };
		}

		if(qs?.search?.length > 0) {
			getWalletParams = { ...getWalletParams, search: qs.search };
		} else {
			getWalletParams = { ...getWalletParams, search: "" };
		}

		this.setState({ getWalletParams });
		this.props.getWalletTransactions(getWalletParams);
	};

	amountColor = (amount, type) => {
		if (type === "credit") {
			return <span className="text-green-500">{CurrencyFormat(amount)}</span>;
		} else if (type === "debit") {
			return <span className="text-red-500">{CurrencyFormat(amount)}</span>;
		} else {
			return <span>{CurrencyFormat(amount)}</span>;
		}
	};

	displayStatus = (status, type = "label") => {
		const allStatus = {
			successful: { label: "label-green", text: "success" },
			pending: { label: "label-yellow" },
			failed: { label: "label-red" },
		};
		if (status in allStatus) {
			if (type === "label") {
				return allStatus[status].label;
			} else if (type === "text") {
				return allStatus[status].text ? allStatus[status].text : status;
			}
		}
		return "";
	};

	viewDetails = (wallet = {}) => {
		this.setState({ toggleModalDetails: true, walletDetails: wallet });
	};

	closeModal = (action) => {
		this.setState({ toggleModalDetails: action });
	};

	gotoUrlQuery = (params) => {
		const qs = queryString.parse(this.props.location.search);

		Object.keys(params).forEach((paramName) => {
			// console.log("qs", qs[paramName]);
			delete qs[paramName];
		});

		let qsToUrl = new URLSearchParams(qs).toString();
		qsToUrl =
			this.props.location.pathname +
			"?" +
			(qsToUrl !== "" ? qsToUrl + "&" : "");

		Object.keys(params).forEach((paramName) => {
			if (params[paramName] !== "") {
				qsToUrl += paramName + "=" + params[paramName] + "&";
			}
		});

		qsToUrl = qsToUrl.substring(0, qsToUrl.length - 1);
		this.props.history.push(qsToUrl);
	};

	transformDataDownload = (data) => {
        if (data?.docs && data?.docs?.length > 0){
            const transformedData = data?.docs?.map((row) => ({
				...row,
				previousBalance: CurrencyFormat(row?.previousBalance/100, "", "none"),
				amount: CurrencyFormat(row?.amount/100, "", "none"),
				charge: CurrencyFormat(row?.charge/100, "", "none"),
				newBalance: CurrencyFormat(row?.newBalance/100, "", "none"),
				revenue: CurrencyFormat(row?.revenue/100, "", "none"),
			}))
            return transformedData;
        }
        else{
            return [];
        }
    }

	componentDidMount() {
		this.getWalletSummary();
		this.getWalletTransactions();
	}

	componentDidUpdate(prevProps) {
		if (this.props.location !== prevProps.location) {
			this.getWalletTransactions();
		}
	}

	render() {
		customizeSVG();
		const {
			walletTransactions: data,
			walletSummaryLoading,
			walletSummary,
		} = this.props;
		const {
			getWalletParams,
			dashboardProps1,
			dashboardProps2,
			// dashboardProps5,
			toggleModalDetails,
			walletDetails,
		} = this.state;

		return (
			<div>
				<div
					className={
						"max-w-md w-screen h-screen bg-white fixed z-50 top-0 bottom-0 shadow-xl transition-all duration-300 ease-in-out " +
						(toggleModalDetails ? "right-0" : "-right-full")
					}>
					<div className="w-full h-full p-5 pb-0 relative overflow-auto overscroll-contain">
						{Object.keys(walletDetails).length > 0 && (
							<div>
								<div>
									<div className="flex justify-between">
										<div className="font-recoleta font-semibold text-2xl">
											Transaction Details
										</div>
										<div
											onClick={() => this.closeModal(false)}
											className="w-8 h-8 flex group hover:bg-red-400 rounded-lg cursor-pointer">
											<Xclose className="customizeSVG group-hover:text-white m-auto" />
										</div>
									</div>
									<div className="mt-1 text-sm flex space-x-2">
										{/* 
                                        {walletDetails.status === "success" &&
                                            <div className="bg-green-500 w-2 h-2 rounded-full"></div>
                                        }
                                        {walletDetails.status === "failed" &&
                                            <div className="bg-red-500 w-2 h-2 rounded-full"></div>
                                        }
                                         */}
										<div className="capitalize">
											{walletDetails.product} &nbsp;•&nbsp;&nbsp;
											<span className="text-fade hidden">
												Target: {walletDetails.target}
											</span>
										</div>
									</div>
								</div>

								<div className="w-full h-px mt-8 bg-gray-200"></div>

								<div className="my-8">
									<div className="font-semibold text-sm">Details</div>

									<div className="mt-5 grid grid-cols-2 gap-4">
										<div className="my-auto">
											<div className="font-semibold text-xs">Amount</div>
											<div className="mt-1 font-medium text-xs">
												{this.amountColor(walletDetails.amount / 100, walletDetails.type)}
											</div>
										</div>

										<div className="my-auto">
											<div className="font-semibold text-xs">Description</div>
											<div className="mt-1 font-medium text-xs">
												{walletDetails.description ?? "-"}
											</div>
										</div>

										<div className="w-full my-auto">
											<div className="font-semibold text-xs">
												Previous Balance
											</div>
											<div className="mt-1 font-medium text-xs break-words">
												{CurrencyFormat(walletDetails.previousBalance / 100)}
											</div>
										</div>

										<div className="my-auto">
											<div className="font-semibold text-xs">Status</div>
											<div className="mt-1 font-medium text-xs">
												<div className={"label " + this.displayStatus(walletDetails.status)}>
													{this.displayStatus(walletDetails.status, "text")}
												</div>
											</div>
										</div>

										<div className="w-full my-auto">
											<div className="font-semibold text-xs">
												Current Balance
											</div>
											<div className="mt-1 font-medium text-xs break-words">
												{CurrencyFormat(walletDetails.newBalance / 100) ?? "-"}
											</div>
										</div>

										<div className="w-full my-auto">
											<div className="font-semibold text-xs">Source Type</div>
											<div className="mt-1 font-medium text-xs break-words">
												{walletDetails.sourceType ?? "-"}
											</div>
										</div>

										<div className="w-full my-auto">
											<div className="font-semibold text-xs">Revenue</div>
											<div className="mt-1 font-medium text-xs break-words">
												{CurrencyFormat(walletDetails.revenue / 100) ?? "-"}
											</div>
										</div>

										<div className="my-auto">
											<div className="font-semibold text-xs">
												Transaction Reference
											</div>
											<div className="mt-1 font-medium text-xs">
												{walletDetails.transactionRef ?? "-"}
											</div>
										</div>

										<div className="w-full my-auto">
											<div className="font-semibold text-xs">Date</div>
											<div className="mt-1 font-medium text-xs break-words">
												{DateTimeFormat(walletDetails.createdAt) ?? "-"}
											</div>
										</div>

										<div className="w-full my-auto">
											<div className="font-semibold text-xs">
												Source Reference
											</div>
											<div className="mt-1 font-medium text-xs break-words">
												{walletDetails.reference ?? "-"}
											</div>
										</div>

										<div className="w-full my-auto">
											<div className="font-semibold text-xs">Product</div>
											<div className="mt-1 font-medium text-xs break-words">
												{walletDetails.product ?? "-"}
											</div>
										</div>
									
										<div className="w-full my-auto">
											<div className="font-semibold text-xs">Session ID</div>
											<div className="mt-1 font-medium text-xs break-words">
												{walletDetails.sessionId ?? "-"}
											</div>
										</div>
									</div>

									<div className="w-full h-px mt-8 bg-gray-200"></div>
									<div className="mt-6 font-semibold text-sm">Sent From</div>

									<div className="mt-5 grid grid-cols-2 gap-x-4">
										<div className="space-y-5">
											<div className="my-auto">
												<div className="font-semibold text-xs">Wallet ID</div>
												<div className="mt-1 font-medium text-xs">
													{walletDetails?.walletId?.walletId ?? "-"}
												</div>
											</div>

											<div className="w-full my-auto">
												<div className="font-semibold text-xs">Fullname</div>
												<div className="mt-1 font-medium text-xs break-words capitalize">
													{walletDetails?.walletId?.firstName ?? "-"} {walletDetails?.walletId?.lastName}
												</div>
											</div>
										</div>
										<div className="space-y-5">
											<div className="w-full my-auto">
												<div className="font-semibold text-xs">
													Email address
												</div>
												<div className="mt-1 font-medium text-xs break-words">
													{walletDetails?.walletId?.email ?? "-"}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>

				<div>
					<TransactionsPageRouter />

					<div className="mt-6 lg:flex">
						<div className="w-full lg:w-1/2">
							<div className="page-title">Wallets</div>
						</div>
						<div className="w-full lg:w-1/2 flex space-x-4 mt-2 lg:mt-0 lg:justify-end">
							<div>
								<Download
									tooltip="Download wallet list"
									format="csv"
									filename="All Wallet Transactions.csv"
									columns={columns}
									ref={(instance) => {
										this.Download = instance;
									}}
									click={() => {
										this.props
											.downloadWalletTransactions({
												...getWalletParams,
												page: 1,
												limit: 10000,
												download: true
											})
											.then(() => {
												this.Download.processDownload();
											});
									}}
									loading={this.props.walletTransactionsDownloadLoading}
									response={this.transformDataDownload(this.props.walletTransactionsDownload)}
								/>
							</div>

							<div>
								<SearchFilter
									search={{
										name: "search",
										placeholder: "Search id, name, email",
									}}
									filters={[
										{
											title: "Status",
											name: "status",
											dataType: "radio",
											options: [
												{
													display: "All",
													payload: "",
												},
												{
													display: "Successful",
													payload: "successful",
												},
												{
													display: "Pending",
													payload: "pending",
												},
												{
													display: "Failed",
													payload: "failed",
												},
											],
										},
										{
											title: "Date",
											dataType: "date-range",
											options: {
												from: {
													name: "startDate",
													placeholder: "Start Date",
												},
												to: {
													name: "endDate",
													placeholder: "End Date",
												},
											},
										},
									]}
								/>
							</div>
						</div>
					</div>

					<div className="mt-6">
						<div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-6">
							<DashboardBoxes
								data={dashboardProps1}
								loading={walletSummaryLoading}
								result={CurrencyFormat(
									walletSummary.balance > 0 ? walletSummary.balance / 100 : 0
								)}
							/>
							<DashboardBoxes
								data={dashboardProps2}
								loading={walletSummaryLoading}
								result={walletSummary.transactionCount}
							/>
							{/* 
							<DashboardBoxes
								data={dashboardProps5}
								loading={walletSummaryLoading}
								result={CurrencyFormat(
									walletSummary.savings > 0 ? walletSummary.savings / 100 : 0
								)}
							/>
							 */}
						</div>
					</div>

					<div className="w-full lg:flex mt-6 mb-4 sm:mb-0">
						<div className="w-full lg:w-1/2 flex">
							<div className="page-title-mini my-auto">Wallet History</div>
						</div>
						<div className="w-full lg:w-1/2 lg:flex lg:justify-end mt-1 lg:mt-0">
							<div className="w-full lg:w-auto inline-block box-border-only overflow-auto">
								<div className="h-10 flex items-center">
									<div onClick={() => this.gotoUrlQuery({ status: "", page: 1 })} className={"cursor-pointer page-nav " + (getWalletParams.status === "" ? "active" : "")}>
										<div className="whitespace-nowrap">All</div>
									</div>
									<div onClick={() => this.gotoUrlQuery({ status: "successful", page: 1 })} className={"cursor-pointer page-nav " + (getWalletParams.status === "paid" ? "active" : "")}>
										<div className="whitespace-nowrap">Completed</div>
									</div>
									<div onClick={() => this.gotoUrlQuery({ status: "pending", page: 1 })} className={"cursor-pointer page-nav " + (getWalletParams.status === "pending" ? "active" : "")}>
										<div className="whitespace-nowrap">Pending</div>
									</div>
									<div onClick={() => this.gotoUrlQuery({ status: "failed", page: 1 })} className={"cursor-pointer page-nav " + (getWalletParams.status === "failed" ? "active" : "")}>
										<div className="whitespace-nowrap">Failed</div>
									</div>
									<div onClick={() => this.gotoUrlQuery({ status: "cancelled", page: 1 })} className={"cursor-pointer page-nav " + (getWalletParams.status === "cancelled" ? "active" : "")}>
										<div className="whitespace-nowrap">Cancelled</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className="mt-3">
						<div className="">
							<div className="table-container">
								<table className="table table-auto table-rounded table-border table-align-top">
									<thead>
										<tr>
											<th>Date</th>
											<th>Wallet</th>
											<th>Previous Bal.</th>
											<th>Amount</th>
											<th>Current Bal.</th>
											<th>Revenue</th>
											<th>Description</th>
											<th>Status</th>
											<th>Actions</th>
										</tr>
									</thead>
									<tbody>
										{this.props.walletTransactionsLoading === true && (
											<tr>
												<td colSpan="9">
													<div className="table-info">
														<FontAwesomeIcon icon="spinner" spin />
														<div className="font-bold uppercase">Loading</div>
													</div>
												</td>
											</tr>
										)}

										{this.props.walletTransactionsLoading === false &&
											!data && (
												<tr>
													<td colSpan="9">
														<div className="table-info">
															<FontAwesomeIcon icon="unlink" />
															<div className="font-bold uppercase">
																An error occurred
																<br />
																Please try again later.
															</div>
														</div>
													</td>
												</tr>
											)}

										{this.props.walletTransactionsLoading === false &&
											data &&
											data.docs &&
											data.docs.length <= 0 && (
												<tr>
													<td colSpan="9">
														{data.page <= data.totalPages && (
															<div className="table-info">
																<FontAwesomeIcon icon="list" />
																<div className="font-bold uppercase">
																	No results found
																</div>
															</div>
														)}

														{data.page > data.totalPages && (
															<div className="table-info">
																<FontAwesomeIcon icon="exclamation-triangle" />
																<div className="font-bold uppercase">
																	Invalid Page Number
																</div>
																<div className="mt-2">
																	<Link
																		to="?page=1"
																		className="btn btn-ep-blue btn-sm mx-auto">
																		Goto Page 1
																	</Link>
																</div>
															</div>
														)}
													</td>
												</tr>
											)}

										{this.props.walletTransactionsLoading === false &&
											data &&
											data.docs &&
											data.docs.length > 0 &&
											data.docs.map((wallet, index) => (
												<tr key={index}>
													<td>{DateTimeFormat(wallet.createdAt)}</td>
													<td>
														<div>
															<span onClick={() => this.viewDetails(wallet)} className="cursor-pointer hover:underline">
																Wallet ID: {wallet?.walletId?.walletId ?? "-"}
															</span>
														</div>
														<div className="text-fade whitespace-nowrap">
															<div className="capitalize">
																{wallet?.walletId?.firstName ?? "-"} {wallet?.walletId?.lastName}
															</div>
															{wallet?.walletId?.email || "-"}
														</div>
													</td>
													<td>
														{CurrencyFormat(wallet.previousBalance / 100)}
													</td>
													<td>
														{this.amountColor(wallet.amount / 100, wallet.type)}
														<br />
														{wallet.sourceType ?? "-"}
													</td>
													<td>{CurrencyFormat(wallet.newBalance / 100)}</td>
													<td>{CurrencyFormat(wallet.revenue / 100) ?? "-"}</td>
													<td>{wallet.description}</td>
													<td>
														<div className={"label " + this.displayStatus(wallet.status)}>
															{this.displayStatus(wallet.status, "text")}
														</div>
													</td>
													<td>
														<div className="table-dropdown-align-top">
															<Dropdown
																icon={dropdownTableIcon}
																menu={[
																	{
																		type: "div",
																		text: "View Details",
																		click: () => this.viewDetails(wallet),
																	},
																]}
															/>
														</div>
													</td>
												</tr>
											))}
									</tbody>
								</table>
							</div>

							{this.props.walletTransactionsLoading === false &&
								data &&
								data.docs &&
								data.docs.length > 0 && (
									<div>
										<Pagination
											data={data}
											url={this.props.location.search}
											limit={getWalletParams.limit}
										/>
									</div>
								)}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	walletSummaryLoading: state.entities.withdrawals.walletSummaryLoading,
	walletSummary: state.entities.withdrawals.walletSummary,
	walletTransactionsLoading:
		state.entities.withdrawals.walletTransactionsLoading,
	walletTransactions: state.entities.withdrawals.walletTransactions,
	walletTransactionsDownloadLoading:
		state.entities.withdrawals.walletTransactionsDownloadLoading,
	walletTransactionsDownload:
		state.entities.withdrawals.walletTransactionsDownload,
});

const mapDispatchToProps = (dispatch) => ({
	getWalletSummary: () => dispatch(withdrawals.getWalletSummary()),
	getWalletTransactions: (params) =>
		dispatch(withdrawals.getWalletTransactions(params)),
	downloadWalletTransactions: (params) =>
		dispatch(withdrawals.downloadWalletTransactions(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(List);
