import { useDispatch, useSelector } from "react-redux";
import * as auth from "store/auth/user/action";


const useLoginInfo = () => {
    const dispatch = useDispatch();
    

    let userInfo = {}
    const { loggedIn: isLogin, data } = useSelector((s) => s.auth.userPersist);


    if (data.token){
        userInfo = {...data}
    }


    const logoutUser = (params = {}) => {
        dispatch(auth.logoutUser(params));
    }
    
    
    return { isLogin, userInfo, logoutUser };
}


export default useLoginInfo;
