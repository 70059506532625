import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Table from "components/__new/common/Table";
import processParams from "utils/processParams";
import useUrlQueryParams from "hooks/useUrlQueryParams";
import { TableContent } from "components/__new/common/TableContent";
import SearchFilter from "components/common/filter";
import Pagination from "components/common/pagination";
import DialogBox from 'utils/dialogBox';
import { ReactComponent as IconCircleQuestion } from "assets/images/icons/project-icons/circle-question.svg";
import { ReactComponent as IconCircleTimes } from "assets/images/icons/project-icons/circle-times.svg";
import Maybe from "components/__new/common/Maybe";
import DropdownNew from "utils/dropdown-new";
import RewardForm from "components/__new/rewards/RewardForm";
import RewardsDashboard from "components/__new/rewards/RewardsDashboard";
import RewardsTab from "components/__new/rewards/RewardsTab";
import Download from "components/common/download";
import NumberFormat from "utils/numberFormat";
import DateFormat from "utils/dateFormat";
import moment from "moment";
import RewardDetails from "components/__new/rewards/RewardDetails";
import * as rewardsActions from "store/entities/rewards/action";


const List = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const {urlQueryParams} = useUrlQueryParams();
    const { rewardsLoading, rewards, rewardsDownloadLoading, rewardsDownload } = useSelector((s) => s.entities.rewards);
    const { userType } = useSelector((state) => state.auth.userPersist.data);
    

    const downloadRef = useRef();
    const [isOpenRewardForm, setIsOpenRewardForm] = useState(false);
    const [isOpenRewardDetails, setIsOpenRewardDetails] = useState(false);
    const [selectedReward, setSelectedReward] = useState({});
    const [listParams, setListParams] = useState({
        page: 1,
        limit: 20,
    });


    const getRewards = () => {
        let params = {...listParams};

        params = processParams(urlQueryParams, params, "page", "number", 1, "page");
        params = processParams(urlQueryParams, params, "limit", "number", 20, "limit");
        params = processParams(urlQueryParams, params, "search", "string", "", "keyword");
        // params = processParams(urlQueryParams, params, "status", "string", "", "status");
        params = processParams(urlQueryParams, params, "usageCountFrom", "string", "", "usageCountFrom");
        params = processParams(urlQueryParams, params, "usageCountTo", "string", "", "usageCountTo");
        params = processParams(urlQueryParams, params, "pointCostFrom", "string", "", "pointCostFrom");
        params = processParams(urlQueryParams, params, "pointCostTo", "string", "", "pointCostTo");
        params = processParams(urlQueryParams, params, "dateFrom", "string", "", "dateFrom");
        params = processParams(urlQueryParams, params, "dateTo", "string", "", "dateTo");
        
        setListParams(params);
        dispatch(rewardsActions.getRewards(params));
    }


    const transformDownloadResponse = (rewards) => {
        const data = rewards?.map((reward) => {
            return {
                ...reward,
                isApproved: reward.isApproved ? "Approved": "Unapproved",
                isAvailable: reward.isApproved ? "Active": "Inactive",
            }
        });
        return data;
    }


    const createReward = () => {
        setIsOpenRewardForm(true);
        setSelectedReward({});
    }


    const editReward = (reward) => {
        setIsOpenRewardForm(true);
        setSelectedReward(reward);
    }


    const viewReward = (reward) => {
        setIsOpenRewardDetails(true);
        setSelectedReward(reward);
    }


    const enableReward = async (reward) => {
        const result = await DialogBox({
            icon: <IconCircleQuestion className="w-16 h-16" />,
			title: "Enable This Reward",
			content: "Are you sure you want to enable this reward?",
			buttonYes: "Enable",
		});
        if (result) {
            dispatch(rewardsActions.enableReward(reward._id)).then(() => {
                getRewards();
            })
        }
    }


    const disableReward = async (reward) => {
        const result = await DialogBox({
            icon: <IconCircleTimes className="w-16 h-16" />,
			title: "Disable This Reward",
			content: "Are you sure you want to disable this reward?",
			buttonYes: "Disable",
		});
        if (result) {
            dispatch(rewardsActions.disableReward(reward._id)).then(() => {
                getRewards();
            })
        }
    }


    const approveReward = async (reward) => {
        const result = await DialogBox({
			title: "Approve This Reward",
			content: "Are you sure you want to approve this reward?",
			buttonYes: "Yes",
		});
        if (result) {
            dispatch(rewardsActions.approveReward(reward._id)).then(() => {
                getRewards();
            })
        }
    }


    useEffect(() => {
        getRewards();
        // eslint-disable-next-line
    }, [location]);


    return (
        <div>

            
            <RewardForm
                isOpen={isOpenRewardForm}
                setIsOpen={setIsOpenRewardForm}
                selectedReward={selectedReward}
                getRewards={getRewards}
            />

            
            <RewardDetails
                isOpen={isOpenRewardDetails}
                setIsOpen={setIsOpenRewardDetails}
                selectedReward={selectedReward}
            />


            <div className="lg:flex">
                <div className="w-full lg:w-1/2">
                    <div className="page-title capitalize">
                        Rewards
                    </div>
                </div>
                <div className="w-full lg:w-1/2 flex space-x-4 mt-2 lg:mt-0 lg:justify-end">
                    <div>
                        <Download
                            tooltip="Download rewards"
                            filename="Rewards.csv"
                            format="csv"
                            columns={[
                                { label: "Reward Title", key: "title" },
                                { label: "Partner", key: "partner" },
                                { label: "Description", key: "description" },
                                { label: "Point Cost", key: "pointCost" },
                                { label: "Reward Validity", key: "expiryDate" },
                                { label: "Approval Status", key: "isApproved" },
                                { label: "Status", key: "isAvailable" },
                                { label: "Usage (current)", key: "currentCount" },
                                { label: "Usage (total)", key: "usageCount" },
                            ]}
                            ref={downloadRef}
                            click={() => {
                                dispatch(rewardsActions.downloadRewards({...listParams, page: 1, limit: 2000, download: true})).then(() => {
                                    downloadRef.current.processDownload();
                                });
                            }}
                            loading={rewardsDownloadLoading}
                            response={transformDownloadResponse(rewardsDownload?.data?.rewards)}
                        />
                    </div>
                    <div> 
                        <SearchFilter
                            search={{
                                name: "search",
                                placeholder: "Keyword search",
                            }}
                            filters={[
                                {
                                    title: "Usage Range",
                                    dataType: "number-range",
                                    options: {
                                        from: {
                                            name: "usageCountFrom",
                                            placeholder: "From",
                                        },
                                        to: {
                                            name: "usageCountTo",
                                            placeholder: "To",
                                        },
                                    },
                                },
                                {
                                    title: "Point Cost Range",
                                    dataType: "number-range",
                                    options: {
                                        from: {
                                            name: "pointCostFrom",
                                            placeholder: "From",
                                        },
                                        to: {
                                            name: "pointCostTo",
                                            placeholder: "To",
                                        },
                                    },
                                },
                                {
                                    title: "Date Range",
                                    dataType: "date-range",
                                    options: {
                                        from: {
                                            name: "dateFrom",
                                            placeholder: "Start Date",
                                        },
                                        to: {
                                            name: "dateTo",
                                            placeholder: "End Date",
                                        },
                                    },
                                },
                                // {
                                //     title: "Rewarder",
                                //     name: "rewarder",
                                //     dataType: "text",
                                //     placeholder: "Enter the name of rewarder",
                                // },
                            ]}
                        />
                    </div>
                </div>
            </div>


            <RewardsDashboard />


            <RewardsTab />


            <div className="mt-6 lg:flex lg:items-center">
                <div className="w-full lg:w-1/2">
                    <div className="page-title-mini">
                        Reward Data
                    </div>
                </div>
                <div className="w-full lg:w-1/2 flex space-x-4 mt-2 lg:mt-0 lg:justify-end">
                    <button type="button" onClick={createReward} className="btn btn-md btn-ep-blue">
                        <FontAwesomeIcon icon="plus" className="mr-2" />
                        Add New Reward
                    </button>
                </div>
            </div>


            <div className="mt-6">
                <Table>
                    <Table.Head>
                        <th>Reward Title</th>
                        <th>Partner</th>
                        <th>Description</th>
                        <th>Point Cost</th>
                        <th>Reward Validity</th>
                        <th>Approval Status</th>
                        <th>Status</th>
                        <th>Usage</th>
                        <th>Actions</th>
                    </Table.Head>
                    <Table.Body>

                        <TableContent
                            loading={rewardsLoading}
                            loadingText="Loading rewards"
                            data={rewards?.data?.rewards}
                            total={rewards?.data?.totalDocs}
                            colspan={9}
                        />
                        
                        <Maybe condition={rewardsLoading === false && rewards?.data?.rewards?.length > 0}>
                            {rewards?.data?.rewards?.map((reward, index) =>
                                <tr key={index}>
                                    <td>
                                        {reward?.title ?? "-"}
                                    </td>
                                    <td>
                                        {reward?.partner ?? "-"}
                                    </td>
                                    <td>
                                        <div className="ellipsis-2-lines">
                                            {reward?.description ?? "-"}
                                        </div>
                                    </td>
                                    <td>
                                        {NumberFormat(reward?.pointCost) ?? "-"}
                                    </td>
                                    <td>
                                        {DateFormat(moment(reward?.expiryDate, "YYYY-MM-DD")) ?? "-"}
                                    </td>
                                    <td>
                                        <div className={"label uppercase " + (reward?.isApproved ? "label-green":"label-red")}>
                                            {reward?.isApproved ? "Approved": "Unapproved"}
                                        </div>
                                    </td>
                                    <td>
                                        <div className={"label uppercase " + (reward?.isAvailable ? "label-green":"label-red")}>
                                            {reward?.isAvailable ? "Active": "Inactive"}
                                        </div>
                                    </td>
                                    <td>
                                        {NumberFormat(reward?.currentCount) ?? "-"} of {NumberFormat(reward?.usageCount) ?? "-"}
                                    </td>
                                    <td>
                                        <DropdownNew>
                                            <></>
                                            <div>
                                                <div onClick={() => viewReward(reward)} className="dropdown-item">View Details</div>
                                                <div onClick={() => editReward(reward)} className="dropdown-item">Edit</div>
                                                <Maybe condition={reward?.isAvailable === false}>
                                                    <div onClick={() => enableReward(reward)} className="dropdown-item">Enable</div>
                                                </Maybe>
                                                <Maybe condition={reward?.isAvailable === true}>
                                                    <div onClick={() => disableReward(reward)} className="dropdown-item">Disable</div>
                                                </Maybe>
                                                <Maybe condition={reward?.isAvailable === false && reward?.isApproved === false && userType === "super-admin"}>
                                                    <div onClick={() => approveReward(reward)} className="dropdown-item">Approve</div>
                                                </Maybe>
                                            </div>
                                        </DropdownNew>
                                    </td>
                                </tr>
                            )}
                        </Maybe>

                    </Table.Body>
                </Table>

                <Maybe condition={rewardsLoading === false && rewards?.data?.rewards?.length > 0}>
                    <Pagination data={rewards?.data} limit={listParams.limit} />
                </Maybe>
            </div>


        </div>
    )
}


export default List;