import * as actions from "./actionTypes";
import { toast } from 'react-toastify';

const initialState = {
    allReportFiltersEmployersLoading: false,
    allReportFiltersEmployers: {},
    allReportsEmployersLoading: false,
    allReportsEmployers: {},
    downloadReportsEmployersLoading: false,
    downloadReportsEmployers: {},

    allReportFiltersEmployeesLoading: false,
    allReportFiltersEmployees: {},
    allReportsEmployeesLoading: false,
    allReportsEmployees: {},
    downloadReportsEmployeesLoading: false,
    downloadReportsEmployees: {},

    allReportFiltersWithdrawalsLoading: false,
    allReportFiltersWithdrawals: {},
    allReportsWithdrawalsLoading: false,
    allReportsWithdrawals: {},
    downloadReportsWithdrawalsLoading: false,
    downloadReportsWithdrawals: {},

    // downloadLoading: false,
    // downloadAllPaymentHistory: {},
};


export default function reducer(state = initialState, action) {
    switch (action.type) {


        case actions.GET_REPORT_FILTERS_EMPLOYERS:
            state = {
                ...state,
                allReportFiltersEmployersLoading: false,
                allReportFiltersEmployers: { ...action.payload }
            }
            return state;


        case actions.GET_REPORT_FILTERS_EMPLOYERS_START:
            state = {
                ...state,
                allReportFiltersEmployersLoading: true,
                allReportFiltersEmployers: {},
            }
            return state;


        case actions.GET_REPORT_FILTERS_EMPLOYERS_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }

            state = {
                ...state,
                allReportFiltersEmployersLoading: false,
                allReportFiltersEmployers: {},
            }
            return state;


        case actions.GET_REPORT_EMPLOYERS:
            state = {
                ...state,
                allReportsEmployersLoading: false,
                allReportsEmployers: { ...action.payload },
            }
            return state;


        case actions.GET_REPORT_EMPLOYERS_START:
            state = {
                ...state,
                allReportsEmployersLoading: true,
                allReportsEmployers: {},
            }
            return state;


        case actions.GET_REPORT_EMPLOYERS_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }

            state = {
                ...state,
                allReportsEmployersLoading: false,
                allReportsEmployers: {},
            }
            return state;


        case actions.DOWNLOAD_REPORT_EMPLOYERS:
            state = {
                ...state,
                downloadReportsEmployersLoading: false,
                downloadReportsEmployers: { ...action.payload },
            }
            return state;


        case actions.DOWNLOAD_REPORT_EMPLOYERS_START:
            state = {
                ...state,
                downloadReportsEmployersLoading: true,
                downloadReportsEmployers: {},
            }
            return state;


        case actions.DOWNLOAD_REPORT_EMPLOYERS_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }

            state = {
                ...state,
                downloadReportsEmployersLoading: false,
                downloadReportsEmployers: {},
            }
            return state;


        case actions.GET_REPORT_FILTERS_EMPLOYEES:
            state = {
                ...state,
                allReportFiltersEmployeesLoading: false,
                allReportFiltersEmployees: { ...action.payload }
            }
            return state;


        case actions.GET_REPORT_FILTERS_EMPLOYEES_START:
            state = {
                ...state,
                allReportFiltersEmployeesLoading: true,
                allReportFiltersEmployees: {},
            }
            return state;


        case actions.GET_REPORT_FILTERS_EMPLOYEES_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }

            state = {
                ...state,
                allReportFiltersEmployeesLoading: false,
                allReportFiltersEmployees: {},
            }
            return state;


        case actions.GET_REPORT_EMPLOYEES:
            state = {
                ...state,
                allReportsEmployeesLoading: false,
                allReportsEmployees: { ...action.payload },
            }
            return state;


        case actions.GET_REPORT_EMPLOYEES_START:
            state = {
                ...state,
                allReportsEmployeesLoading: true,
                allReportsEmployees: {},
            }
            return state;


        case actions.GET_REPORT_EMPLOYEES_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }

            state = {
                ...state,
                allReportsEmployeesLoading: false,
                allReportsEmployees: {},
            }
            return state;


        case actions.DOWNLOAD_REPORT_EMPLOYEES:
            state = {
                ...state,
                downloadReportsEmployeesLoading: false,
                downloadReportsEmployees: { ...action.payload },
            }
            return state;


        case actions.DOWNLOAD_REPORT_EMPLOYEES_START:
            state = {
                ...state,
                downloadReportsEmployeesLoading: true,
                downloadReportsEmployees: {},
            }
            return state;


        case actions.DOWNLOAD_REPORT_EMPLOYEES_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }

            state = {
                ...state,
                downloadReportsEmployeesLoading: false,
                downloadReportsEmployees: {},
            }
            return state;


        case actions.GET_REPORT_FILTERS_WITHDRAWALS:
            state = {
                ...state,
                allReportFiltersWithdrawalsLoading: false,
                allReportFiltersWithdrawals: { ...action.payload }
            }
            return state;


        case actions.GET_REPORT_FILTERS_WITHDRAWALS_START:
            state = {
                ...state,
                allReportFiltersWithdrawalsLoading: true,
                allReportFiltersWithdrawals: {},
            }
            return state;


        case actions.GET_REPORT_FILTERS_WITHDRAWALS_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }

            state = {
                ...state,
                allReportFiltersWithdrawalsLoading: false,
                allReportFiltersWithdrawals: {},
            }
            return state;


        case actions.GET_REPORT_WITHDRAWALS:
            state = {
                ...state,
                allReportsWithdrawalsLoading: false,
                allReportsWithdrawals: { ...action.payload },
            }
            return state;


        case actions.GET_REPORT_WITHDRAWALS_START:
            state = {
                ...state,
                allReportsWithdrawalsLoading: true,
                allReportsWithdrawals: {},
            }
            return state;


        case actions.GET_REPORT_WITHDRAWALS_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }

            state = {
                ...state,
                allReportsWithdrawalsLoading: false,
                allReportsWithdrawals: {},
            }
            return state;


        case actions.DOWNLOAD_REPORT_WITHDRAWALS:
            state = {
                ...state,
                downloadReportsWithdrawalsLoading: false,
                downloadReportsWithdrawals: { ...action.payload },
            }
            return state;


        case actions.DOWNLOAD_REPORT_WITHDRAWALS_START:
            state = {
                ...state,
                downloadReportsWithdrawalsLoading: true,
                downloadReportsWithdrawals: {},
            }
            return state;


        case actions.DOWNLOAD_REPORT_WITHDRAWALS_FAILED:
            if (action.payload.message === "Validation Errors") {
                const payloadErrors = action.payload.errors;
                Object.keys(payloadErrors).forEach(function (key) {
                    for (let i = 0; i < payloadErrors[key].length; i++) {
                        toast.error(payloadErrors[key][i]);
                    }
                });
            }
            else {
                toast.error(action.payload.message);
            }

            state = {
                ...state,
                downloadReportsWithdrawalsLoading: false,
                downloadReportsWithdrawals: {},
            }
            return state;


        default:
            return state;
    }
}