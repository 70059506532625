import React, { Component, useState, Fragment } from "react";
import Joi from "joi-browser";
import queryString from "query-string";
import DashboardBoxes from "components/common/dashboard-boxes";
import Dropdown from "utils/dropdown";
import store from "store/store";
import Pagination from "components/common/pagination";
import DialogBox from "utils/dialogBox";
import Download from "components/common/download";
import SearchFilter from "components/common/filter";
import { Link } from "react-router-dom";
import DateFormat, { DateTimeFormat } from "utils/dateFormat";
import { customizeSVG } from "utils/customizeSVG.jsx";
import { encrypt } from "utils/encrypt_decrypt";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { renderStatusColor } from "utils/statusColor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactComponent as IconEmployees } from "assets/images/icons/project-icons/employers.svg";
import { ReactComponent as IconNav } from "assets/images/icons/project-icons/Navicon-v.svg";
import { ReactComponent as Xclose } from "assets/images/icons/project-icons/xclose.svg";
import { ReactComponent as IconFileUpload } from "assets/images/icons/project-icons/file-upload.svg";
import { ReactComponent as IconDownload } from "assets/images/icons/project-icons/download-green.svg";
import { ReactComponent as IconComment } from "assets/images/icons/project-icons/comment.svg";
import { ReactComponent as IconApproved } from "assets/images/icons/project-icons/onboarding-approved.svg";
import { ReactComponent as IconRejected } from "assets/images/icons/project-icons/onboarding-rejected.svg";
import { ReactComponent as IconCheck } from "assets/images/icons/project-icons/circle-check.svg";
import * as employers from "store/entities/employers/action";
import * as settings from "store/entities/settings/action";
import CenterModal from "components/misc/CenterModal";
import SetEarningsModal from "components/payments/SetEarningsModal";
import Maybe from "components/__new/common/Maybe";
import numberOrdinalSuffix from "utils/numberOrdinalSuffix";
import nameToSentenceCase from "utils/nameToSentenceCase";
import CurrencyFormat from "utils/currencyFormat";
import {
	approveCustomer,
	getAllCustomers,
	getSingleCustomer,
	selectCustomer,
} from "store/entities/employees/action";
import CustomerDetailsModal from "components/__new/customers/CustomerDetailsModal";

const dropdownTableIcon = (
	<IconNav className="w-8 h-8 p-1.5 relative top-1 cursor-pointer group-hover:bg-gray-200 rounded" />
);

const truncate = (text) =>
	text?.length > 9 ? text?.substring(0, 9) + "..." : text;

class CustomersBalanceList extends Component {
	state = {
		getAllCustomersBalanceParams: {
			page: 1,
			limit: 20,
			download: false,
			name: "",
			keyword: "",
			minBalance: "",
			maxBalance: "",
		},
		dashboardProps1: {
			iconBg: "bg-blue-200",
			icon: (
				<IconEmployees className="customizeSVG text-blue-600 m-auto w-5 h-5" />
			),
			titleText: "Total Balance",
			mainText: "-",
		},
		searchErrors: "",
		modalDetailsAction: "",
		toggleModalDetails: false,
		employerDetails: "",
		selectedUser: null,
		errors: {},
		selectedUserName: "",
		selectedUserId: "",
	};

	gotoUrlQuery = (params) => {
		const qs = queryString.parse(this.props.location.search);

		Object.keys(params).forEach((paramName) => {
			delete qs[paramName];
		});

		let qsToUrl = new URLSearchParams(qs).toString();
		qsToUrl =
			this.props.location.pathname +
			"?" +
			(qsToUrl !== "" ? qsToUrl + "&" : "");

		Object.keys(params).forEach((paramName) => {
			if (params[paramName] !== "") {
				qsToUrl += paramName + "=" + params[paramName] + "&";
			}
		});

		qsToUrl = qsToUrl.substring(0, qsToUrl.length - 1);
		this.props.history.push(qsToUrl);
	};

	getAllQueryFilters = (params) => {
		const qs = queryString.parse(this.props.location.search);

		if (qs.keyword !== undefined && qs.keyword !== null) {
			params = { ...params, keyword: qs.keyword };
		} else {
			params = { ...params, keyword: "" };
		}
		return params;
	};

	getAllCustomersBalance = () => {
		const qs = queryString.parse(this.props.location.search);
		let getAllCustomersBalanceParams = {
			...this.state.getAllCustomersBalanceParams,
		};

		if (qs.page !== undefined && !isNaN(qs.page) && qs.page > 0) {
			getAllCustomersBalanceParams = {
				...getAllCustomersBalanceParams,
				page: qs.page,
			};
		} else {
			getAllCustomersBalanceParams = {
				...getAllCustomersBalanceParams,
				page: 1,
			};
		}

		if (qs.limit !== undefined && !isNaN(qs.limit) && qs.limit > 0) {
			getAllCustomersBalanceParams = {
				...getAllCustomersBalanceParams,
				limit: qs.limit,
			};
		} else {
			getAllCustomersBalanceParams = {
				...getAllCustomersBalanceParams,
				limit: 20,
			};
		}

		if (qs.minBalance !== undefined && qs.minBalance !== null) {
			getAllCustomersBalanceParams = {
				...getAllCustomersBalanceParams,
				minBalance: qs.minBalance.replace(/\D/g, ""),
			};
		} else {
			getAllCustomersBalanceParams = {
				...getAllCustomersBalanceParams,
				minBalance: "",
			};
		}

		if (qs.maxBalance !== undefined && qs.maxBalance !== null) {
			getAllCustomersBalanceParams = {
				...getAllCustomersBalanceParams,
				maxBalance: qs.maxBalance.replace(/\D/g, ""),
			};
		} else {
			getAllCustomersBalanceParams = {
				...getAllCustomersBalanceParams,
				maxBalance: "",
			};
		}

		getAllCustomersBalanceParams = this.getAllQueryFilters(
			getAllCustomersBalanceParams
		);
		this.setState({ getAllCustomersBalanceParams });
		this.props.getAllCustomersBalance(getAllCustomersBalanceParams);
	};

	downloadEmployersBalance = () => {
		const { getAllCustomersBalanceParams: params } = this.state;
		this.props
			.getAllCustomersBalance({
				...params,
				page: 1,
				limit: 10000,
				download: true,
			})
			.then(() => {
				this.Download.processDownload();
			});
	};

	transformDataDownload = (data) => {
		const newData = data?.map((row) => {
			const newRow = {
				...row,
				balance: CurrencyFormat((row.balance || 0) / 100) ?? 0,
			};
			return newRow;
		});
		return newData;
	};

	formChange = (e) => {
		const formValue = { ...this.state.search };
		formValue[e.currentTarget.name] = e.currentTarget.value;
		this.setState({ search: formValue });
	};

	validate = () => {
		const options = { abortEarly: false };
		const result = Joi.validate(this.state.search, this.schema, options);
		const error = result.error;
		if (!error) return null;

		const errors = {};
		for (let item of result.error.details) {
			errors[item.path[0]] = item.message;
		}
		return errors;
	};

	toggleViewDetails = (status, customer = {}, action = "view") => {
		this.setState({
			toggleModalDetails: status,
			employerDetails: customer,
			modalDetailsAction: action,
		});
		if (status === true) {
			this.props.getCustomer(customer);
		}
	};

	clearState = () => {
		const { toggleModalDetails } = this.state;
		if (toggleModalDetails) {
			this.setState({
				toggleModalDetails: false,
			});
		}
		// if (isActionModalOpen) {
		//     toggleActionModal();
		// }

		// setReason("");
		this.getAllCustomersBalance();
	};

	onApprove = async () => {
		const { selectedCustomer } = this.props;
		const result = await DialogBox({
			theme: "blue",
			content: "Are you sure you want to approve this customer?",
		});
		if (result) {
			this.props
				.approveCustomer({ id: selectedCustomer?._id })
				.then(() => this.clearState());
		}
		if (result) {
			this.props.approveCustomer().then(() => {
				this.toggleViewDetails(false);
			});
		}
	};

	onReject = () => {};

	getCustomerDetails = () => {
		const { getCustomerLoading, employer } = this.props;

		if (getCustomerLoading === true) {
			return (
				<FontAwesomeIcon
					icon="spinner"
					spin
					className="text-md"
				/>
			);
		} else if (
			getCustomerLoading === false &&
			Object.keys(employer).length <= 0
		) {
			return <>-</>;
		} else if (
			getCustomerLoading === false &&
			Object.keys(employer).length > 0
		) {
			return true;
		}
	};

	componentDidMount() {
		this.getAllCustomersBalance();
	}

	componentDidUpdate(prevProps) {
		if (this.props.location !== prevProps.location) {
			this.getAllCustomersBalance();
		}
		if (this.props.reloadPage === true) {
			setTimeout(() => {
				this.props.resetReloadPage();
				this.props.history.replace(
					this.props.location.pathname + this.props.location.search
				);
			}, 1000);
		}
	}

	componentWillUnmount() {
		const storeUnsubscribe = this.state.storeUnsubscribe;
		if (
			storeUnsubscribe !== "" &&
			storeUnsubscribe !== undefined &&
			storeUnsubscribe !== null
		) {
			storeUnsubscribe();
		}
	}

	render() {
		customizeSVG();
		const {
			loading,
			allCustomersBalance: data,
			employer,
			getCustomerLoading,
			selectedCustomer,
		} = this.props;
		const {
			getAllCustomersBalanceParams,
			toggleModalDetails,
			employerDetails,
		} = this.state;
		return (
			<div>
				<CustomerDetailsModal
					control={toggleModalDetails}
					onClose={() => this.toggleViewDetails(false)}
					loading={getCustomerLoading}
					customer={employer}
					onApprove={this.onApprove}
					onReject={this.onReject}
					selectedCustomer={selectedCustomer}
					fetchCustomers={this.getAllCustomersBalance}
				/>
				<div>
					<div className="lg:flex">
						<div className="w-full lg:w-1/2">
							<div className="page-title">Customers</div>
						</div>
						<div className="w-full lg:w-1/2 flex space-x-4 mt-2 lg:mt-0 lg:justify-end">
							<div>
								<SearchFilter
									search={{
										name: "keyword",
										placeholder: "Keyword search",
									}}
									filters={[
										{
											title: "Amount",
											dataType: "amount-range",
											range1: {
												name: "minBalance",
												placeholder: "Minimum amount",
											},
											range2: {
												name: "maxBalance",
												placeholder: "Maximum amount",
											},
										},
									]}
								/>
							</div>
						</div>
					</div>

					<div className="mt-6">
						<div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-6">
							<DashboardBoxes
								data={this.state.dashboardProps1}
								loading={loading}
								result={
									CurrencyFormat(
										(data?.data?.totalBalanceSum || 0) / 100
									) ?? "0"
								}
							/>
						</div>
					</div>

					<div className="mt-10">
						<div className="mt-3">
							<div className="table-container">
								<table className="table table-auto table-rounded table-border">
									<thead>
										<tr>
											<th className="whitespace-nowrap">
												Customers Name
											</th>
											<th>Account Number</th>
											<th>Account Balance</th>
											<th>Actions</th>
										</tr>
									</thead>
									<tbody>
										{this.props.loading === true && (
											<tr>
												<td colSpan="4">
													<div className="table-info">
														<FontAwesomeIcon
															icon="spinner"
															spin
														/>
														<div className="font-bold uppercase">
															Loading
														</div>
													</div>
												</td>
											</tr>
										)}

										{this.props.loading === false &&
											!data.data?.users && (
												<tr>
													<td colSpan="4">
														<div className="table-info">
															<FontAwesomeIcon icon="unlink" />
															<div className="font-bold uppercase">
																An error
																occurred
																<br />
																Please try again
																later.
															</div>
														</div>
													</td>
												</tr>
											)}

										{this.props.loading === false &&
											data.data?.users &&
											data.data?.users.length <= 0 && (
												<tr>
													<td colSpan="4">
														{(data?.data?.page <=
															data?.data
																?.totalPages ||
															data?.data?.users
																?.length <
																1) && (
															<div className="table-info">
																<FontAwesomeIcon icon="list" />
																<div className="font-bold uppercase">
																	No results
																	found
																</div>
															</div>
														)}
													</td>
												</tr>
											)}

										{this.props.loading === false &&
											data.data?.users &&
											data.data?.users.length > 0 &&
											data.data?.users.map(
												(user, index) => {
													return (
														<tr key={index}>
															<td className="w-4">
																<span
																	onClick={() => {
																		this.setState(
																			{
																				selectedUser:
																					user,
																			}
																		);
																		this.toggleViewDetails(
																			true,
																			user
																		);
																	}}
																	className="hover:underline cursor-pointer capitalize"
																>
																	{`${
																		user?.firstName ||
																		""
																	} ${
																		user?.lastName ||
																		"-"
																	}`}
																</span>
															</td>
															<td>
																{user?.accountNumber ||
																	"-"}
															</td>
															<td>
																{user?.status ===
																	"pending" ||
																user?.status ===
																	"review"
																	? "-"
																	: CurrencyFormat(
																			(user?.balance ||
																				0) /
																				100
																	  )}
															</td>
															<td>
																<div>
																	<Dropdown
																		icon={
																			dropdownTableIcon
																		}
																		menu={[
																			{
																				type: "div",
																				text: "View Transactions",
																				click: () => {
																					this.setState(
																						{
																							selectedUser:
																								user,
																						}
																					);
																					this.props.selectCustomer(
																						user
																					);
																					this.props.history.push(
																						`/users/customers/transactions/${user?.firstName}-${user?.lastName}`
																					);
																				},
																			},
																		]}
																	/>
																</div>
															</td>
														</tr>
													);
												}
											)}
									</tbody>
								</table>
							</div>

							{this.props.loading === false &&
								data.data?.users &&
								data.data?.users.length > 0 && (
									<div>
										<Pagination
											data={data?.data}
											url={this.props.location.search}
											limit={
												getAllCustomersBalanceParams.limit
											}
										/>
									</div>
								)}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	user: state.auth.userPersist.data,
	loading: state.entities.employers.loading,
	downloadLoading: state.entities.employers.downloadLoading,
	allCustomersBalance: state.entities.employers.allCustomersBalance,
	employerToken: state.entities.employers.employerToken,
	getCustomerLoading: state.entities.employees.singleCustomerLoading,
	employer: state.entities.employees.singleCustomerData,
	selectedCustomer: state.entities.employees.selectedCustomer,
});

const mapDispatchToProps = (dispatch) => ({
	getAllCustomersBalance: (params) =>
		dispatch(employers.getAllCustomersBalance(params)),
	downloadGetAllEmployers: (params) =>
		dispatch(employers.downloadGetAllEmployers(params)),
	getCustomer: (user) => {
		const formattedUser = { ...user, _id: user?.userId };
		dispatch(selectCustomer(formattedUser));
		dispatch(getSingleCustomer(formattedUser));
	},
	approveCustomer: (selectedCustomer) =>
		dispatch(approveCustomer({ id: selectedCustomer?._id })),
	selectCustomer: (params) =>
		dispatch(selectCustomer({ ...params, _id: params?.userId })),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(CustomersBalanceList);
