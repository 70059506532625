import * as actions from "./actionTypes";
import * as config from "config";
import ObjectToQueryString from "utils/ObjectToQueryString";

export function approveEmployerOdpRequest(id) {

	return {
		type: config.apiRequestStart,
		payload: {
			url: `${config.apiApproveEmployerOdp}${id}`,
			method: "post",
			data: {},
			onStart: actions.APPROVE_EMPLOYER_ODP_START,
			onSuccess: actions.APPROVE_EMPLOYER_ODP_DONE,
			onError: actions.APPROVE_EMPLOYER_ODP_FAILED,
		},
	};
}

export function rejectEmployerOdpRequest(params) {

	return {
		type: config.apiRequestStart,
		payload: {
			url: `${config.apiRejectEmployerOdp}${params?.id}`,
			method: "post",
			data: {
				rejectComment: params?.comment
			},
			onStart: actions.REJECT_EMPLOYER_ODP_START,
			onSuccess: actions.REJECT_EMPLOYER_ODP_DONE,
			onError: actions.REJECT_EMPLOYER_ODP_FAILED,
		},
	};
}

export function selectEmployer(payload) {
	return {
		type: actions.SELECT_EMPLOYER,
		payload,
	};
}

export function getEmployerTransactionsMetrics(id) {
	return {
		type: config.apiRequestStart,
		payload: {
			baseUrl: config.apiWalletBaseUrl,
			url: `${config.apiGetEmployerTransactionsMetrics}/${id}/transaction-metrics`,
			method: "get",
			data: {},
			onStart: actions.GET_EMPLOYER_TRANSACTIONS_METRICS_START,
			onSuccess: actions.GET_EMPLOYER_TRANSACTIONS_METRICS_DONE,
			onError: actions.GET_EMPLOYER_TRANSACTIONS_METRICS_FAILED,
			customToken: process.env.REACT_APP_ADMIN_AUTH || ""
		},
	};
}

export function getEmployerTransactions(params) {
	return {
		type: config.apiRequestStart,
		payload: {
			url: `${config.apiWalletBaseUrl}/wallet${config.apiGetEmployerTransactions}${params?.id}/transaction-details` + ObjectToQueryString(params?.payload),
			method: "get",
			data: {},
			onStart: actions.GET_EMPLOYER_TRANSACTIONS_START,
			onSuccess: actions.GET_EMPLOYER_TRANSACTIONS_DONE,
			onError: actions.GET_EMPLOYER_TRANSACTIONS_FAILED,
			customToken: process.env.REACT_APP_ADMIN_AUTH || ""
		},
	};
}

export function searchFilterEmployerTransactions(params) {
	return {
		type: config.apiRequestStart,
		payload: {
			url: `${config.apiSearchAndFilterEmployerTransactions}${params?.id}/search-filter-transactions` + ObjectToQueryString(params?.payload),
			method: "get",
			data: {},
			onStart: actions.GET_EMPLOYER_TRANSACTIONS_START,
			onSuccess: actions.GET_EMPLOYER_TRANSACTIONS_DONE,
			onError: actions.GET_EMPLOYER_TRANSACTIONS_FAILED,
		},
	};
}

export function downloadEmployerTransactions(params) {
	return {
		type: config.apiRequestStart,
		payload: {
			url: `${config.apiGetEmployerTransactions}${params?.id}/transaction-details` + ObjectToQueryString(params?.payload),
			method: "get",
			data: {},
			onStart: actions.DOWNLOAD_EMPLOYER_TRANSACTIONS_START,
			onSuccess: actions.DOWNLOAD_EMPLOYER_TRANSACTIONS_DONE,
			onError: actions.DOWNLOAD_EMPLOYER_TRANSACTIONS_FAILED,
		},
	};
}


export function toggleAutoWithdrawals(params) {
	return {
		type: config.apiRequestStart,
		payload: {
			url: `${config.apiGetEmployerAdmins}${params.id}/company-info`,
			method: "post",
			data: {
				autoWithdrawals: params.autoWithdrawals === false ? true : false,
			},
			onStart: actions.TOGGLE_AUTO_WDL_START,
			onSuccess: actions.TOGGLE_AUTO_WDL_DONE,
			onError: actions.TOGGLE_AUTO_WDL_FAILED,
		},
	};
}

export function setEmployeeEarnings(params) {
	return {
		type: config.apiRequestStart,
		payload: {
			url: config.apiSetEarnings,
			method: "post",
			data: params,
			onStart: actions.SET_EARNINGS_START,
			onSuccess: actions.SET_EARNINGS_DONE,
			onError: actions.SET_EARNINGS_FAILED,
		},
	};
}

export function toggleEmployerRollover(params) {
	return {
		type: config.apiRequestStart,
		payload: {
			url: `${config.apiGetEmployerAdmins}${params.id}/rollover`,
			method: "post",
			data: { rollOver: params.rollOver === false ? true : false },
			onStart: actions.TOGGLE_ROLLOVER_START,
			onSuccess: actions.TOGGLE_ROLLOVER_DONE,
			onError: actions.TOGGLE_ROLLOVER_FAILED,
		},
	};
}

export function getAllEmployersCount() {
	return {
		type: config.apiRequestStart,
		payload: {
			url: config.apiGetEmployersCount,
			method: "get",
			data: {},
			onStart: actions.GET_ALL_EMPLOYERS_COUNT_START,
			onSuccess: actions.GET_ALL_EMPLOYERS_COUNT,
			onError: actions.GET_ALL_EMPLOYERS_COUNT_FAILED,
		},
	};
}

export function getAllEmployers(params) {
	return {
		type: config.apiRequestStart,
		payload: {
			url: config.apiGetEmployers + ObjectToQueryString(params),
			method: "get",
			data: {},
			onStart: actions.GET_ALL_EMPLOYERS_START,
			onSuccess: actions.GET_ALL_EMPLOYERS,
			onError: actions.GET_ALL_EMPLOYERS_FAILED,
			// useLoginJwt: false,
		},
	};
}
export function getAllEmployersBalance(params) {
	return {
		type: config.apiRequestStart,
		payload: {
			url: config.apiWalletBaseUrl + config.apiGetEmployersBalance + ObjectToQueryString(params),
			method: "get",
			data: {},
			onStart: actions.GET_ALL_EMPLOYERS_BALANCE_START,
			onSuccess: actions.GET_ALL_EMPLOYERS_BALANCE,
			onError: actions.GET_ALL_EMPLOYERS_BALANCE_FAILED,
			customToken: process.env.REACT_APP_ADMIN_AUTH || ""
		},
	};
}

export function getAllCustomersBalance(params) {
	return {
		type: config.apiRequestStart,
		payload: {
			url: config.apiWalletBaseUrl + config.apiGetCustomersBalance + ObjectToQueryString(params),
			method: "get",
			data: {},
			onStart: actions.GET_ALL_CUSTOMERS_BALANCE_START,
			onSuccess: actions.GET_ALL_CUSTOMERS_BALANCE,
			onError: actions.GET_ALL_CUSTOMERS_BALANCE_FAILED,
			customToken: process.env.REACT_APP_ADMIN_AUTH || ""
		},
	};
}

export function searchFilterEmployers(params) {
	return {
		type: config.apiRequestStart,
		payload: {
			url: config.apiSearchAndFilterEmployers + ObjectToQueryString(params),
			method: "get",
			data: {},
			onStart: actions.GET_ALL_EMPLOYERS_START,
			onSuccess: actions.GET_ALL_EMPLOYERS,
			onError: actions.GET_ALL_EMPLOYERS_FAILED,
			// useLoginJwt: false,
		},
	};
}

export function downloadGetAllEmployers(params) {
	return {
		type: config.apiRequestStart,
		payload: {
			url: config.apiGetEmployers + ObjectToQueryString(params),
			method: "get",
			data: {},
			onStart: actions.DOWNLOAD_GET_ALL_EMPLOYERS_START,
			onSuccess: actions.DOWNLOAD_GET_ALL_EMPLOYERS,
			onError: actions.DOWNLOAD_GET_ALL_EMPLOYERS_FAILED,
		},
	};
}

export function downloadSearchFilterEmployers(params) {
	return {
		type: config.apiRequestStart,
		payload: {
			url: config.apiSearchAndFilterEmployers + ObjectToQueryString(params),
			method: "get",
			data: {},
			onStart: actions.DOWNLOAD_GET_ALL_EMPLOYERS_START,
			onSuccess: actions.DOWNLOAD_GET_ALL_EMPLOYERS,
			onError: actions.DOWNLOAD_GET_ALL_EMPLOYERS_FAILED,
			// useLoginJwt: false,
		},
	};
}

export function getAllEmployerAdmins(id, params) {
	return {
		type: config.apiRequestStart,
		payload: {
			url:
				config.apiGetEmployerAdmins +
				id +
				"/admins" +
				ObjectToQueryString(params),
			method: "get",
			data: {},
			onStart: actions.GET_ALL_EMPLOYER_ADMIN_START,
			onSuccess: actions.GET_ALL_EMPLOYER_ADMIN,
			onError: actions.GET_ALL_EMPLOYER_ADMIN_FAILED,
			// useLoginJwt: false,
		},
	};
}

export function downloadAllEmployerAdmins(id, params) {
	return {
		type: config.apiRequestStart,
		payload: {
			url:
				config.apiGetEmployerAdmins +
				id +
				"/admins" +
				ObjectToQueryString(params),
			method: "get",
			data: {},
			onStart: actions.DOWNLOAD_EMPLOYER_ADMIN_START,
			onSuccess: actions.DOWNLOAD_EMPLOYER_ADMIN,
			onError: actions.DOWNLOAD_EMPLOYER_ADMIN_FAILED,
			// useLoginJwt: false,
		},
	};
}

export function getEmployer(id) {
	return {
		type: config.apiRequestStart,
		payload: {
			url: config.apiGetEmployer + id,
			method: "get",
			data: {},
			onStart: actions.GET_EMPLOYER_START,
			onSuccess: actions.GET_EMPLOYER,
			onError: actions.GET_EMPLOYER_FAILED,
		},
	};
}

export function getAllEmployerEmployees(id, params) {
	return {
		type: config.apiRequestStart,
		payload: {
			url: config.apiGetEmployersEmployees + id,
			method: "post",
			data: params,
			onStart: actions.GET_ALL_EMPLOYER_EMPLOYER_START,
			onSuccess: actions.GET_ALL_EMPLOYER_EMPLOYER,
			onError: actions.GET_ALL_EMPLOYER_EMPLOYER_FAILED,
		},
	};
}

export function downloadGetAllEmployerEmployees(id, params) {
	return {
		type: config.apiRequestStart,
		payload: {
			url: config.apiGetEmployersEmployees + id,
			method: "post",
			data: params,
			onStart: actions.DOWNLOAD_GET_ALL_EMPLOYER_EMPLOYER_START,
			onSuccess: actions.DOWNLOAD_GET_ALL_EMPLOYER_EMPLOYER,
			onError: actions.DOWNLOAD_GET_ALL_EMPLOYER_EMPLOYER_FAILED,
		},
	};
}

export function getAcceptedEmployerEmployees(id, params) {
	return {
		type: config.apiRequestStart,
		payload: {
			url: config.apiGetEmployersEmployees + id,
			method: "post",
			data: params,
			onStart: actions.GET_ACCEPTED_EMPLOYER_EMPLOYER_START,
			onSuccess: actions.GET_ACCEPTED_EMPLOYER_EMPLOYER,
			onError: actions.GET_ACCEPTED_EMPLOYER_EMPLOYER_FAILED,
		},
	};
}

export function getEmployerToken(param) {
	return {
		type: config.apiRequestStart,
		payload: {
			url: config.apiGetEmployerToken,
			method: "post",
			data: param,
			onStart: actions.GET_EMPLOYER_TOKEN_START,
			onSuccess: actions.GET_EMPLOYER_TOKEN,
			onError: actions.GET_EMPLOYER_TOKEN_FAILED,
		},
	};
}

export function approveEmployer(id) {
	return {
		type: config.apiRequestStart,
		payload: {
			url: config.apiApproveEmployer + id,
			method: "post",
			data: {},
			onStart: actions.APPROVE_EMPLOYER_START,
			onSuccess: actions.APPROVE_EMPLOYER,
			onError: actions.APPROVE_EMPLOYER_FAILED,
		},
	};
}

export function rejectEmployerOnboarding({id, payload}) {

	return {
		type: config.apiRequestStart,
		payload: {
			url: config.apiRejectEmployerOnboarding + id,
			method: "post",
			data: payload,
			onStart: actions.REJECT_EMPLOYER_ONBOARDING_START,
			onSuccess: actions.REJECT_EMPLOYER_ONBOARDING_DONE,
			onError: actions.REJECT_EMPLOYER_ONBOARDING_FAILED,
		},
	};
}


export function resetOnboardingRejectionResponse() {
	return {
		type: actions.RESET_ONBOARDING_REJECTION_RESPONSE,
		payload: {},
	};
}

export function activateEmployer(id) {
	return {
		type: config.apiRequestStart,
		payload: {
			url: config.apiEnableEmployer + id,
			method: "post",
			data: {},
			onStart: actions.ENABLE_EMPLOYER_START,
			onSuccess: actions.ENABLE_EMPLOYER,
			onError: actions.ENABLE_EMPLOYER_FAILED,
		},
	};
}

export function deactivateEmployer(id) {
	return {
		type: config.apiRequestStart,
		payload: {
			url: config.apiDisableEmployer + id,
			method: "post",
			data: {},
			onStart: actions.DISABLE_EMPLOYER_START,
			onSuccess: actions.DISABLE_EMPLOYER,
			onError: actions.DISABLE_EMPLOYER_FAILED,
		},
	};
}

export function stopEmployeeWithdrawals(id) {
	return {
		type: config.apiRequestStart,
		payload: {
			url: config.apiStopEmployeeWithdrawals,
			method: "post",
			data: {
				employerId: id,
			},
			onStart: actions.STOP_EMPLOYEE_WITHDRAWALS_START,
			onSuccess: actions.STOP_EMPLOYEE_WITHDRAWALS,
			onError: actions.STOP_EMPLOYEE_WITHDRAWALS_FAILED,
		},
	};
}

export function resumeEmployeeWithdrawals(id) {
	return {
		type: config.apiRequestStart,
		payload: {
			url: config.apiResumeEmployeeWithdrawals,
			method: "post",
			data: {
				employerId: id,
			},
			onStart: actions.RESUME_EMPLOYEE_WITHDRAWALS_START,
			onSuccess: actions.RESUME_EMPLOYEE_WITHDRAWALS,
			onError: actions.RESUME_EMPLOYEE_WITHDRAWALS_FAILED,
		},
	};
}

export function activateEmployee(id) {
	return {
		type: config.apiRequestStart,
		payload: {
			url: config.apiEnableEmployee + id,
			method: "get",
			data: {},
			onStart: actions.ENABLE_EMPLOYER_EMPLOYEE_START,
			onSuccess: actions.ENABLE_EMPLOYER_EMPLOYEE,
			onError: actions.ENABLE_EMPLOYER_EMPLOYEE_FAILED,
		},
	};
}

export function deactivateEmployee(id) {
	return {
		type: config.apiRequestStart,
		payload: {
			url: config.apiDisableEmployee + id,
			method: "get",
			data: {},
			onStart: actions.DISABLE_EMPLOYER_EMPLOYEE_START,
			onSuccess: actions.DISABLE_EMPLOYER_EMPLOYEE,
			onError: actions.DISABLE_EMPLOYER_EMPLOYEE_FAILED,
		},
	};
}

export function pokeEmployer(id) {
	return {
		type: config.apiRequestStart,
		payload: {
			url: config.apiPokeEmployer + id,
			method: "get",
			data: {},
			onStart: actions.RESEND_INVITE_START,
			onSuccess: actions.RESEND_INVITE,
			onError: actions.RESEND_INVITE_FAILED,
		},
	};
}

export function resendVerificationMail(params) {
	return {
		type: config.apiRequestStart,
		payload: {
			url: config.apiResendEmployerVerificationMail,
			method: "post",
			data: params,
			onStart: actions.RESEND_INVITE_START,
			onSuccess: actions.RESEND_INVITE,
			onError: actions.RESEND_INVITE_FAILED,
		},
	};
}

export function resendCompanyInfoPrompt(params) {
	return {
		type: config.apiRequestStart,
		payload: {
			url: config.apiResendEmployerCompanyInfoPrompt,
			method: "post",
			data: params,
			onStart: actions.RESEND_INVITE_START,
			onSuccess: actions.RESEND_INVITE,
			onError: actions.RESEND_INVITE_FAILED,
		},
	};
}

export function resendUnenrolledEmployeesInvite(params) {
	return {
		type: config.apiRequestStart,
		payload: {
			url: config.apiResendUnenrolledEmployeesInvite,
			method: "post",
			data: params,
			onStart: actions.RESEND_UNENROLLED_EMPLOYEES_INVITE_START,
			onSuccess: actions.RESEND_UNENROLLED_EMPLOYEES_INVITE,
			onError: actions.RESEND_UNENROLLED_EMPLOYEES_INVITE_FAILED,
		},
	};
}

export function resetReloadPage() {
	return {
		type: actions.RELOAD_PAGE_RESET,
		payload: {},
	};
}

export function getPerformanceEmployees(params) {
	return {
		type: config.apiRequestStart,
		payload: {
			url: config.apiPerformanceEmployees + ObjectToQueryString(params),
			method: "get",
			data: {},
			onStart: actions.GET_PERFORMANCE_EMPLOYEES_START,
			onSuccess: actions.GET_PERFORMANCE_EMPLOYEES,
			onError: actions.GET_PERFORMANCE_EMPLOYEES_FAILED,
			// useLoginJwt: false,
		},
	};
}

export function getPerformanceEmployeesWithdrawals(params) {
	return {
		type: config.apiRequestStart,
		payload: {
			url:
				config.apiPerformanceEmployeesWithdrawals + ObjectToQueryString(params),
			method: "get",
			data: {},
			onStart: actions.GET_PERFORMANCE_EMPLOYEES_WITHDRAWALS_START,
			onSuccess: actions.GET_PERFORMANCE_EMPLOYEES_WITHDRAWALS,
			onError: actions.GET_PERFORMANCE_EMPLOYEES_WITHDRAWALS_FAILED,
		},
	};
}

export function generateERA(params) {
	return {
		type: config.apiRequestStart,
		payload: {
			url: config.apiGenerateEVA,
			method: "post",
			data: params,
			onStart: actions.GENERATE_ERA_START,
			onSuccess: actions.GENERATE_ERA,
			onError: actions.GENERATE_ERA_FAILED,
		},
	};
}
