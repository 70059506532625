import React, { Component } from "react";
import store from "store/store";
import queryString from "query-string";
import DashboardBoxes from "components/common/dashboard-boxes";
import Pagination from "components/common/pagination";
import Dropdown from "utils/dropdown";
import Select from "react-select";
import pluralize from "utils/pluralize";
import DialogBox from "utils/dialogBox";
import Joi from "joi-browser";
import { Dialog } from "@headlessui/react";
import { DateTimeFormat } from "utils/dateFormat";
import { Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { customizeSVG } from "utils/customizeSVG.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Download from "components/common/download";
import SearchFilter from "components/common/filter";
import { ReactComponent as IconEmployees } from "assets/images/icons/project-icons/employees.svg";
import { ReactComponent as IconNav } from "assets/images/icons/project-icons/Navicon-v.svg";
import * as employees from "store/entities/employees/action";
import * as employers from "store/entities/employers/action";
import { nameToSentenceCase } from "utils/nameToSentenceCase";
import Maybe from "components/__new/common/Maybe";

const dropdownTableIcon = (
	<IconNav className="w-8 h-8 p-1.5 relative top-1 cursor-pointer group-hover:bg-gray-200 rounded" />
);

const columns = [
	{ label: "ID", key: "_id" },
	{ label: "Employee ID", key: "employeeId" },
	{ label: "Full Name", key: "fullName" },
	{ label: "Email Address", key: "email" },
	{ label: "Phone Number", key: "phoneNumber" },
	{ label: "Company Name", key: "employer.companyName" },
	{ label: "Net Monthly Salary", key: "netMonthlySalary" },
	{ label: "Bank Name", key: "bankName" },
	{ label: "Account Number", key: "bankAccountNumber" },
	{ label: "Job Title", key: "jobTitle" },
	{ label: "Group", key: "employeeGroup" },
	{ label: "Date Added", key: "createdAt" },
	{ label: "Status", key: "status" },
	{ label: "Stop Earnings", key: "stopEarning" },
	{ label: "Disabed", key: "disabled" },
];

class List extends Component {
	constructor(props) {
		super(props);
		this.csvLinkEl = React.createRef();
		this.completeButtonRef = React.createRef(null);
	}

	state = {
		getAllEmployeesParams: {
			page: 1,
			limit: 20,
			status: "",
			download: false,
		},
		getAllEmployerEmployeesParams: {
			page: 1,
			limit: 20,
			status: "",
			name: "",
			download: false,
		},
		dashboardProps1: {
			iconBg: "bg-blue-200",
			icon: (
				<IconEmployees className="customizeSVG text-blue-600 m-auto w-5 h-5" />
			),
			titleText: "Total Employees",
			mainText: "-",
		},
		dashboardProps2: {
			iconBg: "bg-green-200",
			icon: (
				<FontAwesomeIcon
					icon="check-circle"
					className="customizeSVG text-green-600 m-auto w-5 h-5"
				/>
			),
			titleText: "Enrolled Employees",
			mainText: "-",
		},
		dashboardProps3: {
			iconBg: "bg-yellow-200",
			icon: (
				<FontAwesomeIcon
					icon="dot-circle"
					className="customizeSVG text-yellow-600 m-auto w-5 h-5"
				/>
			),
			titleText: "Active Employees",
			mainText: "-",
		},
		withdrawalChargeForm: {
			withdrawalChargeAmount: "",
			withdrawalChargeReason: "",
		},
		toggleModalWithdrawalCharge: false,
		employee: {},
		storeUnsubscribe: "",
		employerFilterOptions: [],
		employerFilter: "",
	};

	employerFilterValue = (employerId) => {
		// console.log(employerId);
		this.setState({ employerFilter: employerId });
		this.gotoUrlQuery({ employerId: employerId.value });
	};

	clearEmployerFilterOptions = () => {
		this.setState({ employerFilter: "" });
		this.gotoUrlQuery({ employerId: "" });
	};

	gotoUrlQuery = (params) => {
		const qs = queryString.parse(this.props.location.search);

		Object.keys(params).forEach((paramName) => {
			// console.log("qs", qs[paramName]);
			delete qs[paramName];
		});

		let qsToUrl = new URLSearchParams(qs).toString();
		qsToUrl =
			this.props.location.pathname +
			"?" +
			(qsToUrl !== "" ? qsToUrl + "&" : "");

		Object.keys(params).forEach((paramName) => {
			if (params[paramName] !== "") {
				qsToUrl += paramName + "=" + params[paramName] + "&";
			}
		});

		qsToUrl = qsToUrl.substring(0, qsToUrl.length - 1);
		this.props.history.push(qsToUrl);
	};

	selectEmployerFilter = () => {
		const qs = queryString.parse(this.props.location.search);
		const { employerFilterOptions } = this.state;
		let employerName = "";

		if (
			typeof qs.employerId !== undefined &&
			qs.employerId !== undefined &&
			qs.employerId !== null
		) {
			let employerFilter = { value: qs.employerId, label: "" };
			if (Object.keys(employerFilterOptions).length > 0) {
				Object.keys(employerFilterOptions).forEach((employer) => {
					if (employerFilterOptions[employer].value === qs.employerId) {
						employerName = employerFilterOptions[employer].label;
					}
				});

				if (employerName !== "") {
					employerFilter = { ...employerFilter, label: employerName };
				} else {
					if(!qs?.accountType) {
						toast.error("Invalid employee firm selected");
					}
				}
			}
			this.setState({ employerFilter });
		}
	};

	getAllEmployers = () => {
		const getAllEmployersParams = {
			page: 1,
			limit: 1000,
			download: false,
			status: "",
			name: "",
			keyword: "",
			registeredFrom: "",
			registeredTo: "",
			features: "",
			disabled: ""
		};
		this.props.getAllEmployers(getAllEmployersParams);
	};

	getAllQueryFilters = (params) => {
		const qs = queryString.parse(this.props.location.search);

		if (qs.search !== undefined && qs.search !== null) {
			params = { ...params, keyword: qs.search };
		} else {
			params = { ...params, keyword: "" };
		}

		if (qs.disabled !== undefined && qs.disabled !== null) {
			params = { ...params, disabled: qs.disabled === "true" ? true : false };
		} else {
			params = { ...params, disabled: "" };
		}

		if (qs.registeredFrom !== undefined && qs.registeredFrom !== null) {
			params = { ...params, registeredFrom: qs.registeredFrom };
		} else {
			params = { ...params, registeredFrom: "" };
		}

		if (qs.registeredTo !== undefined && qs.registeredTo !== null) {
			params = { ...params, registeredTo: qs.registeredTo };
		} else {
			params = { ...params, registeredTo: "" };
		}

		if (qs.salaryFrom !== undefined && qs.salaryFrom !== null) {
			params = { ...params, salaryFrom: parseInt(qs.salaryFrom) };
		} else {
			params = { ...params, salaryFrom: "" };
		}

		if (qs.salaryTo !== undefined && qs.salaryTo !== null) {
			params = { ...params, salaryTo: parseInt(qs.salaryTo) };
		} else {
			params = { ...params, salaryTo: "" };
		}

		if (
			typeof qs.employerId !== undefined &&
			qs.employerId !== undefined &&
			qs.employerId !== null
		) {
			params = { ...params, employerId: qs.employerId };
		} else {
			params = { ...params, employerId: "" };
		}

		return params;
	};

	getAllEmployees = () => {
		const qs = queryString.parse(this.props.location.search);
		let getAllEmployeesParams = { ...this.state.getAllEmployeesParams };
		let getAllEmployerEmployeesParams = {
			...this.state.getAllEmployerEmployeesParams,
		};

		if (qs.page !== undefined && !isNaN(qs.page) && qs.page > 0) {
			getAllEmployeesParams = { ...getAllEmployeesParams, page: qs.page };
			getAllEmployerEmployeesParams = {
				...getAllEmployerEmployeesParams,
				page: qs.page,
			};
		} else {
			getAllEmployeesParams = { ...getAllEmployeesParams, page: 1 };
			getAllEmployerEmployeesParams = {
				...getAllEmployerEmployeesParams,
				page: 1,
			};
		}

		if (qs.limit !== undefined && !isNaN(qs.limit) && qs.limit > 0) {
			getAllEmployeesParams = { ...getAllEmployeesParams, limit: qs.limit };
			getAllEmployerEmployeesParams = {
				...getAllEmployerEmployeesParams,
				limit: qs.limit,
			};
		} else {
			getAllEmployeesParams = { ...getAllEmployeesParams, limit: 20 };
			getAllEmployerEmployeesParams = {
				...getAllEmployerEmployeesParams,
				limit: 20,
			};
		}

		if (qs.status !== undefined && qs.status !== null) {
			getAllEmployeesParams = { ...getAllEmployeesParams, status: qs.status };
			getAllEmployerEmployeesParams = {
				...getAllEmployerEmployeesParams,
				status: qs.status,
			};
		} else {
			getAllEmployeesParams = { ...getAllEmployeesParams, status: "" };
			getAllEmployerEmployeesParams = {
				...getAllEmployerEmployeesParams,
				status: "",
			};
		}

		getAllEmployeesParams = this.getAllQueryFilters(getAllEmployeesParams);
		getAllEmployerEmployeesParams = this.getAllQueryFilters(
			getAllEmployerEmployeesParams
		);

		getAllEmployeesParams = { ...getAllEmployeesParams, download: false };
		getAllEmployerEmployeesParams = {
			...getAllEmployerEmployeesParams,
			download: false,
		};

		const searchParams = {
			...this.state.search,
		};

		this.setState({
			getAllEmployeesParams,
			getAllEmployerEmployeesParams,
			search: searchParams,
		});

		this.props.getAllEmployees(getAllEmployeesParams);
	};

	getAllEmployeesCount = () => {
		const { getAllEmployeesParams } = this.state;
		const params = this.getAllQueryFilters(getAllEmployeesParams);
		this.props.getAllEmployeesCount(params);
	};

	transformDataDownload = (data) => {
		const newData = data?.map((row) => {
			const newRow = {
				...row,
				fullName: nameToSentenceCase(row.fullName) ?? "",
			}
			return newRow;
		})
		return newData;
	}

	activateEmployee = async (employee_id) => {
		const result = await DialogBox();
		if (result) {
			this.props.activateEmployee(employee_id);
		}
	};

	deactivateEmployee = async (employee_id) => {
		const result = await DialogBox({
			theme: "red",
		});
		if (result) {
			this.props.deactivateEmployee(employee_id);
		}
	};

	archiveEmployee = async (employee_id) => {
		const result = await DialogBox({
			theme: "red",
		});
		if (result) {
			this.props.archiveEmployee(employee_id);
		}
	};

	resendInvite = async (email) => {
		const result = await DialogBox();
		if (result) {
			const resendInviteParams = {
				email
			};
			this.props.resendInvite(resendInviteParams);
			toast("Resending invite to " + email);
		}
	};

	// generateERA = async (userId, type) => {
	// 	const result = await DialogBox();
	// 	if (result) {
	// 		const params = {
	// 			userId,
	// 			type,
	// 		};
	// 		this.props.generateERA(params);
	// 		toast("Generating Reconciliation Account");
	// 	}
	// };

	toggleModalWithdrawalCharge = (action, employee = {}) => {
		this.setState({ toggleModalWithdrawalCharge: action, employee });
	};

	formChangeWithdrawalCharge = (e) => {
		const formValue = { ...this.state.withdrawalChargeForm };
		formValue[e.currentTarget.name] = e.currentTarget.value;
		this.setState({ withdrawalChargeForm: formValue });
	};

	schema = {
		withdrawalChargeAmount: Joi.number()
			.integer()
			.required()
			.label("Withdrawal Charge Amount")
			.error((errors) => {
				errors.forEach((err) => {
					switch (err.type) {
						case "number.base":
							err.message = "Please enter a withdrawal charge amount";
							break;
						default:
							break;
					}
				});
				return errors;
			}),
		withdrawalChargeReason: Joi.string()
			.required()
			.label("Withdrawal Charge Reason")
			.error((errors) => {
				errors.forEach((err) => {
					switch (err.type) {
						case "any.empty":
							err.message = "Please enter a withdrawal charge reason";
							break;
						default:
							break;
					}
				});
				return errors;
			}),
	};

	validateWithdrawalCharge = () => {
		const options = { abortEarly: false };
		const result = Joi.validate(
			this.state.withdrawalChargeForm,
			this.schema,
			options
		);
		const error = result.error;
		if (!error) return null;

		const errors = {};
		for (let item of result.error.details) {
			errors[item.path[0]] = item.message;
		}
		return errors;
	};

	submitWithdrawalCharge = (e) => {
		e.preventDefault();
		const errors = this.validateWithdrawalCharge();
		this.setState({ errors: errors || {} });
		if (errors) {
			for (var x in errors) {
				toast.error(errors[x]);
			}
			return;
		} else {
			const { employee, withdrawalChargeForm } = this.state;
			const params = {
				employeeId: employee._id,
				amount: withdrawalChargeForm.withdrawalChargeAmount,
				reason: withdrawalChargeForm.withdrawalChargeReason,
			};
			this.props.withdrawalCharge(params).then(() => {
				if (this.props.withdrawalChargeStatus.status) {
					this.toggleModalWithdrawalCharge(false);
				}
			});
		}
	};

	componentDidMount() {
		this.getAllEmployees();
		this.getAllEmployeesCount();
		// this.getAllEmployers();
		this.selectEmployerFilter();
		// this.employerFilterToggle();

		this.unsubscribe = store.subscribe(() => {
			if (this.props.allEmployersLoading === false) {
				this.unsubscribe();
				const allEmployers = this.props.allEmployers?.data?.employers;
				let employerFilterOptions = [];
				if (allEmployers !== undefined && allEmployers !== null) {
					Object.keys(allEmployers).forEach(function (key) {
						if(allEmployers[key].companyName?.length || allEmployers[key]?.company?.companyName?.length) {
							employerFilterOptions.push({
								value: allEmployers[key]._id,
								label: allEmployers[key].companyName || allEmployers[key]?.company?.companyName,
							});
						}
					});
				}
				this.setState({ employerFilterOptions });
				this.selectEmployerFilter();
			}
		});
		this.setState({ storeUnsubscribe: this.unsubscribe });
	}

	componentDidUpdate(prevProps) {
		if (this.props.location !== prevProps.location) {
			this.getAllEmployees();
			this.getAllEmployeesCount();
			this.selectEmployerFilter();
		}
		if (this.props.reloadPage === true) {
			setTimeout(() => {
				this.props.resetReloadPage();
				this.props.history.replace(
					this.props.location.pathname + this.props.location.search
				);
			}, 1000);
		}
	}

	componentWillUnmount() {
		const storeUnsubscribe = this.state.storeUnsubscribe;
		if (
			storeUnsubscribe !== "" &&
			storeUnsubscribe !== undefined &&
			storeUnsubscribe !== null
		) {
			storeUnsubscribe();
		}
	}

	render() {
		customizeSVG();
		const { userType: userRole } = this.props.user;
		const { allEmployeesCount, allEmployersLoading } = this.props;
		let { allEmployees: data, allEmployeesLoading: loading } = this.props;
		const {
			withdrawalChargeForm,
			toggleModalWithdrawalCharge,
			employee,
			getAllEmployeesParams,
			getAllEmployerEmployeesParams,
			employerFilterOptions,
			employerFilter,
		} = this.state;

		const qs = queryString.parse(this.props.location.search);

		return (
			<div>
				{toggleModalWithdrawalCharge === true && (
					<Dialog
						open={toggleModalWithdrawalCharge}
						initialFocus={this.completeButtonRef}
						onClose={() => this.toggleModalWithdrawalCharge(false)}
						className="fixed z-10 inset-0 overflow-y-auto">
						<div className="flex items-center justify-center min-h-screen">
							<Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />

							<div className="w-full max-w-lg mx-auto relative bg-white rounded overflow-hidden">
								<div className="h-12 px-4 bg-ep-blue flex justify-between overflow-hidden">
									<div className="my-auto text-white">
										Withdrawal Charge for {employee.fullName}
									</div>
									<div
										onClick={() => this.toggleModalWithdrawalCharge(false)}
										ref={this.completeButtonRef}
										className="w-7 h-7 flex my-auto border group hover:bg-red-400 hover:border-red-400 border-white rounded-lg cursor-pointer">
										<FontAwesomeIcon
											icon="times"
											className="text-white m-auto"
										/>
									</div>
								</div>

								<form className="p-6" onSubmit={this.submitWithdrawalCharge}>
									<div>
										<div className="form-group">
											<label>
												Amount
												<span className="form-input-required">*</span>
											</label>
											<input
												type="number"
												name="withdrawalChargeAmount"
												placeholder="Enter charge amount"
												className="form-input"
												value={withdrawalChargeForm.withdrawalChargeAmount}
												onChange={this.formChangeWithdrawalCharge}
											/>
										</div>
										<div className="form-group">
											<label>
												Reason
												<span className="form-input-required">*</span>
											</label>
											<textarea
												name="withdrawalChargeReason"
												placeholder="Enter charge reason"
												className="form-input"
												value={withdrawalChargeForm.withdrawalChargeReason}
												onChange={this.formChangeWithdrawalCharge}
												style={{ height: "100px" }}></textarea>
										</div>
									</div>

									<div className="mt-6 flex justify-end space-x-2">
										<button
											onClick={() => this.toggleModalWithdrawalCharge(false)}
											className="btn btn-transparent-black">
											Close
										</button>
										<button
											type="submit"
											className="btn btn-ep-blue"
											disabled={this.props.withdrawalChargeLoading}>
											<FontAwesomeIcon
												icon="spinner"
												spin
												className={
													"text-xl mr-2 " +
													(this.props.withdrawalChargeLoading
														? "inline"
														: "hidden")
												}
											/>
											Submit
										</button>
									</div>
								</form>
							</div>
						</div>
					</Dialog>
				)}

				<div>
					
				<div className="bg-white overflow-auto mb-7">
					<div className="flex text-center space-x-0">
						<NavLink
							to="/users/employees"
							className="tab px-10 md:px-20 flex-shrink-0">
							Employees
						</NavLink>
						<NavLink
							to="/users/customers"
							className="tab px-10 md:px-20 flex-shrink-0">
							Customers
						</NavLink>
						{/* <NavLink
							to="/users/account-deletion/"
							className="tab px-10 md:px-20 flex-shrink-0">
							Customers (Account Deletion)
						</NavLink> */}
					</div>
				</div>
					
					<div className="lg:flex">
						<div className="w-full lg:w-1/2">
							<div className="page-title">Employees</div>
						</div>
						<div className="w-full lg:w-1/2 flex space-x-4 mt-2 lg:mt-0 lg:justify-end">
							<div>
								<Download
									tooltip="Download employee list"
									format="csv"
									filename="All Employees.csv"
									columns={columns}
									ref={(instance) => {
										this.Download = instance;
									}}
									click={() => {
										this.props
											.downloadGetAllEmployees({
												...getAllEmployeesParams,
												page: 1,
												// limit: 10000,
												download: true
											})
											.then(() => {
												this.Download.processDownload();
											});
									}}
									loading={this.props.downloadLoading}
									response={this.transformDataDownload(this.props.downloadAllEmployees.docs)}
								/>
							</div>

							<div>
								<SearchFilter
									search={{
										name: "search",
										placeholder: "Keyword search",
									}}
									filters={[
										{
											title: "Status",
											name: "status",
											dataType: "radio",
											options: [
												{
													display: "All",
													payload: "",
												},
												{
													display: "Enrolled",
													payload: "active",
												},
												{
													display: "Unenrolled",
													payload: "pending",
												},
											],
										},
										{
											title: "Active Status",
											name: "disabled",
											dataType: "radio",
											options: [
												{
													display: "All",
													payload: "",
												},
												{
													display: "Active",
													payload: "false",
												},
												{
													display: "Inactive",
													payload: "true",
												},
											],
										},
										{
											title: "Registration Date",
											dataType: "date-range",
											options: {
												from: {
													name: "registeredFrom",
													placeholder: "Start Date",
												},
												to: {
													name: "registeredTo",
													placeholder: "End Date",
												},
											},
										},
										{
											title: "Salary",
											dataType: "number-range",
											options: {
												from: {
													name: "salaryFrom",
													placeholder: "₦0.00",
												},
												to: {
													name: "salaryTo",
													placeholder: "₦0.00",
												},
											},
										},
									]}
								/>
							</div>
						</div>
					</div>

					<div className="mt-6">
						<div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-6">
							<DashboardBoxes
								data={this.state.dashboardProps1}
								loading={this.props.allEmployeesCountLoading}
								result={
									allEmployeesCount ? allEmployeesCount.totalEmployees : ""
								}
							/>
							<DashboardBoxes
								data={this.state.dashboardProps2}
								loading={this.props.allEmployeesCountLoading}
								result={
									allEmployeesCount ? allEmployeesCount.enrolledEmployees : ""
								}
							/>
							<DashboardBoxes
								data={this.state.dashboardProps3}
								loading={this.props.allEmployeesCountLoading}
								tooltip={
									<span>
										These are Employees that have Withdrawn once/more. <br />
										The number in bracket () are the enabled employees that have
										withdrawm Once/More
									</span>
								}
								tooltipIndex="withdraw-once-more"
								result={
									allEmployeesCount && (
										<>
											<span>
												{allEmployeesCount.totalEmployeesWithdrawn}&nbsp;
											</span>
											<span className="text-green-500">
												({allEmployeesCount.totalEnabledEmployeesWithdrawn})
											</span>
										</>
									)
								}
							/>
						</div>
					</div>

					<div className="w-full lg:flex mt-6 mb-4 sm:mb-0">
						<div className="w-full lg:w-1/2 flex space-x-3">
							{/* <div>
								{allEmployersLoading === true && (
									<div className="w-full px-3 py-2 border border-gray-200 rounded">
										<FontAwesomeIcon icon="spinner" spin className="mr-1.5" />
										Loading
									</div>
								)}
								{allEmployersLoading === false && (
									<div className="w-full">
										<Select
											isDisabled={qs?.accountType === "sub"}
											options={employerFilterOptions}
											placeholder="Search Employees Firm"
											value={employerFilter}
											onChange={this.employerFilterValue}
										/>
									</div>
								)}
							</div> */}
							<div className="lg:col-span-2 flex">
								{(allEmployersLoading === false && employerFilter !== ""  && !qs?.accountType)&& (
									<div className="mx-auto sm:mx-0 my-auto flex text-sm">
										<div className="hidden mr-6">
											{data.totalDocs} {pluralize("result", data.totalDocs)}{" "}
											found
										</div>
										<div
											onClick={this.clearEmployerFilterOptions}
											className="cursor-pointer text-red-500 border-b-2 border-transparent hover:border-red-500">
											<FontAwesomeIcon icon="times" className="mr-1" />
											Clear Filter
										</div>
									</div>
								)}
							</div>
						</div>

						<div className="w-full lg:w-1/2 lg:flex lg:justify-end mt-1 lg:mt-0">
							<div className="w-full lg:w-auto inline-block box-border-only overflow-auto">
								<div className="h-10 flex">
									<div
										onClick={() => this.gotoUrlQuery({ status: "" })}
										className={
											"cursor-pointer page-nav " +
											(getAllEmployeesParams.status === "" ||
											getAllEmployerEmployeesParams.status === ""
												? "active"
												: "")
										}>
										<div className="whitespace-nowrap">All Employees</div>
									</div>
									<div
										onClick={() => this.gotoUrlQuery({ status: "active" })}
										className={
											"cursor-pointer page-nav " +
											(getAllEmployeesParams.status === "active" ||
											getAllEmployerEmployeesParams.status === "active"
												? "active"
												: "")
										}>
										<div className="whitespace-nowrap">Enrolled Employees</div>
									</div>
									<div
										onClick={() => this.gotoUrlQuery({ status: "pending" })}
										className={
											"cursor-pointer page-nav " +
											(getAllEmployeesParams.status === "pending" ||
											getAllEmployerEmployeesParams.status === "pending"
												? "active"
												: "")
										}>
										<div className="whitespace-nowrap">
											Unenrolled Employees
										</div>
									</div>
									<Link
										to="/users/employees/withdraw-once-or-more"
										className="cursor-pointer page-nav">
										<div className="whitespace-nowrap">Active Employees</div>
									</Link>
								</div>
							</div>
						</div>
					</div>

					<div className="mt-3">
						<div className="">
							<div className="table-container">
								<table className="table table-auto table-rounded table-border">
									<thead>
										<tr>
											<th>Date</th>
											<th>Name</th>
											<th>Job Title</th>
											<th>Company Name</th>
											<th>Status</th>
											<th>Disabled</th>
											<th>Actions</th>
										</tr>
									</thead>
									<tbody>
										{loading === true && (
											<tr>
												<td colSpan="7">
													<div className="table-info">
														<FontAwesomeIcon icon="spinner" spin />
														<div className="font-bold uppercase">Loading</div>
													</div>
												</td>
											</tr>
										)}

										{loading === false && data && !data.docs && (
											<tr>
												<td colSpan="7">
													<div className="table-info">
														<FontAwesomeIcon icon="unlink" />
														<div className="font-bold uppercase">
															An error occurred
															<br />
															Please try again later.
														</div>
													</div>
												</td>
											</tr>
										)}

										{loading === false &&
											data &&
											data.docs &&
											data.docs.length <= 0 && (
												<tr>
													<td colSpan="7">
														{data.page <= data.totalPages && (
															<div className="table-info">
																<FontAwesomeIcon icon="list" />
																<div className="font-bold uppercase">
																	No results found
																</div>
															</div>
														)}

														{data.page > data.totalPages && (
															<div className="table-info">
																<FontAwesomeIcon icon="exclamation-triangle" />
																<div className="font-bold uppercase">
																	Invalid Page Number
																</div>
																<div className="mt-2">
																	<div
																		onClick={() =>
																			this.gotoUrlQuery({ page: "1" })
																		}
																		className="btn btn-ep-blue btn-sm mx-auto">
																		Goto Page 1
																	</div>
																</div>
															</div>
														)}
													</td>
												</tr>
											)}

										{loading === false &&
											data &&
											data.docs &&
											data.docs.length > 0 &&
											data.docs.map((employee, index) => (
												<tr key={index}>
													<td>{DateTimeFormat(employee.createdAt)}</td>
													<td>
														<div>
															<Link to={"/users/employees/details/" + employee._id} className="capitalize hover:underline">
																<Maybe condition={employee?.fullName !== undefined}>
																	{employee?.fullName}
																</Maybe>
																<Maybe condition={employee?.fullName === undefined}>
																	{employee.firstName ?? "-"} {employee.lastName ?? ""}
																</Maybe>
															</Link>
														</div>
														<div className="text-fade">
															<div>
																<Link
																	to={{ pathname: "mailto:" + employee.email }}
																	target="_blank"
																	onClick={(e) => {
																		window.open(
																			e.target.getAttribute("href"),
																			"_blank"
																		);
																		e.preventDefault();
																	}}
																	className="hover:underline">
																	{employee.email}
																</Link>
															</div>
															<div>
																<Link
																	to={{
																		pathname: "tel:" + employee.phoneNumber,
																	}}
																	target="_blank"
																	onClick={(e) => {
																		window.open(
																			e.target.getAttribute("href"),
																			"_blank"
																		);
																		e.preventDefault();
																	}}
																	className="hover:underline">
																	{employee.phoneNumber}
																</Link>
															</div>
														</div>
													</td>
													<td>{employee.jobTitle}</td>
													<td>
														{employee?.company || ""}
													</td>
													<td>
														{employee.status === "pending" && (
															<div className="label label-yellow uppercase">
																Pending
															</div>
														)}
														{employee.status === "active" && (
															<div className="label label-blue uppercase">
																Enrolled
															</div>
														)}
														{employee.status === "disabled" && (
															<div className="label label-red uppercase">
																Disabled
															</div>
														)}
													</td>
													<td>
														{employee.disabled === false && (
															<div className="label label-green uppercase">
																Active
															</div>
														)}
														{employee.disabled === true && (
															<div className="label label-red uppercase">
																Disabled
															</div>
														)}
													</td>
													<td>
														<div>
															<Dropdown
																icon={dropdownTableIcon}
																menu={[
																	{
																		type: "link",
																		text: "View Details",
																		link: `/users/employees/details/${employee._id}`,
																	},
																	userRole === "super-admin" ||
																	userRole === "customer-success-admin"
																		? employee.disabled
																			? {
																					type: "div",
																					text: "Activate",
																					click: () =>
																						this.activateEmployee(employee._id),
																			  }
																			: {
																					type: "div",
																					text: "Deactivate",
																					click: () =>
																						this.deactivateEmployee(
																							employee._id
																						),
																			  }
																		: "",
																	employee.status === "pending"
																		? {
																				type: "div",
																				text: "Resend Invite",
																				click: () =>
																					this.resendInvite(employee.email),
																		  }
																		: "",
																	userRole === "super-admin"
																		? {
																				type: "div",
																				text: "Withdrawal Charge",
																				click: () =>
																					this.toggleModalWithdrawalCharge(
																						true,
																						employee
																					),
																		  }
																		: "",
																	// {type: "div", text: "Delete Employee", click: () => this.archiveEmployee(employee._id)}
																]}
															/>
														</div>
													</td>
												</tr>
											))}
									</tbody>
								</table>
							</div>

							{loading === false &&
								data &&
								data.docs &&
								data.docs.length > 0 && (
									<div>
										<Pagination
											data={data}
											url={this.props.location.search}
											limit={getAllEmployeesParams.limit}
										/>
									</div>
								)}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	user: state.auth.userPersist.data,

	allEmployeesLoading: state.entities.employees.loading,
	allEmployees: state.entities.employees.allEmployees,
	downloadLoading: state.entities.employees.downloadLoading,
	downloadAllEmployees: state.entities.employees.downloadAllEmployees,

	allEmployerEmployeeLoading: state.entities.employers.employerEmployeeLoading,
	allEmployerEmployee: state.entities.employers.allEmployerEmployee,

	allEmployeesCountLoading: state.entities.employees.allEmployeesCountLoading,
	allEmployeesCount: state.entities.employees.allEmployeesCount,
	reloadPage: state.entities.employees.reloadPage,
	allEmployersLoading: state.entities.employers.loading,
	allEmployers: state.entities.employers.allEmployers,

	downloadEmployerEmployeeLoading:
		state.entities.employers.downloadEmployerEmployeeLoading,
	downloadAllEmployerEmployee:
		state.entities.employers.downloadAllEmployerEmployee,

	generatedERALoading: state.entities.employees.generatedERALoading,
	generatedERA: state.entities.employees.generatedERA,

	withdrawalChargeLoading: state.entities.employees.withdrawalChargeLoading,
	withdrawalChargeStatus: state.entities.employees.withdrawalChargeStatus,
});

const mapDispatchToProps = (dispatch) => ({
	getAllEmployees: (params) => dispatch(employees.getAllEmployees(params)),
	downloadGetAllEmployees: (params) =>
		dispatch(employees.downloadGetAllEmployees(params)),
	getAllEmployeesCount: (params) =>
		dispatch(employees.getAllEmployeesCount(params)),
	activateEmployee: (employee_id) =>
		dispatch(employees.activateEmployee(employee_id)),
	deactivateEmployee: (employee_id) =>
		dispatch(employees.deactivateEmployee(employee_id)),
	archiveEmployee: (employee_id) =>
		dispatch(employees.archiveEmployee(employee_id)),
	resendInvite: (params) => dispatch(employees.resendInvite(params)),
	generateERA: (params) => dispatch(employees.generateERA(params)),
	withdrawalCharge: (params) => dispatch(employees.withdrawalCharge(params)),
	resetReloadPage: () => dispatch(employees.resetReloadPage()),
	getAllEmployers: (params) => dispatch(employers.getAllEmployers(params)),
	getAllEmployerEmployees: (employerId, params) =>
		dispatch(employers.getAllEmployerEmployees(employerId, params)),
	downloadGetAllEmployerEmployees: (employerId, params) =>
		dispatch(employers.downloadGetAllEmployerEmployees(employerId, params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(List);
