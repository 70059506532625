import React from "react";
import errorIcon from "assets/images/icons/error-icon-only.svg";

export const UnderMaintenance = () => {
	return (
		<div className="flex overflow-hidden justify-center">
			<div className="">
				<div className="h-24 sm:h-32 md:h-56">
					<img
						src={errorIcon}
						alt="EarniPay"
						className="h-full object-contain mx-auto"
					/>
				</div>
				<div className="mt-8 font-boing font-semibold text-2xl sm:text-3xl">
					Page Is Currently Under Maintenance
				</div>
			</div>
		</div>
	);
};
