import { combineReducers } from "redux";
import dashboardReducer from "./dashboard/reducer";
import employeesReducer from "./employees/reducer";
import employersReducer from "./employers/reducer";
import complianceReducer from "./compliance/reducer";
import adminReducer from "./admin/reducer";
import withdrawalReducer from "./withdrawals/reducer";
import payrollReducer from "./payroll/reducer";
import paymentsReducer from "./payments/reducer";
import rewardsReducer from "./rewards/reducer";
import reportsReducer from "./reports/reducer";
import approvalsReducer from "./approvals/reducer";
import referralsReducer from "./referrals/reducer";
import ambassadorProgramReducer from "./ambassadorProgram/reducer";
import integrationsReducer from "./integrations/reducer";
import settingsReducer from "./settings/reducer";
import auditTrailReducer from "./auditTrail/reducer";
import savingsReducer from "./savings/reducer";
import creditReducer from "./credit/reducer";
import subAccountsReducer from "./subAccounts/reducer";

export default combineReducers({
	dashboard: dashboardReducer,
	employees: employeesReducer,
	employers: employersReducer,
	compliance: complianceReducer,
	admin: adminReducer,
	withdrawals: withdrawalReducer,
	payroll: payrollReducer,
	payments: paymentsReducer,
	rewards: rewardsReducer,
	approvals: approvalsReducer,
	reports: reportsReducer,
	referrals: referralsReducer,
	ambassadorProgram: ambassadorProgramReducer,
	integrations: integrationsReducer,
	settings: settingsReducer,
	auditTrail: auditTrailReducer,
	savings: savingsReducer,
	credit: creditReducer,
	subAccounts: subAccountsReducer,
});
