import React, { Component } from 'react';
import ReactToolTip from 'react-tooltip';
import { toast } from 'react-toastify';
import { CSVLink } from "react-csv";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactComponent as DownloadIcon } from "assets/images/icons/project-icons/Download.svg";



class Download extends Component {
    constructor(props) {
        super(props);
        this.csvLinkEl = React.createRef();
    }


    state = {
        result: [],
    }


    getProps = () => {
        return this.props;
    }


    processDownload = () => {
        if (this.getProps().loading === false){
            let response = "";
            if (this.getProps().response.docs){
                response = this.getProps().response.docs;
            }
            else{
                response = this.getProps().response;
            }
            
            if (response !== ""){
                const data = Object.values(response);
                // console.log(data);

                this.setState({ result: data }, () => {
                    if(data?.length > 0) {
                        setTimeout(() => {
                            // this.csvLinkEl.current.link.click();
                            toast.success(`The requested document has been forwarded to your email`);
                            // toast.success("Download complete");
                        });
                    }
                });

            }
            else{
                toast.error("An error occurred while downloading file.");
            }

        }
    }
    

    render() { 
        const { result } = this.state;
        const { tooltip, filename, columns, click, loading } = this.props;

    
        return ( 
            <>
                <div>
                    <CSVLink filename={filename} data={result} headers={columns} ref={this.csvLinkEl} />
                    
                    <div onClick={click} data-tip data-for='download' className="w-9 h-9 flex box-border-only cursor-pointer">
                        <FontAwesomeIcon icon="spinner" spin className={"m-auto " + (loading?"inline":"hidden")} />
                        <DownloadIcon className={"customizeSVG m-auto " + (loading?"hidden":"inline")} />
                    </div>

                    {tooltip &&
                        <ReactToolTip id='download' className='tooptip-size'>
                            {tooltip}
                        </ReactToolTip>
                    }
                </div>
            </>
        );
    }
}
 

export default Download;