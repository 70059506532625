import * as actions from "./actionTypes";
import * as config from "config";
import ObjectToQueryString from 'utils/ObjectToQueryString';

export function getAllDashboard(params){
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetAllDashboard + ObjectToQueryString(params),
            method: "get",
            data: {},
            onStart: actions.GET_ALL_DASHBOARD_START,
            onSuccess: actions.GET_ALL_DASHBOARD,
            onError: actions.GET_ALL_DASHBOARD_FAILED,
            // useLoginJwt: false,
        },
    }
}

export function getDashboardPerformanceRevenue(params) {
    // console.log("ObjectToQueryString", ObjectToQueryString(params));

    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetDashboardPerformanceRevenue + ObjectToQueryString(params),
            method: "get",
            data: {},
            onStart: actions.GET_DASHBOARD_PERFORMANCE_REVENUE_START,
            onSuccess: actions.GET_DASHBOARD_PERFORMANCE_REVENUE,
            onError: actions.GET_DASHBOARD_PERFORMANCE_REVENUE_FAILED,
            // useLoginJwt: false,
        },
    }
}

export function getDashboardPerformanceRepayment(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetDashboardPerformanceRepayment + ObjectToQueryString(params),
            method: "get",
            data: {},
            onStart: actions.GET_DASHBOARD_PERFORMANCE_REPAYMENT_START,
            onSuccess: actions.GET_DASHBOARD_PERFORMANCE_REPAYMENT,
            onError: actions.GET_DASHBOARD_PERFORMANCE_REPAYMENT_FAILED,
        },
    }
}

export function getDashboardCharts(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetDashboardCharts + ObjectToQueryString(params),
            method: "get",
            data: {},
            onStart: actions.GET_DASHBOARD_CHARTS_START,
            onSuccess: actions.GET_DASHBOARD_CHARTS,
            onError: actions.GET_DASHBOARD_CHARTS_FAILED,
        },
    }
}

export function getCompanyStats(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetCompanyStats + ObjectToQueryString(params),
            method: "get",
            data: {},
            onStart: actions.GET_COMPANY_STATS_START,
            onSuccess: actions.GET_COMPANY_STATS,
            onError: actions.GET_COMPANY_STATS_FAILED,
        },
    }
}

export function getDashboardWithdrawalMonthStats(params) {
    return {
        type: config.apiRequestStart,
        payload: {
            url: config.apiGetWithdrawalMonthStats + ObjectToQueryString(params),
            method: "get",
            data: {},
            onStart: actions.GET_WITHDRAWAL_MONTH_STATS_START,
            onSuccess: actions.GET_WITHDRAWAL_MONTH_STATS,
            onError: actions.GET_WITHDRAWAL_MONTH_STATS_FAILED,
        },
    }
}
