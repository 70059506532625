import {
	TabSettingsWide,
	TabSettingsMobile,
} from "components/common/settingsTab";
import { useState } from "react";
import GoalSavingsTab from "./savings-tabs/GoalSavingsTab";
import FlexSavingsTab from "./savings-tabs/FlexSavingsTab";

function SavingsSettings() {
	const tabItems = ["Goal Savings", "Flex Savings"];

	const [currentTab, setCurrentTab] = useState(tabItems[0]);

	const isGoalSavings = currentTab === tabItems[0];

	return (
		<div>
			<TabSettingsMobile />

			<div className="box">
				<div className="flex">
					<TabSettingsWide />

					<div className="lg:w-3/4 xl:w-4/5 lg:pl-6 flex-grow">
						<div className="grid sm:grid-cols-2">
							<div className="page-title">Savings Settings</div>
							<div></div>
						</div>
						<div className="w-full mt-6 pb-3 relative flex items-center space-x-5 border-b border-gray-400">
							{tabItems.map((item) => (
								<div
									onClick={() => setCurrentTab(item)}
									key={item}
									className="w-max cursor-pointer relative">
									<p
										style={{ color: currentTab === item ? "" : "#718096" }}
										className={`setting-tab-item ${
											currentTab === item && "text-ep-blue"
										}`}>
										{item}
									</p>
									{currentTab === item && (
										<div
											style={{
												height: "2.5px",
												width: "100%",
												position: "absolute",
												bottom: "-12px",
											}}
											className="bg-ep-blue-deeper "
										/>
									)}
								</div>
							))}
						</div>
						<div className="mt-8 w-full">
							{isGoalSavings ? <GoalSavingsTab isGoalSavings={isGoalSavings} /> : 
							<FlexSavingsTab isGoalSavings={isGoalSavings} />}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default SavingsSettings;
